import { useState } from "react";

import { useConfigContext } from "shared/contexts/ConfigContext";
import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getPageKeyWithVersion } from "features/ui/Filters/utils";

import {
  DEFAULT_EMPTY_OCCURS_FILTER,
  DEFAULT_EMPTY_OCCURS_FILTER_ENCODED,
} from "./constants";
import {
  decodeOccursFilterAndOptions,
  encodeOccursFilterAndOptions,
} from "./utils";

export const useAssociatedSignalEventsOccursFilter = (
  pageKey: string,
  pendingFiltersKey: string,
  occursFilterKey: string
) => {
  const {
    pages: { signalEventsAnalytics },
  } = useConfigContext();

  const defaultSignalEventFilters = filterBuilderQueryToFilterBuilderState(
    signalEventsAnalytics?.defaultFilters
  );

  const { attributeAccessors } = useSignalEventOccurrencesSchema();

  const associatedSignalEventsFilterSortState = useFilterSortState({
    pageKey,
    defaultFilterValues:
      defaultSignalEventFilters ?? DEFAULT_FILTER_BUILDER_STATE,
    pendingFiltersLocalStorageKey: pendingFiltersKey,
    schemaAttributes: attributeAccessors,
  });

  const initialOccursFilter = getInitialOccursFilterState(
    associatedSignalEventsFilterSortState,
    occursFilterKey
  );

  const [appliedOccursFilter, setAppliedOccursFilterInternal] =
    useState(initialOccursFilter);

  const setAppliedOccursFilter = (occursFilterState: string) => {
    setAppliedOccursFilterInternal(occursFilterState);
    localStorage.setItem(
      getPageKeyWithVersion(occursFilterKey),
      occursFilterState
    );
  };

  const [occursFilter, setOccursFilterInternal] =
    useState<string>(appliedOccursFilter);

  const setOccursFilter = (value: string) => {
    setOccursFilterInternal(value);
    // we set internal value and also propagate changes to useFilterSortState so it is recoded in query
    associatedSignalEventsFilterSortState.manageRelatedSignalEventsFilterChange &&
      associatedSignalEventsFilterSortState.manageRelatedSignalEventsFilterChange(
        decodeOccursFilterAndOptions(value)
      );
  };

  const onApply = () => {
    setAppliedOccursFilter(occursFilter);
  };

  const onCancel = () => {
    setOccursFilter(appliedOccursFilter);
  };

  return {
    occursFilter,
    setOccursFilter,
    appliedOccursFilter,
    setAppliedOccursFilter,
    associatedSignalEventsFilterSortState,
    onApply,
    onCancel,
  };
};

const getInitialOccursFilterState = (
  associatedSignalEventsFilterSortState: UseFilterSortState,
  occursFilterKey: string
): string => {
  if (associatedSignalEventsFilterSortState.relatedSignalEventsFilter) {
    return encodeOccursFilterAndOptions(
      filterBuilderQueryToFilterBuilderState(
        associatedSignalEventsFilterSortState.relatedSignalEventsFilter?.filters
      ),
      {
        windowDirection:
          associatedSignalEventsFilterSortState.relatedSignalEventsFilter
            ?.windowDirection ?? DEFAULT_EMPTY_OCCURS_FILTER.windowDirection,
        windowSize:
          associatedSignalEventsFilterSortState.relatedSignalEventsFilter
            ?.windowSize ?? DEFAULT_EMPTY_OCCURS_FILTER.windowSize,
        windowType:
          associatedSignalEventsFilterSortState.relatedSignalEventsFilter
            ?.windowType ?? DEFAULT_EMPTY_OCCURS_FILTER.windowType,
      }
    );
  }

  const occursFilterStateLocalStorage = localStorage.getItem(
    getPageKeyWithVersion(occursFilterKey)
  );
  if (occursFilterStateLocalStorage) {
    return occursFilterStateLocalStorage;
  }

  return DEFAULT_EMPTY_OCCURS_FILTER_ENCODED;
};
