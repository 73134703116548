import classNames from "classnames";

import { TestProps } from "shared/types";
import { cloneObject } from "shared/utils";

import { DEFAULT_FILTER_TYPE } from "features/ui/Filters/constants";
import { FilterOperator } from "features/ui/Filters/types";
import { getFirstAvailableSelectedOperator } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";

import { DEFAULT_FILTER_BUILDER_STATE } from "./constants";
import FilterGroup from "./FilterGroup";
import { AnyAll } from "./FilterGroup/AnyAllSelect";
import { EditableRowProperty } from "./FilterRow/types";
import { FilterGroupState, FilterRowState } from "./types";
import {
  deleteAndReturnRowFromGroupByID,
  deleteFromGroupByID,
  getFilterRowRelations,
  insertInGroupAfterID,
  updateGroupAnyAllByID,
  updateRowPropertyByID,
} from "./utils";

interface Props extends TestProps {
  filterBuilderState?: FilterGroupState;
  filtersSchema: SchemaEntry<string>[];
  defaultFilters?: FilterGroupState;
  onChange: (filterState: FilterGroupState) => void;
  onAddToQuickFilters?: (
    filterState: FilterGroupState,
    filterRow: FilterRowState
  ) => void;

  inline?: boolean;
  disabled?: boolean;
  attributePlaceholder?: string;
  // embedded is a parameter we send to FilterSelector to say "this will be embedded as part of another filter selector",
  // which would adjust the paddings and coloring. This is applicable to Exists and Occurs filters
  embedded?: boolean;
  hasChanges?: boolean;
  hasInitialFocus?: boolean;
  occursFilterSelector?: boolean;
  quickFilters?: FilterRowState[];
}

const FilterBuilder = ({
  filterBuilderState = DEFAULT_FILTER_BUILDER_STATE,
  filtersSchema,
  defaultFilters,
  onChange,
  inline = false,
  embedded,
  disabled,
  attributePlaceholder,
  testId = "filter-builder",
  hasChanges,
  hasInitialFocus,
  occursFilterSelector,
  onAddToQuickFilters,
  quickFilters,
}: Props) => {
  const quickFilterAttributes = quickFilters
    ?.map((quickFilter) => quickFilter.attribute)
    .filter((attribute) => attribute != null);

  const addNewRow = (id: string) => {
    onChange(insertInGroupAfterID(filterBuilderState, id, "row"));
  };

  const addNewGroup = (id: string) => {
    onChange(insertInGroupAfterID(filterBuilderState, id, "group"));
  };

  const onDelete = (id: string) => {
    const relations = getFilterRowRelations(filterBuilderState, id);
    if (!relations) return;

    const { siblingBefore, siblingAfter, parent } = relations;
    let gotoRow: HTMLElement | null = null;

    if (!siblingBefore && !siblingAfter) {
      // if no siblings, let the default autofocus happen
      onChange(deleteFromGroupByID(filterBuilderState, id));

      return;
    }

    if (siblingBefore) {
      gotoRow = document.getElementById(siblingBefore);
    } else if (siblingAfter) {
      gotoRow = document.getElementById(siblingAfter);
    } else if (parent) {
      gotoRow = document.getElementById(parent);
    }

    if (gotoRow) {
      setTimeout(() => {
        const firstInput = gotoRow.querySelector("input");
        if (firstInput) {
          firstInput.focus();
        }
      }, 0);
    }

    onChange(deleteFromGroupByID(filterBuilderState, id));
  };

  const onGroupOperatorChange = (id: string, anyAll: AnyAll) => {
    onChange(updateGroupAnyAllByID(filterBuilderState, id, anyAll));
  };

  const onResetToDefault = () => {
    onChange(defaultFilters || DEFAULT_FILTER_BUILDER_STATE);
  };

  const onClear = () => {
    onChange(DEFAULT_FILTER_BUILDER_STATE);
  };

  const onMoveToQuickFilters = (id: string) => {
    const [updatedState, removedRow] = deleteAndReturnRowFromGroupByID(
      filterBuilderState,
      id
    );
    if (removedRow && onAddToQuickFilters) {
      onAddToQuickFilters(updatedState, removedRow);
    }
  };

  const onRowDataChange = (
    id: string,
    property: EditableRowProperty,
    values?: string | string[]
  ) => {
    let newFilterState = cloneObject(filterBuilderState);

    // if we are changing the attribute, we need to reset operator and values for this id before applying this change
    if (property === "attribute") {
      // get default operator for this attribute
      const attributeType =
        filtersSchema.find((schemaEntry) => schemaEntry.accessor === values)
          ?.filter?.filterType || DEFAULT_FILTER_TYPE;

      const defaultOperator = getFirstAvailableSelectedOperator(
        null,
        attributeType,
        filtersSchema.find((schemaEntry) => schemaEntry.accessor === values)
      );

      newFilterState = updateRowPropertyByID(
        newFilterState,
        id,
        "operator",
        defaultOperator
      );

      newFilterState = updateRowPropertyByID(newFilterState, id, "values", []);
    }

    // if operator is is_empty or is_not_empty, we need to set values to "null"
    if (
      property === "operator" &&
      (values === FilterOperator.IS_EMPTY ||
        values === FilterOperator.IS_NOT_EMPTY)
    ) {
      newFilterState = updateRowPropertyByID(newFilterState, id, "values", [
        "null",
      ]);
    }

    // if operator is is_true or is_false, we need to set values to "true" or "false"
    if (
      property === "operator" &&
      (values === FilterOperator.IS_TRUE || values === FilterOperator.IS_FALSE)
    ) {
      newFilterState = updateRowPropertyByID(newFilterState, id, "values", [
        String(values === FilterOperator.IS_TRUE),
      ]);
    }

    onChange(updateRowPropertyByID(newFilterState, id, property, values));
  };

  return (
    <div
      data-testid={testId}
      className={classNames("py-1 w-full", {
        "px-3 border-t h-[35rem] ": !inline,
        "-mt-4": embedded,
      })}
    >
      <FilterGroup
        key={filterBuilderState.id}
        id={filterBuilderState.id}
        depth={0}
        children={filterBuilderState.children}
        anyAll={filterBuilderState.anyAll}
        onNewRow={addNewRow}
        onNewGroup={addNewGroup}
        onDelete={onDelete}
        onGroupOperatorChange={onGroupOperatorChange}
        onRowDataChange={onRowDataChange}
        schema={filtersSchema}
        onResetToDefault={onResetToDefault}
        onClear={onClear}
        disabled={disabled}
        attributePlaceholder={attributePlaceholder}
        embedded={embedded}
        hasChanges={hasChanges}
        hasInitialFocus={hasInitialFocus}
        occursFilterSelector={occursFilterSelector}
        onMoveToQuickFilters={onMoveToQuickFilters}
        quickFilterAttributes={quickFilterAttributes}
      />

      {/* Handle for focus to not get lost while tabbing */}
      {hasInitialFocus && (
        <button
          className="sr-only"
          tabIndex={0}
          onFocus={() => {
            // the id of the first row is always "group-0"
            const item = document.getElementById("group-0");
            if (item) {
              setTimeout(() => {
                const firstInput = item.querySelector("input");
                if (firstInput) {
                  firstInput.focus();
                }
              }, 0);
            }
          }}
          aria-label="Return to first input"
        />
      )}
    </div>
  );
};

export default FilterBuilder;
