import { useState } from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  CollectionType,
  newCollection,
  NewCollectionRequest,
} from "shared/api/api";
import { APIFilter } from "shared/api/utils";

import { VEHICLE_COLLECTION_ENTITY_TYPE } from "pages/Collections/constants";
import FormStepper from "pages/Collections/NewCollectionForm/FormStepper";
import styles from "pages/Collections/NewCollectionForm/NewCollectionForm.module.css";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";

import { routes } from "services/routes";

import { DEFAULT_COLLECTION_TYPE } from "./constants";
import FiltersPreview from "./FiltersPreview";
import FormNavigation from "./FormNavigation";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";

const NAME_MIN_LENGTH = 5;
const NAME_MAX_LENGTH = 120;

export const getSuccessMessage = (name: string) =>
  `Successfully created new collection "${name}"`;

interface Props {
  filters: FilterGroupState;
  staticFilters?: APIFilter[];
}

interface Step {
  label: string;
  content: JSX.Element;
}

const NewCollectionForm = ({ filters, staticFilters }: Props) => {
  const navigate = useNavigate();
  const [collectionName, setCollectionName] = useState("");
  const [collectionType, setCollectionType] = useState<CollectionType>(
    DEFAULT_COLLECTION_TYPE
  );
  const [activeStep, setActiveStep] = useState(0);
  const [error, setError] = useState<Error>();

  const collectionEntityType = VEHICLE_COLLECTION_ENTITY_TYPE;

  const [isLoading, setIsLoading] = useState(false);

  const [submitted, setSubmitted] = useState(false);

  const nameValid =
    collectionName.length >= NAME_MIN_LENGTH &&
    collectionName.length <= NAME_MAX_LENGTH;

  const stepsAll: (Step | boolean)[] = [
    {
      label: "Name",
      content: (
        <Step1
          collectionName={collectionName}
          onCollectionNameChange={setCollectionName}
          nameValid={nameValid}
        />
      ),
    },
    {
      label: "Type",
      content: (
        <Step2 onChange={setCollectionType} selectedType={collectionType} />
      ),
    },
    {
      label: "Review & Submit",
      content: (
        <Step3
          collectionName={collectionName}
          filters={filters}
          collectionType={collectionType}
        />
      ),
    },
  ];

  const steps = stepsAll.filter(Boolean) as Step[];

  const createNewCollection = () => {
    setIsLoading(true);
    setSubmitted(false);

    const args: NewCollectionRequest = {
      name: collectionName,
      type: collectionType,
      filter: getFiltersQuery(filters, staticFilters),
    };

    newCollection(args)
      .then(({ data: { ID } }) => {
        toast.success(getSuccessMessage(collectionName));
        navigate({
          pathname: generatePath(routes.collections, { id: ID }),
        });
      })
      .catch((error: Error) => {
        setError(error);
      })
      .finally(() => {
        setSubmitted(true);
        setIsLoading(false);
      });
  };

  const showStepper = !submitted;

  return (
    <>
      {!submitted && (
        <p className="text-gray-500 text-xs pt-2">
          You will create a collection of {collectionEntityType}s based on
          currently selected filters.
        </p>
      )}

      {showStepper && <FormStepper steps={steps} activeStep={activeStep} />}

      {!submitted && (
        <div className={styles["stepsWrap"]}>
          {steps.map(
            ({ label, content }, i) =>
              activeStep === i && <div key={label}>{content}</div>
          )}
        </div>
      )}

      {!showStepper && <FiltersPreview filters={filters} />}

      {!isLoading && (
        <div className="py-3">
          <h3>
            {error && (
              <div className="text-gray-500">
                {error.message}.<br />
                Please try again later or contact us directly.
              </div>
            )}
          </h3>
          {submitted && !error && (
            <div className="mt-4 text-sm text-gray-400">
              Go to{" "}
              <Link
                to={routes.collections}
                className="text-gray-500 hover:text-blue-400"
              >
                Collections
              </Link>{" "}
              to see it.
            </div>
          )}
        </div>
      )}

      {!submitted && (
        <FormNavigation
          activeStep={activeStep}
          stepsLength={steps.length}
          collectionNameValid={nameValid}
          isLoading={isLoading}
          onStepChange={setActiveStep}
          onFormSubmit={createNewCollection}
        />
      )}
    </>
  );
};

export default NewCollectionForm;
