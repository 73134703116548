import { Component, ErrorInfo, ReactNode } from "react";

import DuckError from "./DuckError";

interface DuckErrorBoundaryProps {
  children: ReactNode;
  quiet?: boolean;
}

interface DuckErrorBoundaryState {
  error: Error | null;
}

class DuckErrorBoundary extends Component<
  DuckErrorBoundaryProps,
  DuckErrorBoundaryState
> {
  constructor(props: DuckErrorBoundaryProps) {
    super(props);
    this.state = {
      error: null,
    };
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error,
    };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error("ViaDuck Error Boundary caught an error", error, info);
  }

  render() {
    if (this.state.error) {
      return this.props.quiet ? null : <DuckError />;
    }

    return this.props.children;
  }
}

export default DuckErrorBoundary;
