import { useState } from "react";
import Skeleton from "react-loading-skeleton";

import {
  getSignalEventsByVehicleAgeExport,
  SignalEventByVehicleAgeFiltersRequest,
} from "shared/api/signalEvents/api";
import { useListSignalEventsByVehicleAgeTimeline } from "shared/api/signalEvents/hooks";
import { NO_BY_PRODUCTION_DATE_ATTRIBUTES_CONFIGURED } from "shared/constants";
import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { EventTypeEnum, EventTypeLabel } from "shared/types";

import { BY_VEHICLES_AGE_TAB_KEY } from "pages/constants";
import { useByVehicleAgeOptions } from "pages/hooks";
import ByVehicleAgeChart from "pages/shared/analytics/ByVehicleAgeChart";
import ByVehicleAgeTable from "pages/shared/analytics/ByVehicleAgeTable";
import { SignalEventsFiltersProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";
import {
  areThereAnyByAgeAttributes,
  isSelectedByAgeAttributeInvalid,
} from "pages/utils";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import {
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";

import { useGetByVehicleAgeChartActions } from "./hooks";

export const SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY =
  "signalEventsAnalyticsByVehicleAgeChartOptions";
const ALLOW_NONE_EXPOSURE = true;

const ByVehicleAge = ({
  signalEventsFilters,
  vehiclesFilters,
  onBadRequest,
  chartSettings,
  manageChartSettingsChange,
  onAddDateRangeToVehicleFilters,
}: SignalEventsFiltersProps) => {
  const actions = useGetByVehicleAgeChartActions(
    EventTypeLabel.SIGNAL_EVENT,
    ALLOW_NONE_EXPOSURE
  );

  const initialSelectedOptions: SelectedChartOptions[] =
    chartSettings &&
    chartSettings[BY_VEHICLES_AGE_TAB_KEY] &&
    chartSettings[BY_VEHICLES_AGE_TAB_KEY][
      SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY
    ]
      ? chartSettings[BY_VEHICLES_AGE_TAB_KEY][
          SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY
        ]
      : getDefaultActions(actions);

  const [selectedOptions, setSelectedOptions] = useState<
    SelectedChartOptions[]
  >(initialSelectedOptions);

  const handleSelectedOptionsChange = (
    newSelectedOptions: SelectedChartOptions[]
  ) => {
    setSelectedOptions(newSelectedOptions);
    if (manageChartSettingsChange) {
      manageChartSettingsChange(
        newSelectedOptions,
        SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY
      );
    }
  };

  const byVehicleAgeData = useByVehicleAgeOptions(selectedOptions, actions);
  const { byVehicleAgeBirthday, byVehicleAgeExposure, granularity } =
    byVehicleAgeData;

  const filteredActions = useGetByVehicleAgeChartActions(
    EventTypeLabel.SIGNAL_EVENT,
    ALLOW_NONE_EXPOSURE,
    byVehicleAgeExposure
  );

  const yAxisKey = getSelectedOptionId(selectedOptions, "y");
  const filteredYOptions =
    filteredActions
      .find((action) => action.id === "y")
      ?.options?.map((option) => option.id) || [];
  if (!filteredYOptions.includes(yAxisKey)) {
    const newSelectedOptions = selectedOptions.map((option) =>
      option.id === "y" ? { id: "y", optionId: filteredYOptions[0] } : option
    );
    handleSelectedOptionsChange(newSelectedOptions);
  }

  const requestParams: SignalEventByVehicleAgeFiltersRequest = {
    signalEventOccurrencesFilter:
      signalEventsFilters && getFiltersQuery(signalEventsFilters),
    vehiclesFilter: vehiclesFilters && getFiltersQuery(vehiclesFilters),
    byVehicleAgeBirthday,
    byVehicleAgeExposure,
    granularity,
  };

  const { data, isLoading, error } =
    useListSignalEventsByVehicleAgeTimeline(requestParams);

  const { attributes: vehicleAttributes } = useVehiclesSchema();
  const { attributes: signalEventAttributes } =
    useSignalEventOccurrencesSchema();
  if (
    !areThereAnyByAgeAttributes(
      vehicleAttributes,
      signalEventAttributes,
      ALLOW_NONE_EXPOSURE
    )
  ) {
    return NO_BY_PRODUCTION_DATE_ATTRIBUTES_CONFIGURED;
  } else if (
    isSelectedByAgeAttributeInvalid(
      byVehicleAgeData,
      vehicleAttributes,
      signalEventAttributes,
      ALLOW_NONE_EXPOSURE
    )
  ) {
    setSelectedOptions(getDefaultActions(actions));
  }

  if (isLoading) {
    return <Skeleton height={500} count={2} className="mt-5" />;
  }

  return (
    <div className="mt-5">
      <ByVehicleAgeChart
        byVehicleAgeData={byVehicleAgeData}
        data={data}
        error={error}
        actions={filteredActions}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleSelectedOptionsChange}
        onBadRequest={onBadRequest}
        onAddDateRangeToVehicleFilters={onAddDateRangeToVehicleFilters}
      />
      <ByVehicleAgeTable
        eventType={EventTypeEnum.SIGNAL_EVENT}
        byVehicleAgeData={byVehicleAgeData}
        filters={mergeFilterGroupStates(signalEventsFilters, vehiclesFilters)}
        data={data}
        error={error}
        downloadRequestParams={requestParams}
        downloadFunc={getSignalEventsByVehicleAgeExport}
        onBadRequest={onBadRequest}
      />
    </div>
  );
};

export default ByVehicleAge;
