import { useState } from "react";
import classNames from "classnames";
import { LuBookmarkPlus as BookmarkIcon } from "react-icons/lu";
import { toast } from "react-toastify";
import { IconButton, Popover } from "@mui/material";

import {
  createBookmark,
  CreateBookmarkRequest,
} from "shared/api/bookmarks/api";

import Tooltip from "features/ui/Tooltip";

import CreateBookmark from "./CreateBookmark";

const SUCCESS_TEXT = "Page state added to quick starts!";
const FAIL_TEXT = "Failed to add to quick starts";
const TOOLTIP_TEXT = "Add current page and its state to quick starts";
const TOOLTIP_FOR_EMPTY_TEXT = "Add a new quick start";

const ICON_SIZE = 25;
const ELEMENT_ID = "create-bookmark-action";

interface CreateBookmarkActionProps {
  isEmpty?: boolean;
  onCreated?: () => void;
}

const CreateBookmarkAction = ({
  isEmpty,
  onCreated,
}: CreateBookmarkActionProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // isOpen is used only to change the color of the icon when form is open
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleConfirm = async (bookmark: CreateBookmarkRequest) => {
    createBookmark(bookmark)
      .then(() => {
        toast.success(SUCCESS_TEXT);
        onCreated && onCreated();
        handleClose();
      })
      .catch(() => {
        toast.error(FAIL_TEXT);
      });
  };
  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  const open = Boolean(anchorEl);
  const tooltipContent = (
    <div className="text-xs">
      {isEmpty ? TOOLTIP_FOR_EMPTY_TEXT : TOOLTIP_TEXT}
    </div>
  );
  const bookmark: CreateBookmarkRequest = {
    title: "",
    description: "",
    path: isEmpty ? "" : window.location.href,
  };

  return (
    <>
      <Tooltip title={tooltipContent} placement="bottom-end">
        <IconButton
          onClick={handleClick}
          data-testid="create-bookmark-action"
          size="small"
          className={classNames({ "text-blue-500!": isOpen })}
        >
          <BookmarkIcon size={ICON_SIZE} />
        </IconButton>
      </Tooltip>

      <Popover
        id={ELEMENT_ID}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CreateBookmark
          onConfirm={handleConfirm}
          onCancel={handleClose}
          bookmark={bookmark}
          isEmpty={isEmpty}
        />
      </Popover>
    </>
  );
};

export default CreateBookmarkAction;
