import { SensorReadingsTimelineGrouping } from "shared/api/sensors/api";

import SignalEventsOccurrencesTable from "pages/SignalEventsAnalytics/tabPages/SignalEvents/SignalEventsTable";

import { mergeFilterGroupStates } from "features/ui/Filters/FilterBuilder/utils";
import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import EventsTimelineTable, {
  EventsTimelineTableProps,
} from "./EventsTimelineTable";
import {
  createDotFilterGroupState,
  getDotTimeFilter,
  SignalEventDot,
} from "./utils";

interface Props extends EventsTimelineTableProps {
  grouping: SensorReadingsTimelineGrouping;
  selectedDot: SignalEventDot | null;
}

const VIN_VIEW_TIMELINE_SIGNAL_EVENTS_BY_CODE_TAB_KEY =
  "timeline-signal-events-by-code";
const VIN_VIEW_TIMELINE_SIGNAL_EVENTS_RAW_TAB_KEY =
  "timeline-signal-events-raw";

const EventsTimelineTabs = ({
  vin,
  setShownSignalEvents,
  resetInitialShownSignalEvents,
  shownSignalEvents,
  hoveredSignal,
  setHoveredSignal,
  filters,
  customEventTableRows,
  dateFilterOccurrences,
  grouping,
  selectedDot,
}: Props) => {
  const allEventDataPageKey = `vin_se_occ_all_event_table_${vin}`;

  const dotTimeFilter = getDotTimeFilter(selectedDot, grouping);
  const dotFilterGroup = createDotFilterGroupState(selectedDot, dotTimeFilter);

  const seFilters = selectedDot
    ? mergeFilterGroupStates(filters, dotFilterGroup)
    : filters;

  const eventsTimelineTable = (
    <EventsTimelineTable
      vin={vin}
      setShownSignalEvents={setShownSignalEvents}
      resetInitialShownSignalEvents={resetInitialShownSignalEvents}
      shownSignalEvents={shownSignalEvents}
      hoveredSignal={hoveredSignal}
      setHoveredSignal={setHoveredSignal}
      filters={filters}
      customEventTableRows={customEventTableRows}
      dateFilterOccurrences={dateFilterOccurrences}
    />
  );
  if (customEventTableRows && customEventTableRows.length > 0)
    return eventsTimelineTable;

  const tabsItems: (Tab | boolean | undefined)[] = [
    {
      key: VIN_VIEW_TIMELINE_SIGNAL_EVENTS_BY_CODE_TAB_KEY,
      title: "Event data grouped by event code",
      content: eventsTimelineTable,
    },
    {
      key: VIN_VIEW_TIMELINE_SIGNAL_EVENTS_RAW_TAB_KEY,
      title: "All event data",
      content: (
        <SignalEventsOccurrencesTable
          onBadRequest={() => {}}
          signalEventsFilters={seFilters}
          pageKey={allEventDataPageKey}
        />
      ),
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return <StatefulTabs tabs={tabs} disableUsingQuery={true} />;
};

export default EventsTimelineTabs;
