import {
  useClaimsFiltersSchema,
  useSignalEventOccurrencesFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";

import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import { FilterDiffData } from "features/ui/Filters/types";

interface PopulationProps {
  populationFilter?: string;
  diff?: FilterDiffData;
}

interface ClaimProps {
  claimFilter?: string;
  diff?: FilterDiffData;
}

interface SignalEventProps {
  signalEventOccurrencesFilter?: string;
  diff?: FilterDiffData;
}

export const PopulationFilterPresenter = ({
  populationFilter,
  diff,
}: PopulationProps) => {
  const schema = useVehiclesFiltersSchema();
  if (!populationFilter) {
    return null;
  }

  return (
    <FilterQueryPresentation
      filter={populationFilter}
      tableSchema={schema}
      diff={diff}
    />
  );
};

export const ClaimFilterPresenter = ({ claimFilter, diff }: ClaimProps) => {
  const schema = useClaimsFiltersSchema();

  if (!claimFilter) {
    return null;
  }

  return (
    <FilterQueryPresentation
      filter={claimFilter}
      tableSchema={schema}
      baseEntityText="Claims"
      diff={diff}
    />
  );
};

export const SignalEventFilterPresenter = ({
  signalEventOccurrencesFilter,
  diff,
}: SignalEventProps) => {
  const schema = useSignalEventOccurrencesFiltersSchema();

  if (!signalEventOccurrencesFilter) {
    return null;
  }

  return (
    <FilterQueryPresentation
      filter={signalEventOccurrencesFilter}
      tableSchema={schema}
      diff={diff}
    />
  );
};
