import { useState } from "react";

import {
  useRepairsFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";
import { useRepairsSchema } from "shared/schemas/repairsSchema";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";

import { PageConfigProps } from "pages/SuperAdmin/PagesConfig/types";
import type { RepairAnalyticsConfig } from "pages/SuperAdmin/PagesConfig/types";

import DropdownSelect from "features/ui/DropdownSelect";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getFilterLabel } from "features/ui/Filters/utils";

const REPAIRS_PAGE_KEY = "admin_repAn_default_repair_filters";
const VEHICLES_PAGE_KEY = "admin_repAn_default_vehicle_filters";

export const DEFAULT_FILTERS_KEY = "defaultFilters";
export const DEFAULT_VEHICLE_FILTERS_KEY = "defaultVehicleFilters";

const RepairAnalyticsConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<RepairAnalyticsConfig>) => {
  const { attributeAccessors } = useRepairsSchema();
  const repairsFiltersSchema = useRepairsFiltersSchema();

  const { attributeAccessors: vehicleAttributeAccessors } = useVehiclesSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const initialRepairFilters = filterBuilderQueryToFilterBuilderState(
    config[DEFAULT_FILTERS_KEY]
  );

  const initialVehicleFilters = filterBuilderQueryToFilterBuilderState(
    config[DEFAULT_VEHICLE_FILTERS_KEY]
  );

  const defaultRepairFilters =
    filterStateToFilterGroupState(initialRepairFilters);

  const defaultVehicleFilters = filterStateToFilterGroupState(
    initialVehicleFilters
  );

  const [repairsDropdownOpen, setRepairsDropdownOpen] = useState(false);
  const [vehiclesDropdownOpen, setVehiclesDropdownOpen] = useState(false);

  const repairsFilterState = useFilterSortState({
    pageKey: REPAIRS_PAGE_KEY,
    defaultFilterValues: defaultRepairFilters ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
    schemaAttributes: attributeAccessors,
  });

  const vehiclesFilterState = useFilterSortState({
    pageKey: VEHICLES_PAGE_KEY,
    defaultFilterValues: defaultVehicleFilters ?? DEFAULT_FILTER_BUILDER_STATE,
    disableUsingLocalStorage: true,
    disableUsingQuery: true,
    schemaAttributes: vehicleAttributeAccessors,
  });

  const handleRepairsChange = (filterState: FilterGroupState) => {
    onConfigChange(pageId, DEFAULT_FILTERS_KEY, getFiltersQuery(filterState));
  };

  const handleVehiclesChange = (filterState: FilterGroupState) => {
    onConfigChange(
      pageId,
      DEFAULT_VEHICLE_FILTERS_KEY,
      getFiltersQuery(filterState)
    );
  };

  return (
    <div className="flex space-x-4">
      <DropdownSelect
        testId="default-repair-filters-dropdown"
        label={getFilterLabel(
          "Default Repair Filters",
          repairsFilterState.filters
        )}
        open={repairsDropdownOpen}
        onOpen={(open) => {
          setRepairsDropdownOpen(open);
        }}
        hasAdvancedFilters={false}
        content={
          <FilterSelector
            schema={repairsFiltersSchema}
            filterSortState={repairsFilterState}
            disableAdvancedFilter
            pendingFiltersKey={getPendingFiltersKey(REPAIRS_PAGE_KEY)}
            onApplyFilters={handleRepairsChange}
            testId="repair-filters-selector"
          />
        }
      />
      <DropdownSelect
        testId="default-vehicle-filters-dropdown"
        label={getFilterLabel(
          "Default Vehicle Filters",
          vehiclesFilterState.filters
        )}
        open={vehiclesDropdownOpen}
        onOpen={(open) => {
          setVehiclesDropdownOpen(open);
        }}
        hasAdvancedFilters={false}
        content={
          <FilterSelector
            schema={vehiclesFiltersSchema}
            filterSortState={vehiclesFilterState}
            disableAdvancedFilter
            pendingFiltersKey={getPendingFiltersKey(VEHICLES_PAGE_KEY)}
            onApplyFilters={handleVehiclesChange}
            testId="vehicle-filters-selector"
          />
        }
      />
    </div>
  );
};

export default RepairAnalyticsConfigPage;
