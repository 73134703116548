import { createBrowserRouter, RouterProvider } from "react-router-dom";

import SecureComponent from "features/auth/Login/SecureComponent";

import { RouteConfig } from "services/routesConfig";

import { routesConfig } from "./routesConfig";

const RouteComponent = ({ config }: { config: RouteConfig }) => {
  if (config.insecure) {
    return config.component;
  }

  return (
    <SecureComponent routeConfig={config}>{config.component!}</SecureComponent>
  );
};

const InstanceManagementRoutes = () => {
  const displayedRoutes = Object.entries(routesConfig);

  const displayedRoutesRouter = displayedRoutes.map(([, config]) => ({
    path: config.path,
    element: <RouteComponent config={config} />,
  }));

  const router = createBrowserRouter(displayedRoutesRouter, {
    // Temporary until migrating fully to v7 router
    // https://reactrouter.com/upgrading/v6#v7_relativesplatpath
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
    },
  });

  return (
    <RouterProvider
      router={router}
      // https://github.com/remix-run/react-router/issues/12974
      // future={{
      //   v7_startTransition: true,
      // }}
    />
  );
};

export default InstanceManagementRoutes;
