import { APIPaginatedRequest, postFetcher } from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { RECENT_ITEMS_ROUTE } from "shared/constants";

export type RecentItemType =
  | "issue"
  | "suggested_issue"
  | "vehicle"
  | "fleet"
  | "group"
  | "claim"
  | "failure_mode"
  | "service_plan"
  | "calculated_attribute"
  | "custom_signal_event"
  | "alert_definition"
  | "repair"
  | "inspection";

export const RECENT_ITEM_PATHS: Record<RecentItemType, string> = {
  issue: "/issues",
  suggested_issue: "/suggested-issues",
  vehicle: "/vehicles",
  fleet: "/fleets",
  group: "/groups",
  claim: "/claim-analytics",
  failure_mode: "/failure-modes-v1",
  service_plan: "/service-plans",
  calculated_attribute: "/calculated-attributes",
  custom_signal_event: "/custom-signal-events",
  alert_definition: "/alert-definitions",
  repair: "/repair-analytics",
  inspection: "/inspection-analytics",
};

export interface RecentItem {
  ID: string;
  createdAt: string;
  objectType: RecentItemType;
  objectID: string;
  name: string;
}

interface DeleteRecentItemRequest {
  ID: string;
}

interface CreateRecentItemRequest {
  objectID: string;
  objectType: string;
}

export const listRecentItemsRequestURI = (
  params: APIPaginatedRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([RECENT_ITEMS_ROUTE]),
    params,
  });

const createRecentItemRequestURI = ({
  ...params
}: CreateRecentItemRequest): string =>
  client.getUri({
    method: "POST",
    url: createURL([RECENT_ITEMS_ROUTE]),
    params,
  });

export const createRecentItem = (args: CreateRecentItemRequest) =>
  postFetcher<RecentItem>(createRecentItemRequestURI(args), args);

const deleteRecentItemRequestURI = ({ ID }: DeleteRecentItemRequest): string =>
  client.getUri({
    method: "DELETE",
    url: createURL([RECENT_ITEMS_ROUTE, ID]),
  });

export const deleteRecentItems = (args: DeleteRecentItemRequest[]) => {
  const promises = args.map((arg) =>
    client.delete(deleteRecentItemRequestURI(arg))
  );

  return Promise.all(promises);
};
