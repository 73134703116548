import { createContext } from "react";

import { EntityAttribute, Option } from "shared/api/api";
import { useListOptions, useVehicleOptionsAttributes } from "shared/api/hooks";

import { ContextWrapComponentProps } from "./types";

const MAX_LIMIT_OPTIONS = 2000;

export const VehicleOptionsAttributeContext = createContext<{
  attributes: EntityAttribute[] | undefined;
  options: Option[] | undefined;
}>({ attributes: undefined, options: undefined });

const VehicleOptionsAttributeContextWrapper = ({
  children,
}: ContextWrapComponentProps) => {
  const { data: attributes } = useVehicleOptionsAttributes({});
  const { data: options } = useListOptions({ limit: MAX_LIMIT_OPTIONS });

  return (
    <VehicleOptionsAttributeContext.Provider value={{ attributes, options }}>
      {children}
    </VehicleOptionsAttributeContext.Provider>
  );
};

export default VehicleOptionsAttributeContextWrapper;
