import { useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { IssueMetricsRegistry } from "shared/api/metrics/api";
import { APIFilter } from "shared/api/utils";

import { useChartSettings } from "pages/hooks";

import { getDefaultActions } from "features/ui/charts/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { UseFilterSortState } from "features/ui/Filters/types";

import { SUGGESTED_ISSUES_CHART_KEY, SUGGESTED_ISSUES_KEY } from "./constants";
import SuggestedIssuesTable from "./SuggestedIssues/SuggestedIssuesTable";
import SuggestedIssuesDashboard from "./SuggestedIssuesDashboard";
import { getIssueDashboardChartActions } from "./utils";

interface Props {
  suggestedIssueMetrics: IssueMetricsRegistry[];
  filterSortState: UseFilterSortState;
}

const SuggestedIssuesPageWithMetrics = ({
  suggestedIssueMetrics,
  filterSortState,
}: Props) => {
  const { issuePublishing } = useFlags();

  const [selectedBarFilters, setSelectedBarFilters] = useState<
    FilterGroupState | undefined
  >();

  const suggestedIssueDashboardChartActions = getIssueDashboardChartActions(
    suggestedIssueMetrics,
    undefined,
    "suggestedIssue"
  );

  const { selectedOptions, setSelectedOptions } = useChartSettings(
    filterSortState.chartSettings,
    filterSortState.manageChartSettingsChange,
    SUGGESTED_ISSUES_KEY,
    SUGGESTED_ISSUES_CHART_KEY,
    getDefaultActions(suggestedIssueDashboardChartActions)
  );

  const staticFiltersConditional: (APIFilter | boolean)[] = [
    // Users that are not allowed to publish/unpublish suggested issues should see only the published ones
    !issuePublishing && {
      name: "published",
      value: true,
      op: "eq",
    },
  ];
  const staticFilters = staticFiltersConditional.filter(Boolean) as APIFilter[];

  const availableSuggestedIssueDashboardChartActions =
    getIssueDashboardChartActions(
      suggestedIssueMetrics,
      selectedOptions,
      "suggestedIssue"
    );

  return (
    <>
      <SuggestedIssuesDashboard
        chartActions={availableSuggestedIssueDashboardChartActions}
        suggestedIssueMetrics={suggestedIssueMetrics}
        filterSortState={filterSortState}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        setSelectedBarFilters={setSelectedBarFilters}
        staticFilters={staticFilters}
      />
      <SuggestedIssuesTable
        chartActions={suggestedIssueDashboardChartActions}
        topFilterSortState={filterSortState}
        selectedChartOptions={selectedOptions}
        selectedBarFilters={selectedBarFilters}
        staticFilters={staticFilters}
      />
    </>
  );
};

export default SuggestedIssuesPageWithMetrics;
