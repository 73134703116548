import { useState } from "react";
import classNames from "classnames";
import { HiChevronDown } from "react-icons/hi";

import { toSnakeCase } from "shared/utils";

import Tooltip from "features/ui/Tooltip";

import { RouteConfig } from "services/routesConfig";

import NavBarItem, { ExternalNavBarItem } from "./NavBarItem";
import { NavGroup } from "./utils";

interface Props extends NavGroup {
  routeConfig: RouteConfig;
  menuExpanded: boolean;
}

const ICON_SIZE = "1rem";

const SideMenuGroup = ({
  title,
  links,
  defaultExpanded = true,
  routeConfig,
  menuExpanded,
}: Props) => {
  const [expanded, setExpanded] = useState(defaultExpanded);

  const navBarGroupHeader = (
    <div
      className={classNames(
        "py-1 flex inline-flex w-full bg-gray-100 text-gray-500 tracking-widest text-xs mb-1 border-b border-gray-300 cursor-pointer",
        {
          "pl-6 pr-2 justify-between": menuExpanded,
          "justify-center": !menuExpanded,
        }
      )}
      onClick={() => setExpanded(!expanded)}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      data-testid={`menu-group-${toSnakeCase(title)}`}
    >
      {menuExpanded && (
        <span className="block uppercase" data-testid="menu-subgroup-title">
          {title}
        </span>
      )}
      <span
        className={classNames("transition-transform duration-200", {
          "rotate-180": !expanded,
        })}
      >
        <HiChevronDown size={ICON_SIZE} />
      </span>
    </div>
  );

  return (
    <li
      className={classNames({
        "mb-3": expanded,
      })}
    >
      {menuExpanded && navBarGroupHeader}
      {!menuExpanded && (
        <Tooltip title={title} placement="right">
          {navBarGroupHeader}
        </Tooltip>
      )}
      <ul
        className={classNames(
          "overflow-hidden origin-top",
          "transition-all duration-200 ease-out",
          {
            "max-h-0 opacity-0 scale-y-95": !expanded,
            "max-h-96 opacity-100 scale-y-100": expanded,
          }
        )}
      >
        {links.map(
          (link) =>
            (link.isExternal && (
              <ExternalNavBarItem
                key={link.to.toString()}
                link={link}
                menuExpanded={menuExpanded}
              />
            )) || (
              <NavBarItem
                key={link.to.toString()}
                item={link}
                routeConfig={routeConfig}
                menuExpanded={menuExpanded}
              />
            )
        )}
      </ul>
    </li>
  );
};

export default SideMenuGroup;
