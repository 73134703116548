import { useState } from "react";

import { IssueMetricsRegistry } from "shared/api/metrics/api";

import { useChartSettings } from "pages/hooks";

import { getDefaultActions } from "features/ui/charts/utils";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { UseFilterSortState } from "features/ui/Filters/types";

import { ISSUES_CHART_KEY, ISSUES_KEY } from "./constants";
import IssuesDashboard from "./IssuesDashboard";
import IssuesTable from "./IssuesTable";
import { getIssueDashboardChartActions } from "./utils";

interface Props {
  issueMetrics: IssueMetricsRegistry[];
  filterSortState: UseFilterSortState;
}

const IssuesPageWithMetrics = ({ issueMetrics, filterSortState }: Props) => {
  const [selectedBarFilters, setSelectedBarFilters] = useState<
    FilterGroupState | undefined
  >();

  const initialIssueDashboardChartActions = getIssueDashboardChartActions(
    issueMetrics,
    undefined,
    "issue"
  );

  const { selectedOptions, setSelectedOptions } = useChartSettings(
    filterSortState.chartSettings,
    filterSortState.manageChartSettingsChange,
    ISSUES_KEY,
    ISSUES_CHART_KEY,
    getDefaultActions(initialIssueDashboardChartActions)
  );

  const availableIssueDashboardChartActions = getIssueDashboardChartActions(
    issueMetrics,
    selectedOptions,
    "issue"
  );

  return (
    <>
      {" "}
      <IssuesDashboard
        chartActions={availableIssueDashboardChartActions}
        issueMetrics={issueMetrics}
        filterSortState={filterSortState}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        setSelectedBarFilters={setSelectedBarFilters}
      />
      <IssuesTable
        chartActions={availableIssueDashboardChartActions}
        topFilterSortState={filterSortState}
        selectedChartOptions={selectedOptions}
        selectedBarFilters={selectedBarFilters}
      />
    </>
  );
};

export default IssuesPageWithMetrics;
