import client from "shared/api/axios";
import { createURL } from "shared/api/utils";

const INFO_BASE_ROUTE = "info";

export interface UserInfo {
  isAdmin: boolean;
  isSuperAdmin: boolean;
}

export const getUserInfoRequestURI = (): string =>
  client.getUri({
    method: "GET",
    url: createURL([INFO_BASE_ROUTE, "user"]),
  });
