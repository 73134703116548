const Row = ({
  key,
  title,
  value,
}: {
  key?: string;
  title: string;
  value: string;
}) => (
  <tr key={key}>
    <td className="py-1 px-2 border">{title}</td>
    <td className="py-1 px-2 border">{value}</td>
  </tr>
);

export default Row;
