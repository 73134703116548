const nameRegex = /^[a-z0-9]+$/;

export const validateForm = (
  name: string
): { valid: boolean; message?: string } => {
  if (!name) {
    return { valid: false, message: "Please enter name." };
  }

  if (!nameRegex.test(name)) {
    return {
      valid: false,
      message: "Name must be lowercase and contain only letters and numbers.",
    };
  }

  return { valid: true, message: undefined };
};
