import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  APISuccessResponse,
  ByVehicleAgeAgeRequest,
  CountRequest,
  ExportRequest,
  getFetcher,
  GetRequest,
  ListAttributesRequest,
  TopContributorsAgeRequest,
} from "shared/api/api";
import client from "shared/api/axios";
import {
  SensorReadingsTimelineAggregation,
  SensorReadingsTimelineGrouping,
} from "shared/api/sensors/api";
import { createURL } from "shared/api/utils";
import {
  CountRequestWithVehiclesFilter,
  Vehicle,
} from "shared/api/vehicles/api";
import { MileageUnit } from "shared/types";

const SIGNAL_EVENTS_ROUTE = "signalEvents";

export interface SignalEvent {
  ID: string;
  description: string | null;
  name: string;
  type: string;
  createdAt: string;
  updatedAt: string;
}

export interface ListSignalEventsRequest extends APIPaginatedRequest {}

export const listSignalEventsRequestURI = (
  params: ListSignalEventsRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([SIGNAL_EVENTS_ROUTE]),
    params,
  });

const getSignalEventRequestURI = ({ id, ...params }: GetRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([SIGNAL_EVENTS_ROUTE, id]),
    params,
  });

export const getSignalEvent = (args: GetRequest) =>
  getFetcher<SignalEvent>(getSignalEventRequestURI(args));

export const listSignalEventsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listSignalEventsValuesRequestURI(args));

const listSignalEventsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["signalEvents", "values", fieldName]),
    params,
  });

export const getSignalEventsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getSignalEventsExportRequestURI(args), {
    responseType: "blob",
  });

const getSignalEventsExportRequestURI = ({
  IDs,
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([SIGNAL_EVENTS_ROUTE, "occurrences", "export", type]),
    params,
  });

export interface SignalEventsFiltersRequest {
  signalEventOccurrencesFilter?: string;
  vehiclesFilter?: string;
  claimFilter?: string;
}

export interface SignalEventByVehicleAgeFiltersRequest
  extends SignalEventsFiltersRequest,
    ByVehicleAgeAgeRequest {}

export interface SignalEventsOccurrencesMetrics {
  cumulativeIPTV: number;
  last30DayIPTV: number;
  IPTV30DayChangeRatio: number | null;
}

export const getSignalEventsOccurrencesMetricsRequestURI = (
  params: SignalEventsFiltersRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([SIGNAL_EVENTS_ROUTE, "occurrences", "metrics"]),
    params,
  });

export interface SignalEventOccurrencesMetricsHistory {
  date: string;
  cumulativeIPTV: number;
  rollingIPTV: number;
  rollingOccurrences: number;
  cumulativeOccurrences: number;
  vehicleCount: number;
  cumulativeDPTV: number;
  rollingDPTV: number;
  rollingAffectedVINs: number;
  cumulativeAffectedVINs: number;
}

export const getSignalEventsOccurrencesMetricsHistoryRequestURI = (
  params: SignalEventsFiltersRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([SIGNAL_EVENTS_ROUTE, "occurrences", "metricsHistory"]),
    params,
  });

export interface SignalEventsOccurrence {
  FMI: number | null;
  SPN: number | null;
  VIN: string;
  amberLampStatus: string | null;
  trigger: string | null;
  currentFault: string | null;
  description: string | null;
  ecuFamily: string | null;
  ecuSupplier: string | null;
  ecuVersion: string | null;
  ecuPartNumber: string | null;
  ecuSoftwareVersion: string | null;
  ecuEditionNumber: string | null;
  extCode: string | null;
  milLampStatus: string | null;
  obd2Code: string | null;
  occurrenceCount: number;
  protectLampStatus: string | null;
  recordedAt: string;
  redLampStatus: string | null;
  signalEventID: string;
  signalEventType: string;
  status: string | null;
  symptom: string | null;
  totalEngineHours: number | null;
  daysInService: number | null;
  monthsInService: number | null;
  odometer: number | null;
  odometerUnit: MileageUnit | null;
  newOccurrence: boolean | null;
  highPriority: boolean | null;
  extTransactionID: string | null;
}

export interface ListSignalEventOccurrencesRequest extends APIPaginatedRequest {
  mileageUnit: MileageUnit;
}

export const listSignalEvents = (
  args: ListSignalEventOccurrencesRequest
): Promise<APISuccessResponse<SignalEvent[]>> =>
  getFetcher(listSignalEventsRequestURI({ ...args }));

export const listSignalEventOccurrenceAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([SIGNAL_EVENTS_ROUTE, "occurrences", "attributes"]),
    params,
  });

const listSignalEventsOccurrencesValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([SIGNAL_EVENTS_ROUTE, "occurrences", "values", fieldName]),
    params,
  });

export const listSignalEventsOccurrencesValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(
    listSignalEventsOccurrencesValuesRequestURI(args)
  );

export const listSignalEventsOccurrencesRequestURI = ({
  ...params
}: ListSignalEventOccurrencesRequest): string =>
  client.getUri({
    method: "GET",
    url: createURL([SIGNAL_EVENTS_ROUTE, "occurrences"]),
    params,
  });

export const listSignalEventsOccurrencesCountRequestURI = ({
  ...params
}: CountRequestWithVehiclesFilter): string =>
  client.getUri({
    method: "GET",
    url: createURL([SIGNAL_EVENTS_ROUTE, "occurrences", "count"]),
    params,
  });

export interface SignalEventAssociatedClaim {
  groupByAttributeValue: string | null;
  IPTV: number;
  numAssociatedClaims: number;
  numAssociatedVehicles: number;
  percentClaimsWithPreceding30DaysSignalEvent: number;
  totalEventOccurrences: number;
  associationStrength: number | null;
}

export interface SignalEventsAssociatedClaimsRequest
  extends APIPaginatedRequest,
    SignalEventsFiltersRequest {
  groupBy: string;
  signalEventsTimeWindow?: number;
}

export const listSignalEventsAssociatedClaimsRequestURI = ({
  groupBy,
  ...otherParams
}: SignalEventsAssociatedClaimsRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([SIGNAL_EVENTS_ROUTE, "associatedClaims", groupBy]),
    params: otherParams,
  });

// By Production Date
export const getSignalEventsByVehicleAgeTimelineRequestURI = (
  params: SignalEventsFiltersRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([SIGNAL_EVENTS_ROUTE, "signalEventsByVehicleAgeTimeline"]),
    params,
  });

const getSignalEventsByVehicleAgeTimelineExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([
      SIGNAL_EVENTS_ROUTE,
      "signalEventsByVehicleAgeTimeline",
      "export",
      type,
    ]),
    params,
  });

export const getSignalEventsByVehicleAgeExport = (args: ExportRequest) =>
  getFetcher<Blob>(getSignalEventsByVehicleAgeTimelineExportRequestURI(args), {
    responseType: "blob",
  });

export interface SignalEventsTopContributorsRequest
  extends APIPaginatedRequest,
    SignalEventsFiltersRequest,
    TopContributorsAgeRequest {
  groupBy: string;
  mis?: string;
}

export interface SignalEventsTopContributor {
  groupByAttributeValue: string | null;
  IPTV: number;
  numSignalEventOccurrences: number;
  numVehicles: number;
  IPTV30DayChangeRatio: number | null;
  DPTV: number;
  DPTV30DayChangeRatio: number | null;
}

export const getSignalEventsTopContributorsRequestURI = ({
  groupBy,
  ...otherParams
}: SignalEventsTopContributorsRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([SIGNAL_EVENTS_ROUTE, "topContributors", groupBy]),
    params: otherParams,
  });

const getSignalEventsTopContributorsExportRequestURI = ({
  IDs,
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([
      SIGNAL_EVENTS_ROUTE,
      "topContributors",
      ...IDs,
      "export",
      type,
    ]),
    params,
  });

export const getSignalEventsTopContributorsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getSignalEventsTopContributorsExportRequestURI(args), {
    responseType: "blob",
  });

export interface SignalEventsAssociatedVehiclesRequest
  extends APIPaginatedRequest,
    SignalEventsFiltersRequest {
  mileageUnit: MileageUnit;
}

export const listSignalEventsAssociatedVehiclesRequestURI = ({
  ...params
}: SignalEventsAssociatedVehiclesRequest): string =>
  client.getUri({
    method: "GET",
    url: createURL([SIGNAL_EVENTS_ROUTE, "associatedVehicles"]),
    params,
  });

export const listSignalEventsAssociatedVehiclesCountRequestURI = ({
  ...params
}: CountRequest): string =>
  client.getUri({
    method: "GET",
    url: createURL([SIGNAL_EVENTS_ROUTE, "associatedVehicles", "count"]),
    params,
  });

export interface SignalEventsAssociatedVehicle extends Vehicle {
  numRelatedClaimOccurrences: number;
  lastRelatedSignalEventOccurrenceAt: string;
  firstRelatedSignalEventOccurrenceAt: string;
}

const getAssociatedVehiclesExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([SIGNAL_EVENTS_ROUTE, "associatedVehicles", "export", type]),
    params,
  });

export const getAssociatedVehiclesExport = (args: ExportRequest) =>
  getFetcher<Blob>(getAssociatedVehiclesExportRequestURI(args), {
    responseType: "blob",
  });

export const listDealerValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listDealerValuesRequestURI(args));

const listDealerValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["dealers", "values", fieldName]),
    params,
  });

export const listDealerAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL(["dealers", "attributes"]),
    params,
  });

export interface SignalEventOccurrencesVINTimelineBucket {
  signalEventID: string;
  VIN: string;
  occurrences: number;
  date: string;
  description?: string;
}

export interface SignalEventOccurrencesVINAggregateBucket {
  signalEventID: string;
  VIN: string;
  totalOccurrences: number;
  description?: string;
  signalEventType?: string;
}

export interface ListSignalEventOccurrencesVINTimelineRequest
  extends APIPaginatedRequest {
  grouping?: SensorReadingsTimelineGrouping;
  aggregation?: SensorReadingsTimelineAggregation;
}

export const listSignalEventOccurrencesVINTimelineRequestURI = ({
  ...params
}: ListSignalEventOccurrencesVINTimelineRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["signalEvents", "occurrences", "timeline"]),
    params,
  });

export interface ListSignalEventOccurrencesVINAggregateRequest
  extends APIPaginatedRequest {}

export const listSignalEventOccurrencesVINAggregateRequestURI = ({
  ...params
}: ListSignalEventOccurrencesVINAggregateRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["signalEvents", "occurrences", "aggregate"]),
    params,
  });

export const listSignalEventOccurrencesVINAggregateCountRequestURI = ({
  ...params
}: ListSignalEventOccurrencesVINAggregateRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["signalEvents", "occurrences", "aggregate", "count"]),
    params,
  });

export interface AssociatedSignalEvent {
  signalEventID: string;
  description: string;
  associationStrength: number | null;
  vehiclesWithCooccurrence: number;
  associatedVehicles: number;
  percentageAssociatedVehiclesWithBase: number;
  baseVehicles: number;
  percentageBaseVehiclesWithAssociated: number;
  cooccurrenceDays: number;
  percentageAssociatedWithBase: number;
  percentageBaseWithAssociated: number;
}

export interface ListSignalEventAssociatedSignalEventsRequest
  extends APIPaginatedRequest {
  signalEventOccurrencesFilter?: string;
  vehiclesFilter?: string;
  associatedSignalEventsFilter: string;
}

export const listSignalEventAssociatedSignalEventsRequestURI = ({
  associatedSignalEventsFilter,
  ...params
}: ListSignalEventAssociatedSignalEventsRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([
      "signalEvents",
      "associatedSignalEvents",
      associatedSignalEventsFilter,
    ]),
    params,
  });
