import { FormControlLabel, Switch } from "@mui/material";

import FeatureFlagExternalLink from "pages/SuperAdmin/PagesConfig/FeatureFlagExternalLink";
import {
  PageConfigProps,
  RBACConfig,
} from "pages/SuperAdmin/PagesConfig/types";

const RBAC_GROUPS_KEY = "rbacGroups";

const RBACConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<RBACConfig>) => (
  <div>
    <FormControlLabel
      control={
        <Switch
          checked={config[RBAC_GROUPS_KEY] ?? false}
          onChange={(_, checked) =>
            onConfigChange(pageId, RBAC_GROUPS_KEY, checked)
          }
          size="small"
        />
      }
      label={<div className="text-xs">Show groups in menu</div>}
    />
    <FeatureFlagExternalLink flagID="rbac-groups" />
  </div>
);

export default RBACConfigPage;
