import { Navigate } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";

import DeveloperPortal from "pages/Docs/DeveloperPortal";
import NotFound from "pages/NotFound";

import LoginLoading from "features/auth/Login/LoginLoading";

import { RouteConfig } from "services/routesConfig";

import NewTenant from "./pages/NewTenant";
import Tenants from "./pages/Tenants";
import { RouteKey, routes } from "./routes";

type RoutesConfig = Record<RouteKey, RouteConfig>;

export const routesConfig: RoutesConfig = {
  [RouteKey.LoginCallback]: {
    path: routes.loginCallback,
    insecure: true,
    component: <LoginCallback loadingElement={<LoginLoading />} />,
    wayFindingListingPage: true,
  },
  [RouteKey.Root]: {
    path: routes.root,
    component: <Navigate to={routes.tenants} />,
    wayFindingListingPage: true,
  },
  [RouteKey.Tenants]: {
    path: routes.tenants,
    component: <Tenants />,
    wayFindingListingPage: true,
  },
  [RouteKey.NewTenant]: {
    path: routes.newTenant,
    component: <NewTenant />,
    wayFindingListingPage: false,
  },
  [RouteKey.Docs]: {
    path: routes.docs,
    component: <DeveloperPortal />,
    wayFindingListingPage: true,
  },
  [RouteKey.NotFound]: {
    path: routes.notFound,
    component: <NotFound />,
    wayFindingListingPage: true,
  },
};
