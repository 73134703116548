import { BackToEntityEntry } from "./types";

const RETURN_TO_ENTITY_KEY = "returnToEntityEntry";

export const getReturnToEntityEntry = (): BackToEntityEntry | null => {
  const entry = localStorage.getItem(RETURN_TO_ENTITY_KEY);
  if (entry === null) {
    return null;
  }

  return JSON.parse(entry);
};
export const setReturnToEntityEntry = (entry: BackToEntityEntry) => {
  localStorage.setItem(RETURN_TO_ENTITY_KEY, JSON.stringify(entry));
};
export const removeReturnToEntityEntry = () => {
  localStorage.removeItem(RETURN_TO_ENTITY_KEY);
};
