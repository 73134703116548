import { UNIT_OPTIONS } from "pages/CustomSignalEvents/constants";
import { CustomAttribute } from "pages/CustomSignalEvents/Definition/CustomAttributes/types";

import DropdownSelect from "features/ui/DropdownSelect";
import Input from "features/ui/Input";

import { FunctionInputConfig } from "./types";

interface Props {
  config: FunctionInputConfig;
  attribute: CustomAttribute;
  onChange: (attribute: CustomAttribute) => void;
  disabled?: boolean;
}

const TextWithUnit = ({ config, attribute, onChange, disabled }: Props) => {
  const defaultUnitOption = UNIT_OPTIONS.find(({ id }) => id === "second");
  const unitParameter = `${config.parameter}Unit`;

  return (
    <div className="flex inline-flex space-x-2 items-center">
      <Input
        label={config.labelBefore}
        value={attribute[config.parameter] as string}
        onChange={({ target: { value } }) =>
          onChange({ ...attribute, [config.parameter]: Number(value) })
        }
        fullWidth={false}
        className="w-32"
        disabled={disabled}
      />
      <DropdownSelect
        label={
          (UNIT_OPTIONS.find(
            ({ id }) =>
              id ===
              (attribute[unitParameter as keyof CustomAttribute] as string)
          )?.value as string) || (defaultUnitOption?.value as string)
        }
        options={UNIT_OPTIONS}
        onSelect={(selectedOption) =>
          onChange({
            ...attribute,
            [unitParameter]: selectedOption.id as string,
          })
        }
        disabled={disabled}
      />
    </div>
  );
};

export default TextWithUnit;
