import { SelectOption } from "features/ui/Select";

export const TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR = "groupByAttributeValue";
export const CLAIM_DEALER_ACCESSOR = "dealer";
export const VEHICLE_ACCESSOR = "vehicle";
export const VEHICLE_LAST_KNOWN_DEALER_ACCESSOR = "lastKnownDealer";

// m2m relations always have this (ECUs, Options)
export const MANY_TO_MANY_PRESENT_SELECT_OPTION: SelectOption = {
  id: "present",
  value: "Present",
  label: "Present",
};

export const VALUES_ENDPOINT_LIMIT_DEFAULT = 1000;
