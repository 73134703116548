import { useAttributesContext } from "shared/contexts/GenericAttributesContextWrapper";
import { ContextProviderName } from "shared/contexts/types";
import { SERVICE_PLANS_GENERIC_FILTER } from "shared/filterDefinitions";

import { UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

const useServicePlansSchema = (accessorsToHide: string[] = []): UseSchema => {
  const { attributes } = useAttributesContext(ContextProviderName.ServicePlan);

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(
    SERVICE_PLANS_GENERIC_FILTER,
    attributeMap
  );

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const schema = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    undefined,
    undefined,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  return {
    schema,
    getDisplayLabel,
    attributeMap,
    attributes,
    getSchemaEntry,
    attributeAccessors: Object.keys(attributeMap || {}),
    getSchemaForAccessor,
  };
};
export default useServicePlansSchema;
