import { createContext, useContext } from "react";

import { UserInfo } from "shared/api/info/api";
import { useUserInfo } from "shared/api/info/hooks";
import { GeneralConfigModel } from "shared/api/superadmin/api";
import {
  useAdminPagesConfig,
  useGeneralConfig,
} from "shared/api/superadmin/hooks";

import { DEFAULT_GENERAL_CONFIG } from "pages/SuperAdmin/constants";
import { PagesConfigModel } from "pages/SuperAdmin/PagesConfig/types";

import { ContextWrapComponentProps } from "./types";

interface ConfigContextInterface {
  general: GeneralConfigModel;
  pages: PagesConfigModel;
  userInfo: UserInfo;
  loaded: boolean;
}

const defaultGeneralConfig: GeneralConfigModel = DEFAULT_GENERAL_CONFIG;
const defaultPagesConfig: PagesConfigModel = {};
const defaultUserInfo: UserInfo = {
  isAdmin: false,
  isSuperAdmin: false,
};

const ConfigContext = createContext<ConfigContextInterface>({
  general: defaultGeneralConfig,
  pages: defaultPagesConfig,
  userInfo: defaultUserInfo,
  loaded: false,
});

const ConfigContextWrapper = ({ children }: ContextWrapComponentProps) => {
  const { data: generalConfig } = useGeneralConfig({});
  const { data: pagesConfigArray } = useAdminPagesConfig({});
  const { data: userInfo } = useUserInfo();

  const pagesConfig: PagesConfigModel | undefined = pagesConfigArray?.reduce(
    (acc, { ID, config }) => {
      acc[ID] = config;

      return acc;
    },
    {} as PagesConfigModel
  );

  return (
    <ConfigContext.Provider
      value={{
        general: generalConfig || defaultGeneralConfig,
        pages: pagesConfig || defaultPagesConfig,
        userInfo: userInfo || defaultUserInfo,
        loaded: Boolean(generalConfig) && Boolean(pagesConfig),
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfigContext = (): ConfigContextInterface => {
  const context = useContext(ConfigContext);

  if (!context) {
    throw new Error("useConfigContext must be used within a ConfigContext");
  }

  return context;
};

export default ConfigContextWrapper;
