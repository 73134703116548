import classNames from "classnames";
import { MdLogout as LogoutIcon } from "react-icons/md";
import { useOktaAuth } from "@okta/okta-react";

import { NAV_ITEM_ICON_SIZE } from "features/layout/SidebarNav/utils";

import * as config from "config/config";

import oktaLogout from "./utils";

interface Props {
  menuExpanded: boolean;
}

/**
 * Log the user out based on the tenant config.
 *
 * Make sure that the relevant tenant config app in Okta has
 * Sign-out redirect URIs set to the URL of the app (that's the default)
 * OR if postLogoutRedirectUri is set to also include that!!
 *
 * Notes:
 *  - if we only wanna log out from the app (and not Okta!) we should use oktaAuth.tokenManager.clear();
 *  - otherwise we use await oktaAuth.signOut
 *
 * @see https://developer.okta.com/docs/guides/sign-users-out/react/main/
 */
const Logout = ({ menuExpanded }: Props) => {
  const { oktaAuth } = useOktaAuth();
  const { isIframed } = config.get();

  if (isIframed) return null;

  const handleLogout = async () => {
    // Perform the logout
    await oktaLogout(oktaAuth);
  };

  return (
    <button
      onClick={handleLogout}
      data-testid="viaduct-logout-cta"
      className={classNames(
        "flex w-full items-center space-x-2 font-medium text-sm leading-none md:leading-3",
        {
          "justify-center": !menuExpanded,
        }
      )}
    >
      <div
        className={classNames({
          "pl-4 lg:pl-6": menuExpanded,
          "justify-center": !menuExpanded,
        })}
      >
        <LogoutIcon size={NAV_ITEM_ICON_SIZE} />
      </div>
      {menuExpanded && <div>Logout</div>}
    </button>
  );
};

export default Logout;
