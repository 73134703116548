import { useState } from "react";
import DuckFeedbackButton from "duck/ui/feedback/DuckFeedbackButton";
import {
  deleteFeedback,
  getSavedFeedbackId,
  getSavedFeedbackSentiment,
  submitFeedback,
  updateFeedback,
} from "duck/ui/feedback/utils";
import { toast } from "react-toastify";

interface DuckFeedbackProps {
  runId: string;
}

const DuckFeedback = ({ runId }: DuckFeedbackProps) => {
  const [positive, setPositive] = useState<boolean | null>(() =>
    getSavedFeedbackSentiment(runId)
  );
  const [feedbackId, setFeedbackId] = useState<string | null>(() =>
    getSavedFeedbackId(runId)
  );

  /**
   * Handle a click on a feedback button.
   * @param up Is this the thumbs up button? i.e. positive feedback
   */
  const assignFeedback = async (up: boolean): Promise<void> => {
    const priorPositive = positive;
    setPositive(positive === up ? null : up);

    if (priorPositive === up) {
      if (!feedbackId) {
        toast.error("Unable to retract feedback");
        console.error("Unable to retract feedback due to missing feedbackId");

        return;
      }

      try {
        await deleteFeedback(runId, feedbackId);
        setFeedbackId(null);
        toast.success(`${up ? "👍" : "👎"} feedback sucessfully retracted`);
      } catch (deleteError) {
        toast.error("Unable to retract feedback");
        console.error(deleteError);
      }

      return;
    }

    if (priorPositive === null) {
      try {
        const { id: feedbackId } = await submitFeedback(runId, up);
        setFeedbackId(feedbackId);
        toast.success(`${up ? "👍" : "👎"} feedback sucessfully submitted`);
      } catch (submitError) {
        toast.error("Unable to submit feedback");
        console.error(submitError);
      }

      return;
    }

    if (!feedbackId) {
      toast.error("Unable to update feedback");
      console.error("Unable to update feedback due to missing feedbackId");

      return;
    }

    try {
      await updateFeedback(runId, feedbackId, up);
      toast.success(`Feedback sucessfully changed to ${up ? "👍" : "👎"}`);
    } catch (updateError) {
      toast.error("Unable to update feedback");
      console.error(updateError);
    }
  };

  return (
    <div className="flex mt-2">
      <DuckFeedbackButton
        onClick={() => assignFeedback(true)}
        positive={positive}
        up={true}
      />

      <DuckFeedbackButton
        onClick={() => assignFeedback(false)}
        positive={positive}
        up={false}
      />
    </div>
  );
};

export default DuckFeedback;
