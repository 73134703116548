import { useEffect, useState } from "react";
import {
  Autocomplete,
  AutocompleteValue,
  SelectProps,
  TextField,
} from "@mui/material";

import { TestProps } from "shared/types";

import { SelectOption } from "features/ui/Select";

interface Props<
  T,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
> extends TestProps {
  label?: string;
  options: SelectOption<T>[];
  groupedOptions?: SelectOption<T>[][];
  selectedOption: AutocompleteValue<
    SelectOption<T>,
    Multiple,
    DisableClearable,
    false
  >;
  onSelectedOptionChange: (
    value: AutocompleteValue<SelectOption<T>, Multiple, DisableClearable, false>
  ) => void;
  className?: string;
  width?: number;
  fullWidth?: SelectProps["fullWidth"];
  disabled?: SelectProps["disabled"];
  disableClearable?: DisableClearable;
  placeholder?: string;
  multiple?: Multiple;
  autoFocus?: boolean;
  openOnAutoFocus?: boolean;
}

const DEFAULT_WIDTH = 250;

const HORIZONTAL_LINE = <hr className="m-2" />;

const DropdownWithSearch = <
  T,
  Multiple extends boolean = false,
  DisableClearable extends boolean = false,
>({
  label = "",
  options,
  groupedOptions,
  selectedOption,
  onSelectedOptionChange,
  className = "",
  width = DEFAULT_WIDTH,
  fullWidth = false,
  testId,
  placeholder,
  multiple = false as Multiple,
  autoFocus,
  openOnAutoFocus,
  ...otherProps
}: Props<T, Multiple, DisableClearable>) => {
  const [open, setOpen] = useState(!!autoFocus && openOnAutoFocus);

  useEffect(() => {
    if (autoFocus && openOnAutoFocus) {
      setOpen(true);
    }
  }, [autoFocus, openOnAutoFocus]);

  if (groupedOptions) {
    const optionsWithGroups: SelectOption<T>[] = [];
    groupedOptions.forEach((opList, index) =>
      opList.forEach((op) => {
        optionsWithGroups.push({ ...op, group: index.toString() });
      })
    );
    options = optionsWithGroups;
  }

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Autocomplete
      autoFocus={autoFocus}
      autoHighlight={true}
      multiple={multiple}
      open={open}
      disablePortal={false}
      options={options}
      groupBy={(option) => option.group ?? ""}
      fullWidth={fullWidth}
      sx={fullWidth ? undefined : { width }}
      onOpen={handleOpen}
      onClose={handleClose}
      slotProps={{
        popper: {
          sx: {
            position: "absolute",
            zIndex: 10000,
          },
        },
        paper: {
          sx: {
            maxHeight: "16rem !important",
            overflowY: "auto",
            position: "relative",
          },
        },
        listbox: {
          sx: {
            maxHeight: "16rem !important",
            overflowY: "auto",
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          autoFocus={autoFocus}
          {...params}
          label={label}
          size="small"
          placeholder={placeholder}
          onFocus={(event) => {
            setTimeout(() => {
              event.target.select();
            }, 0);
          }}
        />
      )}
      renderGroup={
        groupedOptions
          ? (params) => (
              <li key={params.key}>
                {params.group !== "0" && HORIZONTAL_LINE}
                <ul>{params.children}</ul>
              </li>
            )
          : undefined
      }
      size="small"
      getOptionLabel={(option) => option.value.toString() || ""}
      className={className}
      value={selectedOption}
      onChange={(_, value) => {
        onSelectedOptionChange(value as any);
      }}
      data-testid={testId}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      {...otherProps}
    />
  );
};

export default DropdownWithSearch;
