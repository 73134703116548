import classNames from "classnames";

import { TestProps } from "shared/types";

interface Row extends TestProps {
  label: string;
  value: string;
  extra?: React.ReactElement;
}

interface Props {
  labelClassName?: string;
  valueClassName?: string;
  data: Row[];
  extraClassName?: string;
}

const InfoTable = ({
  data,
  labelClassName,
  extraClassName,
  valueClassName,
}: Props) => (
  <div className="w-full flex flex-col gap-y-2 text-sm">
    {data.map(({ label, value, extra, testId }, index) => (
      <div
        className="flex gap-x-2 items-center"
        data-testid={testId}
        key={`${testId}-${index}`}
      >
        <div
          className={classNames(
            "text-gray-400 shrink-0 text-right",
            labelClassName
          )}
        >
          {label}
        </div>
        <div className={classNames("w-full", valueClassName)}>{value}</div>
        <div
          className={classNames(
            "shrink-0 flex gap-x-2 items-center",
            extraClassName
          )}
        >
          {extra}
        </div>
      </div>
    ))}
  </div>
);

export default InfoTable;
