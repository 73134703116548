import { addYears, format, subYears } from "date-fns";
import { getValidateFilter } from "duck/graph/nodes/SignalEventAnalyticsAgent/utils";
import { Source } from "duck/graph/nodes/utils";
import { setRouteValue } from "duck/graph/nodes/VinViewAgent/tools/utils";
import { PageHandler } from "duck/graph/PageHandler";
import {
  NonEmptyStringArray,
  StringSetter,
  VinViewTimelineChartOptionStrings,
} from "duck/graph/types";
import { z } from "zod";
import { StructuredTool, tool } from "@langchain/core/tools";

import { API_DATE_FORMAT_W_TIME } from "shared/constants";

import { VinViewTimelineActionIdType } from "pages/utils";
import {
  VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY,
} from "pages/VINView/constants";
import {
  CHART_ACTIONS,
  CHART_OPTIONS_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY,
} from "pages/VINView/Events/constants";

import {
  createChartSettings,
  createDatesFilterParameter,
  createSensorsParameter,
  getParamName,
} from "./utils";

/**
 * Create the goToTimelineTab tool.
 *
 * @param pageHandler
 * @param sensorOptions These are initialized asynchronously so we need to prepared for
 * them to be undefined. If they are undefined the validation that they permit will be
 * skipped.
 * @param timelineChartOptions These are initialized asynchronously so we need to prepared for
 * them to be undefined. If they are undefined the validation that they permit will be
 * skipped.
 * @param selectedTimelineChartOptions
 * @param selectedSensorsAndTriggers
 * @param selectedDateRange
 * @param selectedSignalEventFilters
 * @returns
 */
const getGoToTimelineTabTool = (
  pageHandler: PageHandler,
  setAgentResponse: StringSetter,
  sensorOptions: NonEmptyStringArray | undefined,
  timelineChartOptions: VinViewTimelineChartOptionStrings | undefined,
  selectedTimelineChartOptions: Record<VinViewTimelineActionIdType, string>,
  selectedSensorsAndTriggers: string,
  selectedDateRange: string,
  selectedSignalEventFilters: string
): StructuredTool => {
  const fiveYearsAgo = format(subYears(new Date(), 5), API_DATE_FORMAT_W_TIME);
  const fiveYearsFuture = format(
    addYears(new Date(), 5),
    API_DATE_FORMAT_W_TIME
  );
  const oneYearAgo = format(subYears(new Date(), 1), API_DATE_FORMAT_W_TIME);
  const today = format(new Date(), API_DATE_FORMAT_W_TIME);

  const sensorType = sensorOptions ? z.enum(sensorOptions) : z.string();
  const aggregationType = timelineChartOptions
    ? z.enum(timelineChartOptions.legend)
    : z.string();

  const goToTimelineTabSchema = z.object({
    vin: z.string().describe("The VIN to display. There is no default value."),
    sensors: z
      .array(sensorType)
      .optional()
      .describe(
        "Sensor readings to display in the timeline. Up to 2 can be selected. Default value: empty array."
      ),
    fromDate: z
      .string()
      .optional()
      .describe(
        `The starting date of the timeline date range. Range: ${fiveYearsAgo} to ${fiveYearsFuture}. It must be before the toDate. Default: ${oneYearAgo}.`
      ),
    toDate: z
      .string()
      .optional()
      .describe(
        `The ending date of the timeline date range. Range: ${fiveYearsAgo} to ${fiveYearsFuture}. It must be after the fromDate. Default: ${today}.`
      ),
    signalEventFilter: z
      .string()
      .optional()
      .describe("Filter to apply to the signal events shown on the timeline."),
    aggregation: aggregationType
      .optional()
      .describe(
        `The time aggregation to apply to the sensor readings on the timeline. Default value: "none".`
      ),
  });

  type GoToTimelineTab = z.infer<typeof goToTimelineTabSchema>;

  const goToTimelineTab = async ({
    vin,
    sensors,
    fromDate,
    toDate,
    signalEventFilter,
    aggregation,
  }: GoToTimelineTab) => {
    console.debug(
      `[VIN]: Navigating to Timeline tab with ${JSON.stringify({ vin, sensors, fromDate, toDate, signalEventFilter, aggregation })}`
    );

    await setRouteValue(vin, pageHandler);

    pageHandler.navigateToTab(VIN_VIEW_EVENTS_TIMELINE_TAB_KEY);

    const agentResponse = [
      `- Navigate to the Timeline tab for vin ${vin} with the following settings:`,
    ];

    const filterQueryString =
      signalEventFilter ?? selectedSignalEventFilters ?? "";
    if (filterQueryString !== "") {
      await getValidateFilter(Source.SIGNAL_EVENT_OCCURRENCE)(
        filterQueryString
      );
    }

    const signalEventFilterKey = getParamName(
      VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY(vin)
    );
    pageHandler.updateFilter(filterQueryString, signalEventFilterKey);

    if (filterQueryString !== selectedSignalEventFilters) {
      if (filterQueryString === "") {
        agentResponse.push(`  - Clear the signal event filter`);
      } else {
        agentResponse.push(
          `  - Filter the signal events by ${filterQueryString}`
        );
      }
    }

    const sensorFilterKey = getParamName(
      VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY(vin)
    );
    const sensorFilter = createSensorsParameter(
      sensors,
      selectedSensorsAndTriggers,
      sensorOptions
    );
    if (sensorFilter) {
      pageHandler.updateFilter(sensorFilter, sensorFilterKey);
      agentResponse.push(`  - Filter the sensors by ${sensorFilter}`);
    }

    const dateFilterKey = getParamName(
      VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY(vin)
    );
    const datesFilter = createDatesFilterParameter(
      fromDate,
      toDate,
      selectedDateRange
    );
    if (datesFilter) {
      pageHandler.updateFilter(datesFilter, dateFilterKey);
      if (datesFilter !== selectedDateRange) {
        if (datesFilter === "") {
          agentResponse.push(`  - Clear the date filter`);
        } else {
          agentResponse.push(`  - Filter the dates by ${datesFilter}`);
        }
      }
    }

    const tabChartSettings = createChartSettings(
      aggregation,
      selectedTimelineChartOptions,
      timelineChartOptions
    );
    if (tabChartSettings) {
      pageHandler.updateTabChartSettings(
        VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY(vin),
        VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
        tabChartSettings
      );

      const optionId = tabChartSettings[CHART_OPTIONS_KEY][0].optionId;
      const optionLabel = (CHART_ACTIONS[0].options ?? []).find(
        ({ id }) => id === optionId
      );

      agentResponse.push(
        `  - Set the aggregation to ${optionLabel ? optionLabel.value : optionId}`
      );
    }

    setAgentResponse(agentResponse.join("\n"));

    return "queued navigation to Timeline tab";
  };

  return tool(goToTimelineTab, {
    name: "queueGoToTimelineTab",
    description: `Navigate to the Timeline tab to view a timeline of sensor readings and signal events for the selected vehicle.
Use this tool when the user wants to:
- View the Timeline tab
- Filter sensor readings by a date range
- Filter signal events by signal event attributes
- Clear a signal event filter
- Select up to two sensor readings to display
- Clear sensor readings to display
- Aggregate sensor readings by daily or hourly time intervals
- Clear the time-interval aggregation of sensor readings`,
    schema: goToTimelineTabSchema,
  });
};

export default getGoToTimelineTabTool;
