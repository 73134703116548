import { useState } from "react";
import { TextField } from "@mui/material";

import Button from "features/ui/Button";

const URL_LABEL = "Enter URL";

interface Props {
  onConfirm: (url: string) => void;
}

export const RichTextEditorLinkForm = ({ onConfirm }: Props) => {
  const [link, setLink] = useState<string>("");

  const handleConfirm = () => {
    onConfirm(link);
  };

  return (
    <form
      className="p-2 flex flex-row gap-2"
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <TextField
        autoFocus
        size="small"
        label={URL_LABEL}
        value={link}
        onChange={({ target: { value } }) => setLink(value)}
      />
      <Button type="submit" onClick={handleConfirm}>
        Confirm
      </Button>
    </form>
  );
};

export default RichTextEditorLinkForm;
