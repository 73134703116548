import { CSSProperties } from "react";
import { ReactComponent as TwinkleIcon } from "duck/ui/twinkle.svg";

interface AiTwinkleProps {
  fillColor?: string;
  strokeColor?: string;
}

const AiTwinkle = ({
  fillColor = "#0F62FE",
  strokeColor = "#5D9BE5",
}: AiTwinkleProps) => (
  <div
    style={{ "--fill": fillColor, "--stroke": strokeColor } as CSSProperties}
  >
    <TwinkleIcon />
  </div>
);

export default AiTwinkle;
