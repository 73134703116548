import { useAPI } from "shared/api/hooks";

import {
  getIssueMetricsRegistryRequestURI,
  getSuggestedIssueMetricsRegistryRequestURI,
  IssueMetricsRegistry,
} from "./api";

export const useGetIssueMetricsRegistry = () =>
  useAPI<null, IssueMetricsRegistry[]>(getIssueMetricsRegistryRequestURI, null);

export const useGetSuggestedIssuesMetricsRegistry = () =>
  useAPI<null, IssueMetricsRegistry[]>(
    getSuggestedIssueMetricsRegistryRequestURI,
    null
  );
