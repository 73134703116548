import { deleteUser, User } from "shared/api/admin/api";
import { useListUsers } from "shared/api/admin/hooks";
import { DeleteRequest } from "shared/api/api";

import APIError from "features/ui/APIError";
import DeleteAction from "features/ui/DeleteAction";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Table, { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";
import TableCount from "features/ui/Table/TableCount";

import AddUsers from "./AddUsers";

const PAGE_KEY = "users";
const TITLE = "Users";
const ROWS_PER_PAGE = 20;

const schema: SchemaEntry[] = [
  {
    label: "Email",
    accessor: "email",
    dataType: DataType.STRING,
  },
  {
    label: "Actions",
    accessor: "actions",
    dataType: DataType.JSX,
    sortable: false,
  },
];

const Users = () => {
  let { data, isLoading, error, requestKey: requestKeyList } = useListUsers();

  const handleOnDelete = (args: DeleteRequest) => deleteUser(args);

  const formatRow = (user: User) => {
    const { ID, email } = user;

    return {
      ...user,
      actions: (
        <DeleteAction
          data={{ ID, name: email }}
          entityName="User"
          deleteCallback={handleOnDelete}
          entityRequestKeys={[requestKeyList]}
          iconOnly={true}
        />
      ),
    };
  };

  const formattedData = data?.map(formatRow);

  return (
    <>
      <PageHeaderWrapper title={TITLE}>
        <PageHeaderActionsWrapper>
          <AddUsers />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <div className="flex items-center my-3">
        <TableCount
          extraClasses="ml-auto self-end"
          count={formattedData?.length as number}
          entityName="user"
          isLoading={isLoading}
          error={!!error}
        />
      </div>
      {!error && (
        <Table
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          pageKey={PAGE_KEY}
        />
      )}
      {error && <APIError error={error} />}
    </>
  );
};

export default Users;
