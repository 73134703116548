import { useFlags } from "launchdarkly-react-client-sdk";

import {
  APIErrorResponse,
  APISuccessResponse,
  ExportRequest,
  VehicleAgeTimeline,
} from "shared/api/api";
import { Granularity } from "shared/api/constants";
import { MAX_ROWS_DOWNLOAD_LIMIT, NO_DATA } from "shared/constants";
import { EventTypeEnum } from "shared/types";

import { ByVehicleAgeData } from "pages/types";
import { prepareVehiclesByAgeTableSchemaAndData } from "pages/utils";

import APIError from "features/ui/APIError";
import DownloadAction, { RequestParams } from "features/ui/DownloadAction";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import Table from "features/ui/Table";
import MISColumnHeaders from "features/ui/Table/MISColumnHeaders";

import { NONE_EXPOSURE_ID } from "./constants";

interface Props {
  eventType: EventTypeEnum;
  byVehicleAgeData: ByVehicleAgeData;
  filters: FilterGroupState;
  data: VehicleAgeTimeline[] | undefined;
  error: APIErrorResponse | undefined;
  downloadRequestParams: RequestParams;
  downloadFunc: (request: ExportRequest) => Promise<APISuccessResponse<Blob>>;
  onBadRequest: () => void;
}

const STICKY_FIRST_COLUMN = true;
const TABLE_HEIGHT_PX = 500;

const ByVehicleAgeTable = ({
  eventType,
  byVehicleAgeData,
  filters,
  data,
  error,
  downloadRequestParams,
  downloadFunc,
  onBadRequest,
}: Props) => {
  const { warrantyClaimsCost } = useFlags();

  const {
    byVehicleAgeBirthdayValue,
    byVehicleAgeExposure,
    byVehicleAgeExposureValue,
    granularity,
  } = byVehicleAgeData;

  if (!data) return NO_DATA;

  const {
    data: formattedData,
    schema,
    uniqueExposureValues,
  } = prepareVehiclesByAgeTableSchemaAndData(
    data,
    eventType === EventTypeEnum.CLAIM && warrantyClaimsCost,
    granularity as Granularity,
    byVehicleAgeBirthdayValue,
    eventType,
    byVehicleAgeExposure
  );

  const extraRowHeaders =
    byVehicleAgeExposure !== NONE_EXPOSURE_ID ? (
      <MISColumnHeaders
        stickyFirstColumn={STICKY_FIRST_COLUMN}
        label={byVehicleAgeExposureValue}
        uniqueExposureValues={uniqueExposureValues}
        eventType={eventType}
      />
    ) : undefined;
  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <>
      {!error && (
        <div className="flex justify-end mt-5">
          <DownloadAction
            disabled={downloadDisabled}
            downloadFunc={downloadFunc}
            fileName={`${eventType}-by-vehicle-age`}
            requestParams={{
              ...downloadRequestParams,
              limit: MAX_ROWS_DOWNLOAD_LIMIT,
            }}
            entityName={eventType}
            filters={filters}
          />
        </div>
      )}
      <div>
        {!error && (
          <Table
            data={formattedData}
            schema={schema}
            stickyFirstColumn={STICKY_FIRST_COLUMN}
            dense
            scrollHeight={TABLE_HEIGHT_PX}
            extraHeaderRowContent={extraRowHeaders}
          />
        )}
        {error && <APIError error={error} onBadRequest={onBadRequest} />}
        {!error && !formattedData?.length && (
          <div className="py-4 text-gray-400 text-sm">No results.</div>
        )}
      </div>
    </>
  );
};

export default ByVehicleAgeTable;
