import { MdClear, MdSearch } from "react-icons/md";
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

interface DuckDrillAttributeFilterProps {
  filter: string;
  setFilter: (value: string) => void;
}

const DuckDrillAttributeFilter = ({
  filter,
  setFilter,
}: DuckDrillAttributeFilterProps) => (
  <FormControl fullWidth={true} variant="outlined" sx={{ mb: 1, mt: 0.5 }}>
    <InputLabel
      htmlFor="drill-attribute-filter"
      sx={{
        mt: "-2px",
        lineHeight: "0.75rem",
        "&.MuiInputLabel-shrink": {
          mt: 0.5,
        },
      }}
    >
      Search
    </InputLabel>
    <OutlinedInput
      id="drill-attribute-filter"
      type="text"
      size="small"
      label="Search"
      value={filter}
      onChange={(e) => setFilter(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          {filter === "" ? null : (
            <IconButton onClick={() => setFilter("")}>
              <MdClear size={18} />
            </IconButton>
          )}
          <MdSearch size={24} />
        </InputAdornment>
      }
    />
  </FormControl>
);

export default DuckDrillAttributeFilter;
