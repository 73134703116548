import { useEffect, useState } from "react";
import { Autocomplete, TextField } from "@mui/material";

import { useDebounce } from "shared/hooks";

import { ObjectEventType } from "pages/AlertDefinitions/constants";
import { AlertDefinitionState } from "pages/AlertDefinitions/types";

import FormSection from "features/ui/FormSection";

import AlertDefinitionObjectAlertLink from "./AlertDefinitionObjectAlertLink";
import {
  HEADER_TEXT,
  MIN_CHARS_TO_START_SEARCH,
  NO_OPTIONS_TEXT,
  SEARCH_INPUT_DELAY,
} from "./constants";
import {
  getObjectEventLabelText,
  getObjectEventText,
  getObjectOptions,
} from "./utils";
import WatchObjectEventTypesSelect from "./WatchObjectEventTypesSelect";

interface Props {
  currentAlertDefinition: AlertDefinitionState;
  isFormDisabled: boolean;
  onDataUpdated: (data: Partial<AlertDefinitionState>) => void;
}

const AlertDefinitionObjectEvent = ({
  currentAlertDefinition,
  onDataUpdated,
  isFormDisabled,
}: Props) => {
  const [selectedValue, setSelectedValue] = useState<{
    id: string;
    value: string;
  } | null>({
    id: currentAlertDefinition.watchedObjectID || "",
    value: currentAlertDefinition.watchedObjectID || "",
  });

  const [searchInput, setSearchInput] = useState(
    currentAlertDefinition.watchedObjectID || ""
  );

  const [options, setOptions] = useState<{ id: string; value: string }[]>([]);
  const debouncedSearchInput = useDebounce(searchInput, SEARCH_INPUT_DELAY);

  const getData = (searchInput: string) => {
    getObjectOptions(currentAlertDefinition.eventType, searchInput).then(
      (opts) => setOptions(opts)
    );
  };

  useEffect(() => {
    if (debouncedSearchInput.length >= MIN_CHARS_TO_START_SEARCH) {
      getData(debouncedSearchInput);

      return;
    }

    setOptions([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchInput, currentAlertDefinition.eventType]);

  const onSelect = (value?: { id: string; value: string } | null) => {
    if (!value) {
      setSelectedValue(null);
      onDataUpdated({ watchedObjectID: undefined });

      return;
    }

    setSelectedValue(value);
    onDataUpdated({ watchedObjectID: value.id });
  };

  return (
    <>
      <FormSection title={getObjectEventText(currentAlertDefinition.eventType)}>
        {!isFormDisabled && (
          <Autocomplete
            className="mt-2"
            disabled={isFormDisabled}
            id="alert-definition-object-event-search"
            data-testid="alert-definition-object-event-search"
            options={options}
            getOptionLabel={(option) => option.value}
            multiple={false}
            onChange={(_, value) => {
              onSelect(value);
            }}
            blurOnSelect={true}
            noOptionsText={NO_OPTIONS_TEXT}
            inputValue={searchInput}
            onInputChange={(_, newInputValue) => {
              setSearchInput(newInputValue);
            }}
            isOptionEqualToValue={(option, value) =>
              option.value === value.value
            }
            value={selectedValue}
            size="small"
            renderInput={(params) => (
              <TextField
                {...params}
                label={getObjectEventLabelText(
                  currentAlertDefinition.eventType
                )}
                variant="outlined"
                slotProps={{
                  htmlInput: params.inputProps,
                }}
              />
            )}
          />
        )}
        {isFormDisabled && (
          <AlertDefinitionObjectAlertLink
            currentAlertDefinition={currentAlertDefinition}
          />
        )}
      </FormSection>
      <FormSection title={HEADER_TEXT}>
        <WatchObjectEventTypesSelect
          type={currentAlertDefinition.eventType as ObjectEventType}
          isFormDisabled={isFormDisabled}
          values={currentAlertDefinition.watchedObjectEventTypes}
          onDataUpdated={onDataUpdated}
        />
      </FormSection>
    </>
  );
};

export default AlertDefinitionObjectEvent;
