import qs from "qs";
import { useNavigate } from "react-router-dom";

import { toTitleCase } from "shared/utils";

import Button from "features/ui/Button";

import { routes } from "services/routes";

const collectionEntityType = "vehicle";
const collectionTabKey = "vehicle-collections";

const CollectionDescriptionTooltip = () => {
  const navigate = useNavigate();

  const titleCaseCollectionType = toTitleCase(collectionEntityType);

  return (
    <div className="py-2 px-3 font-light">
      <div className="mb-3">
        {titleCaseCollectionType} Collections are saved sets of{" "}
        {collectionEntityType}
        s. They allow you to filter by the set of {collectionEntityType}s you
        are looking for. These Collections can be created from{" "}
        {collectionEntityType === "vehicle" && "CSVs or"} a set of filters on
        the {titleCaseCollectionType} page.
      </div>
      <Button
        label="View Collections"
        size="small"
        variant="text"
        className="text-white!"
        onClick={() => {
          navigate({
            pathname: routes.collections,
            search: qs.stringify({
              tab: collectionTabKey,
            }),
          });
        }}
      />
    </div>
  );
};

export default CollectionDescriptionTooltip;
