import {
  useEmailFromJWT,
  useSignalEventOccurrencesFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";

import UseFiltersMenu from "pages/ClaimAnalytics/UseFilters";
import { DEFAULT_ISSUE_DATA } from "pages/shared/constants";
import PageLevelFilters from "pages/shared/PageLevelFilters/PageLevelFilters";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import {
  PENDING_FILTERS_SE_LS_KEY,
  PENDING_FILTERS_VEHICLES_LS_KEY,
  SIGNAL_EVENTS_FILTER_LABEL,
  VEHICLES_FILTER_LABEL,
} from "./constants";

interface Props {
  signalEventsFilterSortState: UseFilterSortState;
  vehiclesFilterSortState: UseFilterSortState;
  defaultSignalEventFilters: FilterGroupState;
  defaultVehicleFilters: FilterGroupState;
}

const SignalEventsAnalyticsFilters = ({
  signalEventsFilterSortState,
  vehiclesFilterSortState,
  defaultSignalEventFilters,
  defaultVehicleFilters,
}: Props) => {
  const myEmail = useEmailFromJWT();

  const signalEventsOccurrencesFiltersSchema =
    useSignalEventOccurrencesFiltersSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const signalEventFilterLabel = getFilterLabel(
    SIGNAL_EVENTS_FILTER_LABEL,
    signalEventsFilterSortState.filters
  );
  const vehiclePopulationFilterLabel = getFilterLabel(
    VEHICLES_FILTER_LABEL,
    vehiclesFilterSortState.filters
  );

  return (
    <PageLevelFilters
      actions={
        <UseFiltersMenu
          issueData={{
            ...DEFAULT_ISSUE_DATA,
            assignee: myEmail,
            atRiskPopulationFilter:
              vehiclesFilterSortState.filtersWithQuickFilters,
            signalEventOccurrencesFilter:
              signalEventsFilterSortState.filtersWithQuickFilters,
          }}
          currentPage="signalEventsAnalytics"
        />
      }
      filtersProps={[
        {
          filterSortState: signalEventsFilterSortState,
          defaultFilters: defaultSignalEventFilters,
          filterSchema: signalEventsOccurrencesFiltersSchema,
          filterTitle: SIGNAL_EVENTS_FILTER_LABEL,
          filterLabel: signalEventFilterLabel,
          pendingFilterKey: PENDING_FILTERS_SE_LS_KEY,
          entity: "SignalEvents",
          testId: "signal-events",
          quickFilterLabel: "Signal Event Quick Filters",
        },
        {
          filterSortState: vehiclesFilterSortState,
          defaultFilters: defaultVehicleFilters,
          filterSchema: vehiclesFiltersSchema,
          filterTitle: VEHICLES_FILTER_LABEL,
          filterLabel: vehiclePopulationFilterLabel,
          pendingFilterKey: PENDING_FILTERS_VEHICLES_LS_KEY,
          entity: "Vehicles",
          testId: "vehicles",
          quickFilterLabel: "Vehicles Quick Filters",
        },
      ]}
    />
  );
};

export default SignalEventsAnalyticsFilters;
