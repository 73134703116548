export enum DuckMessageFormat {
  TEXT = "text",
  FILTER = "filter",
  SORT = "sort",
  CHART_OPTIONS = "chart_options",
}

export enum DuckMessageAuthor {
  HUMAN = "human",
  AGENT = "agent",
}
