import { useEffect, useState } from "react";

import { AlertEventType } from "shared/api/alertDefinitions/api";
import { capitalizeFirstLetter } from "shared/utils";

import {
  ALERT_EVENT_TYPE_OPTIONS,
  OBJECT_TO_WATCHED_EVENTS_TYPE_MAP,
  ObjectEventType,
  WatchedObjectEventType,
} from "pages/AlertDefinitions/constants";
import { AlertDefinitionState } from "pages/AlertDefinitions/types";

import Checkbox from "features/ui/Checkbox";

interface Props {
  type: ObjectEventType;
  values: AlertDefinitionState["watchedObjectEventTypes"];
  isFormDisabled: boolean;
  onDataUpdated: (data: Partial<AlertDefinitionState>) => void;
}

const getEventTypeOptions = (type: ObjectEventType): WatchedObjectEventType[] =>
  OBJECT_TO_WATCHED_EVENTS_TYPE_MAP[type];

const getInitialState = (
  alertEventType: ObjectEventType,
  values: AlertDefinitionState["watchedObjectEventTypes"]
) => {
  const types = getEventTypeOptions(alertEventType);

  return types.reduce(
    (acc, type) => {
      acc[type] = values?.includes(type) || false;

      return acc;
    },
    {} as Record<WatchedObjectEventType, boolean>
  );
};

const WatchObjectEventTypesSelect = ({
  values,
  type,
  onDataUpdated,
  isFormDisabled,
}: Props) => {
  const [state, setState] = useState(getInitialState(type, values));

  useEffect(() => {
    setState(getInitialState(type, values));
  }, [type, values]);

  const onChange = (eventType: WatchedObjectEventType) => {
    state[eventType] = !state[eventType];
    setState({ ...state });

    const payload = Object.entries(state)
      .filter(([, value]) => value)
      .map(([key]) => key);

    onDataUpdated({
      watchedObjectEventTypes: payload as AlertEventType[],
    });
  };

  return (
    <div className="flex flex-row flex-wrap gap-4">
      {Object.entries(state).map(([key, value]) => (
        <Checkbox
          disabled={isFormDisabled}
          key={key}
          checked={value}
          onChange={() => onChange(key as WatchedObjectEventType)}
          label={
            (ALERT_EVENT_TYPE_OPTIONS.find((option) => option.id === key)
              ?.value as string) || capitalizeFirstLetter(key)
          }
        ></Checkbox>
      ))}
    </div>
  );
};

export default WatchObjectEventTypesSelect;
