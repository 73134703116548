import { KeyboardEvent } from "react";

import { removeNonDigitCharacters } from "shared/utils";

import {
  DownsamplingOptions,
  UNIT_OPTIONS,
} from "pages/CustomSignalEvents/constants";
import { TimeUnit } from "pages/CustomSignalEvents/types";

import DropdownSelect from "features/ui/DropdownSelect";
import { onlyAllowPositiveIntegersOnKeyDown } from "features/ui/Filters/utils";
import FormSection from "features/ui/FormSection";
import Input from "features/ui/Input";
import { InputProps } from "features/ui/Input/Input";
import RadioButtonGroup, {
  RadioSelectOption,
} from "features/ui/RadioButtonGroup";

export interface Downsampling {
  option: string;
  secondsBefore: number;
  beforeUnit?: TimeUnit;
  secondsAfter: number;
  afterUnit?: TimeUnit;
  occurrences: number;
}

interface DownsamplingInputProps {
  currentValue: Downsampling;
  onChange: (value: Downsampling) => void;
  disabled?: boolean;
  className?: string;
}

const DownsamplingInput = ({
  currentValue,
  onChange,
  disabled,
  className,
}: DownsamplingInputProps) => {
  const onKeyDown = (event: KeyboardEvent) =>
    onlyAllowPositiveIntegersOnKeyDown(event);

  const onChangeFilter = (input: string) => removeNonDigitCharacters(input);

  const sharedProps: Partial<InputProps> = {
    fullWidth: false,
    className: "w-20",
    onKeyDown,
    min: 0,
  };

  const downSamplingOptions: RadioSelectOption[] = [
    {
      id: DownsamplingOptions.NONE,
      value: "Do not downsample",
    },
    {
      id: DownsamplingOptions.REST_PERIOD,
      value:
        "Downsample by keeping lonely events and discarding tightly packed events",
      additionalContent: (
        <div className="flex flex-inline space-x-2 text-gray-600 text-sm leading-9">
          <span>
            Keep events which do not have any neighboring occurrence within
          </span>
          <Input
            testId="seconds-before-input"
            value={currentValue.secondsBefore}
            onChange={({ target: { value } }) =>
              onInputChange("secondsBefore", onChangeFilter(value))
            }
            disabled={currentValue.option !== DownsamplingOptions.REST_PERIOD}
            {...sharedProps}
          />
          <DropdownSelect
            label={
              (UNIT_OPTIONS.find(({ id }) => id === currentValue.beforeUnit)
                ?.value as string) || (UNIT_OPTIONS[0].value as string)
            }
            options={UNIT_OPTIONS}
            onSelect={(selectedOption) =>
              onValueChange("beforeUnit", selectedOption.id as string)
            }
            disabled={disabled}
          />
          <span>before and</span>
          <Input
            testId="seconds-after-input"
            value={currentValue.secondsAfter}
            onChange={({ target: { value } }) =>
              onInputChange("secondsAfter", onChangeFilter(value))
            }
            disabled={currentValue.option !== DownsamplingOptions.REST_PERIOD}
            {...sharedProps}
          />
          <DropdownSelect
            label={
              (UNIT_OPTIONS.find(({ id }) => id === currentValue.afterUnit)
                ?.value as string) || (UNIT_OPTIONS[0].value as string)
            }
            options={UNIT_OPTIONS}
            onSelect={(selectedOption) =>
              onValueChange("afterUnit", selectedOption.id as string)
            }
            disabled={disabled}
          />
          <span>after</span>
        </div>
      ),
    },
    {
      id: DownsamplingOptions.OCCURS_AT_LEAST,
      value:
        "Downsample by keeping tightly packed events and discarding lonely events",
      additionalContent: (
        <div className="flex flex-inline space-x-2 text-gray-600 text-sm leading-9">
          <span>Keep events which have at least</span>
          <Input
            value={currentValue.occurrences}
            onChange={({ target: { value } }) =>
              onInputChange("occurrences", onChangeFilter(value))
            }
            disabled={
              currentValue.option !== DownsamplingOptions.OCCURS_AT_LEAST
            }
            {...sharedProps}
          />
          <span>neighboring events within</span>
          <Input
            value={currentValue.secondsBefore}
            onChange={({ target: { value } }) =>
              onInputChange("secondsBefore", onChangeFilter(value))
            }
            disabled={
              currentValue.option !== DownsamplingOptions.OCCURS_AT_LEAST
            }
            {...sharedProps}
          />
          <DropdownSelect
            label={
              (UNIT_OPTIONS.find(({ id }) => id === currentValue.beforeUnit)
                ?.value as string) || (UNIT_OPTIONS[0].value as string)
            }
            options={UNIT_OPTIONS}
            onSelect={(selectedOption) =>
              onValueChange("beforeUnit", selectedOption.id as string)
            }
            disabled={disabled}
          />
          <span>before and</span>
          <Input
            value={currentValue.secondsAfter}
            onChange={({ target: { value } }) =>
              onInputChange("secondsAfter", onChangeFilter(value))
            }
            disabled={
              currentValue.option !== DownsamplingOptions.OCCURS_AT_LEAST
            }
            {...sharedProps}
          />
          <DropdownSelect
            label={
              (UNIT_OPTIONS.find(({ id }) => id === currentValue.afterUnit)
                ?.value as string) || (UNIT_OPTIONS[0].value as string)
            }
            options={UNIT_OPTIONS}
            onSelect={(selectedOption) =>
              onValueChange("afterUnit", selectedOption.id as string)
            }
            disabled={disabled}
          />
          <span>after</span>
        </div>
      ),
    },
  ];

  const handleOptionChanged = (option: string) => {
    onChange({ ...currentValue, option });
  };

  const onInputChange = (key: string, value: string) => {
    onChange({ ...currentValue, [key]: Number(value) });
  };

  const onValueChange = (key: string, value: string) => {
    onChange({ ...currentValue, [key]: value });
  };

  return (
    <FormSection title="Downsampling" className={className}>
      <RadioButtonGroup
        options={downSamplingOptions}
        onSelect={handleOptionChanged}
        selected={currentValue.option}
        disabled={disabled}
      />
    </FormSection>
  );
};

export default DownsamplingInput;
