import { getFetcher } from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { NOTIFICATIONS_ROUTE } from "shared/constants";

interface CheckNotificationsResponse {
  unseen: boolean;
  alertsCount: number;
  notificationsCount: number;
}

interface NotificationIdsRequest {
  IDs: string[];
}

const setAsSeenRequestURI = (): string =>
  client.getUri({
    method: "POST",
    url: createURL([NOTIFICATIONS_ROUTE, "seen"]),
  });

export const setNotificationsAsSeen = (args: NotificationIdsRequest) =>
  client.post(setAsSeenRequestURI(), args);

const clearNotificationsRequestURI = (): string =>
  client.getUri({
    method: "POST",
    url: createURL([NOTIFICATIONS_ROUTE, "clear"]),
  });

export const clearNotifications = (args: NotificationIdsRequest) =>
  client.post(clearNotificationsRequestURI(), args);

const checkForNewNotificationsRequestURI = (): string =>
  client.getUri({
    method: "GET",
    url: createURL([NOTIFICATIONS_ROUTE, "unseen"]),
  });

export const checkForNewNotifications = () =>
  getFetcher<CheckNotificationsResponse>(checkForNewNotificationsRequestURI());
