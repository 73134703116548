import { useEffect, useState } from "react";

import { useDebounce } from "shared/hooks";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";

import { SearchResource, useSearchConfig } from "./searchConfig";

const SEARCH_INPUT_DELAY = 300;

interface Props {
  searchInput: string;
}

const getInitialDataRecord = (searchConfig: SearchResource[]) =>
  searchConfig.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.type]: [],
    }),
    {}
  );

const getResourceFilter = (config: SearchResource, searchInput: string) => {
  const filter: FilterGroupState = {
    type: "group",
    id: "filter",
    anyAll: "any",
    children: config.searchFields.map((field) => ({
      type: "row",
      id: field.fieldName,
      attribute: field.fieldName,
      operator: field.filterOperator,
      values: [searchInput],
    })),
  };

  return getFiltersQuery(filter);
};

export const useGlobalSearch = ({ searchInput }: Props) => {
  const debouncedSearchInput = useDebounce(searchInput, SEARCH_INPUT_DELAY);
  const searchConfig = useSearchConfig();

  const [data, setData] = useState<Record<string, any[]>>(
    getInitialDataRecord(searchConfig)
  );
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (debouncedSearchInput === "") {
      setData(getInitialDataRecord(searchConfig));

      return;
    }

    setLoading(true);
    const promises = searchConfig.map(async (config) => {
      const filter = getResourceFilter(config, debouncedSearchInput);
      const result = await config.loader({
        filter,
        limit: 5,
        sort: config.orderBy,
      });
      data[config.type] = result.data;
      setData({ ...data });
    });

    Promise.all(promises)
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchInput]);

  return {
    data,
    loading,
    debouncedSearchInput,
  };
};
