import React from "react";
import Skeleton from "react-loading-skeleton";

import "react-loading-skeleton/dist/skeleton.css";

import { formatDate, formatNumber, formatPercent } from "shared/utils";

import InfoIcon from "features/ui/Icons/Info";
import { getValueByAccessor } from "features/ui/Table/utils";

import {
  InfoDataMap,
  InfoDataMapComponent,
  InfoDataMapType,
  VINViewVehicle,
} from "./types";

interface Props {
  title: string;
  dataMap: InfoDataMap[];
  data?: VINViewVehicle;
  whitelist?: string[];
}

interface InfoProps {
  label: string;
  description?: string;
  value: string | number | JSX.Element | null;
  suffix?: string;
  id: string;
}

// using custom type to avoid and issue within getDisplayValue() assuming data[key] can be of type Predictions as well
// (we are not showing Predictions in here)
type DisplayValue = null | number | string;

const Info = ({ label, description, value, suffix = "", id }: InfoProps) => {
  if (!value) return null;

  return (
    <div>
      <div className="text-gray-500 text-sm">
        {label}
        {description && (
          <span className="ml-1">
            <InfoIcon text={description} size="sm" />
          </span>
        )}
      </div>
      <div data-testid={`vehicle-${id}`}>
        {value} {suffix}
      </div>
    </div>
  );
};

const getDisplayValue = (
  value?: DisplayValue,
  type?: InfoDataMapType,
  Component?: InfoDataMapComponent
): string | JSX.Element | null => {
  if (!value) return null;

  if (Array.isArray(value) && value.length < 1) return null;

  if (type === "number") return formatNumber(Number(value), 2);

  if (type === "date") return formatDate(value.toString(), undefined, true);

  if (type === "percent") return formatPercent(Number(value));

  if (type === "jsx" && Component) {
    return <Component value={value} />;
  }

  return value.toString();
};

const InfoCard = ({ title, data, dataMap, whitelist }: Props) => (
  <div className="px-4 py-2 border rounded-sm shadow-sm">
    <h3 className="text-lg text-metabase-blue">{title}</h3>
    <div className="flex flex-row space-x-4">
      {dataMap
        .filter(
          (infoDataMap) =>
            whitelist?.includes(infoDataMap.key) ||
            whitelist?.includes(infoDataMap.whitelistKey || "")
        )
        .map(
          (d) =>
            (data && (
              <Info
                {...d}
                key={`${title}-${d.key}`}
                label={d.label}
                value={getDisplayValue(
                  getValueByAccessor(d.key, data) as DisplayValue,
                  d.type,
                  d.Component
                )}
                id={d.key}
              />
            )) || (
              <div key={`${title}-${d.key}`} className="flex flex-col">
                <Skeleton width={75} height={10} />
                <Skeleton width={75} />
              </div>
            )
        )}
    </div>
  </div>
);

export default InfoCard;
