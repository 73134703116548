import { useFlags } from "launchdarkly-react-client-sdk";

import { useConfigContext } from "shared/contexts/ConfigContext";

import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import DeveloperPortal from "./DeveloperPortal";
import HelpArticles from "./HelpArticles";
import ReleaseNotes from "./ReleaseNotes";

const DOCS_DEVELOPER_PORTAL_TAB_KEY = "developer-portal";
const DOCS_HELP_ARTICLES = "help-articles";
export const DOCS_RELEASE_NOTES_TAB_KEY = "release-notes";

const Docs = () => {
  const { developerPortal } = useFlags();
  const {
    pages: { releaseNotes },
  } = useConfigContext();

  const tabs: (Tab | undefined | boolean)[] = [
    {
      key: DOCS_HELP_ARTICLES,
      title: "Help Articles",
      content: <HelpArticles />,
    },
    developerPortal && {
      key: DOCS_DEVELOPER_PORTAL_TAB_KEY,
      title: "Developer Portal",
      content: <DeveloperPortal />,
    },
    releaseNotes && {
      key: DOCS_RELEASE_NOTES_TAB_KEY,
      title: "Release Notes",
      content: <ReleaseNotes />,
    },
  ];

  const filteredTabs = tabs.filter(Boolean) as Tab[];

  return (
    <div className="pt-4">
      <StatefulTabs tabs={filteredTabs} />
    </div>
  );
};

export default Docs;
