import { useConfigContext } from "shared/contexts/ConfigContext";
import { VEHICLES_COLLECTION_FILTER } from "shared/filterDefinitions";
import { useTenantServiceRecordName } from "shared/utils";

import SR from "pages/VINView/ServiceRecords";

import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

const GLOBAL_FILTERS = [VEHICLES_COLLECTION_FILTER];

const ServiceRecords = () => {
  const {
    pages: { collections },
  } = useConfigContext();

  return (
    <>
      <PageHeaderWrapper title={useTenantServiceRecordName()} />
      <SR
        pageKey="service_records_feed"
        staticFilters={[]}
        globalFilters={collections ? GLOBAL_FILTERS : undefined}
      />
    </>
  );
};

export default ServiceRecords;
