import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { MdClose as CloseIcon } from "react-icons/md";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";

import {
  AlertDefinitionPostRequestData,
  newAlertDefinition,
} from "shared/api/alertDefinitions/api";
import { useEmailFromJWT } from "shared/hooks";

import {
  FrequencyOptions,
  OBJECT_TO_WATCHED_EVENTS_TYPE_MAP,
  ObjectEventType,
} from "pages/AlertDefinitions/constants";

import Button from "features/ui/Button";
import Error from "features/ui/Error";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import Input from "features/ui/Input";

import {
  ALERT_NAME_REQUIRED_TEXT,
  CANCEL_TEXT,
  CREATE_ALERT_ERROR_TEXT,
  CREATE_ALERT_FILTER_NOT_SET_TEXT,
  CREATE_ALERT_SUCCESS_TEXT,
  EVENT_TYPE_LABELS,
  NEW_ALERT_HEADER_TEXT,
  SAVE_TEXT,
} from "./constants";
import WatchlistAlertRadioForm from "./WatchlistAlertRadioForm";

interface Props {
  eventFilter?: FilterGroupState;
  eventType: string;
  objectId?: string;
  name?: string;
  onClose: () => void;
}

const useCreateNewAlert = (
  eventType: string,
  eventFilter?: FilterGroupState,
  objectId?: string,
  name?: string
): AlertDefinitionPostRequestData => {
  const email = useEmailFromJWT();
  let alert: AlertDefinitionPostRequestData = {
    name: name ?? "",
    description: "",
    vehiclesFilter: null,
    frequency: FrequencyOptions.ASAP,
    emails: [email],
    groupIds: [],
    eventType,
    inAppAlerts: true,
    emailAlerts: false,
    eventFilter: null,
  };
  if (objectId && !eventFilter) {
    alert = {
      ...alert,
      watchedObjectEventTypes:
        OBJECT_TO_WATCHED_EVENTS_TYPE_MAP[eventType as ObjectEventType],
      watchedObjectID: objectId,
    };

    return alert;
  }

  const filterQuery = getFiltersQuery(eventFilter);
  alert = { ...alert, eventFilter: filterQuery };

  return alert;
};

const WatchlistCreateAlertForm = ({
  eventType,
  eventFilter,
  objectId,
  name,
  onClose,
}: Props) => {
  const alert = useCreateNewAlert(eventType, eventFilter, objectId, name);
  const [form, setForm] = useState<AlertDefinitionPostRequestData>(alert);
  const [nameError, setNameError] = useState<string | null>(null);
  const elementRef = useRef<HTMLInputElement>(null);

  // focus on the title input on render and select text
  useEffect(() => {
    const { current } = elementRef;
    if (current != null) {
      current.focus();
    }
  }, []);

  if (!form) {
    return <Error></Error>;
  }

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value.length === 0) {
      setNameError(ALERT_NAME_REQUIRED_TEXT);
    } else {
      setNameError(null);
    }

    form.name = value;
    setForm({ ...form });
  };

  const handleFrequencyOrTypeChange = (
    frequency: string,
    inAppAlerts: boolean,
    emailAlerts: boolean
  ) => {
    form.frequency = frequency;
    form.emailAlerts = emailAlerts;
    form.inAppAlerts = inAppAlerts;
    // todo: handle the alert notifications type -> form.type = type;
    setForm({ ...form });
  };

  const onSave = () => {
    if (form.name.length === 0) {
      setNameError(ALERT_NAME_REQUIRED_TEXT);

      return;
    }

    if (form.eventFilter != null && form.eventFilter.length === 0) {
      setNameError(CREATE_ALERT_FILTER_NOT_SET_TEXT);

      return;
    }

    // description is required
    form.description = form.name;
    setForm({ ...form });
    newAlertDefinition(form)
      .then(() => {
        toast.success(CREATE_ALERT_SUCCESS_TEXT);
        onClose();
      })
      .catch(() => {
        toast.error(CREATE_ALERT_ERROR_TEXT);
        onClose();
      });
  };

  return (
    <div className="flex flex-col gap-2 min-w-80">
      <div className="flex flex-row justify-between items-center gap-4">
        <div className="flex flex-col w-full gap-2">
          <div className="flex flex-row text-xs gap-2 items-center justify-between">
            <span className="text-lg font-semibold">
              {NEW_ALERT_HEADER_TEXT}
            </span>

            <IconButton
              size="small"
              onClick={onClose}
              data-testid="show-filter-summary"
              className={classNames("pl-0 text-gray-400! hover:text-gray-500!")}
            >
              <CloseIcon size={16} />
            </IconButton>
          </div>
          <span
            className="text-gray-400 text-xs overflow-hidden text-ellipsis"
            title={EVENT_TYPE_LABELS[form.eventType]}
          >
            {EVENT_TYPE_LABELS[form.eventType]}
          </span>
          <Input
            type="text"
            label="Alert name"
            value={form.name}
            error={Boolean(nameError)}
            helperText={nameError}
            onChange={handleNameChange}
            testId="watchlist-alert-name"
            inputRef={elementRef}
            tabIndex={1}
            sx={{
              ".MuiInputBase-input": {
                fontSize: "0.875rem",
                lineHeight: "1.25rem",
              },
            }}
          />
        </div>
      </div>

      <WatchlistAlertRadioForm
        frequency={form.frequency as FrequencyOptions}
        inAppAlerts={form.inAppAlerts}
        emailAlerts={form.emailAlerts}
        onChange={handleFrequencyOrTypeChange}
      ></WatchlistAlertRadioForm>

      <div className="flex flex-row gap-4 justify-center mt-4">
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={onSave}
        >
          {SAVE_TEXT}
        </Button>
        <Button
          size="small"
          variant="outlined"
          color="secondary"
          onClick={onClose}
        >
          {CANCEL_TEXT}
        </Button>
      </div>
    </div>
  );
};

export default WatchlistCreateAlertForm;
