import { useEffect, useState } from "react";
import {
  AreaChart as AC,
  Area,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  XAxisProps,
  YAxis,
  YAxisProps,
} from "recharts";
import { DataKey } from "recharts/types/util/types";

import { areArraysEqual } from "shared/utils";

import {
  AXIS_TEXT_COLOR,
  AXIS_TOOLTIP_FONT_SIZE,
  LABEL_COLOR,
  LINE_COLOR,
} from "features/ui/charts/constants";
import { DataElement } from "features/ui/charts/types";

import AreaChartLegend from "./AreaChartLegend";

interface AreaElement {
  key: string;
  label: string;
  color?: string;
  opacity: number;
  stackId: string;
}

interface AreaChartProps {
  data: DataElement[];
  areas: AreaElement[];
  width?: number | string;
  height?: number | string;
  xAxisKey: string;
  xAxisLabel?: string;
  showLegend?: boolean;
  yAxisProps?: YAxisProps;
  xAxisProps?: XAxisProps;
  tooltipProps?: TooltipProps<any, any>;
}

const itemSorter = (payload: any) => {
  switch (payload.dataKey) {
    case "countFailures":
      return 3;
    case "countRepairs":
      return 2;
    case "countTotal":
      return 1;
    default:
      return 3;
  }
};

const AreaChart = ({
  data,
  areas,
  height = 300,
  width = "100%",
  xAxisKey,
  xAxisLabel,
  showLegend = false,
  yAxisProps,
  xAxisProps,
  tooltipProps,
}: AreaChartProps) => {
  const xAxisTicks = Array.from(data.keys()).map((i) => data[i][xAxisKey]);

  const [shownAreaKeys, setShownAreaKeys] = useState(() =>
    areas.map(({ key }) => key)
  );

  useEffect(() => {
    const newAreaKeys = areas.map(({ key }) => key);
    if (!areArraysEqual(shownAreaKeys, newAreaKeys)) {
      setShownAreaKeys(newAreaKeys);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areas]);

  const onLegendClick = (dataKey: DataKey<any> | undefined) => {
    const key = dataKey as string;

    // If clicked on only shown area, show all
    if (shownAreaKeys.length === 1 && shownAreaKeys.includes(key)) {
      setShownAreaKeys(areas.map(({ key }) => key));

      return;
    }

    // if all are shown, and we click on one, show only that one
    if (shownAreaKeys.length === areas.length) {
      setShownAreaKeys([key]);

      return;
    }

    // Otherwise toggle the clicked area
    const newShownKeys = shownAreaKeys.includes(key)
      ? shownAreaKeys.filter((x) => x !== key)
      : [...shownAreaKeys, key];

    setShownAreaKeys(newShownKeys);
  };

  const filteredAreas = areas.filter(({ key }) => shownAreaKeys.includes(key));

  return (
    <ResponsiveContainer width={width} height={height}>
      <AC data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <CartesianGrid stroke="#eee" strokeDasharray="5 5" vertical={false} />
        <YAxis
          scale="sequential"
          width={40}
          tick={{ fill: AXIS_TEXT_COLOR, fontSize: AXIS_TOOLTIP_FONT_SIZE }}
          type="number"
          {...yAxisProps}
        />
        <XAxis
          label={{
            value: xAxisLabel,
            position: "middle",
            dy: 15,
            fill: LABEL_COLOR,
          }}
          height={45}
          tick={{ fill: AXIS_TEXT_COLOR, fontSize: AXIS_TOOLTIP_FONT_SIZE }}
          dataKey={xAxisKey}
          ticks={xAxisTicks}
          minTickGap={10}
          allowDuplicatedCategory={false}
          {...xAxisProps}
        />
        {showLegend && (
          <Legend
            content={
              <AreaChartLegend
                areas={areas}
                shownAreaKeys={shownAreaKeys}
                onLegendClick={onLegendClick}
              />
            }
            align="left"
            verticalAlign="top"
            wrapperStyle={{ top: 0, paddingBottom: 5 }}
          />
        )}
        <Tooltip
          contentStyle={{ fontSize: AXIS_TOOLTIP_FONT_SIZE }}
          labelStyle={{ fontSize: AXIS_TOOLTIP_FONT_SIZE }}
          itemSorter={itemSorter}
          {...tooltipProps}
        />
        {filteredAreas.map(({ key, label, color, opacity, stackId }) => (
          <Area
            key={key}
            type="monotone"
            dataKey={key}
            stroke={color || LINE_COLOR}
            fillOpacity={opacity}
            strokeOpacity={1}
            stackId={stackId}
            fill={color || LINE_COLOR}
            name={label}
          />
        ))}
      </AC>
    </ResponsiveContainer>
  );
};

export default AreaChart;
