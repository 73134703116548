import { SignalEventsTopContributor } from "shared/api/signalEvents/api";

import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";

type YAxisVal = keyof SignalEventsTopContributor;

export const topContributorsChartYAxisOptions: DropdownSelectOption<YAxisVal>[] =
  [
    {
      id: "DPTV",
      value: "DPTV - Distinct VINs",
      label: "DPTV - Distinct VINs",
    },
    {
      id: "IPTV",
      value: "DPTV",
      label: "DPTV",
    },
    {
      id: "numSignalEventOccurrences",
      value: "Total Signal Event Occurrences",
      label: "Total number of Signal Event Occurrences",
    },
  ];
