import Skeleton from "react-loading-skeleton";

import { useLatestSensorsReadings } from "shared/api/sensors/hooks";
import { TireConfiguration } from "shared/api/tires/api";

import APIError from "features/ui/APIError";

// This configuration will come from the API, but for now it lives here.
import { getTireDiagramConfiguration } from "./configuration/configuration";
import { TIRE_HEIGHT, TRUCK_WIDTH } from "./constants";
import Tire from "./Tire";

interface Props {
  vin: string;
  axleConfigurationID?: string | null;
}

const TireDiagramContent = ({ vin, axleConfigurationID }: Props) => {
  const configuration = getTireDiagramConfiguration();
  const {
    data: readings,
    isLoading,
    error,
  } = useLatestSensorsReadings(
    {
      vin,
      sensorReadingsFilter: "sensorID=startswith:tire_",
    },
    // Refresh the data every minute
    { refreshInterval: 60000 }
  );

  if (!axleConfigurationID) {
    return <div>Vehicle is missing axle configuration.</div>;
  }

  if (axleConfigurationID !== configuration.ID) {
    return (
      <div>
        Tire diagram is not available for axle configuration{" "}
        <b>{axleConfigurationID}</b>.
      </div>
    );
  }

  if (isLoading || !readings) {
    return <Skeleton height={TIRE_HEIGHT} width={TRUCK_WIDTH} count={3} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  return (
    <div className="w-[460px] pt-4">
      <Axle>
        <AxleSide>
          <Tire
            id="11"
            configuration={configuration.tires}
            sensorReadings={readings}
          />
        </AxleSide>
        <AxleBar />
        <AxleSide>
          <Tire
            id="14"
            configuration={configuration.tires}
            sensorReadings={readings}
          />
        </AxleSide>
      </Axle>
      <Axle>
        <AxleSide>
          <Tire
            id="21"
            configuration={configuration.tires}
            sensorReadings={readings}
          />
          <AxleBar />
          <Tire
            id="22"
            configuration={configuration.tires}
            sensorReadings={readings}
          />
        </AxleSide>
        <AxleBar />
        <AxleSide>
          <Tire
            id="23"
            configuration={configuration.tires}
            sensorReadings={readings}
          />
          <AxleBar />
          <Tire
            id="24"
            configuration={configuration.tires as TireConfiguration[]}
            sensorReadings={readings}
          />
        </AxleSide>
      </Axle>
      <Axle>
        <AxleSide>
          <Tire
            id="31"
            configuration={configuration.tires}
            sensorReadings={readings}
          />
          <AxleBar />
          <Tire
            id="32"
            configuration={configuration.tires}
            sensorReadings={readings}
          />
        </AxleSide>
        <AxleBar />
        <AxleSide>
          <Tire
            id="33"
            configuration={configuration.tires}
            sensorReadings={readings}
          />
          <AxleBar />
          <Tire
            id="34"
            configuration={configuration.tires}
            sensorReadings={readings}
          />
        </AxleSide>
      </Axle>
    </div>
  );
};

const Axle = ({ children }: { children: React.ReactNode }) => (
  <div className="flex justify-between items-center mb-4">{children}</div>
);

const AxleBar = () => (
  <div className="flex-1 border bg-gray-200 h-4 mb-8 items-center min-w-2"></div>
);

const AxleSide = ({ children }: { children: React.ReactNode }) => (
  <div className="flex items-center">{children}</div>
);

export default TireDiagramContent;
