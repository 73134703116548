import { useEffect, useState } from "react";

import { Inspection } from "shared/api/inspections/api";
import { Repair } from "shared/api/repairs/api";
import { useTenantMileageUnit } from "shared/hooks";
import { camelCaseToTitle } from "shared/utils";

import CardResourceDetails from "pages/shared/CardResourceDetails";

import CardWithJSONData from "features/ui/CardWithJSONData";

import InformationWrapper from "./InformationWrapper";
import { RecordProps } from "./types";
import { getEntityFunc } from "./utils";

type Data = Inspection | Repair;

const GenericDetail = ({ serviceRecord, onClose }: RecordProps) => {
  const getEntity = getEntityFunc(serviceRecord);

  const [entityData, setEntityData] = useState<Data>();
  const mileageUnit = useTenantMileageUnit();

  useEffect(() => {
    if (!getEntity) return;

    getEntity({
      ID: serviceRecord.ID,
      mileageUnit,
    }).then((res) => {
      setEntityData(res.data);
    });
  }, [getEntity, serviceRecord.ID, mileageUnit]);

  return (
    <InformationWrapper
      externalID={serviceRecord.externalID || ""}
      onClose={onClose}
    >
      {entityData && (
        <>
          <div className="mb-8">
            <CardResourceDetails
              eventType={serviceRecord.sourceType}
              data={entityData}
              key="event-details"
              alignment="left"
              removeCardWrapper={true}
            />
          </div>
          <CardWithJSONData
            data={entityData}
            title={`${camelCaseToTitle(serviceRecord.sourceType)} Data`}
            key="event-other-data"
            alignment="left"
            removeCardWrapper={true}
          />
        </>
      )}
    </InformationWrapper>
  );
};

export default GenericDetail;
