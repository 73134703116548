import {
  ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_KEY,
  BY_VEHICLES_AGE_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "pages/constants";
import { FieldDateRange } from "pages/shared/analytics/types";
import {
  addDateRangeToFilterState,
  addDateRangeToQuickFilters,
} from "pages/shared/analytics/utils";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { UseFilterSortState } from "features/ui/Filters/types";
import { clearPendingFiltersForKey } from "features/ui/Filters/utils";
import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import {
  INSPECTION_ANALYTICS_TABS,
  INSPECTIONS_TAB_KEY,
  INSPECTIONS_VEHICLES_PAGE_KEY,
} from "./constants";
import AssociatedSignalEvents from "./tabPages/AssociatedSignalEvents";
import AssociatedVehicles from "./tabPages/AssociatedVehicles";
import ByVehicleAge from "./tabPages/ByVehicleAge";
import Inspections from "./tabPages/Inspections";
import TopContributors from "./tabPages/TopContributors";

export interface InspectionAnalyticsProps {
  inspectionsFiltersFilterSortState: UseFilterSortState;
  vehiclesFiltersFilterSortState?: UseFilterSortState;
}

export interface InspectionsFiltersProps {
  inspectionsFilters?: FilterGroupState;
  vehiclesFilters?: FilterGroupState;
  onBadRequest: () => void;
  onAddDateRangeToVehicleFilters?: (fieldDateRange: FieldDateRange) => void;
}

export interface InspectionAnalyticsTabsProps
  extends InspectionsFiltersProps,
    InspectionAnalyticsProps {
  queryParamsToPersist?: string[];
}

const InspectionAnalyticsTabs = ({
  inspectionsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
  queryParamsToPersist,
  onBadRequest,
}: InspectionAnalyticsTabsProps) => {
  const inspectionsFilters =
    inspectionsFiltersFilterSortState?.filtersWithQuickFilters;
  const vehiclesFilters =
    vehiclesFiltersFilterSortState?.filtersWithQuickFilters;

  const onAddDateRangeToVehicleFilters = (fieldDateRange: FieldDateRange) => {
    const quickFilter = vehiclesFiltersFilterSortState?.quickFilters.find(
      (filter) => filter.attribute === fieldDateRange.fieldName
    );

    if (quickFilter && vehiclesFiltersFilterSortState) {
      vehiclesFiltersFilterSortState.updateQuickFilters(
        addDateRangeToQuickFilters(
          vehiclesFilters ?? DEFAULT_FILTER_BUILDER_STATE,
          vehiclesFiltersFilterSortState.quickFilters,
          fieldDateRange
        )
      );

      return;
    }

    if (!vehiclesFilters) return;

    vehiclesFiltersFilterSortState.updateFilters(
      addDateRangeToFilterState(vehiclesFilters, fieldDateRange)
    );
    clearPendingFiltersForKey(INSPECTIONS_VEHICLES_PAGE_KEY);
  };

  const tabsItems: (Tab | boolean | undefined)[] = [
    {
      key: INSPECTIONS_TAB_KEY,
      title: INSPECTION_ANALYTICS_TABS[INSPECTIONS_TAB_KEY],
      content: (
        <Inspections
          inspectionsFilters={inspectionsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: BY_VEHICLES_AGE_TAB_KEY,
      title: INSPECTION_ANALYTICS_TABS[BY_VEHICLES_AGE_TAB_KEY],
      content: (
        <ByVehicleAge
          inspectionsFilters={inspectionsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
          onAddDateRangeToVehicleFilters={onAddDateRangeToVehicleFilters}
        />
      ),
    },
    {
      key: TOP_CONTRIBUTORS_TAB_KEY,
      title: INSPECTION_ANALYTICS_TABS[TOP_CONTRIBUTORS_TAB_KEY],
      content: (
        <TopContributors
          inspectionsFiltersFilterSortState={inspectionsFiltersFilterSortState}
          vehiclesFiltersFilterSortState={vehiclesFiltersFilterSortState}
        />
      ),
    },
    {
      key: ASSOCIATED_VEHICLES_TAB_KEY,
      title: INSPECTION_ANALYTICS_TABS[ASSOCIATED_VEHICLES_TAB_KEY],
      content: (
        <AssociatedVehicles
          inspectionsFilters={inspectionsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
      title: INSPECTION_ANALYTICS_TABS[ASSOCIATED_SIGNAL_EVENTS_TAB_KEY],
      content: (
        <AssociatedSignalEvents
          inspectionsFiltersFilterSortState={inspectionsFiltersFilterSortState}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return (
    <StatefulTabs tabs={tabs} queryParamsToPersist={queryParamsToPersist} />
  );
};

export default InspectionAnalyticsTabs;
