import { EventTypeEnum } from "shared/types";

import { useClaimsSchema } from "./claimsSchema";
import { useCustomRecordsSchema } from "./customRecordsSchema";
import { useInspectionsSchema } from "./inspectionsSchema";
import { useRepairsSchema } from "./repairsSchema";
import useSensorReadingsSchema from "./sensorReadingsSchema";
import useSignalEventOccurrencesSchema from "./signalEventOccurrencesSchema";

export const USE_RESOURCE_SCHEMA_MAP: Record<
  EventTypeEnum,
  (accessorsToHide?: string[]) => ReturnType<typeof useInspectionsSchema>
> = {
  [EventTypeEnum.CLAIM]: useClaimsSchema,
  [EventTypeEnum.REPAIR]: useRepairsSchema,
  [EventTypeEnum.INSPECTION]: useInspectionsSchema,
  [EventTypeEnum.SIGNAL_EVENT]: useSignalEventOccurrencesSchema,
  [EventTypeEnum.SENSOR_READING]: useSensorReadingsSchema,
  [EventTypeEnum.CUSTOM_RECORD]: useCustomRecordsSchema,
};
