import { NonEmptyStringArray, PageState } from "duck/graph/types";

import * as claimsApi from "shared/api/claims/api";
import * as SEApi from "shared/api/signalEvents/api";

export type BaseTopContributorsRequestArgs =
  | SEApi.SignalEventsTopContributorsRequest
  | claimsApi.ClaimsTopContributorsRequest;

export type BaseTopContributorResponse =
  | SEApi.SignalEventsTopContributor
  | claimsApi.ClaimsTopContributor;

export interface AttributeGroupMetric {
  groupByAttributeValue: string | number | boolean | null;
  metric: {
    value: number;
    label: string;
  };
}

export interface EntropyMetrics {
  entropy: number;
  contrast: number;
  numGroups: number;
}
export interface AttributeEntropy {
  attribute: string;
  attributeUri?: string;
  attributeGroups?: AttributeGroupMetric[];
  metrics: EntropyMetrics;
}

export enum DrillNodeType {
  CLAIMS = "claims",
  SIGNAL_EVENTS = "signalEvents",
}

export interface DrillNodeOptions {
  type: DrillNodeType;
  computeEntropyFn: (
    groupByOptions: NonEmptyStringArray,
    filterArgs: any,
    attributeLabelMapping?: Map<string, string>
  ) => Promise<AttributeEntropy[]>;
  // Extracts the filter query strings from the current state
  extractFilterQueryStrings: (state: PageState) => {
    filterQueryString: string;
    vehiclesFilterQueryString: string;
  };
  // Constructs the filter arguments for the compute entropy function
  constructFilterArgs: (
    filterQueryString: string,
    vehiclesFilterQueryString: string
  ) => any;
  // The node name to use for agent responses
  nodeName: string;
}
