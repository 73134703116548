import useSuggestedIssuesSchema from "shared/schemas/useSuggestedIssuesSchema";

import { SUGGESTED_ISSUES_PENDING_FILTERS_LS_KEY } from "pages/Issues/SuggestedIssuesPage";
import PageLevelFilters from "pages/shared/PageLevelFilters/PageLevelFilters";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

interface Props {
  filterSortState: UseFilterSortState;
  defaultFilters: FilterGroupState;
}

const SUGGESTED_ISSUES_FILTER_LABEL = "Suggested Issue Filters";

const SuggestedIssuesFilters = ({ filterSortState, defaultFilters }: Props) => {
  const { schema } = useSuggestedIssuesSchema();

  const { filters } = filterSortState;

  const suggestedIssueFilterLabel = getFilterLabel(
    SUGGESTED_ISSUES_FILTER_LABEL,
    filters
  );

  return (
    <div className="mb-3">
      <PageLevelFilters
        filtersProps={[
          {
            filterSortState,
            defaultFilters,
            filterSchema: schema,
            filterTitle: SUGGESTED_ISSUES_FILTER_LABEL,
            filterLabel: suggestedIssueFilterLabel,
            pendingFilterKey: SUGGESTED_ISSUES_PENDING_FILTERS_LS_KEY,
            entity: "Suggested Issue",
            testId: "suggested-issues",
            quickFilterLabel: "Suggested Issue Quick Filters",
          },
        ]}
      />
    </div>
  );
};

export default SuggestedIssuesFilters;
