import React from "react";
import { FiTrash2 as DeleteIcon } from "react-icons/fi";
import { MdDragIndicator as DragIcon } from "react-icons/md";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IconButton, TextField } from "@mui/material";

import { OrderedValueState } from "./types";

interface ConfigRowProps {
  sortIndex: number;
  row: OrderedValueState;
  updateValueByID: (id: string, row: OrderedValueState) => void;
  onDelete: (id: string) => void;
}

const ConfigRow = ({
  sortIndex,
  row,
  updateValueByID,
  onDelete,
}: ConfigRowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: row.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const updateValue = (id: string, key: string, value: boolean | string) => {
    updateValueByID(id, { ...row, [key]: value });
  };

  return (
    <div className="flex space-x-2 mb-2 w-full" ref={setNodeRef} style={style}>
      <DragIcon
        {...attributes}
        {...listeners}
        size={22}
        className="cursor-grab hover:text-black select-none shrink-0 mt-2"
        data-testid={`rb-rule-drag-handle-${sortIndex}`}
      />
      <TextField
        label="ID"
        defaultValue={row.ID}
        size="small"
        className="shrink-0 w-96"
        placeholder="Enter ID"
        onChange={(change) => updateValue(row.id, "ID", change.target.value)}
      />
      <TextField
        label="Title"
        defaultValue={row.value}
        size="small"
        className="shrink-0 w-96"
        placeholder="Enter Value"
        onChange={(change) => updateValue(row.id, "value", change.target.value)}
      />
      <IconButton onClick={() => onDelete(row.id)} size="small" className="h-8">
        <DeleteIcon className="text-red-600" />
      </IconButton>
    </div>
  );
};

export default ConfigRow;
