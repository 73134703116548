import { useCallback, useMemo } from "react";
import { MdGroup as GroupIcon } from "react-icons/md";
import { Autocomplete, TextField } from "@mui/material";

import { PermissionEntry } from "shared/api/api";
import { Group } from "shared/api/rbac/api";
import { PermissionID } from "shared/types";

import NoAccessGroupCell from "pages/Groups/NoAccessGroupCell";

import Label from "features/ui/Label";
import { SelectOption } from "features/ui/Select";
import Table, { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";

import PermissionAccess from "./PermissionAccess";
import PermissionActions from "./PermissionActions";

interface GroupFieldProps {
  permission: PermissionEntry;
  availableGroups: SelectOption[];
  allGroups: Group[];
  onUpdateGroup: (id: string, groupID: string) => void;
}

// export needed for tests
export const TITLE = "Group Permissions";

const GroupField = ({
  permission,
  availableGroups,
  allGroups,
  onUpdateGroup,
}: GroupFieldProps) => {
  const { ID, access, originalAccess, groupID } = permission;

  if (!ID) {
    const selectedGroup =
      availableGroups.find((x) => x.id === permission.groupID) || null;

    return (
      <Autocomplete
        renderInput={(params) => (
          <TextField
            {...params}
            label=""
            size="small"
            margin="none"
            variant="outlined"
          />
        )}
        options={availableGroups}
        size="small"
        getOptionLabel={(option) => option.value?.toString()}
        onChange={(_, newValue: SelectOption | null) => {
          if (newValue) {
            onUpdateGroup(ID, newValue.id as string);
          }
        }}
        value={selectedGroup}
        data-testid="group-permission-dropdown"
      />
    );
  }

  const newGroupEntry = ID === groupID;
  const permissionChanged = ID && groupID && access !== originalAccess;

  const groupName = newGroupEntry
    ? allGroups.find((x) => x.ID === groupID)?.name
    : permission.group?.name;

  return (
    <span className="flex items-center">
      <GroupIcon size="2em" className="m-1.5" />
      {Boolean(groupName) && groupName}
      {!Boolean(groupName) && <NoAccessGroupCell showIcon={false} />}
      {newGroupEntry && <span className="ml-2 text-green-500">New</span>}
      {!newGroupEntry && permissionChanged && (
        <span className="ml-2 text-orange-500">Updated</span>
      )}
    </span>
  );
};

interface Props {
  generalAccess: PermissionID;
  groups: Group[];
  canEdit: boolean;
  groupPermissions: PermissionEntry[];
  onUpdateGroup: (id: string, groupID: string) => void;
  onUpdatePermission: (id: string, access: PermissionID) => void;
  onAddPermission: () => void;
  onRemovePermission: (id: string) => void;
}

const GroupPermissionsTable = ({
  generalAccess,
  groups,
  canEdit,
  groupPermissions,
  onUpdateGroup,
  onUpdatePermission,
  onAddPermission,
  onRemovePermission,
}: Props) => {
  const schemaItems: (SchemaEntry | boolean | undefined)[] = [
    {
      label: "Group",
      accessor: "groupField",
      dataType: DataType.JSX,
    },
    {
      label: "Permission",
      accessor: "groupAccess",
      dataType: DataType.JSX,
    },
    canEdit && {
      label: "Action",
      accessor: "groupActions",
      dataType: DataType.JSX,
      align: "center",
    },
  ];

  const schema = schemaItems.filter(Boolean) as SchemaEntry[];

  const takenGroupIDs = groupPermissions
    .filter((x) => x.ID)
    .map((x) => x.groupID);
  const availableGroups = groups
    .filter((group) => !takenGroupIDs.includes(group.ID))
    .map((group) => ({
      id: group.ID,
      value: group.name,
    }));

  const showGroupPermissions =
    availableGroups.length === 0
      ? groupPermissions.filter(({ ID, shown }) => ID && shown)
      : groupPermissions.filter(({ shown }) => shown);

  const formatRow = useCallback(
    (permissionEntry: PermissionEntry) => ({
      groupField: (
        <GroupField
          permission={permissionEntry}
          availableGroups={availableGroups}
          allGroups={groups}
          onUpdateGroup={onUpdateGroup}
        />
      ),
      groupAccess: (
        <PermissionAccess
          permission={permissionEntry}
          canEdit={canEdit}
          generalAccess={generalAccess}
          onUpdatePermission={onUpdatePermission}
        />
      ),
      groupActions: (
        <PermissionActions
          permission={permissionEntry}
          permissions={groupPermissions}
          onAddPermission={onAddPermission}
          onRemovePermission={onRemovePermission}
          permissionType="group"
        />
      ),
    }),
    [
      availableGroups,
      canEdit,
      generalAccess,
      groupPermissions,
      groups,
      onAddPermission,
      onRemovePermission,
      onUpdateGroup,
      onUpdatePermission,
    ]
  );

  const formattedData = useMemo(
    () => showGroupPermissions.map(formatRow),
    [formatRow, showGroupPermissions]
  );

  return (
    <div data-testid="group-permissions-table">
      <Label text={TITLE} />
      <Table schema={schema} data={formattedData} dense />
    </div>
  );
};

export default GroupPermissionsTable;
