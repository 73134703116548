import { TestProps } from "shared/types";

interface SectionProps extends TestProps {
  children: React.ReactNode | React.ReactNode[];
}

const Section = ({ testId, children }: SectionProps) => (
  <div className="text-xl mb-7" data-testid={testId}>
    {children}
  </div>
);

export default Section;
