import DuckAction from "duck/ui/DuckAction";
import { LDFlagSet } from "launchdarkly-js-client-sdk";
import { Navigate } from "react-router-dom";
import { LoginCallback } from "@okta/okta-react";

import { UserInfo } from "shared/api/info/api";
import { RecentItemType } from "shared/api/recentItems/api";
import { GeneralConfigModel } from "shared/api/superadmin/api";

import Users from "pages/Admin/Users";
import AlertDefinitions from "pages/AlertDefinitions";
import AlertDefinition from "pages/AlertDefinitions/Form/AlertDefinition";
import CalculatedAttributes from "pages/CalculatedAttributes";
import CalculatedAttribute from "pages/CalculatedAttributes/CalculatedAttribute";
import ClaimAnalytics from "pages/ClaimAnalytics";
import SingleClaim from "pages/ClaimAnalytics/SingleClaim";
import Collections from "pages/Collections";
import CustomSignalEvents from "pages/CustomSignalEvents";
import CustomSignalEvent from "pages/CustomSignalEvents/CustomSignalEvent";
import CustomSignalEventForm from "pages/CustomSignalEvents/CustomSignalEventForm";
import Docs from "pages/Docs";
import FailureMode from "pages/FailureModes/FailureMode/FailureMode";
import FailureModes from "pages/FailureModes/FailureModes";
import Fleets from "pages/Fleets";
import FleetView from "pages/FleetView";
import Groups from "pages/Groups";
import Group from "pages/Groups/Group";
import Info from "pages/Info";
import InspectionAnalytics from "pages/InspectionAnalytics";
import SingleInspection from "pages/InspectionAnalytics/SingleInspection";
import Issue from "pages/Issues/Issue";
import IssuesPage from "pages/Issues/IssuesPage";
import SuggestedIssue from "pages/Issues/SuggestedIssues/SuggestedIssue";
import SuggestedIssueLatestRun from "pages/Issues/SuggestedIssues/SuggestedIssueLatestRun";
import SuggestedIssuesPage from "pages/Issues/SuggestedIssuesPage";
import LandingPage from "pages/LandingPage";
import CreateBookmarkAction from "pages/LandingPage/Bookmarks/CreateBookmarkAction";
import WatchlistAction from "pages/LandingPage/Watchlist/WatchlistAction";
import Dashboard from "pages/MetabaseDashboardPage/MetabaseDashboardPage";
import NotFound from "pages/NotFound";
import RepairAnalytics from "pages/RepairAnalytics";
import SingleRepair from "pages/RepairAnalytics/SingleRepair/SingleRepair";
import ServicePlans from "pages/ServicePlans";
import ServicePlan from "pages/ServicePlans/Form/ServicePlan";
import ServiceRecommendations from "pages/ServiceRecommendations";
import ServiceRecords from "pages/ServiceRecords";
import SingleCustomRecord from "pages/ServiceRecords/SingleCustomRecord";
import SignalEventsAnalytics from "pages/SignalEventsAnalytics";
import SingleSignalEventOccurrence from "pages/SignalEventsAnalytics/SingleSignalEventOccurrence";
import AttributesConfig from "pages/SuperAdmin/AttributesConfig/AttributesConfig";
import GeneralConfig from "pages/SuperAdmin/GeneralConfig/GeneralConfig";
import OrderedValuesConfiguration from "pages/SuperAdmin/OrderedValues/OrderedValuesConfig";
import PagesConfigPage from "pages/SuperAdmin/PagesConfig";
import {
  ALERT_DEFINITIONS_PAGE_CONFIG_KEY,
  CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY,
  CLAIM_ANALYTICS_PAGE_CONFIG_KEY,
  COLLECTIONS_PAGE_CONFIG_KEY,
  CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY,
  FAILURE_MODES_PAGE_CONFIG_KEY,
  FLEETS_PAGE_CONFIG_KEY,
  INSPECTION_ANALYTICS_PAGE_CONFIG_KEY,
  ISSUES_PAGE_CONFIG_KEY,
  PagesConfigModel,
  RBAC_PAGE_CONFIG_KEY,
  SERVICE_PLANS_PAGE_CONFIG_KEY,
  SERVICE_RECORDS_PAGE_CONFIG_KEY,
  SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY,
  VEHICLES_PAGE_CONFIG_KEY,
} from "pages/SuperAdmin/PagesConfig/types";
import VehicleImages from "pages/SuperAdmin/VehicleImages/VehicleImages";
import Vehicles from "pages/Vehicles";
import Version from "pages/Version";
import VINView from "pages/VINView";

import LoginLoading from "features/auth/Login/LoginLoading";
import CopyURLToClipboardAction from "features/ui/CopyURLToClipboardAction";

import { RouteKey, routes } from "services/routes";

interface RouteConfigNoComponent {
  path: string;
  title?: string;
  insecure?: boolean;
  component?: JSX.Element;
  shouldDisplay?: (props: RouteDataProps) => boolean;
  componentFunction?: (props: RouteDataProps) => JSX.Element;
  // Default actions will be displayed in the top right corner of the page
  // as part of the PageHeader component
  routeActions?: JSX.Element[];
  // Properties bellow are used for route middleware
  // no constraints between them yet, since the id UrlParameter
  // could be used in other middleware functionality.
  // The default value is ALWAYS "id".
  recentItemType?: RecentItemType;
  idUrlParameter?: "vin" | "id" | "fleetName";
  wayFindingListingPage: boolean;
}

// ensures that exactly one of component or componentFunction is defined
export type RouteConfig = RouteConfigNoComponent &
  (
    | {
        component: JSX.Element;
        componentFunction?: never;
      }
    | {
        component?: never;
        componentFunction: (props: RouteDataProps) => JSX.Element;
      }
  );

export interface RouteDataProps {
  general: GeneralConfigModel;
  pages: PagesConfigModel;
  userInfo: UserInfo;
  flags: LDFlagSet;
}

type RoutesConfig = Record<RouteKey, RouteConfig>;

const commonRouteActions = [
  <CreateBookmarkAction />,
  <WatchlistAction />,
  <DuckAction />,
];

export const routesConfig: RoutesConfig = {
  [RouteKey.LoginCallback]: {
    path: routes.loginCallback,
    insecure: true,
    component: <LoginCallback loadingElement={<LoginLoading />} />,
    wayFindingListingPage: true,
  },
  [RouteKey.Root]: {
    path: routes.root,
    componentFunction: ({ general, pages }: RouteDataProps) =>
      pages.landingPage ? (
        <LandingPage />
      ) : (
        <Navigate to={general?.rootPage || routes.vehicles} />
      ),
    wayFindingListingPage: true,
  },
  [RouteKey.Docs]: {
    path: routes.docs,
    component: <Docs />,
    wayFindingListingPage: true,
  },
  [RouteKey.FailureModes]: {
    path: routes.failureModes,
    componentFunction: () => <FailureModes />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ pages }) => !!pages[FAILURE_MODES_PAGE_CONFIG_KEY],
    wayFindingListingPage: true,
  },
  [RouteKey.FailureMode]: {
    path: routes.failureMode,
    recentItemType: "failure_mode",
    componentFunction: () => <FailureMode />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ pages }) => !!pages[FAILURE_MODES_PAGE_CONFIG_KEY],
    wayFindingListingPage: false,
  },
  [RouteKey.ClaimAnalytics]: {
    path: routes.claimAnalytics,
    componentFunction: () => <ClaimAnalytics />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      pages[CLAIM_ANALYTICS_PAGE_CONFIG_KEY] && flags.claimAnalytics,
    wayFindingListingPage: true,
  },
  [RouteKey.ClaimAnalyticsDetails]: {
    path: routes.claimAnalyticsDetails,
    recentItemType: "claim",
    componentFunction: () => <SingleClaim />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ pages, flags }) =>
      pages[CLAIM_ANALYTICS_PAGE_CONFIG_KEY] && flags.claimAnalytics,
    wayFindingListingPage: false,
  },
  [RouteKey.RepairAnalytics]: {
    path: routes.repairAnalytics,
    componentFunction: () => <RepairAnalytics />,
    routeActions: commonRouteActions,
    wayFindingListingPage: true,
  },
  [RouteKey.RepairAnalyticsDetails]: {
    path: routes.repairAnalyticsDetails,
    recentItemType: "repair",
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    componentFunction: () => <SingleRepair />,
    wayFindingListingPage: false,
  },
  [RouteKey.InspectionAnalytics]: {
    path: routes.inspectionAnalytics,
    componentFunction: () => <InspectionAnalytics />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      pages[INSPECTION_ANALYTICS_PAGE_CONFIG_KEY] && flags.inspectionAnalytics,
    wayFindingListingPage: true,
  },
  [RouteKey.InspectionAnalyticsDetails]: {
    path: routes.inspectionAnalyticsDetails,
    componentFunction: () => <SingleInspection />,
    recentItemType: "inspection",
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ pages, flags }) =>
      pages[INSPECTION_ANALYTICS_PAGE_CONFIG_KEY] && flags.inspectionAnalytics,
    wayFindingListingPage: false,
  },
  [RouteKey.CustomRecordDetails]: {
    path: routes.customRecordDetails,
    componentFunction: () => <SingleCustomRecord />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    wayFindingListingPage: false,
  },
  [RouteKey.SignalEventAnalytics]: {
    path: routes.signalEventAnalytics,
    componentFunction: () => <SignalEventsAnalytics />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      pages[SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY] &&
      flags.signalEventsAnalytics,
    wayFindingListingPage: true,
  },
  [RouteKey.SignalEventsAnalyticsDetails]: {
    path: routes.signalEventsAnalyticsDetails,
    componentFunction: () => <SingleSignalEventOccurrence />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ pages, flags }) =>
      pages[SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY] &&
      flags.signalEventsAnalytics,
    wayFindingListingPage: false,
  },
  [RouteKey.Dashboard]: {
    path: routes.dashboard,
    componentFunction: () => <Dashboard />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ general }) =>
      !!(general?.metabaseDashboards && general.metabaseDashboards?.length > 0),
    wayFindingListingPage: true,
  },
  [RouteKey.Vehicles]: {
    path: routes.vehicles,
    routeActions: commonRouteActions,
    componentFunction: () => <Vehicles pageKey="vehicles" />,
    shouldDisplay: ({ pages }) => !!pages[VEHICLES_PAGE_CONFIG_KEY],
    wayFindingListingPage: true,
  },
  [RouteKey.VinView]: {
    path: routes.vinView,
    recentItemType: "vehicle",
    idUrlParameter: "vin",
    componentFunction: () => <VINView />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ pages }) => !!pages[VEHICLES_PAGE_CONFIG_KEY],
    wayFindingListingPage: false,
  },
  [RouteKey.Fleets]: {
    path: routes.fleets,
    componentFunction: () => <Fleets />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages }) => !!pages[FLEETS_PAGE_CONFIG_KEY],
    wayFindingListingPage: true,
  },
  [RouteKey.FleetView]: {
    path: routes.fleetView,
    recentItemType: "fleet",
    idUrlParameter: "fleetName",
    componentFunction: () => <FleetView />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ pages }) => !!pages[FLEETS_PAGE_CONFIG_KEY],
    wayFindingListingPage: false,
  },
  [RouteKey.ServicePlans]: {
    path: routes.servicePlans,
    componentFunction: () => <ServicePlans />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages }) => !!pages[SERVICE_PLANS_PAGE_CONFIG_KEY],
    wayFindingListingPage: true,
  },
  [RouteKey.NewServicePlan]: {
    path: routes.newServicePlan,
    componentFunction: () => <ServicePlan />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ pages }) => !!pages[SERVICE_PLANS_PAGE_CONFIG_KEY],
    wayFindingListingPage: false,
  },
  [RouteKey.ServiceRecords]: {
    path: routes.serviceRecords,
    componentFunction: () => <ServiceRecords />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages }) => !!pages[SERVICE_RECORDS_PAGE_CONFIG_KEY],
    wayFindingListingPage: true,
  },
  [RouteKey.ServicePlan]: {
    path: routes.servicePlan,
    recentItemType: "service_plan",
    componentFunction: () => <ServicePlan />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ pages }) => !!pages[SERVICE_PLANS_PAGE_CONFIG_KEY],
    wayFindingListingPage: false,
  },
  [RouteKey.ServiceRecommendations]: {
    path: routes.serviceRecommendations,
    componentFunction: () => <ServiceRecommendations />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages }) => !!pages[SERVICE_PLANS_PAGE_CONFIG_KEY],
    wayFindingListingPage: true,
  },
  [RouteKey.CalculatedAttributes]: {
    path: routes.calculatedAttributes,
    componentFunction: () => <CalculatedAttributes />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      pages[CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY] &&
      flags.calculatedAttributes,
    wayFindingListingPage: true,
  },
  [RouteKey.NewCalculatedAttribute]: {
    path: routes.newCalculatedAttribute,
    componentFunction: () => <CalculatedAttribute />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      pages[CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY] &&
      flags.calculatedAttributes,
    wayFindingListingPage: false,
  },
  [RouteKey.CalculatedAttribute]: {
    path: routes.calculatedAttribute,
    recentItemType: "calculated_attribute",
    componentFunction: () => <CalculatedAttribute />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ pages, flags }) =>
      pages[CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY] &&
      flags.calculatedAttributes,
    wayFindingListingPage: false,
  },
  [RouteKey.AlertDefinitions]: {
    path: routes.alertDefinitions,
    componentFunction: () => <AlertDefinitions />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages }) => !!pages[ALERT_DEFINITIONS_PAGE_CONFIG_KEY],
    wayFindingListingPage: true,
  },
  [RouteKey.AlertDefinition]: {
    path: routes.alertDefinition,
    recentItemType: "alert_definition",
    componentFunction: () => <AlertDefinition />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ pages }) => !!pages[ALERT_DEFINITIONS_PAGE_CONFIG_KEY],
    wayFindingListingPage: false,
  },
  [RouteKey.NewAlertDefinition]: {
    path: routes.newAlertDefinition,
    componentFunction: () => <AlertDefinition />,
    shouldDisplay: ({ pages }) => !!pages[ALERT_DEFINITIONS_PAGE_CONFIG_KEY],
    wayFindingListingPage: false,
  },
  [RouteKey.Collections]: {
    path: routes.collections,
    componentFunction: () => <Collections />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages }) => !!pages[COLLECTIONS_PAGE_CONFIG_KEY],
    wayFindingListingPage: true,
  },
  [RouteKey.Issues]: {
    path: routes.issues,
    componentFunction: () => <IssuesPage />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ flags, pages }) =>
      !!pages[ISSUES_PAGE_CONFIG_KEY] && flags.issues,
    wayFindingListingPage: true,
  },
  [RouteKey.Issue]: {
    path: routes.issue,
    recentItemType: "issue",
    componentFunction: () => <Issue />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ flags, pages }) =>
      !!pages[ISSUES_PAGE_CONFIG_KEY] && flags.issues,
    wayFindingListingPage: false,
  },
  [RouteKey.SuggestedIssues]: {
    path: routes.suggestedIssues,
    componentFunction: () => <SuggestedIssuesPage />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ flags, pages }) =>
      pages[ISSUES_PAGE_CONFIG_KEY]?.suggestedIssues && flags.suggestedIssues,
    wayFindingListingPage: true,
  },
  [RouteKey.SuggestedIssueLatestRun]: {
    path: routes.suggestedIssueLatestRun,
    recentItemType: "suggested_issue",
    componentFunction: () => <SuggestedIssueLatestRun />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ flags, pages }) =>
      pages[ISSUES_PAGE_CONFIG_KEY]?.suggestedIssues && flags.suggestedIssues,
    wayFindingListingPage: false,
  },
  [RouteKey.SuggestedIssue]: {
    path: routes.suggestedIssue,
    recentItemType: "suggested_issue",
    componentFunction: () => <SuggestedIssue />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ flags }) => flags.suggestedIssues,
    wayFindingListingPage: false,
  },
  [RouteKey.CustomSignalEvents]: {
    path: routes.customSignalEvents,
    componentFunction: () => <CustomSignalEvents />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      flags.customSignalEvents && pages[CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY],
    wayFindingListingPage: true,
  },
  [RouteKey.NewCustomSignalEvent]: {
    path: routes.newCustomSignalEvent,
    componentFunction: () => (
      <CustomSignalEventForm pageTitle="New Custom Signal Event" />
    ),
    routeActions: commonRouteActions,
    shouldDisplay: ({ pages, flags }) =>
      flags.customSignalEvents && pages[CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY],
    wayFindingListingPage: false,
  },
  [RouteKey.CustomSignalEvent]: {
    path: routes.customSignalEvent,
    recentItemType: "custom_signal_event",
    componentFunction: () => <CustomSignalEvent />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ pages, flags }) =>
      flags.customSignalEvents && pages[CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY],
    wayFindingListingPage: false,
  },
  [RouteKey.Version]: {
    path: routes.version,
    component: <Version />,
    wayFindingListingPage: true,
  },
  [RouteKey.Groups]: {
    path: routes.groups,
    componentFunction: () => <Groups />,
    routeActions: commonRouteActions,
    shouldDisplay: ({ flags, pages }) =>
      flags.rbac &&
      flags.rbacGroups &&
      pages[RBAC_PAGE_CONFIG_KEY] &&
      pages[RBAC_PAGE_CONFIG_KEY]?.rbacGroups,
    wayFindingListingPage: true,
  },
  [RouteKey.Group]: {
    path: routes.group,
    recentItemType: "group",
    componentFunction: () => <Group />,
    routeActions: [<CopyURLToClipboardAction />, ...commonRouteActions],
    shouldDisplay: ({ flags, pages, userInfo }) =>
      userInfo.isAdmin ||
      userInfo.isSuperAdmin ||
      (flags.rbac &&
        flags.rbacGroups &&
        pages[RBAC_PAGE_CONFIG_KEY] &&
        pages[RBAC_PAGE_CONFIG_KEY]?.rbacGroups),
    wayFindingListingPage: false,
  },
  [RouteKey.AttributesConfig]: {
    path: routes.attributesConfig,
    componentFunction: () => <AttributesConfig />,
    shouldDisplay: ({ userInfo }) => userInfo.isSuperAdmin,
    wayFindingListingPage: true,
  },
  [RouteKey.PagesConfig]: {
    path: routes.pagesConfig,
    componentFunction: () => <PagesConfigPage />,
    shouldDisplay: ({ userInfo }) => userInfo.isSuperAdmin,
    wayFindingListingPage: true,
  },
  [RouteKey.GeneralConfig]: {
    path: routes.generalConfig,
    componentFunction: () => <GeneralConfig />,
    shouldDisplay: ({ userInfo }) => userInfo.isSuperAdmin,
    wayFindingListingPage: true,
  },
  [RouteKey.OrderedValues]: {
    path: routes.orderedValues,
    componentFunction: () => <OrderedValuesConfiguration />,
    shouldDisplay: ({ userInfo }) => userInfo.isSuperAdmin,
    wayFindingListingPage: true,
  },
  [RouteKey.AdminGroups]: {
    path: routes.adminGroups,
    componentFunction: () => <Groups adminGroupsOnly />,
    shouldDisplay: ({ userInfo }) => userInfo.isAdmin || userInfo.isSuperAdmin,
    wayFindingListingPage: true,
  },
  [RouteKey.Users]: {
    path: routes.users,
    componentFunction: () => <Users />,
    shouldDisplay: ({ userInfo }) => userInfo.isAdmin || userInfo.isSuperAdmin,
    wayFindingListingPage: true,
  },
  [RouteKey.SuperAdminGroups]: {
    path: routes.superAdminGroups,
    componentFunction: () => <Groups superAdminGroupsOnly />,
    shouldDisplay: ({ userInfo }) => userInfo.isSuperAdmin,
    wayFindingListingPage: true,
  },
  [RouteKey.Info]: {
    path: routes.info,
    component: <Info />,
    shouldDisplay: ({ userInfo, flags }) =>
      userInfo.isSuperAdmin || flags.settingsPage,
    wayFindingListingPage: true,
  },
  [RouteKey.NotFound]: {
    path: routes.notFound,
    component: <NotFound />,
    wayFindingListingPage: true,
  },
  [RouteKey.VehicleImages]: {
    path: routes.vehicleImages,
    componentFunction: () => <VehicleImages />,
    shouldDisplay: ({ userInfo }) => userInfo.isSuperAdmin,
    wayFindingListingPage: true,
  },
};
