import { useContext, useState } from "react";
import AiTwinkle from "duck/ui/AiTwinkle";
import { DRILL_UTTERANCE } from "duck/ui/drill/constants";
import DuckDrillDialog from "duck/ui/drill/DuckDrillDialog";
import { MdTune } from "react-icons/md";
import { Button, ButtonGroup, Tooltip } from "@mui/material";

import { useConfigContext } from "shared/contexts/ConfigContext";
import { DuckletContext } from "shared/contexts/DuckletContextWrapper";

import { VIADUCK_PAGE_CONFIG_KEY } from "pages/SuperAdmin/PagesConfig/types";

import { SelectOption } from "features/ui/Select";

interface DuckDrillButtonProps {
  groupBySelectOptions: SelectOption[];
  filterQueryString?: string;
  vehiclesFilterQueryString?: string;
  pageTitle: string;
}

const DuckDrillButton = ({
  groupBySelectOptions,
  filterQueryString,
  vehiclesFilterQueryString,
  pageTitle,
}: DuckDrillButtonProps) => {
  const { setIsOpen, setAssignedUtterance, setAutoSubmit } =
    useContext(DuckletContext);
  const { pages } = useConfigContext();
  const [openDialog, setOpenDialog] = useState(false);

  if (!pages[VIADUCK_PAGE_CONFIG_KEY]) {
    return null;
  }

  const handleSubmitClick = () => {
    setIsOpen(true);

    const utterance = pageTitle
      ? `${DRILL_UTTERANCE} for ${pageTitle}`
      : DRILL_UTTERANCE;
    setAssignedUtterance(utterance);

    setAutoSubmit(true);
  };

  return (
    <>
      <ButtonGroup>
        <Button onClick={handleSubmitClick} startIcon={<AiTwinkle />}>
          Optimize Splits
        </Button>
        <Tooltip title="Adjust settings" arrow>
          <Button size="small" onClick={() => setOpenDialog(true)}>
            <MdTune size="24px" />
          </Button>
        </Tooltip>
      </ButtonGroup>

      <DuckDrillDialog
        groupBySelectOptions={groupBySelectOptions}
        filterQueryString={filterQueryString}
        vehiclesFilterQueryString={vehiclesFilterQueryString}
        pageTitle={pageTitle}
        open={openDialog}
        onClose={() => setOpenDialog(false)}
      />
    </>
  );
};

export default DuckDrillButton;
