import classNames from "classnames";

interface Props {
  flagID: string;
  block?: boolean;
}

const FeatureFlagExternalLink = ({ flagID, block }: Props) => (
  <div className="text-xs leading-6 text-gray-400">
    <span>Also has </span>
    <a
      href={`https://app.launchdarkly.com/projects/default/flags/${flagID}`}
      target="_blank"
      rel="noreferrer"
      className={classNames("text-blue-400 hover:text-blue-500", { block })}
    >
      Feature Flag
    </a>
  </div>
);

export default FeatureFlagExternalLink;
