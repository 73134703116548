import { promptNames } from "duck/graph/nodes/types";
import buildPageAgentSubgraph from "duck/graph/pageAgentSubGraph/subGraph";
import { PageHandler } from "duck/graph/PageHandler";
import { DuckGraphParams } from "duck/graph/types";
import { NodeNames } from "duck/graph/utils";

import { getActionTools, getRetrievalTools } from "./tools/getTools";

const getGraph = (params: DuckGraphParams) => {
  const pageHandler = new PageHandler();

  return buildPageAgentSubgraph({
    params,
    retrievalTools: getRetrievalTools(),
    actionTools: getActionTools(params, pageHandler),
    promptName: promptNames.ISSUE_DETAILS_AGENT,
    name: NodeNames.ISSUE_DETAILS,
  });
};

export default getGraph;
