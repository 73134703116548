import { useMemo, useState } from "react";
import { AiOutlineSearch as SearchIcon } from "react-icons/ai";

import FilterDescriptionTable from "features/ui/Filters/other/FilterDescriptionTable";
import Input from "features/ui/Input";

interface Props {
  attribute?: string;
  title?: string;
  values: string[];
  showDescriptionsTable?: boolean;
}

const ValuesSearchList = ({
  attribute,
  title,
  values,
  showDescriptionsTable,
}: Props) => {
  const [searchString, setSearchString] = useState("");

  const data = useMemo(() => {
    if (!searchString) return values.sort();

    const lowerCasedSearchString = searchString.toLowerCase();

    return values
      .filter((value) => value.toLowerCase().includes(lowerCasedSearchString))
      .sort();
  }, [searchString, values]);

  const descriptionsTable = attribute && (
    <FilterDescriptionTable
      fieldName={attribute}
      values={values}
      searchString={searchString}
      key={attribute}
    />
  );

  const listOfValues = (
    <ul className="flex flex-col gap-2 mt-4 pl-4 list-disc">
      {data.map((value, i) => (
        <li key={i}>{value}</li>
      ))}
    </ul>
  );

  const noResultsMessageVisible = data.length === 0 && !showDescriptionsTable;
  const toDisplay = showDescriptionsTable ? descriptionsTable : listOfValues;

  return (
    <div className="p-5 space-y-2" key={title}>
      <div className="flex justify-between mb-3">
        <h3 className="flex space-x-3 items-center font-semibold">{title}</h3>
        <Input
          onChange={({ target: { value } }) => {
            setSearchString(value);
          }}
          type="text"
          size="small"
          placeholder={`Search for ${title}`}
          icon={<SearchIcon />}
          fullWidth={false}
          withPlaceholder
        />
      </div>

      <div className="max-h-[40rem] overflow-y-auto">
        {noResultsMessageVisible && `No ${title} match search criteria.`}
        {!noResultsMessageVisible && toDisplay}
      </div>
    </div>
  );
};

export default ValuesSearchList;
