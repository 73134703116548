import { SortBy } from "shared/types";

import { FrequencyOptions } from "pages/AlertDefinitions/constants";

export const DEFAULT_WATCHLIST_SORT: SortBy = { updatedAt: "desc" };
export const WATCHLIST_KEY: string = "landing-page-watchlist";
export const REMOVE_ITEMS_TEXT = "Stop Watching";
export const CLEAR_ITEMS_TEXT = "Clear Alerts";

export const SELECTED_ROW_ACTIONS_TEXT = "Selected row actions:";
export const UNWATCH_MODAL_CONFIRM_TEXT = "Yes, stop watching";
export const UNWATCH_MODAL_CANCEL_TEXT = "No, cancel";

export const CLEAR_MODAL_CONFIRM_TEXT = "Yes, clear alerts";
export const CLEAR_MODAL_CANCEL_TEXT = "No, cancel";
export const NO_ITEMS_TEXT = "No items in watchlist";
export const SUCCESS_UNWATCH_TEXT = "Successfully stopped watching an item";
export const CLEAR_SUCCESS_TEXT = "Successfully cleared alerts";
export const CLEAR_ERROR_TEXT = "Error while clearing alerts";
export const SET_AS_SEEN_TEXT = "Mark as seen";

export const ALERT_FREQUENCY_TEXT = "Alert frequency:";
export const ALERT_TYPE_TEXT = "Alert type:";
export const ALERT_FREQUENCY_LABELS: Record<FrequencyOptions, string> = {
  [FrequencyOptions.ASAP]: "As soon as possible",
  [FrequencyOptions.HOURLY]: "Hourly",
  [FrequencyOptions.DAILY]: "Daily",
  [FrequencyOptions.WEEKLY]: "Weekly",
};

export const NOTIFICATION_TYPE_LABELS: Record<string, string> = {
  app: "In application",
  email: "Email",
  all: "Both",
};

export const EVENT_TYPE_LABELS: Record<string, string> = {
  serviceRecommendation: "Service recommendations",
  claim: "Claims",
  signalEventOccurrence: "Signal event occurrences",
  vehicle: "Vehicle",
  issue: "Issue",
  suggestedIssue: "Suggested issue",
  metadata: "Metadata",
};

export const SAVE_TEXT = "Save";
export const CANCEL_TEXT = "Cancel";
export const NEW_ALERT_HEADER_TEXT = "Add to watchlist";
export const ALERT_NAME_REQUIRED_TEXT = "Name is required";

export const CREATE_ALERT_SUCCESS_TEXT =
  "Alert was created and added to watchlist";
export const CREATE_ALERT_ERROR_TEXT = "Error while creating alert";
export const CREATE_ALERT_FILTER_NOT_SET_TEXT = "Event filter is not set";

export const UPDATE_ALERT_SUCCESS_TEXT =
  "Alert was created and added to watchlist";
export const UPDATE_ALERT_ERROR_TEXT = "Error while creating alert";
