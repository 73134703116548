import { FiChevronLeft as ChevronLeft } from "react-icons/fi";
import { ListItemButton, ListItemText } from "@mui/material";

import { SelectOption } from "features/ui/Select";

interface Props {
  path: SelectOption[];
  onNavigate: (index: number) => void;
}

const Breadcrumb = ({ path, onNavigate }: Props) => (
  <div className="sticky top-0 z-10 bg-white border-b border-gray-200">
    {path.map((option, index) => (
      <ListItemButton
        key={option.id}
        component="button"
        onClick={() => onNavigate(index - 1)}
        className="flex! items-center! py-1.5! px-2! w-full!"
      >
        <ListItemText
          primary={
            <div className="flex items-center">
              <ChevronLeft className="mr-1" data-testid="icon-arrow-left" />
              <span data-testid={`breadcrumb-${option.id}`}>
                {option.value}
              </span>
            </div>
          }
          className="m-0!"
        />
      </ListItemButton>
    ))}
  </div>
);

export default Breadcrumb;
