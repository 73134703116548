import { DEFAULT_GENERAL_PERMISSION } from "features/ui/PermissionsDialog/utils";

import { IssueFormData } from "./types";

export const DEFAULT_ISSUE_DATA: IssueFormData = {
  ID: "",
  atRiskPopulationFilter: undefined,
  comparisonPopulationFilter: undefined,
  claimFilter: undefined,
  signalEventOccurrencesFilter: undefined,
  name: "",
  description: "",
  statusID: undefined,
  severityID: undefined,
  assignee: "",
  acl: [DEFAULT_GENERAL_PERMISSION],
};
