import { EntityAttribute } from "shared/api/api";

import { FilterSchemaItem, FilterType } from "features/ui/Filters/types";
import { SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";

import {
  VEHICLE_ACCESSOR,
  VEHICLE_LAST_KNOWN_DEALER_ACCESSOR,
} from "./constants";
import {
  GetDisplayLabelFunction,
  GetSchemaEntryFunction,
  SchemaEntryOverride,
  SchemaRelations,
  SortSchemaItem,
} from "./types";

const getFilterTypeFromAPIType = (apiType: string): FilterType => {
  switch (apiType) {
    case "number<double>":
    case "integer<int32>":
    case "integer<int64>":
      return "number";
    case "string<duration>":
      return "duration";
    case "string<date>":
    case "string<date-time>":
    case "string<date-time-utc>":
    case "string<date-time-no-tz>":
      return "date";
    case "array[relation]":
      return "exists";
    case "boolean":
      return "boolean";
    default:
      return "string";
  }
};

export const getDisplayLabelFunction =
  (attributeMap?: Record<string, EntityAttribute>): GetDisplayLabelFunction =>
  (accessor: string, defaultLabel: string, suffix?: string): string => {
    if (!attributeMap || !attributeMap[accessor]) {
      return defaultLabel;
    }

    if (suffix) {
      return `${attributeMap[accessor].displayName} ${suffix}`;
    }

    return attributeMap[accessor].displayName;
  };

export const getAttributeMap = (attributes?: EntityAttribute[]) =>
  attributes?.reduce((map: Record<string, EntityAttribute>, obj) => {
    map[obj.ID] = obj;

    return map;
  }, {});

export const getFilterFunction =
  (
    filterType: (item: FilterSchemaItem) => FilterSchemaItem,
    attributeMap?: Record<string, EntityAttribute>
  ) =>
  (
    accessor: string,
    filterOverride: Partial<FilterSchemaItem> = {},
    fieldNamePrefix?: string,
    labelPrefix?: string
  ) => {
    if (!attributeMap || !attributeMap[accessor]) {
      return undefined;
    }

    const attributeConfig = attributeMap[accessor];
    if (!attributeConfig.filtering) {
      return undefined;
    }

    const label = labelPrefix
      ? `${labelPrefix} ${attributeConfig.displayName}`
      : attributeConfig.displayName;

    const fieldName = fieldNamePrefix
      ? `${fieldNamePrefix}.${accessor}`
      : accessor;

    return filterType({
      label,
      fieldName,
      fieldNameForAPI: accessor,
      relationEndpoint: attributeConfig.relationEndpoint || undefined,
      ...getFilterPropertiesFromAttribute(attributeConfig),
      ...filterOverride,
    });
  };

export const getFilterPropertiesFromAttribute = (
  attributeConfig: EntityAttribute
) => ({
  description: attributeConfig.description || undefined,
  search: attributeConfig.values,
  filterType: getFilterTypeFromAPIType(attributeConfig.type),
  filterDataType: getSchemaType(attributeConfig.type),
  disableSelectFilters: !attributeConfig.values,
  disableContainsFilters: !attributeConfig.filteringConfig.contains,
  disableStartsWithFilters: !attributeConfig.filteringConfig.startsWith,
  onlyAllowPositiveIntegers:
    !attributeConfig.filteringConfig.negativeNumbers &&
    !attributeConfig.filteringConfig.decimalNumbers,
  disableIsEmptyFilters: !attributeConfig.filteringConfig.empty,
  enableMinMaxFilters: attributeConfig.filteringConfig.minMax,
  loadDataOnOpen: attributeConfig.filteringConfig.lowCardinality,
});

export const getSchemaEntryFunction =
  (
    getFilter: (
      accessor: string,
      filterOverride?: Partial<FilterSchemaItem>,
      fieldNamePrefix?: string,
      labelPrefix?: string
    ) => FilterSchemaItem | undefined,
    attributeMap?: Record<string, EntityAttribute>
  ) =>
  (
    accessor: string,
    configOverride: Partial<SchemaEntry> = {},
    filterOverride: Partial<FilterSchemaItem> = {},
    sortOverride: SortSchemaItem = {},
    parentEntityAttribute?: EntityAttribute
  ): SchemaEntry | undefined => {
    if (!attributeMap || !attributeMap[accessor]) {
      return undefined;
    }

    const attributeConfig = attributeMap[accessor];

    const label = parentEntityAttribute?.displayName
      ? `${parentEntityAttribute?.displayName} ${attributeConfig.displayName}`
      : attributeConfig.displayName;

    const accessorToUse = parentEntityAttribute?.ID
      ? `${parentEntityAttribute?.ID}.${accessor}`
      : accessor;

    return {
      ...{
        label,
        description: attributeConfig.description || undefined,
        accessor: accessorToUse,
        dataType: getSchemaType(attributeConfig.type),
        sortable: attributeConfig.sorting,
        limitedWidthClass: attributeConfig.displayWideColumn
          ? "w-80"
          : undefined,
        filter: getFilter(
          accessor,
          filterOverride,
          parentEntityAttribute?.ID,
          parentEntityAttribute?.displayName
        ),
        sort: {
          fieldNameForAPI: sortOverride?.fieldNameForAPI || accessor,
        },
        hideInTable: attributeConfig.hideInTable,
        hideFilter: attributeConfig.hideFilter,
        byVehicleAgeBirthday: attributeConfig.byVehicleAgeBirthday,
        byVehicleAgeExposure: attributeConfig.byVehicleAgeExposure,
        byVehicleAgeExposureBuckets:
          attributeConfig.byVehicleAgeExposureBuckets,
      },
      ...configOverride,
    };
  };

export const getSchemaForAccessorFunction =
  (schemaItems: SchemaEntry[]) => (accessor: string) =>
    schemaItems.find(
      (schemaEntry) => schemaEntry && schemaEntry.accessor === accessor
    );

export const getSchemaType = (type: string | undefined): DataType => {
  if (type && (type.includes("int") || type.includes("double"))) {
    return DataType.NUMBER;
  }

  if (type === "string<date>") return DataType.DATE;

  if (type === "string<date-time>") return DataType.DATE_WITH_TIME;

  if (type === "string<date-time-no-tz>") return DataType.DATE_WITH_TIME_NO_TZ;

  if (type === "string<date-time-utc>") return DataType.DATE_WITH_TIME_UTC;

  if (type === "boolean") return DataType.BOOLEAN;

  return DataType.STRING;
};

export const getVehicleDisplayNameValue = (displayName: string) =>
  `Vehicle: ${displayName}`;

export const getVehicleIdValue = (ID: string) => `${VEHICLE_ACCESSOR}.${ID}`;

export const formatVehicleAttribute = ({
  ID,
  displayName,
  ...otherAttrs
}: EntityAttribute) => ({
  ID: getVehicleIdValue(ID),
  displayName: getVehicleDisplayNameValue(displayName),
  ...otherAttrs,
});

export const formatVehicleEntityToSelectOption = ({
  ID,
  displayName,
}: EntityAttribute): SelectOption => ({
  id: getVehicleIdValue(ID),
  value: getVehicleDisplayNameValue(displayName),
});

export const getVehicleLastKnownDealerDisplayNameValue = (
  displayName: string,
  vehicleDealerAttribute?: EntityAttribute
) => `Vehicle: ${vehicleDealerAttribute?.displayName} ${displayName}`;

export const getVehicleLastKnownDealerIdValue = (ID: string) =>
  `${VEHICLE_ACCESSOR}.${VEHICLE_LAST_KNOWN_DEALER_ACCESSOR}.${ID}`;

export const filterGroupableAttributes = ({
  grouping,
  type,
}: EntityAttribute) => grouping && type !== "relation";

export const sortByValue = (a: SelectOption, b: SelectOption) =>
  b.value > a.value ? -1 : 1;

export const sortByDisplayName = (a: EntityAttribute, b: EntityAttribute) =>
  b.displayName > a.displayName ? -1 : 1;

export const getSchemaFromAttributes = (
  attributes: EntityAttribute[] = [],
  getSchemaEntry: GetSchemaEntryFunction,
  overrides: SchemaEntryOverride = {},
  relations: SchemaRelations = {},
  accessorsToHide: string[] = [],
  relationshipDisplayOnly = false
) => {
  const schema: SchemaEntry[] = [];

  attributes.forEach((attribute) => {
    if (attribute.relationEndpoint && relations[attribute.relationEndpoint]) {
      const {
        attributes: relationAttributes,
        getSchemaEntry: getRelationshipSchemaEntry,
        schema: relationSchema,
      } = relations[attribute.relationEndpoint];

      if (relationAttributes) {
        // if overrides include a key starting with attribute.ID + ".", then it's a relation override
        // and we should pass the nested key/attribute (everything AFTER dot) to the getRelationshipSchemaEntry function
        const relationOverrides = Object.keys(overrides).reduce(
          (acc: SchemaEntryOverride, key: string) => {
            if (key.startsWith(`${attribute.ID}.`)) {
              const nestedKey = key.split(".")[1];
              acc[nestedKey] = overrides[key];
            }

            return acc;
          },
          {}
        );

        schema.push(
          ...relationAttributes
            .filter((relationAttribute) =>
              relationSchema.find(
                (schemaEntry) => schemaEntry.accessor === relationAttribute.ID
              )
            )
            .map((entry: EntityAttribute) => {
              const schemaOverrides = {
                ...relationOverrides[entry.ID]?.schemaOverride,
              };
              // we sometimes only want to display the relationship data, but not enable sort/filter as we have that in
              // separate functionality like Vehicles Filter. We can pass this as a relationshipDisplayOnly prop to the function
              if (relationshipDisplayOnly) {
                schemaOverrides.filter = undefined;
                schemaOverrides.sortable = false;
              }

              if (attribute.hideFilter) {
                schemaOverrides.hideFilter = true;
              }

              if (attribute.hideInTable) {
                schemaOverrides.hideInTable = true;
              }

              // if Relationship object does not have sorting/filtering enabled, we should disable it for all it's attributes as well
              if (!attribute.sorting) {
                schemaOverrides.sortable = false;
              }

              if (!attribute.filtering) {
                schemaOverrides.filter = undefined;
              }

              const filterOverrides = {
                disableFiltering: true,
                ...relationOverrides[entry.ID]?.filterOverride,
              };
              const sortOverrides = {
                ...relationOverrides[entry.ID]?.sortOverride,
              };

              return getRelationshipSchemaEntry!(
                entry.ID,
                schemaOverrides,
                filterOverrides,
                sortOverrides,
                attribute
              ) as SchemaEntry;
            })
        );
      }
    } else if (!accessorsToHide.includes(attribute.ID)) {
      const {
        schemaOverride,
        filterOverride,
        sortOverride,
        parentEntityAttribute,
        ignore,
      } = overrides[attribute.ID] || {};

      const schemaEntry = getSchemaEntry(
        attribute.ID,
        schemaOverride,
        filterOverride,
        sortOverride,
        parentEntityAttribute
      );

      if (schemaEntry && !ignore) {
        schema.push(schemaEntry);
      }
    }
  });

  return schema;
};

// extracts string after the last dot to get the base attribute name
export const getMainAttributeName = (fullAttributeName: string) =>
  fullAttributeName.substring(fullAttributeName.lastIndexOf(".") + 1);

/**
 * This allows us to grab the values for m2m relationships, like vehicle ECUs and vehicle options.
 * For example: /v1/vehicleECUs/values/ECUID will get us relevant ECU IDs that we need. ECU attributes are
 * retrieved using useGroupBySelectOptions() as a one-to-many relationship in relevant schemas, ie. vehiclesSchema.
 */
export const constructValuesEndpoint = (endpoint: string, IDColumn: string) =>
  endpoint.replace("/v1/", "") + "/values/" + IDColumn;
