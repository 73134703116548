import { useGetIssueRelationshipByVehicleAgeTimeline } from "shared/api/issues/hooks";
import { DEFAULT_EXPOSURE } from "shared/constants";
import { useCustomLocalStorageState, useTenantMileageUnit } from "shared/hooks";
import { IssueChart, IssueChartType, IssueTypes } from "shared/types";

import { useClaimByVehicleAgeExposureOptions } from "pages/hooks";
import MultipleLinesChart from "pages/Issues/Charts/MultipleLinesChart";
import { RelationshipsCharts } from "pages/Issues/constants";
import {
  getAxisKeyLabelFromActions,
  getChartOptionsKey,
  getPopulationString,
} from "pages/Issues/utils";

import Card from "features/ui/Card";
import { SEDPTV, SEDPTVDV, xAxis } from "features/ui/charts/actions";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { COLOR_PALETTE } from "features/ui/charts/constants";
import { YAxisLine } from "features/ui/charts/types";
import {
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";

const CHART_NAME: IssueChart =
  RelationshipsCharts.COMBINED_OCCURRENCES_BY_VEHICLE_AGE;
const CHART_TYPE: IssueChartType = "VehicleAge";

interface Props {
  title?: string;
  issue: IssueTypes;
  withComparisonPopulation: boolean;
  timePeriod: number;
}

const signalEventsDPTVLine: YAxisLine = {
  key: "signalEventsCumulativeDPTV",
  color: COLOR_PALETTE[0],
  label: "Cumulative DPTV",
  yAxisId: "right",
};

const signalEventsDPTVDistinctVINsLine: YAxisLine = {
  key: "signalEventsCumulativeDPTVDistinctVINs",
  color: COLOR_PALETTE[0],
  label: "Cumulative DPTV - Distinct VINs",
  yAxisId: "right",
};

const claimIPTVLine: YAxisLine = {
  key: "claimsCumulativeIPTV",
  color: COLOR_PALETTE[1],
  label: "Claims Cumulative IPTV",
  yAxisId: "left",
};

const claimWithEventIPTVLine: YAxisLine = {
  key: "claimsWithEventCumulativeIPTV",
  color: COLOR_PALETTE[2],
  label: "Claims with event Cumulative IPTV",
  yAxisId: "left",
};

const OccurrencesByVehicleAgeChart = ({
  issue,
  withComparisonPopulation,
  timePeriod,
}: Props) => {
  const exposures = useClaimByVehicleAgeExposureOptions();
  const chartOptionsKey = getChartOptionsKey(CHART_NAME, issue.ID);

  const actions: ChartAction[] = [
    {
      id: "x",
      title: xAxis,
      type: "dropdownSelect",
      options: exposures,
      defaultOptionId: DEFAULT_EXPOSURE,
    },
    {
      id: "signalEvents",
      title: "Signal Events",
      type: "dropdownSelect",
      options: [SEDPTV, SEDPTVDV],
    },
  ];

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(actions) });

  const { axisKey: xAxisKey, axisValue: xAxisValue } =
    getAxisKeyLabelFromActions(selectedOptions, actions, "x");

  const { axisValue: yAxisValueRight } = getAxisKeyLabelFromActions(
    selectedOptions,
    actions,
    "signalEvents"
  );

  const lines = getLinesFromOptions(selectedOptions);

  const yAxisLabel = "Claims Cumulative IPTV";

  const commonChartProps = {
    issue,
    chartType: CHART_TYPE,
    chart: CHART_NAME,
    dataLoadHook: useGetIssueRelationshipByVehicleAgeTimeline,
    dataLoadHookParams: {
      signalEventsTimeWindow: timePeriod,
      byVehicleAgeExposure: xAxisKey,
      mileageUnit: useTenantMileageUnit(),
    },
    selectedOptions,
    actions,
    yAxisLabel,
    yAxisLabelRight: yAxisValueRight,
    yAxisLines: lines,
    xAxisLabel: xAxisValue,
    exposure: xAxisKey,
  };

  const chartTitle = `Occurrences by ${xAxisValue}`;

  const atRiskChartTitle = `${chartTitle} : ${getPopulationString("atRisk")}`;
  const comparisonChartTitle = `${chartTitle} : ${getPopulationString(
    "comparison"
  )}`;

  return (
    <Card>
      <div className="space-y-5">
        <div>
          <ChartActionsWrap id={CHART_NAME} chartTitle={atRiskChartTitle}>
            <ChartActions
              actions={actions}
              selectedOptions={selectedOptions}
              onOptionChange={setSelectedOptions}
            />
          </ChartActionsWrap>
          <MultipleLinesChart {...commonChartProps} population="atRisk" />
        </div>
        {withComparisonPopulation && (
          <div>
            <ChartActionsWrap
              id={CHART_NAME}
              chartTitle={comparisonChartTitle}
              showActions={false}
            />
            <MultipleLinesChart {...commonChartProps} population="comparison" />
          </div>
        )}
      </div>
    </Card>
  );
};

const getLinesFromOptions = (
  selectedOptions: SelectedChartOptions[]
): YAxisLine[] => [
  claimIPTVLine,
  claimWithEventIPTVLine,
  getSelectedOptionId(selectedOptions, "signalEvents") === SEDPTV.id
    ? signalEventsDPTVLine
    : signalEventsDPTVDistinctVINsLine,
];

export default OccurrencesByVehicleAgeChart;
