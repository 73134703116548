import { useMemo } from "react";

import { APIErrorResponse, VehicleAgeTimeline } from "shared/api/api";
import { NO_DATA } from "shared/constants";

import ByVehicleAgeChartTooltip from "pages/shared/ByVehicleAgeChartTooltip";
import { ByVehicleAgeData } from "pages/types";
import {
  generateByVehicleAgeYAxisLines,
  prepareByVehicleAgeDataByTimestamp,
} from "pages/utils";

import APIError from "features/ui/APIError";
import Button from "features/ui/Button";
import Card from "features/ui/Card";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import LineChart from "features/ui/charts/LineChart";
import { EscKey } from "features/ui/Icons/EscKey";

import { NONE_EXPOSURE_ID } from "./constants";
import { useDateRangeSelection } from "./hooks";
import { FieldDateRange } from "./types";
import {
  getSelectionReferenceAreas,
  getSelectionReferenceLines,
} from "./utils";

const ADD_DATE_RANGE_TO_VEHICLE_FILTERS =
  "Filter to vehicles manufactured within selected range";
const ADD_AFTER_DATE_TO_VEHICLE_FILTERS =
  "Filter to vehicles manufactured after selected date";
const CLEAR_SELECTION = "Clear selection";

interface Props {
  byVehicleAgeData: ByVehicleAgeData;
  data: VehicleAgeTimeline[] | undefined;
  error: APIErrorResponse | undefined;
  actions: ChartAction[];
  selectedOptions: SelectedChartOptions[];
  setSelectedOptions: (options: SelectedChartOptions[]) => void;
  onBadRequest: () => void;
  onAddDateRangeToVehicleFilters?: (fieldDateRange: FieldDateRange) => void;
}

const ByVehicleAgeChart = ({
  byVehicleAgeData,
  data,
  error,
  actions,
  selectedOptions,
  setSelectedOptions,
  onBadRequest,
  onAddDateRangeToVehicleFilters,
}: Props) => {
  const {
    byVehicleAgeBirthdayValue,
    byVehicleAgeExposure,
    byVehicleAgeExposureValue,
    yAxisKey,
    yAxisValue,
  } = byVehicleAgeData;

  const {
    selectedTimestamps,
    clearSelection,
    handleTimestampClick,
    getRangeWithGranularityAndFieldName,
  } = useDateRangeSelection(selectedOptions, byVehicleAgeData.granularity);

  const handleAddDateRangeToVehicleFilters = () => {
    const fieldDateRange = getRangeWithGranularityAndFieldName();
    if (!fieldDateRange || !onAddDateRangeToVehicleFilters) return;

    const { from, to, fieldName } = fieldDateRange;
    clearSelection();
    onAddDateRangeToVehicleFilters({
      fieldName: fieldName as string,
      fromDate: from,
      toDate: to,
    });
  };

  const yAxisLines = useMemo(
    () => generateByVehicleAgeYAxisLines(data ?? [], byVehicleAgeData),
    [data, byVehicleAgeData]
  );

  if (!data) return NO_DATA;

  const chartData = prepareByVehicleAgeDataByTimestamp(data, byVehicleAgeData);

  const chartTitle = `${yAxisValue} by ${byVehicleAgeBirthdayValue}`;

  const yAxisOptions =
    actions.find((action) => action.id === "y")?.options || [];

  const legendPrefix =
    byVehicleAgeExposure !== NONE_EXPOSURE_ID
      ? byVehicleAgeExposureValue
      : undefined;
  const toggleableLines = byVehicleAgeExposure !== NONE_EXPOSURE_ID;

  return (
    <Card>
      <ChartActionsWrap
        id="by-vehicle-age"
        chartTitle={chartTitle}
        contentClassName="w-[350px]"
        customActions={
          <>
            {selectedTimestamps.max && selectedTimestamps.min && (
              <Button onClick={handleAddDateRangeToVehicleFilters}>
                {ADD_DATE_RANGE_TO_VEHICLE_FILTERS}
              </Button>
            )}
            {selectedTimestamps.min && !selectedTimestamps.max && (
              <Button onClick={handleAddDateRangeToVehicleFilters}>
                {ADD_AFTER_DATE_TO_VEHICLE_FILTERS}
              </Button>
            )}
            {(selectedTimestamps.max || selectedTimestamps.min) && (
              <Button onClick={clearSelection} className="flex gap-1">
                {CLEAR_SELECTION}
                <EscKey />
              </Button>
            )}
          </>
        }
      >
        <ChartActions
          actions={actions}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      {error && <APIError error={error} onBadRequest={onBadRequest} />}
      {!error && data.length === 0 && NO_DATA}
      {!error && data.length > 0 && (
        <LineChart
          height={400}
          width="100%"
          data={chartData}
          xAxisKey="ts"
          yAxisLines={yAxisLines}
          yAxisLabel={yAxisValue}
          xAxisLabel={byVehicleAgeBirthdayValue}
          references={getSelectionReferenceLines(selectedTimestamps)}
          referencesAreas={getSelectionReferenceAreas(selectedTimestamps)}
          onClick={handleTimestampClick}
          tooltipProps={{
            content: (props) => (
              <ByVehicleAgeChartTooltip
                {...props}
                yAxisKey={yAxisKey}
                yAxisLabel={yAxisValue}
                yAxisOptions={yAxisOptions}
              />
            ),
          }}
          toggleableLines={toggleableLines}
          legendPrefix={legendPrefix}
        />
      )}
    </Card>
  );
};

export default ByVehicleAgeChart;
