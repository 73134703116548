import responses from "duck/graph/nodes/KnightSwiftVinViewAgent/responses";
import { NodeOutputType } from "duck/graph/nodes/utils";
import { getParamName } from "duck/graph/nodes/VinViewAgent/tools/goToTimelineTab/utils";
import { PageHandler } from "duck/graph/PageHandler";
import { UIHandlers } from "duck/graph/types";

import { sleep } from "shared/utils";

import {
  VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY,
} from "pages/VINView/constants";

const KSDEMO_STATE_KEY = "ksdemo_state";

const currentStateString = localStorage.getItem(KSDEMO_STATE_KEY);
let currentState = currentStateString
  ? parseInt(String(currentStateString))
  : 0;

// The demo cycles around the responses. There are only 3 responses so this is ok.
const incrementState = () => {
  currentState = currentState >= responses.length - 1 ? 0 : currentState + 1;
  localStorage.setItem(KSDEMO_STATE_KEY, String(currentState));
};

const getNode =
  (
    setEphemeralMessage: UIHandlers["setEphemeralMessage"],
    setAgentResponse: UIHandlers["setAgentResponse"]
  ) =>
  async (): Promise<NodeOutputType> => {
    setEphemeralMessage("diagnosing");

    if (currentState === 1) {
      const pageHandler = new PageHandler("/vehicles/VDT13ATJ8OP243586");
      pageHandler.navigateToTab(VIN_VIEW_EVENTS_TIMELINE_TAB_KEY);
      pageHandler.updateFilter(
        `recordedAt=between:"2021-07-02T10:34:58"|"2021-09-18T16:00:55"`,
        getParamName(VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY("VDT13ATJ8OP243586"))
      );
    }

    let currentResponse = 0;
    for (const response of responses[currentState]) {
      // We pause so that the demo feels more realistic
      const pause = currentState === 0 && currentResponse < 2 ? 2000 : 1000;
      await sleep(pause);

      const isLastMessage =
        currentResponse === responses[currentState].length - 1;
      const options = isLastMessage ? {} : { suppressFeedback: true };

      setAgentResponse(response, options);
      currentResponse++;
    }

    incrementState();

    return {};
  };

export default getNode;
