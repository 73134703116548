import { IssueTypes } from "shared/types";

import Board from "features/ui/Board";

import OccurrencesByCalendarTimeChart from "./Charts/OccurrencesByCalendarTimeChart";
import OccurrencesByVehicleAgeChart from "./Charts/OccurrencesByVehicleAgeChart";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const IssueClaimsCharts = (props: Props) => {
  const boardElements: JSX.Element[][] = [
    [
      <OccurrencesByCalendarTimeChart
        {...props}
        key="OccurrencesByCalendarTimeChart"
      />,
    ],
    [<OccurrencesByVehicleAgeChart {...props} key="OccurrencesByMISChart" />],
  ];

  return (
    <div className="mb-3">
      <Board elements={boardElements} />
    </div>
  );
};

export default IssueClaimsCharts;
