import { getTenantFromHostname } from "shared/utils";

/**
 * This file serves as a type definition for the config.js and to provide a
 * .get() function to retrieve the config from the window object.
 */
export const SUPPORT_EMAIL = "support@viaduct.ai";

export const API_CURRENT_VERSION = "v1";

interface MetabaseDashboardFilter {
  name: string;
  id: string;
  key: string;
  search?: boolean;
  hide?: boolean;
}

export type MetabaseDashboardFilterConfig =
  | {
      [group: string]: MetabaseDashboardFilter[];
    }
  | MetabaseDashboardFilter[];

interface HeapAnalyticsConfig {
  id: string;
}

interface AnalyticsConfig {
  heap?: HeapAnalyticsConfig;
}

type AuthScopes = "openid" | "email" | "offline_access";

interface AuthConfig {
  name: string;
  idp?: string;
  issuer: string;
  clientId: string;
  scopes: AuthScopes[];
  postLogoutRedirectUri?: string;
}

interface DataExplorerConfig {
  url: string;
}

interface SingleIDPConfig {
  unauthenticatedRedirect?: string;
  optionalAutoLogin?: boolean;
}

interface FeatureFlagsConfig {
  launchDarklyClientId: string;
  anonymizeEmail?: boolean;
}

interface AppConfig {
  analytics: AnalyticsConfig;
  featureFlags: FeatureFlagsConfig;
  auth: AuthConfig;
  isIframed?: boolean;
  instance: string;
  dataExplorer?: DataExplorerConfig;
  singleIDPLogin?: SingleIDPConfig;
  multiTenant: boolean;
}

declare global {
  interface Window {
    __APP_CONFIG__?: AppConfig;
  }
}

export const get = (): AppConfig => {
  const config = window.__APP_CONFIG__;
  if (!config) {
    throw new Error("Missing required configuration file.");
  }

  if (
    !config.instance ||
    !config.auth?.name ||
    !config.auth?.issuer ||
    !config.auth?.clientId ||
    !config.auth?.scopes ||
    !config.featureFlags?.launchDarklyClientId ||
    typeof config.multiTenant !== "boolean"
  ) {
    throw new Error(
      "Missing required configuration fields. Current config is\n" +
        JSON.stringify(config)
    );
  }

  return config;
};

export const isMultiTenantInstanceManagementInstance = (): boolean => {
  const { instance, multiTenant } = get();

  return instance === getTenantFromHostname() && multiTenant;
};
