import { INSPECTIONS_GENERIC_FILTER } from "shared/filterDefinitions";

import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";

// TODO: can this be removed & figured out based on API /attributes info from Context?
const FIELD_NAMES_WITH_DESCRIPTIONS = [
  "laborCode",
  "failedPartNumber",
  "mentionedSignalEvents",
  "relatedSignalEvents",
  "signalEventID",
];

export const canShowDescription = (attributeName: string) =>
  FIELD_NAMES_WITH_DESCRIPTIONS.includes(attributeName);

export const getDefaultTableSchema = (): SchemaEntry[] =>
  [
    {
      label: "IPTV",
      accessor: "IPTV",
      dataType: DataType.NUMBER,
      sortable: true,
      // note that we only use INSPECTIONS_GENERIC_FILTER for all these because we dont really need /values
      // endpoint for this due to filterType & disableSelectFilters ..
      filter: INSPECTIONS_GENERIC_FILTER({
        fieldName: "IPTV",
        label: "IPTV",
        search: false,
        disableFiltering: true,
        disableSelectFilters: true,
        filterType: "number",
      }),
    },
    {
      label: "% IPTV change",
      accessor: "IPTV30DayChangeRatio",
      dataType: DataType.JSX,
      sortable: true,
      description:
        "Percentage change in IPTV in the last 30 days. Use a value from 0 to 1 when applying filters.",
      filter: INSPECTIONS_GENERIC_FILTER({
        fieldName: "IPTV30DayChangeRatio",
        label: "% IPTV change",
        search: false,
        disableFiltering: true,
        disableSelectFilters: true,
        filterType: "number",
      }),
    },
    {
      label: "Num. Inspections",
      accessor: "numEvents",
      dataType: DataType.NUMBER,
      sortable: true,
      filter: INSPECTIONS_GENERIC_FILTER({
        fieldName: "numEvents",
        label: "Num. Inspections",
        search: false,
        disableFiltering: true,
        disableSelectFilters: true,
        filterType: "number",
        onlyAllowPositiveIntegers: true,
      }),
    },
    {
      label: "Affected Vehicles",
      accessor: "numVehicles",
      dataType: DataType.NUMBER,
      sortable: true,
      filter: INSPECTIONS_GENERIC_FILTER({
        fieldName: "numVehicles",
        label: "Affected Vehicles",
        search: false,
        disableFiltering: true,
        disableSelectFilters: true,
        filterType: "number",
        onlyAllowPositiveIntegers: true,
      }),
    },
    {
      label: "Total Vehicles",
      accessor: "totalNumVehicles",
      dataType: DataType.NUMBER,
      sortable: true,
      filter: INSPECTIONS_GENERIC_FILTER({
        fieldName: "totalNumVehicles",
        label: "Total Vehicles",
        search: false,
        disableFiltering: true,
        disableSelectFilters: true,
        filterType: "number",
        onlyAllowPositiveIntegers: true,
      }),
    },
  ].filter(Boolean) as SchemaEntry[];
