export const DATE_TYPE_VALUES = [
  "date",
  "dateWithTime",
  "dateYearMonth",
  "dateYearMonthDay",
  "dateWithTimeOrTimeAgoIfRecent",
  "dateUTC",
  "dateWithTimeUTC",
  "dateWithTimeNoTZ",
] as const;

export enum DataType {
  DATE = "date",
  DATE_WITH_TIME = "dateWithTime",
  DATE_YEAR_MONTH = "dateYearMonth",
  DATE_YEAR_MONTH_DAY = "dateYearMonthDay",
  DATE_WITH_TIME_OR_TIME_AGO_IF_RECENT = "dateWithTimeOrTimeAgoIfRecent",
  DATE_UTC = "dateUTC",
  DATE_WITH_TIME_UTC = "dateWithTimeUTC",
  DATE_WITH_TIME_NO_TZ = "dateWithTimeNoTZ",
  NUMBER = "number",
  STRING = "string",
  BOOLEAN = "boolean",
  PERCENTAGE = "percentage", // adds %
  RATIO_PERCENTAGE = "ratioPercentage", // multiplies by 100 and adds %
  JSX = "jsx",
}

export type TableCellValue = string | number | JSX.Element;
