import { attributePickerAgent } from "duck/graph/nodes/DrillTopContributorAgent/attributePickerAgent";
import {
  generateFilterSummaryTextWithDescriptions,
  listFilterAttributes,
} from "duck/graph/nodes/DrillTopContributorAgent/utils";
import { OPTIONS_PREFIX } from "duck/ui/drill/constants";
import { DuckDrillTreeItemProps } from "duck/ui/drill/DuckDrillTreeItem";
import { flattenSelectOptionList } from "duck/ui/utils";
import { TreeViewBaseItem } from "@mui/x-tree-view";

import { SelectOption } from "features/ui/Select";

/**
 * Get the default attribute ids in a backup way, if we are unable to get them
 * from the agent.
 * These backup default attribute ids are the top level "vehicle" attributes.
 * @param options
 * @returns
 */
const getBackupDefaultAttributeIds = (options: TreeViewBaseItem[]): string[] =>
  options
    .filter((option) => !option.children)
    .map((option) => encodeURIComponent(String(option.id)));

const flattenTreeViewBaseItemsToStringArray = (
  options: TreeViewBaseItem[]
): string[] => {
  const flattened: string[] = [];

  options.forEach((option) => {
    if (option.children && option.children.length > 0) {
      flattened.push(...flattenTreeViewBaseItemsToStringArray(option.children));
    } else {
      flattened.push(option.id);
    }
  });

  return flattened;
};

/**
 * Get the default attribute ids from an agent.
 * @param availableOptions
 * @returns
 */
export const getDefaultAttributeIds = async (
  availableOptions: TreeViewBaseItem[],
  filterQueryString?: string,
  userEmail?: string
): Promise<string[]> => {
  const filterSummaryDescription =
    await generateFilterSummaryTextWithDescriptions(filterQueryString);

  const flatOptions = flattenTreeViewBaseItemsToStringArray(availableOptions);

  const runnableConfig = {
    metadata: {
      user: userEmail,
      isViaductUser: userEmail?.endsWith("@viaduct.ai"),
    },
  };

  try {
    const { recommendedAttributes } = await attributePickerAgent(
      filterSummaryDescription,
      flatOptions,
      runnableConfig
    );

    if (!recommendedAttributes || recommendedAttributes.length === 0) {
      return getBackupDefaultAttributeIds(availableOptions);
    }

    return recommendedAttributes;
  } catch (error) {
    console.error("Failed to get recommended attributes from agent", error);

    return getBackupDefaultAttributeIds(availableOptions);
  }
};

export const isSelectOption = (
  option: SelectOption | undefined
): option is SelectOption => option !== undefined;

export const isTreeViewBaseItem = (
  option: TreeViewBaseItem | undefined
): option is TreeViewBaseItem => option !== undefined;

export const flattenTreeViewBaseItemsToSelectOptions = (
  options: TreeViewBaseItem[]
): SelectOption[] => {
  const flattened: SelectOption[] = [];

  options.forEach((option) => {
    if (option.children && option.children.length > 0) {
      flattened.push(
        ...flattenTreeViewBaseItemsToSelectOptions(option.children)
      );
    } else {
      flattened.push({
        id: option.id,
        value: option.label,
      });
    }
  });

  return flattened;
};

export const prepareTreeViewItems = (
  options: SelectOption[],
  vehiclesFilterQueryString?: string
): TreeViewBaseItem[] => {
  const vehicleOptions = options.find((option) => option.id === OPTIONS_PREFIX);

  if (!vehicleOptions || !vehicleOptions.children) return [];

  const vehicleAttributesInFilter = listFilterAttributes(
    vehiclesFilterQueryString
  ).map((attr) => `${OPTIONS_PREFIX}.${encodeURIComponent(attr)}`);
  console.log({ vehicleAttributesInFilter });

  const idsToOmit = ["present"];

  const curatedItems: TreeViewBaseItem[] = [];

  vehicleOptions.children.forEach((option) => {
    const newItem: TreeViewBaseItem = {
      id: `${OPTIONS_PREFIX}.${encodeURIComponent(option.id)}`,
      label: `Vehicle > ${option.value}`,
    };

    if (option.children) {
      const flattenedChildren = flattenSelectOptionList(
        option.children,
        `${OPTIONS_PREFIX}.${encodeURIComponent(option.id)}.`,
        `Vehicle > ${option.value} > `
      )
        .filter((option) => {
          if (
            idsToOmit.some((idToOmit) => String(option.id).includes(idToOmit))
          ) {
            return false;
          }

          return !vehicleAttributesInFilter.includes(String(option.id));
        })
        .map((option) => ({
          id: String(option.id),
          label: String(option.value),
        }));
      newItem.children = flattenedChildren;
    }

    if (newItem.children && newItem.children.length > 0) {
      // Has children. Include it.
      curatedItems.push(newItem);
    } else if (option.children && option.children.length > 0) {
      console.log(
        `All the children of ${newItem.label} match vehicle attribute filters. Not including it.`
      );
    } else if (!vehicleAttributesInFilter.includes(String(newItem.id))) {
      // This does not match a vehicle attribute in the filter. Include it.
      curatedItems.push(newItem);
    }
  });

  console.log({ curatedItems });

  return curatedItems;
};

export const areAllChildrenSelected = (
  parentId: string,
  selected: string[],
  flattenedLeaves: SelectOption[]
): boolean => {
  const children = flattenedLeaves.filter((leaf) =>
    String(leaf.id).startsWith(`${parentId}.`)
  );

  if (children.length === 0) return false;

  return children.every((child) => selected.includes(String(child.id)));
};

export const areNoChildrenSelected = (
  parentId: string,
  selected: string[]
): boolean =>
  !selected.some((selectedId) => selectedId.startsWith(`${parentId}.`));

export const createCheckboxProps = ({
  children,
  selectedattributes,
  flattenedleaves,
  itemId,
}: DuckDrillTreeItemProps): Record<string, boolean> => {
  if (!children) {
    return {};
  }

  const checkboxProps: Record<string, boolean> = {
    disabled: true,
    checked: false,
    indeterminate: true,
  };

  if (!selectedattributes || !flattenedleaves) {
    return checkboxProps;
  }

  if (areAllChildrenSelected(itemId, selectedattributes, flattenedleaves)) {
    checkboxProps.checked = true;
    checkboxProps.indeterminate = false;
  } else if (areNoChildrenSelected(itemId, selectedattributes)) {
    checkboxProps.indeterminate = false;
  }

  return checkboxProps;
};

export const filterAvailableItems = (
  availableItems: TreeViewBaseItem[],
  filter: string
): TreeViewBaseItem[] => {
  if (!filter) {
    return availableItems;
  }

  const lowerCaseFilter = filter.toLowerCase();

  const filterItem = (item: TreeViewBaseItem): boolean =>
    item.id.toLowerCase().includes(lowerCaseFilter) ||
    item.label.toLowerCase().includes(lowerCaseFilter);

  const filteredItems = availableItems
    .map((item) => {
      if (!item.children) {
        return filterItem(item) ? item : undefined;
      }

      const filteredChildren = item.children?.filter(filterItem);

      if (!filteredChildren || filteredChildren.length === 0) {
        return undefined;
      }

      return {
        ...item,
        children: filteredChildren,
      };
    })
    .filter(isTreeViewBaseItem);

  return filteredItems;
};
