import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  DeleteRequest,
  getFetcher,
  ListAttributesRequest,
  patchFetcher,
  PermissionEntry,
  postFetcher,
  putFetcher,
  UpdatePermissionRequest,
} from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";

interface ServicePlanRule {
  condition: string;
  recommendation: string;
}

export interface ServicePlan {
  ID: string;
  name: string;
  description: string | null;
  family: string | null;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  rules: ServicePlanRule[];
  access: PermissionEntry[];
  canEdit: boolean;
  vehicleFilter: string;
}

const SERVICE_PLANS_BASE_ROUTE = "servicePlans";

export interface ListServicePlansRequest extends APIPaginatedRequest {}

export const listServicePlansRequestURI = (
  params: ListServicePlansRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([SERVICE_PLANS_BASE_ROUTE]),
    params,
  });

export const listServicePlansCountRequestURI = (
  params: ListServicePlansRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([SERVICE_PLANS_BASE_ROUTE, "count"]),
    params,
  });

const listServicePlansValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([SERVICE_PLANS_BASE_ROUTE, "values", fieldName]),
    params,
  });

export const listServicePlansValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listServicePlansValuesRequestURI(args));

interface GetServicePlanRequest {
  id: string;
}

export const listServicePlansAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([SERVICE_PLANS_BASE_ROUTE, "attributes"]),
    params,
  });

const getServicePlanRequestURI = ({ id }: GetServicePlanRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([SERVICE_PLANS_BASE_ROUTE, id]),
  });

export const getServicePlan = (args: GetServicePlanRequest) =>
  getFetcher<ServicePlan>(getServicePlanRequestURI(args));

export interface ServicePlanRequest {
  name: string;
  vehicleFilter: string | null;
  description: string | null;
  family: string | null;
  rules: ServicePlanRule[];
}

interface CreateServicePlanRequest extends ServicePlanRequest {
  acl: Partial<PermissionEntry>[];
}

const newServicePlanRequestURI = (): string =>
  client.getUri({
    method: "POST",
    url: SERVICE_PLANS_BASE_ROUTE,
  });

export const newServicePlan = (data: CreateServicePlanRequest) =>
  postFetcher<ServicePlan>(newServicePlanRequestURI(), data);

const deleteServicePlanRequestURI = ({ id }: { id: string }): string =>
  client.getUri({
    method: "DELETE",
    url: createURL([SERVICE_PLANS_BASE_ROUTE, id]),
  });

export const deleteServicePlan = (args: DeleteRequest) =>
  client.delete(deleteServicePlanRequestURI(args));

interface UpdateServicePlanRequest extends Partial<ServicePlanRequest> {
  ID: string;
}

interface UpdateServicePlanAccessRequest
  extends Partial<UpdatePermissionRequest> {
  ID: string;
}

const updateServicePlanRequestURI = ({
  ID,
  ...params
}: UpdateServicePlanRequest): string =>
  client.getUri({
    method: "PUT",
    url: createURL([SERVICE_PLANS_BASE_ROUTE, ID]),
    params,
  });

const updateServicePlanAccessRequestURI = ({
  ID,
  ...params
}: UpdateServicePlanAccessRequest): string =>
  client.getUri({
    method: "PATCH",
    url: createURL([SERVICE_PLANS_BASE_ROUTE, ID, "access"]),
    params,
  });

export const updateServicePlan = ({ ID, ...args }: UpdateServicePlanRequest) =>
  putFetcher<ServicePlan>(updateServicePlanRequestURI({ ID }), args);

export const updateServicePlanAccess = ({
  ID,
  ...args
}: UpdateServicePlanAccessRequest) =>
  patchFetcher<PermissionEntry[]>(
    updateServicePlanAccessRequestURI({ ID }),
    args
  );
