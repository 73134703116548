import React, { createContext, useContext } from "react";

import { useGroupByRelationIDValues } from "shared/schemas/hooks";

import {
  AttributesContextContent,
  ContextName,
  ContextRegistry,
  ContextWrapComponentProps,
  UseAttributesHook,
} from "./types";

const contextRegistry: ContextRegistry = {};

export const createGenericContextWrapper = (
  useAttributesHook: UseAttributesHook,
  contextName: ContextName
) => {
  const Context = createContext<AttributesContextContent>({
    attributes: undefined,
    groupByValues: {},
  });
  contextRegistry[contextName] = Context;

  const GenericContextWrapper: React.FC<ContextWrapComponentProps> = ({
    children,
  }) => {
    const { data: attributes } = useAttributesHook();
    const groupByValues = useGroupByRelationIDValues(attributes);

    return (
      <Context.Provider value={{ attributes, groupByValues }}>
        {children}
      </Context.Provider>
    );
  };

  return GenericContextWrapper;
};

export const useAttributesContext = (
  contextName: ContextName
): AttributesContextContent => {
  const Context = contextRegistry[contextName];
  if (!Context) {
    throw new Error(`Context for ${contextName} not found`);
  }

  return useContext(Context);
};
