import { getFetcher, patchFetcher, putFetcher } from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { MileageUnit } from "shared/types";

import { PagesConfig } from "pages/SuperAdmin/PagesConfig/types";

const SUPER_ADMIN_BASE_ROUTE = "superAdmin";

/**
 * Attributes config
 */
const ATTRIBUTES_CONFIG_BASE = "attributesConfig";

export interface AttributeConfig {
  ID: string;
  displayName: string;
  description: string | null;
  sorting: boolean;
  filtering: boolean;
  values: boolean;
  groupBy: boolean;
  export: boolean;
  hideInTable: boolean;
  hideFilter: boolean;
  byVehicleAgeBirthday: boolean;
  byVehicleAgeExposure: boolean;
  byVehicleAgeExposureBuckets: number[];
  unitAtRest: string | null;
  enabled: boolean;
}

interface AdminAttributesConfigRequest {
  resource: string;
}

interface AdminAttributesConfigUpdateRequest
  extends AdminAttributesConfigRequest {
  config: object;
}

export interface AdminAttributeConfig {
  resource: string;
  endpoint: string;
}

export const listAdminAttributesConfigRequestURI = (): string =>
  client.getUri({
    method: "GET",
    url: createURL([SUPER_ADMIN_BASE_ROUTE, ATTRIBUTES_CONFIG_BASE]),
  });

const getAdminAttributesConfigRequestURI = ({
  resource,
  ...params
}: AdminAttributesConfigRequest): string =>
  client.getUri({
    method: "GET",
    url: createURL([SUPER_ADMIN_BASE_ROUTE, ATTRIBUTES_CONFIG_BASE, resource]),
    params,
  });

export const getAdminAttributesConfig = (args: AdminAttributesConfigRequest) =>
  getFetcher<AttributeConfig[]>(getAdminAttributesConfigRequestURI(args));

const updateAdminAttributesConfigRequestURI = ({
  resource,
}: AdminAttributesConfigRequest): string =>
  client.getUri({
    method: "put",
    url: createURL([SUPER_ADMIN_BASE_ROUTE, ATTRIBUTES_CONFIG_BASE, resource]),
  });

export const updateAdminAttributesConfig = ({
  resource,
  ...args
}: AdminAttributesConfigUpdateRequest) =>
  putFetcher<AttributeConfig[]>(
    updateAdminAttributesConfigRequestURI({ resource }),
    { data: args.config }
  );

/**
 * Pages config
 */
const PAGES_CONFIG_BASE = "pagesConfig";

export interface AdminPagesConfigRequest {}

interface AdminPagesConfigUpdateRequest {
  config: PagesConfig;
}

export const getAdminPagesConfigRequestURI = (
  params: AdminPagesConfigRequest
): string =>
  client.getUri({
    method: "GET",
    url: createURL([SUPER_ADMIN_BASE_ROUTE, PAGES_CONFIG_BASE]),
    params,
  });

const updateAdminPagesConfigRequestURI = (): string =>
  client.getUri({
    method: "PUT",
    url: createURL([SUPER_ADMIN_BASE_ROUTE, PAGES_CONFIG_BASE]),
  });

export const updateAdminPagesConfig = (args: AdminPagesConfigUpdateRequest) =>
  putFetcher<PagesConfig>(updateAdminPagesConfigRequestURI(), {
    data: args.config,
  });

/**
 * General config
 */
const GENERAL_CONFIG_BASE = "generalConfig";

export interface MetabaseDashboard {
  id: string;
  name: string;
}

export interface Bookmark {
  title: string;
  path: string;
  description?: string;
}

export interface GeneralConfigModel {
  mileageUnit: MileageUnit;
  maxDate: string | null;
  helpContactEmail: string | null;
  rootPage: string | null;
  bookmarks: Bookmark[];
  metabaseDashboards: MetabaseDashboard[];
}

export interface GeneralConfigRequest {}

interface GeneralConfigUpdateRequest {
  config: GeneralConfigModel;
}

export const getGeneralConfigRequestURI = (
  params: GeneralConfigRequest
): string =>
  client.getUri({
    method: "GET",
    url: createURL([SUPER_ADMIN_BASE_ROUTE, GENERAL_CONFIG_BASE]),
    params,
  });

const updateGeneralConfigRequestURI = (): string =>
  client.getUri({
    method: "PUT",
    url: createURL([SUPER_ADMIN_BASE_ROUTE, GENERAL_CONFIG_BASE]),
  });

export const updateGeneralConfig = (args: GeneralConfigUpdateRequest) =>
  patchFetcher<GeneralConfigModel>(
    updateGeneralConfigRequestURI(),
    args.config
  );

/**
 * Ordered values config
 */
export type AdminOrderedValuesConfig = Record<string, any>[];

export const listAdminOrderedValuesConfigRequestURI = (): string =>
  client.getUri({
    method: "GET",
    url: createURL([SUPER_ADMIN_BASE_ROUTE, "orderedValues"]),
  });

const getAdminOrderedValuesConfigRequestURI = (type: string): string =>
  client.getUri({
    method: "GET",
    url: createURL([SUPER_ADMIN_BASE_ROUTE, "orderedValues", type]),
  });

export const getAdminOrderedValuesConfig = (type: string) =>
  getFetcher<AdminOrderedValuesConfig>(
    getAdminOrderedValuesConfigRequestURI(type)
  );

const updateAdminOrderedValuesConfigRequestURI = (type: string): string =>
  client.getUri({
    method: "PATCH",
    url: createURL([SUPER_ADMIN_BASE_ROUTE, "orderedValues", type]),
  });

interface UpdateAdminOrderedValuesConfigRequest {
  type: string;
  values: AdminOrderedValuesConfig;
}

export const updateAdminOrderedValuesConfig = ({
  type,
  values,
}: UpdateAdminOrderedValuesConfigRequest) =>
  patchFetcher<AdminOrderedValuesConfig>(
    updateAdminOrderedValuesConfigRequestURI(type),
    {
      data: values,
    }
  );

/**
 * Vehicle Images
 */
export interface VehicleImage {
  imageData: string; // Base64 encoded image data
  contentType: string;
  imageOrder: number;
  vehicleMake?: string | null;
  vehicleModel?: string | null;
  vehicleModelYear?: number | null;
  trimLevel?: string | null;
}

export const listAdminVehicleImagesRequestURI = (): string =>
  client.getUri({
    method: "GET",
    url: createURL([SUPER_ADMIN_BASE_ROUTE, "vehicleImages"]),
  });

const updateAdminVehicleImagesRequestURI = (): string =>
  client.getUri({
    method: "PUT",
    url: createURL([SUPER_ADMIN_BASE_ROUTE, "vehicleImages"]),
  });

export const updateAdminVehicleImages = (images: VehicleImage[]) =>
  putFetcher<VehicleImage[]>(updateAdminVehicleImagesRequestURI(), {
    data: images,
  });
