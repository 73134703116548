import { useFlags } from "launchdarkly-react-client-sdk";
import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { useGetClaim } from "shared/api/claims/hooks";
import { useTenantMileageUnit } from "shared/hooks";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { EventTypeEnum } from "shared/types";
import { getEntityWithExternalIDTitle } from "shared/utils";

import APIError from "features/ui/APIError/APIError";
import Board from "features/ui/Board/Board";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

import AnalyzeSimilarClaimsAction from "./AnalyzeSimilarClaimsAction";
import CardDealerDetails from "./CardDealerDetails";
import CardIncidentCategorization from "./CardIncidentCategorization";
import CardRepairCosts from "./CardRepairCosts";
import CardRepairDetails from "./CardRepairDetails";
import CardRepairNarratives from "./CardRepairNarratives";
import CardVehicleDetails from "./CardVehicleDetails";

type Params = {
  id: string;
};

const SingleClaim = () => {
  const { warrantyClaimsCost: warrantyClaimsCostFF } = useFlags();
  const { id } = useParams<Params>();
  const { schema } = useClaimsSchema();

  const { data, isLoading, error } = useGetClaim({
    ID: id as string,
    mileageUnit: useTenantMileageUnit(),
  });

  if (isLoading) {
    return <Skeleton count={2} height={300} className="mb-3" />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data) {
    return <div>No data</div>;
  }

  const boardElements: JSX.Element[][] = [
    [
      <CardVehicleDetails data={data} key="vehicle-details" />,
      <CardRepairDetails data={data} key="repair-details" />,
      <CardDealerDetails data={data} key="dealer-details" />,
    ],
    [
      <CardRepairNarratives data={data} key="repair-narratives" />,
      <CardIncidentCategorization data={data} key="incident-categorization" />,
      warrantyClaimsCostFF && (
        <CardRepairCosts data={data} key="repair-costs" />
      ),
    ].filter(Boolean) as JSX.Element[],
  ];

  const { ID, externalID } = data;
  const similarClaimsEnabled = schema.find(
    (entry) => entry.accessor === "similarClaimID"
  );

  const title = getEntityWithExternalIDTitle(externalID, EventTypeEnum.CLAIM);

  return (
    <>
      <PageHeaderWrapper title={title} />
      <div className="flex space-x-10 mb-4 items-center">
        {similarClaimsEnabled && (
          <AnalyzeSimilarClaimsAction ID={ID} externalID={externalID} />
        )}
      </div>
      <Board elements={boardElements} />
    </>
  );
};

export default SingleClaim;
