import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import FilterTypeWrap from "features/ui/Filters/SingleFilter/FilterTypeWrap";
import { FilterOperator } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  attribute: string | null;
  operator: FilterOperator | null;
  values: string[] | null;
  schema?: SchemaEntry<string>;
  onChange: (values: string[]) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  occursFilterSelector?: boolean;
}

const ValuesSelect = ({
  attribute,
  operator,
  values,
  schema,
  onChange,
  disabled,
  autoFocus,
  occursFilterSelector,
}: Props) => {
  const filterSchema = schema?.filter;

  if (!attribute || !operator || !filterSchema) return;

  // we're only using this format because FilterTypeWrap requires it
  const currentRowFilters: FilterGroupState | undefined = attribute
    ? {
        id: "group-0",
        type: "group",
        anyAll: "all",
        children: [
          {
            id: attribute,
            type: "row",
            attribute,
            values: values || [],
            operator,
          },
        ],
      }
    : undefined;

  return (
    <FilterTypeWrap
      autoFocus={autoFocus}
      isActiveFilter={false}
      selectedOperatorId={operator}
      initialized={true}
      currentFilterSchema={filterSchema}
      onValuesChanged={onChange}
      filters={currentRowFilters}
      hideCTA={true}
      className="flex justify-between items-end"
      disabled={disabled}
      isAdvancedFilterEditor
      occursFilterSelector={occursFilterSelector}
    />
  );
};

export default ValuesSelect;
