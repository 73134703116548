import {
  useInspectionsFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";

import PageLevelFilters from "pages/shared/PageLevelFilters/PageLevelFilters";
import { VEHICLES_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import {
  INSPECTIONS_FILTER_LABEL,
  INSPECTIONS_PAGE_KEY,
  INSPECTIONS_VEHICLES_PAGE_KEY,
} from "./constants";

// These are not in constants.ts so we avoid circular dependencies with the util being used.
export const PENDING_FILTERS_INSPECTIONS_LS_KEY =
  getPendingFiltersKey(INSPECTIONS_PAGE_KEY);

export const PENDING_FILTERS_VEHICLES_LS_KEY = getPendingFiltersKey(
  INSPECTIONS_VEHICLES_PAGE_KEY
);

interface Props {
  inspectionsFilterSortState: UseFilterSortState;
  vehiclesFilterSortState: UseFilterSortState;
  defaultInspectionFilters: FilterGroupState;
  defaultVehicleFilters: FilterGroupState;
}

const InspectionAnalyticsFilters = ({
  inspectionsFilterSortState,
  vehiclesFilterSortState,
  defaultInspectionFilters,
  defaultVehicleFilters,
}: Props) => {
  const inspectionsFiltersSchema = useInspectionsFiltersSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const inspectionFilterLabel = getFilterLabel(
    INSPECTIONS_FILTER_LABEL,
    inspectionsFilterSortState.filters
  );
  const vehiclePopulationFilterLabel = getFilterLabel(
    VEHICLES_FILTER_LABEL,
    vehiclesFilterSortState.filters
  );

  return (
    <PageLevelFilters
      filtersProps={[
        {
          filterSortState: inspectionsFilterSortState,
          defaultFilters: defaultInspectionFilters,
          filterSchema: inspectionsFiltersSchema,
          filterTitle: INSPECTIONS_FILTER_LABEL,
          filterLabel: inspectionFilterLabel,
          pendingFilterKey: PENDING_FILTERS_INSPECTIONS_LS_KEY,
          entity: "Inspections",
          testId: "inspections",
          quickFilterLabel: "Inspection Quick Filters",
        },
        {
          filterSortState: vehiclesFilterSortState,
          defaultFilters: defaultVehicleFilters,
          filterSchema: vehiclesFiltersSchema,
          filterTitle: VEHICLES_FILTER_LABEL,
          filterLabel: vehiclePopulationFilterLabel,
          pendingFilterKey: PENDING_FILTERS_VEHICLES_LS_KEY,
          entity: "Vehicles",
          testId: "vehicles",
          quickFilterLabel: "Vehicle Quick Filters",
        },
      ]}
    />
  );
};

export default InspectionAnalyticsFilters;
