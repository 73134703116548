import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { Dialog, DialogContent } from "@mui/material";

import Tooltip from "features/ui/Tooltip";
import ValuesSearchList from "features/ui/ValuesSearchList";

interface Props {
  title: string;
  values?: string[];
  maxVisible?: number;
  accessor?: string;
  linkToClaimAnalytics?: boolean;
  breakWords?: boolean;
}

const StringList = ({ title, values, breakWords }: Props) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const textRef = useRef<HTMLDivElement>(null);
  const formattedValue = values?.join(", ") ?? "";

  useEffect(() => {
    const element = textRef.current;
    if (element && formattedValue) {
      // Check both height and width overflow
      const hasVerticalOverflow = element.scrollHeight > element.clientHeight;
      const hasHorizontalOverflow = element.scrollWidth > element.clientWidth;
      // Check if content is clipped due to line-clamp
      const hasLineClampOverflow = element.offsetHeight < element.scrollHeight;

      setIsOverflowing(
        hasVerticalOverflow || hasHorizontalOverflow || hasLineClampOverflow
      );
    }
  }, [formattedValue]);

  if (!values || values.length === 0) {
    return null;
  }

  const moreButton = (
    <span
      className="ml-1 text-xs text-metabase-blue hover:text-blue-500"
      role="button"
      onClick={() => setIsDialogOpen(true)}
    >
      [...]
    </span>
  );

  const content = (
    <div
      className={classnames("overflow-hidden", {
        "break-words": breakWords,
        "whitespace-nowrap": !breakWords,
      })}
      style={{ flex: "0 1 auto" }}
      ref={textRef}
    >
      {formattedValue}
    </div>
  );

  return (
    <div className="flex flex-row items-center width-full justify-start">
      {isOverflowing ? (
        <Tooltip
          title={formattedValue}
          placement="bottom-start"
          enterDelay={750}
        >
          <div className="w-full flex flex-row items-center">
            {content}
            {moreButton}
          </div>
        </Tooltip>
      ) : (
        content
      )}

      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        maxWidth="sm"
        slotProps={{
          paper: {
            className: "pb-6! w-[50vw]",
          },
        }}
      >
        <DialogContent>
          <ValuesSearchList values={values as string[]} title={title} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default StringList;
