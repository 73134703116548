import { useMemo } from "react";

import {
  getSignalEventsExport,
  SignalEventsOccurrence,
} from "shared/api/signalEvents/api";
import {
  useListSignalEventsOccurrences,
  useListSignalEventsOccurrencesCount,
} from "shared/api/signalEvents/hooks";
import { applyAdditionalSorting, getSortFilter } from "shared/api/utils";
import { MAX_SIGNAL_EVENT_OCCURRENCES_DOWNLOAD_LIMIT } from "shared/constants";
import { useTenantMileageUnit } from "shared/hooks";
import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";
import { SortBy } from "shared/types";
import { toTitleCase } from "shared/utils";

import { getFilterEqualityValuesForField } from "pages/shared/utils";
import { VEHICLE_TIMELINE_SELECTED_SE_KEY } from "pages/shared/vehicles/constants";
import { VINEventTimelineDateLink } from "pages/shared/vehicles/VINEventTimelineDateLink";
import { SIGNAL_EVENTS_TABLE_PAGE_KEY } from "pages/SignalEventsAnalytics/constants";
import { SignalEventsFiltersProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { OnSortParams } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import TableCount from "features/ui/Table/TableCount";

const ROWS_PER_PAGE = 50;
const DEFAULT_SORT: SortBy = { recordedAt: "desc" };

const formatRow = (row: SignalEventsOccurrence, filters: FilterGroupState) => {
  const { VIN, signalEventType, milLampStatus, recordedAt } = row;

  const values = getFilterEqualityValuesForField(
    filters ?? DEFAULT_FILTER_BUILDER_STATE,
    "signalEventID"
  );

  return {
    ...row,
    VIN: (
      <VINEventTimelineDateLink
        VIN={VIN}
        date={recordedAt}
        filterKey={VEHICLE_TIMELINE_SELECTED_SE_KEY}
        filter={values.toString()}
        useDefaultFromDate={true}
      />
    ),
    signalEventType: toTitleCase(signalEventType),
    milLampStatus: milLampStatus && toTitleCase(milLampStatus),
  };
};

const SignalEventsOccurrencesTable = ({
  signalEventsFilters,
  vehiclesFilters,
  onBadRequest,
  pageKey = SIGNAL_EVENTS_TABLE_PAGE_KEY,
}: SignalEventsFiltersProps) => {
  const { schema, attributeAccessors } = useSignalEventOccurrencesSchema();

  const {
    manageFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey,
    defaultSort: DEFAULT_SORT,
    schemaAttributes: attributeAccessors,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const requestParams = {
    sort: getSortFilter(applyAdditionalSorting(sort)),
    filter: getFiltersQuery(
      mergeFilterGroupStates(filters, signalEventsFilters)
    ),
    vehiclesFilter: vehiclesFilters && getFiltersQuery(vehiclesFilters),
    limit: ROWS_PER_PAGE,
    mileageUnit: useTenantMileageUnit(),
  };

  const { data, isLoading, error, ...paginationData } =
    useListSignalEventsOccurrences(requestParams);

  // re-format the data - but only when data changes
  const formattedData = useMemo(
    () =>
      data?.map((d) =>
        formatRow(d, mergeFilterGroupStates(filters, signalEventsFilters))
      ),
    [data, signalEventsFilters, filters]
  );

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useListSignalEventsOccurrencesCount({
    filter: requestParams.filter,
    vehiclesFilter: requestParams.vehiclesFilter,
  });

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <div className="mt-5">
      <div className="flex items-center my-1">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto"
          count={countData?.count as number}
          prefix={`Showing ${data?.length} out of `}
          entityName="signal event"
          isLoading={countIsLoading || isLoading}
          error={!!countError}
        />
        {!error && (
          <DownloadAction
            disabled={downloadDisabled}
            downloadFunc={(args) =>
              getSignalEventsExport({
                ...args,
              })
            }
            fileName="signal-event-occurrences"
            requestParams={{
              ...requestParams,
              limit: MAX_SIGNAL_EVENT_OCCURRENCES_DOWNLOAD_LIMIT,
            }}
            entityName="signal event"
            filters={mergeFilterGroupStates(filters, vehiclesFilters)}
          />
        )}
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
        />
      )}
      {error && (
        <APIError
          error={error}
          onBadRequest={() => {
            resetFilterSortState();
            onBadRequest();
          }}
        />
      )}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </div>
  );
};

export default SignalEventsOccurrencesTable;
