import { useFilterSchemaForType } from "shared/hooks";

import { CustomAttribute } from "pages/CustomSignalEvents/Definition/CustomAttributes/types";
import {
  combineSchemasToSelectOptions,
  decapitalizeEventType,
} from "pages/CustomSignalEvents/utils";

import DropdownSelect from "features/ui/DropdownSelect";
import { SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";

import { FunctionInputProps } from "./types";

const FieldSelect = ({
  attribute,
  onChange,
  config,
  baseEventType,
  fieldSchema,
  combineFieldSchemas = false,
  useSelectedEvent = false,
  disabled,
}: FunctionInputProps) => {
  const fieldDropdownLabel = (
    options: SelectOption[],
    selectedOptionID?: string
  ): string =>
    (options.find((option) => option.id === selectedOptionID)
      ?.value as string) || "Select field";

  const currentEventSchema = useFilterSchemaForType(
    decapitalizeEventType(attribute.eventType)
  );

  let selectedSchema: SchemaEntry[] | undefined;

  if (useSelectedEvent) {
    if (attribute.eventType === undefined) {
      return (
        <div className="text-gray-400 leading-9">Please select Event Type</div>
      );
    } else {
      selectedSchema = currentEventSchema;
    }
  } else {
    if (!fieldSchema) {
      return (
        <div className="text-gray-400 leading-9">
          Please select Base Event Type
        </div>
      );
    } else {
      selectedSchema = fieldSchema;
    }
  }

  const selectableAttributes = combineSchemasToSelectOptions(
    selectedSchema,
    baseEventType,
    currentEventSchema,
    attribute.eventType,
    combineFieldSchemas,
    config.dataTypes || ["number"]
  );

  return (
    <DropdownSelect
      label={fieldDropdownLabel(
        selectableAttributes,
        attribute[config.parameter as keyof CustomAttribute] as string
      )}
      options={selectableAttributes}
      onSelect={(selectedOption) =>
        onChange({
          ...attribute,
          [config.parameter]: selectedOption.id as string,
        })
      }
      disabled={disabled}
    />
  );
};

export default FieldSelect;
