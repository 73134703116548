import { OktaAuth, SigninWithRedirectOptions } from "@okta/okta-auth-js";

import { routes } from "services/routes";
import * as config from "config/config";

interface LoginProps {
  oktaAuth: OktaAuth;
  redirect?: string;
}

/**
 * If singleIDPLogin tenant => immediately Sign in via Okta <=> pre-configured IDP
 * If not a singleIDPLogin tenant => taken to Viaduct Okta Sign-in page (https://viaduct-cloud.okta.com),
 * where they log in without going to the IDP login at all.
 */
const login = ({ oktaAuth, redirect = "" }: LoginProps) => {
  const {
    auth: { idp, scopes },
    singleIDPLogin,
  } = config.get();

  const signInParams: SigninWithRedirectOptions = singleIDPLogin
    ? {
        originalUri: `${window.location.origin}${redirect}`,
        idp,
        scopes,
        redirectUri: `${window.location.origin}${routes.loginCallback}`,
      }
    : {
        originalUri: `${window.location.origin}${redirect}`,
      };

  const oktaLogin = async () => {
    await oktaAuth.signInWithRedirect(signInParams);
  };

  oktaLogin();
};

export default login;
