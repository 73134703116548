import axios, { AxiosRequestHeaders } from "axios";

import { oktaAuth } from "services/okta";
import { API_CURRENT_VERSION } from "config/config";

import { getAPIUrl } from "./getAPIURL";

const getVersionedURL = () =>
  new URL(API_CURRENT_VERSION, getAPIUrl()).toString();

const clientWithoutVersion = axios.create({
  baseURL: getAPIUrl(),
});

const client = axios.create({
  baseURL: getVersionedURL(),
});

interface AuthHeaders {
  Authorization: string;
}

const defaultHeaders = {
  "Content-Type": "application/json",
};

const getAuthHeaders = (): AuthHeaders => {
  const token = oktaAuth.getAccessToken();

  if (!token) {
    console.error("missing user access token");
  }

  return {
    Authorization: `Bearer ${token}`,
  };
};

client.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...defaultHeaders,
    ...getAuthHeaders(),
    ...config.headers,
  } as AxiosRequestHeaders,
  withCredentials: true,
}));

//see getOpenAPISpec() where we use this client..
clientWithoutVersion.interceptors.request.use((config) => ({
  ...config,
  headers: {
    ...defaultHeaders,
    ...getAuthHeaders(),
    ...config.headers,
  } as AxiosRequestHeaders,
  withCredentials: true,
}));

export { clientWithoutVersion };

export default client;
