import {
  ButtonProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import Button from "features/ui/Button";

interface Props {
  title?: string;
  text?: string | React.ReactNode;
  isOpen: boolean;
  onClose: (confirm: boolean) => void;
  loading?: boolean;
  hideCTA?: boolean;
  closeDisabled?: boolean;
  confirmText?: string;
  closeText?: string;
  confirmColor?: ButtonProps["color"];
  additionalAction?: React.ReactNode;
}

const ConfirmationModal = ({
  title = "Are you sure?",
  text = "",
  isOpen,
  onClose,
  loading = false,
  hideCTA = false,
  closeDisabled = false,
  confirmText = "Confirm",
  closeText = "Close",
  confirmColor = "primary",
  additionalAction = null,
}: Props) => (
  <Dialog open={isOpen} fullWidth maxWidth="xs" onClose={() => onClose(false)}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>{text}</DialogContent>
    <DialogActions>
      {additionalAction}
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => onClose(false)}
        testId="button-confirmation-cancel"
        disabled={closeDisabled}
        size="small"
        autoFocus
      >
        {closeText}
      </Button>
      {!hideCTA && (
        <Button
          color={confirmColor}
          variant="contained"
          size="small"
          disabled={loading}
          isLoading={loading}
          onClick={() => onClose(true)}
          testId="button-confirmation-confirm"
        >
          {confirmText}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

export default ConfirmationModal;
