import { useEffect, useState } from "react";

import { isCustomAttributeComplete } from "pages/CustomSignalEvents/utils";

import Button from "features/ui/Button";
import { SchemaEntry } from "features/ui/Table";

import CustomAttributeForm from "./CustomAttributeForm";
import { CustomAttribute } from "./types";

interface Props {
  customAttributes: CustomAttribute[];
  onChange: (customAttributes: CustomAttribute[]) => void;
  onCustomAttributeRemoved: (id: string) => void;
  baseEventType: string;
  baseEventTypeSchema: SchemaEntry<string>[] | undefined;
  disabled?: boolean;
}

const CustomAttributesSelect = ({
  customAttributes,
  onChange,
  onCustomAttributeRemoved,
  baseEventType,
  baseEventTypeSchema,
  disabled,
}: Props) => {
  const [customAttributeCounter, setCustomAttributeCounter] = useState(
    customAttributes.length
  );

  const onAttributeChange = (value: CustomAttribute, index: number) => {
    customAttributes[index] = value;
    onChange([...customAttributes]);
  };

  const addAttribute = (attribute: Partial<CustomAttribute>) => {
    const newCounter = customAttributeCounter + 1;
    setCustomAttributeCounter(newCounter);
    onChange([
      ...customAttributes,
      {
        ...attribute,
        index: newCounter,
        id: `customAttribute${newCounter}`,
        label: `Custom Attribute ${newCounter}`,
      },
    ]);
  };

  const onAddAttribute = () => {
    addAttribute({});
  };

  const onDeleteAttribute = (customAttribute: CustomAttribute) =>
    onChange([...customAttributes.filter((a) => a.id !== customAttribute.id)]);

  const onCopyAttribute = (customAttribute: CustomAttribute) =>
    addAttribute(customAttribute);

  useEffect(() => {
    // we reset the counter, so it goes from 1 again
    if (customAttributes.length === 0 && customAttributeCounter > 0) {
      setCustomAttributeCounter(0);
    }
  }, [customAttributes, customAttributeCounter]);

  return (
    <div>
      {customAttributes.map((customAttribute, index) => {
        const previousCustomAttributes = customAttributes
          .slice(0, index)
          .filter(isCustomAttributeComplete);

        return (
          <CustomAttributeForm
            attribute={customAttribute}
            onChange={(value: CustomAttribute) =>
              onAttributeChange(value, index)
            }
            key={customAttribute.id}
            onAddAttribute={onAddAttribute}
            onCopyAttribute={() => onCopyAttribute(customAttribute)}
            onDeleteAttribute={() => onDeleteAttribute(customAttribute)}
            onAttributeIncomplete={() =>
              onCustomAttributeRemoved(customAttribute.id)
            }
            baseEventType={baseEventType}
            baseEventTypeSchema={baseEventTypeSchema}
            // we pass all previous custom attributes to the form, it can be used as "field" options
            previousCustomAttributes={previousCustomAttributes}
            disabled={disabled}
          />
        );
      })}
      {customAttributes.length === 0 && (
        <Button onClick={onAddAttribute} disabled={disabled}>
          Add custom attribute
        </Button>
      )}
    </div>
  );
};

export default CustomAttributesSelect;
