import { useConfigContext } from "shared/contexts/ConfigContext";

import SupportContent from "features/ui/APIError/SupportContent";
import Error from "features/ui/Error";

interface Props {
  errorMessage: string;
}

const UnhandledError = ({ errorMessage }: Props) => {
  const title = "Ooops, something went wrong.";
  const defaultContent = "Please try later or contact the site administrator.";

  const { general } = useConfigContext();
  const supportEmail = general?.helpContactEmail;

  const content = supportEmail ? (
    <SupportContent email={supportEmail} />
  ) : (
    defaultContent
  );

  return (
    <div className="m-8">
      <Error title={title} content={content} moreInfo={errorMessage} />
    </div>
  );
};

export default UnhandledError;
