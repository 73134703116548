import { useConfigContext } from "shared/contexts/ConfigContext";

const NotFound = () => {
  const { general } = useConfigContext();
  const email = general.helpContactEmail;

  const contact =
    (email && (
      <span>
        us via{" "}
        <a
          href={`mailto:${email}`}
          className="text-blue-400 hover:text-blue-500"
        >
          {email}
        </a>
      </span>
    )) ||
    "the site administrator";

  return (
    <>
      <div className="text-lg font-semibold">
        Sorry, this page couldn't be found.
      </div>
      <div> If you believe this is an error, please contact {contact}.</div>
    </>
  );
};

export default NotFound;
