import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  DeleteRequest,
  getFetcher,
  postFetcher,
  putFetcher,
} from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { EventTypeEnum } from "shared/types";

export interface CalculatedAttribute {
  ID: string;
  name: string;
  description: string | null;
  type: string;
  eventType: EventTypeEnum;
  eventFilter: string;
  unit: string | null;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
}

export interface CalculatedAttributeEvaluation {
  VIN: string;
  value: number;
}

export interface CalculatedAttributeRequest {
  name: string;
  description: string;
  type: string;
  eventType: string;
  eventFilter: string;
  unit?: string;
}

interface UpdateCalculatedAttributeRequest
  extends Partial<CalculatedAttributeRequest> {
  ID: string;
}

const CALCULATED_ATTRIBUTES_BASE_ROUTE = "calculatedAttributes";

export interface ListCalculatedAttributesRequest extends APIPaginatedRequest {}

export const listCalculatedAttributesRequestURI = (
  params: ListCalculatedAttributesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([CALCULATED_ATTRIBUTES_BASE_ROUTE]),
    params,
  });

export const listCalculatedAttributesCountRequestURI = (
  params: ListCalculatedAttributesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([CALCULATED_ATTRIBUTES_BASE_ROUTE, "count"]),
    params,
  });

interface GetCalculatedAttributeRequest {
  id: string;
  skipRequest?: boolean;
}

const getCalculatedAttributeRequestURI = ({
  skipRequest,
  id,
}: GetCalculatedAttributeRequest): string =>
  skipRequest
    ? ""
    : client.getUri({
        method: "get",
        url: createURL([CALCULATED_ATTRIBUTES_BASE_ROUTE, id]),
      });

export const getCalculatedAttribute = (args: GetCalculatedAttributeRequest) =>
  getFetcher<CalculatedAttribute>(getCalculatedAttributeRequestURI(args));

const deleteCalculatedAttributeRequestURI = ({ id }: { id: string }): string =>
  client.getUri({
    method: "DELETE",
    url: createURL([CALCULATED_ATTRIBUTES_BASE_ROUTE, id]),
  });

export const deleteCalculatedAttribute = (args: DeleteRequest) =>
  client.delete(deleteCalculatedAttributeRequestURI(args));

const newCalculatedAttributeRequestURI = (): string =>
  client.getUri({
    method: "POST",
    url: CALCULATED_ATTRIBUTES_BASE_ROUTE,
  });

export const newCalculatedAttribute = (data: CalculatedAttributeRequest) =>
  postFetcher<CalculatedAttribute>(newCalculatedAttributeRequestURI(), data);

const updateCalculatedAttributeRequestURI = ({
  ID,
  ...params
}: UpdateCalculatedAttributeRequest): string =>
  client.getUri({
    method: "PUT",
    url: createURL([CALCULATED_ATTRIBUTES_BASE_ROUTE, ID]),
    params,
  });

export const updateCalculatedAttribute = ({
  ID,
  ...args
}: UpdateCalculatedAttributeRequest) =>
  putFetcher<CalculatedAttribute>(
    updateCalculatedAttributeRequestURI({ ID }),
    args
  );

const listCalculatedAttributesValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "GET",
    url: createURL([CALCULATED_ATTRIBUTES_BASE_ROUTE, "values", fieldName]),
    params,
  });

export const listCalculatedAttributesValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(
    listCalculatedAttributesValuesRequestURI(args)
  );

export interface EvaluateCalculatedAttributeRequest
  extends APIPaginatedRequest {
  id: string;
  vehiclesFilter: string;
}

export const listEvaluateCalculatedAttributeRequestURI = ({
  id,
  ...params
}: EvaluateCalculatedAttributeRequest): string =>
  client.getUri({
    method: "GET",
    url: createURL([CALCULATED_ATTRIBUTES_BASE_ROUTE, id, "evaluate"]),
    params,
  });

export const listEvaluateCalculatedAttributeCountRequestURI = ({
  id,
  ...params
}: EvaluateCalculatedAttributeRequest): string =>
  client.getUri({
    method: "GET",
    url: createURL([CALCULATED_ATTRIBUTES_BASE_ROUTE, id, "evaluate", "count"]),
    params,
  });
