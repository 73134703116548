import { useConfigContext } from "shared/contexts/ConfigContext";
import { useInspectionsSchema } from "shared/schemas/inspectionsSchema";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

import {
  INSPECTIONS_PAGE_KEY,
  INSPECTIONS_VEHICLES_PAGE_KEY,
  PAGE_TITLE,
} from "./constants";
import InspectionAnalyticsFilters from "./InspectionAnalyticsFilters";
import InspectionAnalyticsTabs from "./InspectionAnalyticsTabs";

const InspectionAnalytics = () => {
  const { pages } = useConfigContext();

  const defaultInspectionFilters =
    filterBuilderQueryToFilterBuilderState(
      pages.inspectionAnalytics?.defaultFilters
    ) || DEFAULT_FILTER_BUILDER_STATE;

  const { attributeAccessors } = useInspectionsSchema();

  const inspectionsFiltersFilterSortState = useFilterSortState({
    pageKey: INSPECTIONS_PAGE_KEY,
    defaultFilterValues: defaultInspectionFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(INSPECTIONS_PAGE_KEY),
    schemaAttributes: attributeAccessors,
  });

  const defaultVehicleFilters =
    filterBuilderQueryToFilterBuilderState(
      pages.inspectionAnalytics?.defaultVehicleFilters
    ) || DEFAULT_FILTER_BUILDER_STATE;

  const { attributeAccessors: vehicleAttributeAccessors } = useVehiclesSchema();

  const vehiclesFiltersFilterSortState = useFilterSortState({
    pageKey: INSPECTIONS_VEHICLES_PAGE_KEY,
    defaultFilterValues: defaultVehicleFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(
      INSPECTIONS_VEHICLES_PAGE_KEY
    ),
    schemaAttributes: vehicleAttributeAccessors,
  });

  const resetState = () => {
    inspectionsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <PageHeaderWrapper title={PAGE_TITLE} />
      <InspectionAnalyticsFilters
        inspectionsFilterSortState={inspectionsFiltersFilterSortState}
        vehiclesFilterSortState={vehiclesFiltersFilterSortState}
        defaultInspectionFilters={defaultInspectionFilters}
        defaultVehicleFilters={defaultVehicleFilters}
      />
      <InspectionAnalyticsTabs
        inspectionsFiltersFilterSortState={inspectionsFiltersFilterSortState}
        vehiclesFiltersFilterSortState={vehiclesFiltersFilterSortState}
        onBadRequest={resetState}
      />
    </>
  );
};

export default InspectionAnalytics;
