import { useEffect, useRef, useState } from "react";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import Tooltip from "features/ui/Tooltip";

import { getMaxNumOfLinesForFilterPresenter } from "./utils";

export const DescriptionTableCell = ({
  description,
  filters,
  label,
}: {
  description?: string;
  filters: (string | undefined)[];
  label: string;
}) => {
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const textRef = useRef<HTMLDivElement>(null);

  // The height of each table row in issue tables is determined by number of lines
  // that are present in the filter presenters, we clamp description cell
  // to the same number of rows, to make descriptions more readable at a glance.
  const maxLineHeight = getMaxNumOfLinesForFilterPresenter(filters);

  useEffect(() => {
    const element = textRef.current;
    if (element && description) {
      // For -webkit-box with line clamp, we compare scrollHeight with clientHeight
      const hasOverflow = element.scrollHeight > element.clientHeight;
      setIsOverflowing(hasOverflow);
    }
  }, [description, maxLineHeight]);

  const moreButton = (
    <span
      className="ml-1 text-xs text-metabase-blue hover:text-blue-500 group-hover:bg-gray-50 bg-white pl-1"
      role="button"
      onClick={() => setDialogOpen(true)}
    >
      [...]
    </span>
  );

  const content = (
    <div
      ref={textRef}
      className="overflow-hidden whitespace-normal height-full"
      style={{
        display: "-webkit-box",
        WebkitLineClamp: maxLineHeight,
        WebkitBoxOrient: "vertical",
        overflow: "hidden",
        textOverflow: "clip",
      }}
    >
      {description}
    </div>
  );

  return isOverflowing ? (
    <>
      <Tooltip enterDelay={750} title={description}>
        <div className="relative">
          {content}
          <div className="absolute right-0 bottom-0">{moreButton}</div>
        </div>
      </Tooltip>
      <Dialog
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="sm"
        slotProps={{
          paper: {
            className: "max-h-[60vh]! pb-6!",
          },
        }}
      >
        <DialogTitle>{label}</DialogTitle>
        <DialogContent>{description}</DialogContent>
      </Dialog>
    </>
  ) : (
    content
  );
};
