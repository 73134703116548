import { useEffect, useMemo, useState } from "react";
import { ReferenceArea } from "recharts";
import { AxisDomain } from "recharts/types/util/types";

import { DEFAULT_Y_AXIS_ID } from "features/ui/charts/constants";
import ResetZoomButton from "features/ui/charts/shared/ResetZoomButton";
import SetDateToZoom from "features/ui/charts/shared/SetDateToZoom";
import { DataElement, YAxisLine, ZoomProps } from "features/ui/charts/types";
import { getYDomainWithAddedPercentageOfMax } from "features/ui/charts/utils";

import { LineChartProps } from "./types";

interface ZoomChartState {
  data?: DataElement[];
  left: string | number;
  right: string | number;
  refAreaLeft: string | number;
  refAreaRight: string | number;
  top: string | number;
  bottom: string | number;
}

const getAxisDomains = (
  data: DataElement[],
  yAxisLines: YAxisLine[],
  from: number | string,
  to: number | string,
  xAxisKey: string
) => {
  const fromToUse = typeof from === "number" ? from : parseInt(from);
  const toToUse = typeof to === "number" ? to : parseInt(to);

  // use yAxisLines if no data is passed in (this means this is a categorical chart most likely)
  if (data.length === 0 && yAxisLines.length > 0) {
    data = yAxisLines.map((o) => o?.data).flat();
  }

  const ref = yAxisLines.length > 0 ? yAxisLines[0].key : "";

  // filter data now based on where xAxisKey is between "from" and "to"
  const refData = data.filter(
    (obj) =>
      obj &&
      obj.hasOwnProperty(xAxisKey) &&
      obj[xAxisKey] >= fromToUse &&
      obj[xAxisKey] <= toToUse
  );

  let [bottom, top] =
    refData.length > 0
      ? [refData[0][ref] as number, refData[0][ref] as number]
      : ["auto", "auto"];

  // find the min and max of the data for this ref (y axis key)
  refData.forEach((d) => {
    if (d[ref] > top) top = d[ref] as number;

    if (d[ref] < bottom) bottom = d[ref] as number;
  });

  // TODO
  // this is currently only working for the first yAxisLine ref (left y axis, and thus it's data)
  // - so if you zoom on the 2nd line (right axis), the 5% won't be applied
  // - to fix this, we'd have to find the min and max of both yAxisLine refs, and then apply the 5% to each based on the max in the zoomed-in area
  const { bottom: bottomToUse, top: topToUse } =
    getYDomainWithAddedPercentageOfMax(bottom, top);

  return {
    bottom: bottomToUse,
    top: topToUse,
    left: fromToUse,
    right: toToUse,
  };
};

interface ZoomState {
  isZoomedIn?: boolean;
  handleZoomOut?: () => void;
  handleMouseDown?: (e: any) => void;
  handleMouseMove?: (e: any) => void;
  handleZoom?: () => void;
  zoomYAxisDomain?: AxisDomain;
  zoomXAxisDomain?: AxisDomain;
  initialState?: ZoomChartState;
  resetZoomButton: JSX.Element | null;
  zoomReferenceArea: JSX.Element | null;
}

const emptyState: ZoomState = {
  isZoomedIn: undefined,
  handleZoomOut: undefined,
  handleMouseDown: undefined,
  handleMouseMove: undefined,
  handleZoom: undefined,
  zoomYAxisDomain: undefined,
  zoomXAxisDomain: undefined,
  initialState: undefined,
  resetZoomButton: null,
  zoomReferenceArea: null,
};

const initialState: ZoomChartState = {
  left: "auto",
  right: "auto",
  refAreaLeft: "",
  refAreaRight: "",
  top: "auto",
  bottom: "auto",
};

export const useZoom = ({
  data,
  yAxisLines,
  xAxisKey,
  zoomOverride,
  enableZoom,
  onZoom,
  onZoomOut,
  onZoomReferenceAreaChange,
  zoomOutControlMarginSide,
  onSyncDateWithZoom,
}: LineChartProps & ZoomProps) => {
  const [state, setState] = useState(initialState);

  // when zoomOverride changes, apply it to state
  useEffect(() => {
    if (zoomOverride) {
      setState({
        ...state,
        left: zoomOverride.left,
        right: zoomOverride.right,
      });
    } else {
      setState(initialState);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomOverride, initialState]);

  const isZoomedIn =
    state.left !== initialState.left || state.right !== initialState.right;

  const handleZoomOut = () => {
    setState(initialState);
    onZoomOut && onZoomOut();
  };

  const zoomReferenceArea = useMemo(() => {
    if (!state.refAreaLeft || !state.refAreaRight || !enableZoom) return null;

    return (
      <ReferenceArea
        x1={state.refAreaLeft}
        x2={state.refAreaRight}
        strokeOpacity={0.3}
        yAxisId={DEFAULT_Y_AXIS_ID}
      />
    );
  }, [state, enableZoom]);

  // when zoomReferenceArea changes, call onZoomReferenceAreaChange
  useEffect(() => {
    onZoomReferenceAreaChange && onZoomReferenceAreaChange(zoomReferenceArea);
  }, [zoomReferenceArea, onZoomReferenceAreaChange]);

  if (!enableZoom) return emptyState;

  const handleZoom = () => {
    let { refAreaLeft, refAreaRight } = state;

    if (refAreaLeft === refAreaRight || refAreaRight === "") {
      setState({
        ...state,
        refAreaLeft: "",
        refAreaRight: "",
      });

      return;
    }

    if (refAreaLeft > refAreaRight)
      [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

    const { bottom, top, left, right } = getAxisDomains(
      data || [],
      yAxisLines,
      refAreaLeft,
      refAreaRight,
      xAxisKey
    );

    setState({
      refAreaLeft: "",
      refAreaRight: "",
      left,
      right,
      bottom,
      top,
    });

    onZoom && onZoom({ left, right });
  };

  const handleMouseDown = (e: any) =>
    setState({ ...state, refAreaLeft: e?.activeLabel || "" });

  const handleMouseMove = (e: any) =>
    state.refAreaLeft &&
    setState({ ...state, refAreaRight: e?.activeLabel || "" });

  return {
    initialState,
    isZoomedIn,
    handleZoomOut,
    handleMouseDown,
    handleMouseMove,
    handleZoom,
    zoomYAxisDomain: [state.bottom, state.top] as AxisDomain,
    zoomXAxisDomain: [state.left, state.right] as AxisDomain,
    zoomReferenceArea,
    resetZoomButton: (
      <>
        {onSyncDateWithZoom && (
          <SetDateToZoom
            isZoomedIn={isZoomedIn}
            onClick={() =>
              onSyncDateWithZoom({ left: state.left, right: state.right })
            }
            marginSide={zoomOutControlMarginSide}
          />
        )}
        <ResetZoomButton
          atBottom={Boolean(!onSyncDateWithZoom)}
          isZoomedIn={isZoomedIn}
          handleZoomOut={handleZoomOut}
          marginSide={zoomOutControlMarginSide}
        />
      </>
    ),
  };
};
