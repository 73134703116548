import { useState } from "react";
import classNames from "classnames";
import { FaRegBell as BellIcon } from "react-icons/fa";
import { IconButton, Popover } from "@mui/material";

import { checkForNewNotifications } from "shared/api/notifications/api";

import Tooltip from "features/ui/Tooltip";

import Watchlist from "./Watchlist";

const ICON_SIZE = 22;
const TOOLTIP_TEXT = "Notifications";
const ELEMENT_ID = "watchlist-action";

const WatchlistAction = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hasNotifications, setHasNotifications] = useState<boolean>(false);
  const [alertCount, setAlertCount] = useState<number>(0);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  // Calls an API to check for new notifications on every render
  checkForNewNotifications().then((response) => {
    setHasNotifications(response.data.unseen);
    setAlertCount(response.data.alertsCount);
  });

  const tooltipContent = <div className="text-xs">{TOOLTIP_TEXT}</div>;

  return (
    <>
      <Tooltip title={tooltipContent} placement="bottom-end">
        <IconButton
          onClick={handleClick}
          data-testid="open-watchlist-action"
          size="small"
          className={classNames({ "text-blue-500!": isOpen })}
        >
          {hasNotifications && (
            <div className="absolute rounded-full w-2 h-2 bg-red-500 top-1.5 right-1.5"></div>
          )}
          <BellIcon size={ICON_SIZE} />
        </IconButton>
      </Tooltip>
      <Popover
        id={ELEMENT_ID}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div className="min-w-[45rem]">
          <Watchlist alertsCount={alertCount}></Watchlist>
        </div>
      </Popover>
    </>
  );
};

export default WatchlistAction;
