import { useContext, useEffect, useRef, useState } from "react";
import AiTwinkle from "duck/ui/AiTwinkle";
import { DRILL_UTTERANCE } from "duck/ui/drill/constants";
import DuckDrillAttributeFilter from "duck/ui/drill/DuckDrillAttributeFilter";
import DuckDrillOptionsTree from "duck/ui/drill/DuckDrillOptionsTree";
import {
  filterAvailableItems,
  flattenTreeViewBaseItemsToSelectOptions,
  getDefaultAttributeIds,
  isSelectOption,
  prepareTreeViewItems,
} from "duck/ui/drill/utils";
import DuckLoadingAnimation from "duck/ui/DuckLoadingAnimation";
import { MdClose } from "react-icons/md";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  useTheme,
} from "@mui/material";
import { TreeViewBaseItem } from "@mui/x-tree-view";

import { DuckletContext } from "shared/contexts/DuckletContextWrapper";
import { useEmailFromJWT } from "shared/hooks";

import { SelectOption } from "features/ui/Select";

const MAX_SELECTED_ATTRIBUTES = 100;

interface DuckDrillDialogProps {
  groupBySelectOptions: SelectOption[];
  filterQueryString?: string;
  vehiclesFilterQueryString?: string;
  pageTitle: string;
  open: boolean;
  onClose: () => void;
}

const DuckDrillDialog = ({
  groupBySelectOptions,
  filterQueryString,
  vehiclesFilterQueryString,
  pageTitle,
  open,
  onClose,
}: DuckDrillDialogProps) => {
  const {
    setIsOpen,
    setAssignedUtterance,
    setAutoSubmit,
    setSelectedGroupByVehicleAttributes,
  } = useContext(DuckletContext);

  const userEmail = useEmailFromJWT();

  const [filter, setFilter] = useState("");

  const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);

  const [defaultSelectedAttributes, setDefaultSelectedAttributes] = useState<
    string[]
  >([]);

  const [expandedItems, setExpandedItems] = useState<string[]>([]);

  const [availableItems, setAvailableItems] = useState<TreeViewBaseItem[]>([]);

  const [filteredAvailableItems, setFilteredAvailableItems] = useState<
    TreeViewBaseItem[]
  >([]);

  const [flattenedLeaves, setFlattenedLeaves] = useState<SelectOption[]>([]);

  const [loadedDefaults, setLoadedDefaults] = useState(false);
  // We need loadingDefaults to update immediately so we useRef
  const loadingDefaults = useRef(false);

  useEffect(() => {
    const updateOptions = async (): Promise<void> => {
      const curatedItems = prepareTreeViewItems(
        groupBySelectOptions,
        vehiclesFilterQueryString
      );

      setAvailableItems(curatedItems);

      setFlattenedLeaves(flattenTreeViewBaseItemsToSelectOptions(curatedItems));

      // In development mode, effects are called twice which causes odd behavior.
      // Because the agent is non-deterministic, the selected attributes are changed
      // after each run of the agent. We use a ref to ensure that the defaults are
      // only loaded once.
      if (!loadingDefaults.current) {
        loadingDefaults.current = true;

        const agenticDefaultSelectedAttributes = await getDefaultAttributeIds(
          curatedItems,
          filterQueryString,
          userEmail
        );

        setDefaultSelectedAttributes(agenticDefaultSelectedAttributes);

        setSelectedAttributes(agenticDefaultSelectedAttributes);

        setLoadedDefaults(true);
        loadingDefaults.current = false;
      }
    };

    void updateOptions();
    // Putting in all the deps caused this effect to execute too many times.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupBySelectOptions, filterQueryString, vehiclesFilterQueryString]);

  useEffect(() => {
    const filteredItems = filterAvailableItems(availableItems, filter);
    setFilteredAvailableItems(filteredItems);
    setExpandedItems(filter === "" ? [] : filteredItems.map((item) => item.id));
  }, [filter, availableItems]);

  const theme = useTheme();

  const handleSubmitClick = () => {
    setIsOpen(true);
    const utterance = pageTitle
      ? `${DRILL_UTTERANCE} for ${pageTitle}`
      : DRILL_UTTERANCE;
    setAssignedUtterance(utterance);

    setSelectedGroupByVehicleAttributes(
      selectedAttributes
        .map((attribute) =>
          flattenedLeaves.find((leaf) => leaf.id === attribute)
        )
        .filter(isSelectOption)
    );
    setAutoSubmit(true);
    onClose();
  };

  const handleClearClick = () => {
    setSelectedAttributes([]);
  };

  const handleRestoreDefaultsClick = () => {
    setFilter("");
    setSelectedAttributes(defaultSelectedAttributes);
    setExpandedItems([]);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <div className="flex items-center space-x-4">
          <AiTwinkle
            fillColor={theme.palette.text.primary}
            strokeColor={theme.palette.text.primary}
          />
          <span>Optimize Splits</span>
        </div>
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
      >
        <MdClose />
      </IconButton>

      <DialogContent>
        <div className="h-[70vh] w-[550px] flex flex-col">
          {(!loadedDefaults || loadingDefaults.current) && (
            <DuckLoadingAnimation overrideMessage="Finding optimal dimensions..." />
          )}

          {loadedDefaults && !loadingDefaults.current && (
            <>
              <p className="pb-2 text-gray-400 text-sm">
                Select up to {MAX_SELECTED_ATTRIBUTES} vehicle dimensions you
                want to use for Optimizing Splits.
              </p>

              <DuckDrillAttributeFilter filter={filter} setFilter={setFilter} />

              <div className="flex-1 min-h-0 overflow-y-auto">
                <DuckDrillOptionsTree
                  availableItems={filteredAvailableItems}
                  flattenedLeaves={flattenedLeaves}
                  selectedAttributes={selectedAttributes}
                  setSelectedAttributes={setSelectedAttributes}
                  expandedItems={expandedItems}
                  setExpandedItems={setExpandedItems}
                />
              </div>

              <p className="pt-4 text-gray-400 text-xs shrink-0">
                {selectedAttributes.length} selected out of{" "}
                {MAX_SELECTED_ATTRIBUTES} attributes limit
              </p>
            </>
          )}
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} variant="text">
          Cancel
        </Button>
        <Button
          onClick={handleClearClick}
          variant="outlined"
          color="secondary"
          disabled={!loadedDefaults || loadingDefaults.current}
        >
          Clear
        </Button>
        <Button
          onClick={handleRestoreDefaultsClick}
          variant="outlined"
          color="secondary"
          disabled={!loadedDefaults || loadingDefaults.current}
        >
          Restore Recommended
        </Button>
        <Button
          onClick={handleSubmitClick}
          startIcon={<AiTwinkle fillColor="white" strokeColor="white" />}
          variant="contained"
          sx={{
            "&.Mui-disabled": {
              opacity: 0.3,
              bgcolor: theme.palette.primary.main,
              color: "white",
            },
          }}
          disabled={
            selectedAttributes.length > MAX_SELECTED_ATTRIBUTES ||
            selectedAttributes.length === 0 ||
            !loadedDefaults ||
            loadingDefaults.current
          }
        >
          Optimize Splits
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DuckDrillDialog;
