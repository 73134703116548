import { CountRequest, CountResponse } from "shared/api/api";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import {
  GetGroupRequest,
  getGroupRequestURI,
  Group,
  listGroupsCountRequestURI,
  ListGroupsRequest,
  listGroupsRequestURI,
} from "./api";

export const useListGroups = (args: ListGroupsRequest) =>
  usePaginatedAPI<typeof args, Group[]>(listGroupsRequestURI, args);

export const useListGroupsCount = (args: CountRequest) =>
  usePaginatedAPI<typeof args, CountResponse>(listGroupsCountRequestURI, args);

export const useGroup = (args: GetGroupRequest) =>
  useAPI<typeof args, Group>(getGroupRequestURI, args);
