import classNames from "classnames";
import { BsCardList } from "react-icons/bs";
import { IconButton } from "@mui/material";

import Tooltip from "features/ui/Tooltip";

interface Props {
  open: boolean;
  onClick: () => void;
  onClose: () => void;
}

const ViewFiltersButton = ({ open, onClick, onClose }: Props) => {
  const action = open ? onClose : onClick;

  return (
    <IconButton
      className="pl-0 "
      onClick={action}
      data-testid="show-filter-summary"
    >
      <Tooltip
        title={<span className="text-sm w-40">Show/hide filter summary</span>}
      >
        <BsCardList className={classNames({ "fill-blue-400": open })} />
      </Tooltip>
    </IconButton>
  );
};

export default ViewFiltersButton;
