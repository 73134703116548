import { useCallback, useMemo } from "react";
import { generatePath, Link } from "react-router-dom";

import {
  CalculatedAttribute as CalculatedAttributeModel,
  deleteCalculatedAttribute,
} from "shared/api/calculatedAttributes/api";
import {
  useListCalculatedAttributes,
  useListCalculatedAttributesCount,
} from "shared/api/calculatedAttributes/hooks";
import { getSortFilter } from "shared/api/utils";
import NewCTA from "shared/components/NewCTA";
import {
  CALCULATED_ATTRIBUTES_GENERIC_FILTER,
  CREATED_AT_FILTER,
  UPDATED_AT_FILTER,
} from "shared/filterDefinitions";
import { SortBy } from "shared/types";

import APIError from "features/ui/APIError";
import DeleteAction from "features/ui/DeleteAction";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell/types";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

import {
  ATTRIBUTES_PER_PAGE,
  CALCULATED_ATTRIBUTES_TITLE,
  CTA_TEXT,
} from "./constants";

const PAGE_KEY = "CalculatedAttributes";
const DEFAULT_SORT: SortBy = { name: "asc" };

const SCHEMA: SchemaEntry[] = [
  {
    label: "Name",
    accessor: "name",
    dataType: DataType.JSX,
    sortable: true,
    filter: CALCULATED_ATTRIBUTES_GENERIC_FILTER({
      label: "Name",
      fieldName: "name",
    }),
  },
  {
    label: "Description",
    accessor: "description",
    dataType: DataType.STRING,
    sortable: true,
    filter: CALCULATED_ATTRIBUTES_GENERIC_FILTER({
      label: "Description",
      fieldName: "description",
    }),
  },
  {
    label: "Updated",
    accessor: "updatedAt",
    dataType: DataType.DATE_WITH_TIME_UTC,
    sortable: true,
    filter: UPDATED_AT_FILTER,
  },
  {
    label: "Updated By",
    accessor: "updatedBy",
    dataType: DataType.STRING,
    sortable: true,
    filter: CALCULATED_ATTRIBUTES_GENERIC_FILTER({
      label: "Updated By",
      fieldName: "updatedBy",
    }),
  },
  {
    label: "Created",
    accessor: "createdAt",
    dataType: DataType.DATE_WITH_TIME_UTC,
    sortable: true,
    filter: CREATED_AT_FILTER,
  },
  {
    label: "Created By",
    accessor: "createdBy",
    dataType: DataType.STRING,
    sortable: true,
    filter: CALCULATED_ATTRIBUTES_GENERIC_FILTER({
      label: "Created By",
      fieldName: "createdBy",
    }),
  },
  {
    label: "Actions",
    accessor: "actions",
    dataType: DataType.JSX,
  },
];

const CalculatedAttributes = () => {
  const {
    filters,
    sort,
    manageFilterChange,
    manageOnSortChange,
    resetFilterSortState,
    resetFilters,
    initialized: filtersInitialized,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
    schemaAttributes: [],
  });

  const requestParams = {
    filter: getFiltersQuery(filters),
    sort: getSortFilter(sort),
  };

  const { data, error, isLoading, requestKey, ...paginationData } =
    useListCalculatedAttributes(requestParams);

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
    requestKey: countRequestKey,
  } = useListCalculatedAttributesCount(requestParams);

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const formatRow = useCallback(
    (attribute: CalculatedAttributeModel) => {
      const requestKeys = [requestKey, countRequestKey];

      const { ID, name } = attribute;
      const pathname = generatePath(routes.calculatedAttribute, {
        id: ID,
      });

      return {
        ...attribute,
        name: (
          <Link to={pathname} className="text-metabase-blue hover:underline">
            {name}
          </Link>
        ),
        actions: (
          <DeleteAction
            data={attribute}
            entityName="calculated attribute"
            deleteCallback={deleteCalculatedAttribute}
            entityRequestKeys={requestKeys}
            iconOnly={true}
          />
        ),
      };
    },
    [requestKey, countRequestKey]
  );

  const formattedData = useMemo(() => data?.map(formatRow), [data, formatRow]);

  return (
    <>
      <PageHeaderWrapper title={CALCULATED_ATTRIBUTES_TITLE}>
        <PageHeaderActionsWrapper>
          <NewCTA
            to={routes.newCalculatedAttribute}
            label={CTA_TEXT}
            testId="new-attribute-cta"
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <div className="flex my-2 items-center">
        <FiltersOverview
          filters={filters}
          tableSchema={SCHEMA}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto self-end"
          count={countData?.count as number}
          entityName="calculated attribute"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={SCHEMA}
          isLoading={isLoading}
          loadingRows={ATTRIBUTES_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          pageKey={PAGE_KEY}
          dense
        />
      )}
    </>
  );
};
export default CalculatedAttributes;
