import { AxiosResponse } from "axios";
import { useFlags } from "launchdarkly-react-client-sdk";

import { DeleteRequest, PermissionEntry } from "shared/api/api";
import { useConfigContext } from "shared/contexts/ConfigContext";
import { EntityWithPermissions } from "shared/types";

import DeleteAction from "features/ui/DeleteAction";
import { RequiredProps } from "features/ui/DeleteAction/DeleteAction";
import PermissionsSettingsAction from "features/ui/PermissionsDialog/PermissionsSettingsAction";

interface Props {
  entity: RequiredProps;
  entityType: EntityWithPermissions;
  permissions: PermissionEntry[];
  canEdit: boolean;
  deleteCallback: (args: DeleteRequest) => Promise<AxiosResponse<any, any>>;
  requestKeys?: string[];
}

const ICON_SIZE = 15;

const DeleteAndPermissionRowAction = ({
  entity,
  deleteCallback,
  entityType,
  canEdit,
  requestKeys,
  permissions,
}: Props) => {
  const { rbac: rbacFF } = useFlags();
  const {
    pages: { rbac },
  } = useConfigContext();
  const rbacEnabled = rbacFF && rbac;

  return (
    <>
      {rbacEnabled && (
        <PermissionsSettingsAction
          entity={entityType}
          entityId={entity.ID}
          entityName={entity.name}
          permissions={permissions}
          canEdit={canEdit}
          iconSize={ICON_SIZE}
          entityRequestKeys={requestKeys}
        />
      )}
      {(!rbacEnabled || (rbacEnabled && canEdit)) && (
        <DeleteAction
          data={entity}
          entityName={entityType}
          deleteCallback={deleteCallback}
          entityRequestKeys={requestKeys}
          iconOnly={true}
        />
      )}
    </>
  );
};

export default DeleteAndPermissionRowAction;
