import { getLangSmithClient } from "duck/graph/utils";
import {
  DUCK_FEEDBACK_ID_KEY_PREFIX,
  DUCK_FEEDBACK_SENTIMENT_KEY_PREFIX,
} from "duck/ui/feedback/constants";
import { Feedback } from "langsmith/dist/schemas";

const getFeedbackSentimentKey = (runId: string) =>
  `${DUCK_FEEDBACK_SENTIMENT_KEY_PREFIX}.${runId}`;

const getFeedbackIdKey = (runId: string) =>
  `${DUCK_FEEDBACK_ID_KEY_PREFIX}.${runId}`;

export const submitFeedback = async (
  runId: string,
  positive: boolean
): Promise<Feedback> => {
  const smithClient = getLangSmithClient();

  const feedback = await smithClient.createFeedback(runId, "user-feedback", {
    score: positive,
    comment: "from thumb button in duck ui",
  });

  sessionStorage.setItem(getFeedbackSentimentKey(runId), String(positive));
  sessionStorage.setItem(getFeedbackIdKey(runId), feedback.id);

  return feedback;
};

export const updateFeedback = async (
  runId: string,
  feedbackId: string,
  positive: boolean
): Promise<void> => {
  const smithClient = getLangSmithClient();
  await smithClient.updateFeedback(feedbackId, {
    score: positive,
  });

  sessionStorage.setItem(getFeedbackSentimentKey(runId), String(positive));
};

export const deleteFeedback = async (
  runId: string,
  feedbackId: string
): Promise<void> => {
  const smithClient = getLangSmithClient();
  await smithClient.deleteFeedback(feedbackId);

  sessionStorage.removeItem(getFeedbackSentimentKey(runId));
  sessionStorage.removeItem(getFeedbackIdKey(runId));
};

export const getSavedFeedbackSentiment = (runId: string): boolean | null => {
  const sentimentString = sessionStorage.getItem(
    getFeedbackSentimentKey(runId)
  );

  return sentimentString ? sentimentString === "true" : null;
};

export const getSavedFeedbackId = (runId: string): string | null =>
  sessionStorage.getItem(getFeedbackIdKey(runId));
