import { useFlags } from "launchdarkly-react-client-sdk";
import qs from "qs";
import { Link } from "react-router-dom";

import { SuggestedIssue } from "shared/api/suggestedIssues/api";
import { EXPLORE_IN_CLAIM_ANALYTICS_LABEL } from "shared/constants";
import { useConfigContext } from "shared/contexts/ConfigContext";
import { IssueTypes } from "shared/types";

import {
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import { isSuggestedIssue } from "pages/Issues/utils";
import { setReturnToEntityEntry } from "pages/shared/analytics/returnToEntity/utils";

import { getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";

interface Props {
  issue: IssueTypes;
}

const ClaimAnalyticsLink = ({ issue }: Props) => {
  const { claimAnalytics } = useFlags();
  const { pages } = useConfigContext();

  if (!claimAnalytics || !pages.claimAnalytics) {
    return null;
  }

  const { ID, name, claimFilter, atRiskPopulationFilter } = issue;

  const claimAnalyticsFilterKey = getFiltersKey(CLAIMS_PAGE_KEY);
  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);

  return (
    <Link
      to={{
        pathname: routes.claimAnalytics,
        search: qs.stringify(
          {
            [claimAnalyticsFilterKey]: claimFilter,
            [vehicleFilterKey]: atRiskPopulationFilter,
          },
          { arrayFormat: "indices" }
        ),
      }}
      className="ml-auto mt-2 text-metabase-blue hover:underline sm:text-sm"
      data-testid="explore-claim-analytics"
      onClick={() =>
        setReturnToEntityEntry({
          entityType: isSuggestedIssue(issue) ? "suggested_issue" : "issue",
          entityID: ID,
          entityName: name,
          entityRunDate: isSuggestedIssue(issue)
            ? (issue as SuggestedIssue).updated
            : undefined,
        })
      }
    >
      {EXPLORE_IN_CLAIM_ANALYTICS_LABEL}
    </Link>
  );
};

export default ClaimAnalyticsLink;
