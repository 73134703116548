import { OPENAI_API_KEY } from "duck/graph/constants";
import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import { MODEL_NAMES } from "duck/graph/types";
import { getTracer } from "duck/graph/utils";
import { initChatModel } from "langchain/chat_models/universal";
import { z } from "zod";
import { RunnableConfig } from "@langchain/core/runnables";

const RecommendedAttributes = z.object({
  recommendedAttributes: z.array(z.string()),
});

type RecommendedAttributesType = z.infer<typeof RecommendedAttributes>;

export const attributePickerAgent = async (
  eventFilterSummaryDescription: string,
  attributeOptions: string[],
  config: RunnableConfig = {}
): Promise<RecommendedAttributesType> => {
  console.debug("attributePickerAgent");
  const prompt = await loadPrompt(promptNames.DRILL_ATTRIBUTE_PICKER_AGENT);
  const tracer = getTracer();

  // This way of loading the model does not work with the "withStructuredOutput" method.
  // Langgraph thinks that the o3-mini model does not support structured output, even
  // though it does. For some reason, Langgraph is ok with it when the LLM is created
  // using the initChatModel method.
  // const llm = getLLM(O3_MINI_MODEL_SPEC).withStructuredOutput(RecommendedAttributes);
  const llm = (
    await initChatModel(MODEL_NAMES.O3_MINI, {
      modelProvider: "openai",
      openAIApiKey: OPENAI_API_KEY,
      callbacks: [tracer],
    })
  ).withStructuredOutput(RecommendedAttributes);

  const agent = prompt.pipe(llm);

  const { metadata: configMetadata, ...otherConfig } = config;

  const combinedConfig = {
    callbacks: [tracer],
    runName: "DUCK Attribute Picker",
    ...otherConfig,
    metadata: {
      modelName: MODEL_NAMES.O3_MINI,
      provider: "openai",
      ...configMetadata,
    },
  };

  return await agent.invoke(
    {
      input: {
        eventFilterSummaryDescription,
        attributeOptions,
      },
    },
    combinedConfig
  );
};
