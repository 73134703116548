export const DEFAULT_NUM_SEARCH_RESULTS = 10;
export const SEARCH_INPUT_DELAY = 300;
export const MIN_CHARS_TO_START_SEARCH = 0;
export const NO_OPTIONS_TEXT = "No results";
export const HEADER_TEXT = "Select the event types to alert on";

export const ALERT_TYPE_TITLE = "Alert Type";
export const ALERT_TYPE_TEXT =
  "Select the type of alert. Event alerts notify you of new occurrences \n" +
  "of events that satisfy a set of filters, while object alerts notify you\n" +
  "about updates and new events on individual objects";

export const ALERT_EVENT_TYPE_TITLE = "Select Event Type";
export const ALERT_EVENT_TYPE_TEXT =
  "Specify type of event you want to alert on";

export const ALERT_OBJECT_TYPE_TITLE = "Select Object Type";
export const ALERT_OBJECT_TYPE_TEXT =
  "Specify type of object you want to alert on";
