import { useListVehicles } from "shared/api/vehicles/hooks";
import { useTenantMileageUnit } from "shared/hooks";

import APIError from "features/ui/APIError";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";

import SignalEventPreviewIPTV from "./SignalEventPreviewIPTV";

interface Props {
  VINs: string[];
  dateFilter: FilterGroupState;
  numAllOccurrences: number;
}

const SignalEventPreviewIPTVWrapper = ({
  VINs,
  dateFilter,
  numAllOccurrences,
}: Props) => {
  const { data, isLoading, error } = useListVehicles({
    filter: getFiltersQuery(
      filterStateToFilterGroupState({
        VIN: {
          operator: FilterOperator.IN,
          values: VINs,
        },
      })
    ),
    // there is a 'min' validation on API
    limit: Math.max(VINs.length, 1),
    mileageUnit: useTenantMileageUnit(),
  });

  if (isLoading) {
    return;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data || data.length === 0) {
    return;
  }

  return (
    <SignalEventPreviewIPTV
      vehicles={data}
      dateFilter={dateFilter}
      numAllOccurrences={numAllOccurrences}
    />
  );
};

export default SignalEventPreviewIPTVWrapper;
