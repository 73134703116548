import { IconProps } from "./types";

const FailureModesIcon = ({ size }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9.59315 5.18521L10.6057 9.2344C9.79397 9.43309 8.71128 9.56271 7.50003 9.56271C6.28878 9.56271 5.20715 9.43309 4.39434 9.2344L5.4069 5.18521C6.04972 5.26703 6.75628 5.31272 7.50003 5.31272C8.24378 5.31272 8.95034 5.26703 9.59315 5.18521ZM9.33284 4.14609L8.53065 0.935215C8.2629 -0.13791 6.73715 -0.13791 6.4694 0.935215L5.66721 4.14396C6.22503 4.21303 6.8434 4.25022 7.50003 4.25022C8.15665 4.25022 8.77503 4.21197 9.33284 4.14609ZM14.0036 13.2974C14.1154 13.3251 14.2152 13.3884 14.2879 13.4777C14.3606 13.567 14.4023 13.6775 14.4068 13.7926C14.4112 13.9077 14.3781 14.0211 14.3125 14.1158C14.2469 14.2104 14.1522 14.2812 14.0429 14.3174L7.6679 16.4424C7.55893 16.4787 7.44113 16.4787 7.33215 16.4424L0.957153 14.3174C0.847813 14.2812 0.7532 14.2104 0.687571 14.1158C0.621943 14.0211 0.588854 13.9077 0.59329 13.7926C0.597726 13.6775 0.639446 13.567 0.712165 13.4777C0.784885 13.3884 0.884663 13.3251 0.996466 13.2974L3.5369 12.662L4.13722 10.264C5.05309 10.4892 6.22397 10.6242 7.50003 10.6242C8.77609 10.6242 9.94696 10.4892 10.8628 10.264L11.4632 12.662L14.0036 13.2974Z" />
  </svg>
);

export default FailureModesIcon;
