import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DuckContext } from "duck/context/DuckContextWrapper";
import DuckSubmitProcessor from "duck/ui/DuckSubmitProcessor";

import { DuckletContext } from "shared/contexts/DuckletContextWrapper";
import { sleep } from "shared/utils";

import { useAvailableData, useCtrlDKeyPress } from "./hooks";
import { LocationInfo } from "./types";

type DuckTextInputProps = {
  threadId: string;
  acquireLocationInformation: (reset: boolean) => LocationInfo;
  clearLocationInfo: () => void;
};

const DuckTextInput = ({
  threadId,
  acquireLocationInformation,
  clearLocationInfo,
}: DuckTextInputProps) => {
  const [utterance, setUtterance] = useState("");

  const [submitting, setSubmitting] = useState(false);

  const isCtrlDKeyPressed = useCtrlDKeyPress();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { loading } = useContext(DuckContext);
  const { assignedUtterance, setAssignedUtterance, autoSubmit, setAutoSubmit } =
    useContext(DuckletContext);

  useEffect(() => {
    const processUtterance = async () => {
      if (assignedUtterance) {
        setUtterance(assignedUtterance);
        await sleep(10);
        setAssignedUtterance("");
        await sleep(10);
        adjustHeight();
      }

      // Process the autosubmission after assigning the utterance.
      // We wait for the setUtterance state to be set before submitting.
      if (autoSubmit) {
        submit();
        setAutoSubmit(false);
      }
    };
    if (assignedUtterance || autoSubmit) {
      void processUtterance();
    }
    // We only want this effect to run when assignedUtterance or autoSubmit changes,
    // not when the related functions changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignedUtterance, autoSubmit]);

  const { availableData } = useAvailableData();

  const handleSubmitComplete = useCallback(() => {
    setSubmitting(false);
    void assignFocus();
  }, []);

  const submit = async (): Promise<void> => {
    setSubmitting(true);
    // Send the utterance to the agent before clearing it.
    await sleep(100);
    setUtterance("");
    // Let the utterance clear before adjusting the height
    await sleep(100);
    adjustHeight();
  };

  const adjustHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    textarea.style.height = "auto";
    textarea.style.height = `${Math.min(70, textarea.scrollHeight)}px`;
    // Scroll to the bottom. Without this call, the textarea does not scroll all
    // the way to the bottom when a new line is added.
    textarea.scrollTop = textarea.scrollHeight;
  }, []);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>): void => {
    setUtterance(e.target.value);
    adjustHeight();
  };

  const assignFocus = async () => {
    // This brief delay is necessary for this to operate correctly
    await sleep(10);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleKeyDown = async (event: KeyboardEvent<HTMLTextAreaElement>) => {
    if (
      event.key === "Enter" &&
      !event.shiftKey &&
      (!!utterance.trim() || isCtrlDKeyPressed)
    ) {
      event.preventDefault(); // Prevent default to avoid new line in multiline input
      await submit();
      await assignFocus();
    }
  };

  // The use of the MUI TextField component leads to runtime errors with the message of:
  // "ResizeObserver loop completed with undelivered notifications"
  // This happens when horizontally resizing the component over the CSS breakpoint boundary.
  // Using the native textarea component avoids this issue.
  return (
    <>
      <textarea
        data-testid="duck-text-input"
        ref={textareaRef}
        value={utterance}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        placeholder="Ask something"
        disabled={loading}
        rows={1}
        style={{
          width: "100%",
          backgroundColor: "white",
          padding: "4px 8px",
          borderRadius: "0.375rem",
          resize: "none",
          overflow: "auto",
        }}
      />
      {submitting && (
        <DuckSubmitProcessor
          availableData={availableData}
          utterance={
            isCtrlDKeyPressed
              ? "Please download images of the graph"
              : utterance.trim()
          }
          threadId={threadId}
          acquireLocationInformation={acquireLocationInformation}
          clearLocationInfo={clearLocationInfo}
          captureImages={isCtrlDKeyPressed}
          handleComplete={handleSubmitComplete}
          startTime={new Date().getTime()}
        />
      )}
    </>
  );
};

export default DuckTextInput;
