import React from "react";
import classNames from "classnames";
import { Paper, PaperProps } from "@mui/material";

import { TestProps } from "shared/types";

interface CardProps extends TestProps {
  children?: React.ReactNode;
  classNames?: string;
  variant?: PaperProps["variant"];
}

/**
 * Card is a common flexbox container component
 */
const Card = ({ children, classNames: cNames, testId, variant }: CardProps) => (
  <Paper
    elevation={variant === "outlined" ? 0 : 2}
    children={children}
    className={classNames("py-3 px-6 text-gray-700!", cNames)}
    data-testid={testId}
    variant={variant}
  />
);

export default Card;
