import { SyntheticEvent, useCallback, useRef } from "react";
import { AiOutlineCheck as CheckIcon } from "react-icons/ai";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";

import Accordion from "features/ui/Accordion";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import SingleFilter from "features/ui/Filters/SingleFilter/SingleFilter";
import { FilterChangeCallback } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

import { ADDED_AS_QUICK_FILTER_TEXT } from "./constants";

interface Props {
  schema: SchemaEntry<string>[];
  activeFilterKeys: string[];
  expanded: string | false;
  filters: FilterGroupState;
  handleAccordionChange: (
    panel: string
  ) => (event: SyntheticEvent, isExpanded: boolean) => void;
  onFilterChange: FilterChangeCallback;
  onResetFilters: (fieldNames?: string[]) => void;
  filtersInitialized?: boolean;
  onFilterValuesOrOperatorChange: FilterChangeCallback;
  defaultFilters?: FilterGroupState;
  pageKey: string;
  quickFilterAttributes?: string[];
}

const VirtualizedFiltersList = ({
  schema,
  activeFilterKeys,
  expanded,
  filters,
  handleAccordionChange,
  onFilterChange,
  onResetFilters,
  filtersInitialized,
  onFilterValuesOrOperatorChange,
  defaultFilters,
  pageKey,
  quickFilterAttributes,
}: Props) => {
  const virtuosoRef = useRef<VirtuosoHandle>(null);

  // Function to scroll to first item
  const scrollToFirstItem = useCallback(() => {
    virtuosoRef.current?.scrollToIndex({
      index: 0,
      align: "start",
      behavior: "auto",
    });
    setTimeout(() => {
      const firstAccordion = document.querySelector(
        '[data-testid^="accordion-"]'
      );
      (firstAccordion?.querySelector("button") as HTMLElement)?.focus();
    }, 100);
  }, []);

  const formattedSchemas = schema.map(({ label, accessor, filter }) => {
    const isSelected = activeFilterKeys.includes(accessor);
    const isAddedAsQuickFilter = quickFilterAttributes?.includes(accessor);
    const title = (
      <div className="flex items-center w-full">
        <div className="flex items-center space-x-3 w-full">
          {(isSelected && (
            <CheckIcon size={20} className="text-blue-400" />
          )) || <div className="w-5"></div>}
          <span className="capitalize">{filter?.label || label}</span>
        </div>

        {isAddedAsQuickFilter && (
          <div className="flex flex-row text-gray-500 ml-auto mr-4 whitespace-nowrap items-center gap-2">
            {ADDED_AS_QUICK_FILTER_TEXT}
          </div>
        )}
      </div>
    );

    return (
      <Accordion
        key={accessor}
        id={accessor}
        title={title}
        expanded={expanded === accessor}
        disabled={isAddedAsQuickFilter}
        onChange={handleAccordionChange(accessor)}
        className={expanded === accessor ? "bg-gray-50!" : ""}
      >
        <SingleFilter
          autoFocus={expanded === accessor}
          filter={filter}
          activeFilters={filters}
          onFilterChange={onFilterChange}
          onFiltersReset={onResetFilters}
          filtersInitialized={filtersInitialized}
          onValueOrOperatorChange={onFilterValuesOrOperatorChange}
          hideCTA
          defaultFilters={defaultFilters}
          pageKey={pageKey}
        />
      </Accordion>
    );
  });

  return (
    <>
      <Virtuoso
        tabIndex={-1}
        ref={virtuosoRef}
        data={formattedSchemas}
        itemContent={(index: number) => formattedSchemas[index]}
      />
      {/* Add a hidden button that links back to the first accordion */}
      <button
        className="sr-only"
        tabIndex={0}
        onFocus={() => {
          scrollToFirstItem();
        }}
        aria-label="Return to first attribute"
      />
    </>
  );
};

export default VirtualizedFiltersList;
