import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { useGroup } from "shared/api/rbac/hooks";

import APIError from "features/ui/APIError";
import Board from "features/ui/Board";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

import { CreateGroupProps } from "./Details/CardActions";
import CardOverview from "./Details/CardOverview";
import GroupMembers from "./Details/GroupMembers";
import GroupActions from "./GroupActions";
import { toFEGroupMemberAccess } from "./utils";

type GroupParams = {
  id: string;
};

const Group = () => {
  const { id } = useParams<GroupParams>();

  const {
    data,
    isLoading,
    error,
    requestKey: groupRequestKey,
  } = useGroup({
    id,
  });

  if (error) {
    return <APIError error={error} />;
  }

  if (isLoading) {
    return <Skeleton height={300} count={2} />;
  }

  if (!data) {
    return (
      <div className="py-4 text-gray-400 text-sm">No data for Group {id}.</div>
    );
  }

  const createGroupProps: CreateGroupProps = {
    groupData: {
      ID: data.ID,
      name: data.name,
      description: data.description,
      users: data.users.map((x) => x.email),
      acl: data.access,
      memberAccess: toFEGroupMemberAccess(data.memberAccess),
    },
    groupRequestKey,
    action: "update",
    step: 0,
  };

  const boardElements: JSX.Element[][] = [
    [
      <CardOverview group={data} createGroupProps={createGroupProps} />,
      <GroupMembers group={data} groupRequestKey={groupRequestKey} />,
    ],
    [],
  ];

  const { name, canEdit, admin } = data;

  return (
    <>
      <PageHeaderWrapper title={name}>
        {canEdit && !admin && (
          <PageHeaderActionsWrapper>
            <GroupActions group={data} groupRequestKeys={[groupRequestKey]} />
          </PageHeaderActionsWrapper>
        )}
      </PageHeaderWrapper>
      <Board elements={boardElements} />
    </>
  );
};

export default Group;
