import TextField, { BaseTextFieldProps } from "@mui/material/TextField";

import { TestProps } from "shared/types";
import { toSnakeCase } from "shared/utils";

export interface InputProps extends TestProps, BaseTextFieldProps {
  value?: string | number;
  label?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: React.ReactElement;
  characterLimit?: number;
  min?: number;
  max?: number;
  withPlaceholder?: boolean;
}

const DEFAULT_TYPE = "text";

/**
 * Simple text input component
 */
const Input = ({
  label,
  value,
  onChange,
  placeholder,
  testId,
  disabled = false,
  type = DEFAULT_TYPE,
  icon,
  characterLimit,
  className,
  min,
  max,
  fullWidth = true,
  helperText,
  withPlaceholder = false,
  sx = {},
  ...otherProps
}: InputProps) => {
  const additionalText =
    typeof value === "string" && characterLimit ? (
      <div className="pointer-events-none text-sm text-gray-400 leading-6">
        {value?.length || 0}/{characterLimit}
      </div>
    ) : undefined;

  const iconAndHelperText = icon ? (
    <div className="flex items-center">
      {icon}
      {additionalText}
    </div>
  ) : (
    additionalText
  );

  return (
    <TextField
      {...otherProps}
      id={label && toSnakeCase(label)}
      label={label}
      variant="outlined"
      type={type}
      disabled={disabled}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      helperText={helperText}
      className={className}
      size="small"
      fullWidth={fullWidth}
      margin="none"
      sx={{
        "[placeholder]": {
          textOverflow: withPlaceholder ? "ellipsis" : undefined,
        },
        ...sx,
      }}
      slotProps={{
        input: {
          endAdornment: iconAndHelperText,
          className: withPlaceholder ? "text-sm!" : undefined,
        },

        htmlInput: {
          className: withPlaceholder && "pr-1!",
          maxLength: characterLimit,
          "data-testid": testId,
          min: min !== undefined ? min : undefined,
          max: max !== undefined ? max : undefined,
        },
      }}
    />
  );
};

export default Input;
