import { useAPI } from "shared/api/hooks";

import * as api from "./api";

export const useInstanceManagementTenantList = (
  args: api.ListInstanceManagementTenantsRequest
) =>
  useAPI<typeof args, api.InstanceManagementTenant[]>(
    api.listInstanceManagementTenantsRequestURI,
    args
  );
