import { useFlags } from "launchdarkly-react-client-sdk";
import {
  AiOutlineFileProtect as ClaimsIcon,
  AiOutlineExclamationCircle as IssuesIcon,
  AiOutlineCar as VehiclesIcon,
} from "react-icons/ai";
import { GiAutoRepair as RepairIcon } from "react-icons/gi";

import { APIPaginatedRequest, listLaborCodes, listParts } from "shared/api/api";
import { listClaims } from "shared/api/claims/api";
import { useClaimAttributes } from "shared/api/claims/hooks";
import { listInspections } from "shared/api/inspections/api";
import { listIssues } from "shared/api/issues/api";
import { listRepairs } from "shared/api/repairs/api";
import { listSignalEvents } from "shared/api/signalEvents/api";
import { listSuggestedIssues } from "shared/api/suggestedIssues/api";
import { listVehicles } from "shared/api/vehicles/api";
import { useConfigContext } from "shared/contexts/ConfigContext";
import { getAttributeMap, getDisplayLabelFunction } from "shared/schemas/utils";

import {
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY as VEHICLES_CA_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import {
  SIGNAL_EVENTS_PAGE_KEY,
  VEHICLES_PAGE_KEY as VEHICLES_SE_PAGE_KEY,
} from "pages/SignalEventsAnalytics/constants";
import {
  CLAIM_ANALYTICS_PAGE_CONFIG_KEY,
  INSPECTION_ANALYTICS_PAGE_CONFIG_KEY,
  ISSUES_PAGE_CONFIG_KEY,
  REPAIR_ANALYTICS_PAGE_CONFIG_KEY,
  SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY,
} from "pages/SuperAdmin/PagesConfig/types";

import { FilterOperator } from "features/ui/Filters/types";

import { RouteKey } from "services/routes";

interface SearchFieldConfig {
  fieldName: string;
  filterOperator: FilterOperator;
}

export interface SearchResource {
  type: string;
  searchFields: SearchFieldConfig[];
  loader: (args: APIPaginatedRequest) => Promise<any>;
  idField: string;
  nameField: string;
  orderBy?: string;
  icon: JSX.Element;
  routeKey: RouteKey;

  // bellow props are used only in a case when we want to navigate to a list page with filters
  isRouteList?: boolean;
  routeFilterKey?: string;
  listingFieldId?: string;
  resetFilterKey?: string; // we want to set vehicle filter to defaults when navigating
}

export const useSearchConfig = (): SearchResource[] => {
  const { pages } = useConfigContext();
  const {
    issues,
    suggestedIssues,
    claimAnalytics,
    repairAnalytics,
    inspectionAnalytics,
  } = useFlags();
  // Add this to load claim attributes

  const { data: claimAttributes } = useClaimAttributes({});
  const attributeMap = getAttributeMap(claimAttributes);
  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  return [
    {
      idField: "VIN",
      nameField: "VIN",
      type: "Vehicle",
      searchFields: [
        { fieldName: "VIN", filterOperator: FilterOperator.CONTAINS },
      ],
      loader: listVehicles,
      icon: <VehiclesIcon />,
      routeKey: RouteKey.VinView,
    },
    pages[REPAIR_ANALYTICS_PAGE_CONFIG_KEY] &&
      repairAnalytics && {
        idField: "ID",
        nameField: "externalID",
        type: "Repair",
        searchFields: [
          {
            fieldName: "externalID",
            filterOperator: FilterOperator.CONTAINS,
          },
        ],
        loader: listRepairs,
        icon: <RepairIcon />,
        routeKey: RouteKey.RepairAnalyticsDetails,
      },
    pages[INSPECTION_ANALYTICS_PAGE_CONFIG_KEY] &&
      inspectionAnalytics && {
        idField: "ID",
        nameField: "externalID",
        type: "Inspection",
        searchFields: [
          {
            fieldName: "externalID",
            filterOperator: FilterOperator.CONTAINS,
          },
        ],
        loader: listInspections,
        icon: <ClaimsIcon />,
        routeKey: RouteKey.RepairAnalyticsDetails,
      },
    pages[CLAIM_ANALYTICS_PAGE_CONFIG_KEY] &&
      claimAnalytics && {
        idField: "ID",
        nameField: "externalID",
        type: "Claim",
        searchFields: [
          {
            fieldName: "externalID",
            filterOperator: FilterOperator.CONTAINS,
          },
        ],
        loader: listClaims,
        icon: <ClaimsIcon />,
        routeKey: RouteKey.ClaimAnalyticsDetails,
      },
    pages[CLAIM_ANALYTICS_PAGE_CONFIG_KEY] &&
      claimAnalytics && {
        idField: "ID",
        nameField: "ID",
        type: "Part",
        searchFields: [
          {
            fieldName: "ID",
            filterOperator: FilterOperator.CONTAINS,
          },
          {
            fieldName: "description",
            filterOperator: FilterOperator.CONTAINS,
          },
        ],
        loader: listParts,
        icon: <ClaimsIcon />,
        routeKey: RouteKey.ClaimAnalytics,
        isRouteList: true,
        routeFilterKey: CLAIMS_PAGE_KEY,
        listingFieldId: "failedPartNumber",
        resetFilterKey: VEHICLES_CA_PAGE_KEY,
      },
    pages[CLAIM_ANALYTICS_PAGE_CONFIG_KEY] &&
      claimAnalytics && {
        idField: "ID",
        nameField: "ID",
        type: getDisplayLabel("laborCode", "Labor Code"),
        searchFields: [
          {
            fieldName: "ID",
            filterOperator: FilterOperator.CONTAINS,
          },
          {
            fieldName: "description",
            filterOperator: FilterOperator.CONTAINS,
          },
        ],
        loader: listLaborCodes,
        icon: <ClaimsIcon />,
        routeKey: RouteKey.ClaimAnalytics,
        isRouteList: true,
        routeFilterKey: CLAIMS_PAGE_KEY,
        listingFieldId: "laborCode",
        resetFilterKey: VEHICLES_CA_PAGE_KEY,
      },
    pages[SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY] && {
      idField: "ID",
      nameField: "ID",
      type: "Signal event",
      searchFields: [
        {
          fieldName: "ID",
          filterOperator: FilterOperator.CONTAINS,
        },
        {
          fieldName: "description",
          filterOperator: FilterOperator.CONTAINS,
        },
      ],

      loader: listSignalEvents,
      icon: <ClaimsIcon />,
      routeKey: RouteKey.SignalEventAnalytics,
      isRouteList: true,
      routeFilterKey: SIGNAL_EVENTS_PAGE_KEY,
      listingFieldId: "signalEventID",
      resetFilterKey: VEHICLES_SE_PAGE_KEY,
    },
    pages[ISSUES_PAGE_CONFIG_KEY] &&
      issues && {
        idField: "ID",
        nameField: "name",
        type: "Issue",
        searchFields: [
          {
            fieldName: "name",
            filterOperator: FilterOperator.CONTAINS,
          },
          {
            fieldName: "externalID",
            filterOperator: FilterOperator.CONTAINS,
          },
        ],
        loader: listIssues,
        icon: <IssuesIcon />,
        routeKey: RouteKey.Issue,
      },
    pages[ISSUES_PAGE_CONFIG_KEY]?.suggestedIssues &&
      suggestedIssues && {
        idField: "ID",
        nameField: "name",
        type: "Suggested Issue",
        searchFields: [
          {
            fieldName: "name",
            filterOperator: FilterOperator.CONTAINS,
          },
          {
            fieldName: "description",
            filterOperator: FilterOperator.CONTAINS,
          },
        ],
        orderBy: "updatedAt:desc",
        loader: listSuggestedIssues,
        icon: <IssuesIcon />,
        routeKey: RouteKey.SuggestedIssue,
      },
  ].filter(Boolean);
};
