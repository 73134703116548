import React from "react";
import { FiTrash2 as DeleteIcon } from "react-icons/fi";
import { MdDragIndicator as DragIcon } from "react-icons/md";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { IconButton, TextField } from "@mui/material";

import TextArea from "features/ui/TextArea";

import { BookmarkState } from "./types";

interface ConfigRowProps {
  row: BookmarkState;
  updateValueByID: (id: string, row: BookmarkState) => void;
  onDelete: (id: string) => void;
}

const ConfigRow = ({ row, updateValueByID, onDelete }: ConfigRowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: row.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const updateValue = (
    internalID: string,
    key: string,
    value: boolean | string
  ) => {
    updateValueByID(internalID, { ...row, [key]: value });
  };

  return (
    <div className="flex space-x-2 mb-2 w-full" ref={setNodeRef} style={style}>
      <DragIcon
        {...attributes}
        {...listeners}
        size={22}
        className="cursor-grab hover:text-black select-none shrink-0 mt-2"
        data-testid={`rb-rule-drag-handle-${row.id}`}
      />
      <TextField
        label="Title"
        defaultValue={row.title}
        size="small"
        className="shrink-0"
        placeholder="Enter Title"
        onChange={(change) => updateValue(row.id, "title", change.target.value)}
      />
      <div className="w-96">
        <TextArea
          label="Path"
          defaultValue={row.path}
          size="small"
          className="shrink-0"
          placeholder="Enter path"
          onChange={(change) =>
            updateValue(row.id, "path", change.target.value)
          }
        />
      </div>
      <div className="w-96">
        <TextArea
          label="Description"
          defaultValue={row.description}
          size="small"
          className="shrink-0"
          placeholder="Enter description"
          onChange={(change) =>
            updateValue(row.id, "description", change.target.value)
          }
        />
      </div>
      <IconButton onClick={() => onDelete(row.id)} size="small" className="h-8">
        <DeleteIcon className="text-red-600" />
      </IconButton>
    </div>
  );
};

export default ConfigRow;
