import { generatePath, Link } from "react-router-dom";

import { useConfigContext } from "shared/contexts/ConfigContext";

import { routes } from "services/routes";

const DEFAULT_CLASS = "text-metabase-blue hover:underline";

// asterisk is a reserved word and is not URL encoded by design
// - generatePath() removes the last asterix if it is present.
// With this helper we avoid that so that single fleet page shows correctly.
// (Ideal but longer solution would be to not use fleet names in the URIs at all..)
const asteriskFix = (str: string): string => str.replaceAll("*", "%2A");

const getFleetPathname = (fleetName: string) =>
  generatePath(routes.fleetView, {
    fleetName: asteriskFix(encodeURIComponent(fleetName)),
  });

interface SingleFleetLinkProps {
  fleetName: string | null;
  className?: string;
}

const SingleFleetLink = ({
  fleetName,
  className = DEFAULT_CLASS,
}: SingleFleetLinkProps) => {
  const { pages } = useConfigContext();

  if (!fleetName) return null;

  if (!pages.fleets) {
    return <div>{fleetName}</div>;
  }

  return (
    <Link to={getFleetPathname(fleetName)} className={className}>
      {fleetName}
    </Link>
  );
};

export default SingleFleetLink;
