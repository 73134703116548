import { useState } from "react";

import { RISK_MODEL_PREDICTIONS_GENERIC_FILTER } from "shared/filterDefinitions";
import { useInspectionItemsSchema } from "shared/schemas/inspectionItemsSchema";
import useRepairPartSchema from "shared/schemas/repairPartSchema";
import { useRiskModelPredictionsSchema } from "shared/schemas/riskModelPredictionsSchema";
import useVehicleECUsSchema from "shared/schemas/vehicleECUsSchema";
import useVehicleOptionsSchema from "shared/schemas/vehicleOptionsSchema";

import DropdownSelect from "features/ui/DropdownSelect";
import FilterBuilder from "features/ui/Filters/FilterBuilder";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getFilterLabel } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";

import {
  encodeExistsFilterValue,
  getFilterGroupStateFromExistsFilterValue,
} from "./utils";

interface Props {
  label: string;
  fieldName: string;
  onChange: (values: string[]) => void;
  disabled?: boolean;
  currentFilterValues: string[];
  isAdvancedFilterEditor?: boolean;
  relationEndpoint: string;
  pageKey?: string;
  autoFocus?: boolean;
}

const ExistsFilter = ({
  label,
  fieldName,
  onChange,
  isAdvancedFilterEditor,
  currentFilterValues = [],
  relationEndpoint,
  disabled,
  pageKey = "",
  autoFocus,
}: Props) => {
  const existsPageKey = `${pageKey}-exists-${fieldName}`;
  const pendingFiltersKey = getPendingFiltersKey(existsPageKey);

  const schema = useRelationshipFilterSchema(relationEndpoint);
  const [filterSummaryOpen, setFilterSummaryOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(!!autoFocus);

  const filteredSchema = schema.filter(
    ({ filter, hideFilter }) => filter && !hideFilter
  );

  const existsFilterSortState = useFilterSortState({
    pageKey: existsPageKey,
    defaultFilterValues: getFilterGroupStateFromExistsFilterValue(
      currentFilterValues[0] || "[]"
    ),
    disableUsingQuery: true,
    disableUsingLocalStorage: true,
    pendingFiltersLocalStorageKey: pendingFiltersKey,
    schemaAttributes: schema.map(({ accessor }) => accessor),
  });

  const onApplyFilters = (newFilterState: FilterGroupState) => {
    existsFilterSortState.updateFilters(newFilterState);
    onChange([encodeExistsFilterValue(newFilterState)]);
    setDropdownOpen(false);
  };

  const filterLabel = `${label} Filters`;
  const filterLabelWithCount = getFilterLabel(
    `${label} Filters`,
    existsFilterSortState.filters
  );

  if (isAdvancedFilterEditor) {
    return (
      <FilterBuilder
        filterBuilderState={existsFilterSortState.filters}
        filtersSchema={filteredSchema}
        onChange={onApplyFilters}
        inline
        embedded
        disabled={disabled}
      />
    );
  }

  return (
    <div>
      <div className="flex">
        <DropdownSelect
          testId={`filter-exists-${fieldName}-dropdown`}
          label={filterLabelWithCount}
          buttonClass="mt-6 h-[38px] mr-4"
          open={dropdownOpen}
          disabled={disabled}
          onOpen={(open) => {
            setDropdownOpen(open);
          }}
          content={
            <FilterSelector
              schema={filteredSchema}
              filterSortState={existsFilterSortState}
              title={filterLabel}
              testId={`filter-exists-${fieldName}`}
              onApplyFilters={onApplyFilters}
              pendingFiltersKey={pendingFiltersKey}
              disableAdvancedFilter
            />
          }
        />
        <ViewFiltersButton
          open={filterSummaryOpen}
          onClick={() => setFilterSummaryOpen(true)}
          onClose={() => setFilterSummaryOpen(false)}
        />
      </div>
      <FiltersSummary
        open={filterSummaryOpen}
        filterStates={[
          {
            name: "",
            filters: existsFilterSortState.filters,
            schema: filteredSchema,
          },
        ]}
      />
    </div>
  );
};

const useRelationshipFilterSchema = (
  relationshipEndpoint: string
): SchemaEntry[] => {
  const schemas: Record<string, SchemaEntry[]> = {
    "/v1/vehicleECUs": useVehicleECUsSchema(["VIN"]).schema,
    "/v1/inspections/items": useInspectionItemsSchema(["VIN"]).schema,
    "/v1/riskModelPredictions": useRiskModelPredictionsSchema(
      RISK_MODEL_PREDICTIONS_GENERIC_FILTER,
      ["failureMode", "VIN"]
    ).schema,
    "/v1/vehicleOptions": useVehicleOptionsSchema(["VIN"]).schema,
    "/v1/repairParts": useRepairPartSchema(["repairID"], ["ID"]).schema,
  };

  return schemas[relationshipEndpoint] || [];
};

export default ExistsFilter;
