import { formatNumber } from "shared/utils";

interface Props {
  count: number;
  label: string;
  textColorClass: string;
  lineColorClass: string;
}

const CountBox = ({ count, label, textColorClass, lineColorClass }: Props) => (
  <div className="flex-1 flex flex-col justify-center items-center shadow-sm px-4 py-3 text-center rounded-sm">
    <div
      className={`flex w-full items-center text-3xl font-semibold ${textColorClass}`}
      data-testid={`failure-mode-${label}-count`}
    >
      <div className={`h-px w-full mr-2 ${lineColorClass}`} />
      {formatNumber(count)}
      <div className={`h-px w-full ml-2 ${lineColorClass}`} />
    </div>
    <div className="text-gray-500">{label}</div>
  </div>
);

export default CountBox;
