import { AxiosResponse } from "axios";

import client from "shared/api/axios";

export interface EmbeddingURLPayloadResource {
  dashboard?: number;
  question?: number;
}

interface EmbeddingURLPayload {
  resource: EmbeddingURLPayloadResource;
  params: object;
  exp: number;
}

type BoolString = "true" | "false";

interface EmbeddingURLOptions {
  bordered: BoolString;
  titled: BoolString;
  theme?: "night";
}

export interface EmbeddingURLRequest {
  payload: EmbeddingURLPayload;
  options: EmbeddingURLOptions;
}

interface EmbeddingURLResponse {
  url: string;
}

export const createEmbeddingURL = (data: EmbeddingURLRequest) =>
  client
    .post<
      EmbeddingURLRequest,
      AxiosResponse<EmbeddingURLResponse>
    >("/metabaseEmbeddingUrl", data)
    .then(({ data }) => data);
