import { IconProps } from "./types";

const BackToEntityIcon = ({ size }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(0, 3)">
      <path d="M4.81877 9.4999C4.56612 9.4999 4.34983 9.4108 4.16991 9.23259C3.98999 9.05438 3.90002 8.84015 3.90002 8.5899V3.1299C3.90002 2.87965 3.98999 2.66542 4.16991 2.48722C4.34983 2.30901 4.56612 2.2199 4.81877 2.2199H6.96252V1.30725C6.96252 1.05624 7.05261 0.842263 7.23279 0.665319C7.41288 0.488375 7.62943 0.399902 7.88242 0.399902H9.72209C9.97509 0.399902 10.1909 0.489007 10.3696 0.667215C10.5482 0.845424 10.6375 1.05965 10.6375 1.3099V2.2199H12.7813C13.0339 2.2199 13.2502 2.30901 13.4301 2.48722C13.6101 2.66542 13.7 2.87965 13.7 3.1299V8.5899C13.7 8.84015 13.6101 9.05438 13.4301 9.23259C13.2502 9.4108 13.0339 9.4999 12.7813 9.4999H4.81877ZM7.88127 2.2199H9.71877V1.3099H7.88127V2.2199ZM5.73752 3.1299H4.81877V8.5899H5.73752V3.1299ZM10.9438 8.5899V3.1299H6.65627V8.5899H10.9438ZM11.8625 3.1299V8.5899H12.7813V3.1299H11.8625Z" />
      <path d="M4.50481 11.0698C4.50481 11.2631 4.34811 11.4198 4.15481 11.4198L1.18496 11.4198C0.991658 11.4198 0.834957 11.2631 0.834957 11.0698L0.834958 8.09998C0.834958 7.90668 0.991658 7.74998 1.18496 7.74998C1.37826 7.74998 1.53496 7.90668 1.53496 8.09998L1.53496 10.7198L4.15481 10.7198C4.34811 10.7198 4.50481 10.8765 4.50481 11.0698Z" />
    </g>
  </svg>
);

export default BackToEntityIcon;
