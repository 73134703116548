import React, { useEffect, useState } from "react";

import { useVehicle } from "shared/api/vehicles/hooks";
import { useTenantMileageUnit } from "shared/hooks";

import ServiceScheduleContent from "./ServiceScheduleContent";
import ServiceScheduleSelections, {
  InferredCategoriesOnly,
} from "./ServiceScheduleSelections";

interface Props {
  vin: string;
}

const ServiceSchedule = ({ vin }: Props) => {
  const { data } = useVehicle({ vin, mileageUnit: useTenantMileageUnit() });
  const { vehicleCategoryID, transportCategoryID } = data || {};

  const [selectedCategories, setSelectedCategories] =
    useState<InferredCategoriesOnly>();

  useEffect(() => {
    if (!data) return;

    setSelectedCategories({ vehicleCategoryID, transportCategoryID });
  }, [vin, data, vehicleCategoryID, transportCategoryID]);

  const hasBothCategories =
    selectedCategories?.transportCategoryID &&
    selectedCategories.vehicleCategoryID;

  return (
    <div className="py-4">
      <div className="lg:flex space-y-10 lg:space-y-0 lg:space-x-10">
        <ServiceScheduleSelections
          setSelected={setSelectedCategories}
          selectedCategories={selectedCategories}
          inferredCategories={{ vehicleCategoryID, transportCategoryID }}
        />
        {(hasBothCategories && (
          <ServiceScheduleContent
            vehicleCategoryID={selectedCategories.vehicleCategoryID!}
            transportCategoryID={selectedCategories.transportCategoryID!}
          />
        )) || (
          <SelectBothText
            vehicleCategoryID={selectedCategories?.vehicleCategoryID}
            transportCategoryID={selectedCategories?.transportCategoryID}
          />
        )}
      </div>
    </div>
  );
};

const SelectBothText = ({
  vehicleCategoryID,
  transportCategoryID,
}: InferredCategoriesOnly) => (
  <div className="shadow-sm flex-1 w-full px-5 py-12 text-sm text-gray-400">
    Please select {!vehicleCategoryID ? "vehicle category" : ""}
    {!vehicleCategoryID && !transportCategoryID ? " and " : ""}
    {!transportCategoryID ? "transport category" : ""} to see a service
    schedule.
  </div>
);

export default ServiceSchedule;
