import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { add, sub } from "date-fns";
import { toDate } from "date-fns-tz";
import { toast } from "react-toastify";
import { Alert } from "@mui/material";

import {
  CustomSignalEventsRequestBody,
  CustomSignalEventsTimelineBucket,
} from "shared/api/customSignalEvents/api";
import { useListCustomSignalEventsTimeline } from "shared/api/customSignalEvents/hooks";
import {
  SensorReadingsTimelineAggregation,
  SensorReadingsTimelineGrouping,
} from "shared/api/sensors/api";
import { useSensorsReadingsTimeline } from "shared/api/sensors/hooks";
import { ServiceRecord } from "shared/api/serviceRecords/api";
import { useListServiceRecords } from "shared/api/serviceRecords/hooks";
import { SignalEventOccurrencesVINAggregateBucket } from "shared/api/signalEvents/api";
import { useListSignalEventOccurrencesVINTimeline } from "shared/api/signalEvents/hooks";
import { APIFilter, formatAPIDate, getSortFilter } from "shared/api/utils";
import { Vehicle } from "shared/api/vehicles/api";
import { useConfigContext } from "shared/contexts/ConfigContext";
import { EventRegistryContext } from "shared/contexts/EventRegistryContext";
import { DATE_FILTER_GENERIC } from "shared/filterDefinitions";
import {
  useCustomLocalStorageState,
  useSignalEventOccurrencesFiltersSchema,
  useTenantMileageUnit,
} from "shared/hooks";
import { EventTypeEnum, SortBy } from "shared/types";

import {
  addFiltersToInputEvent,
  capitalizeEventType,
  getDateTypeForEventType,
} from "pages/CustomSignalEvents/utils";
import { SIGNAL_EVENTS_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";
import { VehiclesConfig } from "pages/SuperAdmin/PagesConfig/types";
import {
  MAX_LIMIT_EVENTS,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY,
  VIN_VIEW_EVENTS_TIMELINE_USE_DEFAULT_TO_DATE_KEY,
} from "pages/VINView/constants";
import EventDetail from "pages/VINView/ServiceRecords/EventDetail";

import Button from "features/ui/Button";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { AXIS_TOOLTIP_FONT_SIZE } from "features/ui/charts/constants";
import { DataElement, ZoomXState } from "features/ui/charts/types";
import { getColor, getDefaultActions } from "features/ui/charts/utils";
import DropdownSelect from "features/ui/DropdownSelect";
import Filters from "features/ui/Filters";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  filterStateToFilterGroupState,
  getFiltersQuery,
  getTopLevelRowFromFilterGroupState,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterOperator } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";

import { useQuery } from "services/hooks";

import {
  CHART_ACTIONS,
  CHART_OPTIONS_KEY,
  DEFAULT_R_AXIS_WIDTH,
  MAX_CHART_LABEL_LENGTH,
  SELECTED_SIGNAL_EVENT_SUFFIX,
  UNAVAILABLE_TEXT_WIDTH,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_AGGREGATION_FUNCTION_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_AGGREGATION_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY,
} from "./constants";
import EventsTimelineGraph from "./EventsTimelineGraph";
import EventsTimelineTabs from "./EventsTimelineTabs";
import { useChartActions } from "./hooks";
import SensorAndTriggerFilters from "./SensorAndTriggerFilters";
import SensorsChart from "./SensorsCharts";
import useShownSignalEvents from "./useShownSignalEvents";
import {
  getAggregationWindowAsSensorReadingsTimelineGrouping,
  getInitialChartSettings,
  getSharedXAxisProps,
  getTextWidth,
  onExploreInSEAnalyticsActionClick,
  partitionArray,
  processEventSignals,
  processVehicleHistoryEvents,
  processVehicleServiceRecords,
  SignalEventDot,
  updateLSFilters,
} from "./utils";
import VehicleServiceEventDialog from "./VehicleServiceEventDialog";

interface Props {
  vin: string;
  staticFilters?: APIFilter[];
  customEventTableRows?: SignalEventOccurrencesVINAggregateBucket[];
  customEventsRequestBody?: CustomSignalEventsRequestBody;
  customEventSchema?: SchemaEntry[];
  defaultStartDate?: Date;
  defaultEndDate?: Date;
  // Sometimes we don't want data to be stored in localstorage and query since it can produce unintended results:
  // Example:
  //  - modifying definition of custom signal event can produce different results for the same VIN (last occurrence date, etc)
  //    and some of these data are stored in local storage / query when visiting Event Timeline. So last occurrence date might be
  //    different when custom signal event definition change (and we use this date to create a default date range)
  //    -> we could somehow fix this by generating unique page key for (custom signal event definition, VIN) pair but that would
  //       dramatically increase local storage usage
  disableDateStorage?: boolean;
  vehicle: Vehicle;
}

const Y_AXIS_LETTER_WIDTH_PX = 4;
const DEFAULT_OCCURRENCES_SORT: SortBy = { date: "desc" };
const DEFAULT_DATE_ATTRIBUTE = "date";

const getDefaultFromDate = (
  vehicle?: Vehicle,
  vehicles?: VehiclesConfig,
  vehicleHistoryEvents?: DataElement[]
) => {
  if (
    vehicles?.eventTimelineFromDateVehicleAttribute &&
    vehicle?.[vehicles.eventTimelineFromDateVehicleAttribute as keyof Vehicle]
  ) {
    return sub(
      toDate(
        vehicle[
          vehicles.eventTimelineFromDateVehicleAttribute as keyof Vehicle
        ] as string
      ),
      { days: 14 }
    );
  }

  const manufacturedEvent = vehicleHistoryEvents?.find(
    (event) => event.key === "vehicleManufacturedAt"
  );

  if (manufacturedEvent) {
    return sub(new Date(manufacturedEvent.x), { days: 14 });
  }

  if (vehicles?.eventTimelineFromDate) {
    return toDate(vehicles.eventTimelineFromDate);
  }

  return add(new Date(), { months: -1 });
};

const getToDateFromLocalStorage = (vin: string) => {
  const storedDate = localStorage.getItem(
    VIN_VIEW_EVENTS_TIMELINE_USE_DEFAULT_TO_DATE_KEY(vin)
  );
  if (storedDate) {
    return toDate(storedDate);
  }

  return undefined;
};

const getDefaultToDate = (
  toDateLS: Date | undefined,
  vehicles?: VehiclesConfig
) => {
  if (toDateLS) {
    return toDateLS;
  }

  return vehicles?.eventTimelineToDate
    ? toDate(vehicles.eventTimelineToDate)
    : new Date();
};

const EventsTimeline = ({
  vin,
  staticFilters,
  customEventTableRows,
  customEventsRequestBody,
  customEventSchema,
  defaultStartDate,
  defaultEndDate,
  disableDateStorage,
  vehicle,
}: Props) => {
  const pageKey = VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY(vin);
  const pageSEFilterKey = VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY(vin);
  const pageSensorsTriggersKey =
    VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY(vin);

  const query = useQuery();
  const urlSelectedEvents = (query.selectedSignalEvents as string)?.split(",");

  const vinSEPendingFiltersKey = getPendingFiltersKey(pageSEFilterKey);

  const [sharedZoom, setSharedZoom] = useState<ZoomXState>();
  const [zoomReferenceAreaOverride, setZoomReferenceAreaOverride] =
    useState<JSX.Element | null>();

  const eventTypes = useContext(EventRegistryContext);

  const vehicleHistoryEvents = processVehicleHistoryEvents(vehicle);

  const defaultActions = getDefaultActions(CHART_ACTIONS);

  const handleOnReferenceLineClick = ({ date }: ServiceRecord) => {
    const eventsWithSameTimestamp =
      events?.filter(
        (serviceRecord: ServiceRecord) => serviceRecord.date === date
      ) || [];
    setSelectedEvents(eventsWithSameTimestamp);
  };

  const signalEventsOccurrencesFiltersSchema =
    useSignalEventOccurrencesFiltersSchema(["VIN", "recordedAt"]);

  const [filterSummaryOpen, setFilterSummaryOpen] = useState<boolean>(false);

  const [hoveredSignal, setHoveredSignal] = useState<string>("");
  const [selectedEvents, setSelectedEvents] = useState<ServiceRecord[]>([]);

  const {
    pages: { vehicles: vehiclesConfig },
  } = useConfigContext();

  const defaultFromDateInitial = getDefaultFromDate(
    vehicle,
    vehiclesConfig,
    vehicleHistoryEvents
  );

  const toDateLS = getToDateFromLocalStorage(vin);
  const defaultToDateInitial = getDefaultToDate(toDateLS, vehiclesConfig);

  updateLSFilters(defaultFromDateInitial, toDateLS, pageKey, vin);

  const defaultFromDate = defaultStartDate || defaultFromDateInitial;
  const defaultToDate = defaultEndDate || defaultToDateInitial;

  const defaultFilterValues = filterStateToFilterGroupState({
    recordedAt: {
      values: [
        formatAPIDate(
          defaultFromDate.toString(),
          DataType.DATE_WITH_TIME_NO_TZ
        ),
        formatAPIDate(defaultToDate.toString(), DataType.DATE_WITH_TIME_NO_TZ),
      ],
      operator: FilterOperator.BETWEEN,
    },
  });

  const handleOnZoomOut = () => {
    setSharedZoom(undefined);
  };

  const {
    manageFilterChange,
    filters,
    initialized: filtersInitialized,
    chartSettings,
    manageChartSettingsChange,
  } = useFilterSortState({
    pageKey,
    defaultFilterValues,
    disableUsingLocalStorage: disableDateStorage,
    disableUsingQuery: disableDateStorage,
    schemaAttributes: [],
  });

  const sensorsTriggersFiltersState = useFilterSortState({
    pageKey: pageSensorsTriggersKey,
    schemaAttributes: [],
  });

  const signalEventsFilterSortState = useFilterSortState({
    pageKey: pageSEFilterKey,
    schemaAttributes: [],
    defaultFilterValues: vehiclesConfig?.eventTimelineDefaultSignalEventFilters
      ? filterBuilderQueryToFilterBuilderState(
          vehiclesConfig.eventTimelineDefaultSignalEventFilters
        )
      : undefined,
  });

  const [selectedChartSettings, setSelectedChartSettings] = useState(
    getInitialChartSettings(chartSettings, defaultActions)
  );

  const { chartActions } = useChartActions(selectedChartSettings);

  const [aggregationWindow, setAggregationWindow] =
    useCustomLocalStorageState<SensorReadingsTimelineGrouping>(
      VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_AGGREGATION_KEY(vin),
      {
        defaultValue: getAggregationWindowAsSensorReadingsTimelineGrouping(
          selectedChartSettings[0]
        ),
      }
    );

  const [aggregationFunction, setAggregationFunction] =
    useCustomLocalStorageState<SensorReadingsTimelineAggregation>(
      VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_AGGREGATION_FUNCTION_KEY(vin),
      {
        defaultValue:
          (selectedChartSettings[1]
            ?.optionId as SensorReadingsTimelineAggregation) ?? "avg",
      }
    );

  const handleChartSettingsChange = (
    selectedOptions: SelectedChartOptions[]
  ) => {
    setSelectedChartSettings(selectedOptions);
    setAggregationWindow(
      getAggregationWindowAsSensorReadingsTimelineGrouping(selectedOptions[0])
    );
    setSelectedDot(null);
    if (selectedOptions[1]) {
      setAggregationFunction(
        selectedOptions[1].optionId as SensorReadingsTimelineAggregation
      );
    }

    if (manageChartSettingsChange) {
      manageChartSettingsChange(selectedOptions, CHART_OPTIONS_KEY);
    }
  };

  const recordedAtRow = getTopLevelRowFromFilterGroupState(
    "recordedAt",
    filters
  );

  const startDate =
    recordedAtRow && recordedAtRow?.values?.length > 0
      ? recordedAtRow?.values[0]
      : "";
  const endDate =
    recordedAtRow && recordedAtRow?.values?.length > 1
      ? recordedAtRow?.values[1]
      : "";

  const sharedXAxisProps = getSharedXAxisProps(
    startDate,
    endDate,
    aggregationWindow
  );

  const staticFiltersRecords: APIFilter[] = [
    ...(staticFilters ?? []),
    {
      name: "date",
      value: formatAPIDate(startDate, DataType.DATE),
      op: "gte",
    },
    {
      name: "date",
      value: formatAPIDate(endDate, DataType.DATE),
      op: "lte",
    },
  ];

  const sensorsTriggersStaticFilters: APIFilter[] = [
    ...(staticFilters ?? []),
    {
      name: "readAt",
      value: formatAPIDate(
        toDate(startDate).toString(),
        DataType.DATE_WITH_TIME_NO_TZ
      ),
      op: "gte",
    },
    {
      name: "readAt",
      value: formatAPIDate(
        toDate(endDate).toString(),
        DataType.DATE_WITH_TIME_NO_TZ
      ),
      op: "lte",
    },
  ];

  const sensorIDRow = getTopLevelRowFromFilterGroupState(
    "sensorID",
    sensorsTriggersFiltersState.filters
  );

  const selectedSensorsIDs = useMemo(
    () => sensorIDRow?.values || [],
    [sensorIDRow?.values]
  );

  const [prevSelectedSensorsIDs, setPrevSelectedSensorsIDs] =
    useState<string[]>(selectedSensorsIDs);

  const filtersQuery = getFiltersQuery(
    sensorsTriggersFiltersState.filters,
    sensorsTriggersStaticFilters
  );

  const {
    data,
    error: sensorReadingsError,
    isLoading: sensorReadingsIsLoading,
  } = useSensorsReadingsTimeline({
    mileageUnit: useTenantMileageUnit(),
    sensorReadingsFilter: filtersQuery,
    grouping: aggregationWindow,
    aggregation: aggregationFunction,
    skipRequest: !selectedSensorsIDs.length,
  });

  const allStateSensors = data?.metadata?.filter(
    ({ type }) => type === "state"
  );
  const allStateSensorsIDs = allStateSensors?.map(({ ID }) => ID);

  /**
   * If aggregationWindow === "none" we split sensors into point-value vs state sensors because we draw 2 different charts.
   * When sensorChartGrouping !== "none" state sensors are considered point-value sensors, but we use "count" instead of
   * "value" to display the chart.
   * See prepareSensorsChartData() for more info.
   */
  const showStateSensorsChart = aggregationWindow === "none";
  const [selectedStateSensorsIDs, selectedPointValueSensorsIDs] = useMemo(
    () =>
      showStateSensorsChart
        ? partitionArray(
            selectedSensorsIDs,
            (sensorID) => allStateSensorsIDs?.includes(sensorID) || false
          )
        : [[], selectedSensorsIDs],
    [selectedSensorsIDs, allStateSensorsIDs, showStateSensorsChart]
  );

  // When selected sensors change from no selected to some selected
  // we want to setSelectedAggregationWindow to default if they were on non-default previously.
  useEffect(() => {
    if (
      defaultActions.length > 0 &&
      prevSelectedSensorsIDs.length === 0 &&
      selectedSensorsIDs.length > 0 &&
      selectedChartSettings[0].optionId !== defaultActions[0].optionId
    ) {
      toast.info(
        "We have reset the timeline event aggregation to daily due to changed sensor selection."
      );
      handleChartSettingsChange(defaultActions);
    }

    setPrevSelectedSensorsIDs(selectedSensorsIDs);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSensorsIDs.length]);

  useEffect(() => {
    // if (endDate - startDate) is less than a day, set aggregation window to "hour"
    if (startDate && endDate) {
      const diffInMs = Math.abs(
        toDate(endDate).getTime() - toDate(startDate).getTime()
      );
      const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
      if (diffInDays < 1 && aggregationWindow === "day") {
        setAggregationWindow("hour");

        const newSelectedOptions = getInitialChartSettings(
          chartSettings,
          defaultActions
        ).map((option) =>
          option.id === "legend" ? { ...option, optionId: "hour" } : option
        );

        setSelectedChartSettings(newSelectedOptions);

        if (manageChartSettingsChange) {
          manageChartSettingsChange(newSelectedOptions, CHART_OPTIONS_KEY);
        }
      }
    }
    // we want to run this only when startDate or endDate changes, not on every onMouseOver on the chart
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate]);

  const selectedStateSensors = useMemo(
    () =>
      allStateSensors?.filter(({ ID }) => selectedStateSensorsIDs.includes(ID)),
    [allStateSensors, selectedStateSensorsIDs]
  );

  const selectedPointValueSensors = useMemo(
    () =>
      data?.metadata?.filter(({ ID }) =>
        selectedPointValueSensorsIDs.includes(ID)
      ),
    [data, selectedPointValueSensorsIDs]
  );

  const [pointValueSensorsData, stateSensorsData] = useMemo(() => {
    if (!data || !selectedStateSensorsIDs) return [];

    const actualData = data.data;

    return partitionArray(
      actualData,
      ({ sensorID }) => !selectedStateSensorsIDs.includes(sensorID)
    );
  }, [data, selectedStateSensorsIDs]);

  const hasTwoPointValueSensorsSelected = Boolean(
    selectedPointValueSensors && selectedPointValueSensors.length >= 2
  );

  const {
    shownSignalEvents,
    setShownSignalEvents,
    resetInitialShownSignalEvents,
  } = useShownSignalEvents(vin, urlSelectedEvents);

  const {
    data: events,
    isLoading: eventsIsLoading,
    error: eventsError,
  } = useListServiceRecords({
    filter: getFiltersQuery(undefined, staticFiltersRecords),
    limit: MAX_LIMIT_EVENTS,
  });

  const vehicleFilter: FilterGroupState = filterStateToFilterGroupState({
    VIN: { values: [vin], operator: FilterOperator.EQUALS },
  });

  const signalEventsGlobalFilters = filterStateToFilterGroupState({
    recordedAt: {
      values: [
        formatAPIDate(
          toDate(startDate).toString(),
          DataType.DATE_WITH_TIME_NO_TZ
        ),
        formatAPIDate(
          toDate(endDate).toString(),
          DataType.DATE_WITH_TIME_NO_TZ
        ),
      ],
      operator: FilterOperator.BETWEEN,
    },
  });

  const allFilters = mergeFilterGroupStates(
    signalEventsGlobalFilters,
    vehicleFilter,
    signalEventsFilterSortState.filters
  );

  const signalEventOccurrencesVINTimelineFilter = getFiltersQuery(allFilters);

  const {
    data: signals,
    isLoading: signalsIsLoading,
    error: signalsError,
  } = useListSignalEventOccurrencesVINTimeline({
    filter: signalEventOccurrencesVINTimelineFilter,
    limit: MAX_LIMIT_EVENTS,
    grouping: aggregationWindow,
    aggregation: aggregationFunction,
    sort: getSortFilter(DEFAULT_OCCURRENCES_SORT),
  });

  const mileageUnit = useTenantMileageUnit();

  const hasCustomSignalEvents = customEventsRequestBody !== undefined;

  const eventType = customEventsRequestBody?.inputEventType as EventTypeEnum;
  const dateParam =
    eventTypes?.find((x) => x.type === capitalizeEventType(eventType))
      ?.dateAttribute || DEFAULT_DATE_ATTRIBUTE;
  const dateType = getDateTypeForEventType(dateParam, customEventSchema);

  const updatedCustomEventsRequestBody: CustomSignalEventsRequestBody =
    (hasCustomSignalEvents && {
      ...customEventsRequestBody,
      inputEventFilter: addFiltersToInputEvent(
        filterStateToFilterGroupState({
          [dateParam]: {
            values: [
              formatAPIDate(toDate(startDate).toString(), dateType),
              formatAPIDate(toDate(endDate).toString(), dateType),
            ],
            operator: FilterOperator.BETWEEN,
          },
        }),
        customEventsRequestBody.inputEventFilter
      ),
    }) || {
      inputEventType: undefined,
      customAttributes: [],
      vehiclesFilter: `VIN=eq:${vin}`,
      mileageUnit,
    };

  const {
    data: customSignalEvents,
    isLoading: customSignalEventsIsLoading,
    error: customSignalEventsError,
  } = useListCustomSignalEventsTimeline(
    {
      skipRequest: !hasCustomSignalEvents,
      grouping: aggregationWindow,
      limit: MAX_LIMIT_EVENTS,
    },
    updatedCustomEventsRequestBody
  );

  const [cursorX, setCursorX] = useState<number | null>(null);
  const [isSeDropdownOpen, setIsSeDropdownOpen] = useState(false);
  const [selectedDot, setSelectedDot] = useState<SignalEventDot | null>(null);

  const handleOnMouseMove = (e?: any) => {
    setCursorX(e?.chartX);
  };

  // filtered by date range and VIN but not by "shown"
  const signalsAndCustomSignalEvents: CustomSignalEventsTimelineBucket[] = [
    ...(signals || []),
    ...(customSignalEvents || []),
  ];
  const signalEventPoints = processEventSignals(
    signalsAndCustomSignalEvents,
    shownSignalEvents
  );

  // we have to add descriptions separately since they are not included as dimensions
  const signalEventDescriptions = signalsAndCustomSignalEvents.reduce(
    (res, event) => {
      res[event.signalEventID] = event.description || "";

      return res;
    },
    {} as Record<string, string>
  );

  // we need to separate the rows for the selected dot(s) since styling is
  // handled on row level, so we need to separate it in a new row
  const rows = Array.from(shownSignalEvents)
    .map((value, index) => {
      const rowData = signalEventPoints.filter(
        ({ signal }) => signal === value
      );

      // if we have a selected dot for this signal, remove it from the main row data
      const mainRowData =
        selectedDot && selectedDot.signal === value
          ? rowData.filter((point) => point.ts !== Number(selectedDot.ts))
          : rowData;

      const baseRow = {
        color: getColor(index),
        data: mainRowData,
        opacity:
          (!hoveredSignal && !selectedDot) || hoveredSignal === value ? 1 : 0.4,
        key: value,
      };

      // if this signal has the selected dot, create an additional row for it
      if (selectedDot && selectedDot.signal === value) {
        const selectedDotRow = {
          color: getColor(index),
          data: [rowData.find((point) => point.ts === Number(selectedDot.ts))!],
          opacity: 1,
          key: `${value}${SELECTED_SIGNAL_EVENT_SUFFIX}`,
        };

        return [selectedDotRow, baseRow];
      }

      return [baseRow];
    })
    .flat()
    .reverse();

  const handleSetShownSignalEvents = useCallback(
    (events: Set<string>, persist?: boolean) => {
      setShownSignalEvents(events, persist);

      // this ensures we always have the latest state of both events and selectedDot
      setSelectedDot((currentSelectedDot) => {
        if (currentSelectedDot && !events.has(currentSelectedDot.signal)) {
          return null;
        }

        return currentSelectedDot;
      });
    },
    [setShownSignalEvents]
  );

  // filters need a couple frames to set up default value, so we stop temporarily rendering until we get values. It is not visible to users.
  if (!startDate || !endDate) {
    return null;
  }

  const signalEventFilterLabel = getFilterLabel(
    "Signal Event Filters",
    signalEventsFilterSortState.filters
  );

  const hasSensorIDFilter = selectedSensorsIDs.length > 0;

  const serviceRecordReferenceLines = processVehicleServiceRecords(events);

  const dateFilterOccurrences = signalsAndCustomSignalEvents.reduce(
    (acc: DataElement, curr) => {
      if (curr.signalEventID in acc) {
        acc[curr.signalEventID] += curr.occurrences;
      } else {
        acc[curr.signalEventID] = curr.occurrences;
      }

      return acc;
    },
    {}
  );

  // signal events chart: we calculate how much space we need on the left to display event label that can be rather long (ex. sgn_eng_oil_temp1_top_prct)
  const pointMaxNameLength = signalEventPoints.reduce(
    (prev, current) => Math.max(current.signal.length, prev),
    0
  );
  // I have come to this number by drawing different lengths on screen, so it might be approximate. I think it's still better than a fixed number
  // - we re-use this on all 3 charts on this page
  const graphLeftMargin = Math.max(
    50,
    pointMaxNameLength * Y_AXIS_LETTER_WIDTH_PX
  );

  const maxLabelWidth = Math.max(
    UNAVAILABLE_TEXT_WIDTH,
    ...rows.map((row) => {
      // we remove SELECTED_SIGNAL_EVENT_SUFFIX if exists
      const rowLabel = row.key.endsWith(SELECTED_SIGNAL_EVENT_SUFFIX)
        ? row.key.slice(0, -SELECTED_SIGNAL_EVENT_SUFFIX.length)
        : row.key;

      const isTruncated = rowLabel.length > MAX_CHART_LABEL_LENGTH;
      const truncatedValue = isTruncated
        ? `${rowLabel.slice(0, MAX_CHART_LABEL_LENGTH)}...`
        : rowLabel;

      return getTextWidth(truncatedValue, `${AXIS_TOOLTIP_FONT_SIZE}px lato`);
    })
  );

  // recharts adds some margin on its own so we move it a bit to the left
  const marginLeft =
    rows.length === 0
      ? 30
      : maxLabelWidth === UNAVAILABLE_TEXT_WIDTH
        ? graphLeftMargin
        : maxLabelWidth - 30;

  const showSensorsCharts = Boolean(
    hasSensorIDFilter &&
      (stateSensorsData?.length || pointValueSensorsData?.length) &&
      (selectedStateSensors?.length || selectedPointValueSensors?.length)
  );

  const handleSyncDateWithZoom = (zoom: ZoomXState) => {
    const from = formatAPIDate(
      toDate(zoom.left).toString(),
      DataType.DATE_WITH_TIME_NO_TZ
    );
    const to = formatAPIDate(
      toDate(zoom.right).toString(),
      DataType.DATE_WITH_TIME_NO_TZ
    );
    manageFilterChange({
      key: "recordedAt",
      op_id: FilterOperator.BETWEEN,
      values: [from, to],
      dataType: DataType.DATE_WITH_TIME_UTC,
    });
    handleOnZoomOut();
  };

  const globalSignalEventOccurrencesFilter: FilterGroupState =
    filterStateToFilterGroupState({
      recordedAt: {
        values: [
          formatAPIDate(
            toDate(startDate).toString(),
            DataType.DATE_WITH_TIME_NO_TZ
          ),
          formatAPIDate(
            toDate(endDate).toString(),
            DataType.DATE_WITH_TIME_NO_TZ
          ),
        ],
        operator: FilterOperator.BETWEEN,
      },
      VIN: { values: [vin], operator: FilterOperator.EQUALS },
    });

  const eventsTimelineTabsFilters = mergeFilterGroupStates(
    signalEventsFilterSortState.filters,
    globalSignalEventOccurrencesFilter
  );

  const isAdvancedEditor = signalEventsFilterSortState.isAdvancedFilterEditor;

  return (
    <>
      <div className="flex justify-between">
        <div className="flex space-x-3 shrink-0">
          <Filters
            initialized={filtersInitialized}
            schema={[
              DATE_FILTER_GENERIC({
                fieldName: "recordedAt",
                label: "Date",
                filterDataType: DataType.DATE_WITH_TIME_NO_TZ,
              }),
            ]}
            // we need this so the component re-renders when filters change (filters below is an object and it's reference doesn't change)
            key={startDate + endDate}
            onFilterChange={manageFilterChange}
            filters={filters}
            horizontal
          />
          <DropdownSelect
            testId="signal-events-filters-dropdown"
            label={signalEventFilterLabel}
            buttonClass="h-[40px]"
            open={isSeDropdownOpen}
            onOpen={(open) => {
              setIsSeDropdownOpen(open);
            }}
            hasAdvancedFilters={isAdvancedEditor}
            content={
              <FilterSelector
                schema={signalEventsOccurrencesFiltersSchema}
                filterSortState={signalEventsFilterSortState}
                title={SIGNAL_EVENTS_FILTER_LABEL}
                testId="signal-events-filters"
                pendingFiltersKey={vinSEPendingFiltersKey}
                onCloseFilters={() => {
                  setIsSeDropdownOpen(false);
                }}
                initialIsAdvancedFilter={isAdvancedEditor}
              />
            }
          />
          <ViewFiltersButton
            open={filterSummaryOpen}
            onClick={() => setFilterSummaryOpen(true)}
            onClose={() => setFilterSummaryOpen(false)}
          />
        </div>
        <ChartActionsWrap id="sensors">
          <ChartActions
            actions={chartActions}
            selectedOptions={selectedChartSettings}
            onOptionChange={handleChartSettingsChange}
          />
        </ChartActionsWrap>
      </div>
      <div className="flex mt-2 justify-between items-center w-full">
        <SensorAndTriggerFilters
          vin={vin}
          filterState={sensorsTriggersFiltersState}
        />
        {selectedDot && (
          <Button
            label="Explore selected Signal Event in Signal Event Analytics"
            variant="text"
            color="primary"
            disabled={!selectedDot}
            testId="explore-in-signal-event-analytics-cta"
            onClick={() => onExploreInSEAnalyticsActionClick(selectedDot)}
          />
        )}
      </div>
      <FiltersSummary
        open={filterSummaryOpen}
        filterStates={[
          {
            name: "Signal Event Filters",
            filters: signalEventsFilterSortState.filters,
            schema: signalEventsOccurrencesFiltersSchema,
          },
        ]}
      />
      {signalsAndCustomSignalEvents.length === MAX_LIMIT_EVENTS && (
        <Alert severity="info" className="mt-3 h-[38px] flex items-center">
          Only showing 2000 most recent data points due to high volume.
        </Alert>
      )}
      {showSensorsCharts ? (
        <SensorsChart
          filterState={sensorsTriggersFiltersState}
          grouping={aggregationWindow}
          aggregation={
            aggregationWindow === "none" ? "avg" : aggregationFunction
          }
          endDate={endDate}
          serviceRecordReferenceLines={serviceRecordReferenceLines}
          vehicleHistoryEvents={vehicleHistoryEvents}
          sharedXAxisProps={sharedXAxisProps}
          cursorX={cursorX}
          currentZoom={sharedZoom}
          onMouseMove={handleOnMouseMove}
          onSyncDateWithZoom={handleSyncDateWithZoom}
          onReferenceLineClick={handleOnReferenceLineClick}
          margin={{ left: marginLeft }}
          onZoom={setSharedZoom}
          onZoomOut={handleOnZoomOut}
          onZoomReferenceAreaChange={setZoomReferenceAreaOverride}
          zoomReferenceAreaOverride={zoomReferenceAreaOverride}
          pointValueSensorsData={pointValueSensorsData}
          stateSensorsData={stateSensorsData}
          pointValueSensors={selectedPointValueSensors}
          stateSensors={selectedStateSensors}
          isLoading={sensorReadingsIsLoading}
          error={sensorReadingsError}
        />
      ) : (
        <div className="mt-3" />
      )}
      <EventsTimelineGraph
        isLoading={
          signalsIsLoading ||
          eventsIsLoading ||
          (hasCustomSignalEvents && customSignalEventsIsLoading)
        }
        error={(signalsError || eventsError || customSignalEventsError)!}
        serviceRecordReferenceLines={serviceRecordReferenceLines}
        vehicleHistoryEvents={vehicleHistoryEvents}
        rows={rows}
        setHoveredSignal={setHoveredSignal}
        sharedXAxisProps={sharedXAxisProps}
        cursorX={cursorX}
        currentZoom={sharedZoom}
        onMouseMove={handleOnMouseMove}
        onZoom={setSharedZoom}
        onZoomOut={handleOnZoomOut}
        onZoomReferenceAreaChange={setZoomReferenceAreaOverride}
        onSyncDateWithZoom={handleSyncDateWithZoom}
        zoomReferenceAreaOverride={zoomReferenceAreaOverride}
        onReferenceLineClick={handleOnReferenceLineClick}
        signalEventDescriptions={signalEventDescriptions}
        margin={{
          left: marginLeft,
          right: hasTwoPointValueSensorsSelected
            ? DEFAULT_R_AXIS_WIDTH
            : undefined,
        }}
        sensorChartsPresent={showSensorsCharts}
        grouping={aggregationWindow}
        selectedDot={selectedDot}
        setSelectedDot={setSelectedDot}
      />
      {selectedEvents.length > 0 && (
        <VehicleServiceEventDialog
          isOpen={true}
          onClose={() => setSelectedEvents([])}
        >
          <>
            {selectedEvents.map((serviceRecord: ServiceRecord) => (
              <EventDetail
                serviceRecord={serviceRecord}
                key={serviceRecord.ID}
              />
            ))}
          </>
        </VehicleServiceEventDialog>
      )}
      <EventsTimelineTabs
        vin={vin}
        shownSignalEvents={shownSignalEvents}
        setShownSignalEvents={handleSetShownSignalEvents}
        resetInitialShownSignalEvents={resetInitialShownSignalEvents}
        hoveredSignal={hoveredSignal}
        setHoveredSignal={setHoveredSignal}
        filters={eventsTimelineTabsFilters}
        customEventTableRows={customEventTableRows}
        dateFilterOccurrences={dateFilterOccurrences}
        grouping={aggregationWindow}
        selectedDot={selectedDot}
      />
    </>
  );
};

export default EventsTimeline;
