import { getValidateFilter } from "duck/graph/nodes/ClaimAnalyticsAgent/utils";
import { Source } from "duck/graph/nodes/utils";
import { PageHandler } from "duck/graph/PageHandler";
import { sendAgentResponse } from "duck/graph/sendAgentResponse/sendAgentResponse";
import {
  NonEmptyStringArray,
  SignalEventAnalyticsAssociatedClaimsOptions,
  StringSetter,
} from "duck/graph/types";
import { z } from "zod";
import { StructuredTool, tool } from "@langchain/core/tools";

import { DEFAULT_GROUP_BY_ATTRIBUTE } from "pages/ClaimAnalytics/tabPages/TopContributors/constants";
import { GROUP_BY_ATTRIBUTE_KEY } from "pages/constants";
import { ASSOCIATED_CLAIMS_TAB_KEY } from "pages/SignalEventsAnalytics/constants";
import {
  SE_ASSOCIATED_CLAIMS_KEY,
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY,
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY,
  WINDOW_SIZE_KEY,
} from "pages/SignalEventsAnalytics/tabPages/AssociatedClaims/constants";

import {
  DEFAULT_WINDOW_SIZE,
  MAX_WINDOW_SIZE,
  MIN_WINDOW_SIZE,
} from "features/ui/Filters/FilterTypes/OccursFilter/constants";
import { getPageKeyWithVersion, getQueryKeys } from "features/ui/Filters/utils";

interface GoToAssociatedClaimsTabToolParams {
  pageHandler: PageHandler;
  groupByOptions: NonEmptyStringArray;
  selectedOptions: SignalEventAnalyticsAssociatedClaimsOptions;
  setEphemeralMessage: StringSetter;
  setAgentResponse: StringSetter;
  selectedTab: string;
}

const getGoToAssociatedClaimsTabTool = ({
  pageHandler,
  groupByOptions,
  selectedOptions,
  setEphemeralMessage,
  setAgentResponse,
  selectedTab,
}: GoToAssociatedClaimsTabToolParams): StructuredTool => {
  const goToAssociatedClaimsTabSchema = z.object({
    groupByAttribute: z
      .enum(groupByOptions)
      .optional()
      .describe(
        `Claim attribute to group the results by. While Claim attributes can be referenced directly, Vehicle attributes must be prefixed with 'vehicle.'. Default value: '${DEFAULT_GROUP_BY_ATTRIBUTE}'.`
      ),
    claimFilter: z
      .string()
      .optional()
      .describe("Filter to apply to the associated claims."),
    windowSize: z
      .number()
      .optional()
      .describe(
        `Window size in days for claims occurring after base signal event. Range: ${MIN_WINDOW_SIZE}-${MAX_WINDOW_SIZE}. Default: ${DEFAULT_WINDOW_SIZE}.`
      ),
  });

  type GoToAssociatedClaimsTab = z.infer<typeof goToAssociatedClaimsTabSchema>;

  const goToAssociatedClaimsTab = async ({
    groupByAttribute,
    claimFilter: claimsFilter,
    windowSize,
  }: GoToAssociatedClaimsTab) => {
    setEphemeralMessage("to associated claims tab");
    console.debug(
      `[SEA]: Navigating to Associated Claims tab with groupByAttribute: ${groupByAttribute}, claimFilter: ${claimsFilter}, windowSize: ${windowSize}`
    );

    const {
      filterQueryString: selectedClaimsFilter,
      windowSize: selectedWindowSize,
      groupByAttribute: selectedGroupByAttribute,
    } = selectedOptions;

    const filterQueryString = claimsFilter ?? selectedClaimsFilter ?? "";
    await getValidateFilter(Source.CLAIM)(filterQueryString);

    const pageKeyWithVersion = getPageKeyWithVersion(SE_ASSOCIATED_CLAIMS_KEY);
    const { filtersKey } = getQueryKeys(pageKeyWithVersion);
    pageHandler.updateFilter(filterQueryString, filtersKey);

    const nonNullWindowSize =
      windowSize ?? selectedWindowSize ?? DEFAULT_WINDOW_SIZE;
    if (
      nonNullWindowSize < MIN_WINDOW_SIZE ||
      nonNullWindowSize > MAX_WINDOW_SIZE
    ) {
      throw new Error(
        `Invalid window size: ${nonNullWindowSize}. Must be between ${MIN_WINDOW_SIZE} and ${MAX_WINDOW_SIZE}.`
      );
    }

    const nonNullGroupByAttribute =
      groupByAttribute ??
      selectedGroupByAttribute ??
      DEFAULT_GROUP_BY_ATTRIBUTE;
    if (!groupByOptions.includes(nonNullGroupByAttribute)) {
      throw new Error(
        `Invalid groupByAttribute: ${nonNullGroupByAttribute}. Valid options: ${groupByOptions.join(", ")}`
      );
    }

    pageHandler.updateTabChartSettings(
      SE_ASSOCIATED_CLAIMS_KEY,
      ASSOCIATED_CLAIMS_TAB_KEY,
      {
        [SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY]: [
          { id: GROUP_BY_ATTRIBUTE_KEY, optionId: nonNullGroupByAttribute },
        ],
        [SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY]: [
          { id: WINDOW_SIZE_KEY, optionId: nonNullWindowSize },
        ],
      }
    );

    sendAgentResponse({
      setAgentResponse,
      selectedTabKey: selectedTab,
      newTabKey: ASSOCIATED_CLAIMS_TAB_KEY,
      newTabTitle: "Associated Claims",
      filterString: filterQueryString,
      selectedFilterString: selectedClaimsFilter,
      source: Source.CLAIM,
      selectedGroupByAttribute,
      newGroupByAttribute: nonNullGroupByAttribute,
    });

    if (selectedWindowSize !== nonNullWindowSize) {
      setAgentResponse(`- Set the window size to ${nonNullWindowSize} days`);
    }

    return "queued navigation to Associated Claims tab";
  };

  return tool(goToAssociatedClaimsTab, {
    name: "queueGoToAssociatedClaimsTab",
    description: `Navigate to the Associated Claims tab to view claims related to the selected signal events. Use this tool if the user wants to see related claims grouped by a specific attribute, or if they need to update the group-by attribute, apply a claim filter, or adjust the time window for the related claims.`,
    schema: goToAssociatedClaimsTabSchema,
  });
};

export default getGoToAssociatedClaimsTabTool;
