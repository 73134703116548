import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  CountRequest,
  Dealer,
  getFetcher,
  ListAttributesRequest,
} from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { Vehicle } from "shared/api/vehicles/api";
import { EventTypeEnum } from "shared/types";

export interface ListServiceRecordsRequest extends APIPaginatedRequest {}

export interface ServiceRecord {
  ID: string;
  VIN: string;
  date: string;
  externalID?: string | null;
  externalURL?: string | null;
  notes?: string | null;
  sourceType: EventTypeEnum;
  status?: string | null;
  type: string;
  vehicle: Partial<Vehicle>; // use full Vehicle only when this is joined and in API, but you ll have to adjust tests in utils.ts
}

export interface CustomRecord {
  ID: string;
  VIN: string;
  cost: number | null;
  createdAt: string;
  currencyCode: string | null;
  data?: Record<string, string | undefined> | string;
  date: string;
  dealer: Dealer | null;
  dealerID: string | null;
  externalID: string;
  externalURL?: string;
  label: string | null;
  notes: string | null;
  status: string | null;
  type: string;
  updatedAt: string;
}

export const listServiceRecordsRequestURI = ({
  ...params
}: ListServiceRecordsRequest): string =>
  client.getUri({
    method: "get",
    url: `/serviceRecords`,
    params,
  });

export const listServiceRecordsCountRequestURI = ({
  ...params
}: CountRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["serviceRecords", "count"]),
    params,
  });

interface APIListValuesServiceRecordsRequest extends APIListValuesRequest {}

export const listServiceRecordsValues = (
  args: APIListValuesServiceRecordsRequest
) =>
  getFetcher<APIListValuesResponse>(listServiceRecordsValuesRequestURI(args));

const listServiceRecordsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesServiceRecordsRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["serviceRecords", "values", fieldName]),
    params,
  });

export interface GetCustomRecordRequest {
  ID: string;
}
export const getCustomRecordRequestURI = ({
  ID,
  ...params
}: GetCustomRecordRequest): string =>
  client.getUri({
    method: "get",
    url: createURL(["customRecords", ID]),
    params,
  });

export const listServiceRecordsAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL(["serviceRecords", "attributes"]),
    params,
  });
