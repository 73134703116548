import client from "shared/api/axios";
import { createURL } from "shared/api/utils";

interface LookbackWindow {
  Weeks: number[];
}

interface DateField {
  name: string;
  value: string;
}

export interface IssueMetricsRegistry {
  dateFields: DateField[];
  lookbackWindow: LookbackWindow;
  name: string;
  type: string;
}

const BASE_ROUTE = "metricsRegistry";

export const getIssueMetricsRegistryRequestURI = (): string =>
  client.getUri({
    method: "GET",
    url: createURL([BASE_ROUTE, "issueMetrics"]),
  });

export const getSuggestedIssueMetricsRegistryRequestURI = (): string =>
  client.getUri({
    method: "GET",
    url: createURL([BASE_ROUTE, "suggestedIssueMetrics"]),
  });
