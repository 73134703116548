import classNames from "classnames";

import { OPERATOR_TO_LABEL } from "features/ui/Filters/constants";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import {
  FilterDiffData,
  FilterOperator,
  FilterOverviewFormat,
} from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

import { DEFAULT_BASE_ENTITY_TEXT } from "./constants";
import { WINDOW_DIRECTION_OPTION_BOTH } from "./OccursTimeWindowForm";
import { decodeOccursFilterAndOptions } from "./utils";

interface Props {
  operator: FilterOperator;
  value: string;
  baseEntityText?: string;
  occursBeforeNow?: boolean;
  tableSchema?: SchemaEntry[];
  format: FilterOverviewFormat;
  diff?: FilterDiffData;
}

const windowDirectionToLabel = (windowDirection: string) => {
  if (windowDirection === WINDOW_DIRECTION_OPTION_BOTH.id) {
    return WINDOW_DIRECTION_OPTION_BOTH.value;
  }

  return windowDirection;
};

const operatorToLabel = (operator: string) =>
  OPERATOR_TO_LABEL[operator as FilterOperator];

const OccursFilterLabel = ({
  operator,
  value,
  baseEntityText = DEFAULT_BASE_ENTITY_TEXT,
  occursBeforeNow = false,
  tableSchema,
  format,
  diff,
}: Props) => {
  const { filters, windowSize, windowType, windowDirection } =
    decodeOccursFilterAndOptions(value);

  const timeWindowText = (
    <span className="font-normal">
      {operatorToLabel(operator)} within {occursBeforeNow && "the last "}
      <strong>
        {windowSize} {windowType}{" "}
        {!occursBeforeNow && windowDirectionToLabel(windowDirection as string)}
      </strong>{" "}
      {baseEntityText}
    </span>
  );

  // todo miha: we could handle OCCURS filter diff more properly
  //  meaning we would also need to handle this separately in getUpdatedDeletedAttributes
  const applyValueDiffStyle =
    diff?.changes[diff.level]?.[diff.index]?.values ?? false;

  return (
    <>
      <span className="font-normal">defined by:</span>
      <div
        className={classNames(
          format === "label-inline" ? "ml-1" : "mt-1",
          applyValueDiffStyle ? diff?.bgColor : undefined
        )}
      >
        <FilterQueryPresentation
          filterState={filterBuilderQueryToFilterBuilderState(filters)}
          tableSchema={tableSchema}
          baseEntityText={baseEntityText}
          occursBeforeNow={occursBeforeNow}
        />
      </div>
      <span
        className={classNames(applyValueDiffStyle ? diff?.bgColor : undefined)}
      >
        {timeWindowText}
      </span>
    </>
  );
};

export default OccursFilterLabel;
