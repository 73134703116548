import { useState } from "react";
import ReactDiffViewer, { DiffMethod } from "react-diff-viewer";
import {
  MdOutlineCheckBox as CheckedIcon,
  MdOutlineCheckBoxOutlineBlank as UncheckedIcon,
} from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { DragEndEvent } from "@dnd-kit/core";

import { APIError } from "shared/api/api";
import {
  AttributeConfig,
  getAdminAttributesConfig,
  updateAdminAttributesConfig,
} from "shared/api/superadmin/api";
import { useAdminAttributesConfigList } from "shared/api/superadmin/hooks";
import { capitalizeFirstLetter } from "shared/utils";

import { handleOnSortEnd, showErrorToast } from "pages/SuperAdmin/utils";

import Button from "features/ui/Button";
import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import Modal from "features/ui/Modal";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { SelectOption } from "features/ui/Select";
import Tooltip from "features/ui/Tooltip";

import ConfigContainer from "./ConfigContainer";
import { AttributesConfigState } from "./types";

const TITLE = "Attributes Configuration";
const CONFIG_ROWS = 25;

export const convertAttributesConfigToAttributeConfigState = (
  config: AttributeConfig[]
): AttributesConfigState[] =>
  config.map((row) => ({
    ...row,
    id: row.ID,
  }));

export const convertAttributesConfigStateToAttributeConfig = (
  config: AttributesConfigState[]
): AttributeConfig[] => config.map(({ newAttribute, id, ...rest }) => rest);

const AttributesConfiguration = () => {
  const [selectedResource, setSelectedResource] = useState<SelectOption | null>(
    null
  );

  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [configuration, setConfiguration] = useState<AttributesConfigState[]>(
    []
  );
  const [configurationFromAPI, setConfigurationFromAPI] = useState<
    AttributeConfig[]
  >([]);

  const { data: resources } = useAdminAttributesConfigList();

  const resourceOptions =
    resources?.map(({ resource }) => ({
      id: resource,
      value: capitalizeFirstLetter(resource),
    })) || [];

  const handleResourceChange = (option: SelectOption | null) => {
    if (option) {
      setSelectedResource(option);
      setIsConfigLoading(true);
      setIsError(false);

      getAdminAttributesConfig({ resource: option.id as string })
        .then(({ data }) => {
          setConfiguration(convertAttributesConfigToAttributeConfigState(data));
          setConfigurationFromAPI(data);
        })
        .catch(() => {
          setIsError(true);
        })
        .finally(() => setIsConfigLoading(false));
    } else {
      setSelectedResource(null);
      setConfiguration([]);
      setConfigurationFromAPI([]);
      setIsError(false);
    }
  };

  const handleSave = () => {
    if (!showConfig) return;

    setIsSubmitting(true);

    updateAdminAttributesConfig({
      resource: selectedResource!.id as string,
      config: convertAttributesConfigStateToAttributeConfig(configuration),
    })
      .then(() => {
        toast.success(
          `${selectedResource!.value} configuration saved successfully!`
        );
      })
      .catch((error: APIError) => {
        showErrorToast(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const showConfig = Boolean(!isConfigLoading && selectedResource);

  const updateValueByID = (
    id: string,
    updater: (prev: AttributesConfigState) => AttributesConfigState
  ) => {
    setConfiguration((prev) =>
      prev.map((row) => (row.id === id ? updater(row) : row))
    );
  };

  const onSortEnd = (event: DragEndEvent) => {
    handleOnSortEnd(event, setConfiguration);
  };

  const selectAllAttributes = (selected: boolean) => {
    setConfiguration(
      configuration.map((row) => ({
        ...row,
        enabled: selected,
      }))
    );
  };

  const noAttributesSelected = !configuration.find((row) => row.enabled);

  return (
    <div>
      <PageHeaderWrapper title={TITLE} />
      <div className="sticky top-0 bg-white z-10 pt-2 pb-4 max-w-[1000px]">
        <div className="flex items-center gap-4">
          <div className="grow">
            <DropdownWithSearch
              label="Select a resource"
              options={resourceOptions}
              selectedOption={selectedResource}
              onSelectedOptionChange={handleResourceChange}
              testId="resource-select"
            />
          </div>

          {showConfig && (
            <>
              <Button
                label="Select all"
                color="secondary"
                variant="text"
                testId="check-all-attributes-config"
                onClick={() => selectAllAttributes(true)}
                isLoading={isSubmitting}
                startIcon={<CheckedIcon />}
              />
              <Button
                label="Deselect all"
                color="secondary"
                variant="text"
                testId="uncheck-all-attributes-config"
                onClick={() => selectAllAttributes(false)}
                isLoading={isSubmitting}
                startIcon={<UncheckedIcon />}
              />
              <Modal
                triggerElement={
                  <div className="text-gray-400 cursor-pointer hover:underline">
                    Show JSON
                  </div>
                }
                showCloseIcon
                maxWidth="xl"
              >
                <ReactDiffViewer
                  oldValue={JSON.stringify(configurationFromAPI, null, 2)}
                  newValue={JSON.stringify(
                    convertAttributesConfigStateToAttributeConfig(
                      configuration
                    ),
                    null,
                    2
                  )}
                  showDiffOnly
                  splitView={true}
                  compareMethod={DiffMethod.LINES}
                />
                <div></div>
              </Modal>
              <Tooltip
                title={
                  noAttributesSelected &&
                  "You should have at least one attribute enabled."
                }
                arrow
              >
                <Button
                  label="Save"
                  color="primary"
                  variant="contained"
                  testId="save-attributes-config"
                  onClick={handleSave}
                  isLoading={isSubmitting}
                  disabled={noAttributesSelected}
                />
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {isError && (
        <div className="mb-4">
          <p className="text-red-500">Failed to load configuration.</p>
        </div>
      )}
      {isConfigLoading && <Skeleton height={CONFIG_ROWS * 20} />}
      {showConfig && (
        <div className="overflow-x-auto relative">
          <div className="text-xs text-gray-500 mb-6">
            Note: "enabled" needs to be set to "true" for attribute changes to
            be applied. New attributes will appear automatically when added on
            the API.
          </div>
          <ConfigContainer
            configuration={configuration}
            updateValueByID={updateValueByID}
            onSortEnd={onSortEnd}
          />
        </div>
      )}
    </div>
  );
};

export default AttributesConfiguration;
