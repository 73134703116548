import { DeleteRequest } from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";

const ADMIN_BASE_ROUTE = "admin";

const USERS_ROUTE = "users";

export interface User {
  ID: string;
  email: string;
  oktaInviteStatus: string;
}

export const listUsersRequestURI = (): string =>
  client.getUri({
    method: "GET",
    url: createURL([ADMIN_BASE_ROUTE, USERS_ROUTE]),
  });

export const listUserCountRequestURI = (): string =>
  client.getUri({
    method: "GET",
    url: createURL([ADMIN_BASE_ROUTE, USERS_ROUTE, "count"]),
  });

export const deleteRequestURI = ({ id }: DeleteRequest): string =>
  client.getUri({
    method: "DELETE",
    url: createURL([ADMIN_BASE_ROUTE, USERS_ROUTE, id]),
  });

export const deleteUser = (args: DeleteRequest) =>
  client.delete(deleteRequestURI(args));
