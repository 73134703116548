import { PermissionEntry } from "shared/api/api";

import PermissionsTableWrapper from "features/ui/PermissionsDialog/PermissionsTableWrapper";

interface Props {
  permissions: PermissionEntry[];
  onPermissionsUpdate: (permissions: PermissionEntry[]) => void;
}

const StepMetadataACL = ({ permissions, onPermissionsUpdate }: Props) => {
  const onUpdatePermissions = (permissions: PermissionEntry[]) => {
    onPermissionsUpdate(permissions);
  };

  return (
    <div className="mt-2">
      <PermissionsTableWrapper
        entity="issue"
        permissions={permissions}
        canEdit={true}
        onPermissionsUpdate={onUpdatePermissions}
      />
      <div className="text-gray-400 mt-2 text-xs">
        You and assignee (if specified) will get <b>edit</b> permissions
        automatically.
      </div>
    </div>
  );
};

export default StepMetadataACL;
