import classNames from "classnames";
import { differenceInMinutes, toDate } from "date-fns";

import { LatestSensorReading } from "shared/api/sensors/api";
import { TireConfiguration } from "shared/api/tires/api";

import TirePressureDirection from "./TirePressureDirection";
import TireTiming from "./TireTiming";

interface Props {
  id: string;
  sensorReadings: LatestSensorReading[];
  configuration: TireConfiguration[];
}

const Tire = ({ id, configuration, sensorReadings }: Props) => {
  const configurationItem = configuration.find((item) => item.ID === id);

  if (!configurationItem) {
    return null;
  }

  const sensorReading = sensorReadings.find(
    (item) => item.sensorID === configurationItem?.pressureSensorID
  );

  // TEMPORARY. We currently store timestamps without timezone in our Clickhouse, but we know that for Tire readings they are UTC
  // As a temporary workaround, we add the Z to the end of the timestamp to treat is as UTC https://viaduct-ai.atlassian.net/browse/SVC-1063
  const UTCFormattedLatestReadAt =
    sensorReading?.latestReadAt && sensorReading?.latestReadAt + "Z";

  const timeDiffMinutes = UTCFormattedLatestReadAt
    ? differenceInMinutes(new Date(), toDate(UTCFormattedLatestReadAt))
    : 0;
  const isLate = timeDiffMinutes >= 15;

  const pressureDiff = sensorReading?.reading
    ? sensorReading.reading - configurationItem.pressureSpec
    : 0;

  return (
    <div className="shrink-0 text-center text-gray-600">
      <div
        className={classNames(
          `w-[92px] h-[138px] border border-gray-600 rounded-2xl pt-2 flex flex-col space-y-2`,
          {
            "bg-amber-50": pressureDiff > -10 && isLate,
            "bg-red-50": pressureDiff <= -10,
          }
        )}
      >
        <div className="text-gray-500 text-xs">
          spec {configurationItem.pressureSpec} PSI
        </div>
        <div className="leading-6">
          {sensorReading?.reading ? (
            <>
              <span className="text-xl">
                {Math.round(sensorReading.reading)}
              </span>{" "}
              PSI
            </>
          ) : (
            <span className="text-gray-400">No reading</span>
          )}
        </div>
        {sensorReading?.reading && (
          <TirePressureDirection
            reading={sensorReading.reading}
            pressureDiff={pressureDiff}
          />
        )}
        {UTCFormattedLatestReadAt && (
          <TireTiming
            latestReadAt={UTCFormattedLatestReadAt}
            timeDiffMinutes={timeDiffMinutes}
            isLate={isLate}
          />
        )}
      </div>
      <div className="text-xs leading-4">Tire {id}</div>
      <div className="text-xs leading-4">{configurationItem.name}</div>
    </div>
  );
};

export default Tire;
