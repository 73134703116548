import {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { HiChevronDown as ChevronDownIcon } from "react-icons/hi";
import { IoMdCloseCircle as CloseIcon } from "react-icons/io";
import { Button, Popover } from "@mui/material";

import OperatorSelect from "features/ui/Filters/FilterBuilder/FilterRow/OperatorSelect";
import ValuesSelect from "features/ui/Filters/FilterBuilder/FilterRow/ValuesSelect";
import { FilterRowState } from "features/ui/Filters/FilterBuilder/types";
import FilterLabel from "features/ui/Filters/other/FilterLabel";
import { FilterOperator } from "features/ui/Filters/types";
import { prepareFilterValuesForAPI } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  filterSchema: SchemaEntry[];
  filter: FilterRowState;
  index: number;
  onUpdate: (index: number, filter: FilterRowState) => void;
  onDelete: () => void;
}

const QuickFilter = forwardRef<{ open: () => void }, Props>(
  ({ filterSchema, filter, onUpdate, onDelete, index }: Props, ref) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    // isOpen is used only to change the color of the icon when form is open
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [innerFilter, setInnerFilter] = useState<FilterRowState>(filter);
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setIsOpen(true);
    };

    const open = useCallback(() => {
      setAnchorEl(buttonRef.current);
      setIsOpen(true);
    }, []);

    // Expose the open function through the ref
    useImperativeHandle(
      ref,
      () => ({
        open,
      }),
      [open]
    );

    const handleClose = () => {
      setAnchorEl(null);
      onUpdate(index, innerFilter);
      setIsOpen(false);
    };

    const handleDelete = (
      event: React.MouseEvent<HTMLElement> | React.MouseEvent<SVGElement>
    ) => {
      event.preventDefault();
      event.stopPropagation();

      onDelete();

      // If you have any popover/modal state, close it
      setAnchorEl?.(null);
    };

    const handleClear = (
      event: React.MouseEvent<HTMLElement> | React.MouseEvent<SVGElement>
    ) => {
      event.preventDefault();
      event.stopPropagation();

      filter.values = [];
      filter.operator = null;
      setInnerFilter({ ...filter });

      // If you have any popover/modal state, close it
      setAnchorEl?.(null);
      onUpdate(index, filter);
    };

    const schema = filterSchema.find(
      (schemaEntry) => schemaEntry.accessor === filter.attribute
    );

    const isFilterValid =
      filter.attribute &&
      filter.operator &&
      filter.values &&
      filter.values.length > 0;

    if (!schema) return null;

    const label = () =>
      isFilterValid && (
        <div className="cursor-pointer pointer-events-none">
          <FilterLabel
            filters={{
              type: "group",
              id: "0",
              anyAll: "all",
              children: [filter],
            }}
            fieldName={schema.accessor}
            keyNameMap={{ [schema.accessor]: schema.label }}
            format="label-inline"
            dense
          ></FilterLabel>
        </div>
      );

    return (
      <>
        <Button
          ref={buttonRef}
          onClick={handleClick}
          variant="outlined"
          className={classNames(
            "w-full !justify-start hover:!bg-gray-100  flex-row !px-2 !py-0.5 gap-1 h-8 inline-flex items-center",
            {
              "!text-blue-500 !border !border-blue-500": isOpen,
              "!border-gray-300 !text-gray-600": !isOpen,
            }
          )}
        >
          <span className="text-xs w-full">
            {isFilterValid ? label() : schema.label}
          </span>

          {!isFilterValid && <ChevronDownIcon size={24} />}
          {isFilterValid && (
            <CloseIcon
              size={24}
              className="hover:text-blue-500 text-gray-300"
              onClick={handleClear}
            ></CloseIcon>
          )}
        </Button>

        <Popover
          key={filter.id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transitionDuration={0}
          disableRestoreFocus
        >
          <form
            onSubmit={(ev) => ev.preventDefault()}
            className="w-auto min-w-60 max-w-60 p-2 flex flex-col gap-2"
          >
            <div className="flex flex-row justify-between gap-2">
              <Button
                variant="outlined"
                size="small"
                color="error"
                className="flex-1"
                onClick={handleDelete}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                size="small"
                type="submit"
                className="flex-1"
                onClick={handleClose}
              >
                Confirm
              </Button>
            </div>
            <OperatorSelect
              autoFocus={
                filter.operator === null ||
                filter.operator === FilterOperator.NOT_FILTERED
              }
              selected={filter.operator}
              onChange={(val) => {
                let values = prepareFilterValuesForAPI(
                  filter.values ?? [],
                  val
                );

                filter.operator = val;
                filter.values = values;
                setInnerFilter({ ...filter });
              }}
              disabled={filter.attribute === null}
              attributeSchema={schema}
            ></OperatorSelect>
            <ValuesSelect
              autoFocus={true}
              attribute={filter.attribute}
              operator={filter.operator}
              values={filter.values}
              schema={schema}
              onChange={(values) => {
                filter.values = values;
                setInnerFilter({ ...filter });
              }}
              disabled={filter.operator === null}
            />
          </form>
        </Popover>
      </>
    );
  }
);

export default QuickFilter;
