import { useState } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";

import {
  Bookmark as BookmarkModel,
  deleteBookmark,
  updateBookmark,
  UpdateBookmarkRequest,
} from "shared/api/bookmarks/api";
import { useListBookmarks } from "shared/api/bookmarks/hooks";

import { LANDING_PAGE_BOOKMARKS_TITLE } from "pages/LandingPage/constants";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";

import Bookmark from "./Bookmark";
import {
  DEFAULT_SORT,
  ROWS_PER_PAGE,
  SUCCESS_DELETE_TEXT,
  SUCCESS_UPDATE_TEXT,
} from "./constants";
import CreateBookmarkAction from "./CreateBookmarkAction";

const Bookmarks = () => {
  const [refreshKey, setRefreshKey] = useState(false);
  const { data, isLoading, error } = useListBookmarks({
    sort: DEFAULT_SORT,
    limit: ROWS_PER_PAGE,
    refreshKey,
  });

  const onDelete = async (bookmark: BookmarkModel) => {
    await deleteBookmark({ id: bookmark.ID });
    toast.success(SUCCESS_DELETE_TEXT);
    setRefreshKey(!refreshKey);
  };

  const onUpdate = async (bookmark: UpdateBookmarkRequest) => {
    await updateBookmark(bookmark);
    toast.success(SUCCESS_UPDATE_TEXT);
    setRefreshKey(!refreshKey);
  };

  if (isLoading) return <Skeleton height={200} />;

  if (error) return <APIError error={error} />;

  if (data)
    return (
      <Card>
        <div className="flex flex-col justify-between w-full max-h-[30rem]">
          <div className="flex flex-row justify-between text-center mb-1">
            <h3 className="flex space-x-3 items-center font-semibold capitalize">
              {LANDING_PAGE_BOOKMARKS_TITLE}
            </h3>
            <CreateBookmarkAction
              isEmpty={true}
              onCreated={() => setRefreshKey(!refreshKey)}
            />
          </div>

          {data.length > 0 && (
            <div className="bg-gray-100 flex flex-row gap-2 p-3 overflow-x-auto">
              {data.map((bookmark) => (
                <Bookmark
                  key={bookmark.ID}
                  bookmark={bookmark}
                  onDelete={onDelete}
                  onUpdate={onUpdate}
                />
              ))}
            </div>
          )}

          {data.length === 0 && (
            <span className="text-sm">No quick starts available.</span>
          )}
        </div>
      </Card>
    );
};
export default Bookmarks;
