import { useVehiclesFiltersSchema } from "shared/hooks";

import PageLevelFilters from "pages/shared/PageLevelFilters/PageLevelFilters";

import Filters from "features/ui/Filters";
import { removeAttributesFromFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import { GLOBAL_FILTERS, VEHICLES_PAGE_KEY } from "./constants";

interface Props {
  vehiclesFilterSortState: UseFilterSortState;
  hideCollectionFilter?: boolean;
  vehicleAccessorsToHide?: string[];
}

export const VEHICLE_PENDING_FILTERS_LS_KEY =
  getPendingFiltersKey(VEHICLES_PAGE_KEY);

const LABEL = "Vehicle Filters";
const ATTRIBUTES_TO_IGNORE = ["collection"];

const VehicleFilters = ({
  vehiclesFilterSortState,
  hideCollectionFilter,
  vehicleAccessorsToHide,
}: Props) => {
  const vehicleFiltersSchema = useVehiclesFiltersSchema(
    undefined,
    vehicleAccessorsToHide
  );

  const filteredFilters = removeAttributesFromFilterGroupState(
    vehiclesFilterSortState.filters,
    ATTRIBUTES_TO_IGNORE
  );

  const label = getFilterLabel(LABEL, filteredFilters);

  return (
    <PageLevelFilters
      actionsBefore={
        !hideCollectionFilter && (
          <Filters
            initialized={vehiclesFilterSortState.initialized}
            schema={GLOBAL_FILTERS}
            onFilterChange={vehiclesFilterSortState.manageFilterChange}
            filters={vehiclesFilterSortState.filters}
            horizontal
          />
        )
      }
      filtersProps={[
        {
          filterSortState: {
            ...vehiclesFilterSortState,
            filters: filteredFilters,
          },
          filterSchema: vehicleFiltersSchema,
          filterTitle: LABEL,
          filterLabel: label,
          pendingFilterKey: VEHICLE_PENDING_FILTERS_LS_KEY,
          entity: "Vehicle",
          testId: "vehicles",
          quickFilterLabel: "Vehicle Quick Filters",
        },
      ]}
    />
  );
};

export default VehicleFilters;
