import * as api from "shared/api/api";
import parseLinkHeader from "shared/api/parse-link-header";

import { API_MAX_LIMIT } from "./constants";

/**
 * Fetches all pages of data using the provided fetch function and arguments.
 *
 * @template T - The type of the data items.
 * @template A - The type of the API request arguments, extending `api.APIPaginatedRequest`.
 *
 * @param fetchFunction - A function that fetches a page of data and returns a promise that resolves to an object containing the data and headers.
 * @param args - The arguments to pass to the fetch function.
 * @param limit - The maximum number of items to fetch. (note: args.limit is the number of items per page with max of 2000)
 *
 * @returns A promise that resolves to an array containing all fetched data items.
 */
export const getPages = async <T, A extends api.APIPaginatedRequest>(
  fetchFunction: (args: A) => Promise<{ data: T[]; headers: api.APIHeaders }>,
  args: A,
  limit?: number
): Promise<T[]> => {
  let allItems: T[] = [];
  let before: string | undefined = undefined;
  let after: string | undefined = undefined;

  // Use the maximum limit of 2000 items per page for efficiency.
  args.limit = Math.min(limit ?? API_MAX_LIMIT, API_MAX_LIMIT);
  while (true) {
    const response = await fetchFunction({
      ...args,
      before,
      after,
    });

    allItems = allItems.concat(response.data);

    if (limit && allItems.length >= limit) {
      allItems = allItems.slice(0, limit);
      break;
    }

    const links = parseLinkHeader(response.headers.link);
    if (!links?.next) {
      break;
    }

    before = links.next.before;
    after = links.next.after;
  }

  return allItems;
};
