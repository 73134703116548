import classNames from "classnames";

export const KeyBindingTag = ({
  children,
  square = false,
}: {
  children: React.ReactNode;
  square?: boolean;
}) => (
  <kbd
    className={classNames(
      "text-xs flex flex-row border rounded-sm pl-1 pr-1 h-6 items-center justify-center",
      { "w-6": square }
    )}
  >
    {children}
  </kbd>
);
