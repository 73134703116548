import {
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
} from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import * as api from "./api";

export const useListServicePlans = (args: api.ListServicePlansRequest) =>
  usePaginatedAPI<typeof args, api.ServicePlan[]>(
    api.listServicePlansRequestURI,
    args
  );

export const useListServicePlansCount = (args: api.ListServicePlansRequest) =>
  useAPI<typeof args, CountResponse>(api.listServicePlansCountRequestURI, args);

export const useServicePlanAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    api.listServicePlansAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );
