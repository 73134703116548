import classNames from "classnames";
import { IoTriangleSharp as TriangleIcon } from "react-icons/io5";

interface Props {
  reading: number;
  pressureDiff: number;
}

const ICON_SIZE = 22;

const TirePressureDirection = ({ pressureDiff }: Props) => {
  const isNegative = pressureDiff < 0;

  if (Math.abs(pressureDiff) <= 1) {
    return <div>At spec</div>;
  }

  return (
    <>
      <div className="flex items-center space-x-1 w-full justify-center">
        <TriangleIcon
          size={ICON_SIZE}
          className={classNames(
            {
              "rotate-180": isNegative,
              "text-red-600": pressureDiff <= -10,
            },
            "text-gray-400"
          )}
        />
        <b>{Math.round(Math.abs(pressureDiff))}</b>{" "}
        <span className="text-xs">PSI</span>
      </div>
    </>
  );
};

export default TirePressureDirection;
