import React from "react";

import Button from "features/ui/Button";

interface Props {
  label?: string;
  disabled?: boolean;
  onClick: () => void;
}

const DEFAULT_LABEL = "Add as Related Signal Event";

const AddAsOccursFilterAction = ({
  label = DEFAULT_LABEL,
  disabled = false,
  onClick,
}: Props) => (
  <Button
    label={label}
    variant="text"
    color="primary"
    testId="add-as-related-signal-event-cta"
    disabled={disabled}
    onClick={onClick}
  />
);

export default AddAsOccursFilterAction;
