import { getTenantFromHostname } from "shared/utils";

import * as config from "config/config";

/**
 * This is intentionally in its own file due to Jest import problems that occur when
 * defined in shared/api/api.ts, shared/api/axios.ts, or shared/api/utils.ts
 */
export const getAPIUrl = (): string => {
  if (import.meta.env.MODE === "test") {
    return "http://localhost:3001";
  }

  const tenant = getTenantFromHostname();
  const conf = config.get();

  if (tenant === "localhost") {
    return import.meta.env.VITE_API_BASE_URL || "http://localhost:3001";
  }

  if (
    conf.instance === "local" &&
    conf.multiTenant &&
    ["viaduct", "local"].includes(tenant)
  ) {
    // if we are using multi-tenant routes addresses
    return `http://api.${tenant}.cloud.viaduct.ai:3001`;
  }

  return `https://api.${tenant}.cloud.viaduct.ai`;
};
