import {
  APIListValuesRequest,
  APIListValuesResponse,
  getFetcher,
  ListAttributesRequest,
} from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";

const REPAIR_PARTS_BASE_URL = "repairParts";

export const listRepairPartsAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIR_PARTS_BASE_URL, "attributes"]),
    params,
  });

const listRepairPartsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIR_PARTS_BASE_URL, "values", fieldName]),
    params,
  });

export const listRepairPartsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listRepairPartsValuesRequestURI(args));
