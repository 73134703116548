import { useListGroups } from "shared/api/rbac/hooks";

import { DUMMY_GROUP_SELECT_OPTION } from "pages/Groups/utils";

import Select, { SelectOption } from "features/ui/Select";
import { NONE_SELECTED_OPTION } from "features/ui/Select/Select";

interface Props {
  assignedGroupID: string | null;
  onUpdate: (
    field: string,
    value: string | null,
    fieldNiceName?: string
  ) => void;
  label: string;
  disabled?: boolean;
  testId?: string;
}

const GroupDropdown = ({
  assignedGroupID,
  onUpdate,
  label,
  disabled = false,
  testId = "group-dropdown",
}: Props) => {
  const { data: groups } = useListGroups({});
  const groupSelectOptions: SelectOption[] =
    groups?.map(({ ID, name }) => ({
      id: ID,
      value: name,
    })) || [];

  const selectedGroup: SelectOption | undefined =
    groupSelectOptions.find((group) => group.id === assignedGroupID) ||
    (assignedGroupID ? DUMMY_GROUP_SELECT_OPTION : undefined);

  return (
    <Select
      label={label}
      options={groupSelectOptions}
      allowNoneSelected={true}
      fullWidth
      disabled={disabled}
      selected={selectedGroup}
      onSelect={(option) => {
        if (selectedGroup && option.id === selectedGroup?.id) return;

        const valueForAPI =
          option.id === NONE_SELECTED_OPTION.id ? null : (option.id as string);

        onUpdate("assignedGroupID", valueForAPI, label);
      }}
      testId={testId}
    />
  );
};
export default GroupDropdown;
