import { getValueByAccessor } from "features/ui/Table/utils";

import { InfoDataMap } from "./types";

export const shouldDisplayInfoCard = (
  schema: InfoDataMap[],
  whitelistedAttributes?: string[],
  data?: Record<string, any>
) => {
  if (!data) {
    return false;
  }

  return schema.some((schemaItem: InfoDataMap) => {
    if (
      schemaItem.whitelistKey &&
      !whitelistedAttributes?.includes(schemaItem.whitelistKey)
    ) {
      return false;
    }

    if (
      !schemaItem.whitelistKey &&
      !whitelistedAttributes?.includes(schemaItem.key)
    ) {
      return false;
    }

    const value = getValueByAccessor(schemaItem.key, data);
    if (Array.isArray(value) && value.length < 1) {
      return false;
    }

    return !["", undefined, null].includes(value as any);
  });
};
