import { useMemo } from "react";
import { generatePath } from "react-router";
import { Link } from "react-router-dom";

import {
  getRepairsExport,
  ListRepairsRequest,
  Repair,
} from "shared/api/repairs/api";
import { useListRepairs, useListRepairsCount } from "shared/api/repairs/hooks";
import { applyAdditionalSorting, getSortFilter } from "shared/api/utils";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import { useTenantMileageUnit } from "shared/hooks";
import { useRepairsSchema } from "shared/schemas/repairsSchema";
import { SortBy } from "shared/types";

import { RepairsFiltersProps } from "pages/RepairAnalytics/RepairAnalyticsTabs";
import { VINEventTimelineDateLink } from "pages/shared/vehicles/VINEventTimelineDateLink";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { OnSortParams } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

const PAGE_KEY = "repairAn-repairsTable";
const ROWS_PER_PAGE = 15;
const DEFAULT_SORT: SortBy = { date: "desc" };

export const formatRow = (repair: Repair) => {
  const { ID, VIN, externalID, externalURL, date } = repair;

  return {
    ...repair,
    ID: (
      <Link
        className="text-metabase-blue hover:underline"
        to={generatePath(routes.repairAnalyticsDetails, {
          id: ID,
        })}
      >
        Go to Repair
      </Link>
    ),
    externalID: externalURL ? (
      <Link
        target="_blank"
        to={externalURL}
        className="text-metabase-blue hover:underline"
      >
        {externalID}
      </Link>
    ) : (
      externalID
    ),
    VIN: (
      <VINEventTimelineDateLink
        VIN={VIN}
        date={date}
        useDefaultFromDate={true}
      />
    ),
  };
};

const RepairsTable = ({
  repairsFilters,
  vehiclesFilters,
  onBadRequest,
}: RepairsFiltersProps) => {
  const { schema, attributeAccessors } = useRepairsSchema();

  const {
    manageFilterChange,
    resetFilters,
    filters,
    initialized: filtersInitialized,
    sort,
    manageOnSortChange,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
    schemaAttributes: attributeAccessors,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const requestParams: ListRepairsRequest = {
    sort: getSortFilter(applyAdditionalSorting(sort)),
    filter: getFiltersQuery(mergeFilterGroupStates(filters, repairsFilters)),
    vehiclesFilter: vehiclesFilters && getFiltersQuery(vehiclesFilters),
    limit: ROWS_PER_PAGE,
    analytics: true,
    mileageUnit: useTenantMileageUnit(),
  };

  const { data, isLoading, error, ...paginationData } =
    useListRepairs(requestParams);

  // re-format the data - but only when data changes
  const formattedData = useMemo(
    () => data?.map((entry) => formatRow(entry)),

    [data]
  );
  const downloadDisabled = !formattedData || formattedData.length === 0;

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useListRepairsCount({
    filter: requestParams.filter,
    vehiclesFilter: requestParams.vehiclesFilter,
    analytics: true,
  });

  return (
    <>
      <div className="flex items-center mt-3 mb-1">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto"
          count={countData?.count as number}
          entityName="repair"
          isLoading={countIsLoading}
          error={!!countError}
        />
        <DownloadAction
          disabled={downloadDisabled}
          downloadFunc={getRepairsExport}
          fileName="repairs"
          requestParams={{
            ...requestParams,
            limit: MAX_ROWS_DOWNLOAD_LIMIT,
          }}
          count={countData?.count as number}
          entityName="repair"
          filters={mergeFilterGroupStates(
            filters,
            repairsFilters,
            vehiclesFilters
          )}
        />
      </div>
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
        />
      )}
      {error && (
        <APIError
          error={error}
          onBadRequest={() => {
            resetFilterSortState();
            onBadRequest();
          }}
        />
      )}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default RepairsTable;
