import { IconProps } from "./types";

const InspectionAnalyticsIcon = ({ size }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M15.5138 6.49625L9.50342 0.48584L6.49821 3.49104L5.99735 5.99538L0.487806 11.5049L4.49474 15.5119L10.0043 10.0023L12.5086 9.50145L15.5138 6.49625ZM13.5104 6.49625L13.0095 6.99711L9.00255 2.99018L9.50342 2.48931L13.5104 6.49625ZM9.30307 8.70006L4.49474 13.5084L2.49128 11.5049L7.30461 6.69159L7.80548 4.18725L8.00082 3.99191L12.0078 7.99885L11.8124 8.19419L9.30307 8.70006Z" />
  </svg>
);

export default InspectionAnalyticsIcon;
