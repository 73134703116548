import { SortBy } from "shared/types";

import { ChartAction } from "features/ui/charts/Actions/types";
import { MAX_FILTER_ID } from "features/ui/Filters/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { FilterOperator } from "features/ui/Filters/types";

export const EDIT_ISSUE_CLAIMS_INDEX = 2;
export const EDIT_ISSUE_SIGNAL_EVENTS_INDEX = 3;
export const EDIT_ISSUE_OVERVIEW_INDEX = 4;
export const VALUES_SEPARATOR = "___";
export const EMPTY_CHART_LABEL = "(unknown)";
export const EMPTY_GROUP_CHART_LABEL = "(unassigned or no access)";

export const SUGGESTED_ISSUES_PAGE_KEY = "suggested_issues_list_v2";
export const DEFAULT_SUGGESTED_ISSUES_SORT: SortBy = {
  discovered: "desc",
  name: "asc",
};
export const DEFAULT_SUGGESTED_ISSUES_FILTER: FilterGroupState = {
  id: "group-0",
  type: "group",
  anyAll: "all",
  children: [
    {
      id: "row-0",
      type: "row",
      attribute: "updated",
      operator: FilterOperator.IN,
      values: [MAX_FILTER_ID],
    },
  ],
};
export const SUGGESTED_ISSUES_DASHBOARD_KEY =
  "suggested_issues_dashboard_state";
export const SUGGESTED_ISSUES_CHART_KEY =
  "suggestedIssuesDashboardChartOptions_v5";

export const SUGGESTED_ISSUES_GROUP_BY_ACTION: ChartAction = {
  id: "groupBy",
  title: "X-axis",
  type: "dropdownSelect",
  defaultOptionId: "assignedGroupID",
  options: [
    {
      id: "statusObj.value",
      label: "Status",
      value: "Status",
    },
    {
      id: "name",
      label: "Name",
      value: "Name",
    },
    {
      id: "confidence",
      label: "Confidence",
      value: "Confidence",
    },
    {
      id: "assignedGroupID",
      label: "Responsible Group",
      value: "Responsible Group",
    },
    {
      id: "statusUpdatedAt",
      label: "Age",
      value: "Age",
    },
  ],
};

export const ISSUES_GROUP_BY_ACTION: ChartAction = {
  id: "groupBy",
  title: "X-axis",
  type: "dropdownSelect",
  defaultOptionId: "statusObj.value",
  options: [
    {
      id: "statusObj.value",
      label: "Status",
      value: "Status",
    },
    {
      id: "name",
      label: "Name",
      value: "Name",
    },
    {
      id: "assignedGroupID",
      label: "Responsible Group",
      value: "Responsible Group",
    },
    {
      id: "severityObj.value",
      label: "Type",
      value: "Type",
    },
    {
      id: "assignee",
      label: "Owner",
      value: "Owner",
    },
    {
      id: "createdBy",
      label: "Created By",
      value: "Created By",
    },
    {
      id: "statusUpdatedAt",
      label: "Age",
      value: "Age",
    },
  ],
};

export const ISSUES_BUCKET_BY_ACTION: ChartAction = {
  id: "bucketBy",
  title: "Cell-axis",
  type: "dropdownSelect",
  defaultOptionId: "none",
  options: [
    {
      id: "none",
      label: "None",
      value: "None",
      delimiterBelow: true,
    },
    {
      id: "cmp1WeekAgo",
      label: "compared to 1 week ago",
      value: "Compare to 1 week ago",
    },
    {
      id: "cmp2WeeksAgo",
      label: "compared to 2 weeks ago",
      value: "Compare to 2 weeks ago",
    },
    {
      id: "cmp3WeeksAgo",
      label: "compared to 3 weeks ago",
      value: "Compare to 3 weeks ago",
    },
    {
      id: "cmp4WeeksAgo",
      label: "compared to 4 weeks ago",
      value: "Compare to 4 weeks ago",
      delimiterBelow: true,
    },
    {
      id: "statusObj.value",
      label: "Status",
      value: "Status",
    },
    {
      id: "assignedGroupID",
      label: "Responsible Group",
      value: "Responsible Group",
    },
    {
      id: "severityObj.value",
      label: "Type",
      value: "Type",
    },
    {
      id: "assignee",
      label: "Owner",
      value: "Owner",
    },
    {
      id: "createdBy",
      label: "Created By",
      value: "Created By",
    },
    {
      id: "statusUpdatedAt",
      label: "Age",
      value: "Age",
    },
  ],
};

export const SUGGESTED_ISSUES_BUCKET_BY_ACTION: ChartAction = {
  id: "bucketBy",
  title: "Cell-axis",
  type: "dropdownSelect",
  defaultOptionId: "none",
  options: [
    {
      id: "none",
      label: "None",
      value: "None",
    },
    {
      id: "statusObj.value",
      label: "Status",
      value: "Status",
    },
    {
      id: "confidence",
      label: "Confidence",
      value: "Confidence",
    },
    {
      id: "assignedGroupID",
      label: "Responsible Group",
      value: "Responsible Group",
    },
    {
      id: "statusUpdatedAt",
      label: "Age",
      value: "Age",
    },
  ],
};

export const VALUE_TYPE_ACTION: ChartAction = {
  id: "valueType",
  title: "",
  type: "",
  defaultOptionId: "absolute",
  options: [
    {
      id: "absolute",
      label: "Absolute",
      value: "Absolute",
    },
    {
      id: "percentage",
      label: "Percentage",
      value: "Percentage",
    },
  ],
};

export const SPLIT_BY_ISSUE_SOURCE_ACTION: ChartAction = {
  id: "splitByIssueSource",
  title: "Y-axis",
  type: "boolean",
  checkboxLabel: "Split by Issue Source",
};

export type SuggestedIssuesChartActionIdType =
  | "measure"
  | "lookbackWindow"
  | "valueType"
  | "groupBy"
  | "bucketBy";

export const ISSUES_PAGE_KEY = "issues_v3";
export const DEFAULT_ISSUES_SORT: SortBy = { name: "asc" };
export const DEFAULT_ISSUES_FILTER: FilterGroupState = {
  id: "group-0",
  type: "group",
  anyAll: "all",
  children: [
    {
      id: "row-0",
      type: "row",
      attribute: "statusObj.value",
      operator: FilterOperator.NOT_IN,
      values: ["Closed"],
    },
  ],
};
export const ISSUES_DASHBOARD_KEY = "issues_dashboard_state";
export const ISSUES_CHART_KEY = "issuesDashboardChartOptions_v4";
export const MEASURE_PERCENTAGE_LABELS: Record<string, string> = {
  affected_vehicles_claims:
    "Change (%) in Affected Vehicles experiencing Related Claims",
  affected_vehicles_signal_events:
    "Change (%) in Affected Vehicles experiencing Related Signal Events",
  affected_vehicles_signal_events_high_priority:
    "Change (%) in Affected Vehicles experiencing High Priority Related Signal Events",
  warranty_cost: "Change (%) in Warranty Cost",
};

export type IssuesChartActionIdType =
  | "measure"
  | "lookbackWindow"
  | "valueType"
  | "groupBy"
  | "splitByIssueSource"
  | "bucketBy";

export const ISSUE_HISTORIC_COMPARISONS = [
  "cmp1WeekAgo",
  "cmp2WeeksAgo",
  "cmp3WeeksAgo",
  "cmp4WeeksAgo",
] as const;

export const ISSUES_KEY = "issues";
export const ISSUES_TITLE = "Issues";
export const SUGGESTED_ISSUES_KEY = "suggested_issues";
export const SUGGESTED_ISSUES_TITLE = "Suggested Issues";

export const ISSUE_CLAIMS_TAB_KEY = "issue_claims_tab";
export const ISSUE_SIGNAL_EVENTS_TAB_KEY = "issue_signal_events_tab";
export const ISSUE_RELATIONSHIP_TAB_KEY = "issue_relationship_tab";
export const ISSUE_REPAIR_EFFICACY_TAB_KEY = "issue_repair_efficacy_tab";
export const WITH_COMPARISON_POPULATION_KEY =
  "issuesWithComparisonPopulationSelection";
export const ISSUE_ACTIVITY_TAB_KEY = "issue_activity_tab";

export const ISSUE_DETAILS_TAB_TITLES = {
  [ISSUE_CLAIMS_TAB_KEY]: "Claim Analysis",
  [ISSUE_SIGNAL_EVENTS_TAB_KEY]: "Signal Event Analysis",
  [ISSUE_RELATIONSHIP_TAB_KEY]: "Relationship Analysis",
  [ISSUE_REPAIR_EFFICACY_TAB_KEY]: "Repair Efficacy",
  [ISSUE_ACTIVITY_TAB_KEY]: "Activity",
};

// Define enums for each tab's charts
export enum ClaimsCharts {
  OCCURRENCES_BY_CALENDAR_TIME = "Claims_OccurrencesByCalendarTime",
  OCCURRENCES_BY_VEHICLE_AGE = "Claims_OccurrencesByVehicleAge",
}

export enum SignalEventsCharts {
  OCCURRENCES_BY_CALENDAR_TIME = "SignalEvents_OccurrencesByCalendarTime",
  OCCURRENCES_BY_VEHICLE_AGE = "SignalEvents_OccurrencesByVehicleAge",
}

export enum RelationshipsCharts {
  COMBINED_OCCURRENCES_BY_CALENDAR_TIME = "Relationships_CombinedOccurrencesByCalendarTime",
  COMBINED_OCCURRENCES_BY_VEHICLE_AGE = "Relationships_CombinedOccurrencesByVehicleAge",
  ASSOCIATED_SIGNAL_EVENTS = "Relationships_AssociatedSignalEvents",
}

export enum RepairEfficacyCharts {
  REOCCURRENCE_BY_ATTRIBUTE = "RepairEfficacy_ReoccurrenceByAttribute",
  REOCCURRENCE_BY_POPULATION = "RepairEfficacy_ReoccurrenceByPopulation",
  REOCCURRENCE_BAR = "RepairEfficacy_ReoccurrenceBar",
  REOCCURRENCE_PROCEEDING_A_CLAIM = "RepairEfficacy_ReoccurrenceProceedingAClaim",
  // TODO: Note this chart is not using a CHART_NAME/Key (or I haven't found it yet),
  // Adding it here for completeness.
  REOCCURRENCE_PROCEEDING_A_CLAIM_TIMELINE = "RepairEfficacy_ReoccurrenceProceedingAClaimTimeline",
}

// Create a mapping from each tab key to the corresponding enum
export const ISSUE_DETAILS_TAB_CHARTS = {
  [ISSUE_CLAIMS_TAB_KEY]: ClaimsCharts,
  [ISSUE_SIGNAL_EVENTS_TAB_KEY]: SignalEventsCharts,
  [ISSUE_RELATIONSHIP_TAB_KEY]: RelationshipsCharts,
  [ISSUE_REPAIR_EFFICACY_TAB_KEY]: RepairEfficacyCharts,
  [ISSUE_ACTIVITY_TAB_KEY]: {},
} as const;

export const ISSUES_PENDING_FILTERS_LS_KEY =
  getPendingFiltersKey(ISSUES_PAGE_KEY);

export const EMPTY_ACTIVITY_ITEM_VALUE = "n/a";
export const ADDED_COLOR = "bg-green-100";
export const DELETED_COLOR = "bg-red-100";
