import {
  createContext,
  MutableRefObject,
  useCallback,
  useRef,
  useState,
} from "react";
import {
  CHAT_END_EVENT,
  CHAT_START_EVENT,
  CHAT_STREAM_EVENT,
} from "duck/context/constants";
import { DuckMessageAuthor, DuckMessageFormat } from "duck/context/types";
import { StringSetter } from "duck/graph/types";
import { DUCK_MESSAGES_KEY, DUCK_WELCOME_MESSAGE } from "duck/ui/constants";
import { usePendingAction } from "duck/ui/hooks";
import { StreamEvent } from "@langchain/core/dist/tracers/event_stream";

import { ContextWrapComponentProps } from "shared/contexts/types";
import { cloneObject } from "shared/utils";

export interface DuckMessage {
  message: string;
  author: DuckMessageAuthor;
  format?: DuckMessageFormat;
  options?: Record<string, any>;
  runId?: string;
}

interface DuckContextInterface {
  messages: DuckMessage[];
  addMessage: (message: DuckMessage) => void;
  clearMessages: () => void;
  ephemeralMessage: string;
  setEphemeralMessage: StringSetter;
  streamingMessage: string;
  streamedMessage: MutableRefObject<boolean>;
  handleStreamEvent: (streamEvent: StreamEvent) => void;
  pendingAction: boolean;
  setPendingAction: (pendingAction: boolean) => void;
  loading: boolean;
  setLoading: (pendingAction: boolean) => void;
  setCurrentRunId: (runId: string) => void;
}

const DEFAULT_CONTEXT: DuckContextInterface = {
  messages: [],
  addMessage: () => {},
  clearMessages: () => {},
  ephemeralMessage: "",
  setEphemeralMessage: () => {},
  streamingMessage: "",
  streamedMessage: { current: false },
  handleStreamEvent: () => {},
  pendingAction: false,
  setPendingAction: () => {},
  loading: false,
  setLoading: () => {},
  setCurrentRunId: () => {},
};

export const DuckContext = createContext<DuckContextInterface>(DEFAULT_CONTEXT);

const getInitialMessages = (): DuckMessage[] => {
  try {
    const messagesString = sessionStorage.getItem(DUCK_MESSAGES_KEY);
    if (messagesString) {
      return JSON.parse(messagesString);
    }

    return [DUCK_WELCOME_MESSAGE];
  } catch {
    return [DUCK_WELCOME_MESSAGE];
  }
};

/**
 * @summary This context manages the messages in a Duck session.
 * These messages are visualized in the UI and are also sent with each request to the agent.
 * @returns The context provides a list of messages, a function to add a message,
 * and a function to clear all messages.
 */
const DuckContextWrapper = ({ children }: ContextWrapComponentProps) => {
  const [messages, setMessages] = useState<DuckMessage[]>(getInitialMessages());
  const [ephemeralMessage, setEphemeralMessage] = useState("");

  const [streamingMessage, setStreamingMessage] = useState("");

  const [loading, internalSetLoading] = useState(false);

  const pendingStreamingLink = useRef("");

  const setLoading = (loading: boolean) => {
    internalSetLoading(loading);
    if (loading) {
      streamedMessage.current = false;
    }
  };

  const currentRunId = useRef("");

  const setCurrentRunId = (runId: string) => {
    currentRunId.current = runId;
  };

  const streamedMessage = useRef(false);

  const { pendingAction, setPendingAction } = usePendingAction();

  const addMessage = useCallback((message: DuckMessage) => {
    // We save the current run id value in a variable here because the value of the ref
    // can and sometimes does change before the state update is processed.
    const savedCurrentRunId = currentRunId.current;

    setMessages((previousMessages) => {
      const newMessages = [
        ...previousMessages,
        message.options?.suppressFeedback
          ? message
          : { ...cloneObject(message), runId: savedCurrentRunId },
      ];
      sessionStorage.setItem(DUCK_MESSAGES_KEY, JSON.stringify(newMessages));

      return newMessages;
    });
  }, []);

  const clearMessages = useCallback(() => {
    setMessages([DUCK_WELCOME_MESSAGE]);
    sessionStorage.removeItem(DUCK_MESSAGES_KEY);
  }, []);

  /**
   * The finishesLink function intentionally oversimplifies the logic for
   * determining if a chunk finishes a link because:
   * 1. The consequences for getting it wrong are low. If we say a link is finished
   * when it is not, then it will be displayed prematurely which may (or may not)
   * cause some visual flickering. If we say a link is not finished when it is,
   * then the rest of the response will be buffered, and all of it will be displayed
   * at once when streaming is complete. Not that big a deal.
   * 2. This simple logic will work correctly a high percentage of the time.
   * Prompts like "what is iptv? format your response as [IPTV]: definition" would
   * be incorrectly handled as a link without an end. But prompts like that are not
   * likely to happen during normal operation.
   * 3. Complete and correct logic is quite complex. We would need to be sure that
   * the buffered response plus the chunk matches a regex of ^\[([^\]]+)\]\(([^)]+)\).
   * We'd also need to check to see if another link was started in the chunk.
   * We'd also need to check for a "]" that is not followed by a "(", which is not a link.
   * Even if we tried hard, we would likely end up having occasional failures.
   * @param chunk The latest chunk of the streaming response.
   * @returns True if a link is finished in this chunk.
   */
  const finishesLink = (chunk: string): boolean => chunk.includes(")");

  const startsLink = (chunk: string): boolean => chunk.includes("[");

  const handleIncomingChunk = (chunk: string) => {
    if (pendingStreamingLink.current) {
      if (finishesLink(chunk)) {
        const chunkWithLink = pendingStreamingLink.current + chunk;
        setStreamingMessage(
          (previousMessage) => previousMessage + chunkWithLink
        );
        pendingStreamingLink.current = "";
      } else {
        pendingStreamingLink.current += chunk;
      }
    } else if (startsLink(chunk)) {
      pendingStreamingLink.current = chunk;
    } else {
      setStreamingMessage((previousMessage) => previousMessage + chunk);
    }
  };

  const handleStreamEvent = (streamEvent: StreamEvent) => {
    if (streamEvent.event === CHAT_START_EVENT) {
      setStreamingMessage("");
    } else if (streamEvent.event === CHAT_STREAM_EVENT) {
      handleIncomingChunk(streamEvent.data.chunk.content);
    } else if (streamEvent.event === CHAT_END_EVENT) {
      addMessage({
        author: DuckMessageAuthor.AGENT,
        message: streamEvent.data.output.content,
      });
      setStreamingMessage("");
      streamedMessage.current = true;
      pendingStreamingLink.current = "";
      setLoading(false);
    }
  };

  const contextValue: DuckContextInterface = {
    messages,
    addMessage,
    clearMessages,
    ephemeralMessage,
    setEphemeralMessage,
    streamingMessage,
    streamedMessage,
    handleStreamEvent,
    pendingAction,
    setPendingAction,
    loading,
    setLoading,
    setCurrentRunId,
  };

  return (
    <DuckContext.Provider value={contextValue}>{children}</DuckContext.Provider>
  );
};

export default DuckContextWrapper;
