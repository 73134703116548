import { MouseEvent, useEffect, useState } from "react";
import DuckErrorBoundary from "duck/ui/error/DuckErrorBoundary";
import invokeDetermineLanguageAgent from "duck/ui/translate/agents/determineLanguageAgent";
import invokeTranslateAgent from "duck/ui/translate/agents/translateAgent";
import DuckTranslateMenuOption from "duck/ui/translate/DuckTranslateMenuOption";
import { hasAllEnvVarsForViaDuck } from "duck/ui/utils";
import { IoGlobeOutline } from "react-icons/io5";
import { Button, Menu } from "@mui/material";

import { useConfigContext } from "shared/contexts/ConfigContext";

import { VIADUCK_PAGE_CONFIG_KEY } from "pages/SuperAdmin/PagesConfig/types";

const TARGET_LANGUAGE = "English";

interface DuckTranslateProps {
  originalText: string;
  setValue: (value: string) => void;
}

const DuckTranslate = ({ originalText, setValue }: DuckTranslateProps) => {
  const { pages } = useConfigContext();
  const supportsDuck = hasAllEnvVarsForViaDuck();

  const isViaDuckEnabled = pages[VIADUCK_PAGE_CONFIG_KEY] && supportsDuck;

  if (pages[VIADUCK_PAGE_CONFIG_KEY] && !supportsDuck) {
    console.warn(
      "DuckTranslate could not run because of missing environment variables"
    );
  }

  const [originalLanguage, setOriginalLanguage] = useState("");
  const [translatedText, setTranslatedText] = useState("");
  const [showingOriginal, setShowingOriginal] = useState(false);

  const [menuAnchorElement, setMenuAnchorElement] =
    useState<null | HTMLElement>(null);
  const open = Boolean(menuAnchorElement);

  const handleOpenMenu = (event: MouseEvent<HTMLButtonElement>) => {
    setMenuAnchorElement(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorElement(null);
  };

  const showOriginal = () => {
    setShowingOriginal(true);
    setMenuAnchorElement(null);
  };

  const showTranslated = () => {
    setShowingOriginal(false);
    setMenuAnchorElement(null);
  };

  useEffect(() => {
    const translate = async () => {
      setOriginalLanguage("");
      setTranslatedText("");
      setShowingOriginal(false);

      const { language } = await invokeDetermineLanguageAgent(originalText);

      const nonLatinLanguage = language === "Latin" ? "English" : language;

      console.log(nonLatinLanguage);

      setOriginalLanguage(nonLatinLanguage);

      if (![TARGET_LANGUAGE, "Unknown"].includes(nonLatinLanguage)) {
        const { translatedText: translatedValue } = await invokeTranslateAgent(
          originalText,
          TARGET_LANGUAGE
        );

        setValue(translatedValue);
        setTranslatedText(translatedValue);

        if (window.heap) {
          const windowHeap = window.heap as unknown as {
            track?: (event: string, data: any) => void;
          };
          if (windowHeap.track) {
            windowHeap.track("Duck Translated", { originalLanguage });
          }
        }
      }
    };

    if (
      originalText &&
      !showingOriginal &&
      !translatedText &&
      isViaDuckEnabled
    ) {
      void translate();
    }
    // I only want this effect to run when the originalText changes.
    // I don't want it to run if showingOriginal or any of the other things
    // in the function change.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originalText, isViaDuckEnabled]);

  useEffect(() => {
    setValue(showingOriginal ? originalText : translatedText);
    // I only want this effect to run when showingOriginal changes.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showingOriginal]);

  if (!translatedText || !isViaDuckEnabled) {
    return null;
  }

  return (
    <DuckErrorBoundary quiet={true}>
      <Button
        data-testid="duck-translate"
        variant="text"
        color="secondary"
        size="small"
        onClick={handleOpenMenu}
        sx={{ minWidth: "0rem", padding: "0rem" }}
      >
        <IoGlobeOutline size="16px" className="text-gray-400" />
      </Button>
      <Menu anchorEl={menuAnchorElement} open={open} onClose={handleCloseMenu}>
        <DuckTranslateMenuOption
          text={`Show ${TARGET_LANGUAGE} translation for this field`}
          selected={!showingOriginal}
          handleClick={showTranslated}
        />
        <DuckTranslateMenuOption
          text={`Show original ${originalLanguage === "Other" ? "" : originalLanguage}`}
          selected={showingOriginal}
          handleClick={showOriginal}
        />
      </Menu>
    </DuckErrorBoundary>
  );
};

export default DuckTranslate;
