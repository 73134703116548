import { useEffect, useState } from "react";
import classNames from "classnames";

import { DATE_WITH_TIME_FORMAT, SHORT_DATE_FORMAT } from "shared/constants";
import { formatDate, getAttributeLabel } from "shared/utils";

import { OPERATOR_TO_WORDS } from "features/ui/Filters/constants";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getTopLevelRowFromFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import { extractTextBetweenSquareBrackets } from "features/ui/Filters/FilterTypes/ExistsFilter/utils";
import OccursFilterLabel from "features/ui/Filters/FilterTypes/OccursFilter/OccursFilterLabel";
import { transformValues } from "features/ui/Filters/transformation-utils";
import {
  FilterDiffData,
  FilterOperator,
  FilterOverviewFormat,
  FilterValue,
} from "features/ui/Filters/types";
import {
  DATE_UNIT_OPTIONS,
  isDateOrDateTimeField,
  isDateTimeField,
  isValueInLastXFormat,
} from "features/ui/Filters/utils";
import Label from "features/ui/Label";
import Modal from "features/ui/Modal";
import { SchemaEntry } from "features/ui/Table";
import ValuesSearchList from "features/ui/ValuesSearchList";

import { SUPPORTED_DESCRIPTION_TABLE_FIELD_NAMES } from "./FilterDescriptionTable";
import FLabel from "./FLabel";

interface FilterLabelProps {
  filters: FilterGroupState;
  fieldName: string;
  keyNameMap: { [key: string]: string };
  showValuesIfMultiple?: boolean;
  format?: FilterOverviewFormat;
  noModalForMultipleValues?: boolean;
  baseEntityText?: string;
  tableSchema?: SchemaEntry[];
  dense?: boolean;
  occursBeforeNow?: boolean;
  diff?: FilterDiffData;
}

/**
 * FilterLabel component consist of:
 * - transforming filter values: transforming IDs to names (static transformations & transformations using API).
 * - formatting filter values: returning appropriate UI component to show filter value (badge, label, etc.)
 */
const FilterLabel = ({
  filters,
  fieldName,
  keyNameMap,
  showValuesIfMultiple = false,
  format = "badge",
  noModalForMultipleValues = false,
  baseEntityText,
  occursBeforeNow,
  tableSchema,
  dense,
  diff,
}: FilterLabelProps) => {
  const childRow = getTopLevelRowFromFilterGroupState(fieldName, filters);

  const [values, setValues] = useState<string[]>(childRow?.values || []);

  const showDescriptionsTable =
    SUPPORTED_DESCRIPTION_TABLE_FIELD_NAMES.includes(fieldName);

  useEffect(() => {
    transformValues(filters, fieldName, tableSchema).then((values: string[]) =>
      setValues(values)
    );
  }, [fieldName, filters, tableSchema]);

  if (!childRow) return null;

  const numOfValues = values.length;

  const applyValueDiffStyle =
    diff?.changes[diff.level]?.[diff.index]?.values ?? false;
  let defaultLabel: FilterValue = (
    <span className={applyValueDiffStyle ? diff?.bgColor : undefined}>
      {values.join(",")}
    </span>
  );

  // Show a modal with description table if there is only one value
  if (showDescriptionsTable && values.length === 1) {
    defaultLabel = (
      <Modal
        triggerElement={
          <Label
            text={values.join(", ")}
            className={classNames(
              "text-blue-400 inline-block cursor-pointer pl-0! mb-0! text-xs!"
            )}
          />
        }
        children={[
          <ValuesSearchList
            attribute={fieldName}
            title={getAttributeLabel(fieldName)}
            values={values}
            key="values-modal"
            showDescriptionsTable={showDescriptionsTable}
          />,
        ]}
      />
    );
  }

  const { operator } = childRow;

  let fieldNameFormatted =
    keyNameMap[fieldName] || getAttributeLabel(fieldName);

  const modalTitle = `${fieldNameFormatted} ${OPERATOR_TO_WORDS[operator]}`;

  if (
    !showValuesIfMultiple &&
    numOfValues > 1 &&
    operator !== FilterOperator.BETWEEN
  ) {
    defaultLabel = (noModalForMultipleValues && (
      <span className={applyValueDiffStyle ? diff?.bgColor : undefined}>
        {numOfValues} values
      </span>
    )) || (
      <Modal
        triggerElement={`${numOfValues} values`}
        triggerElementClassNames={classNames(
          "text-blue-500 hover:underline",
          applyValueDiffStyle ? diff?.bgColor : undefined
        )}
        children={[
          <ValuesSearchList
            attribute={fieldName}
            title={modalTitle}
            values={values}
            key="values-modal"
            showDescriptionsTable={showDescriptionsTable}
          />,
        ]}
      />
    );
  }

  // Joins array values with a space in between so the word-break css property works as it should
  if (
    showValuesIfMultiple &&
    numOfValues > 1 &&
    operator === FilterOperator.IN
  ) {
    defaultLabel = values.join(", ");
  }

  const schemaEntry = tableSchema?.find(
    (entry) => entry.accessor === fieldName
  );

  if (numOfValues === 2 && operator === FilterOperator.BETWEEN) {
    // format dates if possible
    if (isDateOrDateTimeField(schemaEntry?.dataType)) {
      let valDate1;
      let valDate2;
      if (isDateTimeField(schemaEntry?.filter?.filterDataType)) {
        valDate1 = formatDate(values[0], DATE_WITH_TIME_FORMAT, false);
        valDate2 = formatDate(values[1], DATE_WITH_TIME_FORMAT, false);
      } else {
        valDate1 = formatDate(values[0], SHORT_DATE_FORMAT, true);
        valDate2 = formatDate(values[1], SHORT_DATE_FORMAT, true);
      }

      defaultLabel = `${valDate1 || "any"} - ${valDate2 || "any"}`;
    } else {
      defaultLabel = `${values[0] || "any"} - ${values[1] || "any"}`;
    }

    defaultLabel = (
      <span className={applyValueDiffStyle ? diff?.bgColor : undefined}>
        {defaultLabel}
      </span>
    );
  } else if (
    operator === FilterOperator.IN_LAST &&
    numOfValues === 1 &&
    isValueInLastXFormat(values[0])
  ) {
    const value = values[0].slice(0, -1);
    const periodCharacter = values[0].slice(-1);

    const period = DATE_UNIT_OPTIONS.find(
      ({ id }) => id === periodCharacter
    )?.value;

    defaultLabel = `${value} ${period}`;
    defaultLabel = (
      <span className={applyValueDiffStyle ? diff?.bgColor : undefined}>
        {defaultLabel}
      </span>
    );
  } else if (isDateTimeField(schemaEntry?.filter?.filterDataType)) {
    defaultLabel = formatDate(values[0], DATE_WITH_TIME_FORMAT);
    defaultLabel = (
      <span className={applyValueDiffStyle ? diff?.bgColor : undefined}>
        {defaultLabel}
      </span>
    );
  }

  if ([FilterOperator.OCCURS, FilterOperator.NOT_OCCURS].includes(operator)) {
    if (["label-block", "label", "label-inline"].includes(format)) {
      defaultLabel = (
        <OccursFilterLabel
          operator={operator}
          value={values[0]}
          format={format}
          baseEntityText={baseEntityText}
          occursBeforeNow={occursBeforeNow}
          tableSchema={tableSchema}
          diff={diff}
        />
      );
    } else {
      defaultLabel = "";
    }
  }

  if (
    [FilterOperator.EXISTS, FilterOperator.NOT_EXISTS].includes(operator) &&
    numOfValues === 1
  ) {
    if (["label-block", "label", "label-inline"].includes(format)) {
      const innerFilter = extractTextBetweenSquareBrackets(values[0]);
      defaultLabel = <FilterQueryPresentation filter={innerFilter} />;
    } else {
      defaultLabel = "";
    }
  }

  return (
    <FLabel
      fieldName={fieldNameFormatted}
      operator={operator}
      fieldValue={defaultLabel}
      format={format}
      dense={dense}
      dataType={schemaEntry?.filter?.filterDataType}
      diff={diff}
    />
  );
};

export default FilterLabel;
