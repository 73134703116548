import { AlertEventType } from "shared/api/alertDefinitions/api";
import { useConfigContext } from "shared/contexts/ConfigContext";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";

import WatchlistCreateAlertAction from "pages/LandingPage/Watchlist/WatchlistCreateAlertAction";
import ReturnToEntityLinks from "pages/shared/analytics/returnToEntity/ReturnToEntityLinks";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { getChartSettingsKey, getFiltersKey } from "features/ui/Filters/utils";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

import BackToSingleClaimBreadCrumb from "./BackToSingleClaimBreadCrumb";
import ClaimAnalyticsFilters from "./ClaimAnalyticsFilters";
import ClaimAnalyticsTabs from "./ClaimAnalyticsTabs";
import {
  CLAIMS_PAGE_KEY,
  CLAIMS_TAB_KEY,
  PAGE_TITLE,
  VEHICLES_PAGE_KEY,
} from "./constants";

const QUERY_PARAMS_TO_PERSIST_THROUGH_TABS = [
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
].reduce(
  (soFar, key) => [...soFar, getFiltersKey(key), getChartSettingsKey(key)],
  [] as string[]
);

const ClaimAnalytics = () => {
  const { pages } = useConfigContext();

  const defaultClaimFilters =
    filterBuilderQueryToFilterBuilderState(
      pages.claimAnalytics?.defaultFilters
    ) || DEFAULT_FILTER_BUILDER_STATE;

  const { attributeAccessors } = useClaimsSchema();

  const claimsFiltersFilterSortState = useFilterSortState({
    pageKey: CLAIMS_PAGE_KEY,
    defaultFilterValues: defaultClaimFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(CLAIMS_PAGE_KEY),
    defaultTab: CLAIMS_TAB_KEY,
    schemaAttributes: attributeAccessors,
  });

  const defaultVehicleFilters =
    filterBuilderQueryToFilterBuilderState(
      pages.claimAnalytics?.defaultVehicleFilters
    ) || DEFAULT_FILTER_BUILDER_STATE;

  const { attributeAccessors: vehicleAttributeAccessors } = useVehiclesSchema();

  const vehiclePopulationFiltersFilterSortState = useFilterSortState({
    pageKey: VEHICLES_PAGE_KEY,
    defaultFilterValues: defaultVehicleFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(VEHICLES_PAGE_KEY),
    defaultTab: CLAIMS_TAB_KEY,
    schemaAttributes: vehicleAttributeAccessors,
  });

  const resetState = () => {
    claimsFiltersFilterSortState?.resetFilterSortState();
    vehiclePopulationFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <PageHeaderWrapper title={PAGE_TITLE}>
        <div className="flex flex-row ml-3 space-x-2">
          <ReturnToEntityLinks
            page="claimAnalytics"
            vehicleFilters={vehiclePopulationFiltersFilterSortState.filters}
            claimFilters={claimsFiltersFilterSortState.filters}
          />
          <BackToSingleClaimBreadCrumb />
        </div>
        <PageHeaderActionsWrapper>
          <WatchlistCreateAlertAction
            eventType={AlertEventType.CLAIM}
            eventFilter={claimsFiltersFilterSortState.filters}
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <ClaimAnalyticsFilters
        claimsFilterSortState={claimsFiltersFilterSortState}
        vehiclesFilterSortState={vehiclePopulationFiltersFilterSortState}
        defaultClaimFilters={defaultClaimFilters}
        defaultVehicleFilters={defaultVehicleFilters}
      />
      <ClaimAnalyticsTabs
        claimsFiltersFilterSortState={claimsFiltersFilterSortState}
        vehiclesFiltersFilterSortState={vehiclePopulationFiltersFilterSortState}
        queryParamsToPersist={QUERY_PARAMS_TO_PERSIST_THROUGH_TABS}
        onBadRequest={resetState}
      />
    </>
  );
};

export default ClaimAnalytics;
