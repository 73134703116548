import { useState } from "react";

import { EventTypeEnum } from "shared/types";

import { ClaimAnalyticsProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";
import { PAGE_TITLE } from "pages/ClaimAnalytics/constants";
import { TOP_CONTRIBUTORS_TAB_KEY } from "pages/constants";
import {
  getDefaultByGroupBySelectOption,
  useGroupBy,
  useTopContributorsChartSettings,
} from "pages/hooks";
import { sortGroupByOptions } from "pages/shared/analytics/utils";
import TopContributorsGroupBySelector from "pages/shared/TopContributorsGroupBySelector";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";

import {
  CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
  CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
  DEFAULT_GROUP_BY_ATTRIBUTE,
} from "./constants";
import TopChart from "./TopChart";
import TopContributorsTable from "./TopContributorsTable";

const TopContributors = ({
  claimsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
}: ClaimAnalyticsProps) => {
  const {
    actions,
    selectedOptions,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
    handleSelectedOptionsChange,
  } = useTopContributorsChartSettings(
    claimsFiltersFilterSortState,
    CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
    EventTypeEnum.CLAIM
  );

  const {
    groupBySelectOptions,
    selectedGroupByAttribute,
    handleGroupByAttributeChange,
  } = useGroupBy(
    EventTypeEnum.CLAIM,
    claimsFiltersFilterSortState,
    DEFAULT_GROUP_BY_ATTRIBUTE,
    CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
    TOP_CONTRIBUTORS_TAB_KEY
  );

  const claimsFilters = claimsFiltersFilterSortState?.filtersWithQuickFilters;
  const vehiclesFilters =
    vehiclesFiltersFilterSortState?.filtersWithQuickFilters;

  const [generalFilters, setGeneralFilters] = useState<
    FilterGroupState | undefined
  >();

  const sortedGroupBySelectOptions = sortGroupByOptions(groupBySelectOptions);

  const resetState = () => {
    handleGroupByAttributeChange(
      getDefaultByGroupBySelectOption(
        sortedGroupBySelectOptions,
        DEFAULT_GROUP_BY_ATTRIBUTE
      )
    );
    claimsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <TopContributorsGroupBySelector
        groupBySelectOptions={sortedGroupBySelectOptions}
        selectedGroupByAttribute={selectedGroupByAttribute}
        setSelectedGroupByAttribute={handleGroupByAttributeChange}
        enableDrill={true}
        filterQueryString={getFiltersQuery(
          claimsFiltersFilterSortState?.filtersWithQuickFilters
        )}
        vehiclesFilterQueryString={getFiltersQuery(
          vehiclesFiltersFilterSortState?.filtersWithQuickFilters
        )}
        pageTitle={PAGE_TITLE}
      />
      <TopChart
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        claimsFilters={claimsFilters}
        filters={generalFilters}
        onClaimsFiltersChange={claimsFiltersFilterSortState?.manageFilterChange}
        onVehiclesFiltersChange={
          vehiclesFiltersFilterSortState?.manageFilterChange
        }
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleSelectedOptionsChange}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
        onBadRequest={resetState}
      />
      <TopContributorsTable
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        claimsFilters={claimsFilters}
        onFiltersUpdated={setGeneralFilters}
        onBadRequest={resetState}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
      />
    </>
  );
};

export default TopContributors;
