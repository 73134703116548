import { Vehicle } from "shared/api/vehicles/api";

import { useVehicleTransportCategoryDisplayName } from "pages/VINView/hooks";

import InfoCardsData from "./InfoCardsData";
import VehicleImage from "./VehicleImage";

interface Props {
  vin: string;
  vehicle?: Vehicle;
}

const VehicleInfo = ({ vin, vehicle }: Props) => {
  const inferredTransportCategoryName =
    useVehicleTransportCategoryDisplayName(vin);

  // we add async data from our hook. Data label/key defined in infoCardsDataMap
  const dataModified = vehicle
    ? { ...vehicle, inferredTransportCategoryName }
    : undefined;

  return (
    <div className="md:flex md:space-x-16">
      <div className="items-start min-w-[24rem] max-w-md">
        {vehicle && <VehicleImage vehicle={vehicle} />}
      </div>

      <div className="flex-1 min-w-0">
        <div className="flex flex-col space-y-4">
          <InfoCardsData data={dataModified} />
        </div>
      </div>
    </div>
  );
};

export default VehicleInfo;
