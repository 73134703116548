import { PermissionEntry } from "shared/api/api";

import { ServicePlanRuleState } from "./types";

export const SERVICE_PLANS_TITLE = "Service Plans";
export const SERVICE_PLAN_TITLE = "Service Plan";
export const CTA_TEXT = "New Service Plan";
export const PLANS_PER_PAGE = 15;
export const PLAN_NAME_LABEL = "Plan Name";
export const PLAN_DESCRIPTION_LABEL = "Plan Description";
export const PLAN_FAMILY_LABEL = "Plan Family";
export const PLAN_RULES_LABEL = "Service Plan logic";
export const ON_SUCCESS_CREATE_TEXT =
  "Successfully created a new service plan.";
export const SP_FORM_UPDATED_SUCCESSFULLY_MESSAGE =
  "Your service plan has been updated successfully.";
export const COLLECTION_LABEL = "Eligible Vehicles";

export const EVERYONE_DEFAULT_ACCESS: PermissionEntry = {
  ID: "",
  everyone: true,
  access: "read",
};

export const DEFAULT_RULE_STATE: ServicePlanRuleState = {
  recommendation: "",
  filter: undefined,
};

export const ICON_SIZE = 15;
