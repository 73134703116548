import Skeleton from "react-loading-skeleton";

import { useGetIssueMetricsRegistry } from "shared/api/metrics/hooks";
import useIssuesSchema from "shared/schemas/issuesSchema";

import APIError from "features/ui/APIError";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

import {
  DEFAULT_ISSUES_SORT,
  ISSUES_PAGE_KEY,
  ISSUES_PENDING_FILTERS_LS_KEY,
  ISSUES_TITLE,
} from "./constants";
import CreateIssue from "./CreateIssue/CreateIssue";
import IssuesFilters from "./IssuesFilters";
import IssuesPageWithMetrics from "./IssuesPageWithMetrics";
import { useDefaultIssueFilter } from "./utils";

const IssuesPage = () => {
  const { attributeAccessors } = useIssuesSchema();

  const filterSortState = useFilterSortState({
    pageKey: ISSUES_PAGE_KEY,
    defaultFilterValues: useDefaultIssueFilter(),
    defaultSort: DEFAULT_ISSUES_SORT,
    pendingFiltersLocalStorageKey: ISSUES_PENDING_FILTERS_LS_KEY,
    schemaAttributes: attributeAccessors,
  });

  const {
    data: issueMetrics,
    isLoading: metricsLoading,
    error,
  } = useGetIssueMetricsRegistry();

  return (
    <>
      <PageHeaderWrapper title={ISSUES_TITLE}>
        <PageHeaderActionsWrapper>
          <CreateIssue action="create" />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <IssuesFilters filterSortState={filterSortState} />
      {metricsLoading && <Skeleton height={300} />}
      {error && <APIError error={error} />}
      {!metricsLoading && !error && issueMetrics && (
        <IssuesPageWithMetrics
          issueMetrics={issueMetrics}
          filterSortState={filterSortState}
        />
      )}
    </>
  );
};

export default IssuesPage;
