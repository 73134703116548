import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { VehicleAgeTimeline } from "shared/api/api";
import { formatNumber } from "shared/utils";

import { ByVehicleAgeData } from "pages/types";
import { formatDateBasedOnGranularity } from "pages/utils";

import { SelectOption } from "features/ui/Select";

import { NONE_EXPOSURE_ID } from "./analytics/constants";

interface ChartTooltipProps {
  yAxisKey: keyof VehicleAgeTimeline;
  yAxisLabel: string;
  yAxisOptions: SelectOption[];
  activeLabel?: number | string;
}

type ByVehicleAgeChartTooltipProps = TooltipProps<ValueType, NameType> &
  ChartTooltipProps;

const ByVehicleAgeChartTooltip = ({
  yAxisKey,
  yAxisLabel,
  yAxisOptions,
  active,
  payload,
  label,
  activeLabel,
}: ByVehicleAgeChartTooltipProps) => {
  if (!active || !activeLabel) return null;

  const data = payload?.[0]?.payload;
  if (!data) return null;

  const {
    granularity,
    byVehicleAgeBirthdayValue,
    byVehicleAgeExposure,
    byVehicleAgeExposureValue,
  } = data.byVehicleAgeData as ByVehicleAgeData;

  const activeData =
    byVehicleAgeExposure !== NONE_EXPOSURE_ID
      ? (data[activeLabel] as VehicleAgeTimeline)
      : (data[0] as VehicleAgeTimeline);

  if (!activeData) return null;

  const date = formatDateBasedOnGranularity(label, granularity);

  return (
    <div className="flex flex-col bg-white shadow-sm px-2 py-3 text-sm">
      <div>
        {byVehicleAgeBirthdayValue}: {date}
      </div>
      {byVehicleAgeExposure !== NONE_EXPOSURE_ID && (
        <div>
          {byVehicleAgeExposureValue}: {activeLabel}
        </div>
      )}
      <div>
        {yAxisLabel}: {formatNumber(activeData[yAxisKey] as number)}
      </div>
      <br />
      {yAxisOptions
        .filter(({ value }) => value !== yAxisLabel)
        .map(({ id, value }) => (
          <div key={id}>
            {value}:{" "}
            {formatNumber(activeData[id as keyof VehicleAgeTimeline] as number)}
          </div>
        ))}
    </div>
  );
};

export default ByVehicleAgeChartTooltip;
