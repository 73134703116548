import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { VEHICLE_ACCESSOR } from "shared/schemas/constants";

import NestedAutocomplete from "features/ui/NestedAutocomplete";
import { getSelectedOptionFromId } from "features/ui/NestedAutocomplete/utils";
import { SelectOption } from "features/ui/Select";

interface ClaimAttributesSingleSelectProps {
  selectedAttribute: string | null;
  onAttributesChange: (attributeIds: string | null) => void;
}

const ClaimAttributesSingleSelect = ({
  selectedAttribute,
  onAttributesChange,
}: ClaimAttributesSingleSelectProps) => {
  const { groupBySelectOptions: groupBySelectOptionsFromSchema } =
    useClaimsSchema([VEHICLE_ACCESSOR]);

  const groupBySelectOptions: SelectOption[] =
    groupBySelectOptionsFromSchema || [];

  const selectedOption = getSelectedOptionFromId(
    selectedAttribute,
    groupBySelectOptions
  );

  const handleAttributeSelect = (option: SelectOption | null) => {
    onAttributesChange(option ? (option.id as string) : null);
  };

  return (
    <NestedAutocomplete<false>
      wrapperClasses="max-w-[300px]"
      options={groupBySelectOptions}
      onSelectionChange={handleAttributeSelect}
      selected={selectedOption}
      testId="default-breakdown-dropdown"
      label="Default Breakdown Attribute"
      fullWidth
      disableClearable={false}
    />
  );
};

export default ClaimAttributesSingleSelect;
