import { useEffect, useRef, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useLocation } from "react-router-dom";

import "@stoplight/elements/web-components.min.js";

import { useFlags } from "launchdarkly-react-client-sdk";
import { useOktaAuth } from "@okta/okta-react";

import { getOpenAPISpec } from "./api";
import CopyAuthButton from "./CopyAuthButton";

const LOADER_PAGE_HEIGHT = 1250;
const LOADERS_SIDEBAR_COUNT = 1;
const LOADERS_BLOCK_COUNT = 5;
const LOADERS_BLOCK_HEIGHT = LOADER_PAGE_HEIGHT / LOADERS_BLOCK_COUNT;

const LOGO_URL = import.meta.env.BASE_URL + "logo192.png";

declare module "react" {
  namespace JSX {
    interface IntrinsicElements {
      "elements-api": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & {
        apiDescriptionDocument?: any;
        router?: "hash" | "memory" | "history";
        hideInternal?: boolean;
        hideSchemas?: boolean;
        logo?: string;
      };
    }
  }
}

const DeveloperPortal = () => {
  const [spec, setSpec] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const { hideInternalApiEndpoints } = useFlags();
  const { hash } = useLocation();

  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken();
  const timerRef = useRef<null | ReturnType<typeof setTimeout>>(null);

  /**
   * Ugly hack but gets the job done.
   */
  const setTokenInElementsHTML = (token: string) => {
    timerRef.current = setTimeout(() => {
      const el = document.querySelector(
        '[id^="id_auth_Token"]'
      ) as HTMLInputElement;

      if (!el) return;

      el.value = token;
      el.dispatchEvent(new Event("input", { bubbles: true }));
    }, 1000);
  };

  useEffect(() => {
    if (!hash || !accessToken) return;

    setTokenInElementsHTML(accessToken as string);
  }, [hash, accessToken]);

  useEffect(() => {
    timerRef.current && clearTimeout(timerRef.current);
    timerRef.current = null;

    setIsLoading(true);
    getOpenAPISpec()
      .then((data) => {
        setSpec(data);
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
        setTokenInElementsHTML(accessToken as string);
      });
  }, [accessToken]);

  return (
    <div>
      <div className="w-full px-4 flex items-center justify-end">
        <CopyAuthButton />
      </div>
      {!isLoading && (
        <elements-api
          apiDescriptionDocument={spec}
          router="hash"
          hideInternal={hideInternalApiEndpoints}
          hideSchemas
          logo={LOGO_URL}
        />
      )}
      {isLoading && (
        <div className="flex">
          <div className="w-1/5 px-2">
            <Skeleton
              count={LOADERS_SIDEBAR_COUNT}
              height={LOADER_PAGE_HEIGHT}
            />
          </div>
          <div className="flex flex-col flex-1">
            <Skeleton
              count={LOADERS_BLOCK_COUNT}
              height={LOADERS_BLOCK_HEIGHT}
              className="my-2"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default DeveloperPortal;
