import { PageState } from "duck/graph/types";
import { BaseMessage } from "@langchain/core/messages";
import { Annotation, messagesStateReducer } from "@langchain/langgraph/web";

import { Claim } from "shared/api/claims/api";

/**
 * @summary Define the type of the state graph for the agent.
 */
export type GraphStateType = typeof graphState.State;

/**
 * @summary Define the state graph for the agent.
 * This is expected to be the same for most if not all top-level agents.
 */
export const graphState = Annotation.Root({
  userInput: Annotation<string>(),
  messages: Annotation<BaseMessage[]>({
    reducer: messagesStateReducer,
    default: () => [],
  }),
  pageState: Annotation<PageState>(),
  previousRunId: Annotation<string>(),
  activeAgent: Annotation<string>(),
  claimsAgent: Annotation<ClaimsAgentState>(),
});

interface SummarizeClaimsInputAttributes {
  externalID?: Claim["externalID"];
  date?: Claim["date"];
  mileage?: Claim["mileage"];
  mileageUnit?: Claim["mileageUnit"];
  costTotal?: Claim["costTotal"];
  parts?: Claim["parts"];
  notesTechnician?: Claim["notesTechnician"];
  notesCustomer?: Claim["notesCustomer"];
  notes?: Claim["notes"];
  group?: Claim["group"];
  subgroup?: Claim["subgroup"];
  laborCodeInformation?: Claim["laborCodeInformation"];
  failedPartInformation?: Claim["failedPartInformation"];
}

interface ClaimsAgentState {
  claimsList?: SummarizeClaimsInputAttributes[];
  claimsCount?: number;
  groups?: {};
}
