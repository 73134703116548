import { Stack } from "@mui/material";

import BetaBadge from "features/ui/BetaBadge";

import DuckLogo from "./DuckLogo";

const DuckSummary = () => (
  <Stack alignItems="center" direction="row" spacing={1}>
    <DuckLogo h={17} w={17} />
    <Stack>
      <div className="text-gray-600">ViaDuck Assistant</div>
      <BetaBadge newLine={false} dark={true} />
    </Stack>
  </Stack>
);

export default DuckSummary;
