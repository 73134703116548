import DuckDrillButton from "duck/ui/drill/DuckDrillButton";

import NestedAutocomplete from "features/ui/NestedAutocomplete";
import { SelectOption } from "features/ui/Select";

interface GroupBySelectorProps {
  groupBySelectOptions: SelectOption[];
  selectedGroupByAttribute: SelectOption;
  setSelectedGroupByAttribute: (option: SelectOption) => void;
  enableDrill?: boolean;
  filterQueryString?: string;
  vehiclesFilterQueryString?: string;
  pageTitle?: string;
}

const TopContributorsGroupBySelector = ({
  groupBySelectOptions,
  selectedGroupByAttribute,
  setSelectedGroupByAttribute,
  enableDrill,
  filterQueryString,
  vehiclesFilterQueryString,
  pageTitle,
}: GroupBySelectorProps) => (
  <div className="mt-4">
    {groupBySelectOptions.length > 0 && (
      <div className="mb-5">
        <div className="text-sm mb-1">
          Select a dimension to update the x-axis of the chart and first column
          of the table:
        </div>
        {selectedGroupByAttribute && (
          <div className="flex space-x-4">
            <NestedAutocomplete
              fullWidth={true}
              wrapperClasses="max-w-[300px]"
              options={groupBySelectOptions}
              onSelectionChange={setSelectedGroupByAttribute}
              selected={selectedGroupByAttribute}
              testId="top-contributors-group-by-dropdown"
            />
            {enableDrill && (
              <DuckDrillButton
                groupBySelectOptions={groupBySelectOptions}
                filterQueryString={filterQueryString}
                vehiclesFilterQueryString={vehiclesFilterQueryString}
                pageTitle={pageTitle || ""}
              />
            )}
          </div>
        )}
      </div>
    )}
  </div>
);

export default TopContributorsGroupBySelector;
