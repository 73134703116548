import { FiChevronLeft as ChevronLeft } from "react-icons/fi";
import { generatePath, Link } from "react-router-dom";

import { useQuery } from "services/hooks";
import { routes } from "services/routes";

import { CLAIM_ID_QUERY_KEY, CLAIM_LABEL_QUERY_KEY } from "./constants";

const BackToSingleClaimBreadCrumb = () => {
  const query = useQuery();

  if (!query[CLAIM_ID_QUERY_KEY]) return null;

  const claimId = query[CLAIM_ID_QUERY_KEY].toString();
  const claimExternalId = query[CLAIM_LABEL_QUERY_KEY]?.toString();
  const claimText = claimExternalId ? `Claim ${claimExternalId}` : "Claim";

  const path = generatePath(routes.claimAnalyticsDetails, { id: claimId });

  return (
    <Link
      to={path}
      className="flex items-center hover:text-blue-400 text-blue-500 text-xs"
    >
      <ChevronLeft size="12" />
      <span className="ml-2">Back to {claimText}</span>
    </Link>
  );
};

export default BackToSingleClaimBreadCrumb;
