import Skeleton from "react-loading-skeleton";
import { generatePath, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { Issue, updateIssue } from "shared/api/issues/api";
import { useOrderedValues } from "shared/api/orderedValues/hooks";
import { transformOrderedValuesToSelectOptions } from "shared/api/orderedValues/utils";
import { SHORT_DATE_FORMAT } from "shared/constants";
import useIssuesSchema from "shared/schemas/issuesSchema";
import { formatDate } from "shared/utils";

import GroupDropdown from "pages/Issues/SuggestedIssues/Details/GroupDropdown";

import APIError from "features/ui/APIError";
import Card from "features/ui/Card";
import Label from "features/ui/Label";
import RichTextEditor from "features/ui/RichTextEditor/RichTextEditor";
import Select from "features/ui/Select";
import TextAreaInlineEdit from "features/ui/TextAreaInlineEdit/TextAreaInlineEdit";

import { routes } from "services/routes";

import { CreateIssueProps } from "./CardActions";
import CardHeader from "./CardHeader";

interface Props {
  issue: Issue;
  createIssueProps: CreateIssueProps;
  issueRequestKey: string;
}

const CardOverview = ({ issue, createIssueProps, issueRequestKey }: Props) => {
  const { getDisplayLabel } = useIssuesSchema();

  const {
    ID,
    assignee,
    assignedGroupID,
    description,
    statusObj,
    severityObj,
    notes,
    canEdit,
    externalID,
    createdAt,
    createdBy,
    promotedFromID,
  } = issue;

  const suggestedIssueLink = promotedFromID ? (
    <Link
      to={generatePath(routes.suggestedIssueLatestRun, { id: promotedFromID })}
      className="text-metabase-blue hover:underline"
    >
      suggested issue
    </Link>
  ) : (
    ""
  );

  const originPrefix = promotedFromID ? (
    <span>
      {getDisplayLabel("promotedFrom", "Promoted From")} {suggestedIssueLink}
    </span>
  ) : (
    "Manually created"
  );
  const origin = (
    <span className="text-viaduct-black">
      {originPrefix} by <u>{createdBy}</u> on{" "}
      <u>{formatDate(createdAt, SHORT_DATE_FORMAT)}</u>.
    </span>
  );

  const {
    data: issueStatuses,
    isLoading: issueStatusesLoading,
    error: issueStatusesError,
  } = useOrderedValues({ valueType: "issueStatus" });

  if (issueStatusesError) {
    return <APIError error={issueStatusesError} />;
  }

  const issueStatusOptions =
    transformOrderedValuesToSelectOptions(issueStatuses);

  const onUpdateIssue = (
    field: string,
    value: string | null,
    fieldNiceName?: string
  ) =>
    updateIssue({ ID, [field]: value })
      .then(() => {
        toast.success(`Issue ${fieldNiceName || field} updated successfully`);
        mutate(issueRequestKey);
      })
      .catch((err: Error) => {
        toast.error(`Updating issue ${fieldNiceName || field} failed`);
        console.log(err);
      });

  return (
    <Card classNames="p-5! max-w-[50vw]" testId="issue-overview-card">
      <CardHeader
        title="Overview"
        canEdit={canEdit}
        createIssueProps={createIssueProps}
      />
      <div className="flex flex-row justify-between mb-3">
        <div className="relative">
          <Label text={getDisplayLabel("severityObj", "Severity")} />
          <Label text={severityObj?.value} className="text-viaduct-black" />
        </div>
        <div className="relative ml-2">
          <Label text={getDisplayLabel("assignee", "Assignee")} />
          <Label text={assignee} className="text-viaduct-black" />
        </div>
        <div className="relative ml-2">
          <Label text={getDisplayLabel("externalID", "External ID")} />
          <Label text={externalID} className="text-viaduct-black" />
        </div>
      </div>
      <div className="flex gap-x-3 mb-3">
        {issueStatusesLoading && <Skeleton height={30} />}
        <Select
          disabled={!canEdit}
          label={getDisplayLabel("statusObj", "Status")}
          options={issueStatusOptions}
          fullWidth
          selected={
            issueStatusOptions.find((x) => x.id === statusObj?.ID) ||
            issueStatusOptions[0]
          }
          onSelect={(option) => {
            if (option.id === statusObj?.ID) return;

            onUpdateIssue("statusID", option.id as string, "status");
          }}
          testId="issue-status"
        />
        <GroupDropdown
          assignedGroupID={assignedGroupID || null}
          onUpdate={onUpdateIssue}
          label={getDisplayLabel("assignedGroupID", "Responsible Group")}
          disabled={!canEdit}
          testId="issue-assignedGroupID"
        />
      </div>
      <div className="flex flex-row justify-between mb-3">
        <div className="relative">
          <Label text="Origin" />
          <Label text={origin} />
        </div>
      </div>
      <div className="flex mb-3">
        <div className="relative w-full">
          <Label text={getDisplayLabel("description", "Description")} />
          <RichTextEditor
            content={description || ""}
            editing={false}
            testId="issue_description"
            onChange={() => {}}
            contentClass="max-h-60"
          />
        </div>
      </div>
      <TextAreaInlineEdit
        fieldName={getDisplayLabel("notes", "Notes")}
        canEdit={canEdit}
        fieldValue={notes || ""}
        onSubmit={(value) => onUpdateIssue("notes", value)}
        testId="issue_notes"
      />
    </Card>
  );
};

export default CardOverview;
