import OktaAuth from "@okta/okta-auth-js";

import { useConfigContext } from "shared/contexts/ConfigContext";

import Button from "features/ui/Button";
import Card from "features/ui/Card";

interface Props {
  oktaAuth: OktaAuth;
}

const AccessForbidden = ({ oktaAuth }: Props) => {
  const { general } = useConfigContext();
  const supportEmail = general?.helpContactEmail;

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <Card classNames="text-center">
        <h1 className="text-2xl font-bold text-red-600 mb-4">
          Access Forbidden
        </h1>
        <div className="text-gray-600 mb-5">
          You don't have access to this.
          <br /> If you think this is a mistake, please contact{" "}
          <a href={`mailto:${supportEmail}`}>{supportEmail}</a>.
        </div>
        <Button
          variant="contained"
          size="small"
          onClick={() => oktaAuth.signOut()}
        >
          Sign Out
        </Button>
      </Card>
    </div>
  );
};
export default AccessForbidden;
