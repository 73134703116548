import { useEffect, useRef, useState } from "react";
import classNames from "classnames";

import {
  FILTER_INDENT_MARGIN_PX,
  FILTER_ROW_OPACITY_PERCENTAGE_INCREASE,
} from "features/ui/Filters/FilterBuilder/constants";
import { FilterRowState } from "features/ui/Filters/FilterBuilder/types";
import { FilterOperator } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

import AttributeSelect from "./AttributeSelect";
import DefaultFilterFields from "./DefaultFilterFields";
import RowActions from "./RowActions";
import { OnRowDataChangeHandle } from "./types";

interface Props extends FilterRowState {
  onNewRow: (id: string) => void;
  onNewGroup: (id: string) => void;
  onDelete?: (id: string) => void;
  onRowDataChange: OnRowDataChangeHandle;
  schema: SchemaEntry<string>[];
  disabled?: boolean;
  attributePlaceholder?: string;
  embedded?: boolean;
  depth: number;
  isClearedStateRow: boolean;
  hasChanges?: boolean;
  hasInitialFocus?: boolean;
  occursFilterSelector?: boolean;
  onMoveToQuickFilters?: (id: string) => void;
  quickFilterAttributes?: string[];
}

const FilterRow = ({
  id,
  attribute,
  operator,
  values,
  depth,
  onNewRow,
  onNewGroup,
  onDelete,
  onRowDataChange,
  schema,
  type,
  disabled,
  attributePlaceholder,
  embedded,
  isClearedStateRow,
  hasChanges,
  hasInitialFocus,
  occursFilterSelector,
  onMoveToQuickFilters,
  quickFilterAttributes,
}: Props) => {
  const [hasInputFocus, setHasInputFocus] = useState(false);

  const timeoutRef = useRef<NodeJS.Timeout>(undefined);

  const handleFocus = () => {
    // Clear any pending blur timeout
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setHasInputFocus(true);
  };

  const handleBlur = (e: React.FocusEvent) => {
    // Only set a timeout to blur if we're not focusing another element within the same row
    if (!e.currentTarget.contains(e.relatedTarget as Node)) {
      timeoutRef.current = setTimeout(() => {
        setHasInputFocus(false);
      }, 50); // Small delay to allow for the next focus to occur
    }
  };

  // Cleanup timeout on unmount
  useEffect(
    () => () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    },
    []
  );

  const currentAttributeSchema: SchemaEntry<string> | undefined = attribute
    ? schema.find((s) => s.filter?.fieldName === attribute)
    : undefined;

  // If operator is "exists" or "occurs" we need to add a background color to the row to make both operator and sub-filters stand out
  const isEmbeddedOperator =
    operator &&
    [
      FilterOperator.EXISTS,
      FilterOperator.OCCURS,
      FilterOperator.NOT_EXISTS,
      FilterOperator.NOT_OCCURS,
    ].includes(operator);

  const filterIndent = FILTER_INDENT_MARGIN_PX - (isEmbeddedOperator ? 16 : 0);
  const rowOpacity = isEmbeddedOperator
    ? (depth + 1) * FILTER_ROW_OPACITY_PERCENTAGE_INCREASE
    : 0;

  return (
    <div
      key={id}
      id={id}
      data-testid="filter-row"
      className={classNames("flex items-start gap-2 mt-2 group/row flex-wrap", {
        "pl-4 pr-1 pt-1 mb-2": isEmbeddedOperator,
      })}
      style={{
        marginLeft: filterIndent,
        background: `rgba(0,0,0, ${rowOpacity}%)`,
      }}
      onFocusCapture={handleFocus}
      onBlurCapture={handleBlur}
    >
      {/* Filter attribute */}
      <AttributeSelect
        selected={attribute}
        onChange={(selected) => onRowDataChange(id, "attribute", selected)}
        schema={schema}
        disabled={disabled}
        attributePlaceholder={attributePlaceholder}
        openOnAutoFocus={!isClearedStateRow || occursFilterSelector}
        hasInitialFocus={hasInitialFocus}
      />

      <DefaultFilterFields
        id={id}
        attribute={attribute}
        attributeSchema={currentAttributeSchema}
        type={type}
        operator={operator}
        values={values}
        onRowDataChange={onRowDataChange}
        disabled={disabled}
        hasChanges={hasChanges}
        hasInitialFocus={hasInitialFocus}
        occursFilterSelector={occursFilterSelector}
      />
      {/* Filter actions */}
      <RowActions
        id={id}
        onNewRow={onNewRow}
        onNewGroup={onNewGroup}
        onDelete={onDelete}
        disabled={disabled}
        hideAddGroupCta={embedded}
        hasInputFocus={hasInputFocus}
        onMoveToQuickFilters={
          attribute &&
          !quickFilterAttributes?.includes(attribute) &&
          schema.find((s) => s.accessor === attribute)?.filter?.filterType !==
            "exists" &&
          schema.find((s) => s.accessor === attribute)?.filter?.filterType !==
            "occurs" &&
          onMoveToQuickFilters
            ? onMoveToQuickFilters
            : undefined
        }
      />
    </div>
  );
};

export default FilterRow;
