import { useState } from "react";
import { BiPencil as EditIcon } from "react-icons/bi";
import { IconButton } from "@mui/material";

import Button from "features/ui/Button";
import Label from "features/ui/Label";
import RichTextEditor from "features/ui/RichTextEditor/RichTextEditor";

interface Props {
  fieldName: string;
  fieldValue: string;
  canEdit: boolean;
  onSubmit: (value: string) => void;
  numRows?: number;
  testId?: string;
}

const TextAreaInlineEdit = ({
  fieldName,
  fieldValue,
  canEdit,
  onSubmit,
  testId = "text-area",
}: Props) => {
  const [editMode, setEditMode] = useState(false);
  const [draftValue, setDraftValue] = useState<string | null>(null);

  // current value is either draftValue (when editing) or fieldValue
  const value = editMode && draftValue !== null ? draftValue : fieldValue;

  const onSave = () => {
    setEditMode(false);
    setDraftValue(null);
    onSubmit(value);
  };

  const onCancel = () => {
    setEditMode(false);
    setDraftValue(null);
  };

  const handleEdit = () => {
    setDraftValue(fieldValue);
    setEditMode(true);
  };

  return (
    <>
      <div className="flex justify-between items-center mb-1">
        <Label text={fieldName} />
        <div className="flex-justify-end">
          {canEdit && !editMode && (
            <IconButton
              size="small"
              onClick={handleEdit}
              data-testid="text-area-edit-mode-cta"
            >
              <EditIcon />
            </IconButton>
          )}
          {canEdit && editMode && (
            <div className="inline-flex space-x-1">
              <Button
                color="secondary"
                label="Cancel"
                size="small"
                onClick={onCancel}
                testId="text-area-cancel-cta"
              />
              <Button
                color="primary"
                label="Save"
                size="small"
                onClick={onSave}
                disabled={fieldValue === value}
                testId="text-area-save-cta"
              />
            </div>
          )}
        </div>
      </div>
      <div
        className={`mb-3 ${!editMode ? "border border-gray-200 rounded-md" : ""}`}
      >
        <RichTextEditor
          content={fieldValue}
          onChange={(value) => setDraftValue(value)}
          editing={editMode}
          testId={testId}
          contentClass="max-h-60"
        />
      </div>
    </>
  );
};

export default TextAreaInlineEdit;
