import { useFlags } from "launchdarkly-react-client-sdk";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";

import { useConfigContext } from "shared/contexts/ConfigContext";

import SecureComponent from "features/auth/Login/SecureComponent";

import { redirects, routes } from "services/routes";
import {
  RouteConfig,
  RouteDataProps,
  routesConfig,
} from "services/routesConfig";

import RouteMiddleware from "./services/routes/RouteMiddleware";

const DEFAULT_ROUTE_VISIBILITY = true;

const RouteComponent = ({
  config,
  data,
}: {
  config: RouteConfig;
  data: RouteDataProps;
}) => {
  // RouteConfig has exactly one of component or componentFunction defined
  if (config.insecure) {
    return config.componentFunction
      ? config.componentFunction(data)
      : config.component;
  }

  return (
    <SecureComponent routeConfig={config}>
      <RouteMiddleware config={config}>
        {config.componentFunction
          ? config.componentFunction(data)
          : config.component}
      </RouteMiddleware>
    </SecureComponent>
  );
};

const AppRoutes = () => {
  const { general, pages, userInfo } = useConfigContext();

  const flags = useFlags();

  const routeProps: RouteDataProps = {
    flags,
    pages,
    general: {
      ...general,
      rootPage: general.rootPage ? general.rootPage : routes.vehicles,
    },
    userInfo,
  };

  const displayedRoutes = Object.entries(routesConfig).filter(([, config]) =>
    config.shouldDisplay
      ? config.shouldDisplay(routeProps)
      : DEFAULT_ROUTE_VISIBILITY
  );

  const routerRedirects = Object.entries(redirects).map(([from, to]) => ({
    path: from,
    element: <Navigate to={to} />,
  }));

  const displayedRoutesRouter = displayedRoutes.map(([, config]) => ({
    path: config.path,
    element: <RouteComponent config={config} data={routeProps} />,
  }));

  const wildcardRoutes = [
    {
      path: "*",
      element: (
        <RouteComponent config={routesConfig.notFound} data={routeProps} />
      ),
    },
  ];

  const routerConfig = [
    ...routerRedirects,
    ...displayedRoutesRouter,
    ...wildcardRoutes,
  ];

  const router = createBrowserRouter(routerConfig, {
    // Temporary until migrating fully to v7 router
    // https://reactrouter.com/upgrading/v6#v7_relativesplatpath
    future: {
      v7_relativeSplatPath: true,
      v7_fetcherPersist: true,
      v7_normalizeFormMethod: true,
      v7_partialHydration: true,
    },
  });

  // Lifted routes to data router
  return (
    <RouterProvider
      router={router}
      // https://github.com/remix-run/react-router/issues/12974
      // future={{
      //   v7_startTransition: true,
      // }}
    />
  );
};

export default AppRoutes;
