import { useAPI } from "shared/api/hooks";

import { PagesConfig } from "pages/SuperAdmin/PagesConfig/types";

import * as api from "./api";

export const useAdminAttributesConfigList = () =>
  useAPI<null, api.AdminAttributeConfig[]>(
    api.listAdminAttributesConfigRequestURI,
    null
  );

export const useAdminPagesConfig = (args: api.AdminPagesConfigRequest) =>
  useAPI<typeof args, PagesConfig>(api.getAdminPagesConfigRequestURI, args);

export const useGeneralConfig = (args: api.GeneralConfigRequest) =>
  useAPI<typeof args, api.GeneralConfigModel>(
    api.getGeneralConfigRequestURI,
    args
  );

export const useOrderedValuesConfigList = () =>
  useAPI<null, string[]>(api.listAdminOrderedValuesConfigRequestURI, null);

export const useAdminVehicleImages = () =>
  useAPI<null, api.VehicleImage[]>(api.listAdminVehicleImagesRequestURI, null);
