import { PermissionEntry, UpdatePermissionRequest } from "shared/api/api";
import { PermissionID } from "shared/types";

import { SelectOption } from "features/ui/Select";

interface PermissionRating {
  id: PermissionID;
  rating: number;
}

const PERMISSION_RATINGS: PermissionRating[] = [
  {
    id: "none",
    rating: 0,
  },
  {
    id: "read",
    rating: 1,
  },
  {
    id: "edit",
    rating: 2,
  },
];

export const PERMISSION_TYPES: SelectOption[] = [
  {
    id: "none",
    value: "Has no access",
  },
  {
    id: "read",
    value: "Can read",
  },
  {
    id: "edit",
    value: "Can edit",
  },
];

export const GENERAL_PERMISSION_TYPES: SelectOption[] = [
  {
    id: "none",
    value: "Restricted",
    label: "Only users with individual permissions have access",
  },
  {
    id: "read",
    value: "Anyone can read",
    label: "All users have read access",
  },
  {
    id: "edit",
    value: "Anyone can edit",
    label: "All users have edit access",
  },
];

export const GROUP_MEMBER_PERMISSION_TYPES: SelectOption[] = [
  {
    id: "none",
    value: "Restricted",
    label: "Only specified members can view or edit",
  },
  {
    id: "read",
    value: "Anyone can read",
    label: "All members can view, only specified can edit",
  },
  {
    id: "edit",
    value: "Anyone can edit",
    label: "All members can view and edit",
  },
];

const DEFAULT_GENERAL_ACCESS: PermissionID = "none";
export const DEFAULT_MEMBER_ACCESS: PermissionID = "read";

export const DEFAULT_GENERAL_PERMISSION: PermissionEntry = {
  ID: "",
  everyone: true,
  access: DEFAULT_GENERAL_ACCESS,
  originalAccess: DEFAULT_GENERAL_ACCESS,
  shown: true,
};

export const newUserPermission = (
  email: string,
  access: PermissionID
): PermissionEntry => ({
  ID: "",
  email: email.toLowerCase(),
  access,
  originalAccess: access,
  everyone: false,
  shown: true,
});

export const newGroupPermission = (
  groupID: string,
  access: PermissionID
): PermissionEntry => ({
  ID: "",
  groupID,
  access,
  originalAccess: access,
  everyone: false,
  shown: true,
});

export const formatGeneralPermission = (
  permList: PermissionEntry[]
): PermissionEntry =>
  permList.find((x) => x.everyone) || {
    ...DEFAULT_GENERAL_PERMISSION,
  };

export const formatUserPermissions = (
  permList: PermissionEntry[],
  generalAccess: PermissionID,
  myEmail: string,
  canEdit: boolean
): PermissionEntry[] => {
  const userPermissions = permList.filter((p) => !p.everyone && !p.groupID);

  // User permissions in a table are ordered in the following way
  // - Row for entering new permission
  // - My permission
  // - Other people permissions ordered by email
  const orderedPermissions: PermissionEntry[] = [];

  // region New permission

  // Hide add new permission row if general permission is already on highest level
  if (canEdit && !isHighestPermission(generalAccess)) {
    const higherPermissions = getHigherPermissionsList(generalAccess);
    if (higherPermissions) {
      orderedPermissions.push(
        userPermissions.find((x) => !x.ID) ||
          newUserPermission("", higherPermissions[0].id as PermissionID)
      );
    }
  }

  // endregion

  // region My permission
  const myPermission = userPermissions.find(
    (x) => x.ID && x.email?.toLowerCase() === myEmail.toLowerCase()
  );
  if (myPermission) {
    // My permission is shown only if it has more access that general permission
    myPermission.shown = isHigherPermission(myPermission.access, generalAccess);
    orderedPermissions.push(myPermission);
  }

  // endregion

  // region Other users permissions

  const otherPermissions = userPermissions.filter(
    (x) => x.email?.toLowerCase() !== myEmail.toLowerCase() && x.ID
  );

  // Show only permissions that have higher permission that general permission
  const shownPermissions = otherPermissions.filter((x) =>
    isHigherPermission(x.access, generalAccess)
  );
  const hiddenPermissions = otherPermissions.filter(
    (x) => !shownPermissions.includes(x)
  );

  shownPermissions.forEach((x) => (x.shown = true));
  hiddenPermissions.forEach((x) => (x.shown = false));

  const otherPermissionsFormatted = [...shownPermissions, ...hiddenPermissions];
  otherPermissionsFormatted.sort((a, b) =>
    String(a.email?.toLowerCase()) < String(b.email?.toLowerCase()) ? -1 : 1
  );

  // endregion

  return [...orderedPermissions, ...otherPermissionsFormatted];
};

export const formatGroupPermissions = (
  permList: PermissionEntry[],
  generalAccess: PermissionID,
  canEdit: boolean
): PermissionEntry[] => {
  const groupPermissions = permList.filter((p) => !p.everyone && p.groupID);

  // Group permissions in a table are ordered in the following way
  // - Row for entering new permission
  // - Group permissions ordered by access
  const orderedPermissions: PermissionEntry[] = [];

  // region New Permission

  // Hide add new permission row if general permission is already on highest level
  if (canEdit && !isHighestPermission(generalAccess)) {
    const higherPermissions = getHigherPermissionsList(generalAccess);
    if (higherPermissions) {
      orderedPermissions.push(
        groupPermissions.find((x) => !x.ID) ||
          newGroupPermission("", higherPermissions[0].id as PermissionID)
      );
    }
  }

  // endregion

  // region Other group permissions

  const definedGroupPermissions = groupPermissions.filter((x) => x.ID);

  // Show only permissions that have higher permission that general permission
  const shownPermissions = definedGroupPermissions.filter((x) =>
    isHigherPermission(x.access, generalAccess)
  );
  const hiddenPermissions = definedGroupPermissions.filter(
    (x) => !shownPermissions.includes(x)
  );

  shownPermissions.forEach((x) => (x.shown = true));
  hiddenPermissions.forEach((x) => (x.shown = false));

  const definedGroupPermissionsFormatted = [
    ...shownPermissions,
    ...hiddenPermissions,
  ];
  definedGroupPermissionsFormatted.sort((a, b) =>
    String(a.access) < String(b.access) ? -1 : 1
  );

  // endregion
  return [...orderedPermissions, ...definedGroupPermissionsFormatted];
};

export const formatPermissions = (
  permList: PermissionEntry[],
  myEmail: string,
  canEdit: boolean
): PermissionEntry[] => {
  const generalPermission = formatGeneralPermission(permList);
  const userPermissions = formatUserPermissions(
    permList,
    generalPermission.access,
    myEmail,
    canEdit
  );
  const groupPermissions = formatGroupPermissions(
    permList,
    generalPermission.access,
    canEdit
  );

  return [generalPermission, ...userPermissions, ...groupPermissions];
};

export const isHighestPermission = (permission: string) =>
  permission === "edit";

export const isHigherPermission = (
  permission1: PermissionID,
  permission2: PermissionID
): boolean => {
  const rating1 =
    PERMISSION_RATINGS.find((x) => x.id === permission1)?.rating || 0;
  const rating2 =
    PERMISSION_RATINGS.find((x) => x.id === permission2)?.rating || 0;

  return rating1 > rating2;
};

export const getHigherPermission = (
  permission1: PermissionID,
  permission2: PermissionID
): PermissionID =>
  isHigherPermission(permission1, permission2) ? permission1 : permission2;

export const getHigherPermissionsList = (
  permission: PermissionID
): SelectOption[] => {
  const permissionRating =
    PERMISSION_RATINGS.find((x) => x.id === permission)?.rating || 0;

  const higherPermissions = PERMISSION_RATINGS.filter(
    (x) => x.rating > permissionRating
  ).map((x) => x.id);

  return PERMISSION_TYPES.filter((x) =>
    higherPermissions.includes(x.id as PermissionID)
  );
};

// modify permission format received from the API to accommodate for the UI
export const modifyPermissionEntry = (
  permissions: PermissionEntry[]
): PermissionEntry[] =>
  permissions.map((x) => ({
    ...x,
    originalAccess: x.access,
    shown: true,
    everyone: x.everyone || false,
  }));

export const isValidEmail = (email: string | undefined) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email || "");

export const isEmptyDiff = (
  oldPermissions: PermissionEntry[],
  newPermissions: PermissionEntry[],
  myEmail: string
): boolean => {
  const diff = calculatePermissionDiff(oldPermissions, newPermissions, myEmail);

  return (
    diff.create.length === 0 &&
    diff.update.length === 0 &&
    diff.remove.length === 0
  );
};

export const calculatePermissionDiff = (
  oldPermissions: PermissionEntry[],
  newPermissions: PermissionEntry[],
  myEmail: string
): UpdatePermissionRequest => {
  // Function returns two arrays:
  // - 'create' array: new permission entries
  // - 'update' array: updated permission entries
  // - 'remove' array: permissions that should be removed

  const permissionsToCreate = [];
  const permissionsToUpdate = [];
  const permissionsToRemove = [];

  // region Handle General Permission

  const generalPerm = newPermissions.find((x) => x.everyone);
  // general permission not explicitly defined
  if (!generalPerm?.ID) {
    if (generalPerm?.access !== "none") {
      permissionsToCreate.push({
        everyone: true,
        access: generalPerm?.access as PermissionID,
      });
    }
  } else if (generalPerm?.access === "none") {
    permissionsToRemove.push(generalPerm?.ID);
  } else if (generalPerm?.access !== generalPerm?.originalAccess) {
    permissionsToUpdate.push({
      ID: generalPerm?.ID as string,
      access: generalPerm?.access as PermissionID,
    });
  }

  //endregion

  // region Handle User/Group Permissions

  // permissions defined upon page load
  const allOldIds = oldPermissions
    .filter(
      (x) =>
        x.ID && !x.everyone && x.shown && (isValidEmail(x.email) || x.groupID)
    )
    .map((x) => x.ID);
  // permissions currently defined
  const allNewIds = newPermissions
    .filter(
      (x) =>
        x.ID && !x.everyone && x.shown && (isValidEmail(x.email) || x.groupID)
    )
    .map((x) => x.ID);
  const commonIds = allNewIds.filter((x) => allOldIds.includes(x));
  const onlyNewIds = allNewIds.filter((x) => !allOldIds.includes(x));

  // remove all actually removed permissions
  const removedPermissions = allOldIds.filter((x) => !allNewIds.includes(x));
  permissionsToRemove.push(...removedPermissions);
  // remove also all permissions that are 'lower' than general's
  const permissionsLowerThanGeneral = newPermissions
    .filter(
      (x) =>
        commonIds.includes(x.ID) &&
        !isHigherPermission(x.access, generalPerm?.access || "none") &&
        x.email?.toLowerCase() !== myEmail.toLowerCase()
    )
    .map((x) => x.ID);
  permissionsToRemove.push(...permissionsLowerThanGeneral);

  const addedPermissions = newPermissions
    .filter(
      (x) =>
        onlyNewIds.includes(x.ID) &&
        isHigherPermission(x.access, generalPerm?.access || "none")
    )
    .map(({ email, groupID, access }) => ({
      email: email?.toLowerCase(),
      groupID,
      access,
    }));
  permissionsToCreate.push(...addedPermissions);

  const updatedPermissionIds = commonIds.filter(
    (x) => !permissionsLowerThanGeneral.includes(x)
  );
  const updatedPermissions = newPermissions
    .filter(
      (x) =>
        updatedPermissionIds.includes(x.ID) && x.access !== x.originalAccess
    )
    .map(({ ID, access }) => ({ ID, access }));
  permissionsToUpdate.push(...updatedPermissions);

  // endregion

  return {
    create: permissionsToCreate,
    update: permissionsToUpdate,
    remove: permissionsToRemove,
  };
};

export const calculatePermissionToSubmit = (
  permissions: PermissionEntry[],
  skipUsers: string[]
): Partial<PermissionEntry>[] => {
  const permsToCreate = [];

  // region Handle General Permission

  const generalPerm = permissions.find((x) => x.everyone);
  // general permission not explicitly defined
  if (!generalPerm?.ID) {
    if (generalPerm?.access !== "none") {
      permsToCreate.push({
        everyone: true,
        access: generalPerm?.access as PermissionID,
      });
    }
  }

  const addedUserPerms = permissions
    .filter(
      (x) =>
        x.ID &&
        isHigherPermission(x.access, generalPerm?.access || "none") &&
        isValidEmail(x.email) &&
        !skipUsers.some((y) => y.toLowerCase() === x.email?.toLowerCase())
    )
    .map(({ email, access }) => ({ email: email?.toLowerCase(), access }));
  permsToCreate.push(...addedUserPerms);

  const addedGroupPerms = permissions
    .filter(
      (x) =>
        x.ID &&
        isHigherPermission(x.access, generalPerm?.access || "none") &&
        x.groupID
    )
    .map(({ groupID, access }) => ({ groupID, access }));
  permsToCreate.push(...addedGroupPerms);

  return permsToCreate;
};

export const addUserPermission = (
  userPermissions: PermissionEntry[]
): PermissionEntry[] => {
  const newUserPermissionEntry = userPermissions.find((x) => !x.ID && x.email);
  if (!newUserPermissionEntry) {
    return userPermissions;
  }

  const userAlreadyHasPermission = userPermissions.find(
    (x) =>
      x.ID &&
      x.email?.toLowerCase() === newUserPermissionEntry.email?.toLowerCase()
  );
  if (userAlreadyHasPermission) {
    // just update access for the user
    return userPermissions
      .map((obj) =>
        obj.ID &&
        obj.email?.toLowerCase() === newUserPermissionEntry.email?.toLowerCase()
          ? { ...obj, access: newUserPermissionEntry.access }
          : obj
      )
      .filter((obj) => obj.ID);
  }

  // add permission entry for new user
  return userPermissions.map((obj) =>
    !obj.ID ? { ...obj, ID: obj.email?.toLowerCase() as string } : obj
  );
};
