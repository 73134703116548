export const ENABLED_KEY = "enabled";

export const VEHICLES_PAGE_CONFIG_KEY = "vehicles";
export type VehiclesConfig = {
  eventTimeline?: boolean;
  // Ideally we don't manually enter eventTimelineFromDateVehicleAttribute but have a dropdown when configuring.
  // Default from date in event timeline
  // Priority 1: eventTimelineFromDateVehicleAttribute
  // Priority 2: eventTimelineFromDate
  eventTimelineFromDateVehicleAttribute?: string;
  eventTimelineFromDate?: string;
  eventTimelineToDate?: string;
  eventTimelineDefaultSignalEventFilters?: string;
  maintenanceSchedules?: boolean;
  tireDiagram?: boolean;
};

export const SERVICE_RECORDS_PAGE_CONFIG_KEY = "serviceRecords";
export type ServiceRecordsConfig = {
  hideMenuItem?: boolean;
  serviceRecordText?: string;
  staticFilters?: string;
};

export const FAILURE_MODES_PAGE_CONFIG_KEY = "failureModes";
export type FailureModesConfig = {
  similarVehicles?: boolean;
  survivalCurves?: boolean;
  nominalRiskModelPerformance?: boolean;
  riskDistribution?: boolean;
  showHideColumns?: boolean;
};

export const ISSUES_PAGE_CONFIG_KEY = "issues";
export type IssuesConfig = {
  suggestedIssues?: boolean;
  topXChartsOptions?: string[];
  defaultBreakdownAttribute?: string;
  defaultFilters?: string;
  suggestedIssuesDefaultFilters?: string;
  issueActivityLog?: boolean;
};

export const CLAIM_ANALYTICS_PAGE_CONFIG_KEY = "claimAnalytics";
export type ClaimAnalyticsConfig = {
  defaultFilters?: string;
  defaultVehicleFilters?: string;
};

export const SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY = "signalEventsAnalytics";
export type SignalEventsAnalyticsConfig = {
  defaultFilters?: string;
  defaultVehicleFilters?: string;
  defaultClaimGroupByAttribute?: string;
};

export const REPAIR_ANALYTICS_PAGE_CONFIG_KEY = "repairAnalytics";
export type RepairAnalyticsConfig = {
  defaultFilters?: string;
  defaultVehicleFilters?: string;
};

export const INSPECTION_ANALYTICS_PAGE_CONFIG_KEY = "inspectionAnalytics";
export type InspectionAnalyticsConfig = {
  defaultFilters?: string;
  defaultVehicleFilters?: string;
};

export const CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY = "customSignalEvents";
export type CustomSignalEventsConfig = {
  eventPreviewFromDate?: string;
  eventPreviewToDate?: string;
};

export const HELP_ARTICLES_PAGE_CONFIG_KEY = "helpArticles";
type HelpArticle = {
  url: string;
  title: string;
};

export const RBAC_PAGE_CONFIG_KEY = "rbac";
export type RBACConfig = {
  rbacGroups?: boolean;
};

export type HelpArticlesConfig = {
  articles: HelpArticle[];
};

export const FLEETS_PAGE_CONFIG_KEY = "fleets";
export const SERVICE_PLANS_PAGE_CONFIG_KEY = "servicePlans";
export const ALERT_DEFINITIONS_PAGE_CONFIG_KEY = "alertDefinitions";
export const COLLECTIONS_PAGE_CONFIG_KEY = "collections";
export const CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY = "calculatedAttributes";
export const DATA_EXPLORER_PAGE_CONFIG_KEY = "dataExplorer";
export const LANDING_PAGE_PAGE_CONFIG_KEY = "landingPage";
export const RELEASE_NOTES_PAGE_CONFIG_KEY = "releaseNotes";
export const FEEBACK_FORM_PAGE_CONFIG_KEY = "feedbackForm";
export const VIADUCK_PAGE_CONFIG_KEY = "viaDuck";

export type KnownPageConfigKeys =
  | typeof VEHICLES_PAGE_CONFIG_KEY
  | typeof SERVICE_RECORDS_PAGE_CONFIG_KEY
  | typeof FAILURE_MODES_PAGE_CONFIG_KEY
  | typeof ISSUES_PAGE_CONFIG_KEY
  | typeof CLAIM_ANALYTICS_PAGE_CONFIG_KEY
  | typeof SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY
  | typeof CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY
  | typeof FLEETS_PAGE_CONFIG_KEY
  | typeof SERVICE_PLANS_PAGE_CONFIG_KEY
  | typeof REPAIR_ANALYTICS_PAGE_CONFIG_KEY
  | typeof INSPECTION_ANALYTICS_PAGE_CONFIG_KEY
  | typeof COLLECTIONS_PAGE_CONFIG_KEY
  | typeof CALCULATED_ATTRIBUTES_PAGE_CONFIG_KEY
  | typeof ALERT_DEFINITIONS_PAGE_CONFIG_KEY
  | typeof HELP_ARTICLES_PAGE_CONFIG_KEY
  | typeof DATA_EXPLORER_PAGE_CONFIG_KEY
  | typeof LANDING_PAGE_PAGE_CONFIG_KEY
  | typeof RELEASE_NOTES_PAGE_CONFIG_KEY
  | typeof FEEBACK_FORM_PAGE_CONFIG_KEY
  | typeof VIADUCK_PAGE_CONFIG_KEY
  | typeof RBAC_PAGE_CONFIG_KEY;

export const DEFAULT_CONFIG = {};

// Here we can define page configs that have other settings besides just "enabled/disabled" flag
type PageConfigMap = {
  [VEHICLES_PAGE_CONFIG_KEY]: VehiclesConfig;
  [SERVICE_RECORDS_PAGE_CONFIG_KEY]: ServiceRecordsConfig;
  [FAILURE_MODES_PAGE_CONFIG_KEY]: FailureModesConfig;
  [ISSUES_PAGE_CONFIG_KEY]: IssuesConfig;
  [CLAIM_ANALYTICS_PAGE_CONFIG_KEY]: ClaimAnalyticsConfig;
  [SIGNAL_EVENTS_ANALYTICS_PAGE_CONFIG_KEY]: SignalEventsAnalyticsConfig;
  [REPAIR_ANALYTICS_PAGE_CONFIG_KEY]: RepairAnalyticsConfig;
  [INSPECTION_ANALYTICS_PAGE_CONFIG_KEY]: InspectionAnalyticsConfig;
  [CUSTOM_SIGNAL_EVENTS_PAGE_CONFIG_KEY]: CustomSignalEventsConfig;
  [HELP_ARTICLES_PAGE_CONFIG_KEY]: HelpArticlesConfig;
  [RBAC_PAGE_CONFIG_KEY]: RBACConfig;
};

type PageConfigItem =
  | {
      [K in keyof PageConfigMap]: { ID: K; config: PageConfigMap[K] };
    }[keyof PageConfigMap]
  | { ID: Exclude<string, KnownPageConfigKeys>; config: typeof DEFAULT_CONFIG };

export type PagesConfig = PageConfigItem[];

// We reformat the config to be a map for easier access across the app
export type PagesConfigModel = {
  [K in keyof PageConfigMap]?: PageConfigMap[K];
} & Record<string, typeof DEFAULT_CONFIG | undefined>;

export type PageConfigValues = boolean | string | null | string[] | object[];

export interface PageConfigProps<T = typeof DEFAULT_CONFIG | undefined> {
  pageId: KnownPageConfigKeys;
  onConfigChange: (
    pageID: KnownPageConfigKeys,
    configKey: string,
    configValue: PageConfigValues
  ) => void;
  config: T;
}
