import { routes } from "instanceManagement/routes";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";

import { ListInstanceManagementTenantsRequest } from "shared/api/instanceManagement/api";
import { useInstanceManagementTenantList } from "shared/api/instanceManagement/hooks";
import { getSortFilter } from "shared/api/utils";
import NewCTA from "shared/components/NewCTA";
import {
  CREATED_AT_FILTER,
  TENANTS_GENERIC_FILTER,
} from "shared/filterDefinitions";
import { SortBy } from "shared/types";

import APIError from "features/ui/APIError";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import Table, { OnSortParams, SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";

import { CTA_TEXT, PAGE_TITLE } from "./constants";

const schema: SchemaEntry[] = [
  {
    label: "Name",
    accessor: "name",
    dataType: DataType.JSX,
    sortable: true,
    filter: TENANTS_GENERIC_FILTER({
      label: "Name",
      fieldName: "name",
      search: true,
      loadDataOnOpen: true,
    }),
  },
  {
    label: "Created",
    accessor: "createdAt",
    dataType: DataType.DATE_WITH_TIME_UTC,
    sortable: true,
    filter: CREATED_AT_FILTER,
  },
  {
    label: "Created By",
    accessor: "createdBy",
    dataType: DataType.STRING,
    sortable: true,
    filter: TENANTS_GENERIC_FILTER({
      label: "Created By",
      fieldName: "createdBy",
      search: true,
      loadDataOnOpen: true,
    }),
  },
  {
    label: "Tenant Provisioning Done",
    accessor: "provisioningDone",
    dataType: DataType.BOOLEAN,
  },
  {
    label: "Okta Resources Created",
    accessor: "oktaResourcesCreated",
    dataType: DataType.BOOLEAN,
  },
  {
    label: "Domain Creation Finished",
    accessor: "domainCreationFinished",
    dataType: DataType.BOOLEAN,
  },
  {
    label: "Certificate Creation Finished",
    accessor: "certificateCreationFinished",
    dataType: DataType.BOOLEAN,
  },
  {
    label: "Clickhouse Role Created",
    accessor: "clickhouseRoleCreated",
    dataType: DataType.BOOLEAN,
  },
  {
    label: "Clickhouse User Created",
    accessor: "clickhouseUserCreated",
    dataType: DataType.BOOLEAN,
  },
  {
    label: "Metabase Resources Created",
    accessor: "metabaseResourcesCreated",
    dataType: DataType.BOOLEAN,
  },
];

const getTenantAppUrl = (tenant: string) =>
  `https://${tenant}.cloud.viaduct.ai`;

const PAGE_KEY = "tenants";
const DEFAULT_SORT: SortBy = { createdAt: "desc" };
const LIMIT = 2000;

const Tenants = () => {
  const {
    manageFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
    schemaAttributes: schema.map(({ accessor }) => accessor),
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const requestParams: ListInstanceManagementTenantsRequest = {
    filter: getFiltersQuery(filters),
    sort: getSortFilter(sort),
    limit: LIMIT,
  };

  const { data, isLoading, error } =
    useInstanceManagementTenantList(requestParams);

  const formattedData = data?.map(({ name, ...otherData }) => {
    const tenantUrl = getTenantAppUrl(name);

    return {
      ...otherData,
      name: (
        <Link
          to={tenantUrl}
          target="_blank"
          className="text-metabase-blue hover:underline"
        >
          {name}
        </Link>
      ),
    };
  });

  if (isLoading) {
    return <Skeleton height={300} />;
  }

  return (
    <>
      <PageHeaderWrapper title={PAGE_TITLE}>
        <PageHeaderActionsWrapper>
          <NewCTA
            to={routes.newTenant}
            label={CTA_TEXT}
            testId="new-tenant-cta"
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>

      <FiltersOverview
        filters={filters}
        tableSchema={schema}
        onFiltersReset={resetFilters}
      />

      {!error && (
        <Table
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          dense
          testId="super-admin-tenants-table"
          sortBy={sort}
          onSort={handleSorting}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          filtersInitialized={filtersInitialized}
          pageKey={PAGE_KEY}
        />
      )}
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
    </>
  );
};

export default Tenants;
