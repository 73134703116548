import { useState } from "react";
import classNames from "classnames";
import { GoPlus as PlusIcon } from "react-icons/go";
import { IconButton, Popover } from "@mui/material";

import { randomID } from "shared/utils";

import AttributeSelect from "features/ui/Filters/FilterBuilder/FilterRow/AttributeSelect";
import { FilterRowState } from "features/ui/Filters/FilterBuilder/types";
import { SchemaEntry } from "features/ui/Table";
import Tooltip from "features/ui/Tooltip";

const ICON_SIZE = 15;

const getEmptyFilterRow = (): FilterRowState => ({
  type: "row" as const,
  id: randomID(),
  attribute: null,
  operator: null,
  values: [],
});

interface Props {
  filterSchema: SchemaEntry[];
  onSubmit: (filter: FilterRowState) => void;
}

const CreateQuickFilterAction = ({ filterSchema, onSubmit }: Props) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  // isOpen is used only to change the color of the icon when form is open
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<FilterRowState>(getEmptyFilterRow());

  const handleOnChange = (val: string) => {
    const f = getEmptyFilterRow();
    f.attribute = val;
    onSubmit(f);
    handleClose();
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilter(getEmptyFilterRow());
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  const handleClose = () => {
    setFilter(getEmptyFilterRow());
    setAnchorEl(null);
    setIsOpen(false);
  };

  return (
    <div data-testid="create-quick-filter">
      <Tooltip title="Create Quick Filter">
        <IconButton
          onClick={handleClick}
          data-testid="create-bookmark-action"
          size="small"
          className={classNames({
            "!text-blue-500 !border !border-blue-500": isOpen,
          })}
        >
          <PlusIcon size={ICON_SIZE} />
        </IconButton>
      </Tooltip>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        disablePortal
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transitionDuration={0}
        disableRestoreFocus
      >
        <form
          onSubmit={(ev) => ev.preventDefault()}
          className="w-auto min-w-60 max-w-60 p-2 flex flex-col gap-2"
        >
          <AttributeSelect
            openOnAutoFocus={true}
            hasInitialFocus={true}
            schema={filterSchema}
            selected={filter.attribute}
            onChange={(val) => {
              handleOnChange(val);
            }}
          />
        </form>
      </Popover>
    </div>
  );
};

export default CreateQuickFilterAction;
