import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { useListSignalEventsOccurrences } from "shared/api/signalEvents/hooks";
import { APIFilter } from "shared/api/utils";
import { DATE_WITH_TIME_FORMAT, DEFAULT_MILEAGE_UNIT } from "shared/constants";
import { formatDate } from "shared/utils";

import APIError from "features/ui/APIError/APIError";
import Board from "features/ui/Board";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

import CardSignalEventOccurrenceDetails from "./CardSignalEventOccurrenceDetails";

type Params = {
  id: string;
  recordedAt: string;
};

const SingleSignalEventOccurrence = () => {
  const { id, recordedAt } = useParams<Params>();

  const staticFilter: APIFilter[] = [
    {
      name: "signalEventID",
      op: "eq",
      value: [String(id)],
    },
    {
      name: "recordedAt",
      op: "eq",
      value: recordedAt,
    },
  ];

  const { data, isLoading, error } = useListSignalEventsOccurrences({
    filter: getFiltersQuery(undefined, staticFilter),
    mileageUnit: DEFAULT_MILEAGE_UNIT,
  });

  const signalEventOccurrence = data?.[0];

  if (!id || !recordedAt) {
    return <div>No data</div>;
  }

  if (isLoading) {
    return <Skeleton count={2} height={300} className="mb-3" />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data || data.length === 0 || !signalEventOccurrence) {
    return (
      <div>
        Signal event occurrence with the given id / recorded at does not exist.
      </div>
    );
  }

  const boardElements: JSX.Element[][] = [
    [
      <CardSignalEventOccurrenceDetails
        data={signalEventOccurrence}
        key="seo-details"
      />,
    ],
  ];

  const title = `${signalEventOccurrence.signalEventID} at ${formatDate(recordedAt, DATE_WITH_TIME_FORMAT)}`;

  return (
    <>
      <PageHeaderWrapper title={title} />
      <Board elements={boardElements} />
    </>
  );
};

export default SingleSignalEventOccurrence;
