import { getPages } from "duck/graph/api";

import * as api from "shared/api/api";
import * as claimsApi from "shared/api/claims/api";
import {
  GeneralConfigModel,
  getGeneralConfigRequestURI,
} from "shared/api/superadmin/api";
import { CountRequestWithVehiclesFilter } from "shared/api/vehicles/api";

import { CLAIMS_PAGE_KEY } from "pages/ClaimAnalytics/constants";

import { getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";

export const getExploreInClaimAnalyticsUriTemplate = () => {
  const filtersKey = getFiltersKey(CLAIMS_PAGE_KEY);

  return `${routes.claimAnalytics}?${filtersKey}=externalID=in:"{{externalID_1}}"|{{externalID_2}}"|...|{{externalID_n}}"`;
};

export const listClaims = (args: claimsApi.ListClaimsRequest, limit?: number) =>
  getPages<claimsApi.Claim, claimsApi.ListClaimsRequest>(
    (args) =>
      api.getFetcher<claimsApi.Claim[]>(claimsApi.listClaimsRequestURI(args)),
    args,
    limit
  );

interface countResponse {
  count: number;
}
export const countClaims = (args: CountRequestWithVehiclesFilter) =>
  api.getFetcher<countResponse>(claimsApi.listClaimsCountRequestURI(args));

export const getGeneralConfig = () =>
  api.getFetcher<GeneralConfigModel>(getGeneralConfigRequestURI({}));
