import { useContext } from "react";

import { VehicleECUsAttributeContext } from "shared/contexts/VehicleECUsAttributesContextWrapper";
import { VEHICLES_ECUS_GENERIC_FILTER } from "shared/filterDefinitions";

import useGeneralDescriptionSchema from "./generalDescriptionSchema";
import { useGroupBySelectOptions } from "./hooks_groupBy";
import { SchemaRelations, UseSchema } from "./types";
import {
  getAttributeMap,
  getDisplayLabelFunction,
  getFilterFunction,
  getSchemaEntryFunction,
  getSchemaForAccessorFunction,
  getSchemaFromAttributes,
} from "./utils";

const useVehicleECUsSchema = (accessorsToHide: string[] = []): UseSchema => {
  const { attributes } = useContext(VehicleECUsAttributeContext);

  const attributeMap = getAttributeMap(attributes);

  const getDisplayLabel = getDisplayLabelFunction(attributeMap);

  const getFilter = getFilterFunction(
    VEHICLES_ECUS_GENERIC_FILTER,
    attributeMap
  );

  const generalDescriptionSchema = useGeneralDescriptionSchema(true);

  const relations: SchemaRelations = {
    "/v1/ECUs": generalDescriptionSchema,
  };

  const getSchemaEntry = getSchemaEntryFunction(getFilter, attributeMap);

  const schema = getSchemaFromAttributes(
    attributes,
    getSchemaEntry,
    undefined,
    relations,
    accessorsToHide
  );

  const getSchemaForAccessor = getSchemaForAccessorFunction(schema);

  const attributesForGroupBy = attributes?.filter(
    ({ ID }) => !accessorsToHide.includes(ID)
  );

  const groupBySelectOptions = useGroupBySelectOptions(attributesForGroupBy);

  return {
    schema,
    getDisplayLabel,
    attributeMap,
    groupBySelectOptions,
    attributes,
    getSchemaEntry,
    getSchemaForAccessor,
    attributeAccessors: Object.keys(attributeMap || {}),
  };
};
export default useVehicleECUsSchema;
