import { useFlags } from "launchdarkly-react-client-sdk";

import { EventTypeEnum } from "shared/types";

import { YAxisValTopContributors } from "pages/constants";

import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";

type YAxisOption = {
  id: YAxisValTopContributors;
  value: string;
  label: string;
};

const yAxisOptions: Record<YAxisValTopContributors, YAxisOption> = {
  DPTV: {
    id: "DPTV",
    value: "DPTV - Distinct VINs",
    label: "DPTV - Distinct VINs",
  },
  IPTV: {
    id: "IPTV",
    value: "DPTV",
    label: "DPTV",
  },
  CPV: {
    id: "CPV",
    value: "Cost per vehicle",
    label: "Cost per vehicle",
  },
  totalCost: {
    id: "totalCost",
    value: "Total Cost",
    label: "Total Cost",
  },
  numSignalEventOccurrences: {
    id: "numSignalEventOccurrences",
    value: "Number of signal events",
    label: "Number of signal events",
  },
  numEvents: {
    id: "numEvents",
    value: "Total Events",
    label: "Total number of Events",
  },
  numVehicles: {
    id: "numVehicles",
    value: "Affected Vehicles",
    label: "Number of affected vehicles",
  },
};

const getTopContributorsChartYAxisOptions = (
  optionIds: YAxisValTopContributors[]
): DropdownSelectOption<YAxisValTopContributors>[] =>
  optionIds.map((id) => yAxisOptions[id]);

export const useTopContributorsChartYAxisOptions = (
  eventType: EventTypeEnum
) => {
  const { warrantyClaimsCost } = useFlags();
  if (eventType === EventTypeEnum.SIGNAL_EVENT) {
    return getTopContributorsChartYAxisOptions([
      "DPTV",
      "IPTV",
      "numSignalEventOccurrences",
      "numVehicles",
    ]);
  }

  return [
    {
      id: "IPTV",
      value: "Incidents per thousand vehicles",
      label: "Incidents per thousand vehicles",
    },
    ...getTopContributorsChartYAxisOptions([
      "numEvents",
      "numVehicles",
      ...(eventType === EventTypeEnum.CLAIM && warrantyClaimsCost
        ? (["CPV", "totalCost"] as const)
        : []),
    ]),
  ];
};
