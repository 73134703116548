import { add, format } from "date-fns";
import Skeleton from "react-loading-skeleton";

import { Claim } from "shared/api/claims/api";
import { Vehicle } from "shared/api/vehicles/api";
import { useVehicle } from "shared/api/vehicles/hooks";
import { API_DATE_FORMAT } from "shared/constants";
import { useTenantMileageUnit } from "shared/hooks";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";

import CardHeader from "pages/Issues/Details/CardHeader";
import { VINEventTimelineDateLink } from "pages/shared/vehicles/VINEventTimelineDateLink";

import Card from "features/ui/Card";
import LabelValuePairs from "features/ui/LabelValuePairs";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  data: Claim;
}

const CARD_TITLE = "Vehicle Details";

const DISPLAY_FIELDS: Array<keyof Vehicle> = [
  "VIN",
  "vehicleMake",
  "vehicleModel",
  "vehicleModelYear",
  "vehicleManufacturedAt",
  "engineModel",
];

const CardVehicleDetails = ({ data }: Props) => {
  const {
    data: vehicleData,
    isLoading,
    error,
  } = useVehicle({ vin: data.VIN, mileageUnit: useTenantMileageUnit() });
  const { schema } = useVehiclesSchema();

  const formatRow = (data: Vehicle, claim: Claim) => {
    const { VIN, vehicleManufacturedAt } = data;
    const { date } = claim;

    if (!vehicleManufacturedAt) {
      return {
        ...data,
        VIN: <VINEventTimelineDateLink date={date} VIN={VIN} />,
      };
    }

    const dateFrom = format(
      add(new Date(vehicleManufacturedAt), { weeks: -2 }),
      API_DATE_FORMAT
    );
    const dateTo = format(add(new Date(date), { weeks: 1 }), API_DATE_FORMAT);

    return {
      ...data,
      VIN: (
        <VINEventTimelineDateLink date={dateFrom} dateTo={dateTo} VIN={VIN} />
      ),
    };
  };

  if (isLoading) return <Skeleton height={300} />;

  if (error) return <div>Error</div>;

  if (!vehicleData) return <div>No data</div>;

  const formattedData = formatRow(vehicleData, data);

  return (
    <Card>
      <CardHeader title={CARD_TITLE} />
      <LabelValuePairs
        schema={schema as SchemaEntry<keyof Vehicle>[]}
        data={formattedData}
        fields={DISPLAY_FIELDS}
      />
    </Card>
  );
};

export default CardVehicleDetails;
