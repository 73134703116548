import { useCallback, useState } from "react";

import { DASHBOARD_GROUP_BY_KEY } from "features/ui/charts/constants";
import { DataElement } from "features/ui/charts/types";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { mergeFilterGroupStates } from "features/ui/Filters/FilterBuilder/utils";
import { SelectOption } from "features/ui/Select";

import { BarTuple, ColumnTuple } from "./types";
import { getFilterFromColumns, handleSelectedColumnsUpdate } from "./utils";

interface Props {
  selectedGroupByAttribute: SelectOption;
  selectedBucketByKey: string;
  splitByIssueSource?: boolean;
  menuOffsetElement?: Element | null;
  nameIDMapping: { [key: string]: string };
  updateFilters?: (filters: FilterGroupState) => void;
  existingFilters?: FilterGroupState;
  key: string;
}

export const useBarSelection = ({
  selectedGroupByAttribute,
  selectedBucketByKey,
  splitByIssueSource,
  menuOffsetElement,
  nameIDMapping,
  updateFilters,
  existingFilters,
  key,
}: Props) => {
  const currentBarsKey = `${key}-selected-bars-filter`;
  const currentColumnsKey = `${key}-selected-bars-columns-filter`;

  const getInitialBars = useCallback((): BarTuple[] => {
    const value = localStorage.getItem(currentBarsKey);

    return value ? JSON.parse(value) : [];
  }, [currentBarsKey]);

  const getInitialColumns = useCallback((): {
    [key: string]: ColumnTuple[];
  } => {
    const value = localStorage.getItem(currentColumnsKey);

    return value ? JSON.parse(value) : {};
  }, [currentColumnsKey]);

  // selectedBars refers to bar groups and selectedColumns refers to single bars inside the bar group
  const [selectedBars, setSelectedBars] =
    useState<BarTuple[]>(getInitialBars());
  const [selectedColumns, setSelectedColumns] = useState<{
    [key: string]: ColumnTuple[];
  }>(getInitialColumns());
  const [showContextMenu, setShowContextMenu] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState<{
    x: number;
    y: number;
  }>({ x: 0, y: 0 });

  const handleClear = () => {
    resetSelectedBars();
    resetSelectedColumns();
    localStorage.removeItem(currentBarsKey);
    localStorage.removeItem(currentColumnsKey);
  };

  // Toggle currently selected bars
  // make sure to stopPropagation so that the chart doesn't get the click event fired, which resets selected bars
  const handleOnBarClick = (
    row: DataElement,
    event: React.MouseEvent<SVGPathElement, MouseEvent>,
    promotedIssue?: boolean
  ) => {
    event.stopPropagation();

    const clickedBar: string = row[DASHBOARD_GROUP_BY_KEY];
    const clickedBarTuple: BarTuple = { value: clickedBar, promotedIssue };

    setSelectedBars((prevSelectedBars) => {
      if (!prevSelectedBars) return [clickedBarTuple];

      const selectedIndex = prevSelectedBars.findIndex(
        (barTuple) =>
          barTuple.value === clickedBar &&
          barTuple.promotedIssue === promotedIssue
      );
      if (selectedIndex !== -1) {
        prevSelectedBars.splice(selectedIndex, 1);
        localStorage.setItem(currentBarsKey, JSON.stringify(prevSelectedBars));

        return prevSelectedBars;
      } else {
        localStorage.setItem(
          currentBarsKey,
          JSON.stringify([...prevSelectedBars, clickedBarTuple])
        );

        return [...prevSelectedBars, clickedBarTuple];
      }
    });

    const clickedColumn: string = row?.["tooltipPayload"]?.[0]?.["name"] ?? "";

    const clickedColumnTuple: ColumnTuple = {
      value: clickedColumn,
      promotedIssue,
    };

    const updatedColumns = handleSelectedColumnsUpdate(
      selectedColumns,
      clickedBar,
      clickedColumnTuple
    );

    setSelectedColumns(() => {
      localStorage.setItem(currentColumnsKey, JSON.stringify(updatedColumns));

      return updatedColumns;
    });
    setShowContextMenu(false);

    return updatedColumns;
  };

  const resetSelectedBars = () => {
    setSelectedBars([]);
  };

  const resetSelectedColumns = () => {
    setSelectedColumns({});
  };

  const handleOnChartClick = () => {
    resetSelectedBars();
    resetSelectedColumns();
    // we close the context menu on any mouse click anywhere on the chart..
    if (showContextMenu) setShowContextMenu(false);
  };

  const onSelectedBarsApplyAsFilter = () => {
    if (Object.keys(selectedColumns).length > 0 && updateFilters) {
      const newColumnsFilter = getFilterFromColumns(
        selectedColumns,
        selectedGroupByAttribute.id.toString(),
        selectedBucketByKey,
        splitByIssueSource,
        nameIDMapping
      );

      const mergedFilters = mergeFilterGroupStates(
        newColumnsFilter,
        existingFilters
      );
      updateFilters(mergedFilters);
    }

    handleClear();
    setShowContextMenu(false);
  };

  const onBarRightClick = (
    _x: DataElement,
    _y: number,
    event: React.MouseEvent<SVGPathElement, MouseEvent>
  ) => {
    event.preventDefault();

    // If the user right-clicks on a bar and no bars are selected, automatically select the right-clicked bar
    if (
      selectedBars.length === 0 ||
      Object.keys(selectedColumns).length === 0
    ) {
      handleOnBarClick(_x, event);
    }

    const elementRect = menuOffsetElement?.getBoundingClientRect();
    const offsetX = elementRect ? elementRect.left : 0;
    const offsetY = elementRect ? elementRect.top : 0;

    setContextMenuPosition({
      x: event.clientX - offsetX,
      y: event.clientY - offsetY,
    });
    setShowContextMenu(true);
  };

  const contextMenu = (
    <div
      className="bg-white shadow-sm border px-4 py-3 absolute z-10"
      style={{ top: contextMenuPosition.y, left: contextMenuPosition.x }}
    >
      <ul className="flex flex-col space-y-2">
        <li
          onClick={onSelectedBarsApplyAsFilter}
          className="text-sm hover:opacity-75 cursor-pointer"
        >
          Include selected values as page filter
        </li>
      </ul>
    </div>
  );

  return {
    selectedBars,
    selectedColumns,
    showContextMenu,
    handleOnBarClick,
    onBarRightClick,
    contextMenu,
    handleOnChartClick,
    handleClear,
  };
};
