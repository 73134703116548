import { Link } from "react-router-dom";

import { useConfigContext } from "shared/contexts/ConfigContext";

import { articles } from "./constants";

const articleEntry = (title: string, url: string) => (
  <li key={url} className="m-1">
    <Link
      to={url}
      target="_blank"
      className="text-metabase-blue hover:underline"
    >
      {title}
    </Link>
  </li>
);

const HelpArticles = () => {
  const {
    pages: { helpArticles },
  } = useConfigContext();

  const customArticles = helpArticles?.articles || [];
  const allArticles = [...customArticles, ...articles];

  return (
    <div className="pr-8 pl-4">
      <p>A collection of articles to help Viaduct users with common tasks.</p>
      <div className="pt-2 pl-5">
        <ul className="list-disc" data-testid="help-articles-list">
          {allArticles.map(({ title, url }) => articleEntry(title, url))}
        </ul>
      </div>
    </div>
  );
};

export default HelpArticles;
