import React, { useRef, useState } from "react";
import classNames from "classnames";
import { BsQuestionCircle as QMarkIcon } from "react-icons/bs";
import { GrEdit as EditIcon } from "react-icons/gr";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";

import {
  Bookmark as BookmarkModel,
  UpdateBookmarkRequest,
} from "shared/api/bookmarks/api";

import ConfirmationModal from "features/ui/ConfirmationModal";
import RichTextEditor from "features/ui/RichTextEditor/RichTextEditor";
import Tooltip from "features/ui/Tooltip";

import { CANCEL_TEXT, CONFIRM_TEXT, TOOLTIP_EDIT_TEXT } from "./constants";
import EditBookmark from "./EditBookmark";

interface InfoButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  isExpanded: boolean;
}

interface EditButtonProps {
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

interface BookmarkItemProps {
  bookmark: BookmarkModel;
  onDelete: (bookmark: BookmarkModel) => void;
  onUpdate: (bookmark: UpdateBookmarkRequest) => void;
}

const InfoButton = ({ onClick, isExpanded }: InfoButtonProps) => (
  <IconButton
    size="small"
    className="pl-0 p-0 "
    onClick={onClick}
    data-testid="show-filter-summary"
    style={{ padding: "2px" }}
  >
    <QMarkIcon
      className={classNames("hover:fill-blue-400", {
        "fill-blue-400": isExpanded,
        "fill-gray-300": !isExpanded,
      })}
    />
  </IconButton>
);

const EditButton = ({ onClick }: EditButtonProps) => (
  <IconButton
    size="small"
    onClick={onClick}
    data-testid="show-filter-summary"
    className={classNames("pl-0 text-gray-300! hover:text-gray-400!")}
  >
    <Tooltip
      title={<span className="text-xs w-8">{TOOLTIP_EDIT_TEXT}</span>}
      placement="left"
    >
      <EditIcon />
    </Tooltip>
  </IconButton>
);

const Bookmark = ({ bookmark, onDelete, onUpdate }: BookmarkItemProps) => {
  let [isHovered, setIsHovered] = useState(false);
  let [isExpanded, setIsExpanded] = useState(false);
  let [isDescriptionVisible, setIsDescriptionVisible] = useState(false);

  let [editing, setEditing] = useState(false);
  let [modalVisible, setModalVisible] = useState(false);
  const elementRef = useRef<HTMLAnchorElement>(null);

  const onClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    if (isExpanded) {
      setIsDescriptionVisible(false);
    } else {
      setTimeout(() => {
        setIsDescriptionVisible(true);
      }, 300);
    }

    setIsExpanded(!isExpanded);
    // scroll to the bookmark element after size transition which is 300ms
    setTimeout(() => {
      const { current } = elementRef;
      if (current != null) {
        current.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }
    }, 305);
  };
  const onEdit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    event.preventDefault();
    setEditing(true);
  };
  const handleDeleteClick = () => {
    setModalVisible(true);
  };
  const handleDelete = (confirmed: boolean) => {
    if (confirmed) {
      onDelete(bookmark);
    }

    setModalVisible(false);
  };
  const handleConfirm = (bookmark: UpdateBookmarkRequest) => {
    onUpdate(bookmark);
    setEditing(false);
  };
  const deleteText = () => (
    <div>
      Are you sure you want to <strong>permanently</strong> delete{" "}
      <i>{bookmark.title}</i> bookmark?
    </div>
  );

  return (
    <>
      {!editing && (
        <Link
          ref={elementRef}
          className={classNames(
            "cursor-default overflow-hidden min-h-20 max-h-20 min-w-40 max-w-40 bg-white p-2 rounded-sm items-start",
            {
              "min-w-80 min-h-30": isExpanded,
              "max-h-60": isDescriptionVisible,
              "outline outline-1 outline-gray-400": isHovered,
            }
          )}
          style={{
            transition:
              "width 300ms, height 300ms, min-width 300ms, min-height 300ms, max-height 300ms",
          }}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          to={bookmark.path}
        >
          <div className="flex flex-row gap-2 justify-between max-h-full">
            <div className="w-[calc(100%-2rem)] flex flex-col gap-1">
              <div
                className={classNames(
                  "w-full max-h-20 flex flex-row items-center font-medium text-sm h-fit",
                  {
                    "text-blue-400": isHovered,
                  }
                )}
                style={{
                  display: "-webkit-box",
                  WebkitLineClamp: isExpanded ? "none" : 3,
                  WebkitBoxOrient: "vertical",
                  overflow: isExpanded ? "visible" : "hidden",
                }}
                title={bookmark.title}
              >
                {bookmark.title}
              </div>
              {isDescriptionVisible && (
                <div
                  className="w-full h-fit mb-2 overflow-hidden"
                  style={{ scrollbarWidth: "none", msOverflowStyle: "none" }}
                >
                  <RichTextEditor
                    content={bookmark.description ?? ""}
                    containerClass="h-full"
                    contentClass="text-xs max-h-full scrollbar-none overflow-auto"
                    noPadding={true}
                    hideScrollbar={true}
                  ></RichTextEditor>
                </div>
              )}
            </div>

            <div className="flex flex-col items-center">
              <InfoButton
                onClick={onClick}
                isExpanded={isExpanded && isHovered}
              />
              {isExpanded && <EditButton onClick={onEdit} />}
            </div>
          </div>
        </Link>
      )}
      {editing && (
        <EditBookmark
          bookmark={bookmark}
          onCancel={() => setEditing(false)}
          onConfirm={handleConfirm}
          onDelete={handleDeleteClick}
        />
      )}
      <ConfirmationModal
        isOpen={modalVisible}
        onClose={handleDelete}
        loading={false}
        confirmText={CONFIRM_TEXT}
        closeText={CANCEL_TEXT}
        title=""
        text={deleteText()}
        confirmColor="error"
      />
    </>
  );
};

export default Bookmark;
