import { Claim } from "shared/api/claims/api";
import { useClaimsSchema } from "shared/schemas/claimsSchema";

import CardHeader from "pages/Issues/Details/CardHeader";

import Card from "features/ui/Card";
import FilterDescriptionTable from "features/ui/Filters/other/FilterDescriptionTable";
import LabelValuePairs from "features/ui/LabelValuePairs";
import StringList from "features/ui/StringList";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  data: Claim;
}

const CARD_TITLE = "Repair Narratives";
const MAX_VISIBLE_CHIPS = 10;

const DISPLAY_FIELDS = [
  "notes",
  "notesCustomer",
  "notesTechnician",
  "notesCorrection",
  "notesPart",
  "mentionedSignalEvents",
  "mentionedPhrases",
];

const RESIZABLE_FIELDS = [
  "notes",
  "notesCustomer",
  "notesTechnician",
  "notesCorrection",
  "notesPart",
];

const CardRepairDetails = ({ data }: Props) => {
  const { schema, getDisplayLabel } = useClaimsSchema();
  const formatRow = (data: Claim) => {
    const { mentionedSignalEvents, mentionedPhrases } = data;

    return {
      ...data,
      mentionedSignalEvents: mentionedSignalEvents?.length ? (
        <FilterDescriptionTable
          values={mentionedSignalEvents?.map(({ ID }) => ID) || []}
          fieldName="mentionedSignalEvents"
          title=""
          searchString=""
          linkToClaimAnalytics={true}
        />
      ) : null,
      mentionedPhrases: mentionedPhrases?.length ? (
        <div className="mx-3 my-2">
          <StringList
            accessor="mentionedPhrases"
            title={getDisplayLabel("mentionedPhrases", "Mentioned phrases")}
            values={mentionedPhrases || []}
            maxVisible={MAX_VISIBLE_CHIPS}
            linkToClaimAnalytics={true}
          />
        </div>
      ) : null,
    };
  };

  const formattedData = formatRow(data);

  return (
    <Card>
      <CardHeader title={CARD_TITLE} />
      <LabelValuePairs
        schema={schema as SchemaEntry<keyof Claim>[]}
        data={formattedData}
        fields={DISPLAY_FIELDS}
        hasNarratives
        translate={true}
        resizableFields={RESIZABLE_FIELDS}
      />
    </Card>
  );
};

export default CardRepairDetails;
