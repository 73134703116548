import { SyntheticEvent } from "react";
import DuckDrillTreeItem from "duck/ui/drill/DuckDrillTreeItem";
import {
  RichTreeView,
  TreeItem2Props,
  TreeViewBaseItem,
} from "@mui/x-tree-view";
import { SlotComponentPropsFromProps } from "@mui/x-tree-view/internals/models";

import { SelectOption } from "features/ui/Select";

interface DuckDrillOptionsTreeProps {
  availableItems: TreeViewBaseItem[];
  flattenedLeaves: SelectOption[];
  selectedAttributes: string[];
  setSelectedAttributes: (attributes: string[]) => void;
  expandedItems: string[];
  setExpandedItems: (items: string[]) => void;
}

const DuckDrillOptionsTree = ({
  availableItems,
  flattenedLeaves,
  selectedAttributes,
  setSelectedAttributes,
  expandedItems,
  setExpandedItems,
}: DuckDrillOptionsTreeProps) => {
  const handleSelectedItemsChange = (
    _event: SyntheticEvent,
    items: string[]
  ) => {
    const leafItems = items.filter(
      (item) => !!flattenedLeaves.find((leaf) => leaf.id === item)
    );
    setSelectedAttributes(leafItems);
  };

  const handleExpandedItemsChange = (
    _event: SyntheticEvent,
    items: string[]
  ) => {
    setExpandedItems(items);
  };

  return (
    <RichTreeView
      checkboxSelection={true}
      multiSelect={true}
      selectedItems={selectedAttributes}
      onSelectedItemsChange={handleSelectedItemsChange}
      items={availableItems}
      expandedItems={expandedItems}
      onExpandedItemsChange={handleExpandedItemsChange}
      slots={{
        item: DuckDrillTreeItem,
      }}
      slotProps={{
        item: {
          // Chrome complained when the attributes were camelCased but not when they are
          // all lower case. Whatever makes it happy.
          selectedattributes: selectedAttributes,
          flattenedleaves: flattenedLeaves,
          // This "as SlotComponentPropsFromProps" is telling TS not to worry about the
          // structure of this slot prop. It is necessary because we are adding additional
          // values to the props that are not part of the original TreeItem2Props definition.
          // The extra values cause no harm to the TreeItem code, and are needed for our
          // custom DuckDrillTreeItem to work properly.
        } as SlotComponentPropsFromProps<TreeItem2Props, {}, {}>,
      }}
    />
  );
};

export default DuckDrillOptionsTree;
