import {
  EXPLORE_IN_CLAIM_ANALYTICS_LABEL,
  EXPLORE_IN_SE_ANALYTICS_LABEL,
} from "shared/constants";

import Button from "features/ui/Button";
import {
  filterBuilderQueryToFilterBuilderState,
  getTopLevelRowFromFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import { SIGNAL_EVENT_ID_FIELD_NAME } from "features/ui/Filters/FilterTypes/OccursFilter/constants";
import { FilterOperator, UseFilterSortState } from "features/ui/Filters/types";
import Label from "features/ui/Label";

import AddAsOccursFilterAction from "./AddAsOccursFilterAction";
import {
  decodeOccursFilterAndOptions,
  encodeOccursFilterAndOptions,
} from "./utils";

interface Props {
  filterSortState?: UseFilterSortState;
  occursFilter: string;
  selectedSignalEvents: Set<string>;
  filterActionLabel?: string;
  onAddToFilter?: () => void;
  onExploreInSEAnalyticsActionClick?: () => void;
  onExploreInClaimAnalyticsActionClick?: () => void;
}

const SelectedRowsActions = ({
  filterSortState,
  occursFilter,
  selectedSignalEvents,
  onAddToFilter,
  filterActionLabel,
  onExploreInSEAnalyticsActionClick,
  onExploreInClaimAnalyticsActionClick,
}: Props) => {
  const filters = filterSortState?.filters;

  const { windowSize, windowType, windowDirection } =
    decodeOccursFilterAndOptions(occursFilter);

  const onFilterActionClick = () => {
    const relatedSEOccurrencesFilter = getTopLevelRowFromFilterGroupState(
      "relatedSignalEventOccurrences",
      filters
    );

    const signalEventIDs = [...selectedSignalEvents];

    if (
      relatedSEOccurrencesFilter?.operator === FilterOperator.OCCURS &&
      relatedSEOccurrencesFilter?.values?.length
    ) {
      const { filters: existingRelatedSEOccurrencesFilter } =
        decodeOccursFilterAndOptions(relatedSEOccurrencesFilter?.values[0]);
      const existingRelatedSEOccurrencesFilterState =
        filterBuilderQueryToFilterBuilderState(
          existingRelatedSEOccurrencesFilter
        );
      const existingSEIDs = getTopLevelRowFromFilterGroupState(
        SIGNAL_EVENT_ID_FIELD_NAME,
        existingRelatedSEOccurrencesFilterState
      );
      if (existingSEIDs?.values?.length) {
        signalEventIDs.push(...existingSEIDs.values);
      }
    }

    const encodedOccursFilterAndOptions = encodeOccursFilterAndOptions(
      {
        id: "group-0",
        type: "group",
        anyAll: "all",
        children: [
          {
            id: "row-0",
            type: "row",
            attribute: SIGNAL_EVENT_ID_FIELD_NAME,
            operator: FilterOperator.IN,
            values: Array.from(signalEventIDs),
          },
        ],
      },
      {
        windowSize,
        windowDirection,
        windowType,
      }
    );

    filterSortState?.manageFilterChange({
      key: "relatedSignalEventOccurrences",
      op_id: FilterOperator.OCCURS,
      values: [encodedOccursFilterAndOptions],
    });

    onAddToFilter && onAddToFilter();
  };

  const FiltersAction = () => (
    <AddAsOccursFilterAction
      onClick={onFilterActionClick}
      label={filterActionLabel}
    />
  );

  if (selectedSignalEvents.size === 0) return null;

  return (
    <div data-testid="add-as-related-signal-event-div">
      <div className="flex items-center">
        <Label text="Selected Row Actions:" className="text-gray-500 mb-0!" />
        {onAddToFilter && <FiltersAction />}
        {onExploreInSEAnalyticsActionClick && (
          <Button
            label={EXPLORE_IN_SE_ANALYTICS_LABEL}
            variant="text"
            color="primary"
            testId="explore-in-signal-event-analytics-cta"
            onClick={onExploreInSEAnalyticsActionClick}
          />
        )}
        {onExploreInClaimAnalyticsActionClick && (
          <Button
            label={EXPLORE_IN_CLAIM_ANALYTICS_LABEL}
            variant="text"
            color="primary"
            testId="explore-in-claim-analytics-cta"
            onClick={onExploreInClaimAnalyticsActionClick}
          />
        )}
      </div>
    </div>
  );
};

export default SelectedRowsActions;
