import { useEffect, useRef, useState } from "react";
import Button from "@mui/material/Button";

import {
  Bookmark as BookmarkModel,
  UpdateBookmarkRequest,
} from "shared/api/bookmarks/api";

import Input from "features/ui/Input";
import { OptionalFeatures } from "features/ui/RichTextEditor/constants";
import RichTextEditor from "features/ui/RichTextEditor/RichTextEditor";

import { HELP_TEXT } from "./constants";

interface UpdateBookmarkProps {
  bookmark: BookmarkModel;
  onConfirm: (bookmark: UpdateBookmarkRequest) => void;
  onDelete: () => void;
  onCancel: () => void;
}

const EditBookmark = ({
  bookmark,
  onConfirm,
  onDelete,
  onCancel,
}: UpdateBookmarkProps) => {
  const [title, setTitle] = useState(bookmark.title);
  const [description, setDescription] = useState(bookmark.description);
  const [path, setPath] = useState(bookmark.path);
  const elementRef = useRef<HTMLDivElement>(null);

  // scroll to the bookmark form on render
  useEffect(() => {
    const { current } = elementRef;
    if (current != null) {
      current.scrollIntoView({ behavior: "auto" });
    }
  });

  const handleConfirm = () => {
    const updatedBookmark: UpdateBookmarkRequest = {
      title,
      description,
      path,
      ID: bookmark.ID,
    };
    onConfirm(updatedBookmark);
  };

  return (
    <div
      ref={elementRef}
      className="flex flex-col gap-3 min-h-70 max-h-80 min-w-96 max-w-80 bg-white p-2 rounded-sm items-start"
    >
      <Input
        label="Title"
        value={title}
        onChange={({ target: { value } }) => setTitle(value)}
        testId="bookmark-description"
        tabIndex={1}
        sx={{
          ".MuiInputBase-input": {
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          },
        }}
      />

      <Input
        label="URL"
        value={path}
        onChange={({ target: { value } }) => setPath(value)}
        testId="bookmark-description"
        tabIndex={1}
        sx={{
          ".MuiInputBase-input": {
            fontSize: "0.875rem",
            lineHeight: "1.25rem",
          },
        }}
      />
      <div className="w-full -mt-3">
        <label className="text-xs text-gray-400">{HELP_TEXT}</label>
        <RichTextEditor
          content={description ?? ""}
          onChange={(value) => setDescription(value)}
          editing={true}
          testId="bookmark-description"
          contentClass="text-sm h-[9rem]"
          containerClass="border-gray-300"
          features={[OptionalFeatures.textDecoration, OptionalFeatures.link]}
          bubbleMenu
        ></RichTextEditor>
      </div>

      {/*<TextArea*/}
      {/*  className="text-sm"*/}
      {/*  label="Help text"*/}
      {/*  value={description}*/}
      {/*  onChange={({ target: { value } }) => setDescription(value)}*/}
      {/*  testId="bookmark-description"*/}
      {/*  tabIndex={1}*/}
      {/*  rows={7}*/}
      {/*  sx={{*/}
      {/*    ".MuiInputBase-input": {*/}
      {/*      fontSize: "0.875rem",*/}
      {/*      lineHeight: "1.25rem",*/}
      {/*    },*/}
      {/*  }}*/}
      {/*/>*/}
      <div className="w-full flex flex-row justify-end gap-2">
        <Button size="small" variant="contained" onClick={handleConfirm}>
          Save
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={onDelete}
          color="secondary"
        >
          Delete
        </Button>
        <Button
          size="small"
          variant="outlined"
          onClick={onCancel}
          color="secondary"
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

export default EditBookmark;
