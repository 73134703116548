import { useContext } from "react";

import { EventType } from "shared/api/api";
import { EventRegistryContext } from "shared/contexts/EventRegistryContext";
import { EventTypeEnumCapitalizedType } from "shared/types";

import { SelectOption } from "features/ui/Select";

export const useEventTypeOptions = (
  ignoreTypes: EventTypeEnumCapitalizedType[] = [],
  requiredAttribute?: string
): SelectOption[] => {
  const eventTypes = useContext(EventRegistryContext);

  return (
    eventTypes
      ?.filter((eventType) => !ignoreTypes.includes(eventType.type))
      // we want to offer only event types that have certain properties like engineHoursAttribute
      ?.filter(
        (eventType) =>
          !requiredAttribute || eventType[requiredAttribute as keyof EventType]
      )
      ?.map((eventType) => ({
        id: eventType.type,
        value: eventType.name,
        testId: eventType.type,
      })) || []
  );
};
