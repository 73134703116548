import CollectionsTable from "pages/Collections/CollectionsTable";

import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

const TITLE = "Collections";

const Collections = () => (
  <>
    <PageHeaderWrapper title={TITLE} />
    <CollectionsTable />
  </>
);

export default Collections;
