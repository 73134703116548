import React from "react";
import classNames from "classnames";

import { IssueVehiclePopulation } from "shared/types";

import Label from "features/ui/Label";

import { getPopulationString } from "./utils";

const NOT_AVAILABLE_LABEL = "N/A";

export interface LabelTexts {
  mainValue?: string;
  secondaryValue?: string;
  description: string;
}

interface Props {
  withComparisonPopulation: boolean;
  population: IssueVehiclePopulation;
  labelTexts: LabelTexts[];
}

const IssueStatistics = ({
  withComparisonPopulation,
  population,
  labelTexts,
}: Props) => (
  <div className="md:flex flex-col md:flex-row md:space-x-10">
    {withComparisonPopulation && (
      <span className="md:flex md:w-64 items-center">
        <Label
          text={getPopulationString(population)}
          className="text-center text-viaduct-black"
        />
      </span>
    )}
    {labelTexts.map(({ mainValue, secondaryValue, description }) => (
      <span className="text-center" key={description}>
        <div className="md:inline-flex gap-3 items-center text-center">
          <Label
            text={mainValue === undefined ? NOT_AVAILABLE_LABEL : mainValue}
            className={classNames("text-3xl!", {
              "text-gray-400": mainValue === undefined,
              "text-viaduct-black ": mainValue !== undefined,
            })}
          />
          {secondaryValue && (
            <Label text={secondaryValue} className="text-gray-500 text-2xl!" />
          )}
        </div>

        <Label text={description} className="text-viaduct-black" />
      </span>
    ))}
  </div>
);

export default IssueStatistics;
