import React, { useState } from "react";
import { FiMoreHorizontal as MoreIcon } from "react-icons/fi";
import { MdDragIndicator as DragIcon } from "react-icons/md";
import { DraggableAttributes } from "@dnd-kit/core";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { FormControlLabel, IconButton, Switch, TextField } from "@mui/material";

import { AttributesConfigState } from "./types";

interface InnerRowProps extends ConfigRowProps {
  attributes: DraggableAttributes;
  listeners: SyntheticListenerMap | undefined;
}
interface ConfigRowProps {
  row: AttributesConfigState;
  updateValueByID: (
    id: string,
    updater: (prevRow: AttributesConfigState) => AttributesConfigState
  ) => void;
}

const ConfigRow = ({
  row,
  updateValueByID,
  listeners,
  attributes,
}: InnerRowProps) => {
  const [showDetails, setShowDetails] = useState(false);

  const updateValue = (key: string, value: boolean | string) => {
    updateValueByID(row.id, (prevRow) => ({ ...prevRow, [key]: value }));
  };

  return (
    <div>
      <div className="flex justify-between mb-2 max-w-[1000px]">
        <div className="flex flex-col lg:flex-row space-y-3 lg:space-y-0 lg:space-x-2">
          <DragIcon
            {...attributes}
            {...listeners}
            size={22}
            className="cursor-grab hover:text-black select-none shrink-0 mt-2"
            data-testid={`rb-rule-drag-handle-${row.id}`}
          />

          <TextField
            label="Attribute ID"
            defaultValue={row.ID}
            disabled={!row.newAttribute}
            size="small"
            className="shrink-0"
            placeholder="Enter attribute ID"
            onChange={(change) => updateValue("ID", change.target.value)}
          />
          <FormControlLabel
            control={
              <Switch
                checked={row.enabled}
                onChange={(_, checked) => updateValue("enabled", checked)}
                size="small"
              />
            }
            label={<div className="text-xs">Enabled</div>}
          />
          <TextField
            label="Display Name"
            defaultValue={row.displayName}
            onChange={(change) =>
              updateValue("displayName", change.target.value)
            }
            size="small"
            className="shrink-0 w-64"
          />
          <TextField
            label="Description"
            defaultValue={row.description}
            onChange={(change) =>
              updateValue("description", change.target.value)
            }
            size="small"
            className="shrink-0 w-64"
          />
        </div>
        <IconButton
          className="leading-10 cursor-pointer"
          size="small"
          title={showDetails ? "Hide details" : "Show details"}
          onClick={() => setShowDetails(!showDetails)}
        >
          <MoreIcon size={22} />
        </IconButton>
      </div>
      {showDetails && (
        <div className="flex flex-wrap max-w-[1000px] gap-2 border-b border-l border-r lg:ml-5 pl-5 pb-3 mb-3">
          <FormControlLabel
            control={
              <Switch
                defaultChecked={row.filtering}
                onChange={(_, checked) => updateValue("filtering", checked)}
                size="small"
              />
            }
            label={<div className="text-xs">Filtering</div>}
          />
          <FormControlLabel
            control={
              <Switch
                defaultChecked={row.sorting}
                onChange={(_, checked) => updateValue("sorting", checked)}
                size="small"
              />
            }
            label={<div className="text-xs">Sorting</div>}
          />
          <FormControlLabel
            control={
              <Switch
                defaultChecked={row.values}
                onChange={(_, checked) => updateValue("values", checked)}
                size="small"
              />
            }
            label={<div className="text-xs">Filtering autocomplete</div>}
          />
          <FormControlLabel
            control={
              <Switch
                defaultChecked={row.groupBy}
                onChange={(_, checked) => updateValue("groupBy", checked)}
                size="small"
              />
            }
            label={<div className="text-xs">GroupBy</div>}
          />
          <FormControlLabel
            control={
              <Switch
                defaultChecked={row.hideInTable}
                onChange={(_, checked) => updateValue("hideInTable", checked)}
                size="small"
              />
            }
            label={<div className="text-xs w-16">Hide in table</div>}
          />
          <FormControlLabel
            control={
              <Switch
                defaultChecked={row.hideFilter}
                onChange={(_, checked) => updateValue("hideFilter", checked)}
                size="small"
              />
            }
            label={<div className="text-xs w-16">Hide filter</div>}
          />
          <FormControlLabel
            control={
              <Switch
                defaultChecked={row.export}
                onChange={(_, checked) => updateValue("export", checked)}
                size="small"
              />
            }
            label={<div className="text-xs">Export</div>}
          />
          <FormControlLabel
            control={
              <Switch
                defaultChecked={row.byVehicleAgeBirthday}
                onChange={(_, checked) =>
                  updateValue("byVehicleAgeBirthday", checked)
                }
                size="small"
              />
            }
            label={<div className="text-xs w-36">By Vehicle age birthday</div>}
          />
          <FormControlLabel
            control={
              <Switch
                defaultChecked={row.byVehicleAgeExposure}
                onChange={(_, checked) =>
                  updateValue("byVehicleAgeExposure", checked)
                }
                size="small"
              />
            }
            label={
              <div className="text-xs shrink-0 w-36">
                By Vehicle age exposure
              </div>
            }
          />
          <TextField
            label="By Vehicle age exposure Buckets"
            defaultValue={row.byVehicleAgeExposureBuckets}
            onChange={(change) =>
              updateValue("byVehicleAgeExposureBuckets", change.target.value)
            }
            size="small"
            className="shrink-0 w-56"
          />
          <TextField
            label="Unit at rest"
            defaultValue={row.unitAtRest}
            onChange={(change) =>
              updateValue("unitAtRest", change.target.value)
            }
            size="small"
            className="shrink-0 w-56"
          />
        </div>
      )}
    </div>
  );
};

const OuterRow = ({ row, updateValueByID }: ConfigRowProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: row.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <MemoizedConfigRow
        row={row}
        updateValueByID={updateValueByID}
        attributes={attributes}
        listeners={listeners}
      />
    </div>
  );
};

// Using React.memo is performance optimization as it was really slow otherwise. This current synthax means that the component will never re-render.
// Input state is handled by component in uncontrolled way and only passed updates up the tree. We never expect the change to come from the top.
const MemoizedConfigRow = React.memo(
  ConfigRow,
  (prev, next) =>
    prev.row.id === next.row.id && prev.row.enabled === next.row.enabled
);

export default OuterRow;
