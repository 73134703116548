import Skeleton from "react-loading-skeleton";

import { useGetSuggestedIssuesMetricsRegistry } from "shared/api/metrics/hooks";
import useSuggestedIssuesSchema from "shared/schemas/useSuggestedIssuesSchema";

import APIError from "features/ui/APIError";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

import {
  DEFAULT_SUGGESTED_ISSUES_SORT,
  SUGGESTED_ISSUES_KEY,
  SUGGESTED_ISSUES_PAGE_KEY,
  SUGGESTED_ISSUES_TITLE,
} from "./constants";
import SuggestedIssuesFilters from "./SuggestedIssues/SuggestedIssuesFilters";
import SuggestedIssuesPageWithMetrics from "./SuggestedIssuesPageWithMetrics";
import { useDefaultSuggestedIssueFilter } from "./utils";

export const SUGGESTED_ISSUES_PENDING_FILTERS_LS_KEY = getPendingFiltersKey(
  SUGGESTED_ISSUES_PAGE_KEY
);

const SuggestedIssuesPage = () => {
  const defaultFilters = useDefaultSuggestedIssueFilter();

  const { attributeAccessors } = useSuggestedIssuesSchema();

  const {
    data: suggestedIssueMetrics,
    isLoading: metricsLoading,
    error,
  } = useGetSuggestedIssuesMetricsRegistry();

  const filterSortState = useFilterSortState({
    pageKey: SUGGESTED_ISSUES_PAGE_KEY,
    defaultSort: DEFAULT_SUGGESTED_ISSUES_SORT,
    defaultFilterValues: defaultFilters,
    pendingFiltersLocalStorageKey: SUGGESTED_ISSUES_PENDING_FILTERS_LS_KEY,
    defaultTab: SUGGESTED_ISSUES_KEY,
    schemaAttributes: attributeAccessors,
  });

  return (
    <>
      <PageHeaderWrapper title={SUGGESTED_ISSUES_TITLE} />
      <SuggestedIssuesFilters
        filterSortState={filterSortState}
        defaultFilters={defaultFilters}
      />
      {metricsLoading && <Skeleton height={300} />}
      {error && <APIError error={error} />}
      {!metricsLoading && !error && suggestedIssueMetrics && (
        <SuggestedIssuesPageWithMetrics
          suggestedIssueMetrics={suggestedIssueMetrics}
          filterSortState={filterSortState}
        />
      )}
    </>
  );
};

export default SuggestedIssuesPage;
