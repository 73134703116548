import {
  APIListValuesRequest,
  APIListValuesResponse,
  getFetcher,
  ListAttributesRequest,
} from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";

const WORK_ORDERS_BASE_ROUTE = "workOrders";

export const listWorkOrdersValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listWorkOrdersValuesRequestURI(args));

const listWorkOrdersValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([WORK_ORDERS_BASE_ROUTE, "values", fieldName]),
    params,
  });

export const listWorkOrdersAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([WORK_ORDERS_BASE_ROUTE, "attributes"]),
    params,
  });
