import { useCallback } from "react";

import { useVehiclesFiltersSchema } from "shared/hooks";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import FilterWizard from "features/ui/Filters/FilterWizard";
import { hasSomeFiltersAppliedFilterGroupState } from "features/ui/Filters/utils";
import { StepProps } from "features/ui/Stepper";

import { ISSUE_AT_RISK_POPULATION_FILTER_PAGE_KEY } from "./constants";
import VehicleCount from "./VehicleCount";

const StepAtRiskPopulation = ({
  data,
  onDataUpdated,
  disableUsingLocalStorage,
}: StepProps) => {
  const onFiltersChange = useCallback((filters: FilterGroupState) => {
    onDataUpdated && onDataUpdated({ atRiskPopulationFilter: filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = useVehiclesFiltersSchema();

  if (!data) return null;

  const currentFilters: FilterGroupState = data.atRiskPopulationFilter;
  const showCount = hasSomeFiltersAppliedFilterGroupState(currentFilters);

  return (
    <>
      <div className="mb-4 text-sm text-viaduct-black">
        Issues allow you to investigate and track systemic quality issues. Start
        by defining the at-risk population, which is population in which the
        defect or problem is thought to occur. Refine your population by adding
        filters from the list below. You must add at least one filter to proceed
        to the next step.
      </div>
      <FilterWizard
        schema={schema}
        initialFilters={currentFilters}
        onFiltersChange={onFiltersChange}
        pageKey={ISSUE_AT_RISK_POPULATION_FILTER_PAGE_KEY}
        disableUsingLocalStorage={disableUsingLocalStorage}
        belowRightBlockContent={
          showCount && (
            <VehicleCount filters={currentFilters} label="At-Risk population" />
          )
        }
        testId={ISSUE_AT_RISK_POPULATION_FILTER_PAGE_KEY}
      />
    </>
  );
};

export default StepAtRiskPopulation;
