import { useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "react-router-dom";

import { useConfigContext } from "shared/contexts/ConfigContext";

import { PAGE_TITLE as CLAIMS_PAGE_TITLE } from "pages/ClaimAnalytics/constants";
import CreateIssue from "pages/Issues/CreateIssue/CreateIssue";
import { clearPendingIssueFilters } from "pages/Issues/CreateIssue/utils";
import { IssueFormData } from "pages/shared/types";
import { PAGE_TITLE as SE_PAGE_TITLE } from "pages/SignalEventsAnalytics/constants";

import DropdownSelect from "features/ui/DropdownSelect";
import { DropdownSelectOption } from "features/ui/DropdownSelect/DropdownSelect";
import { ActionType } from "features/ui/StepperModal";

import { useQuery } from "services/hooks";
import { routes } from "services/routes";

import { combineIssueData, getSearchParamsFor } from "./utils";

const DROPDOWN_LABEL = "Use Filters";

interface Props {
  issueData: IssueFormData;
  currentPage: "claimAnalytics" | "signalEventsAnalytics";
}

const UseFiltersMenu = ({ issueData, currentPage }: Props) => {
  const {
    issues: issuesFF,
    claimAnalytics: claimAnalyticsFF,
    signalEventsAnalytics: signalEventsAnalyticsFF,
  } = useFlags();
  const query = useQuery();
  const navigate = useNavigate();

  const [issueModalOpen, setIssueModalOpen] = useState(false);
  const [issueModalAction, setIssueModalAction] =
    useState<ActionType>("create");

  const isCurrentClaimAnalytics = currentPage === "claimAnalytics";

  const {
    pages: { issues, claimAnalytics, signalEventsAnalytics },
  } = useConfigContext();

  const showIssueOption = issues && issuesFF;

  const showAnalytics = isCurrentClaimAnalytics
    ? signalEventsAnalytics && signalEventsAnalyticsFF
    : claimAnalytics && claimAnalyticsFF;

  if (!showIssueOption && !showAnalytics) {
    return null;
  }

  const pageToUseFilterOnOption = isCurrentClaimAnalytics
    ? routes.signalEventAnalytics
    : routes.claimAnalytics;

  const pageToUseFilterOnOptionLabel = isCurrentClaimAnalytics
    ? SE_PAGE_TITLE
    : CLAIMS_PAGE_TITLE;

  const options: (DropdownSelectOption | Boolean | undefined)[] = [
    showIssueOption && {
      id: "create-issue",
      testId: "create-issue",
      value: "To create new issue",
      onClick: () => {
        clearPendingIssueFilters();
        setIssueModalAction("create");
        setIssueModalOpen(true);
      },
    },
    showAnalytics && {
      id: "on-claim-or-se-analytics",
      testId: "on-claim-or-se-analytics",
      value: `On ${pageToUseFilterOnOptionLabel}`,
      onClick: () => {
        const searchParams = getSearchParamsFor(pageToUseFilterOnOption, query);
        navigate({
          pathname: pageToUseFilterOnOption,
          search: searchParams,
        });
      },
    },
  ].filter(Boolean) as DropdownSelectOption[];

  const optionsToRender = options.filter(Boolean) as DropdownSelectOption[];

  const handleOnSelect = ({ onClick }: DropdownSelectOption) => {
    onClick && onClick();
  };

  const formattedIssueData = combineIssueData(
    issueData,
    issueModalAction,
    isCurrentClaimAnalytics
  );

  return (
    <>
      <DropdownSelect
        label={DROPDOWN_LABEL}
        options={optionsToRender}
        color="primary"
        variant="text"
        onSelect={handleOnSelect}
        testId="use-filters-dropdown"
      />
      {issueModalOpen && (
        <CreateIssue
          action="create"
          defaultOpen={true}
          issueData={formattedIssueData}
          showButton={false}
          onClose={() => setIssueModalOpen(false)}
          onlyAllowCurrentStep={false}
        />
      )}
    </>
  );
};

export default UseFiltersMenu;
