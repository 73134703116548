import { getPages } from "duck/graph/api";
import { OPENAI_API_KEY } from "duck/graph/constants";
import { CodeDescriptionSourceOptions } from "duck/graph/types";
import { MemoryVectorStore } from "langchain/vectorstores/memory";
import type { Document } from "@langchain/core/documents";
import { OpenAIEmbeddings } from "@langchain/openai";

import * as api from "shared/api/api";

const getLaborCodes = (args: api.ListLaborCodesRequest) =>
  getPages<api.LaborCode, api.ListLaborCodesRequest>(api.listLaborCodes, args);

const getParts = (args: api.ListPartsRequest) =>
  getPages<api.Part, api.ListPartsRequest>(api.listParts, args);

/**
 * Use a function to create the embeddings so that this happens when the function is called
 * rather than when the code is loaded. This ensures that any problems will be handled by
 * the error boundary, and also that we can avoid executing it if Duck is not enabled.
 * @returns
 */
const getEmbeddings = () =>
  new OpenAIEmbeddings({
    apiKey: OPENAI_API_KEY,
    model: "text-embedding-3-small",
    dimensions: 512,
  });

let vectorstore: MemoryVectorStore;
let waitForVectorstore: Promise<void>;

/**
 * Ingests part descriptions into the vector store.
 */
const ingestPartDesciptions = async (vectorstore: MemoryVectorStore) => {
  const parts = await getParts({});
  console.debug(`Ingesting part descriptions: ${parts.length}`);

  const documents: Document[] = parts
    .filter((part) => part.description && part.description !== "N/A")
    .map((part) => ({
      pageContent: part.description,
      metadata: {
        source: "parts",
        id: part.ID,
      },
    }));

  await vectorstore.addDocuments(documents);
  console.debug("Ingested part descriptions");
};

/**
 * Ingests labor code descriptions into the vector store.
 */
const ingestLaborCodeDescriptions = async (vectorstore: MemoryVectorStore) => {
  const laborCodes = await getLaborCodes({});
  console.debug(`Ingesting labor code descriptions: ${laborCodes.length}`);

  const documents: Document[] = laborCodes
    .filter(
      (laborCode) => laborCode.description && laborCode.description !== "N/A"
    )
    .map((laborCode) => ({
      pageContent: laborCode.description,
      metadata: {
        source: "laborCodes",
        id: laborCode.ID,
      },
    }));

  await vectorstore.addDocuments(documents);
  console.debug("Ingested labor code descriptions");
};

/**
 * Creates a vector store with descriptions from parts and labor codes.
 */
export const createCodeDescriptionsVectorstore = async () => {
  if (!vectorstore) {
    vectorstore = new MemoryVectorStore(getEmbeddings());
    waitForVectorstore = (async () => {
      await ingestPartDesciptions(vectorstore);
      await ingestLaborCodeDescriptions(vectorstore);
    })();
  } else {
    console.debug("Vectorstore already ingested");
  }
};

/**
 * Parameters for searching descriptions in the vector store.
 */
interface SearchCodeDescriptionsParams {
  /** The query string to search for. */
  query: string;
  /** The number of results to return. */
  numResults: number;
  /** The source of descriptions to search in. */
  source: CodeDescriptionSourceOptions;
  /** The offset for the search results. */
  offset?: number;
}

/**
 * Searches the vector store for descriptions matching the query.
 *
 * @param {SearchCodeDescriptionsParams} params - The parameters for the search.
 * @returns {Promise<Document[]>} The search results.
 */
export const searchCodeDescriptionsVectorstore = async ({
  query,
  numResults,
  source,
  offset = 0,
}: SearchCodeDescriptionsParams): Promise<Document[]> => {
  if (waitForVectorstore) {
    console.debug("Waiting for vectorstore to be ready");
    await waitForVectorstore;
  }

  console.debug("Searching descriptions vectorstore", {
    query,
    numResults,
    source,
    offset,
  });

  const allResults = await vectorstore.similaritySearch(
    query,
    offset + numResults,
    (doc) => doc.metadata.source === source
  );

  return allResults.slice(offset, offset + numResults);
};
