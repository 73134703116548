import React, { useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { HiChevronDown } from "react-icons/hi";

import { deleteIssue, Issue } from "shared/api/issues/api";
import { useConfigContext } from "shared/contexts/ConfigContext";

import DeleteAction from "features/ui/DeleteAction";
import DropdownSelect, {
  DropdownSelectOption,
} from "features/ui/DropdownSelect/DropdownSelect";
import PermissionsSettingsAction from "features/ui/PermissionsDialog/PermissionsSettingsAction";

import { routes } from "services/routes";

import { IssueRequestKeys } from "./types";

interface Props extends IssueRequestKeys {
  issue: Issue;
}

const PERMISSIONS_OPTION_ID = "permissions";
const DELETE_OPTION_ID = "delete_issue";

const useDropdownOptions = (rbac: boolean, canEdit: boolean) => {
  const dropdownOptions: DropdownSelectOption[] = [];
  if (rbac) {
    dropdownOptions.push({
      id: PERMISSIONS_OPTION_ID,
      value: "Manage Permissions",
    });
  }

  if (canEdit) {
    dropdownOptions.push({
      id: DELETE_OPTION_ID,
      value: "Delete",
      classNames: "text-red-500",
      testId: DELETE_OPTION_ID,
    });
  }

  return dropdownOptions;
};

const IssueActions = ({ issue, issueRequestKeys }: Props) => {
  const [selectedAction, setSelectedAction] = useState("");

  const { rbac: rbacFF } = useFlags();
  const {
    pages: { rbac },
  } = useConfigContext();
  const rbacEnabled = rbacFF && rbac;

  const canEdit = !rbacEnabled || (rbacEnabled && issue.canEdit);

  return (
    <>
      <DropdownSelect
        label="Manage Issue"
        options={useDropdownOptions(rbacEnabled, canEdit)}
        icon={<HiChevronDown />}
        onSelect={(opt) => setSelectedAction(opt.id as string)}
        testId="issue-actions-dropdown"
      />
      {selectedAction === PERMISSIONS_OPTION_ID && (
        <PermissionsSettingsAction
          entity="issue"
          entityId={issue.ID}
          entityName={issue.name}
          permissions={issue.access}
          canEdit={issue.canEdit}
          showButton={false}
          defaultOpen={true}
          onClose={() => setSelectedAction("")}
          entityRequestKeys={issueRequestKeys}
        />
      )}
      {selectedAction === DELETE_OPTION_ID && (
        <DeleteAction
          data={issue}
          entityName="issue"
          deleteCallback={deleteIssue}
          showButton={false}
          defaultOpen={true}
          onClose={() => setSelectedAction("")}
          onSuccessNavigateToRoute={routes.issues}
          entityRequestKeys={issueRequestKeys}
        />
      )}
    </>
  );
};

export default IssueActions;
