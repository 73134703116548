import { useState } from "react";

import { EventTypeEnum } from "shared/types";

import { TOP_CONTRIBUTORS_TAB_KEY } from "pages/constants";
import {
  getDefaultByGroupBySelectOption,
  useGroupBy,
  useTopContributorsChartSettings,
} from "pages/hooks";
import { RepairAnalyticsProps } from "pages/RepairAnalytics/RepairAnalyticsTabs";
import { sortGroupByOptions } from "pages/shared/analytics/utils";
import TopContributorsGroupBySelector from "pages/shared/TopContributorsGroupBySelector";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";

import TopChart from "./TopChart";
import TopContributorsTable from "./TopContributorsTable";

// TODO: we shouldn't set defaults in code as these can be removed from the API.
// If changing this, make sure to also adjust in claims, repairs & signal event analytics
const DEFAULT_GROUP_BY_ATTRIBUTE = "status";

const REPAIR_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY =
  "repairAnTopContrChartOptions";

const REPAIR_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY =
  "repairAnalyticsTopContGroupByOptions";

const TopContributors = ({
  repairsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
}: RepairAnalyticsProps) => {
  const {
    actions,
    selectedOptions,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
    handleSelectedOptionsChange,
  } = useTopContributorsChartSettings(
    repairsFiltersFilterSortState,
    REPAIR_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
    EventTypeEnum.REPAIR
  );

  const {
    groupBySelectOptions,
    selectedGroupByAttribute,
    handleGroupByAttributeChange,
  } = useGroupBy(
    EventTypeEnum.REPAIR,
    repairsFiltersFilterSortState,
    DEFAULT_GROUP_BY_ATTRIBUTE,
    REPAIR_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
    TOP_CONTRIBUTORS_TAB_KEY
  );

  const repairsFilters = repairsFiltersFilterSortState?.filtersWithQuickFilters;
  const vehiclesFilters =
    vehiclesFiltersFilterSortState?.filtersWithQuickFilters;

  const [generalFilters, setGeneralFilters] = useState<
    FilterGroupState | undefined
  >();

  const sortedGroupBySelectOptions = sortGroupByOptions(groupBySelectOptions);

  const resetState = () => {
    handleGroupByAttributeChange(
      getDefaultByGroupBySelectOption(
        sortedGroupBySelectOptions,
        DEFAULT_GROUP_BY_ATTRIBUTE
      )
    );
    repairsFiltersFilterSortState.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <TopContributorsGroupBySelector
        groupBySelectOptions={sortedGroupBySelectOptions}
        selectedGroupByAttribute={selectedGroupByAttribute}
        setSelectedGroupByAttribute={handleGroupByAttributeChange}
      />
      <TopChart
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        repairsFilters={repairsFilters}
        filters={generalFilters}
        onRepairsFiltersChange={
          repairsFiltersFilterSortState?.manageFilterChange
        }
        onVehiclesFiltersChange={
          vehiclesFiltersFilterSortState?.manageFilterChange
        }
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleSelectedOptionsChange}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
        onBadRequest={resetState}
      />
      <TopContributorsTable
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        repairsFilters={repairsFilters}
        onFiltersUpdated={setGeneralFilters}
        onBadRequest={resetState}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
      />
    </>
  );
};

export default TopContributors;
