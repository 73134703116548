import classNames from "classnames";
import { AiOutlineCheck } from "react-icons/ai";
import { MenuItem, Stack } from "@mui/material";

interface DuckTranslateMenuOptionProps {
  text: string;
  selected: boolean;
  handleClick: () => void;
}

const DuckTranslateMenuOption = ({
  text,
  selected,
  handleClick,
}: DuckTranslateMenuOptionProps) => (
  <MenuItem
    onClick={handleClick}
    selected={selected}
    sx={{
      "&.Mui-selected": {
        backgroundColor: "white",
      },
      "&:hover": {
        backgroundColor: "aliceblue",
      },
    }}
  >
    <Stack direction="row" alignItems="center" spacing={1}>
      <AiOutlineCheck
        className={classNames("text-blue-400", {
          "opacity-0": !selected,
        })}
      />
      <p>{text}</p>
    </Stack>
  </MenuItem>
);

export default DuckTranslateMenuOption;
