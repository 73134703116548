// TODO: we shouldn't set defaults in code as these can be removed from the API.
// If changing this, make sure to also adjust in claims, repairs & signal event analytics
export const DEFAULT_GROUP_BY_ATTRIBUTE = "laborCode";

export const CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY =
  "claimAnalyticsTopContChartOptions";
// We manage the state of the group by options with a separate key from the rest of
// the chart options because they are not part of the chart options, and are set through
// a different mechanism.
export const CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY =
  "claimAnalyticsTopContGroupByOptions";
