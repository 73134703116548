import classNames from "classnames";
import { Change, diffWords } from "diff";
import { AiOutlineArrowRight } from "react-icons/ai";
import Skeleton from "react-loading-skeleton";
import { Props } from "react-rnd";
import { generatePath, Link } from "react-router-dom";

import {
  FieldUpdateResponse,
  IssueUpdateResponse,
} from "shared/api/issues/api";
import { useGetIssueActivity } from "shared/api/issues/hooks";
import { useOrderedValue } from "shared/api/orderedValues/hooks";
import { useGroup } from "shared/api/rbac/hooks";
import useIssuesSchema from "shared/schemas/issuesSchema";

import APIError from "features/ui/APIError";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { FilterDiffData, FilterDiffResult } from "features/ui/Filters/types";
import { diffFilterGroupStates } from "features/ui/Filters/utils";
import RichTextEditor from "features/ui/RichTextEditor/RichTextEditor";

import { routes } from "services/routes";

import {
  ADDED_COLOR,
  DELETED_COLOR,
  EMPTY_ACTIVITY_ITEM_VALUE,
} from "./constants";
import {
  ClaimFilterPresenter,
  PopulationFilterPresenter,
  SignalEventFilterPresenter,
} from "./IssuePresenters";
import { getFieldLabel, getNonEmptyFieldValue, getStyledSpan } from "./utils";

const AssignedGroupComponent = ({ assignedGroupId }: any) => {
  const { data, isLoading, error } = useGroup({
    id: assignedGroupId as string,
  });
  if (isLoading) {
    return <Skeleton height={300} count={2} />;
  }

  if (error) {
    return <Skeleton height={300} count={2} />;
  }

  if (!data) {
    return "n/a";
  }

  if (data) {
    return (
      <Link
        to={generatePath(routes.group, {
          id: assignedGroupId,
        })}
        className="text-metabase-blue hover:underline"
      >
        {data.name}
      </Link>
    );
  }
};

const OrderedValueComponent = ({ id }: any) => {
  const { data, isLoading, error } = useOrderedValue({
    id: id as string,
  });
  if (isLoading) {
    return <Skeleton height={300} count={2} />;
  }

  if (error) {
    return <Skeleton height={300} count={2} />;
  }

  if (!data) {
    return "n/a";
  }

  if (data) {
    return data.value;
  }
};

const getFieldValueComponent = (field: string, fieldValue: any) => {
  if (fieldValue == null || fieldValue === "") {
    return EMPTY_ACTIVITY_ITEM_VALUE;
  }

  switch (field) {
    case "atRiskPopulationFilter":
      return <PopulationFilterPresenter populationFilter={fieldValue} />;
    case "comparisonPopulationFilter":
      return <PopulationFilterPresenter populationFilter={fieldValue} />;
    case "claimFilter":
      return <ClaimFilterPresenter claimFilter={fieldValue} />;
    case "signalEventOccurrencesFilter":
      return (
        <SignalEventFilterPresenter signalEventOccurrencesFilter={fieldValue} />
      );
    case "promotedFromID":
      return (
        <Link
          to={generatePath(routes.suggestedIssueLatestRun, {
            id: fieldValue,
          })}
          className="text-metabase-blue hover:underline"
        >
          {fieldValue}
        </Link>
      );
    case "statusUpdatedAt":
      return new Date(fieldValue).toLocaleString();
    case "severityID":
    case "statusID":
      return <OrderedValueComponent id={fieldValue}></OrderedValueComponent>;
    case "assignedGroupID":
      return (
        <AssignedGroupComponent
          assignedGroupId={fieldValue}
        ></AssignedGroupComponent>
      );
    case "description":
    case "notes":
      return (
        <div className="min-w-60 min-h-40 max-h-80 border border-gray-200 rounded-md overflow-auto">
          <RichTextEditor
            editing={false}
            content={fieldValue}
            onChange={() => {}}
            testId="issue-activity-rich-text"
          ></RichTextEditor>
        </div>
      );
    default:
      // externalID, assignee
      return fieldValue;
  }
};

const getFieldValueDiffComponent = (
  field: string,
  fieldValue: any,
  isNew: boolean,
  diff: Change[],
  filterDiff: FilterDiffResult
) => {
  const color = isNew ? ADDED_COLOR : DELETED_COLOR;

  if (fieldValue === EMPTY_ACTIVITY_ITEM_VALUE) {
    return getStyledSpan(EMPTY_ACTIVITY_ITEM_VALUE, color);
  }

  const diffData: FilterDiffData = {
    changes: isNew ? filterDiff?.additionChanges : filterDiff?.deletionChanges,
    bgColor: color,
    level: 0,
    index: 0,
  };

  switch (field) {
    case "atRiskPopulationFilter":
    case "comparisonPopulationFilter":
      return (
        <PopulationFilterPresenter
          populationFilter={fieldValue}
          diff={diffData}
        />
      );
    case "claimFilter":
      return <ClaimFilterPresenter claimFilter={fieldValue} diff={diffData} />;
    case "signalEventOccurrencesFilter":
      return (
        <SignalEventFilterPresenter
          signalEventOccurrencesFilter={fieldValue}
          diff={diffData}
        />
      );
    case "promotedFromID":
      return getStyledSpan(
        <Link
          to={generatePath(routes.suggestedIssueLatestRun, {
            id: fieldValue,
          })}
          className="text-metabase-blue hover:underline"
        >
          {fieldValue}
        </Link>,
        color
      );
    case "severityID":
    case "statusID":
      return getStyledSpan(
        <OrderedValueComponent id={fieldValue}></OrderedValueComponent>,
        color
      );
    case "assignedGroupID":
      return getStyledSpan(
        <AssignedGroupComponent
          assignedGroupId={fieldValue}
        ></AssignedGroupComponent>,
        color
      );
    case "assignee":
      return getStyledSpan(fieldValue, color);
    case "description":
    case "notes":
      return (
        <div
          className={classNames(
            "min-w-60 min-h-40 max-h-80 border border-gray-200 rounded-md overflow-auto",
            color
          )}
        >
          <RichTextEditor
            editing={false}
            content={fieldValue}
            onChange={() => {}}
            testId="issue-activity-rich-text"
          ></RichTextEditor>
        </div>
      );
    default:
      // statusUpdatedAt, externalID
      if (isNew)
        return diff.map((part, index) => (
          <span key={`new-${index}`} className={part.added ? color : undefined}>
            {part.added || !part.removed ? part.value : ""}
          </span>
        ));

      return diff.map((part, index) => (
        <span key={`old-${index}`} className={part.removed ? color : undefined}>
          {part.removed || !part.added ? part.value : ""}
        </span>
      ));
  }
};

export const FieldUpdateDiff = ({
  field,
  oldValue,
  newValue,
}: FieldUpdateResponse) => {
  if (oldValue === newValue) {
    // do not highlight any diff changes
    return (
      <div className="flex flex-row items-center">
        <div className="text-viaduct-black min-w-0 max-w-[35vw] truncate">
          {getFieldValueComponent(field, oldValue)}
        </div>
        <div className="mx-4">
          <AiOutlineArrowRight size={15} />
        </div>
        <div className="text-viaduct-black min-w-0 max-w-[35vw] truncate">
          {getFieldValueComponent(field, newValue)}
        </div>
      </div>
    );
  }

  const nonEmptyOldValue = getNonEmptyFieldValue(oldValue, field);
  const nonEmptyNewValue = getNonEmptyFieldValue(newValue, field);

  const diff = diffWords(nonEmptyOldValue, nonEmptyNewValue);

  const oldFilter = field.includes("Filter")
    ? filterBuilderQueryToFilterBuilderState(oldValue)
    : undefined;
  const newFilter = field.includes("Filter")
    ? filterBuilderQueryToFilterBuilderState(newValue)
    : undefined;
  const filterDiff = diffFilterGroupStates(oldFilter, newFilter);

  // todo miha: we could further improve diffing feature by:
  //  having custom logic for date - from the first part to the end we mark the change
  //  handle rich text changes better
  //  handle occurs filter more properly (recursively)

  const diffOldContent = getFieldValueDiffComponent(
    field,
    nonEmptyOldValue,
    false,
    diff,
    filterDiff
  );

  const diffNewContent = getFieldValueDiffComponent(
    field,
    nonEmptyNewValue,
    true,
    diff,
    filterDiff
  );

  return (
    <div className="flex flex-row items-center">
      <div className="text-viaduct-black min-w-0 max-w-[35vw] truncate">
        {diffOldContent}
      </div>
      <div className="mx-4">
        <AiOutlineArrowRight size={15} />
      </div>
      <div className="text-viaduct-black min-w-0 max-w-[35vw] truncate">
        {diffNewContent}
      </div>
    </div>
  );
};

const FieldUpdate = ({ field, oldValue, newValue }: FieldUpdateResponse) => {
  const { schema } = useIssuesSchema();
  let overwrittenField = null;
  if (field === "statusID") {
    overwrittenField = "statusObj";
  }

  if (field === "severityID") {
    overwrittenField = "severityObj";
  }

  return (
    <div className="flex flex-row gap-2 text-base font-medium sm:text-sm whitespace-nowrap">
      <label className="text-gray-500">
        {getFieldLabel(schema, overwrittenField ?? field)}:
      </label>

      <FieldUpdateDiff field={field} oldValue={oldValue} newValue={newValue} />
    </div>
  );
};

const IssueSingleUpdate = (issueUpdate: IssueUpdateResponse) => {
  const updateDate = new Date(issueUpdate.updatedAt).toLocaleString();

  return (
    <>
      <div>
        <b>{issueUpdate.updatedBy}</b> at {updateDate}:
      </div>
      <div className="space-y-1.5 ml-3">
        {issueUpdate.updates.map((fieldUpdate) => FieldUpdate(fieldUpdate))}
      </div>
    </>
  );
};

const IssueActivityTab = ({ issue }: Props) => {
  const { data, isLoading, error } = useGetIssueActivity({
    id: issue.ID as string,
  });

  if (isLoading) {
    return <Skeleton height={300} count={2} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data) {
    return (
      <div className="py-4 text-gray-400 text-sm">
        No activity data yet for Issue.
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-3 ml-4">
      {[...data].reverse().map((i) => IssueSingleUpdate(i))}
    </div>
  );
};

export default IssueActivityTab;
