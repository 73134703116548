import { FiTrash2 as DeleteIcon } from "react-icons/fi";
import { MdDragIndicator as DragIcon } from "react-icons/md";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import IconButton from "@mui/material/IconButton";

import { SchemaEntry } from "features/ui/Table";

import { VehicleImageState } from "./types";

interface SortableItemProps {
  image: VehicleImageState;
  onDelete: (id: string) => void;
  schema: SchemaEntry[];
  sortIndex: number;
}

const VehicleImageRow = ({ image, onDelete }: SortableItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: image.id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div className="flex items-center gap-4 p-4" ref={setNodeRef} style={style}>
      <DragIcon
        {...attributes}
        {...listeners}
        size={22}
        className="cursor-grab hover:text-black select-none shrink-0 mt-2"
        data-testid={`rb-rule-drag-handle-${image.id}`}
      />
      <div className="w-64 h-32 shrink-0">
        <img
          src={`data:${image.contentType};base64,${image.imageData}`}
          alt="Vehicle"
          className="w-full h-full object-contain rounded-sm"
        />
      </div>
      <div className="flex w-full justify-between">
        <div>
          {image.vehicleMake && (
            <div>
              <strong>Vehicle Make:</strong> {image.vehicleMake}
            </div>
          )}
          {image.vehicleModel && (
            <div>
              <strong>Vehicle Model</strong>: {image.vehicleModel}
            </div>
          )}
          {image.vehicleModelYear && (
            <div>
              <strong>Vehicle Model Year:</strong> {image.vehicleModelYear}
            </div>
          )}
          {image.trimLevel && (
            <div>
              <strong>Trim Level:</strong> {image.trimLevel}
            </div>
          )}
        </div>
        <div>
          <IconButton
            onClick={() => onDelete(image.id)}
            color="error"
            size="small"
            className="ml-auto"
          >
            <DeleteIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default VehicleImageRow;
