import DuckLogo from "duck/ui/DuckLogo";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useChatContext } from "@copilotkit/react-ui";
import { IconButton, Tooltip } from "@mui/material";

const DuckAction = () => {
  const { setOpen } = useChatContext();
  const { duckRightPanel } = useFlags();

  if (!duckRightPanel) {
    return null;
  }

  return (
    <Tooltip title="ViaDuck Assistant" placement="bottom-end" arrow={true}>
      <IconButton onClick={() => setOpen(true)}>
        <DuckLogo />
      </IconButton>
    </Tooltip>
  );
};

export default DuckAction;
