import { APISuccessResponse } from "shared/api/api";
import {
  Issue,
  NewIssueRequest,
  UpdateIssueRequest,
} from "shared/api/issues/api";

import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { getPageKeyWithVersion } from "features/ui/Filters/utils";
import { calculatePermissionToSubmit } from "features/ui/PermissionsDialog/utils";
import { ActionType } from "features/ui/StepperModal";

import {
  ISSUE_AT_RISK_POPULATION_FILTER_PAGE_KEY,
  ISSUE_CLAIMS_FILTER_PAGE_KEY,
  ISSUE_COMPARISON_POPULATION_FILTER_PAGE_KEY,
  ISSUE_SIGNAL_EVENT_FILTER_PAGE_KEY,
} from "./constants";

export type ActionCallback<T> = T extends "create"
  ? (d: NewIssueRequest) => Promise<APISuccessResponse<Issue>>
  : T extends "update"
    ? (d: UpdateIssueRequest) => Promise<APISuccessResponse<Issue>>
    : never;

type ActionProps<T> = T extends "create"
  ? NewIssueRequest
  : T extends "update"
    ? UpdateIssueRequest
    : never;

export const prepareRequestData = (
  data: Record<string, any>,
  action: ActionType,
  myEmail: string
): ActionProps<ActionType> => {
  const {
    atRiskPopulationFilter,
    comparisonPopulationFilter,
    claimFilter,
    signalEventOccurrencesFilter,
    ID,
    acl,
    assignee,
    ...otherData
  } = data;

  const requestData = {
    ...otherData,
    ID,
    atRiskPopulationFilter: getFiltersQuery(atRiskPopulationFilter),
    comparisonPopulationFilter: getFiltersQuery(comparisonPopulationFilter),
    claimFilter: getFiltersQuery(claimFilter),
    signalEventOccurrencesFilter: getFiltersQuery(signalEventOccurrencesFilter),
    assignee,
    acl: calculatePermissionToSubmit(acl, [assignee, myEmail]),
  };

  return removeFalsyFromRecord(requestData, action) as ActionProps<
    typeof action
  >;
};

const UNSUPPORTED_PATCH_FIELDS = new Set<string>(["acl"]);

export const removeFalsyFromRecord = (
  obj: Record<string, any>,
  action: ActionType
) => {
  let newObj: Record<string, any> = {};
  Object.keys(obj)
    .filter((k) => action !== "update" || !UNSUPPORTED_PATCH_FIELDS.has(k))
    .forEach((key) => {
      if (obj[key]) {
        newObj[key] = obj[key];
      }

      if (!obj[key] && action === "update") {
        newObj[key] = null;
      }
    });

  return newObj;
};

const PENDING_FILTERS_LS_KEYS = [
  ISSUE_AT_RISK_POPULATION_FILTER_PAGE_KEY,
  ISSUE_COMPARISON_POPULATION_FILTER_PAGE_KEY,
  ISSUE_CLAIMS_FILTER_PAGE_KEY,
  ISSUE_SIGNAL_EVENT_FILTER_PAGE_KEY,
].map((pageKey) => getPendingFiltersKey(pageKey));

export const clearPendingIssueFilters = () => {
  PENDING_FILTERS_LS_KEYS.forEach((pendingFilterLSKey) => {
    localStorage.removeItem(getPageKeyWithVersion(pendingFilterLSKey));
  });
};
