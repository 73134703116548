import Skeleton from "react-loading-skeleton";
import { useNavigate, useParams } from "react-router";

import { useCustomSignalEventDefinition } from "shared/api/customSignalEvents/hooks";

import APIError from "features/ui/APIError";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

import { routes } from "services/routes";

import CustomSignalEventForm from "./CustomSignalEventForm";
import { CustomSignalEventParams } from "./types";

const CustomSignalEvent = () => {
  const navigate = useNavigate();
  const { id } = useParams<CustomSignalEventParams>();

  const { data, isLoading, error, requestKey } = useCustomSignalEventDefinition(
    {
      id: id as string,
    }
  );

  if (isLoading) {
    return <Skeleton height={300} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  if (!data) {
    navigate(routes.customSignalEvents);

    return;
  }

  return (
    <>
      <PageHeaderWrapper title={data.name} />
      <CustomSignalEventForm
        customSignalEventDefinition={data}
        requestKey={requestKey}
      />
    </>
  );
};

export default CustomSignalEvent;
