import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { jwtDecode } from "jwt-decode";
import useLocalStorageState, {
  LocalStorageOptions,
} from "use-local-storage-state";
import { useOktaAuth } from "@okta/okta-react";

import { getDefaultRelatedSignalEventsTimePeriod } from "pages/Issues/utils";

import { FilterSchemaItem } from "features/ui/Filters/types";
import { getPageKeyWithVersion } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";

import { DEFAULT_MILEAGE_UNIT } from "./constants";
import { useConfigContext } from "./contexts/ConfigContext";
import { useClaimsSchema } from "./schemas/claimsSchema";
import { useCustomRecordsSchema } from "./schemas/customRecordsSchema";
import { useInspectionsSchema } from "./schemas/inspectionsSchema";
import useIssuesSchema from "./schemas/issuesSchema";
import { useRepairsSchema } from "./schemas/repairsSchema";
import useSensorReadingsSchema from "./schemas/sensorReadingsSchema";
import useServiceRecommendationsSchema from "./schemas/serviceRecommendationsSchema";
import useSignalEventOccurrencesSchema from "./schemas/signalEventOccurrencesSchema";
import useVehiclesSchema from "./schemas/vehiclesSchema";
import {
  EventTypeEnum,
  EventTypeEnumCapitalized,
  EventTypeEnumCapitalizedType,
  JWT,
  MileageUnit,
} from "./types";

export const useDebounce = <T>(value: T, delay: number): T => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler);
      };
    },
    [value, delay] // Only re-call effect if value or delay changes
  );

  return debouncedValue;
};

/**
 * From https://gist.github.com/Danziger/336e75b6675223ad805a88c2dfdcfd4a
 * and modified to use dependencies also.
 * Example use:
 * useTimeout(() => setShowTooltip(false), HIDE_TOOLTIP_AFTER_MS, [showTooltip]);
 */
export const useTimeout = (
  callback: React.EffectCallback,
  delay: number | null,
  dependencies: any[] = []
): React.MutableRefObject<number | null> => {
  const timeoutRef = useRef<number | null>(null);
  const callbackRef = useRef(callback);
  const dependenciesStr = JSON.stringify(dependencies);

  // Remember the latest callback:
  //
  // Without this, if you change the callback, when setTimeout kicks in, it
  // will still call your old callback.
  //
  // If you add `callback` to useEffect's deps, it will work fine but the
  // timeout will be reset.

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  // Set up the timeout:
  useEffect(() => {
    if (typeof delay === "number") {
      timeoutRef.current = window.setTimeout(
        () => callbackRef.current(),
        delay
      );

      // Clear timeout if the components is unmounted or the delay or dependencies change:
      return () => window.clearTimeout(timeoutRef.current || 0);
    }
  }, [delay, dependenciesStr]);

  // In case you want to manually clear the timeout from the consuming component...:
  return timeoutRef;
};

// From https://usehooks.com/useWindowSize/, adjusted
export const useWindowSize = (debounce: number = 500) => {
  const [windowSize, setWindowSize] = useState<{
    width?: number;
    height?: number;
  }>({
    width: undefined,
    height: undefined,
  });

  const debouncedWindowSize = useDebounce(windowSize, debounce);

  useLayoutEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return debouncedWindowSize;
};

export const useEmailFromJWT = (): string => {
  const { oktaAuth } = useOktaAuth();
  const accessToken = oktaAuth.getAccessToken() || "";

  if (!accessToken) return "";

  const { sub: email }: JWT = jwtDecode(accessToken);

  return (email as string).toLowerCase() || "";
};

export const useClaimsFiltersSchema = (): SchemaEntry[] =>
  // we currently take all cols from Claims table and filter out the ones that don't have a filter
  useClaimsSchema().schema.filter(
    ({ filter, hideFilter }) => filter && !hideFilter
  );

export const useInspectionsFiltersSchema = (): SchemaEntry[] =>
  useInspectionsSchema().schema.filter(
    ({ filter, hideFilter }) => filter && !hideFilter
  );

export const useRepairsFiltersSchema = (): SchemaEntry[] =>
  useRepairsSchema().schema.filter(
    ({ filter, hideFilter }) => filter && !hideFilter
  );

export const useCustomRecordsFiltersSchema = (): SchemaEntry[] =>
  // we currently take all cols from Custom Records table and filter out the ones that don't have a filter
  useCustomRecordsSchema().schema.filter(
    ({ filter, hideFilter }) => filter && !hideFilter
  );

export const useIssuesFiltersSchema = (): SchemaEntry[] =>
  useIssuesSchema().schema.filter(
    ({ filter, hideFilter }) => filter && !hideFilter
  );

interface UseFiltersSchemaProps {
  disableIsNotFilteredFilters?: boolean;
}

export const useVehiclesFiltersSchema = (
  options?: UseFiltersSchemaProps,
  accessorsToHide: string[] = []
): SchemaEntry[] => {
  const { disableIsNotFilteredFilters } = options || {};

  const { schema: vehicleAttributesSchema } =
    useVehiclesSchema(accessorsToHide);

  return vehicleAttributesSchema
    .filter(
      ({ filter, hideFilter }: SchemaEntry) => Boolean(filter) && !hideFilter
    )
    .map((schema) => ({
      ...schema,
      filter: {
        ...schema.filter,
        disableIsNotFilteredFilters,
      } as FilterSchemaItem,
    }));
};

export const useServiceRecommendationsFiltersSchema = (
  options?: UseFiltersSchemaProps
): SchemaEntry[] => {
  const { disableIsNotFilteredFilters } = options || {};

  const { schema } = useServiceRecommendationsSchema();

  return schema
    .filter(({ filter, hideFilter }: SchemaEntry) => filter && !hideFilter)
    .map((schema) => ({
      ...schema,
      filter: {
        ...schema.filter,
        disableIsNotFilteredFilters,
      } as FilterSchemaItem,
    }));
};

export const useSignalEventOccurrencesFiltersSchema = (
  accessorsToHide: string[] = []
): SchemaEntry[] => {
  const { schema } = useSignalEventOccurrencesSchema(accessorsToHide);

  return schema.filter(
    ({ filter, hideFilter }: SchemaEntry) => filter && !hideFilter
  );
};

export const useSensorReadingsFiltersSchema = (): SchemaEntry[] =>
  useSensorReadingsSchema().schema.filter(
    ({ filter, hideFilter }) => filter && !hideFilter
  );

export const useFlexibleTimePeriod = (key: string) =>
  useCustomLocalStorageState(key, {
    defaultValue: getDefaultRelatedSignalEventsTimePeriod().id,
  });

export const useFilterSchemaForType = (
  type?: EventTypeEnum | EventTypeEnumCapitalizedType
): SchemaEntry[] | undefined => {
  const signalEventOccurrencesSchema = useSignalEventOccurrencesFiltersSchema();
  const claimsSchema = useClaimsFiltersSchema();
  const sensorReadingsSchema = useSensorReadingsFiltersSchema();
  const repairsSchema = useRepairsFiltersSchema();
  const inspectionsSchema = useInspectionsFiltersSchema();
  const customRecordsSchema = useCustomRecordsFiltersSchema();

  switch (type) {
    case EventTypeEnum.CLAIM:
    case EventTypeEnumCapitalized.CLAIM:
      return claimsSchema;
    case EventTypeEnum.SIGNAL_EVENT:
    case EventTypeEnumCapitalized.SIGNAL_EVENT:
      return signalEventOccurrencesSchema;
    case EventTypeEnum.SENSOR_READING:
    case EventTypeEnumCapitalized.SENSOR_READING:
      return sensorReadingsSchema;
    case EventTypeEnum.REPAIR:
    case EventTypeEnumCapitalized.REPAIR:
      return repairsSchema;
    case EventTypeEnum.INSPECTION:
    case EventTypeEnumCapitalized.INSPECTION:
      return inspectionsSchema;
    case EventTypeEnum.CUSTOM_RECORD:
    case EventTypeEnumCapitalized.CUSTOM_RECORD:
      return customRecordsSchema;
  }
};

/**
 * Just a custom wrapper around useLocalStorageState that adds a version suffix to the key
 * so we can bump all keys in our app at once.
 * Always use this instead of useLocalStorageState.
 */
export const useCustomLocalStorageState = <T>(
  key: string,
  options: LocalStorageOptions<T>
) =>
  useLocalStorageState<T>(getPageKeyWithVersion(key), {
    ...options,
    defaultValue: options.defaultValue ?? undefined,
  });

export const useTenantMileageUnit = (): MileageUnit => {
  const { general, loaded } = useConfigContext();

  // This should never be able to happen, so we raise an error
  if (!loaded)
    throw new Error("Can't get mileageUnit for tenant: config not loaded");

  return general?.mileageUnit ?? DEFAULT_MILEAGE_UNIT;
};

/**
 * Creates a key binding and listen for it until unmounted.
 *
 * @param {string[]} keys - Combination of keys to listen for.
 * Examples:  ["ctrl", "k"], ["ArrowUp"], ["ArrowDown", "Enter"]
 *
 * @param {string} callback - Function to be called when the key combination is pressed.
 * @param {boolean} capture -  catch the event during the capture phase.
 */
export const useKeyBinding = (
  keys: string[],
  callback: (...args: any[]) => void,
  capture: boolean = false
): void => {
  const handleNumberKey = (event: KeyboardEvent) => {
    const integer = parseInt(event.code[event.code.length - 1]);
    if (Number.isNaN(integer)) return event.key;

    return integer.toString();
  };

  const handleKeyPress = useCallback(
    (event: KeyboardEvent) => {
      const lowerCaseKeys = keys.map((key) => key.toLowerCase());
      const eventKey = handleNumberKey(event).toLowerCase();

      // Handle combination keys (cmd/ctrl + key)
      if (
        (lowerCaseKeys.includes("ctrl") || lowerCaseKeys.includes("cmd")) &&
        lowerCaseKeys.length > 1
      ) {
        if (
          (event.metaKey || event.ctrlKey) &&
          lowerCaseKeys.includes(eventKey)
        ) {
          event.preventDefault();
          callback();

          return;
        }
      }

      // Handle combination keys (alt/opt + key)
      if (
        (lowerCaseKeys.includes("alt") || lowerCaseKeys.includes("opt")) &&
        lowerCaseKeys.length > 1
      ) {
        // don't trigger on alt since the requirement is more than 1 key
        if (eventKey === "alt" || eventKey === "opt") return;

        if (event.altKey && lowerCaseKeys.includes(eventKey)) {
          event.preventDefault();
          callback();

          return;
        }
      }

      // Handle single key press
      if (
        lowerCaseKeys.includes(event.key.toLowerCase()) &&
        lowerCaseKeys.length === 1
      ) {
        callback();
      }
    },
    [keys, callback]
  );

  useEffect(() => {
    if (!keys.length) return;

    window.addEventListener("keydown", handleKeyPress, capture);

    return () => {
      window.removeEventListener("keydown", handleKeyPress, capture);
    };
  }, [handleKeyPress, capture, keys]);
};
