import { useFlags } from "launchdarkly-react-client-sdk";
import qs from "qs";
import { Link } from "react-router-dom";

import { getFilterQuery } from "shared/api/utils";
import { useConfigContext } from "shared/contexts/ConfigContext";

import {
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";

import { FilterOperator } from "features/ui/Filters/types";
import { getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";

interface Props {
  attribute: string;
  rawValue?: any;
  value: any;
}

const ClaimAnalyticsLink = ({ attribute, rawValue, value }: Props) => {
  const { claimAnalytics } = useFlags();
  const { pages } = useConfigContext();

  if (!claimAnalytics || !pages.claimAnalytics) {
    return value;
  }

  const claimAnalyticsFilterKey = getFiltersKey(CLAIMS_PAGE_KEY);
  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);

  const valueForFilter = rawValue || value;
  const valuesForFilter = Array.isArray(valueForFilter)
    ? valueForFilter
    : [valueForFilter];

  if (valuesForFilter.length === 0 || !value) {
    return value;
  }

  return (
    <Link
      className="text-blue-400 hover:underline"
      target="_blank"
      to={{
        pathname: routes.claimAnalytics,
        search: qs.stringify({
          [claimAnalyticsFilterKey]: getFilterQuery({
            type: "row",
            id: "",
            attribute,
            operator: FilterOperator.IN,
            values: valuesForFilter,
          }),
          [vehicleFilterKey]: "",
        }),
      }}
    >
      {value}
    </Link>
  );
};

export default ClaimAnalyticsLink;
