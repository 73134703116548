import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import HeadingRenderer from "pages/Docs/HeadingRenderer";

import "pages/Docs/Docs.css";

interface MarkdownSectionProps {
  content: string;
  wrapperClasses?: string;
}

const MarkdownSection = ({
  content,
  wrapperClasses = "",
}: MarkdownSectionProps) => (
  <div className={`Docs ${wrapperClasses}`}>
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      children={content}
      components={{
        h1: HeadingRenderer,
        h2: HeadingRenderer,
        h3: HeadingRenderer,
        h4: HeadingRenderer,
        h5: HeadingRenderer,
      }}
    />
  </div>
);

export default MarkdownSection;
