import { CHAT_END_EVENT } from "duck/context/constants";
import { GPT4O_MODEL_SPEC } from "duck/graph/constants";
import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import { NodeOutputType, NodeType } from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import { DuckGraphParams, NonEmptyStringArray } from "duck/graph/types";
import { getLLM, ToolCallRoutableNodeNames } from "duck/graph/utils";
import {
  assertNonEmptyStringArray,
  toEncodedNonEmptyStringArray,
} from "duck/ui/utils";
import { AIMessage, AIMessageChunk } from "@langchain/core/messages";
import { RunnableConfig } from "@langchain/core/runnables";

import { attributePickerAgent } from "./attributePickerAgent";
import {
  MORE_INFO_MESSAGE,
  NO_ATTRIBUTES_RECOMMENDED_MESSAGE,
  NO_OPTIMAL_SPLIT_MESSAGE,
} from "./constants";
import { AttributeEntropy, DrillNodeOptions, DrillNodeType } from "./types";
// Import the two versions of compute entropy
import {
  computeClaimsEntropy,
  computeSEEntropy,
  generateFilterSummaryTextWithDescriptions,
  listFilterAttributes,
} from "./utils";

const createDrillTopContributorsNode = async (
  params: DuckGraphParams,
  options: DrillNodeOptions
): Promise<NodeType> => {
  const { setEphemeralMessage, setAgentResponse, handleStreamEvent } =
    params.uiHandlers;

  const prompt = await loadPrompt(promptNames.DRILL_TOP_CONTRIBUTORS_AGENT);
  const llm = getLLM(GPT4O_MODEL_SPEC);
  const agent = prompt.pipe(llm);

  const selectedGroupByVehicleAttributes =
    params.selectedGroupByVehicleAttributes;

  // Extract filter query strings based on the node type.
  const { filterQueryString, vehiclesFilterQueryString } =
    options.extractFilterQueryStrings(params.currentState);

  const shouldPickAttributes =
    !selectedGroupByVehicleAttributes ||
    selectedGroupByVehicleAttributes.length === 0;

  const vehicleAttributesInFilter = listFilterAttributes(
    vehiclesFilterQueryString
  );

  const { topContributorsGroupBySelectOptions } =
    params.availableData.claimAnalytics;
  const topContributorsGroupByOptions = toEncodedNonEmptyStringArray(
    topContributorsGroupBySelectOptions
  );
  // Filter out vehicle attributes that are already in the filter
  const filteredGroupByOptions = topContributorsGroupByOptions
    .filter((attr) => attr.startsWith("vehicle.") && !attr.includes("present"))
    .filter(
      (attr) =>
        !vehicleAttributesInFilter.some(
          (filterAttr) => attr === `vehicle.${filterAttr}`
        )
    );

  const filterSummaryDescription =
    await generateFilterSummaryTextWithDescriptions(filterQueryString);

  return async (
    _: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    console.debug(`${options.type} DrillTopContributors`);

    // Helper function to call the agent and stream its output.
    const callAgent = async (
      attributeEntropyList: AttributeEntropy[],
      totalAttributes: number
    ): Promise<AIMessage> => {
      if (!attributeEntropyList.length) {
        setAgentResponse(NO_OPTIMAL_SPLIT_MESSAGE);

        return new AIMessage(NO_OPTIMAL_SPLIT_MESSAGE);
      }

      const stream = agent.streamEvents(
        {
          entropyMap: JSON.stringify(attributeEntropyList.slice(0, 20)),
          totalAttributes,
        },
        { version: "v2", ...config }
      );

      let finalMessage: AIMessageChunk | undefined;
      for await (const event of stream) {
        handleStreamEvent(event);
        if (event.event === CHAT_END_EVENT) {
          finalMessage = event.data.output;
        }
      }

      if (!finalMessage) {
        throw new Error("Final message is undefined");
      }

      setAgentResponse(MORE_INFO_MESSAGE, { suppressFeedback: true });

      return new AIMessage(finalMessage.content.toString(), {
        name: options.nodeName,
      });
    };

    let candidateAttributes: NonEmptyStringArray;
    const attributeLabelMapping: Map<string, string> = new Map();
    if (shouldPickAttributes) {
      setEphemeralMessage("Finding optimal dimensions...");
      const { recommendedAttributes } = await attributePickerAgent(
        filterSummaryDescription,
        filteredGroupByOptions,
        config
      );
      try {
        assertNonEmptyStringArray(recommendedAttributes);
        candidateAttributes = recommendedAttributes;
        console.debug("Recommended attributes:", recommendedAttributes);

        // create a map of filteredGroupByOption ids to their values
        for (const { id, value } of topContributorsGroupBySelectOptions) {
          attributeLabelMapping.set(id.toString(), value.toString());
        }
      } catch (e) {
        console.error("Agent returned no attributes", e);
        setAgentResponse(NO_ATTRIBUTES_RECOMMENDED_MESSAGE);

        return {
          messages: [new AIMessage(NO_ATTRIBUTES_RECOMMENDED_MESSAGE)],
        };
      }
    } else {
      candidateAttributes = toEncodedNonEmptyStringArray(
        selectedGroupByVehicleAttributes
      );

      for (const { id, value } of selectedGroupByVehicleAttributes) {
        attributeLabelMapping.set(id.toString(), value.toString());
      }
    }

    setEphemeralMessage("Optimizing Splits...");

    console.debug("Computing entropy for selected attributes", {
      candidateAttributes,
      filters: options.constructFilterArgs(
        filterQueryString,
        vehiclesFilterQueryString
      ),
      attributeLabelMapping,
    });

    // Compute entropy for all selected attributes.
    const attributeEntropyList = await options.computeEntropyFn(
      candidateAttributes,
      options.constructFilterArgs(filterQueryString, vehiclesFilterQueryString),
      attributeLabelMapping
    );

    console.debug("Calling agent with entropy list", attributeEntropyList);

    return {
      messages: [
        await callAgent(attributeEntropyList, candidateAttributes.length),
      ],
    };
  };
};

// Export the claims version by calling the factory with appropriate options.
export const getClaimsDrillTopContributorsNode = async (
  params: DuckGraphParams
): Promise<NodeType> =>
  createDrillTopContributorsNode(params, {
    type: DrillNodeType.CLAIMS,
    computeEntropyFn: computeClaimsEntropy,
    extractFilterQueryStrings: (currentState) => {
      const { claimsFilterQueryString, vehiclesFilterQueryString } =
        currentState.claimAnalytics;

      return {
        filterQueryString: claimsFilterQueryString,
        vehiclesFilterQueryString,
      };
    },
    constructFilterArgs: (filterQueryString, vehiclesFilterQueryString) => ({
      claimsFilter: filterQueryString,
      vehiclesFilter: vehiclesFilterQueryString,
    }),
    nodeName: ToolCallRoutableNodeNames.CLAIMS_DRILL_TOP_CONTRIBUTORS,
  });

// Export the SE version by calling the factory with the SE–specific options.
export const getSEDrillTopContributorsNode = async (
  params: DuckGraphParams
): Promise<NodeType> =>
  createDrillTopContributorsNode(params, {
    type: DrillNodeType.SIGNAL_EVENTS,
    computeEntropyFn: computeSEEntropy,
    extractFilterQueryStrings: (currentState) => {
      const { signalEventsFilterQueryString, vehiclesFilterQueryString } =
        currentState.signalEventAnalytics;

      return {
        filterQueryString: signalEventsFilterQueryString,
        vehiclesFilterQueryString,
      };
    },
    constructFilterArgs: (filterQueryString, vehiclesFilterQueryString) => ({
      signalEventOccurrencesFilter: filterQueryString,
      vehiclesFilter: vehiclesFilterQueryString,
    }),
    nodeName: ToolCallRoutableNodeNames.SE_DRILL_TOP_CONTRIBUTORS,
  });
