import { useRef } from "react";
import classNames from "classnames";
import DuckNest from "duck/ui/DuckNest";
import { ReactComponent as Logo } from "imgs/ViaductLogoBlack.svg";
import { useFlags } from "launchdarkly-react-client-sdk";
import qs from "qs";
import { AiOutlineHome as HomeIcon } from "react-icons/ai";
import { FiGift as GiftIcon } from "react-icons/fi";
import { IoHelpCircleOutline as HelpIcon } from "react-icons/io5";
import { MdChevronLeft as ChevronLeft } from "react-icons/md";
import { Link } from "react-router-dom";

import { useConfigContext } from "shared/contexts/ConfigContext";

import { DOCS_RELEASE_NOTES_TAB_KEY } from "pages/Docs/Docs";
import { hasSeenLatestReleaseNotes } from "pages/Docs/ReleaseNotes";
import { LANDING_PAGE_PAGE_CONFIG_KEY } from "pages/SuperAdmin/PagesConfig/types";

import Logout from "features/auth/Logout";
import GlobalSearchAction from "features/ui/GlobalSearch/GlobalSearchAction";

import { routes } from "services/routes";
import { RouteConfig } from "services/routesConfig";
import { isMultiTenantInstanceManagementInstance } from "config/config";

import {
  SIDEBAR_COLLAPSED_WIDTH_CLASS,
  SIDEBAR_EXPANDED_WIDTH_CLASS,
} from "./constants";
import NavBarItem, { NavBarItemWithChildren } from "./NavBarItem";
import styles from "./SidebarNav.module.css";
import SideMenuGroup from "./SideMenuGroup";
import { NAV_ITEM_ICON_SIZE, NavGroup } from "./utils";

interface Props {
  navGroups: NavGroup[];
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  toggleExpand: () => void;
  isOverflow: boolean;
  routeConfig: RouteConfig;
  showHomeLink?: boolean;
  showSearch?: boolean;
  showDuck?: boolean;
}

const Sidebar = ({
  navGroups,
  expanded,
  setExpanded,
  toggleExpand,
  isOverflow,
  routeConfig,
  showHomeLink,
  showSearch,
  showDuck,
}: Props) => {
  const flags = useFlags();
  const { pages } = useConfigContext();
  const showReleaseNotesIcon = !hasSeenLatestReleaseNotes();

  const draggableDuckRef = useRef<HTMLDivElement | null>(null);

  const { loaded } = useConfigContext();

  if (!isMultiTenantInstanceManagementInstance() && !loaded) {
    return null;
  }

  return (
    <>
      {expanded && (
        <div
          className={classNames(
            "lg:hidden fixed bg-black w-full h-full bg-opacity-50",
            {
              "z-10": isOverflow && expanded,
            }
          )}
          onClick={() => setExpanded(false)}
        ></div>
      )}
      <nav
        className={classNames(
          "fixed lg:sticky h-screen max-h-screen top-0 flex shrink-0 bg-gray-50 shadow-sm transition-width ease-in-out duration-300",
          {
            "sticky! z-10": showDuck && !flags.duckRightPanel && !expanded,
            [SIDEBAR_EXPANDED_WIDTH_CLASS]: expanded,
            [SIDEBAR_COLLAPSED_WIDTH_CLASS]: !expanded,
            "z-10": isOverflow && expanded,
          }
        )}
      >
        <div className="flex flex-1 overflow-x-hidden flex-col duration-150">
          <ul className="mb-3">
            <li
              className={classNames(
                "px-4 lg:px-6 mb-5 cursor-pointer tracking-normal focus:outline-hidden flex justify-between lg:block mt-6",
                { "opacity-0": !expanded }
              )}
              key="logo"
            >
              <Link
                to={
                  pages[LANDING_PAGE_PAGE_CONFIG_KEY]
                    ? routes.root
                    : routes.vehicles
                }
              >
                <Logo className={styles.logo} title="Viaduct Logo" />
              </Link>
            </li>
            {showSearch && (
              <li className={classNames({ "px-5": expanded })}>
                <GlobalSearchAction menuExpanded={expanded} />
              </li>
            )}
          </ul>
          {showHomeLink && pages[LANDING_PAGE_PAGE_CONFIG_KEY] && (
            <ul className="border-b border-gray-200">
              <NavBarItem
                item={{ to: routes.root, text: "Home", icon: <HomeIcon /> }}
                routeConfig={routeConfig}
                menuExpanded={expanded}
              />
            </ul>
          )}
          <div className="overflow-y-auto">
            <ul id="main-menu">
              {navGroups.map((group) => (
                <SideMenuGroup
                  key={group.title}
                  menuExpanded={expanded}
                  {...group}
                  routeConfig={routeConfig}
                />
              ))}
            </ul>
          </div>
          <ul className="border-t border-gray-200 mb-3">
            <NavBarItem
              item={{
                to: {
                  pathname: routes.docs,
                  search:
                    showReleaseNotesIcon && flags.releaseNotes
                      ? qs.stringify({ tab: DOCS_RELEASE_NOTES_TAB_KEY })
                      : "",
                },
                text: "Help",
                icon:
                  showReleaseNotesIcon && flags.releaseNotes ? (
                    <GiftIcon
                      size={NAV_ITEM_ICON_SIZE}
                      className="animate-pulse text-blue-300"
                    />
                  ) : (
                    <HelpIcon size={NAV_ITEM_ICON_SIZE} />
                  ),
              }}
              routeConfig={routeConfig}
              menuExpanded={expanded}
              onClick={() => !showReleaseNotesIcon && setExpanded(false)}
            />
            <NavBarItemWithChildren title="Logout" menuExpanded={expanded}>
              <Logout menuExpanded={expanded} />
            </NavBarItemWithChildren>
          </ul>
          {showDuck && !flags.duckRightPanel && (
            <div
              className={classNames({
                "fixed bottom-0 z-20": !expanded,
                [SIDEBAR_EXPANDED_WIDTH_CLASS]: !expanded,
              })}
            >
              <DuckNest draggableDuckRef={draggableDuckRef} />
            </div>
          )}
        </div>
        <div onClick={toggleExpand}>
          {/* Group classes need to be in HTML directly: https://github.com/tailwindlabs/tailwindcss/issues/2848 */}
          <div
            className={classNames(
              "w-8 absolute top-0 bottom-0 transform group",
              { "z-10": isOverflow && expanded }
            )}
          >
            <div
              className={classNames(
                "h-full left-0 relative w-6 group",
                styles.sizer
              )}
            >
              <div className="absolute bg-blue-300 opacity-0 h-full left-0 w-0.5 transition transition-colors transition-opacity pointer-events-none group-hover:opacity-100"></div>
            </div>
            <button
              role="separator"
              className="absolute top-8 w-6 h-6 border text-gray-500 shadow-sm opacity-100 rounded-full p-0 transition -left-3 transition-colors bg-white cursor-pointer group-hover:text-white group-hover:border-blue-300 group-hover:bg-blue-300"
            >
              <span className="cursor-pointer fill-current shrink-0 leading-none">
                <ChevronLeft
                  className={classNames("mx-auto fill-current transform", {
                    "rotate-180": !expanded,
                  })}
                  size={20}
                />
              </span>
            </button>
          </div>
        </div>
      </nav>
      {/*
      We need this div to be at this place in the DOM in order to give DraggableDuck a home
      with the right visibility (zIndex). Attaching DraggableDuck at the same place where it
      is defined in the DuckNest component results in it being partially hidden behind some
      of the page content.
      */}
      <div ref={draggableDuckRef} className="fixed z-50 top-0 left-0 p-1" />
    </>
  );
};

export default Sidebar;
