import { PageHandler } from "duck/graph/PageHandler";
import { StringSetter } from "duck/graph/types";
import { StructuredTool, tool } from "@langchain/core/tools";

// import {
//   getChartOptionsKey,
// } from "pages/Issues/utils";

import { withIssueIdSchema } from "./utils";

const getUpdateTabChartSettingsTool = (
  pageHandler: PageHandler,
  setEphemeralMessage: StringSetter,
  tabKey: string,
  tabTitle: string,
  chartKey: string
): StructuredTool => {
  const updateTabChartSettings = async () =>
    // TODO: leaving this for convience when implementing the function
    // const chartOptionsKey = getChartOptionsKey(chartName, issueId);
    "Not Supported yet";

  const toolName =
    chartKey.length > 64 - "updateChartSettings".length
      ? chartKey.slice(0, 64)
      : `update${chartKey}ChartSettings`;

  return tool(updateTabChartSettings, {
    name: toolName,
    description: `Use this tool when the user wants to update ${chartKey} chart settings for the ${tabTitle} tab of a specific issue.`,
    schema: withIssueIdSchema,
  });
};

export default getUpdateTabChartSettingsTool;
