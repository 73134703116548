import { add, format } from "date-fns";
import { toDate } from "date-fns-tz";
import { useFlags } from "launchdarkly-react-client-sdk";
import qs from "qs";
import { generatePath, Link } from "react-router-dom";

import { API_DATE_FORMAT } from "shared/constants";
import { useConfigContext } from "shared/contexts/ConfigContext";

import {
  VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY,
  VIN_VIEW_EVENTS_TIMELINE_USE_DEFAULT_TO_DATE_KEY,
} from "pages/VINView/constants";

import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";

const CURRENT_DATE_WEEK_OFFSET = -4;
const SPECIFIED_DATE_BEFORE_WEEK_OFFSET = -2;
const SPECIFIED_DATE_AFTER_WEEK_OFFSET = 1;

export const VINEventTimelineDateLink = ({
  date,
  VIN,
  VINLabel,
  dateTo,
  filter,
  filterKey,
  useDefaultFromDate,
}: {
  date: string;
  VIN: string;
  VINLabel?: string;
  dateTo?: string;
  filter?: string;
  filterKey?: string | ((vin: string) => string);
  useDefaultFromDate?: boolean;
}) => {
  const { eventTimeline: eventTimelineFlag } = useFlags();
  const {
    pages: { vehicles },
  } = useConfigContext();

  const pathname = generatePath(routes.vinView, {
    vin: encodeURIComponent(VIN),
  });

  // default if date is set to empty string
  let startDate = format(
    add(new Date(), { weeks: CURRENT_DATE_WEEK_OFFSET }),
    API_DATE_FORMAT
  );
  let endDate = format(new Date(), API_DATE_FORMAT);

  // if only one date is provided
  if (date && (!dateTo || date === dateTo)) {
    const currentDate = toDate(date);
    startDate = format(
      add(currentDate, { weeks: SPECIFIED_DATE_BEFORE_WEEK_OFFSET }),
      API_DATE_FORMAT
    );
    endDate = format(
      add(currentDate, { weeks: SPECIFIED_DATE_AFTER_WEEK_OFFSET }),
      API_DATE_FORMAT
    );
  } else if (date && dateTo) {
    // if date range is provided
    startDate = format(toDate(date), API_DATE_FORMAT);
    endDate = format(toDate(dateTo), API_DATE_FORMAT);
  }

  const pageKeyFilterKey = getFiltersKey(
    VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_KEY(VIN)
  );

  const queryParams: { tab: string; [key: string]: string } = {
    tab: VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
  };

  // in cases when we want to use a vehicle history event such as vehicleManufacturedAt as fromDate
  // we sometimes don't want to set it here but rather in the EventsTimeline
  // example for that is ClaimsTable where we have multiple VINs
  // in this case we use local storage instead of filters to pass the toDate value
  const useDefaultTimelineFromDate = useDefaultFromDate && date;
  if (!useDefaultTimelineFromDate) {
    queryParams[pageKeyFilterKey] = getFiltersQuery(
      filterStateToFilterGroupState({
        recordedAt: {
          values: [startDate, endDate],
          operator: FilterOperator.BETWEEN,
        },
      })
    );
  }

  const handleClick = () => {
    if (useDefaultTimelineFromDate) {
      localStorage.setItem(
        VIN_VIEW_EVENTS_TIMELINE_USE_DEFAULT_TO_DATE_KEY(VIN),
        format(
          add(toDate(date), { weeks: SPECIFIED_DATE_AFTER_WEEK_OFFSET }),
          API_DATE_FORMAT
        )
      );
    }
  };

  if (filterKey instanceof Function && filter) {
    queryParams[filterKey(VIN)] = filter;
  }

  if (typeof filterKey == "string" && filter) {
    queryParams[filterKey as string] = filter;
  }

  return (
    <Link
      to={{
        pathname,
        search:
          (Boolean(vehicles?.eventTimeline && eventTimelineFlag) &&
            qs.stringify(queryParams)) ||
          undefined,
      }}
      className="text-blue-400 hover:text-blue-500"
      onClick={handleClick}
    >
      {VINLabel || VIN}
    </Link>
  );
};
