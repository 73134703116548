import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import classNames from "classnames";
import { IoSearchSharp as SearchIcon } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { LinearProgress } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import { useConfigContext } from "shared/contexts/ConfigContext";
import { useKeyBinding } from "shared/hooks";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";

import {
  NO_RESULTS_TEXT,
  SEARCH_PLACEHOLDER,
  START_TYPING_TEXT,
} from "./constants";
import GlobalSearchControlsInfo, {
  GlobalSearchEnterIcon,
} from "./GlobalSearchControlsInfo";
import { useGlobalSearch } from "./hooks";
import { SearchResource, useSearchConfig } from "./searchConfig";
import { createLinkForResultItem, markMatchingText } from "./utils";

interface SingleResourceListProps {
  items: any[];
  type: string;
  searchInput: string;
  firstResultIndex: number;
  selectedIndex: number;
  onClick: () => void;
  ref: React.RefObject<{ handleEnterPress: () => void } | null>;
}

interface ListItemProps {
  item: any;
  config: SearchResource;
  index: number;
  innerSelectedIndex?: number;
  searchInput: string;
  onClick: () => void;
}

const SearchResultItem = ({
  config,
  item,
  onClick,
  searchInput,
  index,
  innerSelectedIndex,
}: ListItemProps) => {
  const elementRef = useRef<HTMLAnchorElement>(null);
  const secondaryText = config.searchFields
    .filter((field) => field.fieldName !== config.nameField)
    .map((field) => item[field.fieldName])
    .join(", ");

  if (index === innerSelectedIndex) {
    elementRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "nearest",
    });
  }

  return (
    <Link
      ref={elementRef}
      key={item[config.idField]}
      className={classNames(
        "p-2 border border-gray-200 flex flex-row items-center rounded-sm gap-4 hover:bg-gray-200",
        { "bg-gray-200": index === innerSelectedIndex }
      )}
      to={createLinkForResultItem(config, item[config.idField])}
      onClick={onClick}
      replace={true}
      reloadDocument={true}
    >
      <div className="text-gray-400">{config.icon}</div>
      <div
        className="flex-1 overflow-hidden text-ellipsis whitespace-nowrap min-w-36"
        dangerouslySetInnerHTML={{
          __html: markMatchingText(item[config?.nameField], searchInput),
        }}
      ></div>
      <div
        className="text-gray-400 overflow-hidden text-ellipsis whitespace-nowrap"
        dangerouslySetInnerHTML={{
          __html: markMatchingText(secondaryText, searchInput),
        }}
      ></div>
      {index === innerSelectedIndex && (
        <div className="ml-auto mr-2">
          <GlobalSearchEnterIcon />
        </div>
      )}
    </Link>
  );
};

// First, create a type for the ref functions
interface SearchResultListRef {
  handleEnterPress: () => void;
}

// Modify SearchResultList to use forwardRef
const SearchResultList = forwardRef<
  SearchResultListRef,
  SingleResourceListProps
>(
  (
    { items, type, searchInput, firstResultIndex, selectedIndex, onClick },
    ref
  ) => {
    const searchConfig = useSearchConfig();
    const { pages } = useConfigContext();

    const config = searchConfig.find((config) => config.type === type);
    const innerSelectedIndex =
      selectedIndex - firstResultIndex >= 0
        ? selectedIndex - firstResultIndex
        : undefined;

    const defaultVehicleFilters =
      filterBuilderQueryToFilterBuilderState(
        pages.claimAnalytics?.defaultVehicleFilters
      ) || DEFAULT_FILTER_BUILDER_STATE;

    const { updateFilters } = useFilterSortState({
      pageKey: config?.resetFilterKey || "",
      disableUsingQuery: true,
      defaultFilterValues: defaultVehicleFilters,
      schemaAttributes: [],
    });

    const handleClick = () => {
      if (config?.resetFilterKey) {
        updateFilters(defaultVehicleFilters);
      }

      onClick();
    };

    // Create the handler function
    const handleEnterPress = useCallback(() => {
      if (config?.resetFilterKey) {
        updateFilters(defaultVehicleFilters);
      }

      onClick();
    }, [config?.resetFilterKey, defaultVehicleFilters, onClick, updateFilters]);

    // Expose the handler function via ref
    useImperativeHandle(
      ref,
      () => ({
        handleEnterPress,
      }),
      [handleEnterPress]
    );

    if (items.length === 0 || config == null) {
      return;
    }

    return (
      <ul
        key={type}
        className="flex flex-col gap-2"
        data-testid="search-result-group"
      >
        <div className="font-bold text-sm mt-2" data-testid="group-title">
          {type}
        </div>
        {items.map((item: any, index: number) => (
          <SearchResultItem
            key={item[config.idField]}
            config={config}
            item={item}
            index={index}
            innerSelectedIndex={innerSelectedIndex}
            searchInput={searchInput}
            onClick={handleClick}
          />
        ))}
      </ul>
    );
  }
);

interface GlobalSearchProps {
  onClick: () => void;
}

const GlobalSearch = ({ onClick }: GlobalSearchProps) => {
  const searchConfig = useSearchConfig();
  const [searchInput, setSearchInput] = useState("");
  const { data, loading, debouncedSearchInput } = useGlobalSearch({
    searchInput,
  });
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const navigate = useNavigate();

  // getCountAboveType handles calculates the number of items above the current type
  // and is used to calculate the index of the selected item inside individual resource lists
  const getCountAboveType = (type: string) => {
    let c = 0;
    for (const [key, value] of Object.entries(data)) {
      if (key === type) return c;

      c += value.length;
    }

    return c > 0 ? c - 1 : 0;
  };

  const handleInputChange = (
    ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSelectedIndex(0);
    setSearchInput(ev.target.value);
  };

  // Create refs for each SearchResultList
  const resultListRefs = useRef<{
    [key: string]: React.RefObject<SearchResultListRef | null>;
  }>({});

  // Initialize refs for each search config type
  useEffect(() => {
    searchConfig.forEach((config) => {
      resultListRefs.current[config.type] =
        React.createRef<SearchResultListRef>();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedEnter = useCallback(() => {
    const item = Object.values(data).flat()[selectedIndex];
    let config: SearchResource | undefined = undefined;
    let c = 0;

    // handling finding the type for the flattened array of result lists index
    for (const [key, value] of Object.entries(data)) {
      if (selectedIndex <= value.length + c - 1) {
        config = searchConfig.find((config) => config.type === key)!;
        break;
      }

      c += value.length;
    }

    if (!config) {
      return;
    }

    const link = createLinkForResultItem(config, item[config.idField]);

    if (window.location.pathname === link.pathname) {
      // If we're on the same page, use location.href
      window.location.href = `${link.pathname}${link.search || ""}`;
    } else {
      // Otherwise use navigate
      navigate(link, {
        replace: true,
      });
    }

    resultListRefs.current[config.type]?.current?.handleEnterPress();
    onClick();
  }, [data, navigate, onClick, selectedIndex, searchConfig]);

  const dataLength = Object.values(data).flat().length;

  useKeyBinding(
    ["ArrowUp"],
    () => selectedIndex > 0 && setSelectedIndex(selectedIndex - 1)
  );
  useKeyBinding(
    ["ArrowDown"],
    () => dataLength - 1 > selectedIndex && setSelectedIndex(selectedIndex + 1)
  );
  useKeyBinding(["Enter"], () => handleSelectedEnter());

  return (
    <div className="min-h-24 flex flex-col">
      <TextField
        fullWidth
        autoFocus
        placeholder={SEARCH_PLACEHOLDER}
        data-testid="global-search-input"
        onChange={handleInputChange}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon size={24} />
              </InputAdornment>
            ),
          },
        }}
        variant="standard"
      />
      <div
        className="max-h-[32rem] overflow-auto mt-4"
        data-testid="search-results"
      >
        {debouncedSearchInput !== "" &&
          Object.entries(data).map(([type, items]) => (
            <SearchResultList
              key={type}
              ref={resultListRefs.current[type]}
              selectedIndex={selectedIndex}
              firstResultIndex={getCountAboveType(type)}
              items={items}
              type={type}
              searchInput={debouncedSearchInput}
              onClick={onClick}
            />
          ))}
        {debouncedSearchInput === "" && (
          <div className="flex flex-col justify-center text-sm h-5">
            {START_TYPING_TEXT}
          </div>
        )}
        {debouncedSearchInput !== "" &&
          !loading &&
          Object.values(data).every((items) => items.length === 0) && (
            <div className="flex flex-col justify-center text-sm h-5">
              {NO_RESULTS_TEXT}
            </div>
          )}

        {loading && (
          <div
            className="mt-auto h-5 flex flex-col justify-center"
            data-testid="search-results-loading"
          >
            <LinearProgress />
          </div>
        )}
      </div>
      <GlobalSearchControlsInfo />
    </div>
  );
};

export default GlobalSearch;
