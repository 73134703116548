import { useEffect, useState } from "react";
import { AxiosResponse } from "axios";

import { APIListValuesResponse, EntityAttribute } from "shared/api/api";
import client from "shared/api/axios";
import { EventTypeEnum } from "shared/types";

import {
  getClaimDealerDisplayNameValue,
  getClaimDealerIdValue,
} from "pages/ClaimAnalytics/utils";

import { SchemaEntry } from "features/ui/Table";

import {
  CLAIM_DEALER_ACCESSOR,
  TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR,
  VALUES_ENDPOINT_LIMIT_DEFAULT,
  VEHICLE_LAST_KNOWN_DEALER_ACCESSOR,
} from "./constants";
import useDealersSchema from "./dealerSchema";
import { useGroupByAttributeFilter } from "./hooks_groupBy";
import { USE_RESOURCE_SCHEMA_MAP } from "./schemaMap";
import { M2MRelationshipsGroupBy } from "./types";
import {
  constructValuesEndpoint,
  filterGroupableAttributes,
  formatVehicleAttribute,
  getSchemaType,
  getVehicleLastKnownDealerDisplayNameValue,
  getVehicleLastKnownDealerIdValue,
  sortByDisplayName,
} from "./utils";
import useVehicleECUsCombinedSchema from "./vehicleECUsCombinedSchema";
import useVehicleOptionsCombinedSchema from "./vehicleOptionsCombinedSchema";
import useVehiclesSchema from "./vehiclesSchema";

export const useGroupByRelationIDValues = (
  attributes: EntityAttribute[] | undefined
) => {
  const [values, setValues] = useState<M2MRelationshipsGroupBy>({});

  const valuesEndpoints = attributes
    ?.filter(
      (a) =>
        a.relationEndpoint && a.relationEndpointIDColumn && a.attributeGrouping
    )
    .map((a) => ({
      endpoint: constructValuesEndpoint(
        a.relationEndpoint!,
        a.relationEndpointIDColumn!
      ),
      originalEndpoint: a.relationEndpoint!,
    }));

  const shouldRefetch = valuesEndpoints
    ?.map(({ originalEndpoint }) => originalEndpoint)
    .sort()
    .join(",");

  useEffect(() => {
    if (!valuesEndpoints) return;

    Promise.all(
      valuesEndpoints.map(({ endpoint }) =>
        client.get(endpoint, {
          params: { limit: VALUES_ENDPOINT_LIMIT_DEFAULT },
        })
      )
    )
      .then((responses: AxiosResponse<APIListValuesResponse>[]) => {
        const newVals: M2MRelationshipsGroupBy = Object.fromEntries(
          responses.map(({ data }, i) => [
            valuesEndpoints[i].originalEndpoint,
            data.distinctValues.filter((x) => x !== null),
          ])
        );

        setValues(newVals);
      })
      .catch((error) => {
        console.error("Error fetching relationship values:", error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldRefetch]);

  return values;
};

/**
 * Get a list of groupable EntityAttributes for a given event type.
 * @param eventType EventTypeEnum
 * @param skipVehicleAttributes boolean
 * @returns EntityAttribute[]
 * @example useGroupByAttributes(EventTypeEnum.CLAIM, true)
 */
const useGroupByAttributes = (
  eventType: EventTypeEnum,
  skipVehicleAttributes: boolean = false
): EntityAttribute[] => {
  const vehicleAttributesOptions = useVehicleGroupByAttributes();
  const { attributes: entityAttributes } = USE_RESOURCE_SCHEMA_MAP[eventType]();
  const { attributes: dealerAttributes } = useDealersSchema();

  if (!entityAttributes) {
    return [];
  }

  let entityAttributesOptions: EntityAttribute[] = entityAttributes.filter(
    filterGroupableAttributes
  );

  if (eventType === EventTypeEnum.CLAIM) {
    entityAttributesOptions = addClaimDealerAttributes(
      entityAttributesOptions,
      entityAttributes.find(({ ID }) => ID === CLAIM_DEALER_ACCESSOR),
      dealerAttributes
    );
  }

  entityAttributesOptions.sort(sortByDisplayName);

  if (skipVehicleAttributes) return entityAttributesOptions;

  return [...entityAttributesOptions, ...vehicleAttributesOptions];
};

const addClaimDealerAttributes = (
  attributes: EntityAttribute[],
  claimDealerAttribute: EntityAttribute | undefined,
  dealerAttributes: EntityAttribute[] | undefined
) => {
  if (!claimDealerAttribute) return attributes;

  if (!dealerAttributes) return attributes;

  return [
    ...attributes,
    ...dealerAttributes.filter(filterGroupableAttributes).map((attr) => ({
      ...attr,
      ID: getClaimDealerIdValue(attr.ID),
      displayName: getClaimDealerDisplayNameValue(
        attr.displayName,
        claimDealerAttribute
      ),
    })),
  ];
};

/**
 * This hook returns a SchemaEntry for a given attributeId and resource. Mainly used for TopContributors table on Claim, SE, Inspection & Repair analytics.
 * @param attributeId string
 * @param eventType EventTypeEnum
 * @returns SchemaEntry | undefined
 */
export const useSchemaEntryForAttribute = (
  attributeId: string,
  eventType: EventTypeEnum,
  overrides: Partial<SchemaEntry> = {}
): SchemaEntry | undefined => {
  const options = useGroupByAttributes(eventType);
  const attributeObj = options.find(({ ID }) => ID === attributeId);

  const vehiclesSchema = useVehiclesSchema();
  const vehicleECUsSchema = useVehicleECUsCombinedSchema();
  const dealerSchema = useDealersSchema();

  const filter = useGroupByAttributeFilter(
    eventType,
    vehiclesSchema,
    vehicleECUsSchema,
    dealerSchema,
    attributeObj
  );

  const schemaEntry = {
    label: attributeObj?.displayName || "",
    accessor: TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR,
    dataType: getSchemaType(attributeObj?.type),
    sortable: false, // we dont want to allow sorting since there might be issues when sorting on numeric fields
    filter,
  };

  return { ...schemaEntry, ...overrides };
};

const useVehicleGroupByAttributes = (): EntityAttribute[] => {
  const { attributes: vehicleAttributes } = useVehiclesSchema();
  const { attributes: dealerAttributes } = useDealersSchema();
  const { attributes: ecuCombinedAttributes } = useVehicleECUsCombinedSchema();
  const { attributes: optionCombinedAttributes } =
    useVehicleOptionsCombinedSchema();

  const vehicleDealerAttribute = vehicleAttributes?.find(
    (a) => a.ID === VEHICLE_LAST_KNOWN_DEALER_ACCESSOR
  );

  const vehicleAttributesOptions: EntityAttribute[] = vehicleAttributes
    ? vehicleAttributes
        .filter(filterGroupableAttributes)
        .map(formatVehicleAttribute)
    : [];

  const vehicleDealerAttributesOptions: EntityAttribute[] =
    dealerAttributes && Boolean(vehicleDealerAttribute)
      ? dealerAttributes
          .filter(filterGroupableAttributes)
          .map(({ ID, displayName, ...otherAttrs }) => ({
            ID: getVehicleLastKnownDealerIdValue(ID),
            displayName: getVehicleLastKnownDealerDisplayNameValue(
              displayName,
              vehicleDealerAttribute
            ),
            ...otherAttrs,
          }))
      : [];

  const vehicleECUAttributesOptions: EntityAttribute[] = ecuCombinedAttributes
    ? ecuCombinedAttributes
        .filter(filterGroupableAttributes)
        .map(formatVehicleAttribute)
    : [];

  const vehicleOptionAttributesOptions: EntityAttribute[] =
    optionCombinedAttributes
      ? optionCombinedAttributes
          .filter(filterGroupableAttributes)
          .map(formatVehicleAttribute)
      : [];

  vehicleAttributesOptions.push(...vehicleDealerAttributesOptions);
  vehicleAttributesOptions.sort(sortByDisplayName);
  vehicleAttributesOptions.push(...vehicleECUAttributesOptions);
  vehicleAttributesOptions.push(...vehicleOptionAttributesOptions);

  return vehicleAttributesOptions;
};
