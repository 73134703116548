import { useEffect, useState } from "react";

import { getTransportCategory } from "shared/api/api";
import { useVehicle } from "shared/api/vehicles/hooks";
import { useTenantMileageUnit } from "shared/hooks";

export const useVehicleTransportCategoryDisplayName = (
  vin: string
): string | undefined => {
  const [transportCategory, setTransportCategory] = useState<string>();
  const { data } = useVehicle({ vin, mileageUnit: useTenantMileageUnit() });

  // when vin changed and thus inferred category, reset inferred category first
  useEffect(() => {
    setTransportCategory("");
  }, [vin]);

  useEffect(() => {
    if (!data) return;

    const { transportCategoryID } = data;

    if (!transportCategoryID) return;

    getTransportCategory({ id: transportCategoryID })
      .then(({ data: { name } }) => setTransportCategory(name))
      .catch((err) => {
        console.error(err);
      });
  }, [data]);

  return transportCategory;
};
