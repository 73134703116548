import classNames from "classnames";
import {
  AiFillInfoCircle as FillInfoIcon,
  AiOutlineInfoCircle as OutlineInfoIcon,
} from "react-icons/ai";

import Tooltip from "features/ui/Tooltip";

import styles from "./Info.module.css";

interface BaseProps {
  /**
   * Text to show on hover
   */
  text: string | JSX.Element;
  /**
   * Outline
   */
  outlined?: boolean;
  /**
   * Icon size
   */
  size?: "xs" | "sm" | "md" | "lg";
}

const iconSizes = {
  xs: 14,
  sm: 18,
  md: 24,
  lg: 30,
};

const DEFAULT_ICON_COLOR = "#ccc"; // light gray
const DEFAULT_SIZE = "sm";
const DEFAULT_OUTLINED = true;

/**
 * Primary UI component for user interaction (hover only)
 */
const InfoIcon = ({
  text,
  outlined = DEFAULT_OUTLINED,
  size = DEFAULT_SIZE,
}: BaseProps) => {
  const classNameIcon = classNames(styles.icon, `text-${size}`);

  const classNameHasTooltip = classNames(
    "inline-block",
    "align-middle",
    styles["has-tooltip"]
  );

  const IconComponent = outlined ? OutlineInfoIcon : FillInfoIcon;

  return (
    <div className={classNameHasTooltip} data-testid="icon-element">
      <Tooltip title={text}>
        <div role="img">
          <IconComponent
            className={classNameIcon}
            color={DEFAULT_ICON_COLOR}
            size={iconSizes[size]}
          />
        </div>
      </Tooltip>
    </div>
  );
};

export default InfoIcon;
