import { GPT4OMINI_STREAMING_MODEL_SPEC } from "duck/graph/constants";
import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import {
  createAgent,
  getEphemeralMessageForNode,
  getPageAgentNames,
  NodeOutputType,
  NodeType,
} from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import { DuckGraphParams } from "duck/graph/types";
import { getLLM, NodeNames } from "duck/graph/utils";
import { AIMessage } from "@langchain/core/messages";
import { RunnableConfig } from "@langchain/core/runnables";

export const greetingRejectClarifyValidActions = [
  "reject",
  "clarify",
  "greeting",
] as const;

type GreetingRejectClarifyActionType =
  (typeof greetingRejectClarifyValidActions)[number];

const DEFAULT_REASON =
  "Sorry, I couldn’t provide an answer. Please try again with more specifics — the clearer the request, the better I can help!";

const getNode =
  async (params: DuckGraphParams): Promise<NodeType> =>
  async (
    state: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    params.uiHandlers.setEphemeralMessage(
      getEphemeralMessageForNode(NodeNames.GREETING_REJECT_CLARIFY)
    );

    const { messages, userInput } = state;

    // we get routed here if the last message is an AI message with a tool_call named "greetingRejectClarify"
    const lastMessage = messages[messages.length - 1] as AIMessage;
    const toolCall = lastMessage.tool_calls
      ? lastMessage.tool_calls[0]
      : undefined;

    // this should never happen but just in case
    if (!toolCall || toolCall.name !== NodeNames.GREETING_REJECT_CLARIFY) {
      throw new Error(
        `Invalid state: we arrived in the RejectClarifyAgent without calling the "${NodeNames.GREETING_REJECT_CLARIFY}" tool. The last message was ${JSON.stringify(lastMessage)}`
      );
    }

    const { action: toolCallAction, reason: toolCallReason } = toolCall.args;
    console.debug("GreetingRejectClarifyAgent:", {
      toolCallAction,
      toolCallReason,
    });

    const action: GreetingRejectClarifyActionType =
      greetingRejectClarifyValidActions.includes(toolCallAction)
        ? toolCallAction
        : "reject";
    const reason: string = toolCallReason || DEFAULT_REASON;

    const prompt = await loadPrompt(promptNames.GREETING_REJECT_CLARIFY_AGENT);
    const llm = getLLM(GPT4OMINI_STREAMING_MODEL_SPEC);
    const agent = createAgent(llm, [], prompt);

    const stream = agent.streamEvents(
      {
        request: userInput,
        action,
        reason,
        pageAgentNames: getPageAgentNames(params.uiHandlers.duckAccess),
      },
      { version: "v2", ...config }
    );

    for await (const streamEvent of stream) {
      params.uiHandlers.handleStreamEvent(streamEvent);
    }

    return {};
  };

export default getNode;
