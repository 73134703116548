export const MORE_INFO_MESSAGE = `
---
---

---
---

<details>
  <summary><strong>Learn More</strong></summary>

**Contrast Score** is an inverse measure of entropy, designed to quantify the distinctiveness or contrast between different groups in a dataset. A higher contrast score indicates a more uneven distribution of events, meaning specific attributes show stronger differentiation.

**Entropy** is a quantitative measure of randomness of a distribution, with more random events having higher entropy. For example, a weighted coin will have lower entropy than a fair coin. Lower entropy in the distribution of events amongst vehicle attributes indicates higher contrast in performance between the groups, increasing the likelihood that those attributes are linked to a special cause of the event.
</details>

---
---
`;

export const NO_ATTRIBUTES_SELECTED_MESSAGE =
  "I'm sorry, it seems like no vehicle attributes were selected.";

export const NO_ATTRIBUTES_RECOMMENDED_MESSAGE =
  "I'm sorry, it seems like I was not able to find any recommended attributes.";

export const NO_OPTIMAL_SPLIT_MESSAGE =
  "It seems like there are no optimal splits for the selected attributes.";

export const MAX_NUM_ATTRIBUTES = 100;
