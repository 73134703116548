import { ClipboardEvent, SyntheticEvent, useState } from "react";
import { Autocomplete, Chip, TextField } from "@mui/material";

import { FILTER_PASTE_DELIMITER } from "features/ui/Filters/constants";

interface Props {
  fieldName: string;
  currentFilterValues: string[];
  onChange: (values: string[]) => void;
  disabled?: boolean;
  autoFocus?: boolean;
}

const INPUT_PLACEHOLDER = "Type + Enter or paste";

const ContainsStartsWithFilter = ({
  fieldName,
  currentFilterValues,
  onChange,
  disabled,
  autoFocus,
}: Props) => {
  const [searchInput, setSearchInput] = useState("");

  const handleOnPaste = ({ target, clipboardData }: ClipboardEvent) => {
    const text = clipboardData.getData("Text");
    const splitValues = text.split(FILTER_PASTE_DELIMITER);
    const newValues = splitValues.filter(Boolean).map((value) => value.trim());
    onChange([...currentFilterValues, ...newValues]);

    // also unfocus the input so that input values are cleared
    // (if we don't do this we get N chips + N values unconfirmed values in the input field)
    (target as HTMLInputElement).blur();
  };

  const handleBlur = () => {
    onChange([...currentFilterValues, searchInput.trim()]);
    setSearchInput("");
  };

  return (
    <Autocomplete
      autoFocus={autoFocus}
      multiple
      disableCloseOnSelect={true}
      autoHighlight={true}
      disabled={disabled}
      freeSolo
      onBlur={handleBlur}
      size="small"
      data-testid={`${fieldName}-autocomplete`}
      options={[]}
      limitTags={2}
      fullWidth
      value={currentFilterValues}
      inputValue={searchInput}
      onInputChange={(_, newInputValue) => {
        setSearchInput(newInputValue);
      }}
      onPaste={handleOnPaste}
      onChange={(_: SyntheticEvent, values: string[]) => {
        onChange(values);
      }}
      renderTags={(value: readonly string[], getTagProps) =>
        value.map((option: string, index: number) => (
          <Chip
            variant="outlined"
            label={option}
            size="small"
            {...getTagProps({ index })}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          autoFocus={autoFocus}
          {...params}
          placeholder={INPUT_PLACEHOLDER}
          variant="outlined"
          onPaste={handleOnPaste}
          slotProps={{
            htmlInput: params.inputProps,
          }}
        />
      )}
    />
  );
};

export default ContainsStartsWithFilter;
