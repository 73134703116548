import { SelectOption } from "features/ui/Select";

export const NONE_EXPOSURE_ID = "none";

export const NONE_EXPOSURE_OPTION: SelectOption = {
  id: NONE_EXPOSURE_ID,
  value: "None",
};

export const TOP_CONTRIBUTORS_MAX_X_AXIS_CHARACTERS = 110;
