import qs from "qs";

import { getOptionsQuery } from "shared/api/utils";
import { splitIgnoringDoubleQuotes } from "shared/utils";

import {
  SIGNAL_EVENTS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/SignalEventsAnalytics/constants";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  filterStateToFilterGroupState,
  getFiltersQuery,
  updateOrAddRowFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import {
  FilterOperator,
  OccursFilterOptionKeys,
  OccursFilterState,
  OccursFilterWindowDirection,
  OccursWindowOptions,
  SingleFilterState,
} from "features/ui/Filters/types";
import { getFiltersKey } from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";

import { routes } from "services/routes";

import {
  DEFAULT_EMPTY_OCCURS_FILTER,
  DEFAULT_WINDOW_SIZE,
  DEFAULT_WINDOW_TYPE,
} from "./constants";

export const extractOccursFilter = (input: string): string | undefined => {
  const match = input.match(/^\[(.*?)\]$/);

  return match ? match[1] : undefined;
};

export const encodeOccursFilterAndOptions = (
  groupFilterState: FilterGroupState | undefined,
  occursOptionsState: OccursWindowOptions
): string =>
  `{${getOptionsQuery(occursOptionsState)}}[${getFiltersQuery(groupFilterState)}]`;

export const decodeOccursFilterAndOptions = (
  value: string = ""
): OccursFilterState => {
  // we split configuration to get "{option1=value1,option2=value2" and "[filter1=op1:value1,filter2=op2:value2]"
  const matches = splitIgnoringDoubleQuotes(value, "}");

  if (matches && matches.length === 2) {
    // we cut first character, which is start of options configuration {
    const optionString = matches[0].substring(1, matches[0].length);
    // we cut first and last character, which is start and end of filters configuration [ and ]
    const filters = matches[1].substring(1, matches[1].length - 1);
    const options = optionsStringToOptions(optionString);

    return {
      windowSize:
        parseInt(options.windowSize.toString()) || DEFAULT_WINDOW_SIZE,
      windowDirection:
        options.windowDirection || OccursFilterWindowDirection.BEFORE,
      windowType: options.windowType || DEFAULT_WINDOW_TYPE,
      filters,
    } as OccursFilterState;
  } else {
    return DEFAULT_EMPTY_OCCURS_FILTER;
  }
};

export const encodeOccursFilterState = (occursFilterState: OccursFilterState) =>
  encodeOccursFilterAndOptions(
    filterBuilderQueryToFilterBuilderState(occursFilterState.filters),
    {
      windowSize: occursFilterState.windowSize,
      windowDirection: occursFilterState.windowDirection,
      windowType: occursFilterState.windowType,
    }
  );

const optionsStringToOptions = (
  settings: string
): Record<OccursFilterOptionKeys, string | number> => {
  const settingsArray = settings.split(",");

  return settingsArray.reduce((acc, setting) => {
    const [key, value] = setting.split("=");

    return { ...acc, [key]: value.replace(/"/g, "") };
  }, {}) as Record<OccursFilterOptionKeys, string | number>;
};

export const getSelectOptionValue = (
  id: string | number,
  options: SelectOption[]
): string => {
  const option = options.find((x) => x.id === id);

  return option?.value.toString() || "";
};

export const getNavigatePropsForSignalEventAnalytics = (
  occursFilter: string,
  selectedSignalEvents: Set<string>,
  vehiclesFilters?: FilterGroupState
) => {
  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);
  const signalEventsFilterKey = getFiltersKey(SIGNAL_EVENTS_PAGE_KEY);

  let seFilter = filterBuilderQueryToFilterBuilderState(
    decodeOccursFilterAndOptions(occursFilter).filters
  );

  seFilter = updateOrAddRowFilterGroupState(seFilter, {
    attribute: "signalEventID",
    id: "signalEventID",
    type: "row",
    operator: FilterOperator.IN,
    values: Array.from(selectedSignalEvents),
  });

  return {
    pathname: routes.signalEventAnalytics,
    search: qs.stringify({
      [vehicleFilterKey]: getFiltersQuery(vehiclesFilters),
      [signalEventsFilterKey]: getFiltersQuery(seFilter),
    }),
  };
};

export const getBasicOccursFilterFromSignalEventFilter = (
  signalEventFilter: SingleFilterState
): SingleFilterState => ({
  values: [
    encodeOccursFilterAndOptions(
      filterStateToFilterGroupState({
        signalEventID: signalEventFilter,
      }),
      {
        windowSize: DEFAULT_WINDOW_SIZE,
        windowDirection: OccursFilterWindowDirection.BEFORE,
        windowType: DEFAULT_WINDOW_TYPE,
      }
    ),
  ],
  operator: FilterOperator.OCCURS,
});
