import {
  CountRequest,
  CountResponse,
  EntityAttribute,
  ListAttributesRequest,
} from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import * as api from "./api";
import { listServiceRecordsAttributesRequestURI } from "./api";

export const useListServiceRecords = (args: api.ListServiceRecordsRequest) =>
  usePaginatedAPI<typeof args, api.ServiceRecord[]>(
    api.listServiceRecordsRequestURI,
    args
  );

export const useListServiceRecordsCount = (args: CountRequest) =>
  useAPI<typeof args, CountResponse>(
    api.listServiceRecordsCountRequestURI,
    args
  );

export const useGetCustomRecord = (args: api.GetCustomRecordRequest) =>
  useAPI<typeof args, api.CustomRecord>(api.getCustomRecordRequestURI, args);

export const useServiceRecordsAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listServiceRecordsAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );
