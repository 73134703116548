import { LC_API_KEY, LC_ENDPOINT } from "duck/graph/constants";
import { getTagSuffix } from "duck/graph/nodes/utils";
import * as hub from "langchain/hub";
import { ChatPromptTemplate } from "@langchain/core/prompts";

import { PromptName, promptNames, PromptTag } from "./types";

const cachedPrompts: Partial<Record<PromptName, ChatPromptTemplate>> = {};

/**
 * The tag to use is typically determined by the environment.
 * The ability to explicitly specify a tag is provided for development purposes.
 * 1. The pseudotag "latest" pulls the latest version of the prompt (preferred)
 * 2. We can create temporary tags to use with development branches
 * Both approaches allow us to test prompts during local development without
 * disrupting the operation of the dev server, or disrupting other developers.
 *
 * The tagOverrides map allows us to override the tag for specific prompts.
 * We override tags this way so that we can easily preload prompts while using
 * the correct tags.
 *
 * @example
 * import { PromptName, promptNames, PromptTag, promptTags } from "./types";

 * const tagOverrides: Partial<Record<PromptName, PromptTag>> = {
 *  [promptNames.RAG_AGENT]: promptTags.LATEST,
 * };
 */
const tagOverrides: Partial<Record<PromptName, PromptTag>> = {};

/**
 * @param promptName The name of the prompt
 * @returns The prompt
 */
const loadPrompt = async (
  promptName: PromptName
): Promise<ChatPromptTemplate> => {
  // caching using the prompt name is sufficient since the environment will always be consistent during runtime.
  if (cachedPrompts[promptName]) {
    return cachedPrompts[promptName];
  }

  // load the prompt based on the environment
  const promptNameWithTag = `${promptName}${getTagSuffix(tagOverrides[promptName])}`;
  const prompt = await hub.pull<ChatPromptTemplate>(promptNameWithTag, {
    apiKey: LC_API_KEY,
    apiUrl: LC_ENDPOINT,
  });

  cachedPrompts[promptName] = prompt;

  return prompt;
};

const loadAllPrompts = (): Promise<ChatPromptTemplate[]> =>
  Promise.all(
    Object.values(promptNames).map((promptName) => loadPrompt(promptName))
  );

export const preloadPrompts = async (): Promise<void> => {
  const maxRetries = 3;
  let currentAttempt = 1;
  let success = false;
  while (!success && currentAttempt <= maxRetries) {
    try {
      await loadAllPrompts();
      success = true;
    } catch (error) {
      console.error(
        `Attempt ${currentAttempt} of ${maxRetries} to preload Duck prompts failed`,
        error
      );
      currentAttempt++;
    }
  }

  if (success) {
    console.debug("Successfully preloaded Duck prompts");
  } else {
    console.error("Unable to preload Duck prompts");
  }
};

export default loadPrompt;
