import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

import styles from "./TablePageControl.module.css";

const TablePageControl = ({
  onNextPage,
  onPreviousPage,
  hasNextPage,
  hasPreviousPage,
}: {
  onNextPage: () => void;
  onPreviousPage: () => void;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
}) => (
  <div className="flex flex-row space-x-2">
    <div className="grow" />
    <button
      data-testid="prev-page-btn"
      className={
        hasPreviousPage ? styles["btn-active"] : styles["btn-inactive"]
      }
      onClick={() => hasPreviousPage && onPreviousPage()}
      disabled={!hasPreviousPage}
    >
      <HiChevronLeft size="1.5rem" />
    </button>
    <button
      data-testid="next-page-btn"
      className={hasNextPage ? styles["btn-active"] : styles["btn-inactive"]}
      onClick={() => hasNextPage && onNextPage()}
      disabled={!hasNextPage}
    >
      <HiChevronRight size="1.5rem" />
    </button>
  </div>
);

export default TablePageControl;
