import * as React from "react";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import {
  BsChevronDown as ChevronDownIcon,
  BsChevronUp as ChevronUpIcon,
} from "react-icons/bs";
import {
  Accordion as Acc,
  AccordionDetails,
  AccordionSummaryProps,
} from "@mui/material";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import { styled } from "@mui/system";

import { AccordionArrowPosition } from "shared/types";

import Label from "features/ui/Label/Label";

export interface AccordionProps {
  id: string;
  title: string | JSX.Element;
  rightContent?: string | JSX.Element;
  children?: string | JSX.Element;
  expanded?: boolean;
  onChange?: (event: SyntheticEvent, isExpanded: boolean) => void;
  arrowPosition?: AccordionArrowPosition;
  className?: string;
  disabled?: boolean;
}

interface ExtendedAccordionSummaryProps {
  // arrowposition intentionally has this name, otherwise the following error is thrown in the console:
  // React does not recognize the `arrowPosition` prop on a DOM element. If you intentionally want it to appear in the DOM as a custom attribute, spell it as lowercase `arrowposition` instead.

  arrowposition: AccordionArrowPosition;
  expanded?: boolean;
  props?: AccordionSummaryProps;
  children?: React.ReactNode;
}

const CustomizedAccordion = styled(Acc)(() => ({
  "&&.Mui-expanded:before": {
    opacity: 1,
  },
  "&.Mui-disabled": {
    backgroundColor: "#f5f5f5",
    position: "relative",
    zIndex: 0,
    "&:before": {
      opacity: 0, // Remove the top border/shadow
    },
  },
  // Make the summary content take full width
  "& .MuiAccordionSummary-content": {
    width: "100%",
    "& > div": {
      width: "100%",
      "& > label": {
        width: "100%",
      },
    },
  },
}));

const CustomizedAccordionSummary = styled(
  ({ expanded, ...props }: ExtendedAccordionSummaryProps) => {
    // Track if the last interaction was from mouse
    const [isMouseInteraction, setIsMouseInteraction] = useState(false);

    return (
      <MuiAccordionSummary
        expandIcon={expanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
        {...props}
        onMouseDown={() => {
          setIsMouseInteraction(true);
        }}
        onFocus={(e) => {
          if (isMouseInteraction) return;

          e.target.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
          setIsMouseInteraction(false);
        }}
      />
    );
  }
)(({ arrowposition }) => ({
  flexDirection: arrowposition === "right" ? "row" : "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)",
  },
}));

const Accordion = ({
  id,
  title,
  children,
  expanded,
  onChange,
  arrowPosition = "right",
  rightContent,
  disabled,
  className,
}: AccordionProps) => {
  // scroll to the accordion element after it is expanded
  const accordionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (expanded && accordionRef?.current) {
      setTimeout(() => {
        accordionRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "nearest",
          inline: "nearest",
        });
      }, 0);
    }
  }, [expanded]);

  return (
    <CustomizedAccordion
      key={id}
      ref={accordionRef}
      data-testid={`accordion-${id}`}
      expanded={expanded}
      onChange={onChange && onChange}
      square
      disabled={disabled}
      disableGutters
      className={className}
      slotProps={{
        transition: {
          timeout: 0,
          unmountOnExit: true,
        },
      }}
    >
      <CustomizedAccordionSummary arrowposition={arrowPosition}>
        <div className="w-full flex justify-between items-center">
          <Label
            text={title}
            className={expanded ? "ml-2 font-bold!" : "ml-2 text-gray-500"}
          />
          {rightContent}
        </div>
      </CustomizedAccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </CustomizedAccordion>
  );
};

export default Accordion;
