import { useEffect, useState } from "react";
import GenericCar from "imgs/generic-car.png";
import Skeleton from "react-loading-skeleton";

import { getVehicleImage, Vehicle } from "shared/api/vehicles/api";

interface Props {
  vehicle: Vehicle;
}

const VehicleImage = ({ vehicle }: Props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [data, setData] = useState<Blob>();

  useEffect(() => {
    // We are not using a hook as it was impossible to get image Blob without it being converted to string
    // by SWR and axios.
    setLoading(true);
    getVehicleImage({ vin: vehicle.VIN })
      .then((response) => {
        setData(response.data);
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [vehicle.VIN]);

  if (loading) {
    return <Skeleton height={200} width={200} />;
  }

  if (error || !data) {
    return <img src={GenericCar} alt="Vehicle" />;
  }

  return <img src={URL.createObjectURL(data)} alt="Vehicle" />;
};

export default VehicleImage;
