import {
  SensorReadingsTimelineAggregation,
  SensorReadingsTimelineGrouping,
} from "shared/api/sensors/api";

import { ChartAction } from "features/ui/charts/Actions/types";

export const DEFAULT_R_AXIS_WIDTH = 70;
export const MAX_SENSOR_Y_AXIS_LABEL_LENGTH = 18;
export const MAX_CHART_LABEL_LENGTH = 30;
export const UNAVAILABLE_TEXT_WIDTH = -1;

export const DEFAULT_SHOWN_EVENTS = 15;
export const MAX_SHOWN_EVENTS = 50;

export const CHART_ACTIONS: ChartAction<
  SensorReadingsTimelineGrouping | SensorReadingsTimelineAggregation
>[] = [
  {
    id: "legend",
    title: "Aggregate by",
    type: "dropdownSelect",
    options: [
      {
        id: "day",
        value: "Day",
      },
      {
        id: "hour",
        value: "Hour",
      },
      {
        id: "10minutes",
        value: "10 Minutes",
      },
      {
        id: "minute",
        value: "Minute",
      },
      {
        id: "none",
        value: "None",
      },
    ],
  },
  {
    id: "measure",
    title: "Aggregation function",
    type: "dropdownSelect",
    options: [
      {
        id: "avg",
        value: "Average",
      },
      {
        id: "min",
        value: "Minimum",
      },
      {
        id: "max",
        value: "Maximum",
      },
    ],
  },
];

export const CHART_OPTIONS_KEY = "sensorsChartOptions";

export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY_PREFIX =
  "vin_event_timeline_";
export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY = (
  vin: string
) => `${VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SENSORS_TRIGGERS_KEY_PREFIX}${vin}`;

const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_AGGREGATION_KEY_PREFIX =
  "vin_event_timeline_aggregation_";
export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_AGGREGATION_KEY = (
  vin: string
) => `${VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_AGGREGATION_KEY_PREFIX}${vin}`;

const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_AGGREGATION_FUNCTION_KEY_PREFIX =
  "vin_event_timeline_aggregation_function_";
export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_AGGREGATION_FUNCTION_KEY = (
  vin: string
) =>
  `${VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_AGGREGATION_FUNCTION_KEY_PREFIX}${vin}`;

export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY_PREFIX =
  "vin_event_timeline_se_filter_data_";
export const VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY = (vin: string) =>
  `${VIN_VIEW_EVENTS_TIMELINE_TAB_PAGE_SE_FILTER_KEY_PREFIX}${vin}`;

export const SELECTED_SIGNAL_EVENT_SUFFIX = "-selectedSigEv";
