import {
  useClaimsFiltersSchema,
  useEmailFromJWT,
  useVehiclesFiltersSchema,
} from "shared/hooks";

import { DEFAULT_ISSUE_DATA } from "pages/shared/constants";
import PageLevelFilters from "pages/shared/PageLevelFilters/PageLevelFilters";
import { VEHICLES_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getTopLevelRowFromFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import {
  CLAIMS_FILTER_LABEL,
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "./constants";
import UseFiltersMenu from "./UseFilters/UseFiltersMenu";

// These are not in constants.ts so we avoid circular dependencies with the util being used.
export const PENDING_FILTERS_CLAIMS_LS_KEY =
  getPendingFiltersKey(CLAIMS_PAGE_KEY);

export const PENDING_FILTERS_VEHICLES_LS_KEY =
  getPendingFiltersKey(VEHICLES_PAGE_KEY);

interface Props {
  claimsFilterSortState: UseFilterSortState;
  vehiclesFilterSortState: UseFilterSortState;
  defaultClaimFilters: FilterGroupState;
  defaultVehicleFilters: FilterGroupState;
}

const ClaimAnalyticsFilters = ({
  claimsFilterSortState,
  vehiclesFilterSortState,
  defaultClaimFilters,
  defaultVehicleFilters,
}: Props) => {
  const myEmail = useEmailFromJWT();

  const claimsFiltersSchema = useClaimsFiltersSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const claimFilterLabel = getFilterLabel(
    CLAIMS_FILTER_LABEL,
    claimsFilterSortState.filters
  );
  const vehiclePopulationFilterLabel = getFilterLabel(
    VEHICLES_FILTER_LABEL,
    vehiclesFilterSortState.filters
  );

  const relatedSignalEventsRow = getTopLevelRowFromFilterGroupState(
    "relatedSignalEvents",
    claimsFilterSortState.filters
  );

  const signalEventOccurrencesFilter: FilterGroupState | undefined =
    relatedSignalEventsRow
      ? {
          id: "group-0",
          type: "group",
          anyAll: "all",
          children: [
            {
              ...relatedSignalEventsRow,
              attribute: "signalEventID",
            },
          ],
        }
      : undefined;

  return (
    <PageLevelFilters
      actions={
        <UseFiltersMenu
          issueData={{
            ...DEFAULT_ISSUE_DATA,
            assignee: myEmail,
            atRiskPopulationFilter:
              vehiclesFilterSortState.filtersWithQuickFilters,
            claimFilter: claimsFilterSortState.filtersWithQuickFilters,
            signalEventOccurrencesFilter,
          }}
          currentPage="claimAnalytics"
        />
      }
      filtersProps={[
        {
          filterSortState: claimsFilterSortState,
          defaultFilters: defaultClaimFilters,
          filterSchema: claimsFiltersSchema,
          filterTitle: CLAIMS_FILTER_LABEL,
          filterLabel: claimFilterLabel,
          pendingFilterKey: PENDING_FILTERS_CLAIMS_LS_KEY,
          entity: "Claims",
          testId: "claims",
          quickFilterLabel: "Claim Quick Filters",
        },
        {
          filterSortState: vehiclesFilterSortState,
          defaultFilters: defaultVehicleFilters,
          filterSchema: vehiclesFiltersSchema,
          filterTitle: VEHICLES_FILTER_LABEL,
          filterLabel: vehiclePopulationFilterLabel,
          pendingFilterKey: PENDING_FILTERS_VEHICLES_LS_KEY,
          entity: "Vehicles",
          testId: "vehicles",
          quickFilterLabel: "Vehicle Quick Filters",
        },
      ]}
    />
  );
};

export default ClaimAnalyticsFilters;
