import { Dispatch, SetStateAction } from "react";
import { FiPlus as AddIcon } from "react-icons/fi";
import { DragEndEvent } from "@dnd-kit/core";

import { randomID } from "shared/utils";

import { handleOnSortEnd } from "pages/SuperAdmin/utils";

import Button from "features/ui/Button";
import Title from "features/ui/Title";

import MetabaseConfigContainer from "./MetabaseConfigContainer";
import { MetabaseDashboardState } from "./types";

const MetabaseDashboards = ({
  metabaseDashboards,
  setMetabaseDashboards,
}: {
  metabaseDashboards: MetabaseDashboardState[];
  setMetabaseDashboards: Dispatch<SetStateAction<MetabaseDashboardState[]>>;
}) => {
  const onAddDashboard = () =>
    setMetabaseDashboards([
      ...metabaseDashboards,
      { realID: "", name: "", id: randomID() },
    ]);

  const onSortEnd = (event: DragEndEvent) => {
    handleOnSortEnd(event, setMetabaseDashboards);
  };

  const onDelete = (id: string) =>
    setMetabaseDashboards(metabaseDashboards.filter((row) => row.id !== id));

  const onUpdate = (id: string, row: MetabaseDashboardState) =>
    setMetabaseDashboards(
      metabaseDashboards.map((metabaseDashboard) =>
        metabaseDashboard.id === id ? row : metabaseDashboard
      )
    );

  return (
    <div className="mt-4">
      <div className="flex items-center w-full justify-between mb-4">
        <Title text="Metabase Dashboards" />
        <Button
          label="Add Metabase Dashboard"
          color="primary"
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          onClick={onAddDashboard}
        />
      </div>
      <MetabaseConfigContainer
        configuration={metabaseDashboards}
        updateValueByID={onUpdate}
        onSortEnd={onSortEnd}
        onDelete={onDelete}
      />
    </div>
  );
};

export default MetabaseDashboards;
