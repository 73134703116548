import { Dispatch, SetStateAction } from "react";
import { FiPlus as AddIcon } from "react-icons/fi";
import { DragEndEvent } from "@dnd-kit/core";

import { randomID } from "shared/utils";

import { handleOnSortEnd } from "pages/SuperAdmin/utils";

import Button from "features/ui/Button";
import Title from "features/ui/Title";

import BookmarkConfigContainer from "./BookmarkConfigContainer";
import { BookmarkState } from "./types";

const Bookmarks = ({
  bookmarks,
  setBookmarks,
}: {
  bookmarks: BookmarkState[];
  setBookmarks: Dispatch<SetStateAction<BookmarkState[]>>;
}) => {
  const onAddBookmark = () =>
    setBookmarks([
      ...bookmarks,
      { title: "", path: "", description: "", id: randomID() },
    ]);

  const onUpdate = (internalID: string, row: BookmarkState) =>
    setBookmarks(
      bookmarks.map((bookmark) => (bookmark.id === internalID ? row : bookmark))
    );

  const onSortEnd = (event: DragEndEvent) => {
    handleOnSortEnd(event, setBookmarks);
  };

  const onDelete = (id: string) =>
    setBookmarks(bookmarks.filter((row) => row.id !== id));

  return (
    <div className="mt-4">
      <div className="flex items-center w-full justify-between mb-4">
        <Title text="Bookmarks" />
        <Button
          label="Add Bookmark"
          color="primary"
          variant="outlined"
          size="small"
          startIcon={<AddIcon />}
          onClick={onAddBookmark}
        />
      </div>
      <BookmarkConfigContainer
        configuration={bookmarks}
        updateValueByID={onUpdate}
        onSortEnd={onSortEnd}
        onDelete={onDelete}
      />
    </div>
  );
};

export default Bookmarks;
