import { sendAgentResponse } from "duck/graph/sendAgentResponse/sendAgentResponse";
import { assertNonEmptyStringArray } from "duck/ui/utils";
import { z } from "zod";
import { DynamicStructuredTool, tool } from "@langchain/core/tools";

import {
  GROUP_BY_ATTRIBUTE_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_TITLE,
} from "pages/constants";

import { GoToTopContributorsTabToolParams } from "./types";

const getGoToTopContributorsTabTool = ({
  pageHandler,
  chartOptions,
  groupByOptions,
  selectedTab,
  selectedChartOptions,
  selectedGroupByAttribute,
  defaultGroupByAttribute,
  pageKey,
  chartOptionsKey,
  groupByOptionsKey,
  setEphemeralMessage,
  setAgentResponse,
}: GoToTopContributorsTabToolParams): DynamicStructuredTool => {
  const { y: yOptions, exposure: exposureOptions } = chartOptions;
  const exposureOptionsKeys = Object.keys(exposureOptions);
  assertNonEmptyStringArray(exposureOptionsKeys);

  const goToTopContributorsTabSchema = z.object({
    groupByAttribute: z
      .string()
      .optional()
      .describe(
        `Group by attribute. Call the 'retrieveTopContributorsGroupByOptions' tool to get the available options. Default value: '${defaultGroupByAttribute}'.`
      ),
    y: z
      .enum(yOptions)
      .optional()
      .describe(
        `The metric to be displayed on the y-axis of the chart. Default value: '${chartOptions.y[0]}'.`
      ),
    exposure: z
      .enum(exposureOptionsKeys)
      .optional()
      .describe(
        `The exposure of the chart. Default value: 'None'. Exposure options: ${JSON.stringify(exposureOptionsKeys)}`
      ),
    exposureBucket: z
      .number()
      .optional()
      .describe(
        `The exposure bucket that goes with the exposure. The default exposure bucket is the first available option for the selected exposure. The valid exposure bucket options are shown for each exposure: ${JSON.stringify(exposureOptions)}`
      ),
  });

  type GoToTopContributorsTab = z.infer<typeof goToTopContributorsTabSchema>;

  const goToTopContributorsTab = ({
    groupByAttribute,
    y,
    exposure,
    exposureBucket,
  }: GoToTopContributorsTab) => {
    setEphemeralMessage("to top contributors tab");
    console.debug(
      "Navigating to Top Contributors tab with group by attribute:",
      { groupByAttribute, y, exposure, exposureBucket }
    );

    const nonNullExposure =
      exposure ??
      selectedChartOptions.exposure ??
      Object.keys(chartOptions.exposure)[0];

    let nonNullExposureBucket =
      exposureBucket ??
      selectedChartOptions.exposureBucket ??
      exposureOptions[nonNullExposure][0] ??
      0;

    // We fix invalid exposure bucket selections instead of throwing an error
    if (
      !exposureOptions[nonNullExposure].includes(String(nonNullExposureBucket))
    ) {
      nonNullExposureBucket = exposureOptions[nonNullExposure][0] ?? 0;
    }

    const nonNullGroupByAttribute =
      groupByAttribute ?? selectedGroupByAttribute ?? defaultGroupByAttribute;

    const nonNullY = y ?? selectedChartOptions.y ?? chartOptions.y[0];

    if (!groupByOptions.includes(nonNullGroupByAttribute)) {
      throw new Error(
        `Invalid group by attribute: '${nonNullGroupByAttribute}'. Valid options: ${groupByOptions.join(", ")}.`
      );
    }

    if (!yOptions.includes(nonNullY)) {
      throw new Error(
        `Invalid y option: '${nonNullY}'. Valid options: ${yOptions.join(", ")}.`
      );
    }

    if (!exposureOptionsKeys.includes(nonNullExposure)) {
      throw new Error(
        `Invalid exposure option: '${nonNullExposure}'. Valid options: ${exposureOptionsKeys.join(", ")}.`
      );
    }

    pageHandler.updateTabChartSettings(pageKey, TOP_CONTRIBUTORS_TAB_KEY, {
      [chartOptionsKey]: [
        { id: "y", optionId: nonNullY },
        { id: "exposure", optionId: nonNullExposure },
        { id: "exposureBucket", optionId: nonNullExposureBucket },
      ],
      [groupByOptionsKey]: [
        {
          id: GROUP_BY_ATTRIBUTE_KEY,
          // The optionId was URL encoded in the availableData collection,
          // so we need to decode it here.
          optionId: decodeURIComponent(nonNullGroupByAttribute),
        },
      ],
    });

    sendAgentResponse({
      setAgentResponse,
      selectedTabKey: selectedTab,
      newTabKey: TOP_CONTRIBUTORS_TAB_KEY,
      newTabTitle: TOP_CONTRIBUTORS_TAB_TITLE,
      chartName: TOP_CONTRIBUTORS_TAB_TITLE,
      chartOptionsKey,
      selectedChartOptions,
      newChartOptions: {
        exposure: nonNullExposure,
        exposureBucket: String(nonNullExposureBucket),
        y: nonNullY,
      },
      selectedGroupByAttribute,
      newGroupByAttribute: nonNullGroupByAttribute,
    });

    return "queued navigation to Top Contributors tab";
  };

  return tool(goToTopContributorsTab, {
    name: "queueGoToTopContributorsTab",
    description: `Navigate to the Top Contributors tab to view a chart and a table that show how the selected metric is distributed across the selected attribute. This data helps identify the values of attributes that contribute the most to the selected metric.
Use this tool when the user wants to:
- View the Top Contributors tab
- Select a different attribute to group the chart data
- Select a different metric to view on the y-axis of the chart
- Select a different exposure for the chart
- Select a different exposure bucket for the chart`,
    schema: goToTopContributorsTabSchema,
  });
};

export default getGoToTopContributorsTabTool;
