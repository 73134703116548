import classNames from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";

import { EventTypeEnum } from "shared/types";

import Tooltip from "features/ui/Tooltip";

interface Props {
  uniqueExposureValues: Set<number>;
  label: string;
  stickyFirstColumn?: boolean;
  eventType: EventTypeEnum;
}

const NUMBER_OF_NON_EXPOSURE_COLUMNS = 1;

// see prepareVehiclesByAgeTableSchemaAndData() to see the number of cols used
const DEFAULT_EXPOSURE_COLSPAN = 3;
const CLAIMS_EXPOSURE_COLSPAN = 7;
const SIGNAL_EVENTS_EXPOSURE_COLSPAN = 7;

const MISColumnHeaders = ({
  uniqueExposureValues,
  label,
  stickyFirstColumn = false,
  eventType,
}: Props) => {
  const { warrantyClaimsCost } = useFlags();

  // Claims have an extra column due to cost data (CPV)
  const hasCostColumns = Boolean(
    warrantyClaimsCost && eventType === EventTypeEnum.CLAIM
  );

  let initialColspan = DEFAULT_EXPOSURE_COLSPAN;
  if (eventType === EventTypeEnum.CLAIM) {
    initialColspan = CLAIMS_EXPOSURE_COLSPAN;
  } else if (eventType === EventTypeEnum.SIGNAL_EVENT) {
    initialColspan = SIGNAL_EVENTS_EXPOSURE_COLSPAN;
  }

  const EXPOSURE_COLSPAN = hasCostColumns ? initialColspan + 2 : initialColspan;

  return (
    <>
      <th
        colSpan={NUMBER_OF_NON_EXPOSURE_COLUMNS}
        className={classNames({
          "sticky left-0 bg-white z-10": stickyFirstColumn,
        })}
      ></th>
      {Array.from(uniqueExposureValues).map((exposureBucket, index) => (
        <th
          key={exposureBucket + index}
          colSpan={EXPOSURE_COLSPAN}
          className="p-2 font-semibold text-gray-500 tracking-wider uppercase whitespace-nowrap cursor-default"
          style={{ fontSize: "0.6rem" }}
        >
          <Tooltip title={<span>{label}</span>}>
            <span>{label}</span>
          </Tooltip>
          {exposureBucket}
        </th>
      ))}
    </>
  );
};

export default MISColumnHeaders;
