import classNames from "classnames";
import { MdOutlineThumbDown, MdOutlineThumbUp } from "react-icons/md";
import { IconButton } from "@mui/material";

interface DuckFeedbackButtonProps {
  onClick: () => void;
  positive: boolean | null;
  up: boolean;
}

const DuckFeedbackButton = ({
  onClick,
  positive,
  up,
}: DuckFeedbackButtonProps) => {
  const Thumb = up ? MdOutlineThumbUp : MdOutlineThumbDown;

  return (
    <IconButton onClick={onClick} size="small">
      <Thumb
        size={20}
        className={classNames({
          "text-black": positive === up,
          "text-gray-300": positive !== up,
        })}
      />
    </IconButton>
  );
};

export default DuckFeedbackButton;
