import { API_DATE_FORMAT } from "shared/constants";
import { formatDate } from "shared/utils";

import {
  CustomSignalEventsConfig,
  PageConfigProps,
} from "pages/SuperAdmin/PagesConfig/types";

import DatePickerRange from "features/ui/DatePickerRange";

const EVENT_PREVIEW_FROM_DATE_KEY = "eventPreviewFromDate";
const EVENT_PREVIEW_TO_DATE_KEY = "eventPreviewToDate";

const CustomSignalEventsConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<CustomSignalEventsConfig>) => (
  <DatePickerRange
    startDateLabel="Default preview from date"
    endDateLabel="Default preview to date"
    initialDateStart={
      config[EVENT_PREVIEW_FROM_DATE_KEY]
        ? new Date(config[EVENT_PREVIEW_FROM_DATE_KEY])
        : null
    }
    initialDateEnd={
      config[EVENT_PREVIEW_TO_DATE_KEY]
        ? new Date(config[EVENT_PREVIEW_TO_DATE_KEY])
        : null
    }
    onChange={(dateFrom, dateTo) => {
      onConfigChange(
        pageId,
        EVENT_PREVIEW_FROM_DATE_KEY,
        dateFrom ? formatDate(dateFrom.toISOString(), API_DATE_FORMAT) : null
      );
      onConfigChange(
        pageId,
        EVENT_PREVIEW_TO_DATE_KEY,
        dateTo ? formatDate(dateTo.toISOString(), API_DATE_FORMAT) : null
      );
    }}
  />
);

export default CustomSignalEventsConfigPage;
