import qs from "qs";

import { useListVehiclesCount } from "shared/api/vehicles/hooks";
import { TestProps } from "shared/types";

import { VEHICLES_PAGE_KEY } from "pages/Vehicles/constants";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import { getFiltersKey } from "features/ui/Filters/utils";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

interface Props extends TestProps {
  vehicleFilter: FilterGroupState;
}

const EligibleVehicleCount = ({ vehicleFilter, testId }: Props) => {
  const filterQuery = getFiltersQuery(vehicleFilter);
  const { isLoading, data, error } = useListVehiclesCount({
    filter: filterQuery,
  });

  return (
    <TableCount
      count={data?.count}
      isLoading={isLoading}
      error={!!error}
      entityName="Vehicle"
      extraClasses="font-normal mb-0.5"
      testId={testId}
      link={{
        pathname: routes.vehicles,
        search: qs.stringify(
          {
            [getFiltersKey(VEHICLES_PAGE_KEY)]: filterQuery,
          },
          { arrayFormat: "indices" }
        ),
      }}
    />
  );
};

export default EligibleVehicleCount;
