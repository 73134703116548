import { useContext } from "react";

import "./duck-loading.css";

import { DuckContext } from "duck/context/DuckContextWrapper";
import { Stack } from "@mui/material";

import DuckLogo from "./DuckLogo";
import ThoughtBubbleTail from "./ThoughtBubbleTail";

interface DuckLoadingAnimationProps {
  overrideMessage?: string;
}

const DuckLoadingAnimation = ({
  overrideMessage,
}: DuckLoadingAnimationProps) => {
  const { ephemeralMessage } = useContext(DuckContext);

  return (
    <Stack alignItems="center" direction="column" spacing={1}>
      <Stack alignItems="center" direction="column">
        <p className="bg-gray-100 border border-gray-200 text-gray-400 px-2 py-1 rounded-xl text-xs">
          {overrideMessage ?? ephemeralMessage ?? "quack"}
        </p>
        <ThoughtBubbleTail />
      </Stack>
      <div className="container">
        <div className="animate-duck-loading">
          <DuckLogo />
        </div>
      </div>
    </Stack>
  );
};

export default DuckLoadingAnimation;
