import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { mutate } from "swr";

import { APIError } from "shared/api/api";
import {
  GeneralConfigModel,
  updateGeneralConfig,
} from "shared/api/superadmin/api";
import { useGeneralConfig } from "shared/api/superadmin/hooks";
import { formatAPIDate } from "shared/api/utils";
import { MileageUnit } from "shared/types";
import { randomID } from "shared/utils";

import { DEFAULT_GENERAL_CONFIG } from "pages/SuperAdmin/constants";
import { showErrorToast } from "pages/SuperAdmin/utils";

import Button from "features/ui/Button";
import DatePicker from "features/ui/DatePicker";
import Input from "features/ui/Input";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { isValidEmail } from "features/ui/PermissionsDialog/utils";
import Select from "features/ui/Select";
import { DataType } from "features/ui/Table/TableBodyCell/types";

import Bookmarks from "./Bookmarks";
import MetabaseDashboards from "./MetabaseDashboards";
import { BookmarkState, MetabaseDashboardState } from "./types";

const TITLE = "General Config";

const mileageUnitOptions = [
  { id: "km" as MileageUnit, value: "Kilometers" },
  { id: "mi" as MileageUnit, value: "Miles" },
];

const GeneralConfig = () => {
  const [generalConfigState, setGeneralConfigState] =
    useState<GeneralConfigModel>(DEFAULT_GENERAL_CONFIG);

  const [bookmarks, setBookmarks] = useState<BookmarkState[]>([]);
  const [metabaseDashboards, setMetabaseDashboards] = useState<
    MetabaseDashboardState[]
  >([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    data,
    isLoading,
    error,
    requestKey: adminGeneralConfigRequestKey,
  } = useGeneralConfig({});

  const onDataUpdated = (update: Partial<GeneralConfigModel>) => {
    setGeneralConfigState({ ...generalConfigState, ...update });
  };

  useEffect(() => {
    if (data && !isLoading && !error) {
      setGeneralConfigState(data);
      setBookmarks(
        data.bookmarks.map((bookmark) => ({
          ...bookmark,
          id: randomID(),
        }))
      );
      setMetabaseDashboards(
        data.metabaseDashboards.map((metabaseDashboard) => ({
          ...metabaseDashboard,
          id: randomID(),
          realID: metabaseDashboard.id,
        }))
      );
    }
  }, [data, isLoading, error]);

  const handleSave = async () => {
    setIsSubmitting(true);

    updateGeneralConfig({
      config: {
        ...generalConfigState,
        rootPage: generalConfigState.rootPage || null,
        helpContactEmail: generalConfigState.helpContactEmail || null,
        bookmarks: bookmarks.map(({ id, ...rest }: BookmarkState) => rest),
        metabaseDashboards: metabaseDashboards.map(
          ({ realID, id, ...rest }: MetabaseDashboardState) => ({
            ...rest,
            id: realID,
          })
        ),
      },
    })
      .then(() => {
        toast.success("General configuration saved successfully");
        mutate(adminGeneralConfigRequestKey);
      })
      .catch((error: APIError) => {
        showErrorToast(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const ctaEnabled = !isSubmitting;

  return (
    <>
      <PageHeaderWrapper title={TITLE} />
      {error && <p className="text-red-500">Failed to load configuration.</p>}
      {isLoading && <Skeleton height={100} />}
      {!isLoading && !error && (
        <div className="mt-4 max-w-4xl">
          <div className="pb-3 max-w-48">
            <Select
              label="Mileage Unit"
              options={mileageUnitOptions}
              selected={
                mileageUnitOptions.find(
                  ({ id }) => id === generalConfigState.mileageUnit
                ) || undefined
              }
              onSelect={(selectedOption) =>
                onDataUpdated({ mileageUnit: selectedOption.id as MileageUnit })
              }
            />
          </div>
          <DatePicker
            label="Max Date"
            initialDate={
              generalConfigState.maxDate
                ? new Date(generalConfigState.maxDate)
                : null
            }
            key={(generalConfigState.maxDate !== "").toString()}
            onChange={(date: Date | null) => {
              onDataUpdated({
                maxDate: date
                  ? formatAPIDate(date.toString(), DataType.DATE)
                  : null,
              });
            }}
          />
          <div className="mt-6">
            <Input
              label="Help Contact Email"
              value={generalConfigState.helpContactEmail || ""}
              onChange={({ target: { value } }) =>
                onDataUpdated({ helpContactEmail: value })
              }
            />
          </div>
          <div className="mt-3">
            <div className="text-gray-400 text-xs mb-2">
              For tenants that do not have Landing page enabled (feature flag),
              we redirect them to root page. It is usually set to /vehicles.
            </div>
            <Input
              label="Root Page"
              value={generalConfigState.rootPage || ""}
              onChange={({ target: { value } }) =>
                onDataUpdated({ rootPage: value })
              }
            />
          </div>
          <Bookmarks bookmarks={bookmarks} setBookmarks={setBookmarks} />
          <MetabaseDashboards
            metabaseDashboards={metabaseDashboards}
            setMetabaseDashboards={setMetabaseDashboards}
          />
          <div className="flex justify-end mt-4">
            <Button
              label="Save"
              color="primary"
              variant="contained"
              testId="save-pages-config"
              onClick={handleSave}
              disabled={
                !ctaEnabled ||
                (generalConfigState.helpContactEmail !== null &&
                  generalConfigState.helpContactEmail.length > 0 &&
                  !isValidEmail(generalConfigState.helpContactEmail || ""))
              }
              isLoading={isSubmitting}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default GeneralConfig;
