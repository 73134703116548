import { AxisId } from "./types";

export const AXIS_TOOLTIP_FONT_SIZE = 12;
export const AXIS_TEXT_COLOR = "#808080";
export const LABEL_COLOR = "#808080";
export const LINE_COLOR = "#1EA7FD";
export const BAR_COLOR_SELECTED = "#1EA7FD";
export const BAR_COLOR_UNSELECTED = "rgba(30, 167, 253, 0.2)";
export const BAR_COLOR_SELECTED_HOVER = "rgba(30, 167, 253, 0.8)";
export const BAR_COLOR_UNSELECTED_HOVER = "rgba(30, 167, 253, 0.4)";
export const BAR_COLOR_NOW = "#D5AA01";
export const BAR_COLOR_LAST_WEEK = "#EEDC99";
export const REFERENCE_LINE_COLOR = "#FF7A7A";
export const PROJECTIONS_LINE_COLOR = "#000000";
export const AREA_COLOR = "#1EA7FD";
export const CONNECTING_LINE_COLOR = "#000000";
export const FAILURES_LINE_COLOR = "#F08080";
export const REFERENCE_LINE_WIDTH = 4;
export const REFERENCE_LINE_ADDITIONAL_CLICKABLE_WIDTH = 16;
export const REFERENCE_LINE_OPACITY = 0.5;
export const MANUFACTURE_DATE_LINE_COLOR = "#3C79F5";
export const STARTED_DRIVING_AT_COLOR = "#FFC93C";
export const SELECTED_DOT_COLOR = "#808080";
export const TIMESTAMP_X_AXIS_KEY = "ts";
export const GRAPH_STROKE_WIDTH = 2;
export const DEFAULT_Y_AXIS_ID: AxisId = "left";
export const DEFAULT_Y_AXIS_ID_R: AxisId = "right";
export const DASHBOARD_GROUP_BY_KEY = "xAxisKey";

// random colors taken from https://www.heavy.ai/blog/12-color-palettes-for-telling-better-stories-with-your-data
export const COLOR_PALETTE = [
  "#e60049",
  "#0bb4ff",
  "#50e991",
  "#e6d800",
  "#9b19f5",
  "#ffa300",
  "#dc0ab4",
  "#b3d4ff",
  "#00bfa0",
  "#ea5545",
  "#f46a9b",
  "#ef9b20",
  "#edbf33",
  "#ede15b",
  "#bdcf32",
  "#87bc45",
  "#27aeef",
  "#b33dc6",
  "#b30000",
  "#7c1158",
  "#4421af",
  "#1a53ff",
  "#0d88e6",
  "#00b7c7",
  "#5ad45a",
  "#8be04e",
  "#ebdc78",
];

export const DARK_PASTEL_COLOR_PALETTE = [
  "#674ea7",
  "#3d85c6",
  "#e69138",
  "#a64d79",
  "#6aa84f",
  "#2d5b6b",
  "#c47a53",
  "#8f4731",
  "#52494c",
  "#7b7d2a",
  "#5f7a79",
  "#e2a572",
  "#50619e",
  "#4c708b",
  "#b6585b",
  "#7d87a1",
  "#556c3c",
  "#9d6c6a",
  "#a9b46a",
  "#875c74",
];
