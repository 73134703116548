import { useState } from "react";
import { routes } from "instanceManagement/routes";
import { CgSpinnerTwo as LoadingIcon } from "react-icons/cg";
import { generatePath, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Alert from "@mui/material/Alert";

import {
  InstanceManagementTenantRequest,
  newTenant,
} from "shared/api/instanceManagement/api";

import { CANCEL_CTA_TEXT, CREATE_CTA_TEXT } from "pages/constants";

import Button from "features/ui/Button";
import Input from "features/ui/Input";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { isValidEmail } from "features/ui/PermissionsDialog/utils";
import TagsInput from "features/ui/TagsInput/TagsInput";

import { NEW_TENANT_TITLE, ON_SUCCESS_CREATE_TEXT } from "./constants";
import { validateForm } from "./utils";

const ServicePlan = () => {
  const navigate = useNavigate();

  const isCreatePage = true;

  const [name, setName] = useState("");
  const [admins, setAdmins] = useState<string[]>([]);
  const [superAdmins, setSuperAdmins] = useState<string[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [validationErrors, setValidationErrors] = useState("");

  const [showStaticValidationErrors, setShowStaticValidationErrors] =
    useState(!isCreatePage);

  const { valid, message: staticErrorMessage } = validateForm(name);

  const handleTenantCreate = (data: InstanceManagementTenantRequest) => {
    newTenant(data)
      .then(() => {
        toast.success(ON_SUCCESS_CREATE_TEXT);
        navigate(generatePath(routes.tenants));
      })
      .catch((error) => {
        setValidationErrors(`${error.message}: ${error.response.data.details}`);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const submitCallback = handleTenantCreate;

  const handleOnCancel = () => {
    navigate(routes.tenants);
  };

  const handleOnSubmit = () => {
    if (!valid) {
      setShowStaticValidationErrors(true);

      return;
    }

    setValidationErrors("");
    setIsSubmitting(true);
    submitCallback({
      name,
      admins,
      superAdmins,
    });
  };

  const ctaIcon =
    (isSubmitting && <LoadingIcon className="animate-spin" />) || undefined;

  const showError =
    validationErrors || (showStaticValidationErrors && staticErrorMessage);

  return (
    <div>
      <div className="max-w-5xl">
        {showError && (
          <Alert
            severity="error"
            data-testid="sps-form-validation-errors"
            className="mb-5"
          >
            <div className="font-bold mb-1">Validation errors</div>
            {validationErrors || staticErrorMessage}
          </Alert>
        )}
        <PageHeaderWrapper title={NEW_TENANT_TITLE} />
        <div className="mb-2 flex justify-between">
          <div className="flex w-full">
            <div className="w-36 mr-4 leading-9 text-right">Name</div>
            <Input
              value={name}
              onChange={({ target: { value } }) => setName(value)}
              testId="sps-name"
              fullWidth={false}
              className="w-80"
              tabIndex={0}
              error={!!showError}
              helperText="Only lowercase letters and numbers."
            />

            <div className="flex items-start ml-auto">
              <Button
                color="secondary"
                variant="outlined"
                label={CANCEL_CTA_TEXT}
                onClick={handleOnCancel}
                isLoading={isSubmitting}
                endIcon={ctaIcon}
                disabled={isSubmitting}
                testId="sps-form-cancel-cta"
                size="medium"
                className="mr-4!"
                tabIndex={-1}
              />
              <Button
                color="primary"
                variant="contained"
                label={CREATE_CTA_TEXT}
                onClick={handleOnSubmit}
                isLoading={isSubmitting}
                endIcon={ctaIcon}
                disabled={isSubmitting}
                testId="sps-form-submit-cta"
                size="medium"
                tabIndex={-1}
              />
            </div>
          </div>
        </div>
        <div className="mb-2 flex justify-between mt-6">
          <div className="w-36 mr-4 leading-9 text-right shrink-0">
            Admin Users
          </div>
          <div className="w-full">
            <TagsInput
              label="Admin Users"
              key="admin-users"
              fieldName="AdminUsers"
              testId="initial-admin-users"
              onChange={(options) => {
                setAdmins(options);
              }}
              validationFunction={(value: string) => isValidEmail(value)}
              validationErrorMessage="Not a valid email."
              values={admins}
              caseInsensitive
              helperText="Enter Admin user's email address. Press Enter to add a new one."
            />
          </div>
        </div>
        <div className="mb-2 flex justify-between mt-6">
          <div className="w-36 mr-4 leading-9 text-right shrink-0">
            SuperAdmin Users
          </div>
          <div className="w-full">
            <TagsInput
              label="SuperAdmin Users"
              key="super-admin-users"
              fieldName="SuperAdminUsers"
              testId="initial-superadmin-users"
              onChange={(options) => {
                setSuperAdmins(options);
              }}
              validationFunction={(value: string) => isValidEmail(value)}
              validationErrorMessage="Not a valid email."
              values={superAdmins}
              caseInsensitive
              helperText="Enter SuperAdmin user's email address. Press Enter to add a new one."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicePlan;
