import classNames from "classnames";
import {
  FiTrash2 as DeleteRowIcon,
  FiPlusSquare as NewGroupIcon,
  FiPlus as NewRowIcon,
} from "react-icons/fi";
import { TbFilterBolt as QuickFilterIcon } from "react-icons/tb";
import IconButton from "@mui/material/IconButton";

import Tooltip from "features/ui/Tooltip";

interface Props {
  id: string;
  onNewRow: (id: string) => void;
  onNewGroup: (id: string) => void;
  onDelete?: (id: string) => void;
  disabled?: boolean;
  hideAddGroupCta?: boolean;
  hasInputFocus?: boolean;
  onMoveToQuickFilters?: (id: string) => void;
}

const RowActions = ({
  id,
  onNewRow,
  onNewGroup,
  onMoveToQuickFilters,
  onDelete,
  disabled,
  hideAddGroupCta,
  hasInputFocus,
}: Props) => (
  <div
    className={classNames(
      "ml-auto flex flex-nowrap self-center space-x-1 order-3",
      {
        "invisible pointer-events-none": !hasInputFocus,
        "visible pointer-events-auto": hasInputFocus,
        // added at the end because of css class ordering
        "group-hover/row:visible group-hover/row:pointer-events-auto group-hover/group:visible group-hover/group:pointer-events-auto":
          true,
      }
    )}
    data-testid="filter-row-actions"
  >
    {onMoveToQuickFilters && (
      <Tooltip title="Add as quick filter">
        <IconButton
          onClick={() => onMoveToQuickFilters(id)}
          size="small"
          data-testid="filter-action-add-row"
          disabled={disabled}
        >
          <QuickFilterIcon />
        </IconButton>
      </Tooltip>
    )}

    <Tooltip title="Add row">
      <IconButton
        onClick={() => onNewRow(id)}
        size="small"
        data-testid="filter-action-add-row"
        disabled={disabled}
      >
        <NewRowIcon />
      </IconButton>
    </Tooltip>

    {!hideAddGroupCta && (
      <Tooltip title="Add group">
        <IconButton
          onClick={() => onNewGroup(id)}
          size="small"
          data-testid="filter-action-add-group"
          disabled={disabled}
        >
          <NewGroupIcon />
        </IconButton>
      </Tooltip>
    )}

    <Tooltip title="Delete row">
      <IconButton
        disabled={!onDelete || disabled}
        onClick={onDelete ? () => onDelete(id) : undefined}
        size="small"
        data-testid="filter-action-delete"
      >
        <DeleteRowIcon />
      </IconButton>
    </Tooltip>
  </div>
);

export default RowActions;
