import {
  DEFAULT_CONFIG,
  ENABLED_KEY,
  KnownPageConfigKeys,
  PageConfigValues,
  PagesConfig,
} from "./types";

export const updateConfig = (
  prevConfig: PagesConfig,
  pageID: KnownPageConfigKeys,
  configKey: string,
  configValue: PageConfigValues
): PagesConfig => {
  if (configKey === ENABLED_KEY) {
    if (configValue) {
      if (prevConfig.some(({ ID }) => ID === pageID)) {
        return prevConfig;
      }

      return [
        ...prevConfig,
        {
          ID: pageID,
          config: DEFAULT_CONFIG,
        },
      ];
    } else {
      return prevConfig.filter((item) => item.ID !== pageID);
    }
  }

  return prevConfig.map((item) =>
    item.ID === pageID
      ? { ...item, config: { ...item.config, [configKey]: configValue } }
      : item
  );
};
