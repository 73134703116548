import Skeleton from "react-loading-skeleton";

import { RiskLabel } from "shared/api/failureModes/api";
import { formatNumber, toTitleCase } from "shared/utils";

interface RiskCountProps {
  risk: RiskLabel;
  count?: number;
}

const RiskCount = ({ risk, count }: RiskCountProps) => {
  if (!risk) return null;

  return (
    <div className="flex-1 flex flex-col justify-center items-center shadow-sm px-4 py-3 text-center rounded-sm">
      <div
        className="text-3xl font-semibold"
        data-testid={`risk-count-${risk.toLowerCase()}`}
      >
        {(count !== undefined && formatNumber(count)) || <Skeleton />}
      </div>
      <div>{toTitleCase(risk)} Risk Vehicles</div>
    </div>
  );
};

export default RiskCount;
