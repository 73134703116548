import Skeleton from "react-loading-skeleton";

import {
  getInspectionsByVehicleAgeTimelineExport,
  InspectionsByVehicleAgeAgeRequest,
} from "shared/api/inspections/api";
import { useListInspectionsByVehicleAgeTimeline } from "shared/api/inspections/hooks";
import { NO_BY_PRODUCTION_DATE_ATTRIBUTES_CONFIGURED } from "shared/constants";
import { useCustomLocalStorageState } from "shared/hooks";
import { useInspectionsSchema } from "shared/schemas/inspectionsSchema";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { EventTypeEnum, EventTypeLabel } from "shared/types";

import {
  useByVehicleAgeBirthdayOptions,
  useByVehicleAgeOptions,
  useInspectionByVehicleAgeExposureOptions,
} from "pages/hooks";
import { InspectionsFiltersProps } from "pages/InspectionAnalytics/InspectionAnalyticsTabs";
import ByVehicleAgeChart from "pages/shared/analytics/ByVehicleAgeChart";
import ByVehicleAgeTable from "pages/shared/analytics/ByVehicleAgeTable";
import {
  areThereAnyByAgeAttributes,
  getByVehicleAgeChartOptions,
  isSelectedByAgeAttributeInvalid,
} from "pages/utils";

import {
  cumulativeEvents,
  cumulativeIPTV,
  totalVehicles,
} from "features/ui/charts/actions";
import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";

const CHART_KEY = "inspectionAnalyticsByVehicleAgeChartOptions";

const ByVehicleAge = ({
  inspectionsFilters,
  vehiclesFilters,
  onBadRequest,
  onAddDateRangeToVehicleFilters,
}: InspectionsFiltersProps) => {
  const actions = getByVehicleAgeChartOptions(
    [
      cumulativeIPTV,
      totalVehicles,
      cumulativeEvents(EventTypeLabel.INSPECTION),
    ],
    useByVehicleAgeBirthdayOptions(),
    useInspectionByVehicleAgeExposureOptions()
  );

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(CHART_KEY, { defaultValue: getDefaultActions(actions) });

  const byVehicleAgeData = useByVehicleAgeOptions(selectedOptions, actions);

  const { byVehicleAgeBirthday, byVehicleAgeExposure, granularity } =
    byVehicleAgeData;

  const requestParams: InspectionsByVehicleAgeAgeRequest = {
    inspectionsFilter:
      inspectionsFilters && getFiltersQuery(inspectionsFilters),
    vehiclesFilter: vehiclesFilters && getFiltersQuery(vehiclesFilters),
    byVehicleAgeBirthday,
    byVehicleAgeExposure,
    granularity,
  };

  const { data, isLoading, error } =
    useListInspectionsByVehicleAgeTimeline(requestParams);

  const { attributes: vehicleAttributes } = useVehiclesSchema();
  const { attributes: inspectionAttributes } = useInspectionsSchema();
  if (!areThereAnyByAgeAttributes(vehicleAttributes, inspectionAttributes)) {
    return NO_BY_PRODUCTION_DATE_ATTRIBUTES_CONFIGURED;
  } else if (
    isSelectedByAgeAttributeInvalid(
      byVehicleAgeData,
      vehicleAttributes,
      inspectionAttributes
    )
  ) {
    setSelectedOptions(getDefaultActions(actions));
  }

  if (isLoading) {
    return <Skeleton height={500} count={2} className="mt-5" />;
  }

  return (
    <div className="mt-5">
      <ByVehicleAgeChart
        byVehicleAgeData={byVehicleAgeData}
        data={data}
        error={error}
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        onBadRequest={onBadRequest}
        onAddDateRangeToVehicleFilters={onAddDateRangeToVehicleFilters}
      />
      <ByVehicleAgeTable
        eventType={EventTypeEnum.INSPECTION}
        byVehicleAgeData={byVehicleAgeData}
        filters={mergeFilterGroupStates(inspectionsFilters, vehiclesFilters)}
        data={data}
        error={error}
        downloadRequestParams={requestParams}
        downloadFunc={getInspectionsByVehicleAgeTimelineExport}
        onBadRequest={onBadRequest}
      />
    </div>
  );
};

export default ByVehicleAge;
