import classNames from "classnames";

import {
  FilterDiffData,
  FilterOperator,
  FilterOverviewFormat,
  FilterValue,
} from "features/ui/Filters/types";
import { getFormattedFilter } from "features/ui/Filters/utils";
import Label from "features/ui/Label";
import { DataType } from "features/ui/Table/TableBodyCell/types";

interface Props {
  fieldName: string;
  operator: FilterOperator;
  fieldValue: FilterValue;
  format?: FilterOverviewFormat;
  dense?: boolean;
  dataType?: DataType;
  diff?: FilterDiffData;
}

const DENSE_CLASSES = "text-xs!";

const FLabel = ({
  fieldName,
  operator,
  fieldValue,
  format = "badge",
  dense,
  dataType = DataType.STRING,
  diff,
}: Props) => {
  const { formattedFieldName, formattedOperator, formattedFieldValue } =
    getFormattedFilter({ fieldName, operator, fieldValue }, format, dataType);

  const className = dense ? DENSE_CLASSES : "";

  const applyAttributeDiffStyle =
    diff?.changes[diff.level]?.[diff.index]?.attribute ?? false;
  const applyOperatorDiffStyle =
    diff?.changes[diff.level]?.[diff.index]?.operator ?? false;

  const labelText = (
    <span>
      <span
        className={classNames(
          "font-medium text-viaduct-black",
          applyAttributeDiffStyle ? diff?.bgColor : undefined
        )}
      >
        {formattedFieldName}
      </span>{" "}
      <span
        className={classNames(
          "italic font-normal text-gray-500",
          applyOperatorDiffStyle ? diff?.bgColor : undefined
        )}
      >
        {formattedOperator}
      </span>
      <span className={applyOperatorDiffStyle ? diff?.bgColor : undefined}>
        {formattedFieldValue !== "" && formattedOperator !== "" && ":"}
      </span>
    </span>
  );

  if (format === "label") {
    return (
      <div className="flex-row">
        <Label text={labelText} className={className} />
        <Label
          text={formattedFieldValue}
          className={classNames("text-viaduct-black break-all", className)}
        />
      </div>
    );
  }

  if (format === "label-inline") {
    return (
      <div className="flex w-full whitespace-nowrap">
        <Label
          text={labelText}
          className={classNames(
            "pl-0! mb-0! inline-block whitespace-nowrap",
            className
          )}
        />
        <Label
          text={formattedFieldValue}
          className={classNames(
            "text-viaduct-black mb-0! max-w-60 overflow-hidden text-ellipsis",
            className
          )}
        />
      </div>
    );
  }

  if (format === "label-block") {
    return (
      <div className="mb-2">
        <Label
          text={labelText}
          className={classNames("pl-0! inline text-viaduct-black", className)}
        />
        <Label
          text={formattedFieldValue}
          className={classNames("inline text-viaduct-black", className)}
        />
      </div>
    );
  }

  return (
    <span className={className}>
      <span className="capitalize">{formattedFieldName}</span>{" "}
      {formattedOperator} {formattedFieldValue}
    </span>
  );
};

export default FLabel;
