import { DuckMessage } from "duck/context/DuckContextWrapper";
import { DuckMessageAuthor, DuckMessageFormat } from "duck/context/types";
import DuckMarkdownMessage from "duck/ui/DuckMarkdownMessage";
import { DuckMessageProps } from "duck/ui/DuckMessage";

import {
  useGetIssueMetricsRegistry,
  useGetSuggestedIssuesMetricsRegistry,
} from "shared/api/metrics/hooks";
import { useConfigContext } from "shared/contexts/ConfigContext";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { EventTypeEnum, EventTypeLabel } from "shared/types";

import {
  CLAIMS_PAGE_KEY,
  CLAIMS_TAB_KEY,
} from "pages/ClaimAnalytics/constants";
import { BY_VEHICLE_AGE_CHART_OPTIONS_KEY } from "pages/ClaimAnalytics/tabPages/ByVehicleAge/ByVehicleAge";
import { useByVehicleAgeChartActions } from "pages/ClaimAnalytics/tabPages/ByVehicleAge/hooks";
import { CLAIMS_CHART_OPTIONS_KEY } from "pages/ClaimAnalytics/tabPages/Claims/ClaimsChart";
import { useClaimsChartActions } from "pages/ClaimAnalytics/tabPages/Claims/hooks";
import { CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY } from "pages/ClaimAnalytics/tabPages/TopContributors/constants";
import { useTopContributorsChartSettings } from "pages/hooks";
import {
  ISSUES_CHART_KEY,
  SUGGESTED_ISSUES_CHART_KEY,
} from "pages/Issues/constants";
import { getIssueDashboardChartActions } from "pages/Issues/utils";
import {
  SIGNAL_EVENTS_PAGE_KEY,
  SIGNAL_EVENTS_TAB_KEY,
} from "pages/SignalEventsAnalytics/constants";
import { SIGNAL_EVENT_CHART_OPTIONS_KEY } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/SignalEventsChart";
import { getSignalEventChartActions } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/utils";
import { SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY } from "pages/SignalEventsAnalytics/tabPages/TopContributors/constants";

import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { Option } from "features/ui/Select";

interface ActionAndOptionLabel {
  actionLabel: string;
  optionLabel: string;
}

const createActionAndOptionLabels = (
  actionLabel: string,
  optionLabel: string
): ActionAndOptionLabel => ({ actionLabel, optionLabel });

const translateActionAndOption = (
  actions: ChartAction<Option>[],
  actionId: string,
  optionId: string | undefined
): ActionAndOptionLabel => {
  const action = actions.find((action) => action.id === actionId);

  if (!action) return createActionAndOptionLabels(actionId, optionId ?? "--");

  const option = action.options?.find((option) => option.id === optionId);

  return createActionAndOptionLabels(
    action.title,
    option ? String(option.value) : "--"
  );
};

const recordToArray = (
  record: Record<string, string>
): SelectedChartOptions<Option>[] =>
  Object.entries(record).map(([id, optionId]) => ({
    id,
    optionId,
  }));

const useTopContributorActions = (
  pageKey: string,
  tabKey: string,
  defaultFilterString: string | undefined,
  chartOptionsKey: string,
  eventType: EventTypeEnum
): ChartAction<Option>[] => {
  const defaultClaimFilters =
    filterBuilderQueryToFilterBuilderState(defaultFilterString) ||
    DEFAULT_FILTER_BUILDER_STATE;

  const { attributeAccessors } = useClaimsSchema();

  const filterSortState = useFilterSortState({
    pageKey,
    defaultFilterValues: defaultClaimFilters,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(pageKey),
    defaultTab: tabKey,
    schemaAttributes: attributeAccessors,
  });

  const { actions } = useTopContributorsChartSettings(
    filterSortState,
    chartOptionsKey,
    eventType
  );

  return actions;
};

/**
 * Get the available actions for the chart. Each actions has a collection of options.
 * @param chartOptionsKey The key of the chart
 * @param selectedChartOptions There is only one selectedChartOptions parameter
 * because there is only one active chart. The labels will not be properly translated
 * for the other charts but it doesn't matter because the user will not see the labels.
 * @returns
 */
const useGetChartActions = (
  chartOptionsKey: string,
  selectedChartOptions: Record<string, string> | undefined
): ChartAction<Option>[] => {
  const selectedChartOptionsArray = recordToArray(selectedChartOptions ?? {});

  const { pages } = useConfigContext();

  const { data: issueMetrics } = useGetIssueMetricsRegistry();
  const { data: suggestedIssueMetrics } =
    useGetSuggestedIssuesMetricsRegistry();

  const claimsTopContributorActions = useTopContributorActions(
    CLAIMS_PAGE_KEY,
    CLAIMS_TAB_KEY,
    pages.claimAnalytics?.defaultFilters,
    CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
    EventTypeEnum.CLAIM
  );

  const signalEventsTopContributorsActions = useTopContributorActions(
    SIGNAL_EVENTS_PAGE_KEY,
    SIGNAL_EVENTS_TAB_KEY,
    pages.signalEventsAnalytics?.defaultFilters,
    SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
    EventTypeEnum.SIGNAL_EVENT
  );

  const chartActions: Record<string, ChartAction<Option>[]> = {
    [CLAIMS_CHART_OPTIONS_KEY]: useClaimsChartActions(),
    [BY_VEHICLE_AGE_CHART_OPTIONS_KEY]: useByVehicleAgeChartActions(
      EventTypeLabel.CLAIM
    ),
    [ISSUES_CHART_KEY]: getIssueDashboardChartActions(
      issueMetrics,
      selectedChartOptionsArray,
      "issue"
    ),
    [SUGGESTED_ISSUES_CHART_KEY]: getIssueDashboardChartActions(
      suggestedIssueMetrics,
      selectedChartOptionsArray,
      "suggestedIssue"
    ),
    [SIGNAL_EVENT_CHART_OPTIONS_KEY]: getSignalEventChartActions(),
    [CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY]:
      claimsTopContributorActions,
    [SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY]:
      signalEventsTopContributorsActions,
  };

  return chartActions[chartOptionsKey] ?? [];
};

/**
 * @param props.message.message The message property of the parameter DuckMessage
 * is the filter string to apply to the source.
 * @param props.message.options.chartOptionsOptions.chartOptionsKey The key of the chart.
 * This is used to translate the id's of the action and option to human readable values.
 * @param props.message.options.chartOptionsOptions.actionId The id of the chart action
 * such as "y", "granularity", etc.
 * @param props.message.options.chartOptionsOptions.optionId The id of the selected
 * option for the action.
 */
const DuckChartOptionMessage = ({ message: { options } }: DuckMessageProps) => {
  const { chartOptionsKey, actionId, optionId, selectedChartOptions } =
    options?.chartOptionsOptions ?? {};

  const actions = useGetChartActions(chartOptionsKey, selectedChartOptions);

  const { actionLabel, optionLabel } = translateActionAndOption(
    actions,
    actionId,
    optionId
  );

  const chartOptionMessage: DuckMessage = {
    author: DuckMessageAuthor.AGENT,
    message: `&nbsp;&nbsp;&nbsp;&nbsp;- Set ${actionLabel} to ${optionLabel}`,
    format: DuckMessageFormat.TEXT,
  };

  return <DuckMarkdownMessage message={chartOptionMessage} />;
};

export default DuckChartOptionMessage;
