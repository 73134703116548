import { VALUES_SEPARATOR } from "pages/Issues/constants";
import { VINEventTimelineDateLink } from "pages/shared/vehicles/VINEventTimelineDateLink";

const CustomTick = (props: any) => {
  const { x, payload } = props;
  const yAxisValue = payload.value;
  const [VINLabel, VIN, date] = yAxisValue.split(VALUES_SEPARATOR);

  return (
    <text {...props} fontSize={12}>
      <tspan x={x} dy="0.355em">
        <VINEventTimelineDateLink VIN={VIN} VINLabel={VINLabel} date={date} />
      </tspan>
    </text>
  );
};

export default CustomTick;
