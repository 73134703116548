import {
  keepNumberBetweenLimits,
  removeNonDigitCharacters,
} from "shared/utils";

import DropdownSelect, {
  DropdownSelectOption,
} from "features/ui/DropdownSelect/DropdownSelect";
import {
  MAX_WINDOW_SIZE,
  MIN_WINDOW_SIZE,
} from "features/ui/Filters/FilterTypes/OccursFilter/constants";
import { getSelectOptionValue } from "features/ui/Filters/FilterTypes/OccursFilter/utils";
import {
  OccursFilterWindowDirection,
  OccursFilterWindowDirectionType,
  OccursWindowOptions,
} from "features/ui/Filters/types";
import { onlyAllowPositiveIntegersOnKeyDown } from "features/ui/Filters/utils";
import Input from "features/ui/Input";

interface Props {
  occursOptionsState: OccursWindowOptions;
  onUpdate: (row: OccursWindowOptions) => void;
  baseEntityText: string;
  inFilterSelector: boolean;
  windowDirectionOptions?: DropdownSelectOption<OccursFilterWindowDirectionType>[];
  disabled?: boolean;
  disableSelectingWindowDirection?: boolean;
}

export const WINDOW_DIRECTION_OPTION_BEFORE: DropdownSelectOption<OccursFilterWindowDirectionType> =
  {
    id: OccursFilterWindowDirection.BEFORE,
    value: "before",
    label: "before",
    testId: "before",
  };

export const WINDOW_DIRECTION_OPTION_AFTER: DropdownSelectOption<OccursFilterWindowDirectionType> =
  {
    id: OccursFilterWindowDirection.AFTER,
    value: "after",
    label: "after",
    testId: "after",
  };

export const WINDOW_DIRECTION_OPTION_BOTH: DropdownSelectOption<OccursFilterWindowDirectionType> =
  {
    id: OccursFilterWindowDirection.BOTH,
    value: "before or after",
    label: "before or after",
    testId: "before-or-after",
  };

export const WINDOW_DIRECTION_OPTIONS: DropdownSelectOption<OccursFilterWindowDirectionType>[] =
  [
    WINDOW_DIRECTION_OPTION_BEFORE,
    WINDOW_DIRECTION_OPTION_AFTER,
    WINDOW_DIRECTION_OPTION_BOTH,
  ];

const OccursTimeWindowForm = ({
  occursOptionsState,
  onUpdate,
  baseEntityText,
  windowDirectionOptions = WINDOW_DIRECTION_OPTIONS,
  disableSelectingWindowDirection = false,
  disabled,
}: Props) => (
  <div
    className="flex flex-wrap items-center gap-2 pt-2"
    data-testid="occurs-time-window-form"
  >
    <div>within {disableSelectingWindowDirection ? "the last" : ""}</div>
    <Input
      className="w-20! min-w-[5rem]"
      type="number"
      value={occursOptionsState.windowSize}
      testId="related-events-window-size"
      max={MAX_WINDOW_SIZE}
      min={MIN_WINDOW_SIZE}
      onKeyDown={onlyAllowPositiveIntegersOnKeyDown}
      disabled={disabled}
      sx={{
        "& .MuiInputBase-input": { paddingTop: "4px", paddingBottom: "4px" },
      }}
      onChange={(e) => {
        onUpdate({
          ...occursOptionsState,
          windowSize: keepNumberBetweenLimits(
            removeNonDigitCharacters(e.target.value),
            MIN_WINDOW_SIZE,
            MAX_WINDOW_SIZE
          ),
        });
      }}
    />
    <div>days</div>
    {(windowDirectionOptions?.length === 1 && (
      <div>{windowDirectionOptions[0].value}</div>
    )) ||
      (!disableSelectingWindowDirection && (
        <DropdownSelect
          options={windowDirectionOptions}
          onSelect={(selectedOption) => {
            onUpdate({
              ...occursOptionsState,
              windowDirection: selectedOption.id,
            });
          }}
          label={getSelectOptionValue(
            occursOptionsState.windowDirection,
            windowDirectionOptions
          )}
          testId="related-events-window-direction"
          size="small"
          disabled={disabled}
        />
      ))}
    <div className="whitespace-nowrap">{baseEntityText}</div>
  </div>
);

export default OccursTimeWindowForm;
