import { getTenantFromHostname } from "shared/utils";

import nelbro from "./nelbro.json";
import standard from "./standard.json";

export type TireDiagramConfiguration = {
  ID: string;
  tires: TireConfiguration[];
};

export type TireConfiguration = {
  ID: string;
  xPos: number;
  yPos: number;
  name: string;
  pressureSensorID: string;
  temperatureSensorID: string;
  pressureSpec: number;
  relatedSignalEvents: string[];
};

export const getTireDiagramConfiguration = (): TireDiagramConfiguration => {
  const tenant = getTenantFromHostname();

  switch (tenant) {
    case "nelbro":
      return nelbro;
    default:
      return standard;
  }
};
