import {
  DatePicker as MUIDatePicker,
  DatePickerProps as MUIDatePickerProps,
} from "@mui/x-date-pickers/DatePicker";
import {
  DateTimePicker,
  DateTimePickerProps,
} from "@mui/x-date-pickers/DateTimePicker";

import { toSnakeCase } from "shared/utils";

type BaseDatePickerProps = Omit<MUIDatePickerProps<Date>, "value" | "onChange">;

export interface DatePickerProps extends BaseDatePickerProps {
  initialDate?: Date | null;
  defaultIsOpen?: boolean;
  onChange?: (date: Date | null) => void;
  placeholderText?: string;
  showTimeSelect?: boolean;
}

const DatePicker = ({
  initialDate = null,
  placeholderText = "Select a date",
  onChange,
  defaultIsOpen,
  autoFocus,
  showTimeSelect,
  ...otherProps
}: DatePickerProps) => {
  const handleDateChange = (date: Date | null) => {
    onChange?.(date);
  };

  const commonProps = {
    ...otherProps,
    value: initialDate,
    onChange: handleDateChange,
    slotProps: {
      textField: {
        size: "small" as const,
        placeholder: placeholderText,
        autoFocus: defaultIsOpen || autoFocus,
        id: otherProps.label ? toSnakeCase(otherProps.label.toString()) : "",
        error: false,
        InputProps: {
          sx: {
            "& .MuiInputBase-input": {
              marginRight: 2.4,
            },
          },
        },
      },
    },
  };

  if (showTimeSelect) {
    return <DateTimePicker {...(commonProps as DateTimePickerProps<Date>)} />;
  }

  return <MUIDatePicker {...(commonProps as MUIDatePickerProps<Date>)} />;
};

export default DatePicker;
