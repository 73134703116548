export const DEFAULT_SORT = "date:desc";
export const CLAIM_LIMIT = 200;

export const ToolCallRoutableNodeNames = {
  EXTRACT_CLAIMS_GROUPS: "extractClaimsGroups",
  SUMMARIZE_CLAIMS: "summarizeClaims",
} as const;

export const ClaimsAgentSubgraphNodeNames = {
  LOAD_CLAIMS: "loadClaims",
  ROUTER: "claimsAgentRouter",
  ...ToolCallRoutableNodeNames,
} as const;
