import {
  ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
  ATTRIBUTE_RETRIEVAL_K,
} from "duck/graph/constants";
import { Source } from "duck/graph/nodes/utils";
import {
  validateFilter,
  validateSort,
} from "duck/graph/nodes/VehiclesAgent/tools/utils";
import { PageHandler } from "duck/graph/PageHandler";
import getAttributeRetrievalTool from "duck/graph/tools/getAttributeRetrievalTool";
import getSortTableTool from "duck/graph/tools/getSortTableTool";
import getUpdateFilterTool from "duck/graph/tools/getUpdateFilterTool";
import { DuckGraphParams } from "duck/graph/types";

import { VEHICLES_PAGE_KEY } from "pages/Vehicles/constants";

/**
 * @summary Get the tools for the vin view agent.
 * The tools are specific to vin view, and are bound to the UI handlers
 * for vin view.
 * @param pageHandler Provides functions to adjust the url and query params.
 * @returns The full set of tools for the signal event analytics agent.
 */
export const getRetrievalTools = (params: DuckGraphParams) => [
  getAttributeRetrievalTool(
    Source.VEHICLE,
    ATTRIBUTE_RETRIEVAL_K,
    ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
    params.uiHandlers.setEphemeralMessage
  ),
];

export const getActionTools = (
  params: DuckGraphParams,
  pageHandler: PageHandler
) => [
  getUpdateFilterTool({
    pageHandler,
    pageKey: VEHICLES_PAGE_KEY,
    validateFunction: validateFilter,
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    setAgentResponse: params.uiHandlers.setAgentResponse,
    source: Source.VEHICLE,
    selectedFilterString:
      params.currentState.vehicles.vehiclesFilterQueryString,
  }),
  getSortTableTool({
    pageHandler,
    setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
    setAgentResponse: params.uiHandlers.setAgentResponse,
    validateFunction: validateSort,
    pageKey: VEHICLES_PAGE_KEY,
    source: Source.VEHICLE,
    selectedSortString: params.currentState.vehicles.vehiclesSortQueryString,
  }),
];
