import { SWRConfiguration } from "swr";

import { EntityAttribute, ListAttributesRequest } from "shared/api/api";
import { DEFAULT_ATTRIBUTES_SWR_CONFIG } from "shared/api/constants";
import { useAPI } from "shared/api/hooks";

import {
  getSensorsReadingsTimelineURI,
  LatestSensorReading,
  listLatestSensorsReadingsRequestURI,
  ListLatestSensorsRequest,
  listSensorReadingAttributesRequestURI,
  SensorReadingsTimelineRequest,
  SensorReadingTimeline,
} from "./api";

export const useSensorsReadingsTimeline = (
  args: SensorReadingsTimelineRequest
) =>
  useAPI<typeof args, SensorReadingTimeline>(
    getSensorsReadingsTimelineURI,
    args
  );

export const useSensorReadingsAttributes = (args: ListAttributesRequest) =>
  useAPI<typeof args, EntityAttribute[]>(
    listSensorReadingAttributesRequestURI,
    args,
    DEFAULT_ATTRIBUTES_SWR_CONFIG
  );

export const useLatestSensorsReadings = (
  args: ListLatestSensorsRequest,
  options: SWRConfiguration = {}
) =>
  useAPI<typeof args, LatestSensorReading[]>(
    listLatestSensorsReadingsRequestURI,
    args,
    options
  );
