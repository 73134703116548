import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  CountRequest,
  DeleteRequest,
  getFetcher,
  patchFetcher,
  PermissionEntry,
  postFetcher,
  UpdatePermissionRequest,
} from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { PermissionID } from "shared/types";

const GROUPS_ROUTE = "groups";

export interface GroupUser {
  email: string;
}

export interface Group {
  ID: string;
  name: string;
  description: string;
  createdBy: string;
  updatedBy: string;
  createdAt: string;
  updatedAt: string;
  users: GroupUser[];
  access: PermissionEntry[];
  memberAccess: PermissionID | null;
  canEdit: boolean;
  admin: boolean;
}

interface UpdateGroupUsersRequest {
  create: string[];
  remove: string[];
}

export interface UpdateGroupRequest {
  ID: string;
  name?: string;
  description?: string;
  users?: UpdateGroupUsersRequest;
}

export interface ListGroupsRequest extends APIPaginatedRequest {}

export const listGroupsRequestURI = ({
  ...params
}: ListGroupsRequest): string =>
  client.getUri({ method: "GET", url: createURL([GROUPS_ROUTE]), params });

export const listGroupsCountRequestURI = ({
  ...params
}: CountRequest): string =>
  client.getUri({
    method: "GET",
    url: createURL([GROUPS_ROUTE, "count"]),
    params,
  });

const listGroupsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "GET",
    url: createURL([GROUPS_ROUTE, "values", fieldName]),
    params,
  });

export const listGroupsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listGroupsValuesRequestURI(args));

export interface GetGroupRequest {
  id: string | undefined | null;
}

export const getGroup = (args: GetGroupRequest) =>
  getFetcher<Group>(getGroupRequestURI(args));

export const listGroups = (args: APIPaginatedRequest) =>
  getFetcher<Group[]>(listGroupsRequestURI(args));

export const getGroupRequestURI = ({
  id,
  ...params
}: GetGroupRequest): string =>
  id
    ? client.getUri({
        method: "GET",
        url: createURL([GROUPS_ROUTE, id]),
        params,
      })
    : "";

const deleteGroupRequestURI = ({ id }: DeleteRequest): string =>
  client.getUri({
    method: "DELETE",
    url: createURL([GROUPS_ROUTE, id]),
  });

export const deleteGroup = (args: DeleteRequest) =>
  client.delete(deleteGroupRequestURI(args));

const updateGroupRequestURI = ({ ID, ...params }: UpdateGroupRequest): string =>
  client.getUri({
    method: "PATCH",
    url: createURL([GROUPS_ROUTE, ID]),
    params,
  });

export const updateGroup = (args: UpdateGroupRequest) =>
  patchFetcher<Group>(updateGroupRequestURI(args), args);

export interface NewGroupRequest {
  name: string;
  description: string;
  memberAccess: PermissionID | null;
  acl: PermissionEntry[];
  users: string[];
}

const newGroupRequestURI = (params: NewGroupRequest): string =>
  client.getUri({ method: "POST", url: GROUPS_ROUTE, ...params });

export const newGroup = (data: NewGroupRequest) =>
  postFetcher<Group>(newGroupRequestURI(data), data);

interface UpdateGroupAccessRequest extends Partial<UpdatePermissionRequest> {
  ID: string;
  memberAccess?: PermissionID | null;
}

const updateGroupAccessRequestURI = ({
  ID,
  ...params
}: UpdateGroupAccessRequest): string =>
  client.getUri({
    method: "PATCH",
    url: createURL([GROUPS_ROUTE, ID, "access"]),
    params,
  });

export const updateGroupAccess = ({ ID, ...args }: UpdateGroupAccessRequest) =>
  patchFetcher<PermissionEntry[]>(updateGroupAccessRequestURI({ ID }), args);
