import { DuckMessageProps } from "duck/ui/DuckMessage";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";
import remarkGfm from "remark-gfm";

/**
 * This component is used to render text messages from the agent.
 *
 * The Markdown component by itself recognizes lists but does not properly render them.
 * It omits the discs and numbers from the lists. The `components` parameter sent to
 * the Markdown component allows us to override the default rendering of lists to make
 * them look correct. It is likely that the CSS of the project prevents the standard
 * rendering of lists from working as expected in markdown.
 */
const DuckMarkdownMessage = ({ message: { message } }: DuckMessageProps) => (
  <div className="w-full flex justify-start">
    {/* break-words: breaks up unfinished Markdown links, preventing links from expanding the inner textbox  */}
    <div className="w-full p-1 text-left break-words">
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw, rehypeSanitize]}
        components={{
          ul: ({ node, ...props }) => (
            <ul
              style={{ listStyleType: "disc", paddingLeft: "20px" }}
              {...props}
            />
          ),
          ol: ({ node, ...props }) => (
            <ol
              style={{ listStyleType: "decimal", paddingLeft: "20px" }}
              {...props}
            />
          ),
          a: ({ node, ...props }) => {
            const allProps: Record<string, any> = { ...props };

            // External links open in a new tab.
            // Internal links open in the same tab.
            if (props.href?.startsWith("http")) {
              allProps.target = "_blank";
            }

            return (
              // The ReactMarkdown library injects content into the anchor tag.
              // It is not a problem that there is no content at this point in the process.
              <a
                // It strikes me as odd that we are using a color named "metabase-blue" here
                // but it is widely used throughout the codebase. It appears to be our defacto
                // standard blue color for links.
                className="text-metabase-blue hover:underline"
                {...allProps}
                // target="_blank"
              />
            );
          },
          th: ({ node, ...props }) => (
            <th className="border border-gray-500 px-2 py-1" {...props} />
          ),
          td: ({ node, ...props }) => (
            <td className="border border-gray-500 px-2 py-1" {...props} />
          ),
          h1: ({ node, ...props }) => (
            // The ReactMarkdown library injects content into the heading.
            // It is not a problem that there is no content at this point in the process.
            <h1 className="text-2xl font-bold pt-2" {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 className="text-xl font-bold pt-1" {...props} />
          ),
          h3: ({ node, ...props }) => (
            <h3 className="text-lg font-bold pt-1" {...props} />
          ),
          h4: ({ node, ...props }) => (
            <h4 className="font-bold pt-0.5" {...props} />
          ),
          h5: ({ node, ...props }) => <h5 className="pt-0.5" {...props} />,
          h6: ({ node, ...props }) => <h6 {...props} />,
        }}
      >
        {message}
      </ReactMarkdown>
    </div>
  </div>
);

export default DuckMarkdownMessage;
