import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import ConfigRow from "./ConfigRow";
import { AttributesConfigState } from "./types";

interface ConfigContainerProps {
  configuration: AttributesConfigState[];
  updateValueByID: (
    id: string,
    updater: (prevRow: AttributesConfigState) => AttributesConfigState
  ) => void;
  onSortEnd: (event: DragEndEvent) => void;
}

const ConfigContainer = ({
  configuration,
  updateValueByID,
  onSortEnd,
}: ConfigContainerProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onSortEnd}
    >
      <SortableContext
        items={configuration}
        strategy={verticalListSortingStrategy}
      >
        {configuration.map((row) => (
          <ConfigRow key={row.id} row={row} updateValueByID={updateValueByID} />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default ConfigContainer;
