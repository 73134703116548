import { IconProps } from "./types";

const SuggestedIssuesIcon = ({ size }: IconProps) => (
  <svg
    width={size}
    height={size}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0"
    xmlns="http://www.w3.org/2000/svg"
    viewBox={`0 0 ${size} ${size}`}
  >
    <g transform="translate(1, 0)">
      <path d="M7.5 1.0625C3.39277 1.0625 0.0625 4.39277 0.0625 8.5C0.0625 12.6072 3.39277 15.9375 7.5 15.9375C11.6072 15.9375 14.9375 12.6072 14.9375 8.5H13.6758C13.6758 11.91 10.91 14.6758 7.5 14.6758C4.09004 14.6758 1.32422 11.91 1.32422 8.5C1.32422 5.09004 4.09004 2.32422 7.5 2.32422V1.0625Z" />
      <path d="M6.70312 11.4219C6.70312 11.6332 6.78708 11.8359 6.93652 11.9854C7.08597 12.1348 7.28866 12.2188 7.5 12.2188C7.71134 12.2188 7.91403 12.1348 8.06348 11.9854C8.21292 11.8359 8.29688 11.6332 8.29688 11.4219C8.29688 11.2105 8.21292 11.0078 8.06348 10.8584C7.91403 10.709 7.71134 10.625 7.5 10.625C7.28866 10.625 7.08597 10.709 6.93652 10.8584C6.78708 11.0078 6.70312 11.2105 6.70312 11.4219ZM7.10156 9.5625H7.89844C7.97148 9.5625 8.03125 9.50273 8.03125 9.42969V4.91406C8.03125 4.84102 7.97148 4.78125 7.89844 4.78125H7.10156C7.02852 4.78125 6.96875 4.84102 6.96875 4.91406V9.42969C6.96875 9.50273 7.02852 9.5625 7.10156 9.5625Z" />
      <path d="M16 4.008C14.9621 4.07169 13.9833 4.51273 13.248 5.24802C12.5127 5.98331 12.0717 6.9621 12.008 8H11.992C11.9284 6.96206 11.4874 5.98321 10.7521 5.2479C10.0168 4.51259 9.03794 4.07159 8 4.008V3.992C9.03794 3.92841 10.0168 3.48741 10.7521 2.7521C11.4874 2.01679 11.9284 1.03794 11.992 0H12.008C12.0717 1.0379 12.5127 2.01669 13.248 2.75198C13.9833 3.48727 14.9621 3.92831 16 3.992V4.008Z" />
    </g>
  </svg>
);

export default SuggestedIssuesIcon;
