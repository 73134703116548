import { Issue } from "./api/issues/api";
import { SuggestedIssue } from "./api/suggestedIssues/api";

export interface TestProps {
  testId?: string;
}

export interface ReoccurrenceProps {
  seOccurrenceWithin: number;
  seReoccurrenceStartingFrom: number;
  seReoccurrenceUpTo: number;
}

export interface JWT {
  [x: string]: string | number | boolean | string[] | number[];
}

export type PermissionID = "none" | "read" | "edit";
export type PermissionType = "user" | "group";
export type EntityWithPermissions =
  | "servicePlan"
  | "collection"
  | "claimCollection"
  | "issue"
  | "group"
  | "alertDefinition";

export type ChartActionID =
  | "x"
  | "y"
  | "legend"
  | "MIS"
  | "signalEvents"
  | "granularity"
  | "exposure"
  | "exposureBucket"
  | "groupBy"
  | "bucketBy"
  | "splitByIssueSource"
  | "measure"
  | "lookbackWindow"
  | "valueType"
  | "timeGroupingField";

export type ChartActionType =
  | "dropdownSelect"
  | "dropdownSelectNested"
  | "label"
  | "attrNameValue"
  | "boolean"
  | "issueMetric"
  | "";

export type ValueType = "absolute" | "percentage";

export type IssueTypes = Issue | SuggestedIssue;
export type IssueVehiclePopulation = "atRisk" | "comparison";
export type IssueClaimGroupBy =
  | "failedPartNumber"
  | "laborCode"
  | "mentionedSignalEvents";

export type IssueChartType = "VehicleAge" | "CalendarTime" | "DaysSinceClaim";

// chart names are defined as "<tab-on-issue-details-page>_<type-of-chart>"
export type IssueChart =
  // Claims Tab
  | "Claims_OccurrencesByCalendarTime"
  | "Claims_OccurrencesByVehicleAge"
  // Signal Events Tab
  | "SignalEvents_OccurrencesByCalendarTime"
  | "SignalEvents_OccurrencesByVehicleAge"
  // Relationships Tab
  | "Relationships_CombinedOccurrencesByCalendarTime"
  | "Relationships_CombinedOccurrencesByVehicleAge"
  | "Relationships_AssociatedSignalEvents"
  // Repair Efficacy Tab
  | "RepairEfficacy_ReoccurrenceByAttribute"
  | "RepairEfficacy_ReoccurrenceByPopulation"
  | "RepairEfficacy_ReoccurrenceBar"
  | "RepairEfficacy_ReoccurrenceProceedingAClaim"
  | "RepairEfficacy_ReoccurrenceProceedingAClaimTimeline";

export type IssueChartGroupBy = "signalEventID" | "groupByAttributeValue";

export type AccordionArrowPosition = "left" | "right";

export type MileageUnit = "mi" | "km";

export type Sort = "asc" | "desc";
export type SortBy = Record<string, Sort>;

export enum EventTypeEnum {
  SIGNAL_EVENT = "signalEvent",
  CLAIM = "claim",
  SENSOR_READING = "sensorReading",
  INSPECTION = "inspection",
  REPAIR = "repair",
  CUSTOM_RECORD = "customRecord",
}

export enum EventTypeLabel {
  SIGNAL_EVENT = "Signal Event",
  CLAIM = "Claim",
  INSPECTION = "Inspection",
  REPAIR = "Repair",
}

export const EventTypeEnumCapitalized = Object.fromEntries(
  Object.entries(EventTypeEnum).map(([key, value]) => [
    key,
    value.charAt(0).toUpperCase() + value.slice(1),
  ])
) as {
  [K in keyof typeof EventTypeEnum]: Capitalize<(typeof EventTypeEnum)[K]>;
};

export type EventTypeEnumCapitalizedType =
  (typeof EventTypeEnumCapitalized)[keyof typeof EventTypeEnumCapitalized];

export type GroupByIssuesEnum =
  | "statusObj.value"
  | "severityObj.value"
  | "statusUpdatedAt"
  | "assignedGroupID"
  | "assignee"
  | "createdBy";

export type BucketByIssuesEnum =
  | "assignedGroupID"
  | "severityObj.value"
  | "assignee"
  | "createdBy"
  | "statusUpdatedAt"
  | "none";

export type IssueMeasuresEnum =
  | "count"
  | "warranty_cost"
  | "affected_vehicles_claims"
  | "affected_vehicles_signal_events"
  | "affected_vehicles_signal_events_high_priority";

export type GroupBySuggestedIssuesEnum =
  | "statusObj.value"
  | "assignedGroupID"
  | "confidence"
  | "statusUpdatedAt";

export type BucketBySuggestedIssuesEnum =
  | "statusObj.value"
  | "assignedGroupID"
  | "statusUpdatedAt"
  | "confidence"
  | "none";

export type SuggestedIssueMeasuresEnum = "count";
