import { useContext } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";

import { APIFilter } from "shared/api/utils";
import { Vehicle } from "shared/api/vehicles/api";
import { useConfigContext } from "shared/contexts/ConfigContext";
import { VehicleECUsAttributeContext } from "shared/contexts/VehicleECUsAttributesContextWrapper";
import { VehicleOptionsAttributeContext } from "shared/contexts/VehicleOptionsAttributesContextWrapper";

import ServiceRecommendations from "pages/ServiceRecommendations";
import HealthTab from "pages/VINView/HealthTab";

import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import {
  useVinViewTabs,
  VIN_VIEW_ECUS_TAB_KEY,
  VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
  VIN_VIEW_OPTIONS_TAB_KEY,
  VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY,
  VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY,
  VIN_VIEW_SERVICE_RECORDS_TAB_KEY,
  VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY,
  VIN_VIEW_TIRE_DIAGRAM_TAB_KEY,
} from "./constants";
import ECUs from "./ECUs";
import EventsTimeline from "./Events/EventsTimeline";
import Options from "./Options";
import ServiceRecords from "./ServiceRecords";
import ServiceSchedule from "./ServiceSchedule/";
import TireDiagramContent from "./TireDiagram/TireDiagramContent";

const VEHICLE_SERVICE_RECORDS_COLUMNS = ["date", "type", "externalID"];

interface Props {
  vin: string;
  vehicle?: Vehicle;
}

const SERVICE_RECORDS_VIN_PAGE_KEY = (vin: string) => `service_records_${vin}`;
const SERVICE_RECOMMENDATIONS_PAGE_KEY = (vin: string) =>
  `service_recommendations_${vin}`;

const VINViewTabs = ({ vin, vehicle }: Props) => {
  const { ECUs: ECUList } = useContext(VehicleECUsAttributeContext);
  const { options: optionsList } = useContext(VehicleOptionsAttributeContext);
  const { servicePlans: servicePlansFlag, eventTimeline: eventTimelineFlag } =
    useFlags();

  const {
    pages: { failureModes, serviceRecords, servicePlans, vehicles },
  } = useConfigContext();

  const staticFilters: APIFilter[] = [{ name: "VIN", op: "eq", value: vin }];

  const VIN_VIEW_TABS = useVinViewTabs();

  const tabsItems: (Tab | boolean | undefined)[] = [
    vehicles?.tireDiagram && {
      key: VIN_VIEW_TIRE_DIAGRAM_TAB_KEY,
      title: VIN_VIEW_TABS[VIN_VIEW_TIRE_DIAGRAM_TAB_KEY],
      content: (
        <TireDiagramContent
          vin={vin}
          axleConfigurationID={vehicle?.axleConfigurationID}
        />
      ),
    },
    failureModes && {
      key: VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY,
      title: VIN_VIEW_TABS[VIN_VIEW_RISK_MODEL_RESULTS_TAB_KEY],
      content: <HealthTab vin={vin} />,
    },
    serviceRecords && {
      key: VIN_VIEW_SERVICE_RECORDS_TAB_KEY,
      title: VIN_VIEW_TABS[VIN_VIEW_SERVICE_RECORDS_TAB_KEY],
      content: (
        <ServiceRecords
          staticFilters={staticFilters}
          columns={VEHICLE_SERVICE_RECORDS_COLUMNS}
          pageKey={SERVICE_RECORDS_VIN_PAGE_KEY(vin)}
        />
      ),
    },
    vehicles?.maintenanceSchedules && {
      key: VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY,
      title: VIN_VIEW_TABS[VIN_VIEW_SERVICE_SCHEDULE_TAB_KEY],
      content: <ServiceSchedule vin={vin} />,
    },
    servicePlans &&
      servicePlansFlag && {
        key: VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY,
        title: VIN_VIEW_TABS[VIN_VIEW_SERVICE_RECOMMENDATIONS_TAB_KEY],
        content: (
          <ServiceRecommendations
            isInsideTab
            staticFilters={staticFilters}
            pageKey={SERVICE_RECOMMENDATIONS_PAGE_KEY(vin)}
          />
        ),
      },
    vehicles?.eventTimeline &&
      eventTimelineFlag && {
        key: VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
        title: VIN_VIEW_TABS[VIN_VIEW_EVENTS_TIMELINE_TAB_KEY],
        content: vehicle ? (
          <EventsTimeline
            vin={vin}
            staticFilters={staticFilters}
            vehicle={vehicle}
          />
        ) : null,
      },
    ECUList &&
      ECUList.length > 0 && {
        key: VIN_VIEW_ECUS_TAB_KEY,
        title: VIN_VIEW_TABS[VIN_VIEW_ECUS_TAB_KEY],
        content: <ECUs vin={vin} staticFilters={staticFilters} />,
      },
    optionsList &&
      optionsList.length > 0 && {
        key: VIN_VIEW_OPTIONS_TAB_KEY,
        title: VIN_VIEW_TABS[VIN_VIEW_OPTIONS_TAB_KEY],
        content: <Options vin={vin} staticFilters={staticFilters} />,
      },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  const defaultTabKey = vehicles?.tireDiagram
    ? VIN_VIEW_TIRE_DIAGRAM_TAB_KEY
    : VIN_VIEW_EVENTS_TIMELINE_TAB_KEY;

  const defaultTabIndex = tabs.findIndex((tab) => tab.key === defaultTabKey);

  return <StatefulTabs tabs={tabs} defaultTab={defaultTabIndex} />;
};

export default VINViewTabs;
