import classNames from "classnames";

interface AreaElement {
  key: string;
  label: string;
  color?: string;
}

interface Props {
  areas: AreaElement[];
  shownAreaKeys: string[];
  onLegendClick: (key: string) => void;
}

const AreaChartLegend = ({ areas, shownAreaKeys, onLegendClick }: Props) => (
  <div className="flex flex-wrap gap-2">
    {areas.map((area) => {
      const isShown = shownAreaKeys.includes(area.key);

      return (
        <div
          key={area.key}
          className={classNames(
            "inline-flex items-center text-xs p-2 mr-2 rounded-2xl cursor-pointer hover:bg-gray-100",
            {
              "text-gray-400": isShown,
              "text-gray-300": !isShown,
            }
          )}
          onClick={() => onLegendClick(area.key)}
        >
          <span
            className="w-4 h-1 mr-2"
            style={{
              backgroundColor: isShown ? area.color : "rgb(209 213 219)",
            }}
          />
          {area.label}
        </div>
      );
    })}
  </div>
);

export default AreaChartLegend;
