import classNames from "classnames";
import { useFlags } from "launchdarkly-react-client-sdk";

import { useConfigContext } from "shared/contexts/ConfigContext";
import { useEmailFromJWT } from "shared/hooks";

import Title from "features/ui/Title";

import Bookmarks from "./Bookmarks/Bookmarks";
import { LANDING_PAGE_TITLE } from "./constants";
import DataDashboard from "./DataDashboard/DataDashboard";
import RecentItems from "./RecentItems";
import Watchlist from "./Watchlist/Watchlist";
import WatchlistAction from "./Watchlist/WatchlistAction";

const LandingPage = () => {
  const email = useEmailFromJWT();
  const title = `${LANDING_PAGE_TITLE}${email}`;

  const {
    pages: { issues },
  } = useConfigContext();
  const { issues: issuesFF } = useFlags();
  const showIssues = issues && issuesFF;

  return (
    <>
      <div className="flex justify-between mb-5 px-8">
        <Title text={title} />
        <WatchlistAction />
      </div>
      <div className="flex flex-col gap-6">
        <Bookmarks />

        <div className="flex flex-row gap-6">
          {showIssues && (
            <div className="w-[60%]">
              <DataDashboard />
            </div>
          )}
          <div
            className={classNames("flex flex-col gap-6", {
              "w-[40%]": showIssues,
              "w-full": !showIssues,
            })}
          >
            <Watchlist />
            <RecentItems />
          </div>
        </div>
      </div>
    </>
  );
};
export default LandingPage;
