import {
  useRepairsFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";

import PageLevelFilters from "pages/shared/PageLevelFilters/PageLevelFilters";
import { VEHICLES_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import {
  REPAIRS_FILTER_LABEL,
  REPAIRS_PAGE_KEY,
  REPAIRS_VEHICLES_PAGE_KEY,
} from "./constants";

// These are not in constants.ts so we avoid circular dependencies with the util being used.
const PENDING_FILTERS_REPAIRS_LS_KEY = getPendingFiltersKey(REPAIRS_PAGE_KEY);

export const PENDING_FILTERS_VEHICLES_LS_KEY = getPendingFiltersKey(
  REPAIRS_VEHICLES_PAGE_KEY
);

interface Props {
  repairsFilterSortState: UseFilterSortState;
  vehiclesFilterSortState: UseFilterSortState;
  defaultRepairFilters: FilterGroupState;
  defaultVehicleFilters: FilterGroupState;
}

const RepairAnalyticsFilters = ({
  repairsFilterSortState,
  vehiclesFilterSortState,
  defaultRepairFilters,
  defaultVehicleFilters,
}: Props) => {
  const repairsFiltersSchema = useRepairsFiltersSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const repairFilterLabel = getFilterLabel(
    REPAIRS_FILTER_LABEL,
    repairsFilterSortState.filters
  );
  const vehiclePopulationFilterLabel = getFilterLabel(
    VEHICLES_FILTER_LABEL,
    vehiclesFilterSortState.filters
  );

  return (
    <PageLevelFilters
      filtersProps={[
        {
          filterSortState: repairsFilterSortState,
          defaultFilters: defaultRepairFilters,
          filterSchema: repairsFiltersSchema,
          filterTitle: REPAIRS_FILTER_LABEL,
          filterLabel: repairFilterLabel,
          pendingFilterKey: PENDING_FILTERS_REPAIRS_LS_KEY,
          entity: "Repairs",
          testId: "repairs",
          quickFilterLabel: "Repair Quick Filters",
        },
        {
          filterSortState: vehiclesFilterSortState,
          defaultFilters: defaultVehicleFilters,
          filterSchema: vehiclesFiltersSchema,
          filterTitle: VEHICLES_FILTER_LABEL,
          filterLabel: vehiclePopulationFilterLabel,
          pendingFilterKey: PENDING_FILTERS_VEHICLES_LS_KEY,
          entity: "Vehicles",
          testId: "vehicles",
          quickFilterLabel: "Vehicle Quick Filters",
        },
      ]}
    />
  );
};

export default RepairAnalyticsFilters;
