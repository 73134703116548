import React, { useCallback, useState } from "react";
import classNames from "classnames";
import { Selection } from "prosemirror-state";
import { GoListOrdered, GoListUnordered } from "react-icons/go";
import { LuHeading1, LuHeading2, LuHeading3 } from "react-icons/lu";
import {
  MdFormatBold,
  MdFormatItalic,
  MdFormatStrikethrough,
  MdFormatUnderlined,
  MdLink,
  MdRedo,
  MdUndo,
} from "react-icons/md";
import { toast } from "react-toastify";
import { IconButton, Popover } from "@mui/material";
import { Editor } from "@tiptap/react";

import { OptionalFeatures } from "./constants";
import RichTextEditorLinkForm from "./RichTextEditorLinkForm";
import RichTextEditorMenuGroup from "./RichTextEditorMenuGroup";

const RichTextEditorMenu = ({
  editor,
  features,
  isBubble,
}: {
  editor: Editor;
  features: OptionalFeatures[];
  isBubble: boolean;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [storedSelection, setStoredSelection] = useState<Selection | null>(
    null
  );

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleLink = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      // If a link is active, unset it
      if (editor.isActive("link")) {
        editor.chain().focus().unsetLink().run();

        return;
      }

      setStoredSelection(editor.state.selection);
      setAnchorEl(event.currentTarget);
    },
    [editor]
  );

  const handleSetLink = useCallback(
    (url: string) => {
      if (storedSelection) {
        editor.commands.setTextSelection({
          from: storedSelection.from,
          to: storedSelection.to,
        });
      }

      if (url === "") {
        editor.chain().focus().extendMarkRange("link").unsetLink().run();

        return;
      }

      try {
        editor
          .chain()
          .focus()
          .extendMarkRange("link")
          .setLink({ href: url })
          .run();
      } catch {
        toast.error("Error setting rich text editor link");
      }
      handleClose();
    },
    [editor, storedSelection]
  );

  return (
    <div
      className={classNames(
        "border-b border-gray-200 flex flex-row g-1 flex-wrap",
        {
          "bg-white p-1 rounded-md shadow-sm border border-gray-200 flex flex-row g-1 flex-nowrap":
            isBubble,
        }
      )}
    >
      {features.includes(OptionalFeatures.heading) && (
        <RichTextEditorMenuGroup>
          <IconButton
            size="small"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 1 }).run()
            }
            className={
              editor.isActive("heading", { level: 1 }) ? "!bg-gray-200" : ""
            }
          >
            <LuHeading1 />
          </IconButton>
          <IconButton
            size="small"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 2 }).run()
            }
            className={
              editor.isActive("heading", { level: 2 }) ? "!bg-gray-200" : ""
            }
          >
            <LuHeading2 />
          </IconButton>
          <IconButton
            size="small"
            onClick={() =>
              editor.chain().focus().toggleHeading({ level: 3 }).run()
            }
            className={
              editor.isActive("heading", { level: 3 }) ? "!bg-gray-200" : ""
            }
          >
            <LuHeading3 />
          </IconButton>
        </RichTextEditorMenuGroup>
      )}
      {features.includes(OptionalFeatures.textDecoration) && (
        <RichTextEditorMenuGroup>
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleBold().run()}
            className={editor.isActive("bold") ? "!bg-gray-200" : ""}
          >
            <MdFormatBold />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleItalic().run()}
            className={editor.isActive("italic") ? "!bg-gray-200" : ""}
          >
            <MdFormatItalic />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleStrike().run()}
            className={editor.isActive("strike") ? "!bg-gray-200" : ""}
          >
            <MdFormatStrikethrough />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleUnderline().run()}
            className={editor.isActive("underline") ? "!bg-gray-200" : ""}
          >
            <MdFormatUnderlined />
          </IconButton>
        </RichTextEditorMenuGroup>
      )}
      {features.includes(OptionalFeatures.lists) && (
        <RichTextEditorMenuGroup>
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleBulletList().run()}
            className={editor.isActive("bulletList") ? "!bg-gray-200" : ""}
          >
            <GoListUnordered />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().toggleOrderedList().run()}
            className={editor.isActive("orderedList") ? "!bg-gray-200" : ""}
          >
            <GoListOrdered />
          </IconButton>
        </RichTextEditorMenuGroup>
      )}
      {features.includes(OptionalFeatures.link) && (
        <RichTextEditorMenuGroup>
          <IconButton
            size="small"
            onClick={toggleLink}
            className={editor.isActive("link") ? "!bg-gray-200" : ""}
          >
            <MdLink />
          </IconButton>
          <Popover
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            disableRestoreFocus={true}
            slotProps={{
              paper: {
                className: "mt-2",
              },
            }}
          >
            <RichTextEditorLinkForm
              onConfirm={(url) => {
                handleSetLink(url);
              }}
            />
          </Popover>
        </RichTextEditorMenuGroup>
      )}
      {features.includes(OptionalFeatures.undoRedo) && (
        <RichTextEditorMenuGroup>
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().undo().run()}
          >
            <MdUndo />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => editor.chain().focus().redo().run()}
          >
            <MdRedo />
          </IconButton>
        </RichTextEditorMenuGroup>
      )}
    </div>
  );
};
export default RichTextEditorMenu;
