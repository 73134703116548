import { useCallback } from "react";

import { useClaimsFiltersSchema } from "shared/hooks";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import FilterWizard from "features/ui/Filters/FilterWizard";
import { hasSomeFiltersAppliedFilterGroupState } from "features/ui/Filters/utils";
import { StepProps } from "features/ui/Stepper";

import ClaimCount from "./ClaimCount";
import { ISSUE_CLAIMS_FILTER_PAGE_KEY } from "./constants";

const StepClaims = ({
  data,
  onDataUpdated,
  disableUsingLocalStorage,
}: StepProps) => {
  const onFiltersChange = useCallback((filters: FilterGroupState) => {
    onDataUpdated && onDataUpdated({ claimFilter: filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = useClaimsFiltersSchema();

  if (!data) return null;

  const currentFilters: FilterGroupState = data.claimFilter;
  const controlPopulationFilter: FilterGroupState =
    data.comparisonPopulationFilter;
  const atRiskPopulationFilter: FilterGroupState = data.atRiskPopulationFilter;

  const showClaimsCount = hasSomeFiltersAppliedFilterGroupState(currentFilters);

  const showAtRiskPopulationFilterCount = hasSomeFiltersAppliedFilterGroupState(
    atRiskPopulationFilter
  );

  const showControlPopulationFilterCount =
    hasSomeFiltersAppliedFilterGroupState(controlPopulationFilter);

  return (
    <>
      <div className="mb-4 text-sm text-viaduct-black">
        Use filters to define the set of claims relevant to the at-risk
        population. To create an issue, you must define either a set of claims
        or a set of signal event occurrences (in step 4).
      </div>
      <FilterWizard
        schema={schema}
        initialFilters={currentFilters}
        onFiltersChange={onFiltersChange}
        disableUsingLocalStorage={disableUsingLocalStorage}
        pageKey={ISSUE_CLAIMS_FILTER_PAGE_KEY}
        testId={ISSUE_CLAIMS_FILTER_PAGE_KEY}
        belowRightBlockContent={
          showClaimsCount && (
            <div className="flex flex-col">
              {showAtRiskPopulationFilterCount && (
                <ClaimCount
                  label="Claims in at-risk population"
                  filters={currentFilters}
                  vehicleFilters={atRiskPopulationFilter}
                />
              )}
              {showControlPopulationFilterCount && (
                <ClaimCount
                  label="Claims in comparison population"
                  filters={currentFilters}
                  vehicleFilters={controlPopulationFilter}
                />
              )}
            </div>
          )
        }
      />
    </>
  );
};

export default StepClaims;
