export const RichTextEditorMenuGroup = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <div className="flex flex-row g-1 border-r border-gray-200 pr-1 mr-1 py-0.5 last:border-r-0 last:pr-0 last:mr-0">
    {children}
  </div>
);

export default RichTextEditorMenuGroup;
