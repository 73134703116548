import classNames from "classnames";
import { Link } from "react-router-dom";

import {
  AlertDefinition,
  AlertEventType,
} from "shared/api/alertDefinitions/api";

import { OBJECT_EVENT_TYPE_URLS } from "pages/AlertDefinitions/constants";
import { AlertDefinitionState } from "pages/AlertDefinitions/types";

import { getObjectName } from "./utils";

interface Props {
  currentAlertDefinition: AlertDefinition | AlertDefinitionState;
  small?: boolean;
}

type ObjectEventType =
  | AlertEventType.VEHICLE
  | AlertEventType.ISSUE
  | AlertEventType.SUGGESTED_ISSUE;

const AlertDefinitionObjectAlertLink = ({
  currentAlertDefinition,
  small,
}: Props) => {
  const url: string =
    OBJECT_EVENT_TYPE_URLS[currentAlertDefinition.eventType as ObjectEventType];

  return (
    <Link
      className={classNames("text-blue-400 hover:underline", {
        "p-2": !small,
      })}
      to={`${url}/${currentAlertDefinition.watchedObjectID}`}
    >
      {getObjectName(currentAlertDefinition) ?? ""}
    </Link>
  );
};

export default AlertDefinitionObjectAlertLink;
