import { useState } from "react";

import { SuggestedIssue } from "shared/api/suggestedIssues/api";
import { useEmailFromJWT } from "shared/hooks";

import { EDIT_ISSUE_OVERVIEW_INDEX } from "pages/Issues/constants";
import CreateIssue from "pages/Issues/CreateIssue/CreateIssue";
import { IssueFormData } from "pages/shared/types";
import { getDefaultIssueCreateData } from "pages/shared/utils";

import Button from "features/ui/Button";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";

interface Props {
  issue: SuggestedIssue;
}

const BUTTON_TEXT = "Promote to Issue";

const PromoteToIssueButton = ({ issue }: Props) => {
  const myEmail = useEmailFromJWT();
  const defaultIssueCreateData = getDefaultIssueCreateData(myEmail);

  const [onPromoteClicked, setOnPromoteClicked] = useState(false);

  const {
    ID,
    name,
    description,
    atRiskPopulationFilter,
    comparisonPopulationFilter,
    claimFilter,
    signalEventOccurrencesFilter,
  } = issue;

  const issueData: IssueFormData = {
    ...defaultIssueCreateData,
    name: name ?? defaultIssueCreateData.name,
    description: description ?? defaultIssueCreateData.description,
    atRiskPopulationFilter: filterBuilderQueryToFilterBuilderState(
      atRiskPopulationFilter
    ),
    comparisonPopulationFilter: filterBuilderQueryToFilterBuilderState(
      comparisonPopulationFilter ?? ""
    ),
    claimFilter: filterBuilderQueryToFilterBuilderState(claimFilter ?? ""),
    signalEventOccurrencesFilter: filterBuilderQueryToFilterBuilderState(
      signalEventOccurrencesFilter ?? ""
    ),
    promotedFromID: ID,
  };

  return (
    <>
      <Button
        color="primary"
        label={BUTTON_TEXT}
        onClick={() => setOnPromoteClicked(true)}
        testId="promote-to-issue-cta"
      />
      {onPromoteClicked && (
        <CreateIssue
          action="create"
          step={EDIT_ISSUE_OVERVIEW_INDEX}
          issueData={issueData}
          showButton={false}
          defaultOpen={true}
          onClose={() => setOnPromoteClicked(false)}
          onFinally={() => setOnPromoteClicked(false)}
        />
      )}
    </>
  );
};

export default PromoteToIssueButton;
