import { clientWithoutVersion } from "shared/api/axios";

interface GetOpenAPISpecResponse {
  data: string;
}
// keep this API route here because it's not a normal REST route
export const getOpenAPISpec = (): Promise<string> =>
  clientWithoutVersion
    .get("/docs/openapi.yaml")
    .then(({ data }: GetOpenAPISpecResponse) => data);
