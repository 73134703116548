import React from "react";
import classNames from "classnames";
import { LegendProps } from "recharts";
import { Payload } from "recharts/types/component/DefaultLegendContent";

import { LineChartLegendOnClick } from "features/ui/charts/types";
import Tooltip from "features/ui/Tooltip";

const DEFAULT_COLOR = "#000000";
// text-gray-300 color
const LINE_NOT_SHOWN_COLOR = "rgb(209 213 219)";

const STROKE_WIDTH = 4;
const SVG_DIMENSION = 14;
const TOOLTIP_TEXT = "Show/Hide line in chart";

const legendIcon = (color: string | undefined = DEFAULT_COLOR) => (
  <svg
    width={SVG_DIMENSION}
    height={SVG_DIMENSION}
    viewBox="0 0 32 32"
    className="mr-2"
  >
    <path
      strokeWidth={STROKE_WIDTH}
      fill={color}
      stroke={color}
      d="M0,16h10.666666666666666
            A5.333333333333333,5.333333333333333,0,1,1,21.333333333333332,16
            H32M21.333333333333332,16
            A5.333333333333333,5.333333333333333,0,1,1,10.666666666666666,16"
    ></path>
  </svg>
);

const legendEntry = (
  entry: Payload,
  index: number,
  onClick?: LineChartLegendOnClick,
  shownLineKeys?: string[]
) => {
  const { value, color, dataKey } = entry;
  const lineShown = !onClick || shownLineKeys?.includes(dataKey as string);

  const iconColor = lineShown ? color : LINE_NOT_SHOWN_COLOR;

  return (
    <div
      className={classNames(
        "inline-flex text-xs p-2 mr-2 align-middle rounded-2xl",
        {
          "text-gray-400": lineShown,
          "text-gray-300": !lineShown,
          "hover:bg-gray-100": onClick,
        }
      )}
      key={dataKey as string}
      data-testid={onClick && `toggle-y-${dataKey as string}`}
      onClick={onClick ? (event) => onClick(entry, index, event) : undefined}
    >
      {legendIcon(iconColor)}
      {value}
    </div>
  );
};

type Props = LegendProps & {
  toggleableLines: boolean;
  shownLineKeys: string[];
  legendPrefix?: string;
};

const LineChartLegend = ({
  payload,
  onClick,
  toggleableLines,
  shownLineKeys,
  legendPrefix,
}: Props) => {
  const legendEntries =
    payload?.map((entry, index) => {
      if (!toggleableLines) {
        return legendEntry(entry, index);
      }

      return (
        <Tooltip
          key={entry.dataKey as string}
          title={<span className="text-sm">{TOOLTIP_TEXT}</span>}
        >
          {legendEntry(entry, index, onClick, shownLineKeys)}
        </Tooltip>
      );
    }) || [];

  if (!legendPrefix) {
    return legendEntries;
  }

  const prefix = (
    <div className="text-xs p-2 mr-2 text-gray-400 align-middle">
      {legendPrefix}
    </div>
  );

  const res = (
    <div className="inline-flex">
      {prefix}
      {legendEntries}
    </div>
  );

  // Return array with keyed elements
  return [<React.Fragment key="legend-container">{res}</React.Fragment>];
};

export default LineChartLegend;
