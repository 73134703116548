import Skeleton from "react-loading-skeleton";

import { SurvivalCurveResponse } from "shared/api/failureModes/api";
import {
  useFailureMode,
  useSurvivalCurves,
} from "shared/api/failureModes/hooks";
import { useTenantMileageUnit } from "shared/hooks";
import { formatNumber } from "shared/utils";

import LineChart from "features/ui/charts/LineChart";
import { ReferenceElement } from "features/ui/charts/types";

interface Props {
  failureModeID: string;
}

const HEIGHT_PX = 400;
const LOADERS_HEIGHT = 186;
const LOADERS_COUNT = 2;
const MAX_TICKS_X_AXIS = 21;
const X_AXIS_TICK_INTERVAL = 100000;

const DEFAULT_X_AXIS_TICKS = Array.from(Array(MAX_TICKS_X_AXIS).keys()).map(
  (i) => i * X_AXIS_TICK_INTERVAL
);

interface SurvivalCurveDisplay {
  distance: number;
  probability: number;
}

// We only take probabilities and distances from SurvivalCurveResponse
const convertStructure = (data: SurvivalCurveResponse | undefined) => {
  if (data === undefined) {
    return undefined;
  }

  if (data.probabilities?.length === 0 || data.distances?.length === 0) {
    return undefined;
  }

  const { probabilities, distances } = data;

  // if we get [0] for both, that's also invalid and we won't display the survival curve
  if (
    probabilities.length === 1 &&
    probabilities[0] === 0 &&
    distances.length === 1 &&
    distances[0] === 0
  ) {
    return undefined;
  }

  let displayData: SurvivalCurveDisplay[] = [];

  for (let i = 0; i < distances.length; i++) {
    displayData.push({
      distance: distances[i],
      probability: probabilities[i],
    });
  }

  return displayData;
};

const calculateDistanceMaxTick = (
  curveData: SurvivalCurveDisplay[],
  failureMileage99Percentile: number
) => {
  const maxDistance = Math.max(
    curveData[curveData.length - 1].distance,
    failureMileage99Percentile
  );

  return Math.ceil(maxDistance / X_AXIS_TICK_INTERVAL) * X_AXIS_TICK_INTERVAL;
};

const generateXAxisTicks = (
  curveData: SurvivalCurveDisplay[],
  failureMileage99Percentile: number
) => {
  // We want to show X axis ticks every 100000 miles, but only until the last value (rounded up to nearest 100.000).
  if (!curveData.length) {
    return DEFAULT_X_AXIS_TICKS;
  }

  const maxDistanceTick = calculateDistanceMaxTick(
    curveData,
    failureMileage99Percentile
  );

  return DEFAULT_X_AXIS_TICKS.filter((tick) => tick <= maxDistanceTick);
};

const getRelativeFailurePercentage = (
  mileage?: number | null,
  survivalCurveData?: SurvivalCurveDisplay[]
) => {
  if (!mileage || !survivalCurveData) return;

  const r = survivalCurveData.find(({ distance }) => distance >= mileage);

  if (!r?.probability) return;

  return Math.round(r.probability * 100);
};

const getReferenceElements = ({
  failureAverage,
  failure99Percentile,
}: SurvivalCurveResponse): ReferenceElement[] | undefined => {
  let references = [];

  if (failureAverage) {
    references.push({
      label: "Average Failure Mileage",
      xAxisValue: failureAverage,
      color: "green",
    });
  }

  if (failure99Percentile) {
    references.push({
      label: "99th Percentile Failure Mileage",
      xAxisValue: failure99Percentile,
      color: "red",
    });
  }

  return references;
};

const SurvivalCurve = ({ failureModeID }: Props) => {
  const mileageUnit = useTenantMileageUnit();

  const {
    data: survivalCurve,
    isLoading,
    error,
  } = useSurvivalCurves({
    mileageUnit,
    failureModeID,
  });

  const { data } = useFailureMode({ id: failureModeID });

  const failureModeName = data?.name ?? "";

  const curveDataDisplay = convertStructure(survivalCurve);

  const references = survivalCurve && getReferenceElements(survivalCurve);

  const relativeFailurePercentage = getRelativeFailurePercentage(
    survivalCurve?.failureAverage,
    curveDataDisplay
  );

  // We need to multiply the probabilities by 100 to get the percentage which we draw on the chart
  const curveDataDisplayPercentages: SurvivalCurveDisplay[] | undefined =
    curveDataDisplay?.map(({ probability, ...otherValues }) => ({
      ...otherValues,
      probability: probability * 100,
    }));

  const isUnsupported =
    (!isLoading && error) ||
    (!relativeFailurePercentage && !survivalCurve?.failure99Percentile);

  return (
    <div>
      <h3 className="text-xl">
        <span className="mr-1">
          Population Survival Curve - {failureModeName}
        </span>
      </h3>
      <h6 className="text-gray-400 mb-4 text-sm max-w-3xl">
        An estimate of the probability that an in-scope vehicle experiences a
        failure due to the failure mode by the time it reaches a given mileage,
        based on historical data.&nbsp;
        {relativeFailurePercentage && survivalCurve?.failure99Percentile && (
          <span>
            For example, at approximately{" "}
            {formatNumber(survivalCurve.failure99Percentile, 0)} {mileageUnit},
            around {relativeFailurePercentage}% of vehicles will have already
            failed.
          </span>
        )}
      </h6>
      {!isLoading && curveDataDisplayPercentages && (
        <LineChart
          width="100%"
          height={HEIGHT_PX}
          data={curveDataDisplayPercentages}
          references={references}
          margin={{ right: 30 }}
          xAxisKey="distance"
          xAxisLabel="Mileage"
          xAxisProps={{
            ticks: generateXAxisTicks(
              curveDataDisplayPercentages,
              survivalCurve?.failure99Percentile || 0
            ),
            tickFormatter: (value: number | string) =>
              `${formatNumber(Number(value))}`,
            interval: 0,
          }}
          yAxisLines={[{ key: "probability", label: "Probability of failure" }]}
          yAxisProps={{
            tickFormatter: (value: number | string) => `${value}%`,
          }}
          tooltipProps={{
            labelFormatter: (label: string) =>
              `${formatNumber(Number(label), 0)} ${mileageUnit}`,
            formatter: (value: number) => `${formatNumber(value)}%`,
          }}
        />
      )}
      {isLoading && !error && (
        <Skeleton
          count={LOADERS_COUNT}
          height={LOADERS_HEIGHT}
          className="mb-3"
        />
      )}
      {isUnsupported && (
        <div className="py-4">
          The population survival curve for the {failureModeName} failure mode
          is unsupported.
        </div>
      )}
    </div>
  );
};

export default SurvivalCurve;
