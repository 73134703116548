import { useCallback, useMemo } from "react";
import { generatePath, Link } from "react-router-dom";

import {
  AlertDefinition,
  AlertEventType,
  deleteAlertDefinition,
} from "shared/api/alertDefinitions/api";
import {
  useListAlertDefinitions,
  useListAlertDefinitionsCount,
} from "shared/api/alertDefinitions/hooks";
import { getSortFilter } from "shared/api/utils";
import useAlertDefinitionSchema from "shared/schemas/alertDefinitionsSchema";
import { SortBy } from "shared/types";

import APIError from "features/ui/APIError";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { FilterOperator } from "features/ui/Filters/types";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import DeleteAndPermissionRowAction from "features/ui/Table/PermissionRowAction/DeleteAndPermissionRowAction";
import { DataType } from "features/ui/Table/TableBodyCell/types";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

import {
  ALERT_DEFINITIONS_PER_PAGE,
  ALERT_EVENT_TYPE_OPTIONS,
  OBJECT_EVENT_TYPES,
} from "./constants";

const PAGE_KEY = "alertDefinitions";
const DEFAULT_SORT: SortBy = { name: "asc" };
const AlertDefinitionsEvents = () => {
  const { schema, attributeAccessors } = useAlertDefinitionSchema();

  const modifiedSchema: SchemaEntry[] = [
    ...schema,
    {
      label: "Actions",
      accessor: "actions",
      dataType: DataType.JSX,
      sortable: false,
    },
  ];

  const DEFAULT_EVENT_TYPE_FILTER: FilterGroupState<FilterRowState> = {
    id: "group-0",
    type: "group",
    anyAll: "all",
    children: [
      {
        id: "row-0",
        type: "row",
        attribute: "eventType",
        operator: FilterOperator.IN,
        values: ALERT_EVENT_TYPE_OPTIONS.map(
          (option) => option.id as string
        ).filter((id) => !OBJECT_EVENT_TYPES.includes(id as AlertEventType)),
      },
    ],
  };

  const {
    filters,
    sort,
    manageFilterChange,
    manageOnSortChange,
    resetFilterSortState,
    resetFilters,
    initialized: filtersInitialized,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
    schemaAttributes: attributeAccessors,
  });

  const requestParams = {
    filter: getFiltersQuery(
      mergeFilterGroupStates(filters, DEFAULT_EVENT_TYPE_FILTER)
    ),
    sort: getSortFilter(sort),
    refreshKey: true,
  };

  const { data, error, isLoading, requestKey, ...paginationData } =
    useListAlertDefinitions(requestParams);

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
    requestKey: countRequestKey,
  } = useListAlertDefinitionsCount(requestParams);

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const formatRow = useCallback(
    (alertDefinition: AlertDefinition) => {
      const pathname = generatePath(routes.alertDefinition, {
        id: alertDefinition.ID,
      });

      return {
        ...alertDefinition,
        name: (
          <Link to={pathname} className="text-metabase-blue hover:underline">
            {alertDefinition.name}
          </Link>
        ),
        vehiclesFilter: (
          <FilterQueryPresentation filter={alertDefinition.vehiclesFilter} />
        ),
        eventFilter: (
          <FilterQueryPresentation filter={alertDefinition.eventFilter} />
        ),
        actions: (
          <DeleteAndPermissionRowAction
            entity={alertDefinition}
            canEdit={alertDefinition.canEdit}
            deleteCallback={deleteAlertDefinition}
            entityType="alertDefinition"
            permissions={alertDefinition.access}
            requestKeys={[requestKey, countRequestKey]}
          />
        ),
      };
    },
    [requestKey, countRequestKey]
  );

  const formattedData = useMemo(() => data?.map(formatRow), [data, formatRow]);

  return (
    <>
      <div className="flex my-2 items-center">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto self-end"
          count={countData?.count as number}
          entityName="alert definition"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && (
        <div className="flex flex-col gap-4">
          <PaginatedTable
            {...paginationData}
            data={formattedData}
            schema={modifiedSchema}
            isLoading={isLoading}
            loadingRows={ALERT_DEFINITIONS_PER_PAGE}
            sortBy={sort}
            onSort={handleSorting}
            filtersInitialized={filtersInitialized}
            onFiltersReset={resetFilters}
            onFilterChange={manageFilterChange}
            filters={filters}
            pageKey={PAGE_KEY}
            dense
          />
        </div>
      )}
    </>
  );
};
export default AlertDefinitionsEvents;
