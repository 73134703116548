import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  getFetcher,
  postFetcher,
} from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";

const INSTANCE_MANAGEMENT_BASE_ROUTE = "instanceManagement";

const TENANTS_BASE_ROUTE = "tenants";

export interface InstanceManagementTenant {
  name: string;
  createdAt: string;
  createdBy: string;
  domainCreationFinished: boolean;
  certificateCreationFinished: boolean;
  clickhouseRoleCreated: boolean;
  clickhouseUserCreated: boolean;
  metabaseResourcesCreated: boolean;
  provisioningDone: boolean;
}

export interface InstanceManagementTenantRequest {
  name: string;
  admins: string[];
  superAdmins: string[];
}

export interface ListInstanceManagementTenantsRequest
  extends APIPaginatedRequest {}

const listInstanceManagementTenantsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([
      INSTANCE_MANAGEMENT_BASE_ROUTE,
      TENANTS_BASE_ROUTE,
      "values",
      fieldName,
    ]),
    params,
  });

export const listInstanceManagementTenantsValues = (
  args: APIListValuesRequest
) =>
  getFetcher<APIListValuesResponse>(
    listInstanceManagementTenantsValuesRequestURI(args)
  );

export const listInstanceManagementTenantsRequestURI = (
  params: ListInstanceManagementTenantsRequest
): string =>
  client.getUri({
    method: "GET",
    url: createURL([INSTANCE_MANAGEMENT_BASE_ROUTE, TENANTS_BASE_ROUTE]),
    params,
  });

const createInstanceManagementRequestURI = (): string =>
  client.getUri({
    method: "GET",
    url: createURL([INSTANCE_MANAGEMENT_BASE_ROUTE, TENANTS_BASE_ROUTE]),
  });

export const newTenant = (data: InstanceManagementTenantRequest) =>
  postFetcher<InstanceManagementTenant>(
    createInstanceManagementRequestURI(),
    data
  );
