import classNames from "classnames";

interface Props {
  title: string;
  text?: string;
  children: React.ReactNode;
  extraTitleComponent?: React.ReactNode;
  className?: string;
  bottomMargin?: boolean;
  middleMargin?: boolean;
}

const FormSection = ({
  title,
  text,
  children,
  extraTitleComponent,
  className,
  bottomMargin = true,
  middleMargin = true,
}: Props) => (
  <div
    className={classNames("w-[65rem] flex flex-col", className, {
      "mb-4": bottomMargin,
    })}
  >
    <div className="text-gray-600 text-base font-semibold mb-1">
      <div className="flex space-x-4 items-end">
        <span>{title}</span>
        {extraTitleComponent}
      </div>
    </div>
    {text && (
      <div
        className={classNames("text-gray-600 text-sm", {
          "mb-4": middleMargin,
        })}
      >
        {text}
      </div>
    )}
    {children}
  </div>
);

export default FormSection;
