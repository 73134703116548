import { AiOutlineShareAlt as ShareIcon } from "react-icons/ai";
import { toast } from "react-toastify";
import { IconButton } from "@mui/material";

import { copyToClipboard } from "shared/utils";

import Tooltip from "features/ui/Tooltip";

const TOOLTIP_TEXT = "Copy URL to clipboard";
const TOOLTIP_SUCCESS_TEXT = "URL Copied to clipboard!";
const TOOLTIP_ERROR_TEXT = "Failed to copy URL to clipboard!";
const ICON_SIZE = 25;

const CopyURLToClipboardAction = () => {
  const copyToClipboardAndNotify = () => {
    copyToClipboard(window.location.href).then((ok) => {
      if (ok) {
        toast.success(TOOLTIP_SUCCESS_TEXT);
      } else {
        toast.error(TOOLTIP_ERROR_TEXT);
      }
    });
  };

  const tooltipContent = <div className="text-xs">{TOOLTIP_TEXT}</div>;

  return (
    <Tooltip title={tooltipContent}>
      <IconButton
        onClick={copyToClipboardAndNotify}
        data-testid="copy-url-to-clipboard"
        size="small"
      >
        <ShareIcon size={ICON_SIZE} />
      </IconButton>
    </Tooltip>
  );
};

export default CopyURLToClipboardAction;
