import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import { SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";

interface Props {
  selected: string | null;
  schema?: SchemaEntry<string>[];
  onChange: (selected: string) => void;
  disabled?: boolean;
  attributePlaceholder?: string;
  openOnAutoFocus?: boolean;
  hasInitialFocus?: boolean;
}

const AttributeSelect = ({
  selected,
  schema,
  onChange,
  disabled,
  attributePlaceholder = "Select an attribute",
  openOnAutoFocus,
  hasInitialFocus,
}: Props) => {
  const options = schema?.map(({ label, filter, dropdownGroup }) => ({
    id: filter?.fieldName as string,
    value: filter?.label || label,
    group: dropdownGroup,
  }));

  if (!options) return null;

  const attributeSelectOption: SelectOption<string> | null = selected
    ? {
        id: selected,
        value:
          options.find((option) => option.id === selected)?.value || selected,
      }
    : null;

  return (
    <DropdownWithSearch
      autoFocus={attributeSelectOption === null && hasInitialFocus}
      options={options}
      selectedOption={attributeSelectOption}
      onSelectedOptionChange={(selected) =>
        onChange(selected ? selected.id : "")
      }
      testId="attribute-select-wrap"
      placeholder={attributePlaceholder}
      fullWidth
      className="max-w-60"
      disabled={disabled}
      openOnAutoFocus={openOnAutoFocus}
    />
  );
};
export default AttributeSelect;
