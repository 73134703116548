import { forwardRef, MouseEvent, Ref } from "react";
import { createCheckboxProps } from "duck/ui/drill/utils";
import {
  TreeItem2,
  TreeItem2Props,
  TreeItem2SlotProps,
} from "@mui/x-tree-view";

import { SelectOption } from "features/ui/Select";

export interface DuckDrillTreeItemProps extends TreeItem2Props {
  selectedattributes?: string[];
  flattenedleaves?: SelectOption[];
}

// The MUI-X examples all used this forwardRef construct,
// so I did too. It works.
const DuckDrillTreeItem = forwardRef(
  (props: DuckDrillTreeItemProps, ref: Ref<HTMLLIElement>) => (
    <TreeItem2
      {...props}
      ref={ref}
      slotProps={
        {
          checkbox: createCheckboxProps(props),
          label: {
            // Clicking the label is the same as clicking the checkbox itself
            onClick: (event: MouseEvent<HTMLDivElement>) => {
              const element = event.target as HTMLElement;
              const checkbox = element.parentElement?.querySelector(
                'input[type="checkbox"]'
              ) as HTMLInputElement;
              if (checkbox) {
                checkbox.click();
              }
            },
          },
        } as TreeItem2SlotProps
      }
    />
  )
);

export default DuckDrillTreeItem;
