import { useContext } from "react";
import AiTwinkle from "duck/ui/AiTwinkle";
import { Button } from "@mui/material";

import { useConfigContext } from "shared/contexts/ConfigContext";
import { DuckletContext } from "shared/contexts/DuckletContextWrapper";

import { VIADUCK_PAGE_CONFIG_KEY } from "pages/SuperAdmin/PagesConfig/types";

const SUMMARIZE_CLAIMS_UTTERANCE = "Please summarize this group of claims";

const SummarizeClaimsButton = () => {
  const { pages } = useConfigContext();
  const { setIsOpen, setAssignedUtterance, setAutoSubmit } =
    useContext(DuckletContext);

  if (!pages[VIADUCK_PAGE_CONFIG_KEY]) {
    return null;
  }

  const handleClick = () => {
    setIsOpen(true);
    setAssignedUtterance(SUMMARIZE_CLAIMS_UTTERANCE);
    setAutoSubmit(true);
  };

  return (
    <Button onClick={handleClick} startIcon={<AiTwinkle />}>
      Summarize Claims
    </Button>
  );
};

export default SummarizeClaimsButton;
