import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import { FilterOperator, FilterType } from "features/ui/Filters/types";
import { getOperatorsSelectOptions } from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";

const EXISTS_ADDITIONAL_TEXT = (
  <div className="order-3 leading-10 flex-1 truncate">
    where <b>All</b> of the following are true
  </div>
);

interface Props {
  type: FilterType;
  selected: SelectOption<FilterOperator>;
  onChange: (selected: SelectOption<FilterOperator>) => void;
  disableSelectFilters?: boolean;
  disableContainsFilters?: boolean;
  disableIsEmptyFilters?: boolean;
  disableStartsWithFilters?: boolean;
  disableIsNotFilteredFilters?: boolean;
  whitelistedFilterOperators?: FilterOperator[];
  fullWidth?: boolean;
  className?: string;
  disabled?: boolean;
  autoFocus?: boolean;
}

const OperatorsDropdown = ({
  type,
  selected,
  onChange,
  disableSelectFilters = false,
  disableContainsFilters = false,
  disableIsEmptyFilters = false,
  disableStartsWithFilters = false,
  disableIsNotFilteredFilters = false,
  whitelistedFilterOperators,
  fullWidth = true,
  className,
  disabled,
  autoFocus,
}: Props) => {
  const data = getOperatorsSelectOptions({
    type,
    disableSelectFilters,
    disableContainsFilters,
    disableIsEmptyFilters,
    disableStartsWithFilters,
    disableIsNotFilteredFilters,
    whitelistedFilterOperators,
  });

  const disabledModified = data.length < 2 || disabled;

  const isOccursFilter = [
    FilterOperator.OCCURS,
    FilterOperator.NOT_OCCURS,
  ].includes(selected.id);

  return (
    <div className="flex flex-row gap-4">
      <DropdownWithSearch
        autoFocus={autoFocus}
        openOnAutoFocus={autoFocus}
        options={[]}
        groupedOptions={data}
        selectedOption={selected}
        onSelectedOptionChange={onChange}
        fullWidth={fullWidth}
        disabled={disabledModified}
        testId="operators-dropdown-wrap"
        className={className}
        disableClearable
      />
      {isOccursFilter && EXISTS_ADDITIONAL_TEXT}
    </div>
  );
};
export default OperatorsDropdown;
