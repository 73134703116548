import { useState } from "react";
import { FiSettings as SettingsIcon } from "react-icons/fi";
import Button from "@mui/material/Button";

import ColumnsOptions, { Option } from "./ColumnsOptions";

interface Props {
  pageKey: string; // use for saving/getting to/from localStorage when we get there ..
  onChange: (checked: string[]) => void;
  options: Option[];
  additionalOptionsLabel?: string;
  additionalOptions?: Option[];
  visibleColumns: string[];
}

const TABLE_COLUMN_SETTINGS_TITLE = "Choose Failure Mode Columns";

const TableColumnSettings = ({
  onChange,
  options,
  visibleColumns,
  additionalOptions,
  additionalOptionsLabel,
}: Props) => {
  const [visible, setVisible] = useState(false);

  const manageOnChange = (checked: string[]) => {
    setVisible(false);
    onChange(checked);
  };

  return (
    <div
      className="flex shrink-0 justify-end text-xs flex-nowrap relative"
      data-testid="column-settings-wrapper"
    >
      {visible && (
        <ColumnsOptions
          onChange={manageOnChange}
          onClose={() => setVisible(false)}
          options={options}
          additionalOptions={additionalOptions}
          additionalOptionsLabel={additionalOptionsLabel}
          visibleColumns={visibleColumns}
        />
      )}
      <Button
        variant="contained"
        color="primary"
        size="small"
        data-testid="open-column-settings"
        onClick={() => setVisible(true)}
        endIcon={<SettingsIcon size={17} />}
      >
        {TABLE_COLUMN_SETTINGS_TITLE}
      </Button>
    </div>
  );
};

export default TableColumnSettings;
