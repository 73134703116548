import { useState } from "react";
import qs from "qs";
import { useLocation, useNavigate } from "react-router-dom";
import { Tab as MUITab, Tabs, TabsProps } from "@mui/material";

import { QS_PARSE_ARRAY_LIMIT } from "services/hooks";

import {
  DEFAULT_TAB,
  getFirstEnabledTab,
  getIndexFromKey,
  getQueryContentToPersist,
  Tab,
} from "./utils";

interface StatefulTabsProps extends TabsProps {
  tabs: Tab[];
  defaultTab?: number;
  tabPanelClassName?: string;
  disableUsingQuery?: boolean;
  queryParamsToPersist?: string[];
}

const StatefulTabs = ({
  tabs,
  defaultTab = DEFAULT_TAB,
  tabPanelClassName,
  disableUsingQuery,
  queryParamsToPersist,
  ...otherTabsProps
}: StatefulTabsProps) => {
  defaultTab = tabs[defaultTab]?.disabled
    ? getFirstEnabledTab(tabs, DEFAULT_TAB)
    : defaultTab;

  const { tab, ...otherQueryParams } = qs.parse(
    decodeURIComponent(useLocation().search),
    {
      ignoreQueryPrefix: true,
      arrayLimit: QS_PARSE_ARRAY_LIMIT,
    }
  );
  const navigate = useNavigate();

  const keys = tabs.map(({ key }) => key);
  const contents = tabs.map(({ content }) => content);

  const tabQueryIndex = getIndexFromKey(tabs, tab as string, keys, defaultTab);

  const [tabStateIndex, setTabStateIndex] = useState(
    getIndexFromKey(tabs, tab as string, keys, defaultTab)
  );

  const setTab = (tab: number = defaultTab) => {
    if (tabs[tab]?.disabled) {
      return;
    }

    if (!disableUsingQuery) {
      const queryContentToPersist = getQueryContentToPersist(
        otherQueryParams,
        queryParamsToPersist
      );

      navigate(
        {
          search: qs.stringify(
            { ...queryContentToPersist, tab: keys[tab] },
            { arrayFormat: "indices" }
          ),
        },
        { replace: true }
      );
    } else {
      setTabStateIndex(tab);
    }
  };

  const tabIndex = disableUsingQuery ? tabStateIndex : tabQueryIndex;

  const currentContent = contents[tabIndex];

  return (
    <div className="mt-2">
      <Tabs
        value={tabIndex}
        onChange={(e, val) => {
          setTab(val);
        }}
        className="mb-2"
        {...otherTabsProps}
      >
        {tabs.map(({ key, title, testId, disabled = false, disabledText }) => {
          const tabTitle = disabled && disabledText ? disabledText : title;

          return (
            <MUITab
              key={key}
              label={title}
              disabled={disabled}
              data-testid={testId}
              title={tabTitle}
              disableRipple
            />
          );
        })}
      </Tabs>
      <div role="tabpanel" className={tabPanelClassName}>
        {currentContent}
      </div>
    </div>
  );
};

export default StatefulTabs;
