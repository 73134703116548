import { useFlags } from "launchdarkly-react-client-sdk";
import qs from "qs";
import { Link } from "react-router-dom";

import { SuggestedIssue } from "shared/api/suggestedIssues/api";
import { EXPLORE_IN_SE_ANALYTICS_LABEL } from "shared/constants";
import { useConfigContext } from "shared/contexts/ConfigContext";
import { IssueTypes } from "shared/types";

import { isSuggestedIssue } from "pages/Issues/utils";
import { setReturnToEntityEntry } from "pages/shared/analytics/returnToEntity/utils";

import { getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";

import { SIGNAL_EVENTS_PAGE_KEY, VEHICLES_PAGE_KEY } from "./constants";

interface Props {
  issue: IssueTypes;
}

const SignalEventAnalyticsLink = ({ issue }: Props) => {
  const { signalEventsAnalytics } = useFlags();
  const { pages } = useConfigContext();

  if (!pages.signalEventsAnalytics || !signalEventsAnalytics) {
    return null;
  }

  const { ID, name, signalEventOccurrencesFilter, atRiskPopulationFilter } =
    issue;

  const signalEventAnalyticsFilterKey = getFiltersKey(SIGNAL_EVENTS_PAGE_KEY);
  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);

  const search = qs.stringify(
    {
      [signalEventAnalyticsFilterKey]: signalEventOccurrencesFilter,
      [vehicleFilterKey]: atRiskPopulationFilter,
    },
    { arrayFormat: "indices" }
  );

  return (
    <Link
      to={{
        pathname: routes.signalEventAnalytics,
        search,
      }}
      className="text-metabase-blue hover:underline"
      data-testid="explore-signal-event-analytics"
      onClick={() =>
        setReturnToEntityEntry({
          entityType: isSuggestedIssue(issue) ? "suggested_issue" : "issue",
          entityID: ID,
          entityName: name,
          entityRunDate: isSuggestedIssue(issue)
            ? (issue as SuggestedIssue).updated
            : undefined,
        })
      }
    >
      {EXPLORE_IN_SE_ANALYTICS_LABEL}
    </Link>
  );
};

export default SignalEventAnalyticsLink;
