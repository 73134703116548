import { useFilterSchemaForType } from "shared/hooks";

import FilterBuilder from "features/ui/Filters/FilterBuilder";

import { FunctionInputProps } from "./types";

const EventFilters = ({
  attribute,
  onChange,
  config,
  disabled,
}: FunctionInputProps) => {
  const schema = useFilterSchemaForType(attribute.eventType);
  if (schema === undefined || attribute.eventType === undefined) {
    return <div className="mt-5 text-gray-400">Please select event type</div>;
  }

  return (
    <FilterBuilder
      disabled={disabled}
      filterBuilderState={attribute.eventFilter}
      filtersSchema={schema}
      onChange={(state) =>
        onChange({
          ...attribute,
          [config.parameter]: state,
        })
      }
      inline
    />
  );
};

export default EventFilters;
