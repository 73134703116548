import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Source } from "duck/graph/nodes/utils";
import {
  AvailableData,
  NonEmptyStringArray,
  PageState,
} from "duck/graph/types";
import { useFlags } from "launchdarkly-react-client-sdk";
import qs, { ParsedQs } from "qs";
import { createPath, useNavigate } from "react-router";

import {
  useGetIssueMetricsRegistry,
  useGetSuggestedIssuesMetricsRegistry,
} from "shared/api/metrics/hooks";
import { getSensors } from "shared/api/sensors/api";
import { getSortFilter } from "shared/api/utils";
import { TAB_QUERY_PARAM } from "shared/constants";
import { useConfigContext } from "shared/contexts/ConfigContext";
import { DuckletContext } from "shared/contexts/DuckletContextWrapper";
import { VehicleECUsAttributeContext } from "shared/contexts/VehicleECUsAttributesContextWrapper";
import { VehicleOptionsAttributeContext } from "shared/contexts/VehicleOptionsAttributesContextWrapper";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { VEHICLE_ACCESSOR } from "shared/schemas/constants";
import useIssuesSchema from "shared/schemas/issuesSchema";
import { USE_RESOURCE_SCHEMA_MAP } from "shared/schemas/schemaMap";
import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";
import useSuggestedIssuesSchema from "shared/schemas/useSuggestedIssuesSchema";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { EventTypeEnum, EventTypeLabel } from "shared/types";
import { randomID } from "shared/utils";

import {
  VEHICLES_PAGE_KEY as CLAIM_ANALYTICS_VEHICLES_PAGE_KEY,
  CLAIMS_PAGE_KEY,
  CLAIMS_TAB_KEY,
  CLAIMS_TABLE_DEFAULT_SORT,
  CLAIMS_TABLE_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import { OCCURS_FILTER_KEY } from "pages/ClaimAnalytics/tabPages/AssociatedSignalEvents";
import { BY_VEHICLE_AGE_CHART_OPTIONS_KEY } from "pages/ClaimAnalytics/tabPages/ByVehicleAge/ByVehicleAge";
import { useByVehicleAgeChartActions as useClaimAnalyticsByVehicleAgeChartActions } from "pages/ClaimAnalytics/tabPages/ByVehicleAge/hooks";
import { CLAIMS_CHART_OPTIONS_KEY } from "pages/ClaimAnalytics/tabPages/Claims/ClaimsChart";
import { useClaimsChartActions } from "pages/ClaimAnalytics/tabPages/Claims/hooks";
import {
  CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
  CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
  DEFAULT_GROUP_BY_ATTRIBUTE as DEFAULT_CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_ATTRIBUTE,
} from "pages/ClaimAnalytics/tabPages/TopContributors/constants";
import {
  BY_VEHICLES_AGE_TAB_KEY,
  GROUP_BY_ATTRIBUTE_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "pages/constants";
import { useTopContributorsExposureOptions } from "pages/hooks";
import {
  ISSUES_CHART_KEY,
  ISSUES_KEY,
  ISSUES_PAGE_KEY,
  SUGGESTED_ISSUES_CHART_KEY,
  SUGGESTED_ISSUES_KEY,
  SUGGESTED_ISSUES_PAGE_KEY,
} from "pages/Issues/constants";
import { useTopContributorsChartYAxisOptions } from "pages/shared/topContributorsChartActions";
import {
  ASSOCIATED_CLAIMS_TAB_KEY,
  VEHICLES_PAGE_KEY as SIGNAL_EVENTS_ANALYTICS_VEHICLES_PAGE_KEY,
  SIGNAL_EVENTS_PAGE_KEY,
  SIGNAL_EVENTS_TAB_KEY,
} from "pages/SignalEventsAnalytics/constants";
import {
  ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_ATTRIBUTE,
  SE_ASSOCIATED_CLAIMS_KEY,
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY,
  SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY,
  WINDOW_SIZE_KEY,
} from "pages/SignalEventsAnalytics/tabPages/AssociatedClaims/constants";
import { useDefaultClaimFilters } from "pages/SignalEventsAnalytics/tabPages/AssociatedClaims/utils";
import { SE_ASSOCIATED_SE_PAGE_KEY } from "pages/SignalEventsAnalytics/tabPages/AssociatedSignalEvents/constants";
import { SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY } from "pages/SignalEventsAnalytics/tabPages/ByVehicleAge/ByVehicleAge";
import { useGetByVehicleAgeChartActions as useSignalEventAnalyticsByVehicleAgeChartActions } from "pages/SignalEventsAnalytics/tabPages/ByVehicleAge/hooks";
import { SIGNAL_EVENT_CHART_OPTIONS_KEY } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/SignalEventsChart";
import { getSignalEventChartActions } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/utils";
import { topContributorsChartYAxisOptions } from "pages/SignalEventsAnalytics/tabPages/TopContributors/ChartActions";
import {
  DEFAULT_GROUP_BY_ATTRIBUTE as DEFAULT_SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_ATTRIBUTE,
  SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
  SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
} from "pages/SignalEventsAnalytics/tabPages/TopContributors/constants";
import { useVinViewTabs } from "pages/VINView/constants";
import { CHART_ACTIONS } from "pages/VINView/Events/constants";

import {
  filterBuilderQueryToFilterBuilderState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import {
  DEFAULT_EMPTY_OCCURS_FILTER,
  DEFAULT_WINDOW_SIZE,
} from "features/ui/Filters/FilterTypes/OccursFilter/constants";
import { WINDOW_DIRECTION_OPTIONS } from "features/ui/Filters/FilterTypes/OccursFilter/OccursTimeWindowForm";
import {
  assertOccursFilterWindowDirection,
  OccursFilterState,
  OccursFilterWindowDirection,
  PageChartSettingsState,
} from "features/ui/Filters/types";
import {
  getPageKeyWithVersion,
  getStateFromLocalStorage,
} from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";

import { useQuery } from "services/hooks";

import {
  DUCK_OMIT_EXISTING_QUERY_PARAM_KEY,
  DUCK_OMIT_EXISTING_QUERY_PARAMS_STARTING_WITH_KEY,
  DUCK_PENDING_ACTION_KEY,
  DUCK_RELOAD_REQUIRED_KEY,
  DUCK_ROUTE_VALUE_KEY,
  DUCK_UPDATED_QUERY_PARAMS_KEY,
  DUCK_VISIBILITY_KEY,
  LANGCHAIN_THREAD_ID_KEY,
} from "./constants";
import {
  DuckAccess,
  LocationInfo,
  PendingAction,
  QueryStringNavigation,
  Reload,
} from "./types";
import {
  assertNonEmptyStringArray,
  createExposureHierarchy,
  flattenSelectOptionList,
  getByVehicleAgeChartOptionStrings,
  getClaimsChartOptionStrings,
  getDuckAccess,
  getFromLocalStorage,
  getInitialThreadId,
  getInitialUpdatedQueryParams,
  getIssuesAgentData,
  getIssuesDetailsPageState,
  getSelectedChartOptions,
  getSelectedGroupByAttribute,
  getSignalEventsChartOptionStrings,
  getVehiclesPageState,
  getVinViewPageState,
  getVinViewTimelineChartOptionStrings,
  persistVisibility,
  toEncodedNonEmptyStringArray,
  toNonEmptyStringArray,
} from "./utils";

/**
 * The useGroupBySelectOptions hook temporarily returns an empty array while the data loads.
 * This wrapper hook ensures that the array is never empty by putting a placeholder in it.
 */
const useNonEmptyGroupBySelectOptions = (
  eventType: EventTypeEnum,
  skipVehicleAttributes?: boolean
): SelectOption[] => {
  const { groupBySelectOptions } = USE_RESOURCE_SCHEMA_MAP[eventType](
    skipVehicleAttributes ? [VEHICLE_ACCESSOR] : undefined
  );

  if (!groupBySelectOptions || groupBySelectOptions.length === 0) {
    return [{ id: "placeholder", value: "Placeholder While Data Loads" }];
  }

  return flattenSelectOptionList(groupBySelectOptions);
};

const useClaimAnalyticsAgentData = (): AvailableData["claimAnalytics"] => {
  const claimsChartOptions = useClaimsChartActions();
  const byVehicleAgeChartActions = useClaimAnalyticsByVehicleAgeChartActions(
    EventTypeLabel.CLAIM
  );

  const groupBySelectOptions = useNonEmptyGroupBySelectOptions(
    EventTypeEnum.CLAIM
  );

  const topContributorsYAxisOptions = useTopContributorsChartYAxisOptions(
    EventTypeEnum.CLAIM
  );

  const { attributes } = useClaimsSchema();

  const topContributorsExposures = useTopContributorsExposureOptions(
    EventTypeEnum.CLAIM
  );

  const exposuresWithBuckets = createExposureHierarchy(
    topContributorsExposures,
    attributes
  );

  return {
    claimsChartOptions: getClaimsChartOptionStrings(claimsChartOptions),
    byVehicleAgeChartOptions: getByVehicleAgeChartOptionStrings(
      byVehicleAgeChartActions
    ),
    topContributorsGroupByOptions:
      toEncodedNonEmptyStringArray(groupBySelectOptions),
    // TODO: remove the above attribute when we remove page agents or refactor them to use select options
    topContributorsGroupBySelectOptions: groupBySelectOptions,
    topContributorsChartOptions: {
      y: toNonEmptyStringArray(topContributorsYAxisOptions),
      exposure: exposuresWithBuckets,
    },
  };
};

const useSignalEventsAnalyticsAgentData =
  (): AvailableData["signalEventAnalytics"] => {
    const signalEventsActions = getSignalEventChartActions();
    const byVehicleAgeActions = useSignalEventAnalyticsByVehicleAgeChartActions(
      EventTypeLabel.SIGNAL_EVENT
    );
    const topContributorsExposures = useTopContributorsExposureOptions(
      EventTypeEnum.SIGNAL_EVENT
    );
    const { attributes } = useSignalEventOccurrencesSchema();
    const topContributorsGroupBySelectOptions = useNonEmptyGroupBySelectOptions(
      EventTypeEnum.SIGNAL_EVENT
    );
    const associatedClaimsGroupBySelectOptions =
      useNonEmptyGroupBySelectOptions(EventTypeEnum.CLAIM, true);

    const associatedSignalEventsWindowDirectionOptions =
      WINDOW_DIRECTION_OPTIONS.map(
        (windowDirectionOption) => windowDirectionOption.id
      );
    assertNonEmptyStringArray(associatedSignalEventsWindowDirectionOptions);

    return {
      signalEventsChartOptions:
        getSignalEventsChartOptionStrings(signalEventsActions),
      byVehicleAgeChartOptions:
        getByVehicleAgeChartOptionStrings(byVehicleAgeActions),
      topContributorsChartOptions: {
        y: toNonEmptyStringArray(topContributorsChartYAxisOptions),
        exposure: createExposureHierarchy(topContributorsExposures, attributes),
      },
      topContributorsGroupByOptions: toEncodedNonEmptyStringArray(
        topContributorsGroupBySelectOptions
      ),
      // TODO: remove the above attribute when we remove page agents or refactor them to use select options
      topContributorsGroupBySelectOptions,
      associatedClaimsGroupByOptions: toNonEmptyStringArray(
        associatedClaimsGroupBySelectOptions
      ),
      associatedSignalEventsWindowDirectionOptions,
    };
  };

const useVinViewAgentData = (): AvailableData["vinView"] | null => {
  const [vinView, setVinView] = useState<AvailableData["vinView"] | null>(null);

  const vinViewTabs = useVinViewTabs();

  useEffect(() => {
    const fetchData = async () => {
      const sensorData = await getSensors({ limit: 1000 });
      const sensorOptions: NonEmptyStringArray =
        sensorData.data.length === 0
          ? ["No sensors available"]
          : (sensorData.data.map((sensor) => sensor.ID) as NonEmptyStringArray);

      const vinViewData = {
        timelineChartOptions:
          getVinViewTimelineChartOptionStrings(CHART_ACTIONS),
        sensorOptions,
        vinViewTabs,
      };

      setVinView(vinViewData);
    };

    fetchData();
    // We just want to run this once when the component initializes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return vinView;
};

/**
 * This hook assembles the relatively static data that the Duck agent needs in order
 * to do its work.
 * More dynamic data is handled by the separate usePageState hook.
 */
export const useAvailableData = (): { availableData: AvailableData } => {
  const claimAnalytics = useClaimAnalyticsAgentData();
  const signalEventAnalytics = useSignalEventsAnalyticsAgentData();
  const vinView = useVinViewAgentData();

  const { data: issueMetrics } = useGetIssueMetricsRegistry();
  const { data: suggestedIssueMetrics } =
    useGetSuggestedIssuesMetricsRegistry();

  if (!issueMetrics || !suggestedIssueMetrics) {
    return {
      availableData: {
        claimAnalytics,
        signalEventAnalytics,
        vinView,
        issues: null,
      },
    };
  }

  const issues = getIssuesAgentData(issueMetrics, suggestedIssueMetrics);

  return {
    availableData: { claimAnalytics, signalEventAnalytics, vinView, issues },
  };
};

interface SignalEventOccurrencesData {
  signalEventOccurrencesFilterQueryString: string;
  signalEventOccurrencesWindowSize: number;
  signalEventOccurrencesWindowDirection: string;
}

const useSignalEventOccurrencesData = (): SignalEventOccurrencesData => {
  const {
    pages: { signalEventsAnalytics },
  } = useConfigContext();

  const defaultSignalEventFilters = signalEventsAnalytics?.defaultFilters
    ? signalEventsAnalytics?.defaultFilters
    : DEFAULT_EMPTY_OCCURS_FILTER.filters;

  const defaultAppliedFilters = {
    ...DEFAULT_EMPTY_OCCURS_FILTER,
    filters: defaultSignalEventFilters,
  };
  const occursFilter = getFromLocalStorage(
    OCCURS_FILTER_KEY,
    defaultAppliedFilters
  );

  const rawWindowDirection = occursFilter?.windowDirection;
  const windowDirection = rawWindowDirection ? String(rawWindowDirection) : "";

  return {
    signalEventOccurrencesFilterQueryString: occursFilter?.filters,
    signalEventOccurrencesWindowSize: +occursFilter?.windowSize,
    signalEventOccurrencesWindowDirection: windowDirection,
  };
};

/**
 * The state of the claim analytics page.
 */
const useClaimAnalyticsPageState = (): PageState["claimAnalytics"] => {
  const {
    pages: { claimAnalytics },
  } = useConfigContext();

  const claimsPageKeyWithVersion = getPageKeyWithVersion(CLAIMS_PAGE_KEY);
  const defaultClaimFilters = filterBuilderQueryToFilterBuilderState(
    claimAnalytics?.defaultFilters
  );
  const claimFilterSortState = getStateFromLocalStorage(
    claimsPageKeyWithVersion,
    defaultClaimFilters
  );

  const vehiclesPageKeyWithVersion = getPageKeyWithVersion(
    CLAIM_ANALYTICS_VEHICLES_PAGE_KEY
  );
  const defaultVehicleFilters = filterBuilderQueryToFilterBuilderState(
    claimAnalytics?.defaultVehicleFilters
  );
  const vehiclesFilterSortState = getStateFromLocalStorage(
    vehiclesPageKeyWithVersion,
    defaultVehicleFilters
  );

  const signalEventOccurrencesData = useSignalEventOccurrencesData();

  const claimsTablePageKeyWithVersion = getPageKeyWithVersion(
    CLAIMS_TABLE_PAGE_KEY
  );
  const claimTableFilterSortState = getStateFromLocalStorage(
    claimsTablePageKeyWithVersion
  );

  return {
    claimsFilterQueryString: getFiltersQuery(claimFilterSortState.filters),
    vehiclesFilterQueryString: getFiltersQuery(vehiclesFilterSortState.filters),
    claimsTableSortQueryString:
      getSortFilter(claimTableFilterSortState.sort) ??
      getSortFilter(CLAIMS_TABLE_DEFAULT_SORT) ??
      "",
    claimsTableFilterQueryString: getFiltersQuery(
      claimTableFilterSortState.filters
    ),
    selectedClaimsChartOptions: getSelectedChartOptions(
      claimFilterSortState.chartSettings,
      CLAIMS_TAB_KEY,
      CLAIMS_CHART_OPTIONS_KEY
    ),
    selectedByVehicleAgeChartOptions: getSelectedChartOptions(
      claimFilterSortState.chartSettings,
      BY_VEHICLES_AGE_TAB_KEY,
      BY_VEHICLE_AGE_CHART_OPTIONS_KEY
    ),
    selectedGroupByAttribute: getSelectedGroupByAttribute(
      claimFilterSortState.chartSettings,
      CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
      DEFAULT_CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_ATTRIBUTE
    ),
    selectedTopContributorsChartOptions: getSelectedChartOptions(
      claimFilterSortState.chartSettings,
      TOP_CONTRIBUTORS_TAB_KEY,
      CLAIM_ANALYTICS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY
    ),
    signalEventOccurrencesFilterQueryString:
      signalEventOccurrencesData.signalEventOccurrencesFilterQueryString,
    signalEventOccurrencesWindowSize:
      signalEventOccurrencesData.signalEventOccurrencesWindowSize,
    signalEventOccurrencesWindowDirection:
      signalEventOccurrencesData.signalEventOccurrencesWindowDirection,
  };
};

const getSignalEventAssociatedClaimsGroupByAttribute = (
  chartSettings: PageChartSettingsState | undefined
): string => {
  const associatedClaimsGroupByOptions = getSelectedChartOptions(
    chartSettings,
    ASSOCIATED_CLAIMS_TAB_KEY,
    SIGNAL_EVENTS_ASSOCIATED_CLAIMS_GROUP_BY_OPTIONS_KEY
  );

  return (
    associatedClaimsGroupByOptions?.[GROUP_BY_ATTRIBUTE_KEY] ??
    ASSOCIATED_CLAIMS_DEFAULT_GROUP_BY_ATTRIBUTE
  );
};

const getSignalEventAssociatedClaimsWindowSize = (
  chartSettings: PageChartSettingsState | undefined
): number => {
  const associatedClaimsWindowSizeOptions = getSelectedChartOptions(
    chartSettings,
    ASSOCIATED_CLAIMS_TAB_KEY,
    SIGNAL_EVENTS_ASSOCIATED_CLAIMS_WINDOW_SIZE_OPTIONS_KEY
  );

  const windowSizeString = associatedClaimsWindowSizeOptions?.[WINDOW_SIZE_KEY];

  return windowSizeString ? parseInt(windowSizeString) : DEFAULT_WINDOW_SIZE;
};

interface WindowData {
  windowDirection: OccursFilterWindowDirection;
  windowSize: number;
}

const getWindowDataFromOccursFilter = (
  relatedSignalEventsFilter: OccursFilterState | undefined
): WindowData => {
  const filterToUse = relatedSignalEventsFilter ?? DEFAULT_EMPTY_OCCURS_FILTER;

  const windowSize = filterToUse.windowSize;
  const windowDirection =
    filterToUse.windowDirection ?? OccursFilterWindowDirection.BEFORE;

  try {
    assertOccursFilterWindowDirection(windowDirection);

    return { windowDirection, windowSize };
  } catch (windowDirectionError) {
    console.warn(windowDirectionError);

    return { windowDirection: OccursFilterWindowDirection.BEFORE, windowSize };
  }
};

/**
 * The state of the signal events analytics page.
 */
const useSignalEventAnalyticsPageState =
  (): PageState["signalEventAnalytics"] => {
    const {
      pages: { signalEventsAnalytics },
    } = useConfigContext();

    const signalEventsPageKeyWithVersion = getPageKeyWithVersion(
      SIGNAL_EVENTS_PAGE_KEY
    );
    const defaultSignalEventFilters = filterBuilderQueryToFilterBuilderState(
      signalEventsAnalytics?.defaultFilters
    );
    const signalEventFilterSortState = getStateFromLocalStorage(
      signalEventsPageKeyWithVersion,
      defaultSignalEventFilters
    );

    const vehiclesPageKeyWithVersion = getPageKeyWithVersion(
      SIGNAL_EVENTS_ANALYTICS_VEHICLES_PAGE_KEY
    );
    const defaultVehicleFilters = filterBuilderQueryToFilterBuilderState(
      signalEventsAnalytics?.defaultVehicleFilters
    );
    const vehiclesFilterSortState = getStateFromLocalStorage(
      vehiclesPageKeyWithVersion,
      defaultVehicleFilters
    );

    const associatedClaimsFilterSortState = getStateFromLocalStorage(
      getPageKeyWithVersion(SE_ASSOCIATED_CLAIMS_KEY),
      useDefaultClaimFilters()
    );

    const associatedSignalEventsFilterSortState = getStateFromLocalStorage(
      getPageKeyWithVersion(SE_ASSOCIATED_SE_PAGE_KEY),
      useDefaultClaimFilters()
    );
    const { windowSize, windowDirection } = getWindowDataFromOccursFilter(
      associatedSignalEventsFilterSortState.relatedSignalEventsFilter
    );

    return {
      signalEventsFilterQueryString: getFiltersQuery(
        signalEventFilterSortState.filters
      ),
      vehiclesFilterQueryString: getFiltersQuery(
        vehiclesFilterSortState.filters
      ),
      selectedSignalEventsChartOptions: getSelectedChartOptions(
        signalEventFilterSortState.chartSettings,
        SIGNAL_EVENTS_TAB_KEY,
        SIGNAL_EVENT_CHART_OPTIONS_KEY
      ),
      selectedByVehicleAgeChartOptions: getSelectedChartOptions(
        signalEventFilterSortState.chartSettings,
        BY_VEHICLES_AGE_TAB_KEY,
        SIGNAL_EVENTS_BY_VEHICLE_AGE_CHART_OPTIONS_KEY
      ),
      selectedTopContributorsChartOptions: getSelectedChartOptions(
        signalEventFilterSortState.chartSettings,
        TOP_CONTRIBUTORS_TAB_KEY,
        SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY
      ),
      selectedTopContributorsGroupByAttribute: getSelectedGroupByAttribute(
        signalEventFilterSortState.chartSettings,
        SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
        DEFAULT_SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_ATTRIBUTE
      ),
      selectedAssociatedClaimsOptions: {
        filterQueryString: getFiltersQuery(
          associatedClaimsFilterSortState.filters
        ),
        windowSize: getSignalEventAssociatedClaimsWindowSize(
          associatedClaimsFilterSortState.chartSettings
        ),
        groupByAttribute: getSignalEventAssociatedClaimsGroupByAttribute(
          associatedClaimsFilterSortState.chartSettings
        ),
      },
      selectedAssociatedSignalEventsOptions: {
        filterQueryString: getFiltersQuery(
          associatedSignalEventsFilterSortState.filters
        ),
        windowSize,
        windowDirection,
      },
    };
  };

/**
 * The state of the issues page.
 */
const useIssuesPageState = (): PageState["issues"] => {
  const {
    pages: { issues },
  } = useConfigContext();

  const issuesPageKeyWithVersion = getPageKeyWithVersion(ISSUES_PAGE_KEY);
  const defaultFilters = filterBuilderQueryToFilterBuilderState(
    issues?.defaultFilters
  );
  const issueFilterSortState = getStateFromLocalStorage(
    issuesPageKeyWithVersion,
    defaultFilters
  );

  const suggestedIssuesPageKeyWithVersion = getPageKeyWithVersion(
    SUGGESTED_ISSUES_PAGE_KEY
  );

  const suggestedIssueFilterSortState = getStateFromLocalStorage(
    suggestedIssuesPageKeyWithVersion,
    defaultFilters
  );

  return {
    issuesFilterQueryString: getFiltersQuery(issueFilterSortState.filters),
    suggestedIssuesFilterQueryString: getFiltersQuery(
      suggestedIssueFilterSortState.filters
    ),
    issuesSortQueryString: getSortFilter(issueFilterSortState.sort) ?? "",
    suggestedIssuesSortQueryString:
      getSortFilter(suggestedIssueFilterSortState.sort) ?? "",
    selectedIssuesChartOptions: getSelectedChartOptions(
      issueFilterSortState.chartSettings,
      ISSUES_KEY,
      ISSUES_CHART_KEY
    ),
    selectedSuggestedIssuesChartOptions: getSelectedChartOptions(
      suggestedIssueFilterSortState.chartSettings,
      SUGGESTED_ISSUES_KEY,
      SUGGESTED_ISSUES_CHART_KEY
    ),
  };
};

/**
 * getPageState obtains the current page state so that it can be passed to the agent.
 * The data returned by this function is not static, and is updated when the user
 * navigates to a different tab or changes the filters. For this reason, we must obtain
 * it at the time that the agent is called.
 *
 * @returns The current page state.
 */
export const usePageState = (): PageState => {
  const { tab } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  return {
    pathname: window.location.pathname,
    selectedTab: tab ? String(tab) : "",
    claimAnalytics: useClaimAnalyticsPageState(),
    signalEventAnalytics: useSignalEventAnalyticsPageState(),
    vehicles: getVehiclesPageState(),
    vinView: getVinViewPageState(),
    issues: useIssuesPageState(),
    issueDetails: getIssuesDetailsPageState(),
  };
};

const getInitialDuckVisibility = (): boolean => {
  if (sessionStorage) {
    const visibilityFromStorage = sessionStorage.getItem(DUCK_VISIBILITY_KEY);
    if (visibilityFromStorage) {
      return true;
    }
  }

  return false;
};

export const useDuckVisibility = () => {
  const [open, setOpen] = useState(getInitialDuckVisibility());
  const { isOpen: isForcedOpen, setIsOpen: setIsForcedOpen } =
    useContext(DuckletContext);

  const setIsDuckVisible = (visible: boolean) => {
    setOpen(visible);
    persistVisibility(visible);
  };

  useEffect(() => {
    if (isForcedOpen !== undefined) {
      setIsDuckVisible(isForcedOpen);
      // The force open state is a one time thing. Reset it.
      setIsForcedOpen(undefined);
    }
  }, [isForcedOpen, setIsForcedOpen]);

  return { isDuckVisible: open, setIsDuckVisible };
};

/**
 * @summary This hook provides a thread id for the Duck agent, and also provides
 * a mechanism to reset it. Resetting the memory of the Duck session is accomplished
 * by resetting the thread id.
 * @returns The current thread id and a function to reset it.
 */
export const useThreadId = () => {
  const [threadId, setThreadId] = useState(getInitialThreadId());

  const resetThreadId = () => {
    const updatedThreadId = randomID();
    if (sessionStorage) {
      sessionStorage.setItem(LANGCHAIN_THREAD_ID_KEY, updatedThreadId);
    }

    setThreadId(updatedThreadId);
  };

  return { threadId, resetThreadId };
};

/**
 * When using this hook, we must be sure that the hook does not get
 * recreated based on its location in the component tree. If it does, the agent
 * would be working with a disconnected instance of the hook that does not
 * actually do anything.
 * A simple solution is to use the hook on a component high in the hierarchy
 * that is not likely to be re-rendered often.
 */
export const useQueryStringNavigation = (): QueryStringNavigation => {
  const navigate = useNavigate();
  const initialReloadRequired =
    sessionStorage.getItem(DUCK_RELOAD_REQUIRED_KEY) ?? Reload.NONE;
  const reloadRequiredRef = useRef<Reload>(initialReloadRequired as Reload);

  const initialUpdatedQueryParams = getInitialUpdatedQueryParams();
  const [updatedQueryParams, setUpdatedQueryParamsInternal] = useState<
    Record<string, string>
  >(initialUpdatedQueryParams);

  const setUpdatedQueryParams: Dispatch<
    SetStateAction<Record<string, string>>
  > = (action) => {
    setUpdatedQueryParamsInternal((prev) => {
      if (typeof action === "function") {
        return action(prev);
      } else {
        return action;
      }
    });
  };

  useEffect(() => {
    sessionStorage.setItem(
      DUCK_UPDATED_QUERY_PARAMS_KEY,
      JSON.stringify(updatedQueryParams)
    );
  }, [updatedQueryParams]);

  // When setting the sort parameter, we need to remove an existing conflicting
  // sort parameter, if it exists. The conflicting sort param will share the same
  // root name but will have a different ending.
  // An example is: `sort_v1.vehicles[mileage]=desc`
  // If we wanted to set a param like `sort_v1.vehicles_table[vehicleModelYear]=asc`,
  // we would need to remove the existing sort param first. The name of the
  // conflicting sort param would start with `sort_v1.vehicles_table[`.
  const initialOmitExistingQueryParamsStartingWith =
    sessionStorage.getItem(DUCK_OMIT_EXISTING_QUERY_PARAMS_STARTING_WITH_KEY) ??
    undefined;
  const [
    omitExistingQueryParamsStartingWith,
    setOmitExistingQueryParamsStartingWithInternal,
  ] = useState<string | undefined>(initialOmitExistingQueryParamsStartingWith);

  const setOmitExistingQueryParamsStartingWith = (
    prefix: string | undefined
  ) => {
    setOmitExistingQueryParamsStartingWithInternal(prefix);
    if (prefix === undefined) {
      sessionStorage.removeItem(
        DUCK_OMIT_EXISTING_QUERY_PARAMS_STARTING_WITH_KEY
      );
    } else {
      sessionStorage.setItem(
        DUCK_OMIT_EXISTING_QUERY_PARAMS_STARTING_WITH_KEY,
        prefix
      );
    }
  };

  // This is also used for sorting. To extend the prior example, it is possible
  // that there would be a degenerate query param that indicates that there is no
  // sort applied, i.e. `sort_v1.vehicles_table=`
  // We need to be sure to get rid of that also so it doesn't conflict with a new
  // sort we are setting.
  const initialOmitExistingQueryParam =
    sessionStorage.getItem(DUCK_OMIT_EXISTING_QUERY_PARAM_KEY) ?? undefined;
  const [omitExistingQueryParam, setOmitExistingQueryParamInternal] = useState<
    string | undefined
  >(initialOmitExistingQueryParam);

  const setOmitExistingQueryParam = (paramName: string | undefined) => {
    setOmitExistingQueryParamInternal(paramName);
    if (paramName === undefined) {
      sessionStorage.removeItem(DUCK_OMIT_EXISTING_QUERY_PARAM_KEY);
    } else {
      sessionStorage.setItem(DUCK_OMIT_EXISTING_QUERY_PARAM_KEY, paramName);
    }
  };

  // The routeValue corresponds to the page in the app
  const initialRouteValue = sessionStorage.getItem(DUCK_ROUTE_VALUE_KEY) ?? "";
  const [routeValue, setRouteValueInternal] = useState(initialRouteValue);

  const setRouteValue = (routeValue: string): void => {
    setRouteValueInternal(routeValue);
    sessionStorage.setItem(DUCK_ROUTE_VALUE_KEY, routeValue);
  };

  const setReloadRequired = (reload: Reload): void => {
    reloadRequiredRef.current = reload;
    sessionStorage.setItem(DUCK_RELOAD_REQUIRED_KEY, reload);
  };

  const getPriorQueryParams = (): ParsedQs => {
    if (window.location.pathname !== routeValue) {
      // We are going to a new page. Discard the query params from the old page.
      return {};
    }

    const priorQueryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });

    if (!omitExistingQueryParamsStartingWith && !omitExistingQueryParam) {
      return priorQueryParams;
    }

    return Object.fromEntries(
      Object.entries(priorQueryParams).filter(
        ([key]) =>
          !omitExistingQueryParamsStartingWith ||
          (!key.startsWith(omitExistingQueryParamsStartingWith) &&
            (!omitExistingQueryParam || key !== omitExistingQueryParam))
      )
    );
  };

  const deliverLocationInfo = (reset: boolean = true): LocationInfo => {
    const newQueryParams = { ...getPriorQueryParams(), ...updatedQueryParams };

    const path = createPath({
      pathname: routeValue,
      search: qs.stringify(newQueryParams, { arrayFormat: "indices" }),
      hash: window.location.hash,
    });

    const response = {
      reloadRequired: reloadRequiredRef.current,
      path,
      url: `${window.location.origin}${path}`,
    };

    if (reset) {
      clearLocationInfo();
    }

    return response;
  };

  /**
   * Clear the data managed by this utility.
   */
  const clearLocationInfo = () => {
    setUpdatedQueryParams(() => ({}));
    setReloadRequired(Reload.NONE);
  };

  const updateLocation = (): void => {
    const { path, url, reloadRequired } = deliverLocationInfo();
    console.log(`updateLocation: ${JSON.stringify({ reloadRequired, path })}`);
    if (reloadRequired === Reload.HARD) {
      window.location.assign(url);
    } else if (reloadRequired === Reload.SOFT) {
      navigate(path);
    }
  };

  const setMinimumReload = (reload: Reload) => {
    if (reload === Reload.HARD) {
      setReloadRequired(Reload.HARD);
    } else if (
      reload === Reload.SOFT &&
      reloadRequiredRef.current === Reload.NONE
    ) {
      setReloadRequired(Reload.SOFT);
    }
  };

  const updateQueryStringParameter = (
    paramName: string,
    paramValue: string,
    reload: Reload = Reload.NONE
  ): void => {
    setUpdatedQueryParams((prev) => ({ ...prev, [paramName]: paramValue }));

    setMinimumReload(reload);

    console.log(
      `${new Date().getTime()} set query string parameter "${paramName}" to "${paramValue}"`
    );
  };

  const navigateToTab = (tabId: string) => {
    updateQueryStringParameter(TAB_QUERY_PARAM, tabId, Reload.SOFT);
  };

  const updateFilter = (
    filterQueryString: string,
    queryStringParameterName: string
  ): void => {
    updateQueryStringParameter(
      queryStringParameterName,
      filterQueryString,
      Reload.HARD
    );
  };

  return {
    deliverLocationInfo,
    updateLocation,
    clearLocationInfo,
    setRouteValue,
    setMinimumReload,
    updateQueryStringParameter,
    navigateToTab,
    updateFilter,
    setOmitExistingQueryParam,
    setOmitExistingQueryParamsStartingWith,
  };
};

const overrideAccessToKnightSwiftDuckDemo = (
  ksDemoQsParam: ParsedQs[string]
): boolean | undefined => {
  const KNIGHTSWIFT_DUCK_DEMO_KEY = "knightSwiftDuckDemo";

  if (ksDemoQsParam === "false" || ksDemoQsParam === "true") {
    localStorage.setItem(KNIGHTSWIFT_DUCK_DEMO_KEY, ksDemoQsParam);

    return ksDemoQsParam === "true";
  }

  if (ksDemoQsParam === "") {
    localStorage.removeItem(KNIGHTSWIFT_DUCK_DEMO_KEY);

    return undefined;
  }

  const ksDemoLocalStorage = localStorage.getItem(KNIGHTSWIFT_DUCK_DEMO_KEY);

  if (ksDemoLocalStorage === "false") return false;

  if (ksDemoLocalStorage === "true") return true;

  if (ksDemoLocalStorage !== null) {
    localStorage.removeItem(KNIGHTSWIFT_DUCK_DEMO_KEY);
  }

  return undefined;
};

export const useDuckAccess = (): DuckAccess => {
  const flags = useFlags();
  const { ksDemo } = useQuery();
  const { ECUs } = useContext(VehicleECUsAttributeContext);
  const { options } = useContext(VehicleOptionsAttributeContext);
  const { pages } = useConfigContext();

  return getDuckAccess(
    flags,
    Boolean(ECUs?.length),
    Boolean(options?.length),
    overrideAccessToKnightSwiftDuckDemo(ksDemo),
    pages
  );
};

export const usePendingAction = (): PendingAction => {
  const initialPendingAction =
    sessionStorage.getItem(DUCK_PENDING_ACTION_KEY) === "true";

  const [pendingAction, setPendingActionInternal] =
    useState(initialPendingAction);

  const setPendingAction = (pendingAction: boolean) => {
    setPendingActionInternal(pendingAction);
    if (pendingAction) {
      sessionStorage.setItem(DUCK_PENDING_ACTION_KEY, "true");
    } else {
      sessionStorage.removeItem(DUCK_PENDING_ACTION_KEY);
    }
  };

  return { pendingAction, setPendingAction };
};

export const useCtrlDKeyPress = () => {
  const [isCtrlDKeyPressed, setIsCtrlDKeyPressed] = useState(false);
  const dKey = "d";

  useEffect(() => {
    const downHandler = (event: KeyboardEvent) => {
      if (event.key === dKey && event.ctrlKey) {
        setIsCtrlDKeyPressed(true);
      }
    };

    const upHandler = (event: KeyboardEvent) => {
      if (event.key === dKey && event.ctrlKey) {
        setIsCtrlDKeyPressed(false);
      }
    };

    window.addEventListener("keydown", downHandler);
    window.addEventListener("keyup", upHandler);

    return () => {
      window.removeEventListener("keydown", downHandler);
      window.removeEventListener("keyup", upHandler);
    };
  }, []);

  return isCtrlDKeyPressed;
};

export enum SchemaUsage {
  FILTER = "filter",
  SORT = "sort",
}

export const useSchema = (
  source: Source,
  usage: SchemaUsage
): SchemaEntry[] => {
  const { schema: claimsSchema } = useClaimsSchema();
  const { schema: vehiclesSchema } = useVehiclesSchema();
  const { schema: signalEventOccurencesSchema } =
    useSignalEventOccurrencesSchema();
  const { schema: issuesSchema } = useIssuesSchema();
  const { schema: suggestedIssuesSchema } = useSuggestedIssuesSchema();

  // The keys in this map should be all lower case.
  // This simplifies getting a match from the parameter source.
  const unfilteredSchemaMap: Partial<Record<Source, SchemaEntry<string>[]>> = {
    [Source.CLAIM]: claimsSchema,
    [Source.VEHICLE]: vehiclesSchema,
    [Source.SIGNAL_EVENT_OCCURRENCE]: signalEventOccurencesSchema,
    [Source.ISSUE]: issuesSchema,
    [Source.SUGGESTED_ISSUE]: suggestedIssuesSchema,
  };

  return (unfilteredSchemaMap[source] ?? []).filter((schemaEntry) => {
    if (usage === SchemaUsage.SORT) return schemaEntry.sortable;

    if (usage === SchemaUsage.FILTER)
      return schemaEntry.filter && !schemaEntry.hideFilter;

    return false;
  });
};
