import React from "react";

import { useKeyBinding } from "shared/hooks";

import DropdownSelect from "features/ui/DropdownSelect";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import FilterSelector from "features/ui/Filters/FilterWizard/FilterSelector";
import { UseFilterSortState } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

export interface PageLevelFilterProps {
  dropDownTestId: string;
  filterTestId: string;
  filterLabel: string;
  open: boolean;
  onOpen: (open: boolean) => void;
  onClose: () => void;
  filterSchema: SchemaEntry<string>[];
  filterSortState: UseFilterSortState;
  defaultFilters?: FilterGroupState;
  filterTitle: string;
  pendingFilterKey: string;
  tooltip?: React.ReactElement;
  keybinding?: string[];
  entity: string;
  quickFilterLabel?: string;
}

const PageLevelFilter = ({
  filterTestId,
  dropDownTestId,
  filterLabel,
  open,
  filterSchema,
  filterSortState,
  defaultFilters,
  filterTitle,
  pendingFilterKey,
  tooltip,
  onOpen,
  onClose,
  keybinding,
  entity,
}: PageLevelFilterProps) => {
  useKeyBinding(
    keybinding || [],
    () => {
      if (keybinding) {
        if (!open) {
          onOpen(true);
        } else {
          onClose();
        }
      }
    },
    true
  );

  return (
    <DropdownSelect
      testId={dropDownTestId}
      label={filterLabel}
      open={open}
      onOpen={onOpen}
      tooltip={tooltip}
      hasAdvancedFilters={filterSortState.isAdvancedFilterEditor}
      content={
        <FilterSelector
          schema={filterSchema}
          filterSortState={filterSortState}
          defaultFilters={defaultFilters}
          title={filterTitle}
          testId={filterTestId}
          pendingFiltersKey={pendingFilterKey}
          baseEntityText={entity}
          onCloseFilters={onClose}
          initialIsAdvancedFilter={filterSortState.isAdvancedFilterEditor}
        />
      }
    />
  );
};

export default PageLevelFilter;
