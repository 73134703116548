export enum RouteKey {
  Root = "root",
  Tenants = "tenants",
  NewTenant = "newTenant",
  LoginCallback = "loginCallback",
  NotFound = "notFound",
  Docs = "docs",
}

export const routes: Record<RouteKey, string> = {
  [RouteKey.Root]: "/",
  [RouteKey.Tenants]: "/tenants",
  [RouteKey.NewTenant]: "/tenants/new",
  [RouteKey.LoginCallback]: "/login/callback",
  [RouteKey.Docs]: "/docs",
  [RouteKey.NotFound]: "*",
};
