import React, { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { differenceInHours, formatDistance } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";

import {
  DATE_WITH_TIME_FORMAT,
  MONTH_YEAR,
  MONTH_YEAR_DAY,
  SHORT_DATE_FORMAT,
  TIME_AGO_THRESHOLD_HOURS,
} from "shared/constants";
import { formatDate, formatPercent } from "shared/utils";

import { FilterSchemaItem } from "features/ui/Filters/types";
import { Alignment } from "features/ui/Table";
import Tooltip from "features/ui/Tooltip";

import styles from "./TableBodyCell.module.css";
import { DataType, TableCellValue } from "./types";

const DECIMALS_DEFAULT = 2;
const MIN_DECIMALS_DEFAULT = 0;

export const formatValue = ({
  value,
  dataType,
  decimals = DECIMALS_DEFAULT,
  minDecimals = MIN_DECIMALS_DEFAULT,
}: {
  value: TableCellValue;
  dataType: DataType;
  decimals?: number;
  minDecimals?: number;
}) => {
  if (dataType === DataType.NUMBER) {
    try {
      if (typeof value !== "number") {
        throw new Error();
      }

      return new Intl.NumberFormat("en-US", {
        maximumFractionDigits: decimals,
        minimumFractionDigits: minDecimals,
      }).format(value);
    } catch {}
  }

  if (dataType === DataType.PERCENTAGE) {
    try {
      return Number(value).toFixed(decimals) + "%";
    } catch {}
  }

  if (dataType === DataType.RATIO_PERCENTAGE) {
    try {
      return formatPercent(value as number);
    } catch {}
  }

  if (dataType === DataType.DATE) {
    try {
      return formatDate(value as string, SHORT_DATE_FORMAT, true);
    } catch {}
  }

  // Hacky way to get rid of local dates with Date.parse() having UTC initially
  // see https://stackoverflow.com/questions/58561169/date-fns-how-do-i-format-to-utc
  if (dataType === DataType.DATE_UTC) {
    try {
      return formatInTimeZone(value as string, "UTC", "MM/dd/yyy");
    } catch {}
  }

  if (
    [
      DataType.DATE_WITH_TIME,
      DataType.DATE_WITH_TIME_NO_TZ,
      DataType.DATE_WITH_TIME_UTC,
    ].includes(dataType)
  ) {
    try {
      return formatDate(value as string, DATE_WITH_TIME_FORMAT);
    } catch {}
  }

  if (dataType === DataType.DATE_WITH_TIME_OR_TIME_AGO_IF_RECENT) {
    try {
      if (
        Math.abs(differenceInHours(new Date(value as string), new Date())) <
        TIME_AGO_THRESHOLD_HOURS
      ) {
        return formatDistance(new Date(value as string), new Date(), {
          addSuffix: true,
        });
      }

      return formatDate(value as string, DATE_WITH_TIME_FORMAT);
    } catch {}
  }

  if (dataType === DataType.DATE_YEAR_MONTH) {
    try {
      return formatDate(value as string, MONTH_YEAR, true);
    } catch {}
  }

  if (dataType === DataType.DATE_YEAR_MONTH_DAY) {
    try {
      return formatDate(value as string, MONTH_YEAR_DAY, true);
    } catch {}
  }

  if (dataType === DataType.BOOLEAN) {
    return <BooleanValueCell value={value} />;
  }

  if (React.isValidElement(value)) {
    return value;
  }

  // by default try to format as string
  try {
    return value.toString().replace(/_/g, " ");
  } catch {}

  return value;
};

interface TableBodyCellProps {
  value: TableCellValue;
  idx: number;
  dataType: DataType;
  align?: Alignment;
  toggleable?: boolean;
  dense?: boolean;
  sortable?: boolean;
  filter?: FilterSchemaItem;
  onClick?: () => void;
  decimals?: number;
  minDecimals?: number;
  limitedWidthClass?: string;
  stickyFirstColumn?: boolean;
  partOfSelectableRow?: boolean;
  itsRowIsSelected?: boolean;
  label?: string;
}

const TableBodyCell = ({
  value,
  idx,
  dataType,
  align,
  toggleable = false,
  dense = false,
  onClick,
  decimals,
  minDecimals,
  limitedWidthClass,
  stickyFirstColumn,
  partOfSelectableRow,
  itsRowIsSelected,
  label,
}: TableBodyCellProps) => {
  // we always align number cells to the right
  const isRightAligned = align === "right" || dataType === "number";
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const className = classNames(
    styles.cell,
    styles[`cell-${dataType}`],
    limitedWidthClass,
    {
      ["cursor-pointer hover:text-metabase-blue"]: Boolean(onClick),
      [styles.alignRight]: isRightAligned,
      [styles.alignCenter]: align === "center",
      [styles.dense]: dense,
      "text-clip overflow-hidden": limitedWidthClass,
    }
  );
  const formattedValue = formatValue({
    value,
    dataType,
    decimals,
    minDecimals,
  });

  const title =
    !!limitedWidthClass && typeof formattedValue === "string"
      ? formattedValue
      : undefined;

  const isSticky = Boolean(stickyFirstColumn && idx === 0);

  useEffect(() => {
    const element = textRef.current;
    if (element && typeof formattedValue === "string") {
      const hasOverflow = element.scrollWidth > element.clientWidth;
      setIsOverflowing(hasOverflow);
    }
  }, [formattedValue]);

  const moreButton = (
    <span
      className="more-button text-xs text-metabase-blue hover:text-blue-500 group-hover:bg-gray-50 bg-white pl-1"
      role="button"
      onClick={() => setDialogOpen(true)}
    >
      [...]
    </span>
  );

  const content = (
    <div
      ref={textRef}
      onClick={() => onClick && onClick()}
      className={className}
    >
      {formattedValue}
    </div>
  );

  return (
    <td
      // we need group-hover here so that bg change applies to sticky cell too
      className={classNames("align-top border border-gray-100", {
        toggleable,
        "text-right": isRightAligned,
        "sticky left-0 bg-white z-1": isSticky,
        "group-hover:bg-gray-50": !partOfSelectableRow && !itsRowIsSelected,
        "group-hover:bg-blue-50": partOfSelectableRow && !itsRowIsSelected,
      })}
    >
      {isOverflowing && title ? (
        <>
          <Tooltip title={title} enterDelay={750} placement="bottom-start">
            <div className="w-full flex flex-row items-center">
              {content}
              {moreButton}
            </div>
          </Tooltip>
          <Dialog
            open={dialogOpen}
            onClose={() => setDialogOpen(false)}
            maxWidth="sm"
            slotProps={{
              paper: {
                className: "max-h-[60vh]! pb-6!",
              },
            }}
          >
            <DialogTitle>{label}</DialogTitle>
            <DialogContent>{formattedValue}</DialogContent>
          </Dialog>
        </>
      ) : (
        content
      )}
      <RightBorderForStickyCell isSticky={isSticky} />
    </td>
  );
};

export const RightBorderForStickyCell = ({
  isSticky,
  colorClass = "bg-gray-50",
}: {
  isSticky: boolean;
  colorClass?: string;
}) => {
  if (!isSticky) return null;

  return (
    <span
      className={classNames(
        "w-px absolute top-0 bottom-0 right-0 h-100",
        colorClass
      )}
    ></span>
  );
};

const BooleanValueCell = ({ value }: { value: any }) => {
  if (value === true) {
    return <AiOutlineCheck />;
  }

  if (value === false) {
    return <AiOutlineClose />;
  }

  return "";
};

export default TableBodyCell;
