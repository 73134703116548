import { addWeeks, format } from "date-fns";

import { API_DATE_FORMAT } from "shared/constants";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { decodeOccursFilterAndOptions } from "features/ui/Filters/FilterTypes/OccursFilter/utils";
import { FilterOperator } from "features/ui/Filters/types";

import { DEFAULT_ISSUE_DATA } from "./constants";
import { IssueFormData } from "./types";

export const getVINTimelineParamsFromAssociatedVehicles = (
  vehicleManufacturedAt: string | null,
  firstOccurrenceDate: string,
  lastOccurrenceDate: string
) => {
  const baseFromDate = vehicleManufacturedAt || firstOccurrenceDate;

  return {
    dateFrom: format(addWeeks(baseFromDate, -2), API_DATE_FORMAT),
    dateTo: format(addWeeks(lastOccurrenceDate, 1), API_DATE_FORMAT),
  };
};

export const getDefaultIssueCreateData = (assignee: string): IssueFormData => ({
  ...DEFAULT_ISSUE_DATA,
  assignee,
});

export const getOccursInnerFilter = (filter?: FilterGroupState) => {
  if (!filter || filter.children.length === 0) {
    return;
  }

  for (const f of filter.children) {
    if (f.type === "group") {
      continue;
    }

    if (
      f.type === "row" &&
      f.operator === "occurs" &&
      f.values &&
      f.values[0]
    ) {
      const inner = decodeOccursFilterAndOptions(f.values[0]);
      if (inner.filters) {
        return inner.filters;
      }

      return;
    }
  }
};

export const getFilterEqualityValuesForField = (
  filter: FilterGroupState,
  fieldName: string,
  values: string[] = []
) => {
  if (!filter || filter.children.length === 0) {
    return values;
  }

  for (const f of filter.children) {
    if (f.type === "group") {
      values = [...values, ...getFilterEqualityValuesForField(f, fieldName)];
      continue;
    }

    if (
      f.type === "row" &&
      f.attribute === fieldName &&
      f.operator &&
      [FilterOperator.EQUALS, FilterOperator.IN].includes(f.operator) &&
      f.values
    ) {
      values = [...values, ...f.values];
    }
  }

  return values;
};
