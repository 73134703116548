import qs from "qs";

import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import { getFiltersKey } from "features/ui/Filters/utils";

import { routes } from "services/routes";

import { SearchResource } from "./searchConfig";

export const replaceUrlParams = (urlTemplate: string, key: string) =>
  urlTemplate.replace(/:([a-zA-Z]+)/, key);

// markMatchingText takes a string and a search term and returns the string with the search term bolded.
export const markMatchingText = (t: string, searchTerm: string): string => {
  if (searchTerm.length === 0 || !t) {
    return t;
  }

  const escapedSearchTerm = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  const reg = new RegExp(escapedSearchTerm, "gi"); // Case-insensitive search

  const matches = t.matchAll(reg);

  if (Array.from(matches).length > 0) {
    return t.replace(
      reg,
      (matched) => `<span style="font-weight:bold">${matched}</span>`
    ); // Preserve original case
  }

  const newTerm = searchTerm.slice(0, -1);

  return markMatchingText(t, newTerm);
};

export const createLinkForResultItem = (
  config: SearchResource,
  itemId: string
) => {
  if (config.isRouteList && config.routeFilterKey && config.listingFieldId) {
    const key = getFiltersKey(config.routeFilterKey);
    const queryParams = {
      [key]: getFiltersQuery(
        filterStateToFilterGroupState({
          [config.listingFieldId]: {
            values: [itemId],
            operator: FilterOperator.IN,
          },
        })
      ),
    };

    // Using qs.stringify with arrayFormat: "indices" to match the app's URL format
    return {
      pathname: routes[config.routeKey],
      search: `?${qs.stringify(queryParams, { arrayFormat: "indices" })}`,
    };
  }

  return {
    pathname: replaceUrlParams(routes[config.routeKey], itemId),
  };
};
