import { useState } from "react";
import {
  ClickAwayListener,
  Tooltip as MUITooltip,
  TooltipProps,
} from "@mui/material";

interface Props extends TooltipProps {
  testId?: string;
  trigger?: string;
}

const Tooltip = ({
  children,
  title,
  testId,
  trigger,
  ...otherProps
}: Props) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return trigger !== "click" ? (
    <MUITooltip
      title={title}
      arrow
      data-testid={testId}
      {...otherProps}
      slotProps={{
        tooltip: {
          className: "max-w-full",
          "data-testid": testId,
        } as any,
        popper: {
          className: "max-w-full",
        },
      }}
    >
      <div data-testid={`${testId}-children`} className="max-w-full">
        {children}
      </div>
    </MUITooltip>
  ) : (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <MUITooltip
        onClose={handleTooltipClose}
        data-testid={testId}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        title={title}
        slotProps={{
          popper: {
            disablePortal: true,
          },
          tooltip: {
            "data-testid": testId,
          } as any,
        }}
      >
        <div onClick={handleTooltipOpen} data-testid={`${testId}-children`}>
          {children}
        </div>
      </MUITooltip>
    </ClickAwayListener>
  );
};

export default Tooltip;
