import classNames from "classnames";

import { getAxisKeyLabelFromActions } from "pages/Issues/utils";

import {
  ChartAction,
  ChartActionOption,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import { getSelectedChartActionOptionName } from "features/ui/charts/utils";
import Checkbox from "features/ui/Checkbox";
import DropdownSelect from "features/ui/DropdownSelect";
import Label from "features/ui/Label";

interface Props {
  actions: ChartAction[];
  selectedOptions: SelectedChartOptions[];
  onOptionChange: (selectedOptions: SelectedChartOptions[]) => void;
}
const DISABLED_COLOR = "text-gray-400";

const IssueMetricsAction = ({
  actions,
  selectedOptions,
  onOptionChange,
}: Props) => {
  const measureAction = actions.find(
    ({ id }) => id === "measure"
  ) as ChartAction;

  const lookbackWindowAction = actions.find(
    ({ id }) => id === "lookbackWindow"
  );
  const timeGroupingAction = actions.find(
    ({ id }) => id === "timeGroupingField"
  );

  const valueTypeAction = actions.find(({ id }) => id === "valueType");

  const selectedMeasureName = getSelectedChartActionOptionName(
    selectedOptions,
    measureAction
  );

  const selectedLookbackName = lookbackWindowAction
    ? getSelectedChartActionOptionName(selectedOptions, lookbackWindowAction)
    : "";

  const selectedTimeGroupingName = timeGroupingAction
    ? getSelectedChartActionOptionName(selectedOptions, timeGroupingAction)
    : "";

  const selectedValueType = valueTypeAction
    ? getSelectedChartActionOptionName(selectedOptions, valueTypeAction)
    : "";

  const { axisKey: measure } = getAxisKeyLabelFromActions(
    selectedOptions,
    actions,
    "measure"
  );

  const { axisKey: lookbackWindow } = getAxisKeyLabelFromActions(
    selectedOptions,
    actions,
    "lookbackWindow"
  );

  const { axisKey: valueType } = getAxisKeyLabelFromActions(
    selectedOptions,
    actions,
    "valueType"
  );

  const valueTypeCheckboxDisabled = lookbackWindow === "0";
  const valueTypeChecked = valueType !== "";

  const resetValueType = (selectedOptions: SelectedChartOptions[]) =>
    selectedOptions.map((obj) =>
      obj.id === "valueType"
        ? {
            id: "valueType",
            optionId: "",
          }
        : obj
    );

  const resetLookbackWindow = (selectedOptions: SelectedChartOptions[]) =>
    selectedOptions.map((obj) =>
      obj.id === "lookbackWindow"
        ? {
            id: "lookbackWindow",
            optionId: lookbackWindowAction?.options?.[0].id as string,
          }
        : obj
    );

  const updateSelectedMeasure = (selectedOption: ChartActionOption) => {
    const selectedMeasureId = selectedOption.id as string;
    let newSelectedActions = selectedOptions.map((obj) =>
      obj.id === "measure"
        ? {
            id: "measure",
            optionId: selectedMeasureId,
          }
        : obj
    );
    newSelectedActions = resetLookbackWindow(newSelectedActions);
    newSelectedActions = resetValueType(newSelectedActions);

    onOptionChange(newSelectedActions);
  };

  const updateSelectedLookbackWindow = (
    id: string,
    selectedOptionId?: string
  ) => {
    let newSelectedActions = selectedOptions.map((obj) =>
      obj.id === id
        ? {
            id,
            optionId: selectedOptionId as string,
          }
        : obj
    );
    newSelectedActions = resetValueType(newSelectedActions);

    onOptionChange(newSelectedActions);
  };

  const updateSelectedTimeGrouping = (
    id: string,
    selectedOptionId?: string
  ) => {
    let newSelectedActions = selectedOptions.map((obj) =>
      obj.id === id
        ? {
            id,
            optionId: selectedOptionId as string,
          }
        : obj
    );

    onOptionChange(newSelectedActions);
  };

  const updateSelectedValueType = (id: string, selectedOptionId?: string) => {
    const newSelectedActions = selectedOptions.map((obj) =>
      obj.id === id
        ? {
            id,
            optionId: selectedOptionId as string,
          }
        : obj
    );
    onOptionChange(newSelectedActions);
  };

  const onValueTypeCheckboxChange = (checked: boolean) => {
    const newSelectedActions = selectedOptions.map((obj) =>
      obj.id === "valueType"
        ? {
            id: "valueType",
            optionId: checked
              ? (valueTypeAction?.options?.[0].id as string)
              : "",
          }
        : obj
    );
    onOptionChange(newSelectedActions);
  };

  return (
    <>
      <div>
        <div className="flex space-x-3 items-center">
          <div className="w-20 text-right">
            <Label text={measureAction?.title} />
          </div>
          <DropdownSelect
            label={
              selectedMeasureName ? selectedMeasureName.toString() : undefined
            }
            options={measureAction.options}
            onSelect={updateSelectedMeasure}
            testId="chart-action-dropdown-measure"
          />
        </div>
      </div>
      {measure !== "count" && (
        <>
          <div>
            <div className="flex space-x-3 items-center">
              <div className="w-20 text-right"></div>
              <Label text={lookbackWindowAction?.title} />
              <DropdownSelect
                label={
                  selectedLookbackName
                    ? selectedLookbackName.toString()
                    : undefined
                }
                options={lookbackWindowAction?.options}
                onSelect={(selectedOption) =>
                  updateSelectedLookbackWindow(
                    "lookbackWindow",
                    selectedOption.id as string
                  )
                }
                testId="chart-action-dropdown-lookbackWindow"
              />
            </div>
          </div>
          <div>
            <div className="flex space-x-3 items-center">
              <div className="w-20 text-right"></div>
              <Label text={timeGroupingAction?.title} />
              <DropdownSelect
                label={
                  selectedTimeGroupingName
                    ? selectedTimeGroupingName.toString()
                    : undefined
                }
                options={timeGroupingAction?.options}
                onSelect={(selectedOption) =>
                  updateSelectedTimeGrouping(
                    "timeGroupingField",
                    selectedOption.id as string
                  )
                }
                testId="chart-action-dropdown-timeGroupingField"
              />
            </div>
          </div>
          <div>
            <div className="flex space-x-3 items-center">
              <div className="w-20 text-right"></div>
              <div
                key="valueType"
                className="inline-flex items-center space-x-2 text-nowrap"
              >
                <Checkbox
                  checked={valueTypeChecked}
                  dense={true}
                  onChange={onValueTypeCheckboxChange}
                  disabled={valueTypeCheckboxDisabled}
                />
                <span
                  className={classNames("", {
                    [DISABLED_COLOR]: !valueTypeChecked,
                  })}
                >
                  Show as
                </span>
                <DropdownSelect
                  label={
                    selectedValueType ? selectedValueType.toString() : undefined
                  }
                  options={valueTypeAction?.options}
                  onSelect={(selectedOption) =>
                    updateSelectedValueType(
                      "valueType",
                      selectedOption.id as string
                    )
                  }
                  testId="chart-action-dropdown-valueType"
                  disabled={!valueTypeChecked}
                />
                <span
                  className={classNames("", {
                    [DISABLED_COLOR]: !valueTypeChecked,
                  })}
                >
                  change from previous period
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default IssueMetricsAction;
