import { useEffect, useState } from "react";
import classNames from "classnames";
import { MdClose as CloseIcon } from "react-icons/md";
import { Dialog, DialogContent, DialogProps, IconButton } from "@mui/material";

interface ModalProps {
  isOpened?: boolean;
  triggerElement: JSX.Element | string;
  triggerElementClassNames?: string;
  children: JSX.Element[];
  showCloseIcon?: boolean;
  modalClassNames?: string;
  contentClassNames?: string;
  onOpen?: () => void;
  maxWidth?: DialogProps["maxWidth"];
}

const DEFAULT_MAX_WIDTH: DialogProps["maxWidth"] = "md";

const Modal = ({
  isOpened = false,
  triggerElement,
  triggerElementClassNames,
  children,
  showCloseIcon = false,
  modalClassNames,
  contentClassNames,
  onOpen,
  maxWidth = DEFAULT_MAX_WIDTH,
}: ModalProps) => {
  const [modalOpen, setModalOpen] = useState(isOpened);

  useEffect(() => {
    setModalOpen(isOpened);
  }, [isOpened]);

  const onModalOpen = () => {
    setModalOpen(true);
    onOpen && onOpen();
  };

  return (
    <>
      <span
        className={classNames(
          "relative cursor-pointer",
          triggerElementClassNames
        )}
        onClick={onModalOpen}
      >
        {triggerElement}
      </span>
      <Dialog
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        fullWidth
        maxWidth={maxWidth}
        className={modalClassNames}
      >
        {showCloseIcon && (
          <IconButton
            onClick={() => setModalOpen(false)}
            data-testid="modal-close-button"
            style={{ position: "absolute", top: "5px", right: "5px" }}
          >
            <CloseIcon />
          </IconButton>
        )}
        <DialogContent className={contentClassNames}>{children}</DialogContent>
      </Dialog>
    </>
  );
};

export default Modal;
