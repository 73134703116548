import {
  OccursFilterState,
  OccursFilterWindowDirection,
} from "features/ui/Filters/types";

export const SIGNAL_EVENT_ID_FIELD_NAME = "signalEventID";

export const DEFAULT_BASE_ENTITY_TEXT = "Base Object";

export const MIN_WINDOW_SIZE = 1;
export const MAX_WINDOW_SIZE = 365;
export const DEFAULT_WINDOW_SIZE = 30;
export const DEFAULT_WINDOW_TYPE = "days";

export const DEFAULT_EMPTY_OCCURS_FILTER: OccursFilterState = {
  windowSize: DEFAULT_WINDOW_SIZE,
  windowDirection: OccursFilterWindowDirection.BEFORE,
  windowType: DEFAULT_WINDOW_TYPE,
  filters: "",
};

export const DEFAULT_EMPTY_OCCURS_FILTER_ENCODED: string = `{windowSize="${DEFAULT_EMPTY_OCCURS_FILTER.windowSize}",windowType="${DEFAULT_EMPTY_OCCURS_FILTER.windowType}",windowDirection="${DEFAULT_EMPTY_OCCURS_FILTER.windowDirection}"}[]`;
