import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";

import ConfigRow from "./OrderedValueRow";
import { OrderedValueState } from "./types";

interface ConfigContainerProps {
  configuration: OrderedValueState[];
  updateValueByID: (id: string, row: OrderedValueState) => void;
  onDelete: (id: string) => void;
  onSortEnd: (event: DragEndEvent) => void;
}

const OderedValuesConfigContainer = ({
  configuration,
  updateValueByID,
  onDelete,
  onSortEnd,
}: ConfigContainerProps) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onSortEnd}
    >
      <SortableContext
        items={configuration}
        strategy={verticalListSortingStrategy}
      >
        {configuration.map((row, sortIndex) => (
          <ConfigRow
            key={row.id}
            row={row}
            sortIndex={sortIndex}
            updateValueByID={updateValueByID}
            onDelete={onDelete}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};

export default OderedValuesConfigContainer;
