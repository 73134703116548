import { CLAIMS_GENERIC_FILTER } from "shared/filterDefinitions";

import {
  GROUP_BY_ATTRIBUTE_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "pages/constants";

import { PageChartSettingsState } from "features/ui/Filters/types";
import { Option, SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";

import { CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY } from "./constants";

const FIELD_NAMES_WITH_DESCRIPTIONS = [
  "laborCode",
  "failedPartNumber",
  "mentionedSignalEvents",
  "relatedSignalEvents",
  "signalEventID",
];

export const canShowDescription = (attributeName: string) =>
  FIELD_NAMES_WITH_DESCRIPTIONS.includes(attributeName);

export const getDefaultTableSchema = (showCosts: boolean): SchemaEntry[] =>
  [
    {
      label: "IPTV",
      accessor: "IPTV",
      dataType: DataType.NUMBER,
      sortable: true,
      // note that we only use CLAIMS_GENERIC_FILTER for all these because we dont really need /values
      // endpoint for this due to filterType & disableSelectFilters ..
      filter: CLAIMS_GENERIC_FILTER({
        fieldName: "IPTV",
        label: "IPTV",
        search: false,
        disableFiltering: true,
        disableSelectFilters: true,
        filterType: "number",
      }),
    },
    {
      label: "% IPTV change",
      accessor: "IPTV30DayChangeRatio",
      dataType: DataType.JSX,
      sortable: true,
      description:
        "Percentage change in IPTV in the last 30 days. Use a value from 0 to 1 when applying filters.",
      filter: CLAIMS_GENERIC_FILTER({
        fieldName: "IPTV30DayChangeRatio",
        label: "% IPTV change",
        search: false,
        disableFiltering: true,
        disableSelectFilters: true,
        filterType: "number",
      }),
    },
    showCosts && {
      label: "CPV",
      accessor: "CPV",
      dataType: DataType.NUMBER,
      sortable: true,
      filter: CLAIMS_GENERIC_FILTER({
        fieldName: "CPV",
        label: "CPV",
        search: false,
        disableSelectFilters: true,
        filterType: "number",
      }),
    },
    showCosts && {
      label: "% CPV change",
      accessor: "CPV30DayChangeRatio",
      dataType: DataType.JSX,
      sortable: true,
      description:
        "Percentage change in CPV in the last 30 days. Use a value from 0 to 1 when applying filters.",
      filter: CLAIMS_GENERIC_FILTER({
        fieldName: "CPV30DayChangeRatio",
        label: "% CPV change",
        search: false,
        disableFiltering: true,
        disableSelectFilters: true,
        filterType: "number",
      }),
    },
    showCosts && {
      label: "Total Cost",
      accessor: "totalCost",
      dataType: DataType.NUMBER,
      sortable: true,
      filter: CLAIMS_GENERIC_FILTER({
        fieldName: "totalCost",
        label: "Total Cost",
        search: false,
        disableFiltering: true,
        disableSelectFilters: true,
        filterType: "number",
        onlyAllowPositiveIntegers: true,
      }),
    },
    {
      label: "Num. Claims",
      accessor: "numEvents",
      dataType: DataType.NUMBER,
      sortable: true,
      filter: CLAIMS_GENERIC_FILTER({
        fieldName: "numEvents",
        label: "Num. Events",
        search: false,
        disableFiltering: true,
        disableSelectFilters: true,
        filterType: "number",
        onlyAllowPositiveIntegers: true,
      }),
    },
    {
      label: "Affected Vehicles",
      accessor: "numVehicles",
      dataType: DataType.NUMBER,
      sortable: true,
      filter: CLAIMS_GENERIC_FILTER({
        fieldName: "numVehicles",
        label: "Affected Vehicles",
        search: false,
        disableFiltering: true,
        disableSelectFilters: true,
        filterType: "number",
        onlyAllowPositiveIntegers: true,
      }),
    },
    {
      label: "Total Vehicles",
      accessor: "totalNumVehicles",
      dataType: DataType.NUMBER,
      sortable: true,
      filter: CLAIMS_GENERIC_FILTER({
        fieldName: "totalNumVehicles",
        label: "Total Vehicles",
        search: false,
        disableFiltering: true,
        disableSelectFilters: true,
        filterType: "number",
        onlyAllowPositiveIntegers: true,
      }),
    },
  ].filter(Boolean) as SchemaEntry[];

/**
 * Determine the initial group by attribute to use.
 * @param chartSettings The chart settings that come from the query string or local storage.
 * @param defaultGroupByAttribute The setting to use if the user has not previously selected one.
 * @param groupBySelectOptions The available options for the group by attribute.
 * @returns If the user has previously selected a group by attribute, that attribute.
 * Otherwise, the default.
 */
export const getInitialSelectedGroupByAttribute = (
  chartSettings: PageChartSettingsState | undefined,
  defaultGroupByAttribute: SelectOption<Option>,
  groupBySelectOptions: SelectOption<Option>[]
): SelectOption<Option> => {
  if (
    !chartSettings ||
    !chartSettings[TOP_CONTRIBUTORS_TAB_KEY] ||
    !chartSettings[TOP_CONTRIBUTORS_TAB_KEY][
      CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY
    ]
  ) {
    return defaultGroupByAttribute;
  }

  const foundSetting = chartSettings[TOP_CONTRIBUTORS_TAB_KEY][
    CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY
  ].find((option) => option.id === GROUP_BY_ATTRIBUTE_KEY);

  if (foundSetting) {
    const foundOption = groupBySelectOptions.find(
      (option) => option.id === foundSetting.optionId
    );

    if (foundOption) {
      return {
        id: foundSetting.optionId,
        value: foundOption.value,
      };
    }
  }

  return defaultGroupByAttribute;
};
