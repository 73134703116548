import { useEffect, useState } from "react";
import classNames from "classnames";

import FeedbackForm from "./FeedbackForm";
import FeedbackWidget from "./FeedbackWidget";
import questions from "./questions";

const COLLAPSE_DURATION = 200;
const DURATION_CLASS = `duration-${COLLAPSE_DURATION}`;
const WAIT_BETWEEN_TRANSITIONS = 200;
const STARTUP_DELAY = 400;

const Feedback = () => {
  const [showForm, setShowForm] = useState(false);

  const [showWidget, setShowWidget] = useState(false);

  const [pressedSubmit, setPressedSubmit] = useState(false);

  const [errorSubmit, setErrorSubmit] = useState(false);

  // bring feedback widget up after render
  useEffect(() => {
    setTimeout(() => setShowWidget(true), STARTUP_DELAY);
  }, []);

  const onWidgetClick = () => {
    setShowWidget(false);
    setPressedSubmit(false);
    setErrorSubmit(false);
    setTimeout(
      () => setShowForm(true),
      COLLAPSE_DURATION + WAIT_BETWEEN_TRANSITIONS
    );
  };

  const onCloseForm = () => {
    setShowForm(false);
    setErrorSubmit(false);
    setTimeout(
      () => setShowWidget(true),
      COLLAPSE_DURATION + WAIT_BETWEEN_TRANSITIONS
    );
  };

  const formClass = classNames(
    "fixed z-50 bottom-0 right-0 md:right-20 max-h-full rounded-t transition-all bg-viaduct-black",
    DURATION_CLASS,
    {
      "transform translate-y-full": !showForm,
    }
  );

  const widgetClass = classNames(
    "fixed z-50 bottom-0 right-20 max-h-full rounded-t transition-all bg-viaduct-black",
    DURATION_CLASS,
    {
      "transform translate-y-full": !showWidget,
    }
  );

  return (
    <>
      <div className={formClass}>
        <FeedbackForm
          questions={questions}
          onCloseForm={onCloseForm}
          pressedSubmit={pressedSubmit}
          setPressedSubmit={setPressedSubmit}
          errorSubmit={errorSubmit}
          setErrorSubmit={setErrorSubmit}
        />
      </div>
      <div className={widgetClass}>
        <FeedbackWidget onWidgetClick={onWidgetClick} />
      </div>
    </>
  );
};
export default Feedback;
