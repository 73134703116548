import { useState } from "react";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";

interface Props {
  vehicleFilters?: FilterGroupState;
  claimFilters?: FilterGroupState;
  signalEventFilters?: FilterGroupState;
}

export const useBackToEntityState = ({
  vehicleFilters,
  claimFilters,
  signalEventFilters,
}: Props) => {
  const [initialVehicleFilters, setInitialVehicleFilters] = useState<
    FilterGroupState | undefined
  >(vehicleFilters);
  const [initialClaimFilters, setInitialClaimFilters] = useState<
    FilterGroupState | undefined
  >(claimFilters);
  const [
    initialSignalEventOccurrencesFilter,
    setInitialSignalEventOccurrencesFilter,
  ] = useState<FilterGroupState | undefined>(signalEventFilters);

  return {
    initialVehicleFilters,
    setInitialVehicleFilters,
    initialClaimFilters,
    setInitialClaimFilters,
    initialSignalEventOccurrencesFilter,
    setInitialSignalEventOccurrencesFilter,
  };
};
