import { ReactNode } from "react";
import { CopilotKit } from "@copilotkit/react-core";
import { CopilotSidebar } from "@copilotkit/react-ui";

import "@copilotkit/react-ui/styles.css";
import "./duck-copilotkit.css";

import DuckLogo from "duck/ui/DuckLogo";
import { CopilotKitCSSProperties } from "@copilotkit/react-ui";

interface DuckRightContainerProps {
  children: ReactNode;
}

const DuckRightContainer = ({ children }: DuckRightContainerProps) => (
  <CopilotKit
    runtimeUrl="https://ai.local.cloud.viaduct.ai:3003/copilotkit-runtime"
    showDevConsole={false}
  >
    <div
      style={
        {
          "--copilot-kit-primary-color": "#fff",
          "--copilot-kit-contrast-color": "#5d9ae5",
          "--copilot-kit-background-color": "#fff",
          "--copilot-kit-secondary-color": "#7a7a7a",
          "--copilot-kit-secondary-contrast-color": "#6B7280",
          "--copilot-kit-separator-color": "#7a7a7a",
          "--copilot-kit-muted-color": "#7a7a7a",
        } as CopilotKitCSSProperties
      }
    >
      <CopilotSidebar
        defaultOpen={false}
        labels={{
          title: "ViaDuck Assistant",
          initial: "Hi, I'm ViaDuck. How can I help?",
        }}
        icons={{ openIcon: <DuckLogo fillColor="#fff" strokeColor="#fff" /> }}
      >
        {children}
      </CopilotSidebar>
    </div>
  </CopilotKit>
);

export default DuckRightContainer;
