import classNames from "classnames";

import { UNIT_OPTIONS } from "pages/CalculatedAttributes/constants";
import { CalculatedAttributeParameters } from "pages/CalculatedAttributes/types";

import DropdownSelect from "features/ui/DropdownSelect/DropdownSelect";

import EventFilters from "./EventFilters";
import EventTypeSelect from "./EventTypeSelect";
import { FunctionInputConfig, FunctionInputProps } from "./types";

const FunctionInput = ({
  config,
  attribute,
  onChange,
  disabled = false,
}: FunctionInputProps) => (
  <div className="flex space-x-2" key={config.parameter as string}>
    <div
      className={classNames("w-20 text-right shrink-0 leading-9", {
        "pt-3": config.type === "event_filter",
      })}
    >
      {config.labelBefore}
    </div>
    {getInputByType(config, attribute, onChange, disabled)}
    {config.labelAfter && <div className="leading-9">{config.labelAfter}</div>}
  </div>
);

const getInputByType = (
  config: FunctionInputConfig,
  attribute: CalculatedAttributeParameters,
  onChange: (attribute: CalculatedAttributeParameters) => void,
  disabled: boolean
): JSX.Element | null => {
  switch (config.type) {
    case "event_type":
      return (
        <EventTypeSelect
          attribute={attribute}
          onChange={onChange}
          config={config}
          disabled={disabled}
        />
      );
    case "event_filter":
      return (
        <EventFilters
          attribute={attribute}
          onChange={onChange}
          config={config}
          disabled={disabled}
        />
      );
    case "unit":
      return (
        <DropdownSelect
          disabled={disabled}
          label={(attribute[config.parameter] as string) || "Select unit"}
          options={UNIT_OPTIONS}
          onSelect={(selectedOption) =>
            onChange({
              ...attribute,
              [config.parameter]: selectedOption.id as string,
            })
          }
        />
      );
    default:
      return null;
  }
};

export default FunctionInput;
