import React, { useContext } from "react";

import { useConfigContext } from "shared/contexts/ConfigContext";

import { LANDING_PAGE_PAGE_CONFIG_KEY } from "pages/SuperAdmin/PagesConfig/types";

import { RouteContext } from "services/routes/RouteMiddleware";

import Title from "./Title";

interface Props {
  title: string;
  children?: React.ReactNode;
  titleOnly?: boolean;
  subtitle?: string;
}

const PageHeaderWrapper = ({ title, children, titleOnly, subtitle }: Props) => {
  const { pages } = useConfigContext();
  const routeContext = useContext(RouteContext);

  if (titleOnly) {
    return <Title text={title} />;
  }

  return (
    <div className="flex justify-between items-center mb-5">
      <div>
        <Title text={title} />
        {subtitle && (
          <div className="text-xs text-gray-400 max-w-[700px]">{subtitle}</div>
        )}
      </div>
      {children}
      <div className="flex justify-between items-center ml-1">
        {pages[LANDING_PAGE_PAGE_CONFIG_KEY] &&
          routeContext &&
          routeContext.routeActions &&
          routeContext.routeActions.map((action, i) => (
            <div key={i}>{action}</div>
          ))}
      </div>
    </div>
  );
};

export default PageHeaderWrapper;
