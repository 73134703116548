import React from "react";
import { HiOutlineX } from "react-icons/hi";

interface Props {
  onExit: () => void;
}

const ExitButton = ({ onExit }: Props) => (
  <button
    className="rounded-md focus:outline-hidden text-gray-300 hover:text-white "
    onClick={() => onExit()}
  >
    <HiOutlineX size="1.25rem" />
  </button>
);

export default ExitButton;
