import { useMemo, useState } from "react";

import { DescriptionObject } from "shared/api/api";
import {
  useListLaborCodes,
  useListParts,
  UsePaginatedAPIState,
} from "shared/api/hooks";
import { useListSignalEvents } from "shared/api/signalEvents/hooks";
import { SortBy } from "shared/types";

import ClaimAnalyticsLink from "pages/shared/analytics/ClaimAnalyticsLink";

import APIError from "features/ui/APIError";
import {
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { FilterOperator } from "features/ui/Filters/types";
import Label from "features/ui/Label";
import Table, { OnSortParams, RowData, SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";

interface Props {
  title?: string | JSX.Element;
  fieldName: string;
  values: string[];
  searchString: string;
  linkToClaimAnalytics?: boolean;
}

const dataFetchHookMap: Record<
  string,
  (args: any) => UsePaginatedAPIState<any[]>
> = {
  laborCode: useListLaborCodes,
  failedPartNumber: useListParts,
  mentionedSignalEvents: useListSignalEvents,
  relatedSignalEvents: useListSignalEvents,
  signalEvents: useListSignalEvents,
  signalEventID: useListSignalEvents,
};

export const getDescriptionForID = (
  value: string,
  descriptionsData?: DescriptionObject[]
): string => {
  if (!descriptionsData) {
    return "";
  }

  const foundDescriptionObject = descriptionsData.find((d) => d.ID === value);
  if (foundDescriptionObject && foundDescriptionObject.description) {
    return foundDescriptionObject.description;
  }

  return "";
};

// Create a constant array with keys
export const SUPPORTED_DESCRIPTION_TABLE_FIELD_NAMES: (keyof typeof dataFetchHookMap)[] =
  Object.keys(dataFetchHookMap);

const FilterDescriptionTable = ({
  title,
  fieldName,
  values,
  searchString,
  linkToClaimAnalytics,
}: Props) => {
  const dataFetchHook: (args: any) => UsePaginatedAPIState<any[]> =
    dataFetchHookMap[fieldName];

  const { data, isLoading, error } = dataFetchHook({
    filter: getFiltersQuery(
      filterStateToFilterGroupState({
        ID: { operator: FilterOperator.IN, values },
      })
    ),
  });

  const [sortBy, setSortBy] = useState<SortBy>({ ID: "desc" });

  const schemaItems: (SchemaEntry | boolean | undefined)[] = [
    {
      label: "ID",
      accessor: "ID",
      dataType: DataType.STRING,
      sortable: true,
    },
    {
      limitedWidthClass: "w-80",
      label: "Description",
      accessor: "description",
      dataType: DataType.STRING,
      sortable: true,
    },
  ];

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    setSortBy({ [accessor]: sort });
  };

  const lowerCasedSearchString = searchString.toLowerCase();
  const formattedData: RowData[] = values
    .map((v) => ({
      ID: v,
      description: getDescriptionForID(v, data),
    }))
    .sort((a, b) => {
      // sort by ID or description based on the current sortBy state
      const sortKey = Object.keys(sortBy)[0] as keyof SortBy;
      const sortDirection = sortBy[sortKey];
      const aValue = (a as SortBy)[sortKey];
      const bValue = (b as SortBy)[sortKey];
      if (sortDirection === "asc") {
        return aValue.localeCompare(bValue);
      }

      return bValue.localeCompare(aValue);
    })
    .filter(
      ({ ID, description }) =>
        ID.toLowerCase().includes(lowerCasedSearchString) ||
        description?.toLowerCase().includes(lowerCasedSearchString)
    );
  const schema = schemaItems.filter(Boolean) as SchemaEntry[];

  const formatRow = (data: RowData) => {
    const { ID } = data;

    const formattedID = linkToClaimAnalytics ? (
      <ClaimAnalyticsLink attribute={fieldName} value={ID} />
    ) : (
      ID
    );

    return {
      ...data,
      ID: formattedID,
    };
  };

  const formattedRowData = useMemo(
    () => formattedData?.map((row) => formatRow(row)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formattedData]
  );

  return (
    <div>
      {(typeof title === "string" && (
        <Label text={title} className="text-gray-500" />
      )) ||
        title}
      {!error && (
        <Table
          data={formattedRowData}
          schema={schema}
          isLoading={isLoading}
          scrollHeight={200}
          dense
          sortBy={sortBy}
          onSort={handleSorting}
        />
      )}
      {error && <APIError error={error} />}
    </div>
  );
};

export default FilterDescriptionTable;
