import { useRef } from "react";

import { FilterRowState } from "features/ui/Filters/FilterBuilder/types";
import { UseFilterSortState } from "features/ui/Filters/types";
import { SchemaEntry } from "features/ui/Table";

import CreateQuickFilterAction from "./CreateQuickFilterAction";
import QuickFilter from "./QuickFilter";

interface Props {
  filterSchema: SchemaEntry[];
  filterSortState: UseFilterSortState;
  filterTitle: string;
}

const QuickFilters = ({
  filterSchema,
  filterSortState,
  filterTitle,
}: Props) => {
  const quickFilters = filterSortState.quickFilters;
  const usedAccessors = quickFilters.map((filter) => filter.attribute);

  // Create refs array to store references to child functions
  const filterRefs = useRef<Array<{ open: () => void } | null>>([]);

  const onAddQuickFilter = (filter: FilterRowState) => {
    filterSortState.updateQuickFilters([...quickFilters, filter]);

    const lastIndex = quickFilters.length;
    setTimeout(() => {
      // Add null check
      if (filterRefs.current[lastIndex]) {
        filterRefs.current[lastIndex]?.open();
      }
    }, 0);
  };

  const onUpdateQuickFilter = (index: number, filter: FilterRowState) => {
    quickFilters[index] = filter;
    filterSortState.updateQuickFilters([...quickFilters]);
  };

  const onDeleteQuickFilter = (id: string) => {
    const updatedFilters = [...filterSortState.quickFilters].filter(
      (f) => f.id !== id
    );
    filterSortState.updateQuickFilters(updatedFilters);
  };

  const availableSchema = filterSchema.filter(
    (schemaEntry) =>
      !usedAccessors.includes(schemaEntry.accessor) &&
      schemaEntry.filter?.filterType !== "exists" &&
      schemaEntry.filter?.filterType !== "occurs"
  );

  return (
    <div className="flex flex-row items-center gap-2 w-full">
      <div className="font-bold max-w-[33%] break-words h-8 flex items-center">
        {filterTitle}
      </div>
      <div className="flex flex-row flex-wrap items-center gap-2">
        {quickFilters.map((filter, index) => (
          <div key={index}>
            <QuickFilter
              ref={(el) => {
                filterRefs.current[index] = el;
              }}
              filterSchema={filterSchema}
              filter={filter}
              onUpdate={onUpdateQuickFilter}
              onDelete={() => onDeleteQuickFilter(filter.id)}
              index={index}
            ></QuickFilter>
          </div>
        ))}
        <CreateQuickFilterAction
          filterSchema={availableSchema}
          onSubmit={onAddQuickFilter}
        ></CreateQuickFilterAction>
      </div>
    </div>
  );
};

export default QuickFilters;
