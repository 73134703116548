import { useIssuesFiltersSchema } from "shared/hooks";

import PageLevelFilters from "pages/shared/PageLevelFilters/PageLevelFilters";

import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import { ISSUES_PENDING_FILTERS_LS_KEY } from "./constants";
import { useDefaultIssueFilter } from "./utils";

const LABEL = "Issue Filters";

interface Props {
  filterSortState: UseFilterSortState;
}

const IssuesFilters = ({ filterSortState }: Props) => {
  const { filters } = filterSortState;
  const issueFiltersSchema = useIssuesFiltersSchema();

  const label = getFilterLabel(LABEL, filters);

  return (
    <div className="mb-3">
      <PageLevelFilters
        filtersProps={[
          {
            filterSortState,
            defaultFilters: useDefaultIssueFilter(),
            filterSchema: issueFiltersSchema,
            filterTitle: LABEL,
            filterLabel: label,
            pendingFilterKey: ISSUES_PENDING_FILTERS_LS_KEY,
            entity: "Issue",
            testId: "issues",
            quickFilterLabel: "Issue Quick Filters",
          },
        ]}
      />
    </div>
  );
};

export default IssuesFilters;
