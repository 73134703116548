import {
  ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_KEY,
  BY_VEHICLES_AGE_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "pages/constants";
import { FieldDateRange } from "pages/shared/analytics/types";
import {
  addDateRangeToFilterState,
  addDateRangeToQuickFilters,
} from "pages/shared/analytics/utils";

import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { UseFilterSortState } from "features/ui/Filters/types";
import { clearPendingFiltersForKey } from "features/ui/Filters/utils";
import StatefulTabs, { Tab } from "features/ui/StatefulTabs";

import {
  REPAIRS_ANALYTICS_TABS,
  REPAIRS_TAB_KEY,
  REPAIRS_VEHICLES_PAGE_KEY,
} from "./constants";
import AssociatedSignalEvents from "./tabPages/AssociatedSignalEvents";
import AssociatedVehicles from "./tabPages/AssociatedVehicles";
import ByVehicleAge from "./tabPages/ByVehicleAge";
import Repairs from "./tabPages/Repairs";
import TopContributors from "./tabPages/TopContributors";

export interface RepairAnalyticsProps {
  repairsFiltersFilterSortState: UseFilterSortState;
  vehiclesFiltersFilterSortState?: UseFilterSortState;
}

export interface RepairsFiltersProps {
  repairsFilters?: FilterGroupState;
  vehiclesFilters?: FilterGroupState;
  onBadRequest: () => void;
  onAddDateRangeToVehicleFilters?: (fieldDateRange: FieldDateRange) => void;
}

export interface RepairAnalyticsTabsProps
  extends RepairsFiltersProps,
    RepairAnalyticsProps {
  queryParamsToPersist?: string[];
}

const RepairAnalyticsTabs = ({
  repairsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
  queryParamsToPersist,
  onBadRequest,
}: RepairAnalyticsTabsProps) => {
  const repairsFilters = repairsFiltersFilterSortState?.filtersWithQuickFilters;
  const vehiclesFilters =
    vehiclesFiltersFilterSortState?.filtersWithQuickFilters;

  const onAddDateRangeToVehicleFilters = (fieldDateRange: FieldDateRange) => {
    const quickFilter = vehiclesFiltersFilterSortState?.quickFilters.find(
      (filter) => filter.attribute === fieldDateRange.fieldName
    );
    if (quickFilter && vehiclesFiltersFilterSortState) {
      vehiclesFiltersFilterSortState.updateQuickFilters(
        addDateRangeToQuickFilters(
          vehiclesFilters ?? DEFAULT_FILTER_BUILDER_STATE,
          vehiclesFiltersFilterSortState.quickFilters,
          fieldDateRange
        )
      );

      return;
    }

    if (!vehiclesFilters) return;

    vehiclesFiltersFilterSortState.updateFilters(
      addDateRangeToFilterState(vehiclesFilters, fieldDateRange)
    );
    clearPendingFiltersForKey(REPAIRS_VEHICLES_PAGE_KEY);
  };

  const tabsItems: (Tab | boolean | undefined)[] = [
    {
      key: REPAIRS_TAB_KEY,
      title: REPAIRS_ANALYTICS_TABS[REPAIRS_TAB_KEY],
      content: (
        <Repairs
          repairsFilters={repairsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: BY_VEHICLES_AGE_TAB_KEY,
      title: REPAIRS_ANALYTICS_TABS[BY_VEHICLES_AGE_TAB_KEY],
      content: (
        <ByVehicleAge
          repairsFilters={repairsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
          onAddDateRangeToVehicleFilters={onAddDateRangeToVehicleFilters}
        />
      ),
    },
    {
      key: TOP_CONTRIBUTORS_TAB_KEY,
      title: REPAIRS_ANALYTICS_TABS[TOP_CONTRIBUTORS_TAB_KEY],
      content: (
        <TopContributors
          repairsFiltersFilterSortState={repairsFiltersFilterSortState}
          vehiclesFiltersFilterSortState={vehiclesFiltersFilterSortState}
        />
      ),
    },
    {
      key: ASSOCIATED_VEHICLES_TAB_KEY,
      title: REPAIRS_ANALYTICS_TABS[ASSOCIATED_VEHICLES_TAB_KEY],
      content: (
        <AssociatedVehicles
          repairsFilters={repairsFilters}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
    {
      key: ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
      title: REPAIRS_ANALYTICS_TABS[ASSOCIATED_SIGNAL_EVENTS_TAB_KEY],
      content: (
        <AssociatedSignalEvents
          repairsFiltersFilterSortState={repairsFiltersFilterSortState}
          vehiclesFilters={vehiclesFilters}
          onBadRequest={onBadRequest}
        />
      ),
    },
  ];

  const tabs = tabsItems.filter(Boolean) as Tab[];

  return (
    <StatefulTabs tabs={tabs} queryParamsToPersist={queryParamsToPersist} />
  );
};

export default RepairAnalyticsTabs;
