import { useEffect } from "react";
import classNames from "classnames";
import Link from "@tiptap/extension-link";
import { Underline } from "@tiptap/extension-underline";
import { BubbleMenu, EditorContent, useEditor } from "@tiptap/react";
import { StarterKit } from "@tiptap/starter-kit";

import { isMac } from "shared/utils";

import { OptionalFeatures } from "./constants";
import styles from "./RichTextEditor.module.css";
import RichTextEditorMenu from "./RichTextEditorMenu";

interface RichTextEditorProps {
  content: string;
  onChange?: (content: string) => void;
  editing?: boolean;
  bubbleMenu?: boolean;
  features?: OptionalFeatures[];
  testId?: string;
  contentClass?: string;
  containerClass?: string;
  noPadding?: boolean;
  hideScrollbar?: boolean;
}

const RichTextEditor = ({
  content,
  onChange = () => {},
  editing = false,
  bubbleMenu = false,
  testId = "rich-text-editor",
  contentClass = "",
  containerClass = "",
  noPadding = false,
  hideScrollbar = false,
  features = [
    OptionalFeatures.heading,
    OptionalFeatures.textDecoration,
    OptionalFeatures.link,
    OptionalFeatures.lists,
    OptionalFeatures.undoRedo,
  ],
}: RichTextEditorProps) => {
  const extensions = [
    StarterKit.configure({
      code: false,
      codeBlock: false,
    }),
    Link.configure({
      autolink: true,
      linkOnPaste: true,
      openOnClick: false,
      HTMLAttributes: {
        target: "_blank",
        title: isMac
          ? "Open in new tab while editing (⌘ + click)"
          : "Open in new tab while editing (CTRL + click)",
        // This is a bit hacky, to inject our logic on the HTML element
        // without writing own prose mirror extension
        onclick: `
          if (event.metaKey || event.ctrlKey) {
            event.preventDefault();
            window.open(event.target.href, '_blank');
          }
      `,
      },
    }).extend({
      inclusive: false,
    }),
    Underline,
  ];

  const editor = useEditor({
    extensions,
    content,
    editable: editing,
    onUpdate: ({ editor }) => {
      onChange(editor.getHTML());
    },
    editorProps: {
      attributes: {
        class: styles.editor,
      },
    },
  });

  // Update editable state when editing prop changes
  useEffect(() => {
    if (editor) {
      editor.setEditable(editing);
      if (!editing) {
        editor.commands.setContent(content);
      }
    }
  }, [editor, editing, content]);

  if (!editor) {
    return null;
  }

  const menu = (bubble: boolean) => (
    <RichTextEditorMenu editor={editor} features={features} isBubble={bubble} />
  );

  return (
    <div
      className={classNames(styles.editor, containerClass, "w-full", {
        "border border-gray-200 rounded-md": editing,
        [styles.padding]: !noPadding,
      })}
      data-testid={testId}
    >
      {!bubbleMenu && editing && menu(false)}
      <EditorContent
        editor={editor}
        className={`${contentClass} overflow-auto w-full max-w-full break-words`}
        style={{ scrollbarWidth: hideScrollbar ? "none" : "auto" }}
      />
      {bubbleMenu && (
        <BubbleMenu editor={editor} tippyOptions={{ maxWidth: "none" }}>
          {menu(true)}
        </BubbleMenu>
      )}
    </div>
  );
};

export default RichTextEditor;
