import { API_MAX_LIMIT } from "duck/graph/constants";
import { NonEmptyStringArray } from "duck/graph/types";

import * as api from "shared/api/api";
import { APISuccessResponse, listLaborCodes, listParts } from "shared/api/api";
import * as claimsApi from "shared/api/claims/api";
import { listSignalEvents } from "shared/api/signalEvents/api";
import * as SEApi from "shared/api/signalEvents/api";

import {
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY as CLAIMS_VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import { CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY } from "pages/ClaimAnalytics/tabPages/TopContributors/constants";
import {
  GROUP_BY_ATTRIBUTE_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "pages/constants";
import {
  VEHICLES_PAGE_KEY as SE_VEHICLES_PAGE_KEY,
  SIGNAL_EVENTS_PAGE_KEY,
} from "pages/SignalEventsAnalytics/constants";
import { SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY } from "pages/SignalEventsAnalytics/tabPages/TopContributors/constants";

import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import {
  filterBuilderQueryToFilterBuilderState,
  filterStateToFilterGroupState,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import {
  getDescriptionForID,
  SUPPORTED_DESCRIPTION_TABLE_FIELD_NAMES,
} from "features/ui/Filters/other/FilterDescriptionTable";
import { FilterOperator } from "features/ui/Filters/types";
import {
  getFiltersKey,
  getPageKeyWithVersion,
  getQueryKeys,
} from "features/ui/Filters/utils";

import { routes } from "services/routes";

import { MAX_NUM_ATTRIBUTES } from "./constants";
import {
  AttributeEntropy,
  AttributeGroupMetric,
  BaseTopContributorResponse,
  BaseTopContributorsRequestArgs,
  EntropyMetrics,
} from "./types";

/**
 * Fetches claims top contributors data from the API.
 *
 * @param args - The request parameters for claims top contributors
 * @returns A Promise that resolves to an array of ClaimsTopContributor
 */
const getClaimsTopContributors = (
  args: claimsApi.ClaimsTopContributorsRequest
) =>
  api.getFetcher<claimsApi.ClaimsTopContributor[]>(
    claimsApi.getClaimsTopContributorsRequestURI(args)
  );

/**
 * Fetches claims top contributors data from the API.
 *
 * @param args - The request parameters for claims top contributors
 * @returns A Promise that resolves to an array of ClaimsTopContributor
 */
const getSETopContributors = (args: SEApi.SignalEventsTopContributorsRequest) =>
  api.getFetcher<SEApi.SignalEventsTopContributor[]>(
    SEApi.getSignalEventsTopContributorsRequestURI(args)
  );

/**
 * Generic helper function to run top contributors requests.
 *
 * @param groupByOptions - Array of grouping attributes.
 * @param filterArgs - Filter parameters (type is generic).
 * @param mapArgs - Function to create the request arguments from a groupBy option and filterArgs.
 * @param requestFn - Function to call for each request.
 * @returns A Promise that resolves to a Map of group by options to their contributor results.
 */
const runTopContributors = async <
  TRequestArgs extends BaseTopContributorsRequestArgs,
  TResponse extends BaseTopContributorResponse,
>(
  groupByOptions: NonEmptyStringArray,
  mapArgs: (groupBy: string) => TRequestArgs,
  requestFn: (
    args: TRequestArgs
  ) => Promise<api.APISuccessResponse<TResponse[]>>
): Promise<Map<string, TResponse[]>> => {
  if (groupByOptions.length > MAX_NUM_ATTRIBUTES) {
    throw new Error(
      `Too many group by options. Maximum is ${MAX_NUM_ATTRIBUTES}.`
    );
  }

  const promises = groupByOptions.map((groupBy) => requestFn(mapArgs(groupBy)));
  const responses = await Promise.allSettled(promises);

  const resultsMap = new Map<string, TResponse[]>();

  responses.forEach((result, index) => {
    if (result.status === "fulfilled") {
      resultsMap.set(
        groupByOptions[index],
        // Filter out null groupByAttributeValue
        result.value.data.filter(
          (contributor) => contributor.groupByAttributeValue !== null
        )
      );
    } else {
      console.error(
        `Failed to fetch top contributors for ${groupByOptions[index]}`,
        result.reason
      );
    }
  });

  return resultsMap;
};

/**
 * Computes the entropy for a single attribute's groups.
 *
 * @param attrGroupMetric - Array of attribute group metrics.
 * @returns An object with the normalized entropy, contrast and number of groups.
 */
const computeEntropyForAttribute = (
  attrGroupMetric: AttributeGroupMetric[]
): EntropyMetrics => {
  // Sum the metric values for all groups to get the total.
  const totalMetric = attrGroupMetric.reduce(
    (acc, curr) => acc + curr.metric.value,
    0
  );
  const numGroups = attrGroupMetric.length;

  // Exit early if totalMetric is 0 or if there's only one group.
  if (totalMetric === 0 || numGroups <= 1) {
    return {
      entropy: 0,
      contrast: 0,
      numGroups,
    };
  }

  let rawEntropy = 0;
  for (const group of attrGroupMetric) {
    const p = group.metric.value / totalMetric;
    if (p > 0) {
      rawEntropy -= p * Math.log2(p);
    }
  }

  const normalizedEntropy = rawEntropy / Math.log2(numGroups);
  const contrast = 1 - normalizedEntropy;

  return {
    entropy: parseFloat(normalizedEntropy.toFixed(2)),
    contrast: parseFloat(contrast.toFixed(2)),
    numGroups,
  };
};

/**
 * Computes entropy metrics for top contributors across multiple group-by attributes.
 *
 * @template TFilterArgs - Type extending BaseFiltersRequest for filtering parameters
 * @template TRequestArgs - Type extending BaseTopContributorsRequest for API request parameters
 * @template TResponse - Type extending BaseTopContributor for API response data
 *
 * @param groupByOptions - Array of group-by attribute names (must be non-empty)
 * @param filterArgs - Filter arguments to apply to the requests
 * @param mapArgs - Function to map filter arguments to request arguments for each group-by option
 * @param requestFn - Function to make API request for top contributors
 * @param mapTopContributorResponse - Function to transform API response into attribute group metrics
 * @param getTopContributorsUriFn - Function to generate URI for each group-by option
 *
 * @returns Promise resolving to a Map where:
 * - Key is the attribute name
 * - Value is AttributeEntropy containing:
 *   - attribute: The group-by attribute name
 *   - attributeUri: URI for the attribute's top contributors
 *   - attributeGroups: Metrics for each group within the attribute
 *   - metrics: Computed entropy metrics for the attribute
 *
 * @remarks
 * - Only includes results where number of groups > 1 and entropy > 0
 * - Processes data in multiple steps:
 *   1. Fetches top contributors for each group-by option
 *   2. Maps responses to attribute group metrics
 *   3. Computes entropy metrics for each attribute
 */
const computeEntropy = async <
  TRequestArgs extends BaseTopContributorsRequestArgs,
  TResponse extends BaseTopContributorResponse,
>(
  groupByOptions: NonEmptyStringArray,
  mapArgs: (groupBy: string) => TRequestArgs,
  requestFn: (
    args: TRequestArgs
  ) => Promise<api.APISuccessResponse<TResponse[]>>,
  mapTopContributorResponse: (contributor: TResponse) => AttributeGroupMetric,
  getTopContributorsUriFn: (groupByOption: string) => string,
  attributeLabelMapping?: Map<string, string>
): Promise<AttributeEntropy[]> => {
  const topContributors = await runTopContributors(
    groupByOptions,
    mapArgs,
    requestFn
  );

  console.debug("Top contributors data:", topContributors);

  // If there are no top contributors, return an empty array -- most likely API calls failed
  if (!topContributors.size) {
    return [];
  }

  const updatedTopContributorsMap = new Map<string, AttributeGroupMetric[]>();
  for (const [key, value] of topContributors) {
    updatedTopContributorsMap.set(key, value.map(mapTopContributorResponse));
  }

  const results: AttributeEntropy[] = [];

  // Iterate over each attribute's data.
  for (const [attribute, attrGroupMetriclist] of updatedTopContributorsMap) {
    const metrics = computeEntropyForAttribute(attrGroupMetriclist);
    const attributeUri = getTopContributorsUriFn(attribute);

    // Only include results with more than one group and non-zero entropy and non-zero contrast.
    if (metrics.numGroups > 1 && metrics.entropy > 0 && metrics.contrast > 0) {
      results.push({
        attribute: attributeLabelMapping?.get(attribute) ?? attribute,
        attributeUri,
        attributeGroups: attrGroupMetriclist,
        metrics,
      });
    }
  }

  // Sort results by entropy in ascending order
  results.sort((a, b) => a.metrics.entropy - b.metrics.entropy);

  return results;
};

/**
 * Generates a URI for the top contributors view with the specified filters and group by option.
 *
 * @param filterQueryStrings - Map of page keys to their filter query strings
 * @param groupByOption - The selected group by attribute
 * @returns A URI string for the top contributors view
 * @throws Error if groupByOption is not provided
 */
const getTopContributorsUri = (
  route: string,
  pageKey: string,
  groupByOptionsKey: string,
  filterQueryStrings: Map<string, string>,
  groupByOption: string
) => {
  if (!groupByOption) {
    throw new Error("groupByOption is required");
  }

  const pageKeyWithVersion = getPageKeyWithVersion(pageKey);
  const { chartSettingsKey } = getQueryKeys(pageKeyWithVersion);

  const chartSettingsValue = JSON.stringify({
    [TOP_CONTRIBUTORS_TAB_KEY]: {
      [groupByOptionsKey]: [
        { id: GROUP_BY_ATTRIBUTE_KEY, optionId: groupByOption },
      ],
    },
  });

  const filterParams = Array.from(filterQueryStrings)
    .map(
      ([pageKey, queryString]) =>
        `${encodeURIComponent(getFiltersKey(pageKey))}=${encodeURIComponent(queryString)}`
    )
    .join("&");

  return `${route}?${filterParams}&${chartSettingsKey}=${encodeURIComponent(chartSettingsValue)}&tab=${encodeURIComponent(TOP_CONTRIBUTORS_TAB_KEY)}`;
};

/**
 * Computes the entropy for claims top contributors.
 *
 * @param groupByOptions - Array of group-by attribute names (must be non-empty)
 * @param filterArgs - Filter arguments to apply to the requests
 * @returns Promise resolving to a Map where:
 * - Key is the attribute name
 * - Value is AttributeEntropy containing:
 *   - attribute: The group-by attribute name
 *   - attributeUri: URI for the attribute's top contributors
 *   - attributeGroups: Metrics for each group within the attribute
 *   - metrics: Computed entropy metrics for the attribute
 */
export const computeClaimsEntropy = async (
  groupByOptions: NonEmptyStringArray,
  filterArgs: claimsApi.ClaimFiltersRequest,
  attributeLabelMapping?: Map<string, string>
) => {
  const filterQueryStrings = new Map([
    [CLAIMS_PAGE_KEY, filterArgs.claimsFilter || ""],
    [CLAIMS_VEHICLES_PAGE_KEY, filterArgs.vehiclesFilter || ""],
  ]);

  return computeEntropy(
    groupByOptions,
    (groupByAttribute) => ({
      ...filterArgs,
      groupByAttribute,
      hideCosts: false,
      sort: "IPTV:desc",
      limit: API_MAX_LIMIT,
    }),
    getClaimsTopContributors,
    (contributor) => ({
      metric: {
        value: contributor.IPTV,
        label: "Incidents Per Thousand Vehicles (IPTV)",
      },
      groupByAttributeValue: contributor.groupByAttributeValue,
    }),
    (groupByOption) =>
      getTopContributorsUri(
        routes.claimAnalytics,
        CLAIMS_PAGE_KEY,
        CLAIM_ANALYTICS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
        filterQueryStrings,
        groupByOption
      ),
    attributeLabelMapping
  );
};

/**
 * Computes the entropy for signal events top contributors.
 *
 * @param groupByOptions - Array of group-by attribute names (must be non-empty)
 * @param filterArgs - Filter arguments to apply to the requests
 * contributors age
 * @returns Promise resolving to a Map where:
 * - Key is the attribute name
 * - Value is AttributeEntropy containing:
 *   - attribute: The group-by attribute name
 *   - attributeUri: URI for the attribute's top contributors
 *   - attributeGroups: Metrics for each group within the attribute
 *   - metrics: Computed entropy metrics for the attribute
 */
export const computeSEEntropy = async (
  groupByOptions: NonEmptyStringArray,
  filterArgs: SEApi.SignalEventsFiltersRequest,
  attributeLabelMapping?: Map<string, string>
) => {
  const filterQueryStrings = new Map([
    [SIGNAL_EVENTS_PAGE_KEY, filterArgs.signalEventOccurrencesFilter || ""],
    [SE_VEHICLES_PAGE_KEY, filterArgs.vehiclesFilter || ""],
  ]);

  return computeEntropy(
    groupByOptions,
    (groupByAttribute) => ({
      ...filterArgs,
      groupBy: groupByAttribute,
      sort: "DPTV:desc",
      limit: API_MAX_LIMIT,
    }),
    getSETopContributors,
    (contributor) => ({
      metric: {
        value: contributor.DPTV,
        label: "DPTV - Distinct VINs",
      },
      groupByAttributeValue: contributor.groupByAttributeValue,
    }),
    (groupByOption) =>
      getTopContributorsUri(
        routes.signalEventAnalytics,
        SIGNAL_EVENTS_PAGE_KEY,
        SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
        filterQueryStrings,
        groupByOption
      ),
    attributeLabelMapping
  );
};

const dataFetchHookMap: Record<
  string,
  (args: any) => Promise<APISuccessResponse<any[]>>
> = {
  laborCode: listLaborCodes,
  failedPartNumber: listParts,
  mentionedSignalEvents: listSignalEvents,
  relatedSignalEvents: listSignalEvents,
  signalEvents: listSignalEvents,
  signalEventID: listSignalEvents,
};

/**
 * Fetches the description for a given value.
 *
 * @param attribute - The attribute name
 * @param values - The values to fetch descriptions for
 * @returns A Promise that resolves to a string with the description
 */
const getValueDescription = async (
  attribute: string,
  values: string[]
): Promise<string> => {
  // if attribute is not in the supported list, return the value as is
  if (!SUPPORTED_DESCRIPTION_TABLE_FIELD_NAMES.includes(attribute)) {
    return values.join(", ");
  }

  const fetchFunc = dataFetchHookMap[attribute];
  try {
    const data = await fetchFunc({
      filter: getFiltersQuery(
        filterStateToFilterGroupState({
          ID: { operator: FilterOperator.IN, values },
        })
      ),
    });

    return values
      .map((value) => {
        const description = getDescriptionForID(value, data.data);

        return description && description !== value
          ? `${value} (${description})`
          : value;
      })
      .join(", ");
  } catch {
    return values.join(", ");
  }
};

/**
 * Generates a human-readable summary of the filter group state.
 * Includes descriptions for the values of supported attributes.
 *
 * @param filterQueryString - The filter query string to summarize
 * @returns A Promise that resolves to a string with the summary
 */
export const generateFilterSummaryTextWithDescriptions = async (
  filterQueryString?: string
): Promise<string> => {
  if (!filterQueryString) return "";

  const filterGroupState =
    filterBuilderQueryToFilterBuilderState(filterQueryString);

  if (!filterGroupState) return filterQueryString;

  const processGroup = async (
    group: FilterGroupState,
    indentLevel = 0
  ): Promise<string> => {
    const indent = "  ".repeat(indentLevel);
    const groupHeader = `${indent}${group.anyAll.toUpperCase()} of the following:\n`;

    const childTexts = await Promise.all(
      group.children.map(async (child) => {
        if (child.type === "group") {
          return processGroup(child, indentLevel + 1);
        }

        const { attribute, operator, values } = child as FilterRowState;
        if (!attribute || !operator || !values?.length) return "";

        const descriptions = await getValueDescription(attribute, values);

        return `${indent}  - ${attribute} ${operator.toUpperCase()} [${descriptions}]`;
      })
    );

    return groupHeader + childTexts.filter(Boolean).join("\n");
  };

  return processGroup(filterGroupState);
};

/**
 * Lists all unique attributes used in a FilterGroupState.
 * @param filterQueryString - The filter query string to analyze
 * @returns Array of unique attribute names
 */
export const listFilterAttributes = (filterQueryString?: string): string[] => {
  const filterGroupState =
    filterBuilderQueryToFilterBuilderState(filterQueryString);
  if (!filterGroupState) return [];

  const attributes = new Set<string>();

  const processGroup = (group: FilterGroupState) => {
    for (const child of group.children) {
      if (child.type === "group") {
        processGroup(child);
      } else if (child.attribute) {
        attributes.add(child.attribute);
      }
    }
  };

  processGroup(filterGroupState);

  return Array.from(attributes);
};
