import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  InspectionAssociatedSignalEvent,
  InspectionAssociatedSignalEventsRequest,
} from "shared/api/inspections/api";
import { useListInspectionsAssociatedSignalEvents } from "shared/api/inspections/hooks";
import { getSortFilter } from "shared/api/utils";
import { SIGNAL_EVENTS_GENERIC_FILTER } from "shared/filterDefinitions";
import { SortBy } from "shared/types";
import { pluralize } from "shared/utils";

import { InspectionAnalyticsTabsProps } from "pages/InspectionAnalytics/InspectionAnalyticsTabs";

import APIError from "features/ui/APIError";
import { getCheckboxCheckedProps } from "features/ui/Checkbox/utils";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import AssociatedSignalEventsOccursFilter from "features/ui/Filters/FilterTypes/OccursFilter/AssociatedSignalEventsOccursFilter";
import { useAssociatedSignalEventsOccursFilter } from "features/ui/Filters/FilterTypes/OccursFilter/hooks";
import OccursFilterActions from "features/ui/Filters/FilterTypes/OccursFilter/OccursFilterActions";
import { WINDOW_DIRECTION_OPTION_BEFORE } from "features/ui/Filters/FilterTypes/OccursFilter/OccursTimeWindowForm";
import SelectedRowsActions from "features/ui/Filters/FilterTypes/OccursFilter/SelectedRowsActions";
import {
  decodeOccursFilterAndOptions,
  getNavigatePropsForSignalEventAnalytics,
} from "features/ui/Filters/FilterTypes/OccursFilter/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell/types";
import TableCellWithCheckbox from "features/ui/Table/TableCellWithCheckbox";
import { Selectable } from "features/ui/Table/types";

// table filters key
const PAGE_KEY = "inspectionAnalytics-associated-se";
const DEFAULT_SORT: SortBy = { associationStrength: "desc" };
const ROWS_PER_PAGE = 20;

// SE filter above table key
const ASSOCIATED_SE_PAGE_KEY = "inspectionAnalytics-associated-se-filters";
const PENDING_FILTERS_ASSOCIATED_SE_LS_KEY = getPendingFiltersKey(
  ASSOCIATED_SE_PAGE_KEY
);

const OCCURS_FILTER_KEY = "inspections-associated-se-relates-filter-v3";

export const getAssociatedSignalEventsSchema = (
  selectedPeriod: string,
  selectableOptions?: Selectable
): SchemaEntry[] => [
  {
    label: "Associated Signal Event",
    accessor: "signalEventID",
    dataType: DataType.JSX,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      label: "Associated Signal Event",
      fieldName: "signalEventID",
      search: true,
      filterType: "string",
      fieldNameForAPI: "ID",
      disableFiltering: true,
    }),
    selectable: selectableOptions,
  },
  {
    label: "Description",
    accessor: "signalEventDescription",
    dataType: DataType.STRING,
    limitedWidthClass: "max-w-xs",
  },
  {
    label: "Inspection rate",
    accessor: "IPTV",
    dataType: DataType.NUMBER,
    sortable: true,
    description: `Inspection rate per 1000 vehicles experiencing this signal event within ${selectedPeriod} of its occurrence`,
  },
  {
    label: "Association strength",
    accessor: "associationStrength",
    dataType: DataType.NUMBER,
    sortable: true,
    description: (
      <div className="text-left">
        A measure of the association between the signal events and the set of
        inspections. In particular, the association strength shows how many
        times more likely a vehicle which experiences the signal event is to go
        on to eventually have a inspection than the average vehicle in the
        population (for example, association strength higher than 1 indicates
        that vehicles are more likely to experience the failure if they’ve
        experienced the signal event). A higher association strength indicates a
        higher likelihood of a non-random relationship between the signal event
        and the set of inspections. Empty cell indicates a failure without
        sufficient evidence of correlation.
      </div>
    ),
  },
  {
    label: "Inspections w/ preceding SE",
    accessor: "percentInspectionsWithPreceding30DaysSignalEvent",
    dataType: DataType.PERCENTAGE,
    description: `Percent of inspections with preceding signal event within ${selectedPeriod}`,
    sortable: true,
  },

  {
    label: "Total signal event occurrences",
    accessor: "totalEventOccurrences",
    dataType: DataType.NUMBER,
    description: `Total number of signal event occurrences within ${selectedPeriod} prior to the defined inspection set`,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      label: "Total signal event occurrences",
      fieldName: "totalEventOccurrences",
      filterType: "number",
      disableSelectFilters: true,
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Associated Inspections",
    accessor: "numAssociatedInspections",
    dataType: DataType.NUMBER,
    description: `The total number of inspections that occur up to ${selectedPeriod} after a signal event`,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      label: "Associated Inspections",
      fieldName: "numAssociatedInspections",
      filterType: "number",
      disableSelectFilters: true,
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Associated Vehicles",
    accessor: "numAssociatedVehicles",
    dataType: DataType.NUMBER,
    description: `The total number of unique vehicles with at least one inspection occurring up to ${selectedPeriod} after a signal event.`,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      label: "Associated Vehicles",
      fieldName: "numAssociatedVehicles",
      filterType: "number",
      disableSelectFilters: true,
      onlyAllowPositiveIntegers: true,
    }),
  },
];

const formatRow = (
  row: InspectionAssociatedSignalEvent,
  selectedSignalEvents: Set<string>,
  setSelectedSignalEvents: (events: Set<string>) => void
) => ({
  ...row,
  signalEventID: (
    <TableCellWithCheckbox
      value={row.signalEventID}
      selectedValues={selectedSignalEvents}
      setSelectedValues={setSelectedSignalEvents}
      testId="checkbox-associated-signal-event"
    />
  ),
});

const AssociatedSignalEvents = ({
  inspectionsFiltersFilterSortState,
  vehiclesFilters,
  onBadRequest,
}: InspectionAnalyticsTabsProps) => {
  // navigation logic
  const navigate = useNavigate();

  const navigateToSignalEventAnalytics = () => {
    const navigationProps = getNavigatePropsForSignalEventAnalytics(
      appliedOccursFilter,
      selectedSignalEvents,
      vehiclesFilters
    );

    navigate(navigationProps);
  };

  // filter logic
  const {
    occursFilter,
    setOccursFilter,
    appliedOccursFilter,
    associatedSignalEventsFilterSortState,
    onApply,
    onCancel,
  } = useAssociatedSignalEventsOccursFilter(
    ASSOCIATED_SE_PAGE_KEY,
    PENDING_FILTERS_ASSOCIATED_SE_LS_KEY,
    OCCURS_FILTER_KEY
  );

  // table logic
  const [selectedSignalEvents, setSelectedSignalEvents] = useState(
    new Set<string>()
  );

  const inspectionsFilters =
    inspectionsFiltersFilterSortState?.filtersWithQuickFilters;

  // table filters
  const {
    manageFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
    schemaAttributes: [],
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const { windowSize, filters: relatedSignalEventsFilter } =
    decodeOccursFilterAndOptions(appliedOccursFilter);

  const requestParams: InspectionAssociatedSignalEventsRequest = {
    sort: getSortFilter(sort),
    filter: getFiltersQuery(filters),
    inspectionsFilter: getFiltersQuery(inspectionsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    signalEventOccurrencesFilter: relatedSignalEventsFilter,
    limit: ROWS_PER_PAGE,
    signalEventsTimeWindow: windowSize,
  };

  const { data, isLoading, error, ...paginationData } =
    useListInspectionsAssociatedSignalEvents(requestParams);

  const allSelectableValues = data?.map((x) => x.signalEventID) || [];
  const { allChecked, indeterminateChecked } = getCheckboxCheckedProps(
    selectedSignalEvents,
    allSelectableValues
  );

  const toggleSelectedSignalEvents = () => {
    if (allChecked) {
      setSelectedSignalEvents(new Set<string>());

      return;
    }

    setSelectedSignalEvents(new Set<string>(allSelectableValues));
  };

  const selectedPeriod = `${windowSize} ${pluralize("day", windowSize)}`;

  const schema = getAssociatedSignalEventsSchema(selectedPeriod, {
    onClick: toggleSelectedSignalEvents,
    checked: allChecked,
    indeterminate: indeterminateChecked,
  });

  const formattedData = useMemo(
    () =>
      data?.map((row) =>
        formatRow(row, selectedSignalEvents, setSelectedSignalEvents)
      ),
    [data, selectedSignalEvents]
  );

  return (
    <>
      <div className="mt-4 space-y-2 flex flex-wrap leading-10 space-x-2 items-end mb-6">
        <AssociatedSignalEventsOccursFilter
          occursFilter={occursFilter}
          setOccursFilter={setOccursFilter}
          filterSortState={associatedSignalEventsFilterSortState}
          pendingFiltersKey={PENDING_FILTERS_ASSOCIATED_SE_LS_KEY}
          baseEntityText="Inspection's Repair Date"
          windowDirectionOptions={[WINDOW_DIRECTION_OPTION_BEFORE]}
        />
        <OccursFilterActions
          occursState={occursFilter}
          appliedOccursFilter={appliedOccursFilter}
          onApply={onApply}
          onCancel={onCancel}
        />
      </div>
      <SelectedRowsActions
        filterSortState={inspectionsFiltersFilterSortState}
        occursFilter={appliedOccursFilter}
        selectedSignalEvents={selectedSignalEvents}
        onExploreInSEAnalyticsActionClick={navigateToSignalEventAnalytics}
      />
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
          testId="associated-signal-events-table"
        />
      )}
      {error && (
        <APIError
          error={error}
          onBadRequest={() => {
            resetFilterSortState();
            onBadRequest();
          }}
        />
      )}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default AssociatedSignalEvents;
