import { CountResponse } from "shared/api/api";
import { useAPI, usePaginatedAPI } from "shared/api/hooks";

import * as api from "./api";

export const useListCalculatedAttributes = (
  args: api.ListCalculatedAttributesRequest
) =>
  usePaginatedAPI<typeof args, api.CalculatedAttribute[]>(
    api.listCalculatedAttributesRequestURI,
    args
  );

export const useListCalculatedAttributesCount = (
  args: api.ListCalculatedAttributesRequest
) =>
  useAPI<typeof args, CountResponse>(
    api.listCalculatedAttributesCountRequestURI,
    args
  );

export const useEvaluateCalculatedAttribute = (
  args: api.EvaluateCalculatedAttributeRequest
) =>
  usePaginatedAPI<typeof args, api.CalculatedAttributeEvaluation[]>(
    api.listEvaluateCalculatedAttributeRequestURI,
    args
  );

export const useEvaluateCalculatedAttributeCount = (
  args: api.EvaluateCalculatedAttributeRequest
) =>
  usePaginatedAPI<typeof args, CountResponse>(
    api.listEvaluateCalculatedAttributeCountRequestURI,
    args
  );
