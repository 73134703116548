import classNames from "classnames";

import { TestProps } from "shared/types";

import { ANY_ALL_INDEX } from "features/ui/Filters/constants";
import FilterLabel from "features/ui/Filters/other/FilterLabel";
import { FilterDiffData } from "features/ui/Filters/types";
import { getFilterKeyMapValues } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";

import { FilterGroupState, FilterRowStateNotNull } from "./types";
import {
  filterBuilderQueryToFilterBuilderState,
  isDefaultAdvancedFilterState,
  isFilterBuilderStateValid,
} from "./utils";

interface Props extends TestProps {
  filter?: string;
  filterState?: FilterGroupState;
  tableSchema?: SchemaEntry<string>[];
  baseEntityText?: string;
  occursBeforeNow?: boolean;
  dense?: boolean;
  diff?: FilterDiffData;
}

const FilterQueryPresentation = ({
  filter,
  filterState,
  tableSchema,
  testId,
  baseEntityText,
  occursBeforeNow,
  dense = true,
  diff,
}: Props) => {
  if (!filter && !filterState) return null;

  // if filterState is valid, then use it, otherwise use filter query and convert it to filter state
  const filterToFilterState =
    (filter && filterBuilderQueryToFilterBuilderState(filter)) || undefined;

  const advFilterState =
    filterState &&
    isFilterBuilderStateValid(filterState) &&
    !isDefaultAdvancedFilterState(filterState)
      ? filterState
      : filterToFilterState;

  if (!advFilterState || !isFilterBuilderStateValid(advFilterState)) {
    return null;
  }

  const filtersKeyMap: Record<string, string> = getFilterKeyMapValues(
    tableSchema || []
  );

  const buildFilterPresentation = (
    groupState: FilterGroupState<FilterRowStateNotNull>,
    diff?: FilterDiffData
  ): JSX.Element => {
    const { anyAll, children, id } = groupState;

    const applyAnyAllDiffStyle =
      diff?.changes[diff.level]?.[ANY_ALL_INDEX]?.anyAll ?? false;

    const groupAnyAll = (
      <div className={classNames({ "text-xs": dense, "text-sm": !dense })}>
        <span
          className={classNames(
            "font-medium capitalize text-viaduct-black",
            applyAnyAllDiffStyle ? diff?.bgColor : undefined
          )}
        >
          {anyAll}
        </span>{" "}
        <span className="italic text-gray-500">of the following are true:</span>
      </div>
    );

    const childrenToDisplay = (
      <div className="ml-4">
        {children.map((child, index) => {
          if (child.type === "group") {
            // recursive step
            return buildFilterPresentation(
              child as FilterGroupState<FilterRowStateNotNull>,
              diff ? { ...diff, level: diff.level + 1, index: 0 } : undefined
            );
          }

          const {
            attribute,
            operator,
            values,
            id: childId,
          } = child as FilterRowStateNotNull;

          // skip attributes that have empty values array
          if (!values.length) return null;

          // only needed so we can re-use existing FilterLabel
          const filters: FilterGroupState = {
            type: "group",
            id,
            anyAll,
            children: [
              {
                type: "row",
                id: childId,
                attribute,
                operator,
                values,
              },
            ],
          };

          return (
            <FilterLabel
              key={`${childId}-${attribute}-${index}`}
              filters={filters}
              fieldName={attribute}
              keyNameMap={filtersKeyMap}
              tableSchema={tableSchema}
              showValuesIfMultiple={false}
              format="label-inline"
              noModalForMultipleValues={false}
              dense={dense}
              baseEntityText={baseEntityText}
              occursBeforeNow={occursBeforeNow}
              diff={diff ? { ...diff, index } : undefined}
            />
          );
        })}
      </div>
    );

    return (
      <div key={`${id}-${anyAll}`} data-testid={testId}>
        {groupAnyAll}
        {childrenToDisplay}
      </div>
    );
  };

  return buildFilterPresentation(
    advFilterState as FilterGroupState<FilterRowStateNotNull>,
    diff
  );
};

export default FilterQueryPresentation;
