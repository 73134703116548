import { useState } from "react";
import { FiPlus as AddIcon } from "react-icons/fi";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { DragEndEvent } from "@dnd-kit/core";

import { APIError } from "shared/api/api";
import {
  getAdminOrderedValuesConfig,
  updateAdminOrderedValuesConfig,
} from "shared/api/superadmin/api";
import { useOrderedValuesConfigList } from "shared/api/superadmin/hooks";
import {
  capitalizeFirstLetter,
  randomID,
  randomUUIDv4OrEmpty,
} from "shared/utils";

import { handleOnSortEnd, showErrorToast } from "pages/SuperAdmin/utils";

import Button from "features/ui/Button";
import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { SelectOption } from "features/ui/Select";

import OrderedValuesConfigContainer from "./OrderedValuesConfigContainer";
import { OrderedValueState } from "./types";

const TITLE = "Ordered Values Configuration";
const CONFIG_ROWS = 25;

const OrderedValuesConfiguration = () => {
  const [selectedOrderedValuesType, setSelectedOrderedValuesType] =
    useState<SelectOption | null>(null);

  const [isConfigLoading, setIsConfigLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [configuration, setConfiguration] = useState<OrderedValueState[]>([]);

  const { data: orderedValuesTypes } = useOrderedValuesConfigList();

  const orderedValuesTypeOptions: SelectOption[] =
    orderedValuesTypes?.map((type) => ({
      id: type,
      value: capitalizeFirstLetter(type),
    })) || [];

  const handleResourceChange = (option: SelectOption | null) => {
    if (option) {
      setSelectedOrderedValuesType(option);
      setIsConfigLoading(true);
      setIsError(false);

      getAdminOrderedValuesConfig(option.id as string)
        .then(({ data }) =>
          setConfiguration(
            data.map((orderedValue) => ({
              ID: orderedValue.ID,
              value: orderedValue.value,
              id: randomID(),
            }))
          )
        )
        .catch(() => {
          setIsError(true);
        })
        .finally(() => setIsConfigLoading(false));
    } else {
      setSelectedOrderedValuesType(null);
      setConfiguration([]);
      setIsError(false);
    }
  };

  const handleSave = () => {
    if (!showConfig) return;

    setIsSubmitting(true);

    updateAdminOrderedValuesConfig({
      type: selectedOrderedValuesType!.id as string,
      values: configuration.map(({ id, ...rest }) => rest),
    })
      .then(() => {
        toast.success(
          `${selectedOrderedValuesType!.value} configuration saved successfully!`
        );
      })
      .catch((error: APIError) => {
        showErrorToast(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const onAddOrderedValue = () =>
    setConfiguration([
      ...configuration,
      { ID: randomUUIDv4OrEmpty(), value: "", id: randomID() },
    ]);

  const onSortEnd = (event: DragEndEvent) => {
    handleOnSortEnd(event, setConfiguration);
  };

  const onDelete = (internalID: string) => {
    setConfiguration(configuration.filter((row) => row.id !== internalID));
  };

  const onUpdate = (internalID: string, row: OrderedValueState) =>
    setConfiguration(
      configuration.map((orderedValue) =>
        orderedValue.id === internalID ? row : orderedValue
      )
    );

  const showConfig = Boolean(!isConfigLoading && selectedOrderedValuesType);

  return (
    <div>
      <PageHeaderWrapper title={TITLE} />
      <div className="mb-4">
        <DropdownWithSearch
          label="Select ordered values type"
          options={orderedValuesTypeOptions}
          selectedOption={selectedOrderedValuesType}
          onSelectedOptionChange={handleResourceChange}
          testId="resource-select"
        />
      </div>
      {isError && (
        <div className="mb-4">
          <p className="text-red-500">Failed to load configuration.</p>
        </div>
      )}
      {isConfigLoading && <Skeleton height={CONFIG_ROWS * 20} />}
      {showConfig && (
        <div className="max-w-4xl">
          <div className="mb-4">
            <Button
              label="Add Ordered Value"
              color="primary"
              variant="outlined"
              size="small"
              startIcon={<AddIcon />}
              onClick={onAddOrderedValue}
              className="mb-4"
            />
          </div>
          <OrderedValuesConfigContainer
            onSortEnd={onSortEnd}
            configuration={configuration}
            updateValueByID={onUpdate}
            onDelete={onDelete}
          />
          <div className="flex justify-end mt-4">
            <Button
              label="Save"
              color="primary"
              variant="contained"
              testId="save-ordered-values-config"
              onClick={handleSave}
              isLoading={isSubmitting}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default OrderedValuesConfiguration;
