import { AccordionProps } from "features/ui/Accordion";
import AccordionGroup from "features/ui/AccordionGroup";
import Title from "features/ui/Title";

import GeneralSettings from "./GeneralSettings";
import LDFlags from "./LDFlags";

const accordionGroupData: AccordionProps[] = [
  {
    id: "general",
    title: "General",
    children: <GeneralSettings />,
  },
  {
    id: "launchdarkly",
    title: "LaunchDarkly",
    children: <LDFlags />,
  },
];

const Info = () => (
  <>
    <Title text="Settings" />
    <div className="my-3">
      <AccordionGroup data={accordionGroupData} />
    </div>
  </>
);

export default Info;
