import { useFlags } from "launchdarkly-react-client-sdk";

import {
  useServiceRecommendationsFiltersSchema,
  useVehiclesFiltersSchema,
} from "shared/hooks";

import PageLevelFilters from "pages/shared/PageLevelFilters/PageLevelFilters";
import { VEHICLES_FILTER_LABEL } from "pages/SignalEventsAnalytics/constants";

import Button from "features/ui/Button";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { UseFilterSortState } from "features/ui/Filters/types";
import { getFilterLabel } from "features/ui/Filters/utils";

import {
  SP_RECOMMENDATIONS_FILTER_LABEL,
  SP_RECOMMENDATIONS_FILTERS_LS_KEY,
  SP_RECOMMENDATIONS_VEHICLE_FILTERS_LS_KEY,
} from "./constants";

interface Props {
  serviceRecommendationFilterSortState: UseFilterSortState;
  vehiclesFilterSortState: UseFilterSortState;
  defaultServiceRecommendationFilters: FilterGroupState;
  defaultVehicleFilters: FilterGroupState;
  isInsideTab?: boolean;
}

const ServiceRecommendationsFilters = ({
  serviceRecommendationFilterSortState,
  vehiclesFilterSortState,
  defaultServiceRecommendationFilters,
  defaultVehicleFilters,
  isInsideTab = false,
}: Props) => {
  const { createWorkOrderCta: createWorkOrderCtaFF } = useFlags();

  const serviceRecommendationsFiltersSchema =
    useServiceRecommendationsFiltersSchema();
  const vehiclesFiltersSchema = useVehiclesFiltersSchema();

  const servicePlanFilterLabel = getFilterLabel(
    SP_RECOMMENDATIONS_FILTER_LABEL,
    serviceRecommendationFilterSortState.filters
  );
  const vehicleFilterLabel = getFilterLabel(
    VEHICLES_FILTER_LABEL,
    vehiclesFilterSortState.filters
  );

  return (
    <PageLevelFilters
      actions={
        Boolean(createWorkOrderCtaFF && isInsideTab) && (
          // Only for demo purposes - ask Gui when we can remove
          <Button
            className="ml-auto"
            variant="contained"
            color="secondary"
            size="small"
            disabled
          >
            Create Work Order
          </Button>
        )
      }
      filtersProps={[
        {
          filterSortState: vehiclesFilterSortState,
          defaultFilters: defaultVehicleFilters,
          filterSchema: vehiclesFiltersSchema,
          filterTitle: VEHICLES_FILTER_LABEL,
          filterLabel: vehicleFilterLabel,
          pendingFilterKey: getPendingFiltersKey(
            SP_RECOMMENDATIONS_VEHICLE_FILTERS_LS_KEY
          ),
          entity: "Vehicles",
          testId: "vehicles",
          quickFilterLabel: "Vehicle Quick Filters",
        },
        {
          filterSortState: serviceRecommendationFilterSortState,
          defaultFilters: defaultServiceRecommendationFilters,
          filterSchema: serviceRecommendationsFiltersSchema,
          filterTitle: SP_RECOMMENDATIONS_FILTER_LABEL,
          filterLabel: servicePlanFilterLabel,
          pendingFilterKey: getPendingFiltersKey(
            SP_RECOMMENDATIONS_FILTERS_LS_KEY
          ),
          entity: "Service Recommendation",
          testId: "service-recommendations",
          quickFilterLabel: "Service Recommendation Quick Filters",
        },
      ]}
    />
  );
};

export default ServiceRecommendationsFilters;
