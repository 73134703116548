import NewCTA from "shared/components/NewCTA";

import Modal from "features/ui/Modal";

const CTA_TEXT = "Add Users";

interface Props {}

const AddUsers = ({}: Props) => (
  <>
    <Modal
      triggerElement={<NewCTA label={CTA_TEXT} testId="new-group-cta" />}
      children={[<div>Add users modal coming soon!</div>]}
    />
  </>
);

export default AddUsers;
