import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import {
  ClaimAssociatedSignalEvent,
  ClaimAssociatedSignalEventsRequest,
} from "shared/api/claims/api";
import { useListClaimsAssociatedSignalEvents } from "shared/api/claims/hooks";
import { getSortFilter } from "shared/api/utils";
import {
  GENERIC_FILTER_WITHOUT_SELECT,
  SIGNAL_EVENTS_GENERIC_FILTER,
} from "shared/filterDefinitions";
import { SortBy } from "shared/types";
import { pluralize } from "shared/utils";

import { ClaimAnalyticsTabsProps } from "pages/ClaimAnalytics/ClaimAnalyticsTabs";

import APIError from "features/ui/APIError";
import { getCheckboxCheckedProps } from "features/ui/Checkbox/utils";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import AssociatedSignalEventsOccursFilter from "features/ui/Filters/FilterTypes/OccursFilter/AssociatedSignalEventsOccursFilter";
import { useAssociatedSignalEventsOccursFilter } from "features/ui/Filters/FilterTypes/OccursFilter/hooks";
import OccursFilterActions from "features/ui/Filters/FilterTypes/OccursFilter/OccursFilterActions";
import SelectedRowsActions from "features/ui/Filters/FilterTypes/OccursFilter/SelectedRowsActions";
import {
  decodeOccursFilterAndOptions,
  getNavigatePropsForSignalEventAnalytics,
} from "features/ui/Filters/FilterTypes/OccursFilter/utils";
import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";
import { OnSortParams, SchemaEntry } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import { DataType } from "features/ui/Table/TableBodyCell/types";
import TableCellWithCheckbox from "features/ui/Table/TableCellWithCheckbox";
import { Selectable } from "features/ui/Table/types";

// table filters key
export const CLAIM_ANALYTICS_ASSOCIATED_SIGNAL_EVENTS_PAGE_KEY =
  "claimAnalytics-associated-se";
const DEFAULT_SORT: SortBy = { associationStrength: "desc" };
const ROWS_PER_PAGE = 20;

// SE filter above table key
const ASSOCIATED_SE_PAGE_KEY = "claimAnalytics-associated-se-filters";
const PENDING_FILTERS_ASSOCIATED_SE_LS_KEY = getPendingFiltersKey(
  ASSOCIATED_SE_PAGE_KEY
);

export const OCCURS_FILTER_KEY = "claims-associated-se-relates-filter-v3";

export const getAssociatedSignalEventsSchema = (
  selectedPeriod: string,
  selectableOptions?: Selectable
): SchemaEntry[] => [
  {
    label: "Associated Signal Event",
    accessor: "signalEventID",
    dataType: DataType.JSX,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      label: "Associated Signal Event",
      fieldName: "signalEventID",
      search: true,
      filterType: "string",
      fieldNameForAPI: "ID",
      disableFiltering: true,
    }),
    selectable: selectableOptions,
  },
  {
    label: "Description",
    accessor: "signalEventDescription",
    dataType: DataType.STRING,
    limitedWidthClass: "max-w-xs",
  },
  {
    label: "Association strength",
    accessor: "associationStrength",
    dataType: DataType.NUMBER,
    sortable: true,
    description: (
      <div className="text-left">
        A measure of the association between the signal events and the set of
        claims. In particular, the association strength shows how many times
        more likely a vehicle which experiences the signal event is to go on to
        eventually have a claim than the average vehicle in the population (for
        example, association strength higher than 1 indicates that vehicles are
        more likely to experience the failure if they’ve experienced the signal
        event). A higher association strength indicates a higher likelihood of a
        non-random relationship between the signal event and the set of claims.
        Empty cell indicates a failure without sufficient evidence of
        correlation.
      </div>
    ),
  },
  {
    label: "Vehicles w/ Co-occurrence",
    accessor: "vehiclesWithCooccurrence",
    dataType: DataType.NUMBER,
    description:
      "Number of unique vehicles which experienced both a claim and an associated signal event within the time window.",
    sortable: true,
    filter: GENERIC_FILTER_WITHOUT_SELECT({
      label: "Vehicles w/ Co-occurrence",
      fieldName: "vehiclesWithCooccurrence",
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Associated Vehicles",
    accessor: "numAssociatedVehicles",
    dataType: DataType.NUMBER,
    description: `Number of unique vehicles that experienced the associated signal event.`,
    sortable: true,
    filter: SIGNAL_EVENTS_GENERIC_FILTER({
      label: "Associated Vehicles",
      fieldName: "numAssociatedVehicles",
      filterType: "number",
      disableSelectFilters: true,
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Base Vehicles",
    accessor: "baseVehicles",
    dataType: DataType.NUMBER,
    description: "Number of unique vehicles that experienced a claim.",
    sortable: true,
    filter: GENERIC_FILTER_WITHOUT_SELECT({
      label: "Base Vehicles",
      fieldName: "baseVehicles",
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
  {
    label: "Total Vehicles",
    accessor: "totalVehicles",
    dataType: DataType.NUMBER,
    description: "Number of eligible vehicles.",
    sortable: true,
    filter: GENERIC_FILTER_WITHOUT_SELECT({
      label: "Total Vehicles",
      fieldName: "totalVehicles",
      filterType: "number",
      onlyAllowPositiveIntegers: true,
    }),
  },
];

const formatRow = (
  row: ClaimAssociatedSignalEvent,
  selectedSignalEvents: Set<string>,
  setSelectedSignalEvents: (events: Set<string>) => void
) => ({
  ...row,
  signalEventID: (
    <TableCellWithCheckbox
      value={row.signalEventID}
      selectedValues={selectedSignalEvents}
      setSelectedValues={setSelectedSignalEvents}
      testId="checkbox-associated-signal-event"
    />
  ),
});

const AssociatedSignalEvents = ({
  claimsFiltersFilterSortState,
  vehiclesFilters,
  onBadRequest,
}: ClaimAnalyticsTabsProps) => {
  // navigation logic
  const navigate = useNavigate();

  const navigateToSignalEventAnalytics = () => {
    const navigationProps = getNavigatePropsForSignalEventAnalytics(
      occursFilter,
      selectedSignalEvents,
      vehiclesFilters
    );

    navigate(navigationProps);
  };

  // filter logic
  const {
    occursFilter,
    setOccursFilter,
    appliedOccursFilter,
    associatedSignalEventsFilterSortState,
    onApply,
    onCancel,
  } = useAssociatedSignalEventsOccursFilter(
    ASSOCIATED_SE_PAGE_KEY,
    PENDING_FILTERS_ASSOCIATED_SE_LS_KEY,
    OCCURS_FILTER_KEY
  );

  // table logic
  const [selectedSignalEvents, setSelectedSignalEvents] = useState(
    new Set<string>()
  );

  const claimsFilters = claimsFiltersFilterSortState?.filters;

  // table filters
  const {
    manageFilterChange,
    resetFilters,
    filters,
    sort,
    manageOnSortChange,
    initialized: filtersInitialized,
    resetFilterSortState,
  } = useFilterSortState({
    pageKey: CLAIM_ANALYTICS_ASSOCIATED_SIGNAL_EVENTS_PAGE_KEY,
    defaultSort: DEFAULT_SORT,
    schemaAttributes: [],
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const { windowSize, filters: relatedSignalEventsFilter } =
    decodeOccursFilterAndOptions(appliedOccursFilter);

  const requestParams: ClaimAssociatedSignalEventsRequest = {
    sort: getSortFilter(sort),
    filter: getFiltersQuery(filters),
    claimsFilter: getFiltersQuery(claimsFilters),
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    signalEventOccurrencesFilter: relatedSignalEventsFilter,
    limit: ROWS_PER_PAGE,
    signalEventsTimeWindow: windowSize,
  };

  const { data, isLoading, error, ...paginationData } =
    useListClaimsAssociatedSignalEvents(requestParams);

  const allSelectableValues = data?.map((x) => x.signalEventID) || [];
  const { allChecked, indeterminateChecked } = getCheckboxCheckedProps(
    selectedSignalEvents,
    allSelectableValues
  );

  const toggleSelectedSignalEvents = () => {
    if (allChecked) {
      setSelectedSignalEvents(new Set<string>());

      return;
    }

    setSelectedSignalEvents(new Set<string>(allSelectableValues));
  };

  const selectedPeriod = `${windowSize} ${pluralize("day", windowSize)}`;

  const schema = getAssociatedSignalEventsSchema(selectedPeriod, {
    onClick: toggleSelectedSignalEvents,
    checked: allChecked,
    indeterminate: indeterminateChecked,
  });

  const formattedData = useMemo(
    () =>
      data?.map((row) =>
        formatRow(row, selectedSignalEvents, setSelectedSignalEvents)
      ),
    [data, selectedSignalEvents]
  );

  // row actions
  const addAsRelatedSignalEvent = () => {
    setSelectedSignalEvents(new Set<string>());
    toast.success("Claim Filters updated");
  };

  return (
    <>
      <div className="mt-4 space-y-2 flex flex-wrap leading-10 space-x-2 items-end mb-6">
        <AssociatedSignalEventsOccursFilter
          occursFilter={occursFilter}
          setOccursFilter={setOccursFilter}
          filterSortState={associatedSignalEventsFilterSortState}
          pendingFiltersKey={PENDING_FILTERS_ASSOCIATED_SE_LS_KEY}
          baseEntityText="Claim's Repair Date"
        />
        <OccursFilterActions
          occursState={occursFilter}
          appliedOccursFilter={appliedOccursFilter}
          onApply={onApply}
          onCancel={onCancel}
        />
      </div>
      <SelectedRowsActions
        filterSortState={claimsFiltersFilterSortState}
        occursFilter={appliedOccursFilter}
        selectedSignalEvents={selectedSignalEvents}
        onAddToFilter={addAsRelatedSignalEvent}
        onExploreInSEAnalyticsActionClick={navigateToSignalEventAnalytics}
      />
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          stickyFirstColumn={true}
          dense
          testId="associated-signal-events-table"
        />
      )}
      {error && (
        <APIError
          error={error}
          onBadRequest={() => {
            resetFilterSortState();
            onBadRequest();
          }}
        />
      )}
      {!error && !isLoading && !formattedData?.length && (
        <div className="py-4 text-gray-400 text-sm">No results.</div>
      )}
    </>
  );
};

export default AssociatedSignalEvents;
