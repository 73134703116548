import { useCallback } from "react";

import { useVehiclesFiltersSchema } from "shared/hooks";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { areFiltersEqual } from "features/ui/Filters/FilterBuilder/utils";
import FilterWizard from "features/ui/Filters/FilterWizard";
import { hasSomeFiltersAppliedFilterGroupState } from "features/ui/Filters/utils";
import { StepProps } from "features/ui/Stepper";

import { ISSUE_COMPARISON_POPULATION_FILTER_PAGE_KEY } from "./constants";
import FormErrorBox from "./FormErrorBox";
import VehicleCount from "./VehicleCount";

const StepComparisonPopulation = ({
  data,
  onDataUpdated,
  disableUsingLocalStorage,
}: StepProps) => {
  const onFiltersChange = useCallback((filters: FilterGroupState) => {
    onDataUpdated && onDataUpdated({ comparisonPopulationFilter: filters });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = useVehiclesFiltersSchema();

  if (!data) return null;

  const currentFilters: FilterGroupState = data.comparisonPopulationFilter;

  const showErrorBox = areFiltersEqual(
    data.atRiskPopulationFilter,
    currentFilters
  );

  const showCount = hasSomeFiltersAppliedFilterGroupState(currentFilters);

  return (
    <>
      {showErrorBox ? (
        <FormErrorBox
          title="Update filter criteria"
          text="A comparison population must be distinct from an at-risk population. Update your filter criteria to create distinction."
        />
      ) : (
        <div className="mb-4 text-sm text-viaduct-black">
          Create an optional comparison population to be used as an analytical
          reference point against an at-risk population. A comparison population
          must have at least one distinct attribute to distinguish it from the
          at- risk vehicle population.
        </div>
      )}
      <FilterWizard
        schema={schema}
        initialFilters={currentFilters}
        onFiltersChange={onFiltersChange}
        disableUsingLocalStorage={disableUsingLocalStorage}
        pageKey={ISSUE_COMPARISON_POPULATION_FILTER_PAGE_KEY}
        testId={ISSUE_COMPARISON_POPULATION_FILTER_PAGE_KEY}
        belowRightBlockContent={
          showCount && (
            <VehicleCount
              filters={currentFilters}
              label="Comparison population"
            />
          )
        }
      />
    </>
  );
};

export default StepComparisonPopulation;
