import { SignalEventsOccurrencesMetrics } from "shared/api/signalEvents/api";
import { useSignalEventsOccurrencesMetrics } from "shared/api/signalEvents/hooks";
import { formatNumber } from "shared/utils";

import { SignalEventsFiltersProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import APIError from "features/ui/APIError";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import MetricCard from "features/ui/MetricCard";

interface Metric {
  title: string;
  prop: keyof SignalEventsOccurrencesMetrics;
  subtitle?: string;
  type?: "number" | "percentage";
}

const metrics: Metric[] = [
  {
    title: "Cumulative DPTV",
    prop: "cumulativeIPTV",
  },
  {
    title: "Last 30 days DPTV",
    prop: "last30DayIPTV",
  },
  {
    title: "% Change in DPTV",
    prop: "IPTV30DayChangeRatio",
    subtitle: "Last 30 days from previous 30",
    type: "percentage",
  },
];

const MetricsCards = ({
  vehiclesFilters,
  signalEventsFilters,
  onBadRequest,
}: SignalEventsFiltersProps) => {
  const { data, isLoading, error } = useSignalEventsOccurrencesMetrics({
    vehiclesFilter: getFiltersQuery(vehiclesFilters),
    signalEventOccurrencesFilter: getFiltersQuery(signalEventsFilters),
  });

  if (error) {
    return <APIError error={error} onBadRequest={onBadRequest} />;
  }

  return (
    <div className="flex flex-col space-y-5">
      {metrics.map(({ prop, type, ...otherProps }) => {
        const multiplier = type === "percentage" ? 100 : 1;

        const value =
          data && (formatNumber((data[prop] || 0) * multiplier) || undefined);

        return (
          <MetricCard
            key={prop}
            value={value}
            isLoading={isLoading}
            subtitleHeight=""
            {...otherProps}
          />
        );
      })}
    </div>
  );
};

export default MetricsCards;
