import { useState } from "react";
import classNames from "classnames";
import { IoSearchSharp as SearchIcon } from "react-icons/io5";
import { Dialog, DialogContent } from "@mui/material";
import Button from "@mui/material/Button";

import { useKeyBinding } from "shared/hooks";
import { isMac } from "shared/utils";

import Tooltip from "features/ui/Tooltip";

import GlobalSearch from "./GlobalSearch";

const ICON_SIZE = 17;

interface Props {
  menuExpanded: boolean;
}

const GlobalSearchAction = ({ menuExpanded }: Props) => {
  const [dialogOpen, setDialogOpen] = useState(false);

  useKeyBinding(["cmd", "k"], () => setDialogOpen(true));

  return (
    <>
      {!menuExpanded && (
        <Tooltip title="Search" placement="right">
          <div
            className="flex items-center w-full justify-center hover:bg-gray-100! leading-none md:leading-3 py-2"
            onClick={() => setDialogOpen(true)}
          >
            <SearchIcon size={ICON_SIZE} />
          </div>
        </Tooltip>
      )}
      {menuExpanded && (
        <Button
          id="global-search"
          data-testid="global-search"
          onClick={() => setDialogOpen(true)}
          variant="outlined"
          className="w-full justify-start! border-gray-300! text-gray-600! hover:bg-gray-100! flex flex-row"
          startIcon={<SearchIcon size={ICON_SIZE} />}
        >
          <span>Search</span>
          <span className="border rounded-sm pl-1 pr-1 text-sm ml-auto flex flex-row items-center">
            <kbd
              className={classNames({
                "text-xs": !isMac,
                "text-sm": isMac,
              })}
            >
              {isMac ? "⌘" : "CTRL"}+
            </kbd>

            <kbd className="text-xs">K</kbd>
          </span>
        </Button>
      )}
      <Dialog
        disableRestoreFocus
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="sm"
        fullWidth={true}
        sx={{
          "& .MuiDialog-container": {
            alignItems: "flex-start",
            marginTop: "4rem",
          },
        }}
      >
        <DialogContent>
          <GlobalSearch onClick={() => setDialogOpen(false)}></GlobalSearch>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GlobalSearchAction;
