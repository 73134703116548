import Skeleton from "react-loading-skeleton";

import {
  getRepairsByVehicleAgeTimelineExport,
  RepairsByVehicleAgeAgeRequest,
} from "shared/api/repairs/api";
import { useListRepairsByVehicleAgeTimeline } from "shared/api/repairs/hooks";
import { NO_BY_PRODUCTION_DATE_ATTRIBUTES_CONFIGURED } from "shared/constants";
import { useCustomLocalStorageState } from "shared/hooks";
import { useRepairsSchema } from "shared/schemas/repairsSchema";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { EventTypeEnum, EventTypeLabel } from "shared/types";

import {
  useByVehicleAgeBirthdayOptions,
  useByVehicleAgeOptions,
  useRepairByVehicleAgeExposureOptions,
} from "pages/hooks";
import { RepairsFiltersProps } from "pages/RepairAnalytics/RepairAnalyticsTabs";
import ByVehicleAgeChart from "pages/shared/analytics/ByVehicleAgeChart";
import ByVehicleAgeTable from "pages/shared/analytics/ByVehicleAgeTable";
import {
  areThereAnyByAgeAttributes,
  getByVehicleAgeChartOptions,
  isSelectedByAgeAttributeInvalid,
} from "pages/utils";

import {
  cumulativeEvents,
  cumulativeIPTV,
  totalVehicles,
} from "features/ui/charts/actions";
import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";
import {
  getFiltersQuery,
  mergeFilterGroupStates,
} from "features/ui/Filters/FilterBuilder/utils";

const CHART_KEY = "repairAnalyticsByVehicleAgeChartOptions";

const ByVehicleAge = ({
  repairsFilters,
  vehiclesFilters,
  onBadRequest,
  onAddDateRangeToVehicleFilters,
}: RepairsFiltersProps) => {
  const actions = getByVehicleAgeChartOptions(
    [cumulativeIPTV, totalVehicles, cumulativeEvents(EventTypeLabel.REPAIR)],
    useByVehicleAgeBirthdayOptions(),
    useRepairByVehicleAgeExposureOptions()
  );

  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(CHART_KEY, { defaultValue: getDefaultActions(actions) });

  const byVehicleAgeData = useByVehicleAgeOptions(selectedOptions, actions);

  const { byVehicleAgeBirthday, byVehicleAgeExposure, granularity } =
    byVehicleAgeData;

  const requestParams: RepairsByVehicleAgeAgeRequest = {
    repairsFilter: repairsFilters && getFiltersQuery(repairsFilters),
    vehiclesFilter: vehiclesFilters && getFiltersQuery(vehiclesFilters),
    byVehicleAgeBirthday,
    byVehicleAgeExposure,
    granularity,
  };

  const { data, isLoading, error } =
    useListRepairsByVehicleAgeTimeline(requestParams);

  const { attributes: vehicleAttributes } = useVehiclesSchema();
  const { attributes: repairAttributes } = useRepairsSchema();
  if (!areThereAnyByAgeAttributes(vehicleAttributes, repairAttributes)) {
    return NO_BY_PRODUCTION_DATE_ATTRIBUTES_CONFIGURED;
  } else if (
    isSelectedByAgeAttributeInvalid(
      byVehicleAgeData,
      vehicleAttributes,
      repairAttributes
    )
  ) {
    setSelectedOptions(getDefaultActions(actions));
  }

  if (isLoading) {
    return <Skeleton height={500} count={2} className="mt-5" />;
  }

  return (
    <div className="mt-5">
      <ByVehicleAgeChart
        byVehicleAgeData={byVehicleAgeData}
        data={data}
        error={error}
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={setSelectedOptions}
        onBadRequest={onBadRequest}
        onAddDateRangeToVehicleFilters={onAddDateRangeToVehicleFilters}
      />
      <ByVehicleAgeTable
        eventType={EventTypeEnum.REPAIR}
        byVehicleAgeData={byVehicleAgeData}
        filters={mergeFilterGroupStates(repairsFilters, vehiclesFilters)}
        data={data}
        error={error}
        downloadRequestParams={requestParams}
        downloadFunc={getRepairsByVehicleAgeTimelineExport}
        onBadRequest={onBadRequest}
      />
    </div>
  );
};

export default ByVehicleAge;
