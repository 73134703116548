/**
 * Based off of https://github.com/thlorenz/parse-link-header
 * The mentioned repo has a limitation to 2000 characters which is a problem for us as
 * react limits our env vars to be called REACT_ ... that's why this is our TS version of it + simplified
 * Note: it would be nicer to have this as a npm repo publicly somewhere
 */

import qs from "qs";

type ObjNil = Record<string, string> | null;

const hasRel = (x?: ObjNil) => x && x.rel;

const intoRels = (acc: Record<string, any>, x?: ObjNil) => {
  const splitRel = (rel: string) => {
    acc[rel] = { ...x, rel };
  };

  if (!x) return acc;

  x.rel.split(/\s+/).map(splitRel);

  return acc;
};

const createObjects = (acc: Record<string, any>, p: string) => {
  const m = p.match(/\s*(.+)\s*=\s*"?([^"]+)"?/);
  if (m && m.length > 1) acc[m[1]] = m[2];

  return acc;
};

const parseLink = (link: string) => {
  try {
    const m = link.match(/<?([^>]*)>(.*)/);
    if (!m) return;

    const linkUrl = m[1];
    const parts = m[2].split(";");
    const parsedUrl = new URL(linkUrl);
    const qry = qs.parse(parsedUrl.search.substring(1));

    parts.shift();

    let info = parts.reduce(createObjects, {});

    info = { ...qry, ...info };
    info.url = linkUrl;

    return info;
  } catch {
    return null;
  }
};

const parseLinkHeader = (
  str?: string
): Record<string, any> | undefined | null =>
  str?.split(/,\s*</).map(parseLink).filter(hasRel).reduce(intoRels, {});

export default parseLinkHeader;
