import {
  APIListValuesRequest,
  APIListValuesResponse,
  APIPaginatedRequest,
  ByVehicleAgeAgeRequest,
  ExportRequest,
  getFetcher,
  ListAttributesRequest,
  TopContributorsAgeRequest,
} from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import {
  CountRequestWithVehiclesFilter,
  Vehicle,
} from "shared/api/vehicles/api";
import { MileageUnit } from "shared/types";

const REPAIRS_BASE_ROUTE = "repairs";

export interface RepairPart {
  partId: string;
  repairId: string;
  overtimeTime: number | null;
  regularTime: number | null;
  startDate: string | null;
  technician: string | null;
  technicianRemarks: string | null;
  workAccomplishedCode: string | null;
  part: Part;
}

interface Part {
  ID: string;
  createdAt: string;
  updatedAt: string;
  description: string | null;
  systemCode: string | null;
  assemblyCode: string | null;
  componentCode: string | null;
}

export interface Repair {
  ID: string;
  VIN: string;
  createdAt: string;
  updatedAt: string;
  date: string;
  data: Record<string, string | undefined>;
  externalID: string | null;
  externalURL: string | null;
  notes: string | null;
  status: string | null;
  mileage: number | null;
  mileageUnit: MileageUnit | null;
  monthsInService: number | null;
  daysInService: number | null;
  totalEngineHours: number | null;
  parts: RepairPart[];
}

export interface ListRepairsRequest extends APIPaginatedRequest {
  vehiclesFilter?: string;
  analytics?: boolean;
  mileageUnit?: MileageUnit;
}

// List & count
export const listRepairsRequestURI = ({
  ...params
}: ListRepairsRequest): string =>
  client.getUri({
    method: "GET",
    url: REPAIRS_BASE_ROUTE,
    params,
  });

export const listRepairs = (args: APIPaginatedRequest) =>
  getFetcher(listRepairsRequestURI({ ...args, analytics: true }));

export const listRepairsCountRequestURI = ({
  ...params
}: CountRequestWithVehiclesFilter): string =>
  client.getUri({
    method: "GET",
    url: createURL([REPAIRS_BASE_ROUTE, "count"]),
    params,
  });

// Repairs values
export const listRepairsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listRepairsValuesRequestURI(args));

const listRepairsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIRS_BASE_ROUTE, "values", fieldName]),
    params,
  });

// Export
const getRepairsExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIRS_BASE_ROUTE, "export", type]),
    params,
  });

export const getRepairsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getRepairsExportRequestURI(args), {
    responseType: "blob",
  });

export interface RepairFiltersRequest {
  repairsFilter?: string;
  vehiclesFilter?: string;
  signalEventOccurrencesFilter?: string;
}

// Metrics
export interface RepairsMetrics {
  totalRepairs: number;
  cumulativeIPTV: number;
}

export const getRepairsMetricsRequestURI = (
  params: RepairFiltersRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIRS_BASE_ROUTE, "metrics"]),
    params,
  });

export interface RepairsMetricsHistory {
  date: string;
  rollingIPTV: number;
  cumulativeIPTV: number;
  vehicleCount: number;
  rollingRepairCount: number;
  cumulativeRepairCount: number;
}

export const getRepairsMetricsHistoryRequestURI = (
  params: RepairFiltersRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIRS_BASE_ROUTE, "metricsHistory"]),
    params,
  });

// Single repair
export interface GetRepairRequest {
  ID: string;
  mileageUnit: MileageUnit;
}

export const getRepairRequestURI = ({
  ID,
  ...params
}: GetRepairRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIRS_BASE_ROUTE, ID]),
    params,
  });

export const getRepair = (args: GetRepairRequest) =>
  getFetcher<Repair>(getRepairRequestURI(args));

// Repair attributes
export const listRepairAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIRS_BASE_ROUTE, "attributes"]),
    params,
  });

// Top Contributors
export interface RepairsTopContributorsRequest
  extends APIPaginatedRequest,
    RepairFiltersRequest,
    TopContributorsAgeRequest {
  groupByAttribute: string;
}

export interface RepairsTopContributor {
  groupByAttributeValue: string | number | boolean | null;
  IPTV: number;
  IPTV30DayChangeRatio: number | null;
  DPTV: number;
  DPTV30DayChangeRatio: number | null;
  numEvents: number;
  numVehicles: number;
  exposureBucket: number | null;
}

export const getRepairsTopContributorsRequestURI = ({
  groupByAttribute,
  ...otherParams
}: RepairsTopContributorsRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIRS_BASE_ROUTE, "topContributors", groupByAttribute]),
    params: otherParams,
  });

const getRepairsTopContributorsExportRequestURI = ({
  IDs,
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([
      REPAIRS_BASE_ROUTE,
      "topContributors",
      ...IDs,
      "export",
      type,
    ]),
    params,
  });

export const getRepairsTopContributorsExport = (args: ExportRequest) =>
  getFetcher<Blob>(getRepairsTopContributorsExportRequestURI(args), {
    responseType: "blob",
  });

// Associated Vehicles
export interface RepairAssociatedVehicle extends Vehicle {
  numRelatedRepairOccurrences: number;
  lastRelatedRepairOccurrenceAt: string;
  firstRelatedRepairOccurrenceAt: string;
}

export interface RepairAssociatedVehiclesRequest
  extends APIPaginatedRequest,
    RepairFiltersRequest {}

export const listAssociatedVehiclesRequestURI = (
  params: RepairAssociatedVehiclesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIRS_BASE_ROUTE, "associatedVehicles"]),
    params,
  });

export const countAssociatedVehiclesRequestURI = (
  params: RepairAssociatedVehiclesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIRS_BASE_ROUTE, "associatedVehicles", "count"]),
    params,
  });

const getAssociatedVehiclesExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIRS_BASE_ROUTE, "associatedVehicles", "export", type]),
    params,
  });

export const getAssociatedVehiclesExport = (args: ExportRequest) =>
  getFetcher<Blob>(getAssociatedVehiclesExportRequestURI(args), {
    responseType: "blob",
  });

// By Production Date
export interface RepairsByVehicleAgeAgeRequest
  extends RepairFiltersRequest,
    ByVehicleAgeAgeRequest {}

export const getRepairsByVehicleAgeTimelineRequestURI = (
  params: RepairFiltersRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIRS_BASE_ROUTE, "repairsByVehicleAgeTimeline"]),
    params,
  });

const getRepairsByVehicleAgeTimelineExportRequestURI = ({
  type = "xlsx",
  ...params
}: ExportRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([
      REPAIRS_BASE_ROUTE,
      "repairsByVehicleAgeTimeline",
      "export",
      type,
    ]),
    params,
  });

export const getRepairsByVehicleAgeTimelineExport = (args: ExportRequest) =>
  getFetcher<Blob>(getRepairsByVehicleAgeTimelineExportRequestURI(args), {
    responseType: "blob",
  });

// Associated signal events
export interface RepairAssociatedSignalEvent {
  IPTV: number;
  numAssociatedRepairs: number;
  numAssociatedVehicles: number;
  percentRepairsWithPreceding30DaysSignalEvent: number;
  signalEventDescription: string | null;
  signalEventID: string;
  totalEventOccurrences: number;
  associationStrength: number | null;
}

export interface RepairAssociatedSignalEventsRequest
  extends APIPaginatedRequest,
    RepairFiltersRequest {
  signalEventsTimeWindow?: number;
}

export const listAssociatedSignalEventsRequestURI = (
  params: RepairAssociatedSignalEventsRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([REPAIRS_BASE_ROUTE, "associatedSignalEvents"]),
    params,
  });
