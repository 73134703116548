import { SortBy } from "shared/types";

import {
  ASSOCIATED_SIGNAL_EVENTS_TAB_KEY,
  ASSOCIATED_SIGNAL_EVENTS_TAB_TITLE,
  ASSOCIATED_VEHICLES_TAB_KEY,
  ASSOCIATED_VEHICLES_TAB_TITLE,
  BY_VEHICLE_AGE_TAB_TITLE,
  BY_VEHICLES_AGE_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_KEY,
  TOP_CONTRIBUTORS_TAB_TITLE,
} from "pages/constants";

export const CLAIMS_TAB_KEY = "claims";
const CLAIMS_TAB_TITLE = "Claims";

export const CLAIMS_FILTER_LABEL = "Claim Filters";

export const PAGE_TITLE = "Claim Analytics";

export const CLAIMS_PAGE_KEY = "clAnClaimState";
export const CLAIM_ID_QUERY_KEY = "claimId";
export const CLAIM_LABEL_QUERY_KEY = "claimExternalId";
export const VEHICLES_PAGE_KEY = "clAnVehicleState";

export const CLAIM_ANALYTICS_TABS = {
  [CLAIMS_TAB_KEY]: CLAIMS_TAB_TITLE,
  [BY_VEHICLES_AGE_TAB_KEY]: BY_VEHICLE_AGE_TAB_TITLE,
  [TOP_CONTRIBUTORS_TAB_KEY]: TOP_CONTRIBUTORS_TAB_TITLE,
  [ASSOCIATED_VEHICLES_TAB_KEY]: ASSOCIATED_VEHICLES_TAB_TITLE,
  [ASSOCIATED_SIGNAL_EVENTS_TAB_KEY]: ASSOCIATED_SIGNAL_EVENTS_TAB_TITLE,
};

export const CLAIMS_TABLE_PAGE_KEY = "clAn-claimsTable";
export const CLAIMS_TABLE_DEFAULT_SORT: SortBy = { date: "desc" };
