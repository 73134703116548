import {
  alertDefinitionsLoadDataFunc,
  calculatedAttributesLoadDataFunc,
  claimsLoadDataFunc,
  customRecordsLoadDataFunc,
  customSignalEventDefinitionsLoadDataFunc,
  dealersLoadDataFunc,
  failureModeEventLoadDataFunc,
  failureModeLoadDataFunc,
  failureModeRiskModelPredictionLoadDataFunc,
  failureModesLoadDataFunc,
  groupsLoadDataFunc,
  inspectionsLoadDataFunc,
  issueGroupNameLoadDataFunc,
  issuePromotedFromLoadDataFunc,
  issueSeverityLoadDataFunc,
  issuesLoadDataFunc,
  issueStatusLoadDataFunc,
  loadSensorsFunc,
  loadTenantsFunc,
  partsLoadDataFunc,
  repairPartsLoadDataFunc,
  repairsLoadDataFunc,
  riskModelPredictionLoadDataFunc,
  servicePlansLoadDataFunc,
  serviceRecommendationsLoadDataFunc,
  serviceRecordsLoadDataFunc,
  signalEventsOccurrencesLoadDataFunc,
  signalEventsValuesLoadDataFunc,
  suggestedIssueGroupNameLoadDataFunc,
  suggestedIssuesLoadDataFunc,
  suggestedIssueStatusLoadDataFunc,
  transformInitialSelectedCollection,
  transformInitialSelectedFailureMode,
  transformInitialSelectedGroup,
  transformInitialSelectedIssues,
  transformInitialSelectedSensors,
  transformInitialSelectedTransportCategory,
  transportCategoriesLoadDataFunc,
  vehicleCollectionsLoadDataFunc,
  vehicleCollectionsValuesLoadDataFunc,
  vehicleECUsLoadDataFunc,
  vehicleLoadDataFunc,
  vehicleOptionsLoadDataFunc,
  vehicleRiskModelPredictionLoadDataFunc,
  vehicleTagsLoadDataFunc,
  workOrderLoadDataFunc,
} from "shared/api/filter.utils";

import CollectionDescriptionTooltip from "pages/Collections/CollectionDescriptionTooltip";
import { getPopulationKeyValuePairs } from "pages/Issues/utils";

import { FilterSchemaItem } from "features/ui/Filters/types";
import { DataType } from "features/ui/Table/TableBodyCell/types";

import { APIListValuesRequest } from "./api/api";

export const VEHICLE_COLLECTION_FILTER_KEY = "collection";

export const VEHICLES_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: vehicleLoadDataFunc,
  ...otherProps,
});

export const VEHICLE_TAGS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: vehicleTagsLoadDataFunc,
  ...otherProps,
});

export const GROUPS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: groupsLoadDataFunc,
  ...otherProps,
});

export const WORK_ORDERS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: workOrderLoadDataFunc,
  ...otherProps,
});

export const SUGGESTED_ISSUE_GROUP_NAME_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  ...otherProps,
  fieldName: "assignedGroupID",
  loadOptionsFunc: suggestedIssueGroupNameLoadDataFunc,
  transformInitialSelectedFunc: transformInitialSelectedGroup,
});

export const SUGGESTED_ISSUE_ISSUE_NAME_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  ...otherProps,
  loadOptionsFunc: issuePromotedFromLoadDataFunc,
  transformInitialSelectedFunc: transformInitialSelectedIssues,
});

export const SUGGESTED_ISSUE_STATUS_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  ...otherProps,
  fieldName: "statusObj.value",
  loadOptionsFunc: suggestedIssueStatusLoadDataFunc,
});

export const ISSUE_STATUS_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  ...otherProps,
  fieldName: "statusObj.value",
  loadOptionsFunc: issueStatusLoadDataFunc,
});

export const ISSUE_SEVERITY_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  ...otherProps,
  fieldName: "severityObj.value",
  loadOptionsFunc: issueSeverityLoadDataFunc,
});

export const ISSUE_GROUP_NAME_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  ...otherProps,
  fieldName: "assignedGroupID",
  loadOptionsFunc: issueGroupNameLoadDataFunc,
  transformInitialSelectedFunc: transformInitialSelectedGroup,
});

export const SERVICE_PLANS_GENERIC_FILTER = ({
  label,
  fieldName,
  search = false,
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  label,
  fieldName,
  search,
  loadOptionsFunc: servicePlansLoadDataFunc,
  ...otherProps,
});

export const SERVICE_RECOMMENDATIONS_GENERIC_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  loadOptionsFunc: serviceRecommendationsLoadDataFunc,
  ...otherProps,
});

export const SERVICE_RECORDS_GENERIC_FILTER = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  loadOptionsFunc: serviceRecordsLoadDataFunc,
  ...otherProps,
});

export const CLAIMS_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: claimsLoadDataFunc,
});

export const INSPECTIONS_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: inspectionsLoadDataFunc,
});

export const REPAIRS_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: repairsLoadDataFunc,
});

export const CUSTOM_RECORDS_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: customRecordsLoadDataFunc,
});

export const FAILURE_MODES_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: failureModesLoadDataFunc,
});

export const VEHICLES_ECUS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: vehicleECUsLoadDataFunc,
  ...otherProps,
  disableFiltering: true,
});

export const VEHICLES_OPTIONS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: vehicleOptionsLoadDataFunc,
  ...otherProps,
  disableFiltering: true,
});

export const TENANTS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: loadTenantsFunc,
  ...otherProps,
});

export const SIGNAL_EVENT_OCCURRENCES_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: signalEventsOccurrencesLoadDataFunc,
  disableFiltering: true,
});

export const SENSOR_READINGS_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: signalEventsOccurrencesLoadDataFunc,
  disableFiltering: true,
});

export const SENSOR_ID_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: loadSensorsFunc,
  transformInitialSelectedFunc: transformInitialSelectedSensors,
  disableFiltering: true,
});

export const ISSUES_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: issuesLoadDataFunc,
  ...otherProps,
});

export const DEALERS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: dealersLoadDataFunc,
  ...otherProps,
});

export const SUGGESTED_ISSUE_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: suggestedIssuesLoadDataFunc,
  ...otherProps,
});

export const ISSUE_CLAIMS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  ...otherProps,
});

export const ALERT_DEFINITIONS_GENERIC_FILTER = ({
  search = false,
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  search,
  loadOptionsFunc: alertDefinitionsLoadDataFunc,
  ...otherProps,
});

export const CUSTOM_SIGNAL_EVENT_DEFINITIONS_GENERIC_FILTER = ({
  search = false,
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  search,
  loadOptionsFunc: customSignalEventDefinitionsLoadDataFunc,
  ...otherProps,
});

export const VEHICLE_COLLECTIONS_CREATED_BY_FILTER: FilterSchemaItem = {
  label: "Created By",
  fieldName: "createdBy",
  loadOptionsFunc: vehicleCollectionsValuesLoadDataFunc,
};

export const VEHICLES_COLLECTION_FILTER: FilterSchemaItem = {
  label: "Vehicle Collection",
  fieldName: VEHICLE_COLLECTION_FILTER_KEY,
  search: true,
  loadDataOnOpen: true,
  loadOptionsFunc: vehicleCollectionsLoadDataFunc,
  transformInitialSelectedFunc: transformInitialSelectedCollection,
  multiple: false,
  description: <CollectionDescriptionTooltip />,
};

export const TRANSPORT_CATEGORY_FILTER: FilterSchemaItem = {
  label: "Transport Category",
  fieldName: "transportCategoryID",
  loadOptionsFunc: transportCategoriesLoadDataFunc,
  transformInitialSelectedFunc: transformInitialSelectedTransportCategory,
  disableFiltering: true,
};

export const EVENT_TYPE_FILTER = (filter?: string): FilterSchemaItem => ({
  label: "Type",
  fieldName: "type",
  loadOptionsFunc: (args: APIListValuesRequest) =>
    serviceRecordsLoadDataFunc({ ...args, filter }),
});

export const DATE_FILTER_GENERIC = ({
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  ...otherProps,
  filterType: "date",
  disableSelectFilters: true,
});

export const SIGNAL_EVENTS_GENERIC_FILTER = ({
  ...props
}: FilterSchemaItem): FilterSchemaItem => ({
  loadOptionsFunc: signalEventsValuesLoadDataFunc,
  ...props,
});

export const ISSUE_SIGNAL_EVENTS_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  ...otherProps,
});

export const ISSUE_POPULATIONS_FILTER: FilterSchemaItem = {
  filterType: "string",
  loadOptionsFunc: () => Promise.all(getPopulationKeyValuePairs()),
  label: "Population(s)",
  fieldName: "populations",
};

export const GENERIC_FILTER_WITHOUT_SELECT = ({
  ...props
}: FilterSchemaItem): FilterSchemaItem => ({
  ...props,
  disableSelectFilters: true,
  disableFiltering: true,
});

export const CALCULATED_ATTRIBUTES_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: calculatedAttributesLoadDataFunc,
  ...otherProps,
});

export const PARTS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: partsLoadDataFunc,
  ...otherProps,
});

export const REPAIR_PARTS_GENERIC_FILTER = ({
  filterType = "string",
  ...otherProps
}: FilterSchemaItem): FilterSchemaItem => ({
  filterType,
  loadOptionsFunc: repairPartsLoadDataFunc,
  ...otherProps,
});

export const CREATED_AT_FILTER: FilterSchemaItem = {
  label: "Created",
  fieldName: "createdAt",
  filterType: "date",
  filterDataType: DataType.DATE_WITH_TIME_UTC,
  disableSelectFilters: true,
};

export const UPDATED_AT_FILTER: FilterSchemaItem = {
  label: "Update",
  fieldName: "updatedAt",
  filterType: "date",
  filterDataType: DataType.DATE_WITH_TIME_UTC,
  disableSelectFilters: true,
};

export const FAILURE_MODE_EVENTS_GENERIC_FILTER = (
  id: string,
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: (args: APIListValuesRequest) =>
    failureModeEventLoadDataFunc({ id, ...args }),
});

export const FAILURE_MODE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER = (
  id: string,
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: (args: APIListValuesRequest) =>
    failureModeRiskModelPredictionLoadDataFunc({ id, ...args }),
});

export const VEHICLE_RISK_MODEL_PREDICTIONS_GENERIC_FILTER = (
  vin: string,
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: (args: APIListValuesRequest) =>
    vehicleRiskModelPredictionLoadDataFunc({ vin, ...args }),
});

export const RISK_MODEL_PREDICTIONS_GENERIC_FILTER = (
  props: FilterSchemaItem
): FilterSchemaItem => ({
  ...props,
  loadOptionsFunc: (args: APIListValuesRequest) =>
    riskModelPredictionLoadDataFunc(args),
});

export const FAILURE_MODE_FILTER: FilterSchemaItem = {
  label: "Failure Mode",
  fieldName: "failureModeID",
  loadOptionsFunc: failureModeLoadDataFunc,
  transformInitialSelectedFunc: transformInitialSelectedFailureMode,
  disableFiltering: true,
};
