import { useEffect, useState } from "react";
import classNames from "classnames";

import TableBodyCell from "features/ui/Table/TableBodyCell";
import TableHeaderCell from "features/ui/Table/TableHeaderCell";
import TableLoader from "features/ui/Table/TableLoader";

import styles from "./Table.module.css";
import { RowClickParams, TableProps } from "./types";
import {
  curryIfDefined,
  getRowId,
  getValueByAccessor,
  isSelectedRow,
  tableBodyCellKey,
  toggleSort,
} from "./utils";

const STICKY_TABLE_MIN_ITEM_COUNT = 10;

const Table = ({
  data,
  schema,
  isLoading = false,
  loadingRows = 6,
  dense = false,
  sortBy,
  onSort,
  filters,
  filtersInitialized,
  onFiltersReset,
  onFilterChange,
  onRowSelect,
  multiSelect = false,
  pageKey,
  extraHeaderRowContent,
  staticFilters,
  hideStaticFiltersColumns = false,
  highlightedRowIndex,
  onRowHover,
  onTableMouseLeave,
  scrollHeight,
  stickyFirstColumn,
  disableSort = false,
  testId = "",
}: TableProps) => {
  const [selectedRowIds, setSelectedRowIds] = useState<Record<string, boolean>>(
    {}
  );

  // whenever data changes, set the selected to the first row (but only when onRowSelect is present)
  useEffect(() => {
    if (!onRowSelect || !data || !data.length) return;

    const defaultSelectedIds = { [getRowId(data[0], 0)]: true };
    setSelectedRowIds(defaultSelectedIds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onRowClickHandler = ({ rowData, currentRowId }: RowClickParams) => {
    // enable selectable rows only when onRowSelect prop is not empty
    if (!onRowSelect) return;

    if (multiSelect) {
      const currentIdIn = selectedRowIds[currentRowId];
      setSelectedRowIds({
        ...selectedRowIds,
        [currentRowId]: Boolean(!currentIdIn),
      });
    } else {
      setSelectedRowIds({ [currentRowId]: true });
    }

    onRowSelect(rowData);
  };

  // filter out columns that are part of staticFilters if hideStaticFiltersColumns = true
  const filteredSchema = schema
    .filter((schemaEntry) => !schemaEntry.hideInTable)
    .filter(({ accessor }) => {
      if (
        staticFilters &&
        staticFilters.length > 0 &&
        hideStaticFiltersColumns &&
        staticFilters.find(({ name }) => name === accessor)
      ) {
        return false;
      }

      return true;
    });

  const headers = filteredSchema.map((schemaEntry, idx) => {
    const { accessor, limitedWidthClass } = schemaEntry;

    return (
      <TableHeaderCell
        key={accessor}
        idx={idx}
        pageKey={pageKey}
        schemaEntry={schemaEntry}
        sort={sortBy?.[accessor]}
        onSort={curryIfDefined(onSort, {
          accessor,
          sort: toggleSort(sortBy?.[accessor]),
        })}
        onFiltersReset={onFiltersReset}
        onFilterChange={onFilterChange}
        filtersInitialized={filtersInitialized}
        activeFilters={filters}
        staticFilters={staticFilters}
        dense={dense}
        stickyFirstColumn={stickyFirstColumn}
        limitedWidthClass={limitedWidthClass}
        disableSort={disableSort}
      />
    );
  });

  return (
    <div
      className={styles["table-wrapper"]}
      style={(scrollHeight && { maxHeight: scrollHeight }) || undefined}
      data-testid={testId || "table"}
    >
      <table className={classNames(styles["table"])}>
        <thead
          className={classNames(styles["table-head"], {
            [styles["table-head-sticky"]]:
              scrollHeight !== undefined &&
              data &&
              // filters inside sticky header row do not work as there in not enough space
              // we make this table sticky only if there are more than 10 items
              data.length > STICKY_TABLE_MIN_ITEM_COUNT,
          })}
        >
          {extraHeaderRowContent && <tr>{extraHeaderRowContent}</tr>}
          <tr>{headers}</tr>
        </thead>
        {/*
            Prevent vertical scrollbar inside table when no data present. If tbody stays, vertical scroll appears.
            See https://stackoverflow.com/questions/6421966/css-overflow-x-visible-and-overflow-y-hidden-causing-scrollbar-issue also.
          */}
        {(isLoading || (data && data.length > 0)) && (
          <tbody
            className={styles["table-body"]}
            onMouseLeave={() => onTableMouseLeave && onTableMouseLeave()}
          >
            {(!isLoading &&
              data?.map((d, index) => {
                const currentRowId = getRowId(d, index);
                const isSelected = isSelectedRow(currentRowId, selectedRowIds);

                return (
                  <tr
                    key={currentRowId}
                    // we need group here so that bg change (group-hover) applies to sticky cell too
                    className={classNames("group", {
                      // if onRowSelect callback prop exists, enable selectable rows
                      "hover:bg-gray-50": !onRowSelect,
                      "cursor-pointer": onRowSelect,
                      "bg-blue-100 hover:bg-blue-100": isSelected,
                      "bg-gray-100": highlightedRowIndex === index,
                      [styles["table-row-sticky"]]: scrollHeight !== undefined,
                    })}
                    onClick={() =>
                      onRowClickHandler({ rowData: d, currentRowId })
                    }
                    onMouseEnter={() => {
                      onRowHover && onRowHover(index);
                    }}
                  >
                    {filteredSchema.map((schemaEntry, idx) => {
                      const {
                        accessor,
                        onCellClick,
                        label,
                        ...otherSchemaProps
                      } = schemaEntry;

                      const value = getValueByAccessor(accessor, d);

                      return (
                        <TableBodyCell
                          idx={idx}
                          key={tableBodyCellKey(d, accessor) + idx}
                          value={value}
                          dense={dense}
                          stickyFirstColumn={stickyFirstColumn}
                          partOfSelectableRow={Boolean(onRowSelect)}
                          itsRowIsSelected={isSelected}
                          // If click is not defined, pass undefined so that
                          // TableCell can detect that and style accordingly
                          onClick={curryIfDefined(onCellClick, {
                            schemaEntry,
                            value,
                            row: d,
                          })}
                          label={label}
                          {...otherSchemaProps}
                        />
                      );
                    })}
                  </tr>
                );
              })) || (
              <TableLoader
                dense={dense}
                rows={loadingRows}
                cols={schema.length}
              />
            )}
          </tbody>
        )}
      </table>
    </div>
  );
};

export default Table;
