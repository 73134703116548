import { differenceInDays, endOfDay, startOfDay } from "date-fns";
import { format, toDate } from "date-fns-tz";
import qs from "qs";
import { XAxisProps } from "recharts";

import {
  Sensor,
  SensorReadingsTimelineAggregation,
  SensorReadingsTimelineGrouping,
  sensorReadingsTimelineGroupingOptions,
  SensorReadingTimelineEntry,
} from "shared/api/sensors/api";
import { ServiceRecord } from "shared/api/serviceRecords/api";
import { SignalEventOccurrencesVINTimelineBucket } from "shared/api/signalEvents/api";
import { formatAPIDate } from "shared/api/utils";
import { Vehicle } from "shared/api/vehicles/api";
import {
  API_DATE_FORMAT_W_TIME,
  DATE_WITH_TIME_FORMAT,
  SHORT_DATE_FORMAT,
} from "shared/constants";
import {
  datetimeToTimestamp,
  extractDateFromDateTime,
  randomID,
} from "shared/utils";

import {
  SIGNAL_EVENTS_PAGE_KEY,
  VEHICLES_PAGE_KEY,
} from "pages/SignalEventsAnalytics/constants";
import {
  VIN_VIEW_EVENTS_TIMELINE_TAB_KEY,
  VIN_VIEW_EVENTS_TIMELINE_USE_DEFAULT_TO_DATE_KEY,
} from "pages/VINView/constants";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import {
  MANUFACTURE_DATE_LINE_COLOR,
  STARTED_DRIVING_AT_COLOR,
  TIMESTAMP_X_AXIS_KEY,
} from "features/ui/charts/constants";
import { VehicleReferenceLineType } from "features/ui/charts/ScatterChart/ScatterChart";
import { DataElement } from "features/ui/charts/types";
import { trimAxisLabel } from "features/ui/charts/utils";
import { DEFAULT_FILTER_BUILDER_STATE } from "features/ui/Filters/FilterBuilder/constants";
import {
  FilterGroupState,
  FilterRowState,
  FilterRowStateNotNull,
} from "features/ui/Filters/FilterBuilder/types";
import {
  getFiltersQuery,
  updateOrAddRowFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import {
  FilterOperator,
  PageChartSettingsState,
} from "features/ui/Filters/types";
import {
  getFiltersKey,
  getPageKeyWithVersion,
  getStateFromLocalStorage,
  persistToLocalStorage,
} from "features/ui/Filters/utils";
import { Option } from "features/ui/Select";
import { DataType } from "features/ui/Table/TableBodyCell/types";

import { routes } from "services/routes";

import {
  CHART_OPTIONS_KEY,
  MAX_SENSOR_Y_AXIS_LABEL_LENGTH,
  UNAVAILABLE_TEXT_WIDTH,
} from "./constants";

interface SignalEventPoint {
  ts: number;
  signal: string;
  count: number;
  description: string;
}

export interface SignalEventDot {
  signal: string;
  ts: string;
  count: number;
}

const getTs = (date: string) => toDate(date).getTime();
export const processEventSignals = (
  events: SignalEventOccurrencesVINTimelineBucket[],
  shownSignalEvents: Set<string>
): SignalEventPoint[] =>
  events
    ?.filter(({ signalEventID }) => shownSignalEvents.has(signalEventID))
    .map((signal) => ({
      ts: getTs(signal.date),
      signal: signal.signalEventID,
      count: signal.occurrences,
      description: signal.description || "",
    }));

export interface ProcessedVehicleEvent {
  x: number;
  key: string;
  data: ServiceRecord;
}

export const processVehicleServiceRecords = (
  serviceRecords: ServiceRecord[] = []
): ProcessedVehicleEvent[] =>
  serviceRecords.map((serviceRecord) => ({
    x: toDate(extractDateFromDateTime(serviceRecord.date)).getTime(),
    key: serviceRecord.ID,
    data: serviceRecord,
  }));

export const processVehicleHistoryEvents = (
  vehicle: Vehicle | null | undefined
) => {
  if (!vehicle) {
    return [];
  }

  const vehicleEvents: VehicleReferenceLineType[] = [];

  const { vehicleStartedDrivingAt, vehicleManufacturedAt } = vehicle;

  if (vehicleStartedDrivingAt) {
    vehicleEvents.push({
      x: toDate(vehicleStartedDrivingAt).getTime(),
      color: STARTED_DRIVING_AT_COLOR,
      key: "vehicleStartedDrivingAt",
    });
  }

  if (vehicleManufacturedAt) {
    vehicleEvents.push({
      x: toDate(vehicleManufacturedAt).getTime(),
      color: MANUFACTURE_DATE_LINE_COLOR,
      key: "vehicleManufacturedAt",
    });
  }

  return vehicleEvents;
};

export const prepareSensorsChartData = (
  data: SensorReadingTimelineEntry[],
  sensors: Sensor[]
): DataElement[] =>
  data.reduce(
    (
      accumulator,
      { time, sensorID, value, count, ...other }: SensorReadingTimelineEntry
    ) => {
      const xAxisKey = toDate(time).getTime();

      const sensorObj = sensors?.find(({ ID }) => ID === sensorID);

      accumulator.push({
        [TIMESTAMP_X_AXIS_KEY]: xAxisKey,
        // if grouping is "daily" and the current sensor is "state" sensor, then we need to display count instead of value on the chart
        // if the grouping is "none" then "state" sensor would fall into prepareStateSensorsChartData anyway
        [`value-${sensorID}`]: sensorObj?.type === "state" ? count : value,
        ...sensorObj,
        ...other,
      });

      return accumulator;
    },
    [] as Record<string, any>[]
  );

interface StateSensorChartData {
  sensorID: string;
  data: {
    ts: number;
    value: string;
    label: string;
    count: string;
    sensorID: string;
  }[];
}

const getValueLabelForStateSensor = (
  value: number | string,
  stateSensors: Sensor[],
  currentSensorID: string
): string => {
  const sensorObj = stateSensors?.find(({ ID }) => ID === currentSensorID);
  const valueLabels = sensorObj?.valueLabels;

  if (!valueLabels) {
    return value.toString();
  }

  return valueLabels[value] || value.toString();
};

export const prepareStateSensorsChartData = (
  data: SensorReadingTimelineEntry[],
  endDate: string,
  stateSensors: Sensor[],
  dataKeyStateDurationSensors: "count" | "value"
): DataElement[] => {
  const result: StateSensorChartData[] = [];
  const sensorDataMap: Record<string, any[]> = {};
  const lastZero: Record<string, boolean> = {};

  data.sort(
    (a, b) => datetimeToTimestamp(a.time) - datetimeToTimestamp(b.time)
  );

  data.forEach((item) => {
    const currentDate = datetimeToTimestamp(item.time);
    const sensorDisplayName =
      stateSensors.find(({ ID }) => ID === item.sensorID)?.displayName ||
      item.sensorID;

    if (!sensorDataMap[sensorDisplayName]) {
      sensorDataMap[sensorDisplayName] = [];
      lastZero[sensorDisplayName] = true;
    }

    const itemValue = item[dataKeyStateDurationSensors];

    const sensorLabel = getValueLabelForStateSensor(
      itemValue,
      stateSensors,
      item.sensorID
    );

    if (itemValue > 0) {
      if (lastZero[sensorDisplayName]) {
        result.push({
          sensorID: sensorDisplayName,
          data: [
            {
              ts: currentDate,
              value: sensorDisplayName,
              count: sensorDisplayName,
              label: sensorLabel,
              sensorID: item.sensorID,
            },
          ],
        });
        sensorDataMap[sensorDisplayName] = result[result.length - 1].data;
      } else {
        sensorDataMap[sensorDisplayName].push({
          ts: currentDate,
          value: sensorDisplayName,
          count: sensorDisplayName,
          sensorID: item.sensorID,
          label: getValueLabelForStateSensor(0, stateSensors, item.sensorID),
        });
      }

      lastZero[sensorDisplayName] = false;
    } else {
      if (!lastZero[sensorDisplayName]) {
        sensorDataMap[sensorDisplayName].push({
          ts: currentDate,
          value: sensorDisplayName,
          count: sensorDisplayName,
          sensorID: item.sensorID,
          label: sensorLabel,
        });
      }

      lastZero[sensorDisplayName] = true;
    }
  });

  // Only keep the first and last entry in each group
  result.forEach((group) => {
    if (group.data.length > 2) {
      group.data = [group.data[0], group.data[group.data.length - 1]];
    }
  });

  // Add an extra entry at the end of the date range for all sensorIDs if the last group has a single positive entry
  result.forEach((group) => {
    const { sensorID, data } = group;

    if (data.length === 1) {
      // sensorID in the group above is not the ID anymore - at this point it's already the displayName which we use in the chart
      const currentSensorID = data[0].sensorID;
      const valueLabel = getValueLabelForStateSensor(
        1,
        stateSensors,
        currentSensorID
      );
      group.data.push({
        ts: datetimeToTimestamp(endDate),
        value: sensorID,
        count: sensorID,
        sensorID: currentSensorID,
        label: valueLabel,
      });
    }
  });

  return result;
};

export const partitionArray = <T>(
  array: T[],
  predicate: (s: T) => boolean
): [T[], T[]] => {
  const isTrue: T[] = [];
  const isFalse: T[] = [];

  array.forEach((value) => {
    if (predicate(value)) {
      isTrue.push(value);
    } else {
      isFalse.push(value);
    }
  });

  return [isTrue, isFalse];
};

export const getPointValueSensorFromID = (
  sensorID: string,
  pointValueSensors?: Sensor[]
) => pointValueSensors?.find(({ ID }) => ID === sensorID);

export const getLabelForPointValueSensor = (
  grouping: SensorReadingsTimelineGrouping,
  aggregation: SensorReadingsTimelineAggregation,
  sensor?: Sensor
): string | undefined => {
  if (!sensor) return undefined;

  const { displayName, type } = sensor;

  return grouping === "none"
    ? displayName
    : type === "state"
      ? `Count (${displayName})`
      : `${aggregation.toUpperCase()} (${displayName})`;
};

export const getSharedXAxisProps = (
  startDate: string,
  endDate: string,
  aggregationWindow: SensorReadingsTimelineGrouping
): XAxisProps => {
  const DAYS_THRESHOLD_TO_SHOW_TIME = 7;

  const daysDiff = Math.abs(
    differenceInDays(toDate(startDate), toDate(endDate))
  );

  const xAxisDateFormat =
    aggregationWindow === "day" || daysDiff > DAYS_THRESHOLD_TO_SHOW_TIME
      ? SHORT_DATE_FORMAT
      : DATE_WITH_TIME_FORMAT;

  const endDateEndOfDay = toDate(endDate).setHours(23, 59, 59);
  const endDateToUse = aggregationWindow === "day" ? endDate : endDateEndOfDay;

  return {
    domain: [toDate(startDate).getTime(), toDate(endDateToUse).getTime()],
    tickFormatter: (value: number) => format(value, xAxisDateFormat),
    allowDataOverflow: true,
  };
};

export const buildYAxisLabel = (
  sensorIndex: number,
  grouping: SensorReadingsTimelineGrouping,
  aggregation: SensorReadingsTimelineAggregation,
  pointValueSensors?: Sensor[]
) => {
  const sensorID =
    pointValueSensors && pointValueSensors.length > sensorIndex
      ? pointValueSensors[sensorIndex].ID
      : undefined;
  const yAxisLabel = sensorID
    ? getLabelForPointValueSensor(
        grouping,
        aggregation,
        pointValueSensors?.find(({ ID }) => ID === sensorID)
      )
    : undefined;
  const trimmedYAxisLabel = trimAxisLabel(
    yAxisLabel,
    MAX_SENSOR_Y_AXIS_LABEL_LENGTH
  );

  const sensorWithUnit = pointValueSensors?.find(
    (x) => x.ID === sensorID && x.unit
  );

  return sensorWithUnit
    ? `${trimmedYAxisLabel} [${sensorWithUnit.unit}]`
    : trimmedYAxisLabel;
};

export const getInitialChartSettings = (
  chartSettings: PageChartSettingsState | undefined,
  defaultActions: SelectedChartOptions<Option>[]
): SelectedChartOptions<Option>[] => {
  if (!chartSettings) {
    return defaultActions;
  }

  const tabSettings = chartSettings[VIN_VIEW_EVENTS_TIMELINE_TAB_KEY];
  if (!tabSettings) {
    return defaultActions;
  }

  const individualSettings = tabSettings[CHART_OPTIONS_KEY];
  if (!individualSettings) {
    return defaultActions;
  }

  return individualSettings;
};

export const getAggregationWindowAsSensorReadingsTimelineGrouping = (
  selectedAggregationWindow: SelectedChartOptions<Option>
): SensorReadingsTimelineGrouping => {
  const optionId: SensorReadingsTimelineGrouping = String(
    selectedAggregationWindow.optionId
  ) as SensorReadingsTimelineGrouping;
  if (sensorReadingsTimelineGroupingOptions.includes(optionId)) {
    return optionId;
  }

  return sensorReadingsTimelineGroupingOptions[0];
};

export const getTextWidth = (text: string, font: string): number => {
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");

  if (context) {
    context.font = font;

    const metrics = context.measureText(text);

    return metrics.width;
  }

  return UNAVAILABLE_TEXT_WIDTH;
};

interface DotTimeFilter {
  startDate: Date;
  endDate: Date;
}

export const getDotTimeFilter = (
  selectedDot: SignalEventDot | null,
  grouping: SensorReadingsTimelineGrouping
): DotTimeFilter | null => {
  if (!selectedDot) return null;

  const date = new Date(selectedDot.ts);

  switch (grouping) {
    case "day":
      const startOfDay = new Date(date.setHours(0, 0, 0, 0));
      const endOfDay = new Date(date.setHours(23, 59, 59, 999));

      return { startDate: startOfDay, endDate: endOfDay };

    case "hour":
      const startOfHour = new Date(date.setMinutes(0, 0, 0));
      const endOfHour = new Date(date.setMinutes(59, 59, 999));

      return { startDate: startOfHour, endDate: endOfHour };

    case "minute":
      const startOfMinute = new Date(date.setSeconds(0, 0));
      const endOfMinute = new Date(date.setSeconds(59, 999));

      return { startDate: startOfMinute, endDate: endOfMinute };

    case "none":
      return { startDate: date, endDate: date };

    default:
      return null;
  }
};

export const createDotFilterGroupState = (
  selectedDot: SignalEventDot | null,
  dotTimeFilter: DotTimeFilter | null
): FilterGroupState | undefined => {
  if (!selectedDot) return undefined;

  return {
    id: `group-${randomID()}`,
    type: "group",
    anyAll: "all",
    children: [
      {
        id: `row-${randomID()}`,
        type: "row",
        attribute: "signalEventID",
        operator: FilterOperator.IN,
        values: [selectedDot.signal],
      },
      ...(dotTimeFilter && dotTimeFilter.startDate && dotTimeFilter.endDate
        ? [
            {
              id: `row-${randomID()}`,
              type: "row",
              attribute: "recordedAt",
              operator: FilterOperator.BETWEEN,
              values: [
                format(dotTimeFilter.startDate, API_DATE_FORMAT_W_TIME),
                format(dotTimeFilter.endDate, API_DATE_FORMAT_W_TIME),
              ],
            } as FilterRowState,
          ]
        : []),
    ],
  };
};

export const onExploreInSEAnalyticsActionClick = (
  selectedDot: SignalEventDot | null
): void => {
  if (!selectedDot) {
    return;
  }

  const vehicleFilterKey = getFiltersKey(VEHICLES_PAGE_KEY);
  const signalEventsFilterKey = getFiltersKey(SIGNAL_EVENTS_PAGE_KEY);

  const seFilter: FilterRowState = {
    type: "row",
    id: "row-1",
    attribute: "signalEventID",
    operator: FilterOperator.IN,
    values: [selectedDot.signal],
  };

  const seFilters: FilterGroupState = {
    type: "group",
    id: "group-1",
    anyAll: "all",
    children: [seFilter],
  };

  const url = `${routes.signalEventAnalytics}?${qs.stringify({
    [vehicleFilterKey]: getFiltersQuery(DEFAULT_FILTER_BUILDER_STATE),
    [signalEventsFilterKey]: getFiltersQuery(seFilters),
  })}`;

  // open in new tab
  window.open(url, "_blank")?.focus();
};

export const updateLSFilters = (
  fromDate: Date,
  toDate: Date | undefined,
  pageKey: string,
  vin: string
) => {
  // toDate here is set when we have it stored in the local storage
  // in this case we want to update the filters in LS if they exist
  // because we want to overwrite them with new values
  // this can happen in some cases when clicking on VINEventTimelineDateLink
  if (!toDate) {
    return;
  }

  const versionedPageKey = getPageKeyWithVersion(pageKey);

  const rowToUpdate: FilterRowStateNotNull = {
    type: "row",
    id: `row-${randomID()}`,
    attribute: "recordedAt",
    operator: FilterOperator.BETWEEN,
    values: [
      formatAPIDate(
        startOfDay(fromDate).toString(),
        DataType.DATE_WITH_TIME_NO_TZ
      ),
      formatAPIDate(endOfDay(toDate).toString(), DataType.DATE_WITH_TIME_NO_TZ),
    ],
  };

  const filterSortState = getStateFromLocalStorage(versionedPageKey);

  // we only update LS when filters exist - strictly as overwrite
  if (!filterSortState || !filterSortState?.filters) {
    return;
  }

  const newFilters = updateOrAddRowFilterGroupState(
    filterSortState?.filters,
    rowToUpdate
  );

  persistToLocalStorage(
    { ...filterSortState, filters: newFilters },
    versionedPageKey
  );

  localStorage.removeItem(
    VIN_VIEW_EVENTS_TIMELINE_USE_DEFAULT_TO_DATE_KEY(vin)
  );
};
