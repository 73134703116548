import { useLDClient } from "launchdarkly-react-client-sdk";

import * as config from "config/config";

import Row from "./Row";

const LD_URL = "https://app.launchdarkly.com/projects/default/flags";

const LDFlags = () => {
  const client = useLDClient();
  const flags = client?.allFlags();

  const {
    featureFlags: { launchDarklyClientId },
  } = config.get();

  return (
    <div>
      <div className="mb-3 text-gray-400">
        LD Client ID: {launchDarklyClientId}
      </div>
      {!flags && (
        <div className="text-red-400 text-lg">Could not retrieve flags.</div>
      )}
      {flags && (
        <table>
          <tbody>
            <tr>
              <th className="text-left">Flag</th>
              <th>Value</th>
            </tr>
            {Object.keys(flags).map((flagKey) => (
              <Row
                key={flagKey}
                title={flagKey}
                value={flags[flagKey].toString()}
              />
            ))}
          </tbody>
        </table>
      )}
      <div className="mt-3">
        <a
          href={LD_URL}
          className="text-blue-500 hover:text-blue-400"
          target="_blank"
          rel="noreferrer"
        >
          Update Flags on LaunchDarkly*
        </a>
        <div className="text-xs text-gray-400">*Credentials in 1Password.</div>
      </div>
    </div>
  );
};

export default LDFlags;
