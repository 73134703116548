import { Source } from "duck/graph/nodes/utils";
import { MODEL_NAMES, ModelSpec } from "duck/graph/types";

export const GPT4O_MODEL_SPEC: ModelSpec = {
  modelName: MODEL_NAMES.GPT4O,
  temperature: 0,
  streaming: false,
  modelKwargs: {
    seed: 22,
  },
};

export const GPT4OMINI_MODEL_SPEC: ModelSpec = {
  modelName: MODEL_NAMES.GPT4O_MINI,
  temperature: 0,
  streaming: false,
  modelKwargs: {
    seed: 22,
  },
};

export const GPT4OMINI_STREAMING_MODEL_SPEC: ModelSpec = {
  modelName: MODEL_NAMES.GPT4O_MINI,
  temperature: 0,
  streaming: true,
  modelKwargs: {
    seed: 22,
  },
};

export const O3_MINI_MODEL_SPEC: ModelSpec = {
  modelName: MODEL_NAMES.O3_MINI,
  temperature: 0,
  streaming: false,
  modelKwargs: {
    seed: 22,
  },
};

export const LC_ENDPOINT = import.meta.env.VITE_LANGCHAIN_ENDPOINT;
export const LC_API_KEY = import.meta.env.VITE_LANGCHAIN_API_KEY;
export const LC_PROJECT_NAME = import.meta.env.VITE_LANGCHAIN_PROJECT;
export const OPENAI_API_KEY = import.meta.env.VITE_OPENAI_API_KEY;
export const DOCUMENTS_SOURCE = "Confluence";
export const DOCUMENT_RETRIEVAL_K = 5;
export const DOCUMENT_RETRIEVAL_DISTANCE_THRESHOLD = 2;
export const ATTRIBUTE_RETRIEVAL_K = 5;
export const ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD = 2;
export const ATTRIBUTE_SOURCES: Record<Source, string> = {
  [Source.CLAIM]: "v1/claims/attributes",
  [Source.VEHICLE]: "v1/vehicles/attributes",
  [Source.SIGNAL_EVENT_OCCURRENCE]: "v1/signalEvents/occurrences/attributes",
  [Source.VEHICLE_ECU]: "v1/vehicleECUs/attributes",
  [Source.ISSUE]: "v1/issues/attributes",
  [Source.SUGGESTED_ISSUE]: "v1/suggestedIssues/attributes",
  [Source.CUSTOM_RECORD]: "v1/customRecords/attributes",
  [Source.CUSTOM_SIGNAL_EVENTS]: "v1/customSignalEvents/attributes",
  [Source.DEALERS]: "v1/dealers/attributes",
} as const;

export const SCREENCAPTURE_ELEMENT_ID = "duck-screenshot-element";

export const API_MAX_LIMIT = 2000;
