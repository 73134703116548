import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import DuckTranslate from "duck/ui/translate/DuckTranslate";
import { Stack } from "@mui/material";

import { TableCellValue } from "features/ui/Table/TableBodyCell/types";

interface LabelValuePairProps {
  hasNarratives?: boolean;
  alignment?: "left" | "center";
  translate?: boolean;
  schemaEntryLabel: string;
  initialValue: TableCellValue;
  isResizable?: boolean;
}

const DEFAULT_ALIGNMENT = "center";

const LabelValuePair = ({
  hasNarratives = false,
  alignment = DEFAULT_ALIGNMENT,
  translate = false,
  schemaEntryLabel,
  initialValue,
  isResizable = false,
}: LabelValuePairProps) => {
  const [value, setValue] = useState(initialValue);
  const [isMultiLine, setIsMultiLine] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [resizedHeight, setResizedHeight] = useState<number | null>(null);

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    const element = contentRef.current;
    if (element) {
      // Check if content is wider than container or has line breaks
      const originalWhiteSpace = element.style.whiteSpace;
      element.style.whiteSpace = "nowrap";
      const hasWrappedContent = element.scrollWidth > element.clientWidth;
      element.style.whiteSpace = originalWhiteSpace;
      setIsMultiLine(hasWrappedContent);
    }
  }, [value]);

  const shouldBeResizable = isResizable && isMultiLine;

  const handleResize = () => {
    if (contentRef.current) {
      // + 2 pixels, makes it so the elements stays the same
      // height when the resize handle or element is only clicked
      setResizedHeight(contentRef.current.clientHeight + 2);
    }
  };

  return (
    <div
      className={classnames("text-sm", {
        "flex space-x-3 border-b py-1": !hasNarratives && alignment !== "left",
        "grid grid-cols-[180px_auto] gap-x-3 py-2 border-b":
          alignment === "left",
      })}
    >
      <div
        className={classnames(
          {
            "w-1/2 md:text-right": !hasNarratives && alignment !== "left",
          },
          "text-gray-400 text-sm"
        )}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={0}
        >
          <p>{schemaEntryLabel}:</p>
          {translate && typeof initialValue === "string" && (
            <DuckTranslate originalText={initialValue} setValue={setValue} />
          )}
        </Stack>
      </div>
      <div
        ref={contentRef}
        className={classnames("break-words min-h-10", {
          "w-1/2 text-left": !hasNarratives && alignment !== "left",
          "max-h-48 overflow-y-auto mt-1 mb-3 border border-gray-300":
            hasNarratives,
          "px-3 py-2": hasNarratives && typeof value === "string",
          "resize-y overflow-y-auto": shouldBeResizable,
          "max-h-20": shouldBeResizable && !resizedHeight,
          "max-h-[40rem]": shouldBeResizable && resizedHeight,
        })}
        onMouseDown={handleResize}
        style={
          shouldBeResizable && resizedHeight
            ? { height: `${resizedHeight}px` }
            : undefined
        }
      >
        {value}
      </div>
    </div>
  );
};

export default LabelValuePair;
