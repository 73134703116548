import { useEffect, useState } from "react";
import classNames from "classnames";
import { SCREENCAPTURE_ELEMENT_ID } from "duck/graph/constants";
import InstanceManagementSidebarNav from "instanceManagement/SidebarNav";

import { useConfigContext } from "shared/contexts/ConfigContext";
import { useWindowSize } from "shared/hooks";

import ErrorBoundary from "features/layout/ErrorBoundary";
import Feedback from "features/layout/Feedback";
import AppSidebarNav from "features/layout/SidebarNav";

import { RouteConfig } from "services/routesConfig";
import { isMultiTenantInstanceManagementInstance } from "config/config";

interface AppLayoutProps {
  wrapperClasses?: string;
  children: React.ReactElement;
  routeConfig: RouteConfig;
}

const DEFAULT_WRAPPER_CLASSES = "pb-10 px-8 pt-6";
const DEFAULT_EXPANDED_SIDE_NAV = true;
const EXPAND_BREAKPOINT_PX = 1024;

const AppLayout = ({
  children,
  wrapperClasses = DEFAULT_WRAPPER_CLASSES,
  routeConfig,
}: AppLayoutProps) => {
  const {
    pages: { feedbackForm },
  } = useConfigContext();

  const [expanded, setExpanded] = useState(true);
  const { width } = useWindowSize();
  const [defaultExpandedState, setDefaultExpandedState] = useState(
    DEFAULT_EXPANDED_SIDE_NAV
  );

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  /**
   * When screen resize happens, automatically expand/close side nav
   */
  useEffect(() => {
    const isDefaultExpandedState = width
      ? Boolean(width >= EXPAND_BREAKPOINT_PX)
      : DEFAULT_EXPANDED_SIDE_NAV;

    setDefaultExpandedState(isDefaultExpandedState);
    setExpanded(isDefaultExpandedState);
  }, [width]);

  const SidebarNav = isMultiTenantInstanceManagementInstance()
    ? InstanceManagementSidebarNav
    : AppSidebarNav;

  return (
    <div className="flex flex-no-wrap min-h-screen">
      <SidebarNav
        expanded={expanded}
        isOverflow={!defaultExpandedState}
        setExpanded={setExpanded}
        toggleExpand={toggleExpand}
        routeConfig={routeConfig}
      />
      <ErrorBoundary>
        <div
          className={classNames(
            "overflow-x-clip",
            "w-full",
            "pb-10",
            { "lg:w-[calc(100%-16rem)]": expanded },
            { "lg:w-[calc(100%-4rem)]": !expanded }
          )}
        >
          <div
            className={classNames("w-full h-full", wrapperClasses)}
            id={SCREENCAPTURE_ELEMENT_ID}
          >
            {children}
          </div>
          {feedbackForm && <Feedback />}
        </div>
      </ErrorBoundary>
    </div>
  );
};

export default AppLayout;
