import {
  APIListValuesRequest,
  APIListValuesResponse,
  getFetcher,
  ListAttributesRequest,
} from "shared/api/api";
import client from "shared/api/axios";
import { createURL } from "shared/api/utils";
import { CountRequestWithVehiclesFilter } from "shared/api/vehicles/api";

const CUSTOM_RECORDS_BASE_ROUTE = "customRecords";

// attributes
export const listCustomRecordAttributesRequestURI = (
  params: ListAttributesRequest
): string =>
  client.getUri({
    method: "get",
    url: createURL([CUSTOM_RECORDS_BASE_ROUTE, "attributes"]),
    params,
  });

export const listCustomRecordsValues = (args: APIListValuesRequest) =>
  getFetcher<APIListValuesResponse>(listCustomRecordsValuesRequestURI(args));

const listCustomRecordsValuesRequestURI = ({
  fieldName,
  ...params
}: APIListValuesRequest): string =>
  client.getUri({
    method: "get",
    url: createURL([CUSTOM_RECORDS_BASE_ROUTE, "values", fieldName]),
    params,
  });

export const listCustomRecordsCountRequestURI = ({
  ...params
}: CountRequestWithVehiclesFilter): string =>
  client.getUri({
    method: "GET",
    url: createURL([CUSTOM_RECORDS_BASE_ROUTE, "count"]),
    params,
  });
