import { useState } from "react";
import { Card } from "@mui/material";

import { isMac } from "shared/utils";

import FiltersSummary, {
  ViewFiltersButton,
} from "features/ui/Filters/FiltersSummary";
import { KeyBindingTag } from "features/ui/KeyBindingTag";
import QuickFilters from "features/ui/QuickFilters/QuickFilters";

import PageLevelFilter, { PageLevelFilterProps } from "./PageLevelFilter";

type PageLevelFilterPartialProps = Omit<
  PageLevelFilterProps,
  | "filterTestId"
  | "dropDownTestId"
  | "keybinding"
  | "tooltip"
  | "open"
  | "onOpen"
  | "onClose"
> & { testId: string };

interface PageLevelFiltersProps {
  filtersProps: PageLevelFilterPartialProps[];
  actions?: React.ReactNode;
  actionsBefore?: React.ReactNode;
}

const PageLevelFilters = ({
  filtersProps,
  actions,
  actionsBefore,
}: PageLevelFiltersProps) => {
  const [filterSummaryOpen, setFilterSummaryOpen] = useState(true);
  const [openedDropdownIndex, setOpenedDropdownIndex] = useState<number | null>(
    null
  );

  return (
    <div>
      <div className="flex space-x-3">
        {actionsBefore}
        {filtersProps.map((filtersProp, index) => (
          <PageLevelFilter
            {...filtersProp}
            key={index}
            filterTestId={`${filtersProp.testId}-filters`}
            dropDownTestId={`${filtersProp.testId}-filters-dropdown`}
            keybinding={["alt", (index + 1).toString()]}
            tooltip={
              <KeyBindingTag>
                {isMac ? "⌥" : "ALT"}+{index + 1}
              </KeyBindingTag>
            }
            onOpen={(open) => {
              if (open) {
                setOpenedDropdownIndex(index);
              } else {
                setOpenedDropdownIndex(null);
              }
            }}
            onClose={() => {
              setOpenedDropdownIndex(null);
            }}
            open={openedDropdownIndex === index}
          />
        ))}
        <ViewFiltersButton
          open={filterSummaryOpen}
          onClick={() => setFilterSummaryOpen(true)}
          onClose={() => setFilterSummaryOpen(false)}
        />
        {actions}
      </div>
      <FiltersSummary
        open={filterSummaryOpen}
        filterStates={filtersProps.map((filtersProp, index) => ({
          name: filtersProp.filterLabel,
          filters: filtersProp.filterSortState.filters,
          quickFilters: filtersProp.filterSortState.quickFilters,
          schema: filtersProp.filterSchema,
          baseEntityText: filtersProp.entity,
          onEdit: () => setOpenedDropdownIndex(index),
        }))}
      />
      <Card
        variant="outlined"
        className="flex flex-row p-2 mt-3 text-sm py-2 px-6 gap-3"
      >
        {filtersProps.map((filtersProp, index) => (
          <div className="flex-1 flex items-center" key={index}>
            <QuickFilters
              key={index}
              filterSchema={filtersProp.filterSchema}
              filterSortState={filtersProp.filterSortState}
              filterTitle={filtersProp.quickFilterLabel ?? "Quick Filters"}
            />
          </div>
        ))}
      </Card>
    </div>
  );
};

export default PageLevelFilters;
