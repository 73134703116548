import { useContext, useState } from "react";

import { EventType } from "shared/api/api";
import { CustomSignalEventDefinition } from "shared/api/customSignalEvents/api";
import { EventRegistryContext } from "shared/contexts/EventRegistryContext";
import { useFilterSchemaForType } from "shared/hooks";
import { EventTypeEnum } from "shared/types";
import { toCamelCase } from "shared/utils";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { removeAttributesFromFilterGroupState } from "features/ui/Filters/FilterBuilder/utils";
import { SelectOption } from "features/ui/Select";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";

import {
  DEFAULT_DATE_PARAM,
  DEFAULT_EDIT_MODE,
  DEFAULT_SELECTED_VIN,
} from "./constants";
import { CustomAttribute } from "./Definition/CustomAttributes/types";
import { Downsampling } from "./Definition/DownsamplingSection";
import {
  decapitalizeEventType,
  getDateTypeForEventType,
  getInitialCustomSEDefinitionState,
  isCustomAttributeComplete,
  SignalEventStudioState,
  validateCustomEventFormState,
} from "./utils";

export const useEventTypeOptions = (
  ignoreTypes: EventTypeEnum[] = [],
  requiredAttribute?: string
): SelectOption[] => {
  const eventTypes = useContext(EventRegistryContext);

  return (
    eventTypes
      ?.filter(
        (eventType) =>
          eventType.type &&
          !ignoreTypes.includes(decapitalizeEventType(eventType.type)!)
      )
      // we want to offer only event types that have certain properties like engineHoursAttribute
      ?.filter(
        (eventType) =>
          !requiredAttribute || eventType[requiredAttribute as keyof EventType]
      )
      ?.map((eventType) => ({
        id: eventType.type,
        value: eventType.name,
        testId: eventType.type,
      })) || []
  );
};

export const usePlaygroundFormState = (
  customSignalEventDefinition?: CustomSignalEventDefinition
): SignalEventStudioState => {
  const eventTypes = useContext(EventRegistryContext);

  const eventTypeOptions = useEventTypeOptions([EventTypeEnum.CUSTOM_RECORD]);

  const {
    defaultName,
    defaultDescription,
    defaultID,
    defaultSelectedEventTypeOption,
    defaultFilterBuilderState,
    defaultCustomAttributeFilterBuilderState,
    defaultDownsampling,
    defaultCustomAttributes,
  } = getInitialCustomSEDefinitionState(
    eventTypeOptions,
    customSignalEventDefinition
  );

  const [name, setName] = useState(defaultName);

  const [description, setDescription] = useState(defaultDescription);

  const [id, setId] = useState(defaultID);

  const [isIdSet, setIsIdSet] = useState(!!defaultID);

  const [editMode, setEditMode] = useState(DEFAULT_EDIT_MODE);

  const [selectedEventTypeOption, setSelectedEventTypeOption] = useState<
    SelectOption | undefined
  >(defaultSelectedEventTypeOption);

  const eventType = selectedEventTypeOption?.id
    ? (toCamelCase(selectedEventTypeOption.id.toString()) as EventTypeEnum)
    : undefined;

  const schema = useFilterSchemaForType(eventType);

  const [filterBuilderState, setFilterBuilderState] = useState<
    FilterGroupState | undefined
  >(defaultFilterBuilderState);

  const [
    customAttributeFilterBuilderState,
    setCustomAttributeFilterBuilderState,
  ] = useState<FilterGroupState | undefined>(
    defaultCustomAttributeFilterBuilderState
  );

  const onCustomAttributeRemoved = (id: string) => {
    if (customAttributeFilterBuilderState) {
      setCustomAttributeFilterBuilderState(
        removeAttributesFromFilterGroupState(
          customAttributeFilterBuilderState,
          [id]
        )
      );
    }
  };

  const [selectedDownsamplingOption, setSelectedDownsamplingOption] =
    useState<Downsampling>(defaultDownsampling);

  const [selectedVIN, setSelectedVIN] = useState<string | undefined>(
    DEFAULT_SELECTED_VIN
  );

  const [customAttributes, setCustomAttributes] = useState<CustomAttribute[]>(
    defaultCustomAttributes
  );

  const completeCustomAttributes = customAttributes.filter(
    isCustomAttributeComplete
  );

  const customAttributesSchema: SchemaEntry[] = completeCustomAttributes.map(
    (attribute) => ({
      label: attribute.label,
      accessor: attribute.id,
      dataType: "number" as DataType,
      filter: {
        label: attribute.label,
        fieldName: attribute.id,
        filterType: "number",
        disableSelectFilters: true,
      },
    })
  );

  const formValidationErrors = validateCustomEventFormState(
    selectedEventTypeOption,
    filterBuilderState,
    customAttributeFilterBuilderState,
    customAttributes,
    selectedDownsamplingOption
  );

  const isValidForm = formValidationErrors.length === 0;

  const showPreview = isValidForm && selectedVIN && !editMode;

  const dateParam =
    eventTypes?.find((x) => x.type === selectedEventTypeOption?.id)
      ?.dateAttribute || DEFAULT_DATE_PARAM;

  const dateType = getDateTypeForEventType(dateParam, schema);

  return {
    name,
    setName,
    description,
    setDescription,
    id,
    setId,
    isIdSet,
    setIsIdSet,
    editMode,
    setEditMode,
    eventTypeOptions,
    selectedEventTypeOption,
    setSelectedEventTypeOption,
    schema,
    filterBuilderState,
    setFilterBuilderState,
    customAttributeFilterBuilderState,
    setCustomAttributeFilterBuilderState,
    onCustomAttributeRemoved,
    selectedDownsamplingOption,
    setSelectedDownsamplingOption,
    customAttributes,
    setCustomAttributes,
    completeCustomAttributes,
    customAttributesSchema,
    formValidationErrors,
    isValidForm,
    showPreview,
    selectedVIN,
    setSelectedVIN,
    dateParam,
    dateType,
  };
};
