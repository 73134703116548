import { useFlags } from "launchdarkly-react-client-sdk";

import { RouteConfig } from "services/routesConfig";

import Sidebar from "./Sidebar";
import { useSideNavGroups } from "./utils";

interface Props {
  expanded: boolean;
  isOverflow: boolean;
  setExpanded: (expanded: boolean) => void;
  toggleExpand: () => void;
  routeConfig: RouteConfig;
}

const SidebarNav = ({
  expanded,
  isOverflow,
  setExpanded,
  toggleExpand,
  routeConfig,
}: Props) => {
  const flags = useFlags();
  const navGroups = useSideNavGroups({ flags });

  return (
    <Sidebar
      navGroups={navGroups}
      expanded={expanded}
      setExpanded={setExpanded}
      toggleExpand={toggleExpand}
      isOverflow={isOverflow}
      routeConfig={routeConfig}
      showHomeLink
      showSearch
      showDuck
    />
  );
};

export default SidebarNav;
