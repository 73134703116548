import { EntityAttribute } from "shared/api/api";
import {
  CLAIMS_GENERIC_FILTER,
  SIGNAL_EVENT_OCCURRENCES_GENERIC_FILTER,
  VEHICLES_GENERIC_FILTER,
} from "shared/filterDefinitions";
import { EventTypeEnum } from "shared/types";

import { FilterSchemaItem } from "features/ui/Filters/types";
import { getFilterType } from "features/ui/Filters/utils";
import { SelectOption } from "features/ui/Select";

import {
  MANY_TO_MANY_PRESENT_SELECT_OPTION,
  TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR,
} from "./constants";
import { M2MRelationshipsGroupBy, SchemaRelations, UseSchema } from "./types";
import { getMainAttributeName, getSchemaType } from "./utils";

/**
 * Generates SelectOptions for the groupBy select based on given attributes and relations.
 * It is intended to work with nested autocomplete SelectOption[], for example Vehicle -> ECUs -> ABC -> Hardware
 * @param attributes EntityAttribute[]
 * @param relations SchemaRelations
 * @returns SelectOption[]
 */
export const useGroupBySelectOptions = (
  attributes: EntityAttribute[] | undefined,
  relations?: SchemaRelations,
  m2mRelationshipsGroupBy?: M2MRelationshipsGroupBy
): SelectOption[] => {
  if (!attributes) {
    return [];
  }

  return attributes.reduce<SelectOption[]>((options, attribute) => {
    const {
      ID,
      type,
      attributeGrouping,
      grouping,
      relationEndpoint,
      relationEndpointIDColumn,
      displayName,
    } = attribute;

    const relationSchemaAvailable =
      relationEndpoint && relations && relations[relationEndpoint];

    const isOneToManyRelationship =
      type === "relation" && relationSchemaAvailable && grouping;

    // we use attributeGrouping because tags also have type = "array[relation]" but we dont treat them as m2m
    const isM2MRelationship =
      attributeGrouping &&
      relationSchemaAvailable &&
      relationEndpointIDColumn &&
      m2mRelationshipsGroupBy &&
      m2mRelationshipsGroupBy[attribute.relationEndpoint!];

    // Many-to-many relationships
    if (isM2MRelationship) {
      const currentM2MOptions =
        m2mRelationshipsGroupBy[attribute.relationEndpoint!];

      if (currentM2MOptions) {
        const values: SelectOption[] = currentM2MOptions.map((val) => ({
          id: val,
          value: val,
          label: val,
          children: [
            MANY_TO_MANY_PRESENT_SELECT_OPTION,
            ...(relations[attribute.relationEndpoint!]?.groupBySelectOptions ||
              []),
          ],
        }));

        options.push({
          id: ID,
          value: displayName,
          label: displayName,
          children: values,
        });
      }
    } else if (isOneToManyRelationship) {
      const { groupBySelectOptions: relationGroupBySelectOptions } =
        relations[attribute.relationEndpoint!];

      if (relationGroupBySelectOptions) {
        options.push({
          id: ID,
          value: displayName,
          label: displayName,
          children: relationGroupBySelectOptions,
        });
      }
    } else if (grouping) {
      options.push({
        id: ID,
        value: displayName,
        label: displayName,
      });
    }

    return options;
  }, []);
};

/**
 * This hook returns a FilterSchemaItem for a given attributeObj and resource.
 * Just extracted out of useSchemaEntryForAttribute for readability.
 * @param eventType EventTypeEnum
 * @param attributeObj EntityAttribute
 * @returns FilterSchemaItem | undefined
 */
export const useGroupByAttributeFilter = (
  eventType: EventTypeEnum,
  vehicleSchemaData: UseSchema,
  vehicleECUSchemaData: UseSchema,
  dealerSchemaData: UseSchema,
  attributeObj?: EntityAttribute
): FilterSchemaItem | undefined => {
  const { schema: vehicleAttributeSchema } = vehicleSchemaData;
  const { schema: ECUsAttributeSchema } = vehicleECUSchemaData;
  const { schema: dealerAttributesSchema } = dealerSchemaData;

  if (!attributeObj) return undefined;

  const { ID, filtering, type, displayName } = attributeObj;

  // we only support string filters on groupByAttributeValue
  if (!ID || !filtering || (!type && !type.includes("string")) || !displayName)
    return undefined;

  const isVehicleAttribute = ID.startsWith("vehicle.");
  const isDealerAttribute =
    ID.startsWith("dealer.") || ID.includes(".lastKnownDealer.");

  // we get part of ID after the last dot if nested attribute
  const fieldName =
    isVehicleAttribute || isDealerAttribute ? getMainAttributeName(ID) : ID;

  const resourceFilter =
    eventType === EventTypeEnum.CLAIM
      ? CLAIMS_GENERIC_FILTER
      : SIGNAL_EVENT_OCCURRENCES_GENERIC_FILTER;

  const genericFilter = isVehicleAttribute
    ? VEHICLES_GENERIC_FILTER
    : resourceFilter;

  if (isVehicleAttribute) {
    // we also need ECU attributes
    vehicleAttributeSchema.push(...ECUsAttributeSchema);
    const filterFromVehiclesSchema = vehicleAttributeSchema.find(
      ({ accessor }) => accessor === fieldName
    )?.filter;

    if (filterFromVehiclesSchema) {
      return {
        ...filterFromVehiclesSchema,
        fieldName: TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR,
        fieldNameForAPI: fieldName,
      };
    }
  }

  if (isDealerAttribute) {
    const filterFromDealerSchema = dealerAttributesSchema.find(
      ({ accessor }) => accessor === fieldName
    )?.filter;

    if (filterFromDealerSchema) {
      return {
        ...filterFromDealerSchema,
        fieldName: TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR,
        fieldNameForAPI: fieldName,
      };
    }
  }

  const filterType = getFilterType(type);
  const filterDataType = getSchemaType(type);
  const disableSelectFilters = filterType === "number";

  return genericFilter({
    fieldName: TOP_CONTRIBUTORS_GROUP_BY_ACCESSOR,
    fieldNameForAPI: fieldName,
    label: displayName,
    filterType,
    loadDataOnOpen: false,
    search: true,
    disableFiltering: true,
    disableSelectFilters,
    filterDataType,
  });
};
