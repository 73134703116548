import { ReactNode } from "react";

import { EntityAttribute } from "shared/api/api";
import { UseAPIState } from "shared/api/hooks";
import { M2MRelationshipsGroupBy } from "shared/schemas/types";

export interface ContextWrapComponentProps {
  children: ReactNode;
}

export type ContextWrapper = React.FC<ContextWrapComponentProps>;

export enum ContextProviderName {
  AlertDefinition = "AlertDefinition",
  Claim = "Claim",
  CustomRecord = "CustomRecord",
  CustomSignalEventDefinition = "CustomSignalEventDefinition",
  Dealer = "Dealer",
  FailureMode = "FailureMode",
  FailureModeEvents = "FailureModeEvents",
  Inspection = "Inspection",
  InspectionItem = "InspectionItem",
  Issue = "Issue",
  Repair = "Repair",
  RiskModelPrediction = "RiskModelPrediction",
  SensorReading = "SensorReading",
  ServicePlan = "ServicePlan",
  ServiceRecommendation = "ServiceRecommendation",
  SignalEventOccurrence = "SignalEventOccurrence",
  SuggestedIssue = "SuggestedIssue",
  Vehicle = "Vehicle",
  WorkOrder = "WorkOrder",
  Part = "Part",
  RepairPart = "RepairPart",
  ServiceRecord = "ServiceRecord",
}

export type ContextName =
  (typeof ContextProviderName)[keyof typeof ContextProviderName];

export type ContextProviderConfig = {
  [K in ContextName]: UseAttributesHook;
};

export type AttributesContextContent = {
  attributes: EntityAttribute[] | undefined;
  groupByValues: M2MRelationshipsGroupBy;
};

export type UseAttributesHook = (
  args?: any
) => UseAPIState<EntityAttribute[] | undefined>;

export interface ContextRegistry {
  [key: string]: React.Context<AttributesContextContent>;
}
