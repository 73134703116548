import { useCallback, useState } from "react";

import { EntityAttribute, VehicleAgeTimeline } from "shared/api/api";
import { NONE_EXPOSURE } from "shared/constants";
import { useClaimsSchema } from "shared/schemas/claimsSchema";
import { useInspectionsSchema } from "shared/schemas/inspectionsSchema";
import { useRepairsSchema } from "shared/schemas/repairsSchema";
import { USE_RESOURCE_SCHEMA_MAP } from "shared/schemas/schemaMap";
import useSignalEventOccurrencesSchema from "shared/schemas/signalEventOccurrencesSchema";
import { sortByValue } from "shared/schemas/utils";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { EventTypeEnum } from "shared/types";

import {
  ChartAction,
  SelectedChartOptions,
} from "features/ui/charts/Actions/types";
import {
  getAxisValue,
  getDefaultActions,
  getSelectedOptionId,
} from "features/ui/charts/utils";
import {
  ChartSettingsChangeHandler,
  PageChartSettingsState,
  UseFilterSortState,
} from "features/ui/Filters/types";
import { Option, SelectOption } from "features/ui/Select";

import {
  GROUP_BY_ATTRIBUTE_KEY,
  TOP_CONTRIBUTORS_NONE_EXPOSURE,
  TOP_CONTRIBUTORS_TAB_KEY,
} from "./constants";
import { useTopContributorsChartYAxisOptions } from "./shared/topContributorsChartActions";
import { ByVehicleAgeData } from "./types";
import {
  getDefaultTopContributorChartActions,
  getTopContributorsChartActions,
  mapByVehicleAgeExposureAttributes,
  mapByVehicleAgeExposureBuckets,
} from "./utils";

export const useByVehicleAgeBirthdayOptions = (): SelectOption[] => {
  const { attributes } = useVehiclesSchema();

  if (!attributes || attributes.length === 0) {
    return [];
  }

  return attributes
    .filter((attr) => attr.byVehicleAgeBirthday)
    .map(({ ID, displayName }) => ({
      id: ID,
      value: displayName,
    }))
    .sort(sortByValue);
};

export const useClaimByVehicleAgeExposureOptions = (): SelectOption[] => {
  const { attributes } = useClaimsSchema();

  return mapByVehicleAgeExposureAttributes(attributes);
};

export const useInspectionByVehicleAgeExposureOptions = (): SelectOption[] => {
  const { attributes } = useInspectionsSchema();

  return mapByVehicleAgeExposureAttributes(attributes);
};

export const useRepairByVehicleAgeExposureOptions = (): SelectOption[] => {
  const { attributes } = useRepairsSchema();

  return mapByVehicleAgeExposureAttributes(attributes);
};

export const useSEByVehicleAgeExposureOptions = (): SelectOption[] => {
  const { attributes } = useSignalEventOccurrencesSchema();

  return mapByVehicleAgeExposureAttributes(attributes);
};

/**
 * Manages chart settings for the top contributors chart.
 */
export const useTopContributorsChartSettings = (
  filterSortState: UseFilterSortState,
  chartOptionsKey: string,
  eventType: EventTypeEnum
) => {
  const { attributes } = USE_RESOURCE_SCHEMA_MAP[eventType]();
  const exposures = useTopContributorsExposureOptions(eventType);
  const yAxisOptions = useTopContributorsChartYAxisOptions(eventType);
  const initialActions = getDefaultTopContributorChartActions(
    attributes,
    chartOptionsKey,
    yAxisOptions,
    exposures
  );

  // Take initial values from LS
  const initialSelectedOptions: SelectedChartOptions[] =
    filterSortState?.chartSettings &&
    filterSortState.chartSettings[TOP_CONTRIBUTORS_TAB_KEY] &&
    filterSortState.chartSettings[TOP_CONTRIBUTORS_TAB_KEY][chartOptionsKey]
      ? filterSortState.chartSettings[TOP_CONTRIBUTORS_TAB_KEY][chartOptionsKey]
      : getDefaultActions(initialActions);

  const [selectedOptions, setSelectedOptions] = useState<
    SelectedChartOptions[]
  >(initialSelectedOptions);

  const handleSelectedOptionsChange = useCallback(
    (newSelectedOptions: SelectedChartOptions[]) => {
      const newExposure = getSelectedOptionId(newSelectedOptions, "exposure");
      const oldExposure = getSelectedOptionId(selectedOptions, "exposure");

      let updatedOptions = [...newSelectedOptions];
      if (newExposure !== oldExposure) {
        // If exposure changed, we need to update both exposure and its related buckets
        updatedOptions = updateExposureOptions(
          updatedOptions,
          newExposure,
          attributes
        );
      } else {
        // If exposure hasn't changed, check if only the exposure bucket has changed
        const newExposureBucket = getSelectedOptionId(
          newSelectedOptions,
          "exposureBucket"
        );
        const oldExposureBucket = getSelectedOptionId(
          selectedOptions,
          "exposureBucket"
        );

        if (newExposureBucket !== oldExposureBucket) {
          // If only the exposure bucket changed, update just the bucket
          updatedOptions = updateExposureBucketOption(
            updatedOptions,
            newExposureBucket
          );
        }
      }

      // Function to update chart settings in the parent component or global state
      const updateChartSettings = (updatedOptions: SelectedChartOptions[]) => {
        if (filterSortState?.manageChartSettingsChange) {
          filterSortState.manageChartSettingsChange(
            updatedOptions,
            chartOptionsKey
          );
        }
      };

      // Update the local state with the new options
      setSelectedOptions(updatedOptions);
      // Update the chart settings in the parent component or global state
      updateChartSettings(updatedOptions);
    },
    [selectedOptions, attributes, chartOptionsKey, filterSortState]
  );

  // Update exposure buckets based on selected exposure
  const updateExposureOptions = (
    options: SelectedChartOptions[],
    newExposure: string,
    attributes: EntityAttribute[] | undefined
  ) => {
    // If the new exposure is NONE, remove the exposure bucket option
    if (newExposure === NONE_EXPOSURE) {
      return options.filter((option) => option.id !== "exposureBucket");
    }

    // Get the new exposure buckets based on the selected exposure
    const newExposureBuckets = mapByVehicleAgeExposureBuckets(
      attributes || [],
      newExposure
    );

    if (newExposureBuckets.length === 0) return options;

    // Find the index of the existing exposure bucket option
    const bucketOptionIndex = options.findIndex(
      (option) => option.id === "exposureBucket"
    );

    if (bucketOptionIndex !== -1) {
      // Update existing exposure bucket option
      options[bucketOptionIndex].optionId = newExposureBuckets[0].id;
    } else {
      // Add new exposure bucket option if it doesn't exist
      options.push({
        id: "exposureBucket",
        optionId: newExposureBuckets[0].id,
      });
    }

    return options;
  };

  const updateExposureBucketOption = (
    options: SelectedChartOptions[],
    newExposureBucket: string
  ): SelectedChartOptions[] =>
    options.map((option) =>
      option.id === "exposureBucket"
        ? { ...option, optionId: newExposureBucket }
        : option
    );

  const selectedExposure = getSelectedOptionId(selectedOptions, "exposure");
  const selectedExposureForAPI =
    selectedExposure === NONE_EXPOSURE ? undefined : selectedExposure;
  const selectedExposureBucket = getSelectedOptionId(
    selectedOptions,
    "exposureBucket"
  );
  const selectedExposureBucketForAPI =
    selectedExposureBucket != null
      ? parseInt(selectedExposureBucket)
      : undefined;

  const currentExposureBucketOptions = selectedExposureForAPI
    ? mapByVehicleAgeExposureBuckets(attributes || [], selectedExposureForAPI)
    : [];

  const actions = getTopContributorsChartActions(
    yAxisOptions,
    exposures,
    currentExposureBucketOptions,
    selectedExposureForAPI || NONE_EXPOSURE
  );

  return {
    actions,
    selectedOptions,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
    handleSelectedOptionsChange,
  };
};

const findOption = (
  options: SelectOption[],
  id: string,
  outputValue?: string
) => {
  const parts = id.split(".");
  const firstPart = parts[0];
  if (!firstPart) return undefined;

  const option = options.find((option) => option.id === firstPart);
  if (!option) return undefined;

  const newOutputValue = outputValue
    ? ((outputValue + " > " + option.value) as string)
    : (option.value as string);

  if (parts.length === 1) {
    const copy = { ...option };
    copy.value = newOutputValue;

    return copy;
  }

  if (option.children && parts.length > 1)
    return findOption(
      option.children,
      parts.slice(1).join("."),
      newOutputValue
    );

  return undefined;
};

// Helper function to get initial selected group by attribute
const getInitialSelectedGroupByAttribute = (
  chartSettings: PageChartSettingsState | undefined,
  defaultGroupByOption: SelectOption,
  groupBySelectOptions: SelectOption[],
  tabKey: string,
  groupByOptionsKey: string,
  groupByAttributeKey: string
): SelectOption => {
  if (
    !chartSettings ||
    !chartSettings[tabKey] ||
    !chartSettings[tabKey][groupByOptionsKey]
  ) {
    return defaultGroupByOption;
  }

  const foundSetting = chartSettings[tabKey][groupByOptionsKey].find(
    (option) => option.id === groupByAttributeKey
  );
  if (!foundSetting) return defaultGroupByOption;

  const foundOption = findOption(
    groupBySelectOptions,
    foundSetting.optionId as string
  );

  if (!foundOption) return defaultGroupByOption;

  return {
    id: foundSetting.optionId,
    value: foundOption.value,
  };
};

const getDefaultGroupByAttributeLabel = (
  options: SelectOption[],
  attributeId: string
  // if the attribute is not found, return an empty string to avoid undefined
) => options.find((option) => option.id === attributeId)?.value ?? "";

export const getDefaultByGroupBySelectOption = (
  options: SelectOption[],
  defaultGroupByAttribute: string
): SelectOption => ({
  id: defaultGroupByAttribute,
  value: getDefaultGroupByAttributeLabel(options, defaultGroupByAttribute),
});

export const useGroupBy = (
  eventType: EventTypeEnum,
  filterSortState: UseFilterSortState,
  defaultGroupByAttribute: string,
  groupByOptionsKey: string,
  tabKey: string
) => {
  const { groupBySelectOptions } = USE_RESOURCE_SCHEMA_MAP[eventType]();

  const groupBySelectOptionsToUse = groupBySelectOptions || [];

  const defaultGroupByOption = getDefaultByGroupBySelectOption(
    groupBySelectOptionsToUse,
    defaultGroupByAttribute
  );

  const initialSelectedGroupByAttribute: SelectOption =
    getInitialSelectedGroupByAttribute(
      filterSortState?.chartSettings,
      defaultGroupByOption,
      groupBySelectOptionsToUse,
      tabKey,
      groupByOptionsKey,
      GROUP_BY_ATTRIBUTE_KEY
    );

  const [selectedGroupByAttribute, setSelectedGroupByAttribute] =
    useState<SelectOption>(initialSelectedGroupByAttribute);

  // since we are awaiting attributes endpoint for default value,
  // we need to check if label is different once the attributes are loaded
  if (
    initialSelectedGroupByAttribute.id !== selectedGroupByAttribute.id ||
    initialSelectedGroupByAttribute.value !== selectedGroupByAttribute.value
  ) {
    setSelectedGroupByAttribute(initialSelectedGroupByAttribute);
  }

  const handleGroupByAttributeChange = (groupByAttribute: SelectOption) => {
    setSelectedGroupByAttribute(groupByAttribute);
    if (filterSortState?.manageChartSettingsChange) {
      filterSortState.manageChartSettingsChange(
        [
          {
            id: GROUP_BY_ATTRIBUTE_KEY,
            optionId: groupByAttribute.id,
          },
        ],
        groupByOptionsKey
      );
    }
  };

  return {
    groupBySelectOptions: groupBySelectOptionsToUse,
    selectedGroupByAttribute,
    handleGroupByAttributeChange,
  };
};

export const useByVehicleAgeOptions = (
  selectedOptions: SelectedChartOptions[],
  actions: ChartAction[]
): ByVehicleAgeData => {
  const byVehicleAgeBirthday = getSelectedOptionId(selectedOptions, "x");
  const byVehicleAgeBirthdayValue = getAxisValue(
    actions,
    "x",
    byVehicleAgeBirthday
  );
  const byVehicleAgeExposure = getSelectedOptionId(selectedOptions, "exposure");
  const byVehicleAgeExposureValue = getAxisValue(
    actions,
    "exposure",
    byVehicleAgeExposure
  );
  const granularity = getSelectedOptionId(selectedOptions, "granularity");

  const yAxisKey = getSelectedOptionId(
    selectedOptions,
    "y"
  ) as keyof VehicleAgeTimeline;
  const yAxisValue = getAxisValue(actions, "y", yAxisKey);

  return {
    byVehicleAgeBirthday,
    byVehicleAgeBirthdayValue,
    byVehicleAgeExposure,
    byVehicleAgeExposureValue,
    granularity,
    yAxisKey,
    yAxisValue,
  };
};

export const useTopContributorsExposureOptions = (
  eventType: EventTypeEnum
): SelectOption[] => {
  const { attributes } = USE_RESOURCE_SCHEMA_MAP[eventType]();

  return [
    ...mapByVehicleAgeExposureAttributes(attributes),
    TOP_CONTRIBUTORS_NONE_EXPOSURE,
  ];
};

/**
 * Get the selected options and a setter for the selected options for chart settings.
 *
 * @param chartSettings From useFilterSortState
 * @param manageChartSettingsChange From useFilterSortState
 * @param tabKey
 * @param chartOptionsKey
 * @param defaultActions Selected options to use if they are not present in chartSettings
 * @returns An object with:
 * - selectedOptions: The selected chart settings options
 * - setSelectedOptions: A function to set the selected chart settings options
 */
export const useChartSettings = (
  chartSettings: PageChartSettingsState | undefined,
  manageChartSettingsChange: ChartSettingsChangeHandler | undefined,
  tabKey: string,
  chartOptionsKey: string,
  defaultActions: SelectedChartOptions<Option>[]
) => {
  const initialSelectedOptions: SelectedChartOptions[] =
    chartSettings &&
    chartSettings[tabKey] &&
    chartSettings[tabKey][chartOptionsKey]
      ? chartSettings[tabKey][chartOptionsKey]
      : defaultActions;

  const [selectedOptions, internalSetSelectedOptions] = useState<
    SelectedChartOptions[]
  >(initialSelectedOptions);

  const setSelectedOptions = (newSelectedOptions: SelectedChartOptions[]) => {
    internalSetSelectedOptions(newSelectedOptions);
    if (manageChartSettingsChange) {
      manageChartSettingsChange(newSelectedOptions, chartOptionsKey);
    }
  };

  return {
    selectedOptions,
    setSelectedOptions,
  };
};
