import { SyntheticEvent, useEffect, useState } from "react";
import { FiChevronRight as ChevronRight } from "react-icons/fi";
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  MenuItem,
  TextField,
} from "@mui/material";

import { TestProps } from "shared/types";

import { Option, SelectOption } from "features/ui/Select";

import BreadcrumbNavigation from "./Breadcrumb";
import {
  BREADCRUMB_ITEM,
  DEFAULT_DISABLE_CLEARABLE,
  DEFAULT_LABEL,
} from "./constants";
import { getFullPathByIds, getFullPathVisual, getInitialPath } from "./utils";

interface NestedAutocompleteProps<
  DisableClearable extends boolean = typeof DEFAULT_DISABLE_CLEARABLE,
> extends TestProps {
  options: SelectOption[];
  // When disableClearable is false, onSelectionChange can accept null, otherwise we always expect SelectOption
  onSelectionChange: DisableClearable extends typeof DEFAULT_DISABLE_CLEARABLE
    ? (selection: SelectOption) => void
    : (selection: SelectOption | null) => void;
  selected?: SelectOption;
  wrapperClasses?: string;
  label?: string;
  fullWidth?: boolean;
  disableClearable?: DisableClearable;
}

const NestedAutocomplete = <
  DisableClearable extends boolean = typeof DEFAULT_DISABLE_CLEARABLE,
>({
  options,
  onSelectionChange,
  selected,
  testId,
  wrapperClasses,
  label,
  fullWidth,
  disableClearable = DEFAULT_DISABLE_CLEARABLE as DisableClearable,
}: NestedAutocompleteProps<DisableClearable>) => {
  const [currentPath, setCurrentPath] = useState<SelectOption[]>(
    getInitialPath(selected, options)
  );
  const [inputValue, setInputValue] = useState(
    selected ? (selected.value as string) : ""
  );
  const [open, setOpen] = useState(false);

  /**
   * When a breadcrumb is clicked, update the current path to that level &
   * reset the selected option and input value.
   */
  const handleBreadcrumbNavigation = (index: number) => {
    setCurrentPath((prev) => prev.slice(0, index + 1));
    setInputValue("");
  };

  const breadcrumbNavigation = (
    <BreadcrumbNavigation
      path={currentPath}
      onNavigate={handleBreadcrumbNavigation}
    />
  );

  /**
   * When non-child node is clicked: add to currentPath + reset
   * When child node is clicked: set input value to full path, close dropdown, call onSelectionChange
   */
  const handleOptionClick = (option: SelectOption) => {
    if (option.children) {
      setCurrentPath((prev) => [...prev, option]);
      setInputValue("");
    } else {
      const fullPath = getFullPathVisual([...currentPath, option]);
      setInputValue(fullPath);

      const fullPathByIds = getFullPathByIds([...currentPath, option]);
      const newSelectedOption = {
        id: fullPathByIds,
        value: fullPath,
      };
      onSelectionChange(newSelectedOption);

      setOpen(false);
    }
  };

  /**
   * For "root" level, we don't need to prepend breadcrumb item.
   * Always show relevant children as options.
   */
  const getCurrentOptions = (): SelectOption[] => {
    const isRootLevel = currentPath.length === 0;

    if (isRootLevel) return options;

    const lastInPath = currentPath[currentPath.length - 1];
    const currentChildren = lastInPath.children || [];

    // Only add breadcrumb when dropdown is open
    return open ? [BREADCRUMB_ITEM, ...currentChildren] : currentChildren;
  };

  /**
   * If no options are available, show a customized message based on the current selection.
   * Breadcrumbs should also be available.
   */
  const noOptionsText = (
    <div>
      {breadcrumbNavigation}
      <div className="p-2 text-sm text-gray-500">
        {selected ? "No further options available" : "No options"}
      </div>
    </div>
  );

  const resetToInitialState = () => {
    setCurrentPath([]);
    setInputValue("");

    if (!disableClearable) {
      // we let TS know that onSelectionChange can accept null due disableClearable=false
      (onSelectionChange as (selection: SelectOption | null) => void)(null);

      return;
    }

    onSelectionChange(options[0]);
  };

  const renderInput = (params: AutocompleteRenderInputParams) => (
    <TextField
      {...params}
      label={label || DEFAULT_LABEL}
      value={inputValue}
      size="small"
      slotProps={{
        htmlInput: {
          ...params.inputProps,
          "data-testid": "nested-autocomplete-input",
        },
      }}
    />
  );

  const renderOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: SelectOption
  ) => {
    if (option.id === BREADCRUMB_ITEM.id) {
      return breadcrumbNavigation;
    }

    return (
      <MenuItem
        {...props}
        key={option.id}
        onClick={() => handleOptionClick(option)}
        className="flex! justify-between! items-center!"
      >
        <span>{option.value.toString()}</span>
        {option.children && (
          <span>
            <ChevronRight />
          </span>
        )}
      </MenuItem>
    );
  };

  const handleChange = (
    _: SyntheticEvent,
    value: SelectOption<Option> | null
  ) => {
    if (value) {
      handleOptionClick(value as SelectOption);
    } else {
      resetToInitialState();
    }
  };

  const handleInputChange = (_: SyntheticEvent, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  // handling scrolling to top of listbox when opening to override the
  // MUI autocomplete default behavior scrolling to the selected option on open
  useEffect(() => {
    setTimeout(() => {
      if (open) {
        const listBoxNode = document.querySelector(".MuiAutocomplete-listbox");
        if (listBoxNode) {
          listBoxNode.scrollTop = 0;
        }
      }
    }, 0); // setTimeout is necessary to ensure the listbox is rendered (tick)
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      options={getCurrentOptions()}
      getOptionLabel={(option: SelectOption) => option.value.toString()}
      renderInput={renderInput}
      renderOption={renderOption}
      value={selected}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      noOptionsText={noOptionsText}
      classes={{
        noOptions: "my-2! p-0! !viaduct-black",
      }}
      className={wrapperClasses}
      data-testid={testId}
      disableClearable={disableClearable}
      fullWidth={fullWidth}
    />
  );
};

export default NestedAutocomplete;
