import { useState } from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { HiChevronDown as ChevronDown } from "react-icons/hi";
import { IoClose as CloseIcon } from "react-icons/io5";
import { generatePath, useNavigate } from "react-router";
import { toast } from "react-toastify";

import { updateIssue } from "shared/api/issues/api";
import { useIssue } from "shared/api/issues/hooks";
import { useConfigContext } from "shared/contexts/ConfigContext";
import { useEmailFromJWT } from "shared/hooks";

import {
  ISSUE_AT_RISK_POPULATION_FILTER_PAGE_KEY,
  ISSUE_CLAIMS_FILTER_PAGE_KEY,
  ISSUE_COMPARISON_POPULATION_FILTER_PAGE_KEY,
  ISSUE_SIGNAL_EVENT_FILTER_PAGE_KEY,
} from "pages/Issues/CreateIssue/constants";
import CreateIssue from "pages/Issues/CreateIssue/CreateIssue";
import { DEFAULT_ISSUE_DATA } from "pages/shared/constants";
import { IssueFormData } from "pages/shared/types";

import Button from "features/ui/Button";
import DropdownSelect from "features/ui/DropdownSelect";
import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  areFiltersEqual,
  getFiltersQuery,
} from "features/ui/Filters/FilterBuilder/utils";
import { clearPendingFiltersForKey } from "features/ui/Filters/utils";
import BackToEntityIcon from "features/ui/Icons/BackToEntityIcon";

import { routes } from "services/routes";

import { useBackToEntityState } from "./hooks";
import { getReturnToEntityEntry, removeReturnToEntityEntry } from "./utils";

const ICON_SIZE = 17;

interface Props {
  page: "claimAnalytics" | "signalEventAnalytics";
  vehicleFilters: FilterGroupState;
  claimFilters?: FilterGroupState;
  signalEventFilters?: FilterGroupState;
}

const BackToEntityLinks = ({
  page,
  vehicleFilters,
  claimFilters,
  signalEventFilters,
}: Props) => {
  const {
    initialVehicleFilters,
    initialClaimFilters,
    initialSignalEventOccurrencesFilter,
  } = useBackToEntityState({
    vehicleFilters,
    claimFilters,
    signalEventFilters,
  });
  const navigate = useNavigate();

  const { rbac: rbacFF } = useFlags();
  const {
    pages: { rbac },
  } = useConfigContext();

  const [returnToEntity, setReturnToEntity] = useState(
    getReturnToEntityEntry()
  );
  const [issueModalOpen, setIssueModalOpen] = useState(false);

  const defaultIssueFormData: IssueFormData = {
    ...DEFAULT_ISSUE_DATA,
    assignee: useEmailFromJWT(),
    atRiskPopulationFilter: initialVehicleFilters,
    claimFilter: initialClaimFilters,
    signalEventOccurrencesFilter: initialSignalEventOccurrencesFilter,
  };

  const [issueData, setIssueData] =
    useState<IssueFormData>(defaultIssueFormData);

  const {
    data: issue,
    isLoading,
    error,
  } = useIssue({
    id: returnToEntity?.entityID as string,
    skipRequest:
      !returnToEntity?.entityID ||
      returnToEntity?.entityType === "suggested_issue",
  });

  if (
    !returnToEntity ||
    (returnToEntity?.entityType === "issue" && !issue) ||
    isLoading
  ) {
    return null;
  }

  const { entityID, entityType, entityRunDate } = returnToEntity;
  const humanizedEntityType =
    entityType === "issue" ? "Issue" : "Suggested Issue";

  const returnToButtonLabel = `Return to ${humanizedEntityType}`;

  const hasChangedVehicleFilters = !areFiltersEqual(
    initialVehicleFilters,
    vehicleFilters
  );

  const hasChangedClaimFilters =
    page === "claimAnalytics" &&
    !areFiltersEqual(initialClaimFilters, claimFilters);

  const hasChangedSignalEventIDs =
    page === "signalEventAnalytics" &&
    !areFiltersEqual(initialSignalEventOccurrencesFilter, signalEventFilters);

  const haveFilterChanged =
    hasChangedVehicleFilters ||
    hasChangedClaimFilters ||
    hasChangedSignalEventIDs;

  const rbacEnabled = rbacFF && rbac;
  const canEditIssue =
    !rbacEnabled || (rbacEnabled && (entityType === "issue" || issue?.canEdit));

  const showReturnToOptions =
    haveFilterChanged && entityType === "issue" && canEditIssue && !error;
  const showPromoteToOptions =
    haveFilterChanged && entityType === "suggested_issue";

  const processRemoveReturnToEntityEntry = () => {
    setReturnToEntity(null);
    removeReturnToEntityEntry();
  };

  const onCloseIconClick = () => processRemoveReturnToEntityEntry();

  const clearPendingCreateIssuesFilters = () => {
    clearPendingFiltersForKey(ISSUE_AT_RISK_POPULATION_FILTER_PAGE_KEY);
    clearPendingFiltersForKey(ISSUE_COMPARISON_POPULATION_FILTER_PAGE_KEY);
    clearPendingFiltersForKey(ISSUE_CLAIMS_FILTER_PAGE_KEY);
    clearPendingFiltersForKey(ISSUE_SIGNAL_EVENT_FILTER_PAGE_KEY);
  };

  const onReturnWithoutApplyingFilterChangesClick = () => {
    processRemoveReturnToEntityEntry();

    const basePath =
      entityType === "issue" ? routes.issue : routes.suggestedIssue;
    const pathParams =
      entityType === "issue"
        ? { id: entityID }
        : { id: entityID, date: entityRunDate };

    navigate(generatePath(basePath, pathParams));
  };

  const onReturnWithApplyingFilterChangesClick = () => {
    let requestData: {
      ID: string;
      atRiskPopulationFilter: string;
      claimFilter?: string;
      signalEventOccurrencesFilter?: string;
    } = {
      ID: entityID,
      atRiskPopulationFilter: getFiltersQuery(vehicleFilters),
    };

    if (hasChangedClaimFilters) {
      requestData.claimFilter = getFiltersQuery(claimFilters);
    }

    if (hasChangedSignalEventIDs) {
      requestData.signalEventOccurrencesFilter =
        getFiltersQuery(signalEventFilters);
    }

    updateIssue(requestData)
      .then(() => {
        toast.success(`${humanizedEntityType} updated successfully`);
        processRemoveReturnToEntityEntry();

        navigate(generatePath(routes.issue, { id: entityID }));
      })
      .catch((err: Error) => {
        toast.error(
          `Updating ${entityType} failed. Please notify us if the problem persists.`
        );
        console.log(err);
      });
  };

  const onPromoteToIssueWithoutApplyingFilterChangesClick = () => {
    setIssueData(defaultIssueFormData);
    clearPendingCreateIssuesFilters();
    setIssueModalOpen(true);
  };

  const onPromoteToIssueWithApplyingFilterChangesClick = () => {
    setIssueData({
      ...defaultIssueFormData,
      atRiskPopulationFilter: vehicleFilters,
      claimFilter: claimFilters,
      signalEventOccurrencesFilter: signalEventFilters,
    });
    clearPendingCreateIssuesFilters();
    setIssueModalOpen(true);
  };

  const returnToButtonComponent = (
    <Button
      label={returnToButtonLabel}
      startIcon={
        entityType === "issue" ? <BackToEntityIcon size={ICON_SIZE} /> : <></>
      }
      className="text-xs! bg-white!"
      endIcon={showReturnToOptions ? <ChevronDown size={ICON_SIZE} /> : <></>}
      testId="return-to-entity-button"
      onClick={(event) => {
        event.preventDefault();

        if (showReturnToOptions) {
          return;
        }

        onReturnWithoutApplyingFilterChangesClick();
      }}
    />
  );

  const promoteToButtonComponent = (
    <Button
      label="Promote to Issue"
      startIcon={<BackToEntityIcon size={ICON_SIZE} />}
      className="text-xs! bg-white!"
      endIcon={showPromoteToOptions ? <ChevronDown size={ICON_SIZE} /> : <></>}
      testId="promote-to-issue-button"
      onClick={(event) => {
        event.preventDefault();

        if (showPromoteToOptions) {
          return;
        }

        onPromoteToIssueWithoutApplyingFilterChangesClick();
      }}
    />
  );

  return (
    <div className="flex flex-row space-x-2 bg-gray-100 p-1 rounded-lg items-center">
      <DropdownSelect
        buttonComponent={returnToButtonComponent}
        disabledOnClick={!showReturnToOptions}
        content={
          showReturnToOptions && (
            <div className="flex flex-col space-y-1 rounded-lg bg-white cursor-pointer">
              <div
                className="p-2 hover:bg-blue-100"
                onClick={onReturnWithoutApplyingFilterChangesClick}
              >
                <div className="mb-1">
                  Return but do not apply filter changes
                </div>
                <div className="ml-3 text-xs text-gray-500">
                  Return to the {entityType} without applying any filter changes
                </div>
              </div>
              <div
                className="p-2 hover:bg-blue-100"
                onClick={onReturnWithApplyingFilterChangesClick}
              >
                <div className="mb-1">Return and apply filter changes</div>
                <div className="ml-3 text-xs text-gray-500">
                  Apply the filter changes made here to the {entityType} you
                  came from
                </div>
              </div>
            </div>
          )
        }
      />
      {entityType === "suggested_issue" && (
        <DropdownSelect
          buttonComponent={promoteToButtonComponent}
          disabledOnClick={!showPromoteToOptions}
          content={
            showPromoteToOptions && (
              <div className="flex flex-col space-y-1 rounded-lg bg-white cursor-pointer">
                <div
                  className="p-2 hover:bg-blue-100"
                  onClick={onPromoteToIssueWithoutApplyingFilterChangesClick}
                >
                  <div className="mb-1">
                    Promote to Issue but do not apply filter changes
                  </div>
                  <div className="ml-3 text-xs text-gray-500">
                    Promote the read-only Suggested Issue without applying the
                    filter changes you explored here
                  </div>
                </div>
                <div
                  className="p-2 hover:bg-blue-100"
                  onClick={onPromoteToIssueWithApplyingFilterChangesClick}
                >
                  <div className="mb-1">
                    Promote to Issue and apply filter changes
                  </div>
                  <div className="ml-3 text-xs text-gray-500">
                    Promote the read-only Suggested Issue to an editable Issue
                    and apply your filter changes to it
                  </div>
                </div>
              </div>
            )
          }
        />
      )}
      <CloseIcon
        size={ICON_SIZE}
        onClick={onCloseIconClick}
        className="cursor-pointer hover:bg-red-100 hover:text-red-500 hover:rounded-full"
      />
      {issueModalOpen && (
        <CreateIssue
          action="create"
          defaultOpen={true}
          issueData={issueData}
          showButton={false}
          onClose={() => setIssueModalOpen(false)}
          onlyAllowCurrentStep={false}
        />
      )}
    </div>
  );
};

export default BackToEntityLinks;
