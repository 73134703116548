import { MdGroup as GroupIcon } from "react-icons/md";

import { DUMMY_GROUP_SELECT_OPTION } from "./utils";

interface Props {
  showIcon?: boolean;
}

const NoAccessGroupCell = ({ showIcon = true }: Props) => (
  <div className="flex space-x-2 items-center">
    {showIcon && <GroupIcon size="1.5em" />}
    <div className="blur-xs" data-testid="group-name-skeleton">
      {DUMMY_GROUP_SELECT_OPTION.value}
    </div>
  </div>
);

export default NoAccessGroupCell;
