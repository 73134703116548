import { DEFAULT_MILEAGE_UNIT } from "shared/constants";

import { SelectOption } from "features/ui/Select";

import { FunctionInputConfig } from "./FunctionInput/types";

export const CALCULATED_ATTRIBUTES_TITLE = "Calculated Attributes";

export const CTA_TEXT = "New Calculated Attribute";
export const ATTRIBUTES_PER_PAGE = 10;

export const NAME_LABEL = "Name";
export const DESCRIPTION_LABEL = "Description";

export const DEFAULT_ERROR_MESSAGE = "Something went wrong. Please contact us.";
export const ON_SUCCESS_CREATE_TEXT =
  "Successfully created a new calculated attribute.";
export const UPDATED_SUCCESSFULLY_MESSAGE =
  "Your calculated attribute has been updated successfully.";

export enum CalculatedAttributeFunction {
  TIME_SINCE_EVENT = "timeSinceEvent",
  MILEAGE_SINCE_EVENT = "mileageSinceEvent",
  ENGINE_HOURS_SINCE_EVENT = "engineHoursSinceEvent",
}

export const CALCULATED_ATTRIBUTE_FUNCTION_PARAMETERS: Record<
  CalculatedAttributeFunction,
  FunctionInputConfig[]
> = {
  [CalculatedAttributeFunction.TIME_SINCE_EVENT]: [
    { type: "event_type", labelBefore: "event type", parameter: "eventType" },
    {
      type: "event_filter",
      labelBefore: "event filter",
      parameter: "eventFilter",
    },
    {
      type: "unit",
      labelBefore: "unit",
      parameter: "unit",
    },
  ],
  [CalculatedAttributeFunction.MILEAGE_SINCE_EVENT]: [
    { type: "event_type", labelBefore: "event type", parameter: "eventType" },
    {
      type: "event_filter",
      labelBefore: "event filter",
      parameter: "eventFilter",
    },
  ],
  [CalculatedAttributeFunction.ENGINE_HOURS_SINCE_EVENT]: [
    { type: "event_type", labelBefore: "event type", parameter: "eventType" },
    {
      type: "event_filter",
      labelBefore: "event filter",
      parameter: "eventFilter",
    },
  ],
};

export const DEFAULT_UNIT_PER_FUNCTION_TYPE: Record<
  CalculatedAttributeFunction,
  string | undefined
> = {
  [CalculatedAttributeFunction.TIME_SINCE_EVENT]: "days",
  [CalculatedAttributeFunction.MILEAGE_SINCE_EVENT]: DEFAULT_MILEAGE_UNIT,
  [CalculatedAttributeFunction.ENGINE_HOURS_SINCE_EVENT]: undefined,
};

export const CALCULATED_ATTRIBUTE_FUNCTION_OPTIONS: SelectOption[] = [
  {
    id: CalculatedAttributeFunction.TIME_SINCE_EVENT,
    value: "Time since an event (event type, event filter, unit)",
  },

  {
    id: CalculatedAttributeFunction.MILEAGE_SINCE_EVENT,
    value: "Mileage since an event (event type, event filter)",
  },
  {
    id: CalculatedAttributeFunction.ENGINE_HOURS_SINCE_EVENT,
    value: "Engine hours since an event (event type, event filter, unit)",
  },
];

export const DEFAULT_ATTRIBUTE_PARAMETERS = {
  type: "",
  eventType: undefined,
  eventFilter: undefined,
  unit: undefined,
};

export const UNIT_OPTIONS: SelectOption[] = [
  { id: "seconds", value: "seconds" },
  { id: "minutes", value: "minutes" },
  { id: "hours", value: "hours" },
  { id: "days", value: "days" },
];

export const DEFINITION_TAB = "definition";
export const PREVIEW_TAB = "preview";
