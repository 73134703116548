import { Issue } from "shared/api/issues/api";

import { DEFAULT_ISSUE_DATA } from "pages/shared/constants";
import { IssueFormData } from "pages/shared/types";

import Board from "features/ui/Board";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";

import {
  EDIT_ISSUE_CLAIMS_INDEX,
  EDIT_ISSUE_OVERVIEW_INDEX,
  EDIT_ISSUE_SIGNAL_EVENTS_INDEX,
} from "./constants";
import { CreateIssueProps } from "./Details/CardActions";
import CardClaims from "./Details/CardClaims";
import CardOverview from "./Details/CardOverview";
import CardPopulations from "./Details/CardPopulations";
import CardSignalEvents from "./Details/CardSignalEvents";
import { IssueRequestKeys } from "./types";

interface Props extends IssueRequestKeys {
  issue: Issue;
  issueRequestKey: string;
}

const IssueDetails = ({ issue, issueRequestKeys, issueRequestKey }: Props) => {
  const {
    ID,
    name,
    description,
    notes,
    atRiskPopulationFilter,
    comparisonPopulationFilter,
    claimFilter,
    signalEventOccurrencesFilter,
    statusObj,
    severityObj,
    assignee,
    access,
  } = issue;
  const issueData: IssueFormData = {
    ID,
    atRiskPopulationFilter: filterBuilderQueryToFilterBuilderState(
      atRiskPopulationFilter
    ),
    comparisonPopulationFilter: filterBuilderQueryToFilterBuilderState(
      comparisonPopulationFilter || ""
    ),
    claimFilter: filterBuilderQueryToFilterBuilderState(claimFilter || ""),
    signalEventOccurrencesFilter: filterBuilderQueryToFilterBuilderState(
      signalEventOccurrencesFilter || ""
    ),
    name,
    description: description || DEFAULT_ISSUE_DATA.description,
    notes: notes || DEFAULT_ISSUE_DATA.notes,
    statusID: statusObj?.ID || DEFAULT_ISSUE_DATA.statusID,
    severityID: severityObj?.ID || DEFAULT_ISSUE_DATA.severityID,
    assignee: assignee || DEFAULT_ISSUE_DATA.assignee,
    assignedGroupID: issue.assignedGroup?.ID,
    externalID: issue.externalID,
    acl: access,
  };

  const createIssueProps: CreateIssueProps = {
    issueData,
    issueRequestKeys,
    action: "update",
  };

  const boardElements: JSX.Element[][] = [
    [
      <CardOverview
        issue={issue}
        createIssueProps={{
          ...createIssueProps,
          step: EDIT_ISSUE_OVERVIEW_INDEX,
        }}
        issueRequestKey={issueRequestKey}
      />,
      <CardClaims
        issue={issue}
        createIssueProps={{
          ...createIssueProps,
          step: EDIT_ISSUE_CLAIMS_INDEX,
        }}
      />,
    ],
    [
      <CardPopulations issue={issue} createIssueProps={createIssueProps} />,
      <CardSignalEvents
        issue={issue}
        createIssueProps={{
          ...createIssueProps,
          step: EDIT_ISSUE_SIGNAL_EVENTS_INDEX,
        }}
      />,
    ],
  ];

  return <Board elements={boardElements} />;
};

export default IssueDetails;
