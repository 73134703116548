import { useState } from "react";

import { EventTypeEnum } from "shared/types";

import { TOP_CONTRIBUTORS_TAB_KEY } from "pages/constants";
import {
  getDefaultByGroupBySelectOption,
  useGroupBy,
  useTopContributorsChartSettings,
} from "pages/hooks";
import { sortGroupByOptions } from "pages/shared/analytics/utils";
import TopContributorsGroupBySelector from "pages/shared/TopContributorsGroupBySelector";
import { PAGE_TITLE } from "pages/SignalEventsAnalytics/constants";
import { SignalEventsAnalyticsProps } from "pages/SignalEventsAnalytics/SignalEventsAnalyticsTabs";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";

import {
  DEFAULT_GROUP_BY_ATTRIBUTE,
  SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
  SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
} from "./constants";
import TopChart from "./TopChart";
import TopContributorsTable from "./TopContributorsTable";

const TopContributors = ({
  signalEventsFiltersFilterSortState,
  vehiclesFiltersFilterSortState,
}: SignalEventsAnalyticsProps) => {
  const {
    actions,
    selectedOptions,
    selectedExposureForAPI,
    selectedExposureBucketForAPI,
    handleSelectedOptionsChange,
  } = useTopContributorsChartSettings(
    signalEventsFiltersFilterSortState,
    SIGNAL_EVENTS_TOP_CONTRIBUTORS_CHART_OPTIONS_KEY,
    EventTypeEnum.SIGNAL_EVENT
  );

  const signalEventsFilters =
    signalEventsFiltersFilterSortState?.filtersWithQuickFilters;
  const vehiclesFilters =
    vehiclesFiltersFilterSortState?.filtersWithQuickFilters;

  const {
    groupBySelectOptions,
    selectedGroupByAttribute,
    handleGroupByAttributeChange,
  } = useGroupBy(
    EventTypeEnum.SIGNAL_EVENT,
    signalEventsFiltersFilterSortState,
    DEFAULT_GROUP_BY_ATTRIBUTE,
    SIGNAL_EVENTS_TOP_CONTRIBUTORS_GROUP_BY_OPTIONS_KEY,
    TOP_CONTRIBUTORS_TAB_KEY
  );

  const [generalFilters, setGeneralFilters] = useState<
    FilterGroupState | undefined
  >();

  const sortedGroupBySelectOptions = sortGroupByOptions(groupBySelectOptions);

  const resetState = () => {
    handleGroupByAttributeChange(
      getDefaultByGroupBySelectOption(
        sortedGroupBySelectOptions,
        DEFAULT_GROUP_BY_ATTRIBUTE
      )
    );
    signalEventsFiltersFilterSortState?.resetFilterSortState();
    vehiclesFiltersFilterSortState?.resetFilterSortState();
  };

  return (
    <>
      <TopContributorsGroupBySelector
        groupBySelectOptions={sortedGroupBySelectOptions}
        selectedGroupByAttribute={selectedGroupByAttribute}
        setSelectedGroupByAttribute={handleGroupByAttributeChange}
        enableDrill={true}
        filterQueryString={getFiltersQuery(
          signalEventsFiltersFilterSortState?.filtersWithQuickFilters
        )}
        vehiclesFilterQueryString={getFiltersQuery(
          vehiclesFiltersFilterSortState?.filtersWithQuickFilters
        )}
        pageTitle={PAGE_TITLE}
      />
      <TopChart
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        signalEventsFilters={signalEventsFilters}
        filters={generalFilters}
        onSignalEventsFiltersChange={
          signalEventsFiltersFilterSortState?.manageFilterChange
        }
        onVehiclesFiltersChange={
          vehiclesFiltersFilterSortState?.manageFilterChange
        }
        actions={actions}
        selectedOptions={selectedOptions}
        setSelectedOptions={handleSelectedOptionsChange}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
        onBadRequest={resetState}
      />
      <TopContributorsTable
        selectedGroupByAttribute={selectedGroupByAttribute}
        vehiclesFilters={vehiclesFilters}
        signalEventsFilters={signalEventsFilters}
        onFiltersUpdated={setGeneralFilters}
        onBadRequest={resetState}
        selectedByVehicleAgeExposure={selectedExposureForAPI}
        selectedByVehicleAgeExposureBucket={selectedExposureBucketForAPI}
      />
    </>
  );
};

export default TopContributors;
