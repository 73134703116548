import { useState } from "react";
import { Link } from "react-router-dom";

import { NO_DATA } from "shared/constants";
import { cloneObject } from "shared/utils";

import { Article } from "pages/Docs/types";
import {
  HelpArticlesConfig,
  PageConfigProps,
} from "pages/SuperAdmin/PagesConfig/types";

import Button from "features/ui/Button";
import DropdownSelect, {
  DropdownSelectOption,
} from "features/ui/DropdownSelect/DropdownSelect";
import Input from "features/ui/Input";
import Table, { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";

const URL_PREFIX = "https://viaduct-ai.atlassian.net/wiki/external/";
const ARTICLES_KEY = "articles";

const SCHEMA: SchemaEntry[] = [
  {
    accessor: "title",
    dataType: DataType.STRING,
    label: "Title",
  },
  {
    accessor: "url",
    dataType: DataType.JSX,
    label: "URL",
  },
  {
    accessor: "actions",
    dataType: DataType.JSX,
    label: "Actions",
    align: "center",
    limitedWidthClass: "w-24",
  },
];

const HelpArticlesConfigPage = ({
  pageId,
  config,
  onConfigChange,
}: PageConfigProps<HelpArticlesConfig>) => {
  const [currentArticles, setCurrentArticles] = useState<Article[]>(
    config.articles || []
  );

  const [title, setTitle] = useState("");
  const [url, setUrl] = useState("");

  const articlePositions: DropdownSelectOption[] = currentArticles.map(
    (article, index) => ({
      id: (index + 1).toString(),
      value: `After "${article.title}"`,
    })
  );

  const allPositions: DropdownSelectOption[] = [
    { id: "0", value: "At the begining" },
    ...articlePositions,
  ];

  const [currentPosition, setCurrentPosition] = useState<DropdownSelectOption>(
    allPositions[0]
  );

  const onAddArticle = (title: string, url: string, index: number) => {
    const updatedCurrentArticles = cloneObject(currentArticles);
    updatedCurrentArticles.splice(index, 0, { title, url: URL_PREFIX + url });

    setCurrentArticles(updatedCurrentArticles);

    setTitle("");
    setUrl("");
    setCurrentPosition(allPositions[0]);

    onConfigChange(pageId, ARTICLES_KEY, updatedCurrentArticles);
  };

  const onDeleteArticle = (index: number) => {
    const updatedCurrentArticles = cloneObject(currentArticles);
    updatedCurrentArticles.splice(index, 1);

    setCurrentArticles(updatedCurrentArticles);
    onConfigChange(pageId, ARTICLES_KEY, updatedCurrentArticles);
  };

  const formatRow = (article: Article, index: number) => {
    const { url } = article;

    return {
      ...article,
      url: (
        <Link
          to={url}
          target="_blank"
          className="text-metabase-blue hover:underline"
        >
          {url}
        </Link>
      ),
      actions: (
        <Button
          color="error"
          label="Delete"
          size="small"
          className="w-20"
          onClick={() => onDeleteArticle(index)}
        />
      ),
    };
  };

  const formattedData = currentArticles.map(formatRow);

  const isTitleValid = title.length > 0;
  const isUrlEmpty = url.length === 0;

  const canAddArticle = isTitleValid && !isUrlEmpty;

  return (
    <div className="mt-5">
      <div>Add new custom help article:</div>
      <div className="mt-5 space-y-2">
        <div className="flex w-full">
          <div className="w-[10%] mr-4 leading-9 text-right">Title</div>
          <div className="w-[90%]">
            <Input
              type="text"
              label="Title"
              onChange={({ target: { value } }) => {
                setTitle(value);
              }}
              value={title}
              placeholder="Enter title"
            />
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-[10%] mr-4 leading-9 text-right">URL</div>
          <div className="w-[90%]">
            <Input
              type="text"
              label="URL"
              onChange={({ target: { value } }) => {
                const documentId = value.substring(URL_PREFIX.length);
                setUrl(documentId.trim());
              }}
              value={URL_PREFIX + url}
              placeholder="URL"
            />
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-[10%] mr-4 leading-9 text-right">Position</div>
          <div className="w-[90%]">
            <DropdownSelect
              options={allPositions}
              label={currentPosition.value.toString()}
              onSelect={(selectedOption) => setCurrentPosition(selectedOption)}
            />
          </div>
        </div>
        <div className="flex w-full">
          <div className="w-[10%] mr-4 leading-9 text-right" />
          <div className="w-[90%]">
            <Button
              color="primary"
              variant="outlined"
              label="Add"
              size="small"
              className="w-20"
              onClick={() => {
                onAddArticle(
                  title,
                  url,
                  parseInt(currentPosition.id.toString())
                );
              }}
              disabled={!canAddArticle}
            />
          </div>
        </div>
      </div>

      <div className="mt-10">
        <Table schema={SCHEMA} data={formattedData} dense />
        {formattedData.length === 0 && NO_DATA}
      </div>
    </div>
  );
};

export default HelpArticlesConfigPage;
