import { useCallback, useEffect, useState } from "react";
import { format } from "date-fns";
import { CategoricalChartState } from "recharts/types/chart/types";

import { Granularity } from "shared/api/constants";
import { API_DATE_FORMAT } from "shared/constants";

import { SelectedChartOptions } from "features/ui/charts/Actions/types";

import { SelectedDateRange } from "./types";
import { addGranularityToDate } from "./utils";

export const useDateRangeSelection = (
  selectedOptions: SelectedChartOptions[],
  granularity: string
) => {
  const [selectedTimestamps, setSelectedTimestamps] =
    useState<SelectedDateRange>({ min: undefined, max: undefined });

  // clear the selected timestamps when granularity changes since the
  // data points are different
  useEffect(() => {
    clearSelection();
  }, [granularity]);

  const handleKeyPress = useCallback((event: KeyboardEvent) => {
    if (event.key === "Escape") {
      clearSelection();
    }
  }, []);

  // handling keyboard navigation
  useEffect(() => {
    // Add event listener
    document.addEventListener("keydown", handleKeyPress);

    // Cleanup: Remove event listener when the hook is unmounted
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, [handleKeyPress]);

  const handleTimestampClick = (event: CategoricalChartState) => {
    if (!event.activeLabel || !selectedTimestamps) return;

    const timestamp = parseInt(event.activeLabel);

    if (!selectedTimestamps.min) {
      selectedTimestamps.min = timestamp;
      setSelectedTimestamps({ ...selectedTimestamps });

      return;
    }

    if (!selectedTimestamps.max && timestamp > selectedTimestamps.min) {
      selectedTimestamps.max = timestamp;
      setSelectedTimestamps({ ...selectedTimestamps });

      return;
    }

    if (!selectedTimestamps.max && timestamp < selectedTimestamps.min) {
      selectedTimestamps.max = selectedTimestamps.min;
      selectedTimestamps.min = timestamp;
      setSelectedTimestamps({ ...selectedTimestamps });

      return;
    }

    if (!selectedTimestamps.max) {
      selectedTimestamps.max = timestamp;
      setSelectedTimestamps({ ...selectedTimestamps });

      return;
    }

    if (
      timestamp > selectedTimestamps.min &&
      timestamp < selectedTimestamps.max
    ) {
      if (
        timestamp - selectedTimestamps.min <
        selectedTimestamps.max - timestamp
      ) {
        selectedTimestamps.min = timestamp;
        setSelectedTimestamps({ ...selectedTimestamps });

        return;
      } else {
        selectedTimestamps.max = timestamp;
        setSelectedTimestamps({ ...selectedTimestamps });

        return;
      }
    }

    if (timestamp > selectedTimestamps.max) {
      selectedTimestamps.max = timestamp;
      setSelectedTimestamps({ ...selectedTimestamps });

      return;
    }

    if (timestamp < selectedTimestamps.min) {
      selectedTimestamps.min = timestamp;
      setSelectedTimestamps({ ...selectedTimestamps });

      return;
    }
  };

  const clearSelection = () => {
    setSelectedTimestamps({ min: undefined, max: undefined });
  };

  const getRangeWithGranularityAndFieldName = () => {
    const fieldName: string | number | undefined = selectedOptions.find(
      (item) => item.id === "x"
    )?.optionId;
    const granularity = selectedOptions.find(
      (item) => item.id === "granularity"
    )?.optionId;

    if (
      !granularity ||
      !fieldName ||
      !selectedTimestamps ||
      !selectedTimestamps.min
    )
      return;

    const from = format(selectedTimestamps.min, API_DATE_FORMAT);

    // not having max date is a valid case, the filter will be bound only by the min date
    if (!selectedTimestamps.max) return { from, to: undefined, fieldName };

    const toWithBuffer = addGranularityToDate(
      new Date(selectedTimestamps.max),
      granularity as Granularity
    );

    const to = format(toWithBuffer.getTime(), API_DATE_FORMAT);

    return { from, to, fieldName };
  };

  return {
    selectedTimestamps,
    clearSelection,
    handleTimestampClick,
    getRangeWithGranularityAndFieldName,
  } as const;
};
