import { format } from "date-fns";
import { TooltipProps } from "recharts";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import { SensorReadingsTimelineGrouping } from "shared/api/sensors/api";
import { DATE_WITH_TIME_FORMAT, SHORT_DATE_FORMAT } from "shared/constants";

interface CustomProps {
  xAxisLabel: string;
  grouping: SensorReadingsTimelineGrouping;
  descriptions: Record<string, string>;
}

const formatValue = (
  value: any,
  name: any,
  xAxisLabel: string,
  grouping: SensorReadingsTimelineGrouping
) =>
  name === xAxisLabel
    ? format(
        value,
        grouping === "none" ? DATE_WITH_TIME_FORMAT : SHORT_DATE_FORMAT
      )
    : value;

const EventsTimelineChartTooltip = ({
  payload,
  xAxisLabel,
  grouping,
  descriptions,
}: TooltipProps<ValueType, NameType> & CustomProps) => {
  if (!payload || payload.length === 0) return null;

  const signalEvent = payload.find((item) => item.name === "Signal Event")
    ?.value as string;

  const description = signalEvent ? descriptions[signalEvent] : "";

  return (
    <div className="flex flex-col bg-white shadow-sm px-2 py-3 text-xs">
      {payload.map(({ name, value }) => (
        <div key={name}>
          <span className="font-bold">{name}: </span>
          <span>{formatValue(value, name, xAxisLabel, grouping)}</span>
        </div>
      ))}
      <div>
        <span className="font-bold">Description: </span>
        <span>{description}</span>
      </div>
    </div>
  );
};

export default EventsTimelineChartTooltip;
