import { format } from "date-fns";
import { DuckMessageAuthor } from "duck/context/types";
import { GPT4O_MODEL_SPEC } from "duck/graph/constants";
import { DuckGraphParams } from "duck/graph/types";
import { DUCK_PREVIOUS_RUN_ID_KEY } from "duck/ui/constants";
import { hasAllEnvVarsForViaDuck } from "duck/ui/utils";
import {
  AIMessage,
  HumanMessage,
  SystemMessage,
} from "@langchain/core/messages";
import { CompiledGraph } from "@langchain/langgraph/web";

import getGraph from "./getGraph";
import { GraphStateType } from "./state";
import {
  drawGraph,
  getTracer,
  loadGraphStateFromSessionStorage,
  saveGraphStateToSessionStorage,
} from "./utils";
import versionJson from "./version.json";

let previousRunId: string | undefined;
let currentRunId: string | undefined;
let prevState: GraphStateType | undefined = undefined;

// Load previous state from session storage if available
prevState = loadGraphStateFromSessionStorage();

// Main function to call agent
const callAgent = async (params: DuckGraphParams): Promise<void> => {
  if (!hasAllEnvVarsForViaDuck()) {
    console.warn(
      "Missing environment variables. Please set VITE_LANGCHAIN_ENDPOINT, VITE_LANGCHAIN_API_KEY, VITE_LANGCHAIN_PROJECT, and VITE_OPENAI_API_KEY."
    );
    throw new Error("Missing environment variables.");
  }

  const graph = await getGraph(params, false);

  if (params.captureImages) {
    await drawGraph(graph as CompiledGraph<any>, "graph.png");

    params.uiHandlers.setAgentResponse(
      "I sent images of the graph to your downloads folder."
    );

    return;
  }

  const messages = params.messageHistory.map((message) =>
    message.author === DuckMessageAuthor.HUMAN
      ? new HumanMessage(message.message, { name: "USER" })
      : new AIMessage(message.message, { name: "DUCK" })
  );

  const formattedDate = format(new Date(), "EEEE MMMM d, yyyy");
  messages.push(
    new SystemMessage(`The current date is ${formattedDate}`, {
      name: "SYSTEM",
    })
  );

  messages.push(new HumanMessage(params.text, { name: "USER" }));

  const duckGraphVersion = versionJson.version;

  // Retrieve previous run id from session storage if not already in memory
  previousRunId =
    currentRunId ??
    sessionStorage.getItem(DUCK_PREVIOUS_RUN_ID_KEY) ??
    undefined;
  if (currentRunId) {
    sessionStorage.setItem(DUCK_PREVIOUS_RUN_ID_KEY, currentRunId);
  }

  currentRunId = params.runId;

  const graphRunnableConfig = {
    configurable: { thread_id: params.threadId },
    callbacks: [getTracer()],
    runId: currentRunId,
    runName: "DUCK Graph",
    tags: [duckGraphVersion],
    metadata: {
      tenant: params.tenant,
      user: params.user,
      isViaductUser: params.user.endsWith("@viaduct.ai"),
      duckGraphVersion,
      nodeEnv: import.meta.env.MODE,
      lsModelName: GPT4O_MODEL_SPEC.modelName,
      lsProvider: "openai",
      lsTemperature: GPT4O_MODEL_SPEC.temperature,
    },
  };

  // Merge the previous state with the current inputs
  const inputState = {
    ...prevState,
    userInput: params.text,
    messages,
    pageState: params.currentState,
    previousRunId,
  };

  const outputState = await graph.invoke(inputState, graphRunnableConfig);

  // Update the in-memory state and persist it in session storage
  prevState = outputState;
  saveGraphStateToSessionStorage(outputState);
};

export default callAgent;
