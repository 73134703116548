import classNames from "classnames";
import { BiPencil as EditIcon } from "react-icons/bi";
import { Collapse, IconButton } from "@mui/material";

import Card from "features/ui/Card";
import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import {
  FilterGroupState,
  FilterRowState,
} from "features/ui/Filters/FilterBuilder/types";
import { hasSomeFiltersAppliedFilterGroupState } from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";

export interface FilterStateElement {
  name: string;
  filters: FilterGroupState;
  quickFilters?: FilterRowState[];
  schema: SchemaEntry[];
  baseEntityText?: string;
  onEdit?: () => void;
}

interface Props {
  open: boolean;
  filterStates: FilterStateElement[];
  additionalClasses?: string;
  quickFiltersLabel?: string;
}

const NO_FILTERS_TEXT = "No filters have been specified.";

const QuickFiltersPresentation = ({
  label,
  onlyQuickFilters,
}: {
  label: string;
  onlyQuickFilters?: boolean;
}) => (
  <div className="text-xs">
    <span className="capitalize text-viaduct-black font-medium">
      {!onlyQuickFilters && "And "}All
    </span>{" "}
    <span className="italic text-gray-500">applied {label}</span>
  </div>
);

const FiltersSummary = ({
  open,
  filterStates,
  additionalClasses,
  quickFiltersLabel,
}: Props) => (
  <Collapse in={open}>
    <Card
      classNames={classNames("mt-3 text-base! bg-gray-50!", additionalClasses)}
      variant="outlined"
    >
      <div className="flex space-x-3">
        {filterStates.map(
          ({ name, filters, schema, baseEntityText, onEdit, quickFilters }) => (
            <div
              className="flex-1 group"
              key={name}
              data-testid="filters-overview-summary"
            >
              <div className="text-gray-500 font-bold mb-2 flex items-center">
                {name}
                {onEdit && (
                  <IconButton
                    size="small"
                    onClick={onEdit}
                    data-testid={`edit-filter-${name}`}
                    className="opacity-0 group-hover:opacity-100 ml-1"
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </div>

              {(hasSomeFiltersAppliedFilterGroupState(filters) ||
                (quickFilters && quickFilters.length > 0)) && (
                <FilterQueryPresentation
                  filterState={filters}
                  tableSchema={schema}
                  baseEntityText={baseEntityText}
                  testId="summary-filter-presentation"
                />
              )}

              {quickFilters && quickFilters?.length > 0 && (
                <QuickFiltersPresentation
                  onlyQuickFilters={
                    !hasSomeFiltersAppliedFilterGroupState(filters)
                  }
                  label={quickFiltersLabel ?? "Quick Filters"}
                ></QuickFiltersPresentation>
              )}

              {!hasSomeFiltersAppliedFilterGroupState(filters) &&
                quickFilters?.length === 0 && (
                  <div className="text-sm">{NO_FILTERS_TEXT}</div>
                )}
            </div>
          )
        )}
      </div>
    </Card>
  </Collapse>
);

export default FiltersSummary;
