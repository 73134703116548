import { FailureMode } from "shared/api/failureModes/api";
import { useFailureModeRiskModel } from "shared/api/failureModes/hooks";
import { SHORT_DATE_FORMAT } from "shared/constants";
import { useConfigContext } from "shared/contexts/ConfigContext";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { formatDate } from "shared/utils";

import VehicleFilters from "pages/Vehicles/VehicleFilters";

import { getPendingFiltersKey } from "features/ui/Filters/FilterWizard/utils";
import { useFilterSortState } from "features/ui/Filters/hooks";

import FailureModePredictionsTable from "./PredictionsTable";
import RiskDistribution from "./RiskDistribution";

interface Props {
  failureMode: FailureMode;
}

const ResultsTab = ({ failureMode }: Props) => {
  const pageKey = `failure_mode_predictions_vehicles_${failureMode.ID}`;

  const {
    pages: { failureModes },
  } = useConfigContext();

  const { error } = useFailureModeRiskModel({
    id: failureMode.ID as string,
  });

  const { attributeAccessors } = useVehiclesSchema();

  const vehiclesFiltersFilterSortState = useFilterSortState({
    pageKey,
    pendingFiltersLocalStorageKey: getPendingFiltersKey(pageKey),
    schemaAttributes: attributeAccessors,
  });

  // we check if the Risk Model is linked and then hide the whole tab if not
  if (error?.response?.status === 404) {
    return (
      <div className="py-4 text-gray-400 text-sm">
        No Results available for Failure Mode {failureMode.name}.
      </div>
    );
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <VehicleFilters
          vehiclesFilterSortState={vehiclesFiltersFilterSortState}
          hideCollectionFilter
        />
        {failureMode.inferenceRunDate && (
          <div className="text-xs text-gray-500 pr-3">
            <strong>Last Run On</strong>:{" "}
            {formatDate(failureMode.inferenceRunDate, SHORT_DATE_FORMAT, true)}
          </div>
        )}
      </div>
      {failureModes?.riskDistribution && (
        <RiskDistribution
          vehiclesFilter={vehiclesFiltersFilterSortState.filters}
          failureModeID={failureMode.ID}
        />
      )}
      <FailureModePredictionsTable
        failureModeID={failureMode.ID}
        vehicleFilters={vehiclesFiltersFilterSortState.filters}
      />
    </>
  );
};

export default ResultsTab;
