import { SelectedChartOptions } from "features/ui/charts/Actions/types";

import { CHART_ACTIONS } from "./constants";

export const useChartActions = (selectedOptions: SelectedChartOptions[]) => {
  if (selectedOptions.length === 0) {
    return { chartActions: CHART_ACTIONS };
  }

  const firstOption = selectedOptions.find(({ id }) => id === "legend");

  if (firstOption?.optionId === "none") {
    const filtered = CHART_ACTIONS.filter(({ id }) => id === "legend");

    return { chartActions: filtered };
  }

  return { chartActions: CHART_ACTIONS };
};
