import { useMemo } from "react";
import { generatePath, Link } from "react-router-dom";

import {
  FailureMode,
  getFailureModesExport,
} from "shared/api/failureModes/api";
import {
  useFailureModes,
  useFailureModesCount,
} from "shared/api/failureModes/hooks";
import { getSortFilter } from "shared/api/utils";
import { MAX_ROWS_DOWNLOAD_LIMIT } from "shared/constants";
import { useFailureModeSchema } from "shared/schemas/failureModeSchema";
import { SortBy } from "shared/types";

import APIError from "features/ui/APIError";
import DownloadAction from "features/ui/DownloadAction";
import { getFiltersQuery } from "features/ui/Filters/FilterBuilder/utils";
import FiltersOverview from "features/ui/Filters/FiltersOverview";
import { useFilterSortState } from "features/ui/Filters/hooks";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";
import { OnSortParams } from "features/ui/Table";
import PaginatedTable from "features/ui/Table/PaginatedTable";
import TableCount from "features/ui/Table/TableCount";

import { routes } from "services/routes";

import StatusBadge from "./StatusBadge";

const formatRow = (failureMode: FailureMode) => {
  const { ID, name, status } = failureMode;

  return {
    ...failureMode,
    name: (
      <Link
        to={{
          pathname: generatePath(routes.failureMode, {
            id: encodeURIComponent(ID),
          }),
        }}
        className="text-metabase-blue hover:underline"
      >
        {name}
      </Link>
    ),
    status: <StatusBadge status={status} />,
  };
};

const ROWS_PER_PAGE = 10;

const DEFAULT_SORT: SortBy = {
  status: "desc",
  name: "asc",
};

const PAGE_KEY = "failureModes";
const TITLE = "Failure Modes";

const FailureModes = () => {
  const { schema, attributeAccessors } = useFailureModeSchema();

  const {
    filters,
    sort,
    manageFilterChange,
    manageOnSortChange,
    resetFilterSortState,
    resetFilters,
    initialized: filtersInitialized,
  } = useFilterSortState({
    pageKey: PAGE_KEY,
    defaultSort: DEFAULT_SORT,
    schemaAttributes: attributeAccessors,
  });

  const requestParams = {
    filter: getFiltersQuery(filters),
    sort: getSortFilter(sort),
  };

  const { data, isLoading, error, ...paginationData } = useFailureModes({
    limit: ROWS_PER_PAGE,
    ...requestParams,
  });

  const handleSorting = ({ accessor, sort }: OnSortParams) => {
    // only allow sorting by one column at the time
    manageOnSortChange({ [accessor]: sort });
  };

  const {
    isLoading: countIsLoading,
    data: countData,
    error: countError,
  } = useFailureModesCount(requestParams);

  // re-format the data - but only when data changes
  const formattedData = useMemo(() => data?.map(formatRow), [data]);

  const downloadDisabled = !formattedData || formattedData.length === 0;

  return (
    <>
      <PageHeaderWrapper title={TITLE}>
        <PageHeaderActionsWrapper>
          <DownloadAction
            disabled={downloadDisabled}
            downloadFunc={getFailureModesExport}
            fileName="failure_modes"
            requestParams={{
              ...requestParams,
              limit: MAX_ROWS_DOWNLOAD_LIMIT,
            }}
            count={countData?.count as number}
            entityName="failure mode"
            filters={filters}
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <div className="flex my-2 items-center">
        <FiltersOverview
          filters={filters}
          tableSchema={schema}
          onFiltersReset={resetFilters}
        />
        <TableCount
          extraClasses="ml-auto self-end"
          count={countData?.count as number}
          entityName="failure mode"
          isLoading={countIsLoading}
          error={!!countError}
        />
      </div>
      {error && <APIError error={error} onBadRequest={resetFilterSortState} />}
      {!error && (
        <PaginatedTable
          {...paginationData}
          data={formattedData}
          schema={schema}
          isLoading={isLoading}
          loadingRows={ROWS_PER_PAGE}
          sortBy={sort}
          onSort={handleSorting}
          filtersInitialized={filtersInitialized}
          onFiltersReset={resetFilters}
          onFilterChange={manageFilterChange}
          filters={filters}
          testId="failure-modes-table"
          pageKey={PAGE_KEY}
          dense
        />
      )}
    </>
  );
};

export default FailureModes;
