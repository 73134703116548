import { routes } from "instanceManagement/routes";
import { AiOutlineHome as HomeIcon } from "react-icons/ai";

import Sidebar from "features/layout/SidebarNav/Sidebar";
import { NAV_ITEM_ICON_SIZE, NavGroup } from "features/layout/SidebarNav/utils";

import { RouteConfig } from "services/routesConfig";

interface Props {
  expanded: boolean;
  isOverflow: boolean;
  setExpanded: (expanded: boolean) => void;
  toggleExpand: () => void;
  routeConfig: RouteConfig;
}

const SidebarNav = ({
  expanded,
  isOverflow,
  setExpanded,
  toggleExpand,
  routeConfig,
}: Props) => {
  const navGroups: NavGroup[] = [
    {
      title: "Tenants",
      links: [
        {
          icon: <HomeIcon size={NAV_ITEM_ICON_SIZE} />,
          text: "Tenants",
          to: routes.tenants,
          hasUnlistedSubroutes: true,
        },
      ],
    },
  ];

  return (
    <Sidebar
      navGroups={navGroups}
      expanded={expanded}
      setExpanded={setExpanded}
      toggleExpand={toggleExpand}
      isOverflow={isOverflow}
      routeConfig={routeConfig}
    />
  );
};

export default SidebarNav;
