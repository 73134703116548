import {
  API_DATE_FORMAT,
  API_DATE_FORMAT_W_TIME,
  ISO_FORMAT,
} from "shared/constants";
import { VEHICLE_COLLECTION_FILTER_KEY } from "shared/filterDefinitions";
import { SortBy } from "shared/types";
import {
  formatDate,
  splitIgnoringDoubleQuotes,
  wrapInQuotesAndJoin,
} from "shared/utils";

import {
  VEHICLE_COLLECTION_API_FIELD_NAME,
  VEHICLE_COLLECTION_REQUEST_KEY,
} from "pages/Collections/constants";

import { FILTER_OPERATOR_TO_API_FILTER } from "features/ui/Filters/constants";
import { FilterRowState } from "features/ui/Filters/FilterBuilder/types";
import {
  FilterOperator,
  FilterState,
  OccursFilterOptionKeys,
} from "features/ui/Filters/types";
import {
  getAttributesFromFilter,
  getOperatorFromAPIOperator,
  isFilterStateCorrect,
} from "features/ui/Filters/utils";
import { SchemaEntry } from "features/ui/Table";
import { DataType } from "features/ui/Table/TableBodyCell/types";

export type APIFilterOp =
  | "eq"
  | "neq"
  | "gt"
  | "gte"
  | "lt"
  | "lte"
  | "between"
  | "in"
  | "nin"
  | "is"
  | "isn"
  | "like"
  | "nlike"
  | "inlast"
  | "startswith"
  | "nstartswith"
  | "occurs"
  | "noccurs"
  | "exists"
  | "nexists";

type APIFilterValue =
  | string
  | number
  | null
  | string[]
  | number[]
  | undefined
  | boolean;

export interface APIFilter {
  name: string;
  value: APIFilterValue;
  op: APIFilterOp;
}

export const getOptionsQuery = (
  options: Record<OccursFilterOptionKeys, string | number>
): string =>
  `windowSize="${options.windowSize}",windowType="${options.windowType}",windowDirection="${options.windowDirection}"`;

const getValuesArray = (values: string): string[] => {
  // catches pipes (|) within the string that are not within quotes ("") and splits values on that
  // - this allows us to have | inside our values
  const regex = /\|+(?=(?:(?:[^"]*"){2})*[^"]*$)/g;

  return values.split(regex).map((str) => str.replaceAll('"', ""));
};

const filterValuesHaveEmbeddedFilter = (operator: FilterOperator) =>
  [
    FilterOperator.NOT_EXISTS,
    FilterOperator.EXISTS,
    FilterOperator.OCCURS,
    FilterOperator.NOT_OCCURS,
  ].includes(operator);

/**
 * TODO: we wanna ditch this if possible as the new filter state is now FilterGroupState.
 *
 * - if attribute appears multiple times, we take the 1st appearance's values and ignore the rest
 *   (except for lte/gte combination which becomes "between" operator)
 * - we also transform collection filter, like so:
 *    {"VIN":{"values":["collectionV1=5e06a976-d4f5-401e-ac80-17c7513b3531"],"operator":"in"}}
 *    becomes
 *    {"collection":{"values":["5e06a976-d4f5-401e-ac80-17c7513b3531"],"operator":"in"}}
 */
export const getFilterStateFromFilter = (
  filter?: string | null
): FilterState => {
  if (!filter) return {};

  // Check if the filter is encoded in the FilterState format:
  // - this allows us to support the old way of storing filters in the URL
  try {
    if (isFilterStateCorrect(filter)) {
      return filter as any as FilterState;
    }
  } finally {
  }

  const attrs = splitIgnoringDoubleQuotes(filter, ",");

  return attrs.reduce((acc, filterPart) => {
    const fieldAndValue = filterPart.split(/=(.*)/s);

    const fieldNamePart = fieldAndValue[0];
    const valuePart = fieldAndValue[1];

    const apiOperator = valuePart.substring(
      0,
      valuePart.indexOf(":")
    ) as APIFilterOp;
    const operator = getOperatorFromAPIOperator(apiOperator);

    const values = valuePart.split(/:(.*)/s)[1];

    // Collection is unique and is handled differently.
    if (
      fieldNamePart === VEHICLE_COLLECTION_API_FIELD_NAME &&
      values.includes(VEHICLE_COLLECTION_REQUEST_KEY) &&
      values.includes("=")
    ) {
      const collectionValues = getValuesArray(values.split("=")[1]);

      return {
        ...acc,
        [VEHICLE_COLLECTION_FILTER_KEY]: {
          values: collectionValues,
          operator: FilterOperator.IN,
        },
      };
    }

    // If fieldNamePart already exists in the current filter string, we ignore this part of the filter
    if (getAttributesFromFilter(acc).includes(fieldNamePart)) return { ...acc };

    let valuesArray = getValuesArray(values);

    // when filter is embedded, we only have one value that we do not have to separate
    if (filterValuesHaveEmbeddedFilter(operator)) {
      valuesArray = [values];
    }

    return {
      ...acc,
      [fieldNamePart]: {
        values: valuesArray,
        operator,
      },
    };
  }, {} as FilterState);
};

export const formatAPIDate = (value: string, dataType?: DataType) => {
  if (value === "null") return value;

  switch (dataType) {
    case "date":
      return formatDate(value, API_DATE_FORMAT, true);
    case "dateWithTimeNoTZ":
      return formatDate(value, API_DATE_FORMAT_W_TIME);
    case "dateWithTimeUTC":
      return formatDate(value, ISO_FORMAT);
    default:
      return value;
  }
};

export const getSortFilter = (sortBy?: SortBy, schema?: SchemaEntry[]) => {
  if (!sortBy) return;

  return Object.entries(sortBy)
    .map(([key, value]) => {
      const keyOverride = schema?.find(({ accessor }) => accessor === key)?.sort
        ?.fieldNameForAPI;
      const keyForAPI = keyOverride || key;

      return `${keyForAPI}:${value}`;
    })
    .join(",");
};

export const applyAdditionalSorting = (
  sortBy?: SortBy,
  additionalSortMap: Record<string, string[]> = {}
) => {
  if (!sortBy) return;

  for (const [sortKey, sortDirection] of Object.entries(sortBy)) {
    if (additionalSortMap[sortKey]) {
      for (const additionalFilter of additionalSortMap[sortKey]) {
        sortBy[additionalFilter] = sortDirection;
      }
    }
  }

  return sortBy;
};

export const hasPathTraversal = <Args>(args: Args): boolean => {
  if (args && typeof args === "object") {
    return Object.values(args)
      .filter((val) => val)
      .some(
        (value) =>
          value.toString().includes("/.") || value.toString().includes("../")
      );
  }

  return false;
};

export const createURL = (arr: string[]): string =>
  arr.map((s) => encodeURIComponent(s)).join("/");

export const VEHICLE_ATTRIBUTES_PREFIX = "vehicle.";
const NON_QUOTED_OPERATORS = ["occurs", "noccurs", "exists", "nexists"];

// TODO: add specific tests just for this as well
export const getFilterQuery = ({
  attribute,
  operator,
  values,
}: FilterRowState): string => {
  if (!attribute || !operator || !values || values.length === 0) return "";

  let val =
    typeof values == "boolean" || NON_QUOTED_OPERATORS.includes(operator)
      ? values
      : `"${values}"`;

  if (Array.isArray(values) && !NON_QUOTED_OPERATORS.includes(operator)) {
    val = wrapInQuotesAndJoin(values);
  }

  const apiOperator = FILTER_OPERATOR_TO_API_FILTER[operator];

  // Collection is unique and is handled differently
  if (attribute === VEHICLE_COLLECTION_FILTER_KEY) {
    return `${VEHICLE_COLLECTION_API_FIELD_NAME}=${apiOperator}:${VEHICLE_COLLECTION_REQUEST_KEY}=${val}`;
  }

  return `${attribute}=${apiOperator}:${val}`;
};
