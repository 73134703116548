import { useFlags } from "launchdarkly-react-client-sdk";

import { useListClaimsCount } from "shared/api/claims/hooks";
import { useListCustomRecordsCount } from "shared/api/customRecords/hooks";
import { useListInspectionsCount } from "shared/api/inspections/hooks";
import { useListRepairsCount } from "shared/api/repairs/hooks";
import { useListSignalEventsOccurrencesCount } from "shared/api/signalEvents/hooks";
import { useConfigContext } from "shared/contexts/ConfigContext";
import {
  useClaimsFiltersSchema,
  useCustomRecordsFiltersSchema,
  useInspectionsFiltersSchema,
  useRepairsFiltersSchema,
  useSignalEventOccurrencesFiltersSchema,
} from "shared/hooks";

import {
  CLAIMS_PAGE_KEY,
  VEHICLES_PAGE_KEY as CLAIMS_VEHICLES_PAGE_KEY,
} from "pages/ClaimAnalytics/constants";
import CountWithLink from "pages/FailureModes/FailureMode/CountWithLink";
import {
  INSPECTIONS_PAGE_KEY,
  INSPECTIONS_VEHICLES_PAGE_KEY,
} from "pages/InspectionAnalytics/constants";
import {
  REPAIRS_PAGE_KEY,
  REPAIRS_VEHICLES_PAGE_KEY,
} from "pages/RepairAnalytics/constants";
import {
  SIGNAL_EVENTS_PAGE_KEY,
  VEHICLES_PAGE_KEY as SIGNAL_EVENTS_VEHICLES_PAGE_KEY,
} from "pages/SignalEventsAnalytics/constants";

import FilterQueryPresentation from "features/ui/Filters/FilterBuilder/FilterQueryPresentation";
import { filterBuilderQueryToFilterBuilderState } from "features/ui/Filters/FilterBuilder/utils";
import FormSection from "features/ui/FormSection";

import { routes } from "services/routes";

interface Props {
  claimFilters: string | null;
  inspectionFilters: string | null;
  repairFilters: string | null;
  signalEventFilters: string | null;
  customRecordFilters: string | null;
  vehicleFilters: string;
}

const EvidenceTab = ({
  claimFilters,
  inspectionFilters,
  repairFilters,
  signalEventFilters,
  customRecordFilters,
  vehicleFilters,
}: Props) => {
  const {
    claimAnalytics: claimAnalyticsFlag,
    signalEventsAnalytics: signalEventsAnalyticsFlag,
    repairAnalytics: repairAnalyticsFlag,
    inspectionAnalytics: inspectionAnalyticsFlag,
  } = useFlags();
  const {
    pages: {
      claimAnalytics,
      signalEventsAnalytics,
      repairAnalytics,
      inspectionAnalytics,
    },
  } = useConfigContext();

  const vehicleFiltersState = filterBuilderQueryToFilterBuilderState(
    vehicleFilters || ""
  );

  const claimsFilterSchema = useClaimsFiltersSchema();
  const claimFiltersState = filterBuilderQueryToFilterBuilderState(
    claimFilters || ""
  );

  const repairFilterSchema = useRepairsFiltersSchema();
  const repairFiltersState = filterBuilderQueryToFilterBuilderState(
    repairFilters || ""
  );

  const inspectionFilterSchema = useInspectionsFiltersSchema();
  const inspectionFiltersState = filterBuilderQueryToFilterBuilderState(
    inspectionFilters || ""
  );

  const signalEventFilterSchema = useSignalEventOccurrencesFiltersSchema();
  const signalEventFiltersState = filterBuilderQueryToFilterBuilderState(
    signalEventFilters || ""
  );

  const customRecordFilterSchema = useCustomRecordsFiltersSchema();
  const customRecordFiltersState = filterBuilderQueryToFilterBuilderState(
    customRecordFilters || ""
  );

  return (
    <>
      {claimFilters && (
        <FormSection
          title="Claims"
          extraTitleComponent={
            <CountWithLink
              filterState={claimFiltersState}
              vehicleFilterState={vehicleFiltersState}
              entityName="Claim"
              pageKey={CLAIMS_PAGE_KEY}
              vehiclesPageKey={CLAIMS_VEHICLES_PAGE_KEY}
              dataFetchHook={useListClaimsCount}
              route={routes.claimAnalytics}
              disabled={!claimAnalyticsFlag || !claimAnalytics}
            />
          }
        >
          <FilterQueryPresentation
            filterState={claimFiltersState}
            tableSchema={claimsFilterSchema}
            dense={false}
          />
        </FormSection>
      )}
      {inspectionFilters && (
        <FormSection
          title="Inspections"
          extraTitleComponent={
            <CountWithLink
              filterState={inspectionFiltersState}
              vehicleFilterState={vehicleFiltersState}
              entityName="Inspection"
              pageKey={INSPECTIONS_PAGE_KEY}
              vehiclesPageKey={INSPECTIONS_VEHICLES_PAGE_KEY}
              dataFetchHook={useListInspectionsCount}
              route={routes.inspectionAnalytics}
              disabled={!inspectionAnalyticsFlag || !inspectionAnalytics}
            />
          }
        >
          <FilterQueryPresentation
            filterState={inspectionFiltersState}
            tableSchema={inspectionFilterSchema}
            dense={false}
          />
        </FormSection>
      )}
      {repairFilters && (
        <FormSection
          title="Repairs"
          extraTitleComponent={
            <CountWithLink
              filterState={repairFiltersState}
              vehicleFilterState={vehicleFiltersState}
              entityName="Repair record"
              pageKey={REPAIRS_PAGE_KEY}
              vehiclesPageKey={REPAIRS_VEHICLES_PAGE_KEY}
              dataFetchHook={useListRepairsCount}
              route={routes.repairAnalytics}
              disabled={!repairAnalyticsFlag || !repairAnalytics}
            />
          }
        >
          <FilterQueryPresentation
            filterState={repairFiltersState}
            tableSchema={repairFilterSchema}
            dense={false}
          />
        </FormSection>
      )}
      {signalEventFilters && (
        <FormSection
          title="Signal Events"
          extraTitleComponent={
            <CountWithLink
              filterState={signalEventFiltersState}
              vehicleFilterState={vehicleFiltersState}
              entityName="Signal Event"
              pageKey={SIGNAL_EVENTS_PAGE_KEY}
              vehiclesPageKey={SIGNAL_EVENTS_VEHICLES_PAGE_KEY}
              dataFetchHook={useListSignalEventsOccurrencesCount}
              route={routes.signalEventAnalytics}
              disabled={!signalEventsAnalyticsFlag || !signalEventsAnalytics}
            />
          }
        >
          <FilterQueryPresentation
            filterState={signalEventFiltersState}
            tableSchema={signalEventFilterSchema}
            dense={false}
          />
        </FormSection>
      )}
      {customRecordFilters && (
        <FormSection
          title="Custom Records"
          extraTitleComponent={
            <CountWithLink
              filterState={customRecordFiltersState}
              vehicleFilterState={vehicleFiltersState}
              entityName="Custom Record"
              dataFetchHook={useListCustomRecordsCount}
            />
          }
        >
          <FilterQueryPresentation
            filterState={customRecordFiltersState}
            tableSchema={customRecordFilterSchema}
            dense={false}
          />
        </FormSection>
      )}
    </>
  );
};

export default EvidenceTab;
