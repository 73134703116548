import {
  AlertDefinition,
  AlertEventType,
} from "shared/api/alertDefinitions/api";
import { APIListValuesRequest } from "shared/api/api";
import { Issue, listIssues, ListIssuesRequest } from "shared/api/issues/api";
import {
  listSuggestedIssues,
  SuggestedIssue,
} from "shared/api/suggestedIssues/api";
import { listVehiclesValues } from "shared/api/vehicles/api";

import { AlertDefinitionState } from "pages/AlertDefinitions/types";

import { DEFAULT_NUM_SEARCH_RESULTS } from "./constants";

export const getObjectOptions = async (
  eventType: AlertEventType,
  filter: string
): Promise<
  {
    id: string;
    value: string;
  }[]
> => {
  switch (eventType) {
    case AlertEventType.VEHICLE: {
      const args: APIListValuesRequest = {
        fieldName: "VIN",
        like: filter || undefined,
        limit: DEFAULT_NUM_SEARCH_RESULTS,
      };
      const res = await listVehiclesValues(args);

      return res.data.distinctValues.map((value) => ({
        id: value || "",
        value: value || "",
      }));
    }
    case AlertEventType.ISSUE:
      const args: ListIssuesRequest = {
        filter: filter ? 'name=like:"' + filter + '"' : undefined,
        limit: DEFAULT_NUM_SEARCH_RESULTS,
      };
      const res = await listIssues(args);

      return res.data.map((value) => ({
        id: value.ID || "",
        value: value.name || "",
      }));

    case AlertEventType.SUGGESTED_ISSUE: {
      const args: ListIssuesRequest = {
        filter: filter ? 'name=like:"' + filter + '"' : undefined,
        limit: DEFAULT_NUM_SEARCH_RESULTS,
      };
      const res = await listSuggestedIssues(args);

      return res.data.map((value) => ({
        id: value.ID || "",
        value: value.name || "",
      }));
    }

    default:
      return [];
  }
};

export const getObjectEventText = (eventType: AlertEventType) => {
  switch (eventType) {
    case AlertEventType.VEHICLE:
      return "Select vehicle to alert on";
    case AlertEventType.ISSUE:
      return "Select issue to alert on";
    case AlertEventType.SUGGESTED_ISSUE:
      return "Select suggested issue to alert on";
    default:
      return "";
  }
};

export const getObjectEventLabelText = (eventType: AlertEventType) => {
  switch (eventType) {
    case AlertEventType.VEHICLE:
      return "Search for a Vehicle by VIN";
    case AlertEventType.ISSUE:
      return "Search for an Issue by name";
    case AlertEventType.SUGGESTED_ISSUE:
      return "Search for a Suggested Issue by ID";
    default:
      return "";
  }
};

export const getObjectName = (
  alertDefinition: AlertDefinition | AlertDefinitionState
) => {
  switch (alertDefinition.eventType) {
    case AlertEventType.VEHICLE:
      return alertDefinition.watchedObjectID;
    case AlertEventType.SUGGESTED_ISSUE:
      return (alertDefinition.watchedObject as SuggestedIssue)?.name
        ? (alertDefinition.watchedObject as SuggestedIssue)?.name
        : alertDefinition.watchedObjectID;
    case AlertEventType.ISSUE:
      return (alertDefinition.watchedObject as Issue)?.name
        ? (alertDefinition.watchedObject as Issue)?.name
        : alertDefinition.watchedObjectID;
    default:
      return alertDefinition.watchedObjectID;
  }
};
