import { arrayMoveImmutable } from "array-move";
import { toast } from "react-toastify";
import { DragEndEvent } from "@dnd-kit/core";

import { APIError } from "shared/api/api";

export const showErrorToast = (error: APIError): void => {
  toast.error(
    <div>
      {error.message}
      {error.response?.data.details && (
        <div className="text-gray-400 text-xs">
          {JSON.stringify(error.response.data.details)}
        </div>
      )}
    </div>
  );
};

export const handleOnSortEnd = <T extends { id: string }>(
  event: DragEndEvent,
  setItems: React.Dispatch<React.SetStateAction<T[]>>
) => {
  const { active, over } = event;

  if (active.id !== over?.id) {
    setItems((items) => {
      const oldIndex = items.findIndex((item) => item.id === active.id);
      const newIndex = items.findIndex((item) => item.id === over?.id);

      return arrayMoveImmutable(items, oldIndex, newIndex);
    });
  }
};
