import { Navigate, useParams } from "react-router-dom";

import { useConfigContext } from "shared/contexts/ConfigContext";

import MetabaseDashboard from "pages/MetabaseDashboardPage/MetabaseDashboard";

type DashboardRouteParams = {
  id: string;
};

const Dashboard = () => {
  const { id } = useParams<DashboardRouteParams>();
  const { general } = useConfigContext();

  const dashboard = general.metabaseDashboards.find((d) => d.id === id);

  if (!dashboard || !id) {
    return <Navigate to="/" replace />;
  }

  return <MetabaseDashboard dashboardId={id!} title={dashboard.name} />;
};
export default Dashboard;
