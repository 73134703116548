import {
  ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
  ATTRIBUTE_RETRIEVAL_K,
} from "duck/graph/constants";
import { isStructuredTool, Source } from "duck/graph/nodes/utils";
import { PageHandler } from "duck/graph/PageHandler";
import getAttributeRetrievalTool from "duck/graph/tools/getAttributeRetrievalTool";
import getGoToTabTool from "duck/graph/tools/getGoToTabTool";
import getSortTableTool from "duck/graph/tools/getSortTableTool";
import getUpdateFilterTool from "duck/graph/tools/getUpdateFilterTool";
import { DuckGraphParams } from "duck/graph/types";
import { StructuredTool } from "@langchain/core/tools";

import {
  ISSUES_CHART_KEY,
  ISSUES_KEY,
  ISSUES_PAGE_KEY,
  ISSUES_TITLE,
  SUGGESTED_ISSUES_CHART_KEY,
  SUGGESTED_ISSUES_KEY,
  SUGGESTED_ISSUES_PAGE_KEY,
  SUGGESTED_ISSUES_TITLE,
} from "pages/Issues/constants";

import getUpdateChartSettingsTool from "./getUpdateChartSettingsTool";
import { getValidateFilter, getValidateSort } from "./utils";

/**
 * @summary Get the tools for the vin view agent.
 * The tools are specific to vin view, and are bound to the UI handlers
 * for vin view.
 * @param pageHandler Provides functions to adjust the url and query params.
 * @returns The full set of tools for the signal event analytics agent.
 */
export const getRetrievalTools = (params: DuckGraphParams) => {
  const duckAccess = params.uiHandlers.duckAccess;

  return [
    duckAccess.issues.issuesTab &&
      (getAttributeRetrievalTool(
        Source.ISSUE,
        ATTRIBUTE_RETRIEVAL_K,
        ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
        params.uiHandlers.setEphemeralMessage
      ) as StructuredTool),
    duckAccess.issues.suggestedIssuesTab &&
      (getAttributeRetrievalTool(
        Source.SUGGESTED_ISSUE,
        ATTRIBUTE_RETRIEVAL_K,
        ATTRIBUTE_RETRIEVAL_DISTANCE_THRESHOLD,
        params.uiHandlers.setEphemeralMessage
      ) as StructuredTool),
  ].filter(isStructuredTool);
};

export const getActionTools = (
  params: DuckGraphParams,
  pageHandler: PageHandler
) => {
  const duckAccess = params.uiHandlers.duckAccess;
  const hasIssuesTab = duckAccess.issues.issuesTab;
  const hasSuggestedIssuesTab = duckAccess.issues.suggestedIssuesTab;

  return [
    hasIssuesTab &&
      getUpdateFilterTool({
        pageHandler,
        pageKey: ISSUES_PAGE_KEY,
        validateFunction: getValidateFilter(Source.ISSUE),
        setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
        setAgentResponse: params.uiHandlers.setAgentResponse,
        source: Source.ISSUE,
        selectedFilterString:
          params.currentState.issues.issuesFilterQueryString,
      }),
    hasSuggestedIssuesTab &&
      getUpdateFilterTool({
        pageHandler,
        pageKey: SUGGESTED_ISSUES_PAGE_KEY,
        validateFunction: getValidateFilter(Source.SUGGESTED_ISSUE),
        setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
        setAgentResponse: params.uiHandlers.setAgentResponse,
        source: Source.SUGGESTED_ISSUE,
        selectedFilterString:
          params.currentState.issues.suggestedIssuesFilterQueryString,
      }),
    hasIssuesTab &&
      getSortTableTool({
        pageHandler,
        pageKey: ISSUES_PAGE_KEY,
        validateFunction: getValidateSort(Source.ISSUE),
        setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
        setAgentResponse: params.uiHandlers.setAgentResponse,
        source: Source.ISSUE,
        selectedSortString: params.currentState.issues.issuesSortQueryString,
      }),
    hasSuggestedIssuesTab &&
      getSortTableTool({
        pageHandler,
        pageKey: SUGGESTED_ISSUES_PAGE_KEY,
        validateFunction: getValidateSort(Source.SUGGESTED_ISSUE),
        setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
        setAgentResponse: params.uiHandlers.setAgentResponse,
        source: Source.SUGGESTED_ISSUE,
        selectedSortString:
          params.currentState.issues.suggestedIssuesSortQueryString,
      }),
    hasIssuesTab &&
      getGoToTabTool({
        pageHandler,
        setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
        setAgentResponse: params.uiHandlers.setAgentResponse,
        tabKey: ISSUES_KEY,
        tabTitle: ISSUES_TITLE,
        selectedTabKey: params.currentState.selectedTab,
      }),
    hasSuggestedIssuesTab &&
      getGoToTabTool({
        pageHandler,
        setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
        setAgentResponse: params.uiHandlers.setAgentResponse,
        tabKey: SUGGESTED_ISSUES_KEY,
        tabTitle: SUGGESTED_ISSUES_TITLE,
        selectedTabKey: params.currentState.selectedTab,
      }),
    hasIssuesTab &&
      getUpdateChartSettingsTool({
        pageHandler,
        setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
        setAgentResponse: params.uiHandlers.setAgentResponse,
        chartOptions: params.availableData.issues?.issuesChartOptions || {},
        selectedChartOptions:
          params.currentState.issues.selectedIssuesChartOptions,
        chartName: "Issues",
        pageKey: ISSUES_PAGE_KEY,
        tabKey: ISSUES_KEY,
        chartKey: ISSUES_CHART_KEY,
        toolDescription: `Use this tool when the user wants to update the settings of the Issues chart. The following settings can be specified:
- measure: The metric shown on the y-axis.
- lookbackWindow, or "Count Across": The time period shown
- valueType, or "Show as _ change from previous period": Show the change as a percentage or absolute value. This is only relevant when the lookbackWindow is not "All time".
- groupBy, "X-axis": The attribute to group by on the x-axis.
- splitByIssueSource, or "Y-axis": if true it separately shows created and promoted issues
- bucketBy, or "Cell-axis": Sub-grouping attribute.`,
      }),
    hasSuggestedIssuesTab &&
      getUpdateChartSettingsTool({
        pageHandler,
        setEphemeralMessage: params.uiHandlers.setEphemeralMessage,
        setAgentResponse: params.uiHandlers.setAgentResponse,
        chartOptions:
          params.availableData.issues?.suggestedIssuesChartOptions || {},
        selectedChartOptions:
          params.currentState.issues.selectedSuggestedIssuesChartOptions,
        chartName: "Suggested Issues",
        pageKey: SUGGESTED_ISSUES_PAGE_KEY,
        tabKey: SUGGESTED_ISSUES_KEY,
        chartKey: SUGGESTED_ISSUES_CHART_KEY,
        toolDescription: `Use this tool when the user wants to update the settings of the Suggested Issues chart. The following settings can be specified:
- measure: The metric shown on the y-axis.
- lookbackWindow, or "Count Across": The time period shown
- valueType, or "Show as _ change from previous period": Show the change as a percentage or absolute value. This is only relevant when the lookbackWindow is not "All time".
- groupBy, "X-axis": The attribute to group by on the x-axis.
- bucketBy, or "Cell-axis": Sub-grouping attribute.`,
      }),
  ].filter(isStructuredTool);
};
