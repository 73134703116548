import DropdownWithSearch from "features/ui/DropdownWithSearch/DropdownWithSearch";
import { SelectOption } from "features/ui/Select";

interface Props {
  selected: AnyAll;
  onChange: (selected: SelectOption<AnyAll>) => void;
  disabled?: boolean;
  autoFocus?: boolean;
}

export type AnyAll = "any" | "all";

export const ANY_ALL_OPTIONS: SelectOption<AnyAll>[] = [
  { id: "all", value: "ALL of the following are true" },
  { id: "any", value: "ANY of the following are true" },
];

const AnyAllSelect = ({ selected, onChange, disabled, autoFocus }: Props) => {
  const selectedOption: SelectOption<AnyAll> =
    ANY_ALL_OPTIONS.find((option) => option.id === selected) ||
    ANY_ALL_OPTIONS[0];

  return (
    <DropdownWithSearch
      autoFocus={autoFocus}
      openOnAutoFocus={false}
      options={ANY_ALL_OPTIONS}
      selectedOption={selectedOption}
      onSelectedOptionChange={onChange}
      testId="any-all-select-wrap"
      disableClearable
      fullWidth
      className="max-w-72"
      disabled={disabled}
    />
  );
};

export default AnyAllSelect;
