import React from "react";
import { useParams } from "react-router-dom";

import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

import FleetInfo from "./FleetInfo";
import FleetViewTabs from "./FleetViewTabs";

type FleetViewParams = {
  fleetName: string;
};

const FleetView = () => {
  const { fleetName } = useParams<FleetViewParams>();
  const fleet = decodeURIComponent(fleetName!);

  return (
    <>
      <PageHeaderWrapper title={fleet} />
      <FleetInfo fleetName={fleet} />
      <FleetViewTabs fleetName={fleet} />
    </>
  );
};

export default FleetView;
