import { useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { MdClose as CloseIcon } from "react-icons/md";
import { RiFilter2Fill as FilterIcon } from "react-icons/ri";
import { IconButton, Paper, Popper } from "@mui/material";

import { APIFilter } from "shared/api/utils";

import { FilterGroupState } from "features/ui/Filters/FilterBuilder/types";
import {
  getFilterGroupStateTopLevelRowAttributes,
  updateOrAddRowFilterGroupState,
} from "features/ui/Filters/FilterBuilder/utils";
import SingleFilter from "features/ui/Filters/SingleFilter";
import {
  FilterChangeCallback,
  FilterChangeProps,
  FilterSchemaItem,
} from "features/ui/Filters/types";

import styles from "./TableHeaderCell.module.css";

const ICON_SIZE = 15;
const CLOSE_ICON_SIZE = 21;

interface Props {
  accessor: string;
  filter?: FilterSchemaItem;
  activeFilters?: FilterGroupState;
  staticFilters?: APIFilter[];
  filtersInitialized?: boolean;
  onFiltersReset?: (fieldNames?: string[]) => void;
  onFilterChange?: FilterChangeCallback;
  onShowFilterTooltip?: (show: boolean) => void;
  fixed?: boolean;
  pageKey: string;
}

const TableHeaderFilter = ({
  accessor,
  filter,
  activeFilters,
  staticFilters,
  filtersInitialized,
  onFiltersReset,
  onFilterChange,
  onShowFilterTooltip,
  pageKey,
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const arrowRef = useRef(null);
  const fieldName = filter?.fieldName;

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setVisible(true);
    onShowFilterTooltip && onShowFilterTooltip(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setVisible(false);
    onShowFilterTooltip && onShowFilterTooltip(true);
  };

  const [filtersToShowApplied, setFiltersToShowApplied] =
    useState(activeFilters);

  const onValueAndOperatorChange = ({
    op_id: operator,
    values = [],
  }: FilterChangeProps) => {
    if (!fieldName) return;

    setFiltersToShowApplied((prev) => {
      // to prev.children array, add (or override) row with attribute = fieldName with operator & values
      const newFilter = updateOrAddRowFilterGroupState(prev, {
        id: fieldName,
        type: "row",
        attribute: fieldName,
        operator,
        values,
      });

      return newFilter;
    });
  };

  const hasActiveFilter = useMemo(() => {
    const activeFilterKeys =
      getFilterGroupStateTopLevelRowAttributes(activeFilters);

    return activeFilterKeys.some(
      (f) =>
        fieldName === f || (fieldName?.endsWith("_") && f.startsWith(fieldName))
    );
  }, [fieldName, activeFilters]);

  if (!fieldName || !onFilterChange) return null;

  const handleOnFilterChange = (props: FilterChangeProps) => {
    setVisible(false);
    onFilterChange(props);
  };

  const handleOnFiltersReset = () => {
    setVisible(false);
    onFiltersReset && onFiltersReset();
  };

  return (
    <div className={styles.filterIconWrap} data-testid={`filter-${fieldName}`}>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ color: "#9CA3AF" }} // equivalent to text-gray-400
        className={classNames("hover:text-metabase-blue text-gray-400", {
          "text-metabase-blue!": hasActiveFilter || visible,
        })}
      >
        <FilterIcon size={ICON_SIZE} className="text-inherit!" />
      </IconButton>

      <Popper
        anchorEl={anchorEl}
        open={visible}
        placement="bottom"
        data-testid={`filter-${accessor}-tooltip`}
        className="z-10"
        modifiers={[
          {
            name: "arrow",
            enabled: true,
            options: { element: arrowRef?.current ?? null },
          },
          {
            name: "offset",
            options: {
              offset: [0, 5],
            },
          },
        ]}
      >
        <div
          ref={arrowRef}
          style={{
            position: "absolute",
            fontSize: 7,
            width: "2em",
            height: "2em",
            top: "-1em",
            left: "50%",
            transform: "translateX(-50%) rotate(45deg)",
            backgroundColor: "#fff",
            boxShadow: "-1px -1px 3px rgba(0, 0, 0, 0.1)",
          }}
        />
        <Paper elevation={3} className="p-2">
          <div className="flex flex-row justify-end">
            <IconButton
              onClick={handleClose}
              size="small"
              className="p-0! mr-1!"
            >
              <CloseIcon
                size={CLOSE_ICON_SIZE}
                data-testid="close-filter-tooltip"
              />
            </IconButton>
          </div>

          <SingleFilter
            filter={filter}
            activeFilters={filtersToShowApplied}
            onFilterChange={handleOnFilterChange}
            onFiltersReset={handleOnFiltersReset}
            onValueOrOperatorChange={onValueAndOperatorChange}
            staticFilters={staticFilters}
            filtersInitialized={filtersInitialized}
            pageKey={pageKey}
          />
        </Paper>
      </Popper>
    </div>
  );
};

export default TableHeaderFilter;
