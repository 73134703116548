import classNames from "classnames";
import { formatDate } from "date-fns";
import { MdOutlineTimer } from "react-icons/md";

import { DATE_WITH_TIME_FORMAT } from "shared/constants";
import { pluralize } from "shared/utils";

import Tooltip from "features/ui/Tooltip";

interface Props {
  latestReadAt: string;
  timeDiffMinutes: number;
  isLate: boolean;
}

const TireTiming = ({ latestReadAt, timeDiffMinutes, isLate }: Props) => {
  const description = (
    <div className="font-light">
      Last reading{" "}
      <span
        className={classNames(
          {
            "text-red-600": isLate,
          },
          "font-bold"
        )}
      >
        {timeDiffMinutes} {pluralize("minute", timeDiffMinutes)} ago
      </span>
      <br /> at <span>{formatDate(latestReadAt, DATE_WITH_TIME_FORMAT)}</span>
    </div>
  );

  return (
    <Tooltip title={description} testId="reading-timing-tooltip">
      <div className="leading-5 flex justify-center w-full cursor-default">
        {isLate ? (
          <>
            <MdOutlineTimer className="text-amber-500 mr-1" size={22} /> delayed
          </>
        ) : (
          <>current</>
        )}
      </div>
    </Tooltip>
  );
};

export default TireTiming;
