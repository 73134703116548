import { useEffect, useState } from "react";
import { MdFileUpload as CloudUploadIcon } from "react-icons/md";
import Skeleton from "react-loading-skeleton";
import { toast } from "react-toastify";
import { mutate } from "swr";
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Button, Paper, Typography } from "@mui/material";

import { updateAdminVehicleImages } from "shared/api/superadmin/api";
import { useAdminVehicleImages } from "shared/api/superadmin/hooks";
import useVehiclesSchema from "shared/schemas/vehiclesSchema";
import { randomID } from "shared/utils";

import { handleOnSortEnd } from "pages/SuperAdmin/utils";

import APIError from "features/ui/APIError";
import SelectFilter from "features/ui/Filters/FilterTypes/SelectFilter";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

import { VehicleImageState } from "./types";
import VehicleImageRow from "./VehicleImageRow";

const ALLLOWED_VEHICLE_ATTRIBUTES = [
  "vehicleMake",
  "vehicleModel",
  "vehicleModelYear",
  "trimLevel",
];

// limit image size to 300kB
const MAX_IMAGE_SIZE = 300;

const VehicleImages = () => {
  const { data = [], isLoading, error, requestKey } = useAdminVehicleImages();
  const [pendingImages, setPendingImages] = useState<VehicleImageState[]>([]);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const [vehicleAttributes, setVehicleAttributes] = useState<
    Record<string, string | undefined>
  >({});

  useEffect(() => {
    if (data && !isLoading && !error && pendingImages.length === 0) {
      setPendingImages(
        data.map((image) => ({
          ...image,
          id: randomID(),
        }))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, error]);

  const onSortEnd = (event: DragEndEvent) => {
    handleOnSortEnd(event, setPendingImages);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      if (event.target.files[0].size > MAX_IMAGE_SIZE * 1024) {
        toast.error(
          `Image size exceeds the maximum limit of ${MAX_IMAGE_SIZE}kB`
        );
      } else {
        setSelectedFile(event.target.files[0]);
      }
    }
  };

  const handleAdd = async () => {
    if (!selectedFile) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      const base64String = reader.result as string;
      const base64Data = base64String.split(",")[1];

      setPendingImages((pendingImgs) => [
        ...(pendingImgs || []),
        {
          imageData: base64Data,
          contentType: selectedFile.type,
          imageOrder: pendingImgs.length,
          vehicleMake: vehicleAttributes.vehicleMake,
          vehicleModel: vehicleAttributes.vehicleModel,
          vehicleModelYear:
            parseInt(vehicleAttributes.vehicleModelYear || "") || undefined,
          trimLevel: vehicleAttributes.trimLevel,
          id: randomID(),
        },
      ]);

      setSelectedFile(null);
      setVehicleAttributes({});
      setInitialized(false);
      setTimeout(() => setInitialized(true), 0);
    };

    reader.readAsDataURL(selectedFile);
  };

  const handleDelete = (id: string) => {
    // remove the image at the given index. use function that returns a new array
    // to avoid mutating the original array
    setPendingImages(
      (pendingImgs) => pendingImgs?.filter((img) => img.id !== id) || null
    );
  };

  const handleSave = async () => {
    const updatedImages = pendingImages.map((img, index) => ({
      imageData: img.imageData,
      contentType: img.contentType,
      vehicleMake: img.vehicleMake || null,
      vehicleModel: img.vehicleModel || null,
      vehicleModelYear: img.vehicleModelYear || null,
      trimLevel: img.trimLevel || null,
      imageOrder: index,
    }));

    await updateAdminVehicleImages(updatedImages)
      .then(async () => {
        toast.success("Vehicle images saved successfully");
        await mutate(requestKey);
        setPendingImages([]);
      })
      .catch((error) => {
        toast.error(
          error?.response?.data?.details || "Saving vehicle images failed"
        );
      });
  };

  const filteredVehicleSchema = useVehiclesSchema().schema.filter(
    (schemaItem) => ALLLOWED_VEHICLE_ATTRIBUTES.includes(schemaItem.accessor)
  );

  const [initialized, setInitialized] = useState(true);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <div className="flex flex-col h-full">
      <PageHeaderWrapper title="Vehicle Images">
        <PageHeaderActionsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSave}
            disabled={isLoading || !pendingImages}
          >
            Save Changes
          </Button>
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>

      <div className="max-w-4xl mb-4">
        <Typography variant="body2" color="text.secondary">
          This page allows you to upload images for vehicles. You can associate
          images with specific vehicle attributes (make, model, year, and trim
          level) and arrange them in your preferred order by dragging. The image
          displayed on VINView will be the first image that matches the
          vehicle's attributes. Each image must be under {MAX_IMAGE_SIZE}kB in
          size. Remember to click 'Save Changes' after making modifications to
          persist your updates.
        </Typography>
      </div>

      {error && <APIError error={error} />}

      <div className="mb-8 shrink-0">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="md:w-1/2 flex flex-col space-y-2">
            <h2>Vehicle Attributes</h2>
            {initialized &&
              filteredVehicleSchema.map((item) => (
                <SelectFilter
                  label={item.label}
                  key={item.label}
                  multiple={false}
                  fieldName={item.accessor}
                  onChange={(values) => {
                    setVehicleAttributes({
                      ...vehicleAttributes,
                      [item.accessor]:
                        values && values.length > 0 ? values[0] : undefined,
                    });
                  }}
                  filterType={item.filter?.filterType}
                  filterDataType={item.filter?.filterDataType}
                  {...item.filter}
                />
              ))}
          </div>
          <div className="md:w-1/2">
            <h2>Image</h2>
            <div className="p-1 flex">
              {initialized && (
                <Paper
                  variant="outlined"
                  className="w-1/2"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "12rem",
                    border: "2px dashed",
                    borderColor: "grey.300",
                    borderRadius: 2,
                    cursor: "pointer",
                    transition: (theme) =>
                      theme.transitions.create([
                        "border-color",
                        "background-color",
                      ]),
                    "&:hover": {
                      borderColor: "primary.main",
                      bgcolor: "primary.50",
                    },
                    "&.dragover": {
                      borderColor: "primary.main",
                      bgcolor: "primary.50",
                    },
                  }}
                  onClick={() => {
                    const input = document.createElement("input");
                    input.type = "file";
                    input.accept = "image/*";
                    input.onchange = (e) =>
                      handleFileChange(
                        e as unknown as React.ChangeEvent<HTMLInputElement>
                      );
                    input.click();
                  }}
                  onDragOver={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.currentTarget.classList.add("dragover");
                  }}
                  onDragLeave={(e) => {
                    e.preventDefault();
                    e.currentTarget.classList.remove("dragover");
                  }}
                  onDrop={(e) => {
                    e.preventDefault();
                    e.currentTarget.classList.remove("dragover");
                    const file = e.dataTransfer.files[0];
                    if (file) {
                      if (file.size > MAX_IMAGE_SIZE * 1024) {
                        toast.error(
                          `Image size exceeds the maximum limit of ${MAX_IMAGE_SIZE}kB`
                        );
                      } else {
                        setSelectedFile(file);
                      }
                    }
                  }}
                >
                  <CloudUploadIcon className="text-4xl text-gray-500 mb-4" />
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    className="p-3"
                    align="center"
                  >
                    Drag & drop an image here, or click to select
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Maximum size: {MAX_IMAGE_SIZE}kB
                  </Typography>
                </Paper>
              )}
              {selectedFile && (
                <div className="flex flex-col items-center justify-center w-1/2 mt-2">
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    align="center"
                  >
                    Selected image:
                  </Typography>
                  <img
                    width={200}
                    src={selectedFile ? URL.createObjectURL(selectedFile) : ""}
                    alt="selected"
                    className="i"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="text-center">
          <Button
            variant="outlined"
            color="primary"
            onClick={handleAdd}
            disabled={!selectedFile}
            className="mt-4!"
          >
            Add Image
          </Button>
        </div>
      </div>
      <div className="mb-8 flex-1">
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={onSortEnd}
        >
          <SortableContext
            items={pendingImages}
            strategy={verticalListSortingStrategy}
          >
            {isLoading && (
              <div className="flex flex-col space-y-4">
                <Skeleton count={4} height={120} />
              </div>
            )}
            {pendingImages.map((image, sortIndex) => (
              <VehicleImageRow
                key={image.id}
                image={image}
                sortIndex={sortIndex}
                onDelete={handleDelete}
                schema={filteredVehicleSchema}
              />
            ))}
          </SortableContext>
        </DndContext>
      </div>
    </div>
  );
};

export default VehicleImages;
