import { SyntheticEvent, useState } from "react";

import { AccordionArrowPosition } from "shared/types";

import Accordion, { AccordionProps } from "features/ui/Accordion";

interface AccordionGroupProps {
  data: AccordionProps[];
  allowMultiOpen?: boolean;
  arrowPosition?: AccordionArrowPosition;
}

type ExpandedAccordionKeys = Record<string, boolean>;

const getDefaultExpandedAccordions = (data: AccordionProps[]) =>
  data.reduce(
    (acc, { id, expanded }) => ({
      ...acc,
      [id]: expanded || false,
    }),
    {}
  );

const AccordionGroup = ({
  data,
  allowMultiOpen,
  arrowPosition,
}: AccordionGroupProps) => {
  const [expandedAccordions, setExpandedAccordions] =
    useState<ExpandedAccordionKeys>(getDefaultExpandedAccordions(data));

  const handleAccordionChange =
    (id: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      if (allowMultiOpen) {
        setExpandedAccordions({
          ...expandedAccordions,
          [id]: isExpanded,
        });
      } else {
        setExpandedAccordions({
          [id]: isExpanded,
        });
      }
    };

  return (
    <div>
      {data.map(({ id, title, children }) => (
        <Accordion
          key={id}
          id={id}
          title={title}
          expanded={expandedAccordions[id] || false}
          onChange={handleAccordionChange(id)}
          arrowPosition={arrowPosition}
        >
          {children}
        </Accordion>
      ))}
    </div>
  );
};

export default AccordionGroup;
