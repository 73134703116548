import React from "react";
import classNames from "classnames";
import { RiShareBoxFill as ExtLinkIcon } from "react-icons/ri";
import { NavLink } from "react-router-dom";

import Tooltip from "features/ui/Tooltip";

import { RouteConfig } from "services/routesConfig";

import { NavItem } from "./utils";

interface Props {
  item: NavItem;
  routeConfig?: RouteConfig;
  menuExpanded: boolean;
  onClick?: () => void;
}

const NavBarItem = ({ item, routeConfig, menuExpanded, onClick }: Props) => {
  const { to, hasUnlistedSubroutes, ...otherLinkProps } = item;

  const navBarItem = (
    <NavLink
      to={to}
      className={({ isActive }) =>
        classNames(
          "flex items-center space-x-2 font-medium text-sm leading-none md:leading-3 py-2",
          {
            "justify-center": !menuExpanded,
            "hover:bg-gray-100": !isActive,
            "bg-gray-200": isActive && routeConfig?.wayFindingListingPage,
            "bg-blue-100": isActive && !routeConfig?.wayFindingListingPage,
          }
        )
      }
      end={!hasUnlistedSubroutes}
      {...otherLinkProps}
    >
      <div
        className={classNames({
          "pl-4 lg:pl-6": menuExpanded,
          "justify-center": !menuExpanded,
        })}
      >
        {item.icon}
      </div>
      {menuExpanded && <div>{item.text}</div>}
    </NavLink>
  );

  return (
    <li
      data-testid="menu-item"
      className="transition duration-200 cursor-pointer text-gray-600 tracking-normal"
      onClick={onClick}
    >
      {!menuExpanded && (
        <Tooltip title={item.text} placement="right">
          {navBarItem}
        </Tooltip>
      )}
      {menuExpanded && navBarItem}
    </li>
  );
};

interface ExternalNavBarItemProps {
  link: NavItem;
  menuExpanded: boolean;
}

export const ExternalNavBarItem = ({
  link,
  menuExpanded,
}: ExternalNavBarItemProps) => {
  const navBarItem = (
    <a
      href={link.to.toString()}
      target="_blank"
      rel="noreferrer"
      className={classNames(
        "flex items-center space-x-2 font-medium text-sm leading-none md:leading-3 py-2 hover:bg-gray-100",
        { "justify-center": !menuExpanded }
      )}
    >
      <div
        className={classNames({
          "pl-4 lg:pl-6": menuExpanded,
          "justify-center": !menuExpanded,
        })}
      >
        {link.icon}
      </div>
      {menuExpanded && (
        <div className="flex">
          {link.text} <ExtLinkIcon className="inline ml-1" />
        </div>
      )}
    </a>
  );

  return (
    <li
      data-testid="menu-item"
      className="transition duration-200 cursor-pointer text-gray-600 tracking-normal"
    >
      {!menuExpanded && (
        <Tooltip title={link.text} placement="right">
          {navBarItem}
        </Tooltip>
      )}
      {menuExpanded && navBarItem}
    </li>
  );
};

interface NavBarItemWithChildrenProps {
  children: React.ReactElement;
  title: string;
  menuExpanded: boolean;
}

export const NavBarItemWithChildren = ({
  children,
  title,
  menuExpanded,
}: NavBarItemWithChildrenProps) => (
  <li
    data-testid="menu-item"
    className="transition duration-200 cursor-pointer text-gray-600 tracking-normal py-2 hover:bg-gray-100"
  >
    {!menuExpanded && (
      <Tooltip title={title} placement="right">
        {children}
      </Tooltip>
    )}
    {menuExpanded && children}
  </li>
);

export default NavBarItem;
