import React from "react";
import { HiChevronDown } from "react-icons/hi";
import Skeleton from "react-loading-skeleton";

import { formatNumber, roundPercentageToNDecimals } from "shared/utils";

import Card from "features/ui/Card";
import Tooltip from "features/ui/Tooltip";

import { RELATIVE_TOOLTIP_TEXT, TOTAL_TOOLTIP_TEXT } from "./constants";

interface DataMetricCardProps {
  title: string;
  value: number;
  diff: number;
  prefix?: string;
  isLoading?: boolean;
  onClick?: () => void;
  toggleOptions?: {
    title: string;
    isOn: boolean;
    onToggle: () => void;
  };
}

const DataMetricCard = ({
  title,
  value,
  diff,
  prefix = "",
  isLoading = false,
  onClick,
  toggleOptions,
}: DataMetricCardProps) => {
  const getDiffDisplay = (diff: number) => {
    const symbol = diff === 0 ? "―" : diff > 0 ? "▲" : "▼";
    const color = diff === 0 ? "gray" : diff > 0 ? "red" : "green";

    return (
      <>
        <span className={`text-${color}-500 mr-1`}>{symbol}</span>
        <span className="text-gray-500">
          {roundPercentageToNDecimals(Math.abs(diff))}%
        </span>
      </>
    );
  };

  const displayedTitle =
    toggleOptions && toggleOptions.isOn ? toggleOptions.title : title;
  const getTitleDisplay = () => {
    if (!toggleOptions) {
      return (
        <h4 className="mb-2 text-gray-500 text-center">{displayedTitle}</h4>
      );
    }

    return (
      <h4
        className="mb-2 text-gray-500 text-center cursor-pointer hover:opacity-80 transition-all duration-200"
        onClick={toggleOptions.onToggle}
      >
        {displayedTitle}
        <HiChevronDown className="inline-block ml-1" />
      </h4>
    );
  };

  if (isLoading) {
    return (
      <Card>
        <div className="p-2">
          <h4 className="mb-2 text-gray-500 text-center">{displayedTitle}</h4>
          <Skeleton height={30} width="100%" />
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div className="p-2 flex flex-col justify-between h-full">
        {getTitleDisplay()}
        <div className="flex items-center justify-center">
          <Tooltip
            title={<span className="text-xs">{TOTAL_TOOLTIP_TEXT}</span>}
          >
            <span
              className={`text-2xl text-metabase-blue mr-2 ${onClick ? "cursor-pointer hover:opacity-80" : ""}`}
              onClick={onClick}
            >
              {prefix}
              {formatNumber(value)}
            </span>
          </Tooltip>
          <Tooltip
            title={<span className="text-xs">{RELATIVE_TOOLTIP_TEXT}</span>}
          >
            <span className="text-sm">{getDiffDisplay(diff)}</span>
          </Tooltip>
        </div>
      </div>
    </Card>
  );
};

export default DataMetricCard;
