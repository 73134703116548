import {
  parseSortKeySuffix,
  Source,
  validateApiRequest,
  validateSortOrder,
} from "duck/graph/nodes/utils";

import { listIssuesRequestURI } from "shared/api/issues/api";
import { listSuggestedIssuesURI } from "shared/api/suggestedIssues/api";

const requestURIs: Partial<
  Record<Source, { listURI: (params: any) => string }>
> = {
  [Source.ISSUE]: {
    listURI: listIssuesRequestURI,
  },
  [Source.SUGGESTED_ISSUE]: {
    listURI: listSuggestedIssuesURI,
  },
};

/**
 * Validate the filter that is passed in. If the validation fails, an error will be thrown.
 *
 * @param params The parameters contains a vehiclesFilter property.
 * @throws If validation fails, an error will be thrown. The error is unusually wordy
 * because it is intended for consumption by the agent, which benefits from the detail.
 */
export const getValidateFilter =
  (source: Source) =>
  async (filterString: string): Promise<void> => {
    const uri = requestURIs[source];
    if (!uri) {
      throw new Error(`Invalid source of ${source}`);
    }

    await validateApiRequest(
      { filter: filterString, mileageUnit: "mi", limit: 1 },
      uri.listURI
    );
  };

export const getValidateSort =
  (source: Source) =>
  async (sortString: string): Promise<void> => {
    const uri = requestURIs[source];
    const [sortKeySuffix, sortOrder] = sortString.split("=");
    validateSortOrder(sortOrder);

    const fieldName = parseSortKeySuffix(sortKeySuffix);

    if (!uri) {
      throw new Error(`Invalid source of ${source}`);
    }

    await validateApiRequest(
      { sort: `${fieldName}:${sortOrder}`, mileageUnit: "mi", limit: 1 },
      uri.listURI
    );
  };
