import { GPT4OMINI_MODEL_SPEC } from "duck/graph/constants";
import { getLLM } from "duck/graph/utils";
import { runnableConfig } from "duck/ui/translate/agents/constants";
import { z } from "zod";

interface HasTranslatedText {
  translatedText: string;
}

const getPrompt = (text: string, targetLanguage: string) =>
  `You are a language translation model. Your purpose is to translate the provided text into ${targetLanguage}. 
  
Text:
${text}`;

const responseSchema = z.object({
  translatedText: z.string().describe("The translated text"),
});

/**
 * Invoke the agent to translate the text.
 * @param text
 * @returns
 */
const invokeTranslateAgent = async (
  text: string,
  targetLanguage: string
): Promise<HasTranslatedText> => {
  const prompt = getPrompt(text, targetLanguage);

  const translateAgent =
    getLLM(GPT4OMINI_MODEL_SPEC).withStructuredOutput(responseSchema);

  return translateAgent.invoke(prompt, runnableConfig);
};

export default invokeTranslateAgent;
