import { GPT4OMINI_MODEL_SPEC } from "duck/graph/constants";
import loadPrompt from "duck/graph/nodes/loadPrompt";
import { promptNames } from "duck/graph/nodes/types";
import {
  createStrictToolCallingAgent,
  getEphemeralMessageForNode,
  getPageAgentNames,
  NodeOutputType,
  NodeType,
} from "duck/graph/nodes/utils";
import { GraphStateType } from "duck/graph/state";
import {
  getRouteToIssuesToolDef,
  getRouteToSignalEventAnalyticsToolDef,
  getRouteToVinViewToolDef,
  routeToAnalyzeScreenshotToolDef,
  routeToClaimAnalyticsToolDef,
  routeToClaimsAgentToolDef,
  routeToClaimsDrillTopContributorsToolDef,
  routeToGreetingRejectClarifyToolDef,
  routeToIssueDetailsToolDef,
  routeToKnightSwiftVinViewToolDef,
  routeToRagToolDef,
  routeToSearchCodesByDescriptionToolDef,
  routeToSeDrillTopContributorsToolDef,
  routeToSubmitFeedbackToolDef,
  routeToVehiclesToolDef,
} from "duck/graph/tools/routingTools";
import { DuckGraphParams } from "duck/graph/types";
import { getLLM, NodeNames } from "duck/graph/utils";
import { BindToolsInput } from "@langchain/core/language_models/chat_models";
import { AIMessage } from "@langchain/core/messages";
import { RunnableConfig } from "@langchain/core/runnables";

const getNode = async (params: DuckGraphParams): Promise<NodeType> => {
  const duckAccess = params.uiHandlers.duckAccess;

  const tools: BindToolsInput[] = [
    routeToRagToolDef,
    routeToGreetingRejectClarifyToolDef,
    routeToSubmitFeedbackToolDef,
  ];

  if (!params.disableAnalyzeScreenShot) {
    tools.push(routeToAnalyzeScreenshotToolDef);
  }

  if (duckAccess.claimAnalytics.enabled) {
    tools.push(routeToClaimsAgentToolDef);
    tools.push(routeToSearchCodesByDescriptionToolDef);
    tools.push(routeToClaimsDrillTopContributorsToolDef);
  }

  if (duckAccess.signalEventAnalytics.enabled) {
    tools.push(routeToSeDrillTopContributorsToolDef);
  }

  if (duckAccess.knightSwiftVinView.enabled) {
    tools.push(routeToKnightSwiftVinViewToolDef);
  }

  if (!params.disablePageAgents) {
    if (duckAccess.claimAnalytics.enabled) {
      tools.push(routeToClaimAnalyticsToolDef);
    }

    if (duckAccess.signalEventAnalytics.enabled) {
      tools.push(getRouteToSignalEventAnalyticsToolDef(duckAccess));
    }

    if (duckAccess.vinView.enabled) {
      tools.push(getRouteToVinViewToolDef(duckAccess));
    }

    if (duckAccess.vehicles.enabled) {
      tools.push(routeToVehiclesToolDef);
    }

    if (duckAccess.issues.enabled) {
      tools.push(getRouteToIssuesToolDef(duckAccess));
    }

    if (duckAccess.issueDetails.enabled) {
      tools.push(routeToIssueDetailsToolDef);
    }
  }

  const prompt = await loadPrompt(promptNames.ROUTER_AGENT);
  const llm = getLLM(GPT4OMINI_MODEL_SPEC);

  // disable parallel tool calls since we currently don't want to route to multiple agents
  const agent = createStrictToolCallingAgent(llm, tools, prompt, false);

  const name = NodeNames.ROUTER;
  const setEphemeralMessage = params.uiHandlers.setEphemeralMessage;

  return async (
    { messages, pageState, activeAgent }: GraphStateType,
    config: RunnableConfig = {}
  ): Promise<NodeOutputType> => {
    setEphemeralMessage(getEphemeralMessageForNode(name));

    console.debug(`[${new Date().toISOString()}] invokeAgentNode: ${name}`);

    const agentMessage: AIMessage = await agent.invoke(
      {
        messages,
        page_agent_names: getPageAgentNames(duckAccess),
        current_page: pageState.pathname,
        prev_active_agent: activeAgent,
      },
      config
    );
    agentMessage.name = name;

    const toolCallName = agentMessage.tool_calls?.[0]?.name;

    return {
      messages: [agentMessage],
      activeAgent: toolCallName,
    };
  };
};

export default getNode;
