import { useMemo, useState } from "react";
import { BsShieldLock as LockIcon } from "react-icons/bs";
import { IconButton } from "@mui/material";

import { PermissionEntry } from "shared/api/api";
import { EntityWithPermissions, PermissionID } from "shared/types";

import PermissionsDialog from "./PermissionsDialog";
import { modifyPermissionEntry } from "./utils";

interface PermissionsSettingsActionProps {
  entity: EntityWithPermissions;
  entityId: string;
  entityName: string;
  permissions: PermissionEntry[];
  canEdit: boolean;
  iconSize?: number | string;
  showButton?: boolean;
  defaultOpen?: boolean;
  onClose?: () => void;
  entityRequestKeys?: string[];
  memberAccess?: PermissionID;
}

const PermissionsSettingsAction = ({
  entity,
  entityId,
  entityName,
  permissions,
  canEdit,
  iconSize = "25",
  showButton = true,
  defaultOpen = false,
  onClose,
  entityRequestKeys,
  memberAccess,
}: PermissionsSettingsActionProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const openModal = () => setIsOpen(true);
  const closeModal = () => {
    setIsOpen(false);
    onClose && onClose();
  };

  // modify permissions for the dialog
  const formattedPermissions = useMemo(
    () => modifyPermissionEntry(permissions),
    [permissions]
  );

  return (
    <>
      {showButton && (
        <IconButton
          title="Permissions"
          onClick={openModal}
          data-testid="permissions_icon"
        >
          <LockIcon size={iconSize} />
        </IconButton>
      )}

      <PermissionsDialog
        entity={entity}
        entityId={entityId}
        entityName={entityName}
        permissions={formattedPermissions}
        memberAccess={memberAccess}
        isOpen={isOpen}
        onClose={closeModal}
        canEdit={canEdit}
        entityRequestKeys={entityRequestKeys}
      />
    </>
  );
};

export default PermissionsSettingsAction;
