import { IconProps } from "./types";

const ServiceRecommendationsIcon = ({ size }: IconProps) => (
  <svg
    width={size}
    height={size}
    viewBox={`0 0 ${size} ${size}`}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14.875 0C15.4386 0 15.9791 0.223883 16.3776 0.622398C16.7761 1.02091 17 1.56141 17 2.125V14.875C17 15.4386 16.7761 15.9791 16.3776 16.3776C15.9791 16.7761 15.4386 17 14.875 17H2.125C1.56141 17 1.02091 16.7761 0.622398 16.3776C0.223883 15.9791 0 15.4386 0 14.875V2.125C0 1.56141 0.223883 1.02091 0.622398 0.622398C1.02091 0.223883 1.56141 0 2.125 0L14.875 0ZM2.125 1.0625C1.84321 1.0625 1.57296 1.17444 1.3737 1.3737C1.17444 1.57296 1.0625 1.84321 1.0625 2.125V3.1875H15.9375V2.125C15.9375 1.84321 15.8256 1.57296 15.6263 1.3737C15.427 1.17444 15.1568 1.0625 14.875 1.0625H2.125ZM1.0625 4.25V14.875C1.0625 15.1568 1.17444 15.427 1.3737 15.6263C1.57296 15.8256 1.84321 15.9375 2.125 15.9375H4.25V4.25H1.0625ZM5.3125 4.25V15.9375H14.875C15.1568 15.9375 15.427 15.8256 15.6263 15.6263C15.8256 15.427 15.9375 15.1568 15.9375 14.875V4.25H5.3125Z" />
  </svg>
);

export default ServiceRecommendationsIcon;
