import { IconProps } from "./types";

const RepairAnalyticsIcon = ({ size }: IconProps) => (
  <svg
    width={size}
    height={size - 1}
    viewBox={`0 0 ${size} ${size - 1}`}
    fill="currentColor"
    stroke="currentColor"
    strokeWidth="0"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12.8916 2.77007C12.8347 2.5408 12.5478 2.4631 12.3808 2.63017L10.4927 4.51821L8.7692 4.23105L8.48203 2.50753L10.3701 0.619492C10.5382 0.451407 10.4572 0.165001 10.2264 0.107618C9.02336 -0.190467 7.69873 0.130724 6.75852 1.07068C5.75204 2.07716 5.47503 3.53611 5.89397 4.80817L0.475883 10.2263C-0.158627 10.8608 -0.158627 11.8896 0.475883 12.5241C1.11039 13.1586 2.13922 13.1586 2.77373 12.5241L8.18725 7.11059C9.45982 7.53486 10.916 7.25481 11.9291 6.24172C12.8703 5.3005 13.191 3.97384 12.8916 2.77007ZM1.62506 11.9843C1.28863 11.9843 1.01569 11.7114 1.01569 11.3749C1.01569 11.0383 1.28863 10.7656 1.62506 10.7656C1.96148 10.7656 2.23443 11.0383 2.23443 11.3749C2.23443 11.7114 1.96148 11.9843 1.62506 11.9843Z" />
  </svg>
);

export default RepairAnalyticsIcon;
