import Skeleton from "react-loading-skeleton";
import { useParams } from "react-router-dom";

import { AlertEventType } from "shared/api/alertDefinitions/api";
import { useVehicle } from "shared/api/vehicles/hooks";
import { useTenantMileageUnit } from "shared/hooks";

import WatchlistCreateAlertAction from "pages/LandingPage/Watchlist/WatchlistCreateAlertAction";

import APIError from "features/ui/APIError";
import PageHeaderActionsWrapper from "features/ui/PageHeaderActionsWrapper";
import PageHeaderWrapper from "features/ui/PageHeaderWrapper";

import VehicleInfo from "./VehicleInfo";
import VINViewTabs from "./VINViewTabs";

type VINViewParams = {
  vin: string;
};

const VINView = () => {
  const params = useParams<VINViewParams>();
  const vin = params.vin as string;
  const mileageUnit = useTenantMileageUnit();

  const {
    data: vehicle,
    isLoading,
    error,
  } = useVehicle({
    vin,
    mileageUnit,
  });

  if (isLoading) {
    return <Skeleton height={300} />;
  }

  if (error) {
    return <APIError error={error} />;
  }

  return (
    <>
      <PageHeaderWrapper title={vin}>
        <PageHeaderActionsWrapper>
          <WatchlistCreateAlertAction
            eventType={AlertEventType.VEHICLE}
            objectId={vin}
            name={vin}
          />
        </PageHeaderActionsWrapper>
      </PageHeaderWrapper>
      <VehicleInfo vin={vin} vehicle={vehicle} />
      <VINViewTabs vin={vin} vehicle={vehicle} />
    </>
  );
};

export default VINView;
