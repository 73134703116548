import { useState } from "react";
import DuckTranslate from "duck/ui/translate/DuckTranslate";

import { RowData, SchemaEntry } from "features/ui/Table";
import { formatValue } from "features/ui/Table/TableBodyCell/TableBodyCell";
import { getValueByAccessor } from "features/ui/Table/utils";

interface Props<T> {
  schema: SchemaEntry<keyof T>[];
  data: T;
  fields: string[];
  translate?: boolean;
}

interface RowProps<T> {
  schemaEntry: SchemaEntry<keyof T>;
  data: T;
  accessor: string;
  translate?: boolean;
}

const LabelValuePairsRow = <T,>({
  data,
  accessor,
  translate = false,
  schemaEntry,
}: RowProps<T>) => {
  const value = formatValue({
    value: getValueByAccessor(accessor as string, data as RowData) as string,
    dataType: schemaEntry.dataType,
  });

  const [displayedValue, setDisplayedValue] = useState(value);

  if (!value) return null;

  return (
    <tr className="w-full  last:border-b-0">
      <td className="py-1 px-2 text-gray-400 text-sm border-b border-gray-200">
        {schemaEntry.label}
        {translate && typeof displayedValue === "string" && (
          <DuckTranslate
            originalText={displayedValue}
            setValue={setDisplayedValue}
          />
        )}
      </td>
      <td className="py-1 px-2 text-sm border-b border-gray-200">
        {displayedValue}
      </td>
    </tr>
  );
};

const LabelValuePairsTable = <T,>({
  schema,
  data,
  fields = [],
  translate = false,
}: Props<T>) => (
  // using native table so the items get automatically nicely aligned
  <table className="w-full! table-fixed">
    {fields.map((accessor) => {
      const schemaEntry = schema.find((entry) => entry.accessor === accessor);
      if (!schemaEntry) return null;

      return (
        <LabelValuePairsRow
          key={accessor}
          data={data}
          accessor={accessor}
          translate={translate}
          schemaEntry={schemaEntry}
        />
      );
    })}
  </table>
);

export default LabelValuePairsTable;
