import { createContext, useState } from "react";

import { SelectOption } from "features/ui/Select";

import { ContextWrapComponentProps } from "./types";

interface DuckletContextInterface {
  isOpen?: boolean;
  setIsOpen: (isOpen?: boolean) => void;
  assignedUtterance: string;
  setAssignedUtterance: (assignedUtterance: string) => void;
  selectedGroupByVehicleAttributes: SelectOption[];
  setSelectedGroupByVehicleAttributes: (
    groupByVehicleAttributes: SelectOption[]
  ) => void;
  autoSubmit: boolean;
  setAutoSubmit: (autoSubmit: boolean) => void;
}

const DEFAULT_CONTEXT: DuckletContextInterface = {
  isOpen: undefined,
  setIsOpen: () => {},
  assignedUtterance: "",
  setAssignedUtterance: () => {},
  selectedGroupByVehicleAttributes: [],
  setSelectedGroupByVehicleAttributes: () => {},
  autoSubmit: false,
  setAutoSubmit: () => {},
};

/**
 * The DuckletContext allows components outside of Duck to trigger actions inside Duck.
 * The context supports the following actions:
 * - setIsOpen: Open or close the Duck panel.
 * - setAssignedUtterance: Populate an utterance in Duck.
 * - setAutoSubmit: Automatically submit the utterance.
 *
 * These actions can be combined. A single button, for example, can open the Duck panel,
 * populate an utterance, and automatically submit it.
 */
export const DuckletContext =
  createContext<DuckletContextInterface>(DEFAULT_CONTEXT);

const DuckletContextWrapper = ({ children }: ContextWrapComponentProps) => {
  const [isOpen, setIsOpen] = useState<boolean | undefined>();
  const [assignedUtterance, setAssignedUtterance] = useState("");
  const [
    selectedGroupByVehicleAttributes,
    setSelectedGroupByVehicleAttributes,
  ] = useState<SelectOption[]>([]);
  const [autoSubmit, setAutoSubmit] = useState(false);

  return (
    <DuckletContext.Provider
      value={{
        isOpen,
        setIsOpen,
        assignedUtterance,
        setAssignedUtterance,
        selectedGroupByVehicleAttributes,
        setSelectedGroupByVehicleAttributes,
        autoSubmit,
        setAutoSubmit,
      }}
    >
      {children}
    </DuckletContext.Provider>
  );
};

export default DuckletContextWrapper;
