import { useGetIssueSignalEventsByCalendarTime } from "shared/api/issues/hooks";
import { useCustomLocalStorageState } from "shared/hooks";
import { IssueTypes } from "shared/types";

import ChartWithComparisonPopulation from "pages/Issues/Charts/ChartWithComparisonPopulation";
import PopulationChart from "pages/Issues/Charts/PopulationChart";
import { SignalEventsCharts } from "pages/Issues/constants";
import {
  getAxisKeyLabelFromActions,
  getChartOptionsKey,
} from "pages/Issues/utils";
import { getSignalEventChartActions } from "pages/SignalEventsAnalytics/tabPages/SignalEvents/utils";

import Card from "features/ui/Card";
import ChartActions from "features/ui/charts/Actions/ChartActions";
import { ChartActionsWrap } from "features/ui/charts/Actions/ChartActionsWrap";
import { SelectedChartOptions } from "features/ui/charts/Actions/types";
import { getDefaultActions } from "features/ui/charts/utils";

const ACTIONS = getSignalEventChartActions();

const CHART_NAME = SignalEventsCharts.OCCURRENCES_BY_CALENDAR_TIME;
const TITLE = "Signal Event Occurrences by Date";

interface Props {
  issue: IssueTypes;
  withComparisonPopulation: boolean;
}

const OccurrencesByCalendarTimeChart = ({
  issue,
  withComparisonPopulation,
}: Props) => {
  const chartOptionsKey = getChartOptionsKey(CHART_NAME, issue.ID);
  const [selectedOptions, setSelectedOptions] = useCustomLocalStorageState<
    SelectedChartOptions[]
  >(chartOptionsKey, { defaultValue: getDefaultActions(ACTIONS) });

  const { axisKey: yAxisKey, axisValue: yAxisValue } =
    getAxisKeyLabelFromActions(selectedOptions, ACTIONS, "y");

  const PopulationsChart = withComparisonPopulation
    ? ChartWithComparisonPopulation
    : PopulationChart;

  return (
    <Card>
      <ChartActionsWrap id={CHART_NAME} chartTitle={TITLE}>
        <ChartActions
          actions={ACTIONS}
          selectedOptions={selectedOptions}
          onOptionChange={setSelectedOptions}
        />
      </ChartActionsWrap>
      <PopulationsChart
        issue={issue}
        yAxisKey={yAxisKey}
        yAxisLabel={yAxisValue}
        chartType="CalendarTime"
        chart={CHART_NAME}
        dataLoadHook={useGetIssueSignalEventsByCalendarTime}
      />
    </Card>
  );
};

export default OccurrencesByCalendarTimeChart;
