import { SelectOption } from "features/ui/Select";

export const getFullPathVisual = (path: SelectOption[]) =>
  path.map(({ value }) => value).join(" > ");

export const getFullPathByIds = (path: SelectOption[]) =>
  path.map(({ id }) => id).join(".");

/**
 * Finds the complete path to a selected option in a nested options structure
 * @param searchOptions - The array of options to search through
 * @param ids - Array of ids representing the path (e.g., ["vehicle", "ecu", "firmware"])
 * @returns Array of SelectOptions representing the path to the target, or empty array if not found
 */
const findPathToSelected = (
  searchOptions: SelectOption[],
  ids: string[]
): SelectOption[] => {
  let currentOptions = searchOptions;
  const path: SelectOption[] = [];

  for (let i = 0; i < ids.length; i++) {
    const currentId = ids[i];
    const found = currentOptions.find((opt) => opt.id === currentId);

    if (!found) break;

    path.push(found);
    if (found.children) {
      currentOptions = found.children;
    }
  }

  return path;
};

export const getSelectedOptionFromId = (
  selectedAttribute: string | null,
  options: SelectOption[]
): SelectOption | undefined => {
  if (!selectedAttribute) return undefined;

  const path = findPathToSelected(options, selectedAttribute.split("."));

  return path.length > 0
    ? {
        ...path[path.length - 1],
        id: selectedAttribute,
        value: getFullPathVisual(path),
      }
    : undefined;
};

export const getInitialPath = (
  selected: SelectOption | undefined,
  options: SelectOption[]
): SelectOption[] => {
  if (!selected) return [];

  const ids = (selected.id as string).split(".");
  const path = findPathToSelected(options, ids);

  return path.slice(0, -1); // Exclude the leaf node
};
