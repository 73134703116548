import { CSSProperties } from "react";
import { ReactComponent as DuckLogoSvg } from "duck/ui/duck-logo.svg";

interface DuckLogoProps {
  w?: number;
  h?: number;
  strokeColor?: string;
  fillColor?: string;
}

const DuckLogo = ({
  w = 24,
  h = 24,
  strokeColor = "#6B7280",
  fillColor = "#6B7280",
}: DuckLogoProps) => (
  <div
    className={`h-[${w}px] w-[${h}px]`}
    style={{ "--fill": fillColor, "--stroke": strokeColor } as CSSProperties}
  >
    <DuckLogoSvg />
  </div>
);

export default DuckLogo;
