import Box from "@mui/material/Box";

import DatePicker from "features/ui/DatePicker";
import { DatePickerProps } from "features/ui/DatePicker/DatePicker";

interface DatePickerRangeProps
  extends Omit<
    DatePickerProps,
    "onChange" | "initialDate" | "label" | "placeholderText"
  > {
  startDateLabel?: string;
  endDateLabel?: string;
  startPlaceholder?: string;
  endPlaceholder?: string;
  initialDateStart?: Date | null;
  initialDateEnd?: Date | null;
  onChange?: (dateObjectStart: Date | null, dateObjectTo: Date | null) => void;
}

const DatePickerRange = ({
  startDateLabel,
  endDateLabel,
  startPlaceholder = "Start date",
  endPlaceholder = "End date",
  initialDateStart = null,
  initialDateEnd = null,
  onChange,
  ...otherProps
}: DatePickerRangeProps) => {
  const handleStartDateChange = (date: Date | null) => {
    onChange?.(date, initialDateEnd);
  };

  const handleEndDateChange = (date: Date | null) => {
    onChange?.(initialDateStart, date);
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <DatePicker
        {...otherProps}
        label={startDateLabel}
        placeholderText={startPlaceholder}
        initialDate={initialDateStart}
        onChange={handleStartDateChange}
      />
      <DatePicker
        {...otherProps}
        label={endDateLabel}
        placeholderText={endPlaceholder}
        initialDate={initialDateEnd}
        onChange={handleEndDateChange}
        minDate={initialDateStart || undefined}
      />
    </Box>
  );
};

export default DatePickerRange;
